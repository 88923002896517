import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { CustomersService } from 'src/app/services/customers.service';
import { MessageDialogeService } from 'src/app/message-dialoge/message-dialoge.service';
import { MarketPlaceService } from 'src/app/services/SaasFulfilment/market-place.service';
import { CommonService } from 'src/app/Base/Common.service';

@Component({
  selector: 'app-market-place',
  templateUrl: './market-place.component.html',
  styleUrls: ['./market-place.component.scss']
})
export class MarketPlaceComponent implements OnInit {

  constructor(
    private router: Router,    
    private activatedRoute: ActivatedRoute,                
    public customersService:CustomersService,
    public messageDialog: MessageDialogeService,
    public marketPlaceService: MarketPlaceService,
    public common: CommonService,

  ) {

   }

  ngOnInit(): void {
    debugger;
  
    this.canActivate(this.activatedRoute.snapshot);
  }

  canActivate(route: ActivatedRouteSnapshot) {
    this.common.ShowSpinner();
    
    debugger;
    var url: string = window.location.href;
    if (url.includes("?token=")) {
      this.setMpToken(url)
    }

    var token = localStorage.getItem('mptoken') == null ? '' : localStorage.getItem('mptoken');

    if (token == "" || token == null || token == undefined) {
      this.common.HideSpinner();
      this.router.navigate([""]);
    }

    console.log("Starting Resolved Subscription");
    this.marketPlaceService.ResolveSubscription(token);

  }

  setMpToken(url:string){
    if (url.includes("?token=")) {        
      if(localStorage.getItem('mptoken') == null){
          var token: string[] = url.split("token=");
          localStorage.setItem('mptoken', token[1]);
      }
      
    }
  }




}
