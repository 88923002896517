import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/Base/Common.service';
import { GeneralService } from 'src/app/services/general.service';
import { VenueService } from 'src/app/setupForms/venue/venue.service';

@Component({
  selector: 'app-sso-redirect',
  templateUrl: './sso-redirect.component.html',
  styleUrls: ['./sso-redirect.component.scss']
})
export class SsoRedirectComponent implements OnInit {

  constructor(
    private venueService:VenueService,
    private router:Router,
  ) { }

  ngOnInit(): void {
   this.getVenues();
  }

  async getVenues() {
    await this.venueService.getVenues().then(res => {  
      this.router.navigate(['signin']);
    }, error => {
      console.log(error);
     
    })
  }

}
