import { Injectable } from '@angular/core';
import moment from 'moment';
import * as customConfig from 'src/app/customConfig.json'

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }

  getToken():string{
    var token = localStorage.getItem(customConfig.tokenID);
    if (token == "" || token == null || token == undefined) {
      return "";
    }
    else { 
      return token;
    }
  }

  setToken(token:string){
    localStorage.setItem(customConfig.tokenID, token);
  }

  removeToken(){
    localStorage.removeItem(customConfig.tokenID);
    
  }

  getId_Token(url:string){
    if (url.includes("#id_token=")) {              
          var token: string[] = url.split("id_token=");
          return token[1];            
    }
    return "";
  }

     
   sessionTimeout(): Date {
    var sessionTimeout = moment().add(customConfig.sessionTimeoutMins,'minute').toDate();
    return sessionTimeout;
  }

}
