<nav [class]="theme.topNav">
    <div class="row top-desk" style="margin-left: 0;margin-right: 0;">
        <div class="col-md-4 col-sm-5 col-6">
            <a class="iconshadow mr-2" (click)="sideNavToggle()">
                <mdb-icon fas icon="bars"></mdb-icon>
            </a>
            <a routerLink="/calendar">
                <img src="assets/venue_arc_top.png" alt="image" class="venuearc_logo">
            </a>
            <!-- <span class="Premium" [matTooltip]="user?.SubscriptionName"
                [ngClass]="{'purple-gradient':user.Subscription == 'free', 'premium-gradient':(user.Subscription == 'premium' || user.Subscription == 'organization'), 'peach-gradient':user.Subscription == 'enterprise','basic-gradient':user.Subscription == 'basic'}">
                {{user?.Subscription}}
            </span> -->
            
        </div>

        <div class="col-md-8  col-sm-4 col-6 text-right">

            <div class="">
                <!-- <button mat-button
                    *ngIf="user?.SubscriptionType == 'stripe' && user?.Subscription == 'free' && isAdmin == true && isOwner == true"
                    type="button"
                    class="float-right ml-3 upgrade upgrade-icon upgrade-lg d-none d-sm-none d-md-inline d-xl-inline d-lg-inline"
                    matTooltip="Upgrade" (click)="upgrade()">
                    <mat-icon>upgrade</mat-icon>
                    <span class="">Upgrade</span>
                </button> -->

                <button class="mr-name float-right user-button ml-2 setting-btn" mat-mini-fab
                    [matMenuTriggerFor]="setting" #settingTrigger="matMenuTrigger" [matTooltip]="user?.UserName">
                    <span>{{user?.ShortCode}} </span>
                </button>

                <mat-menu #setting="matMenu">
                    <p class="text-center mt-2 px-2" [matTooltip]="user?.Email"> <b> {{user?.UserName}} </b></p>
                    <mat-divider></mat-divider>
                    <!-- <button mat-menu-item class="menu-width" type="button" (click)="upgradetoenterprise()"
                        *ngIf="(user?.Subscription == 'premium' || user?.Subscription == 'organization') && isOwner == true">
                        <mat-icon>arrow_upward</mat-icon>
                        Upgrade
                    </button> -->

                    <!-- <button mat-menu-item class="menu-width" type="button" (click)="switchTenant()">
                        <mat-icon>switch</mat-icon>
                        Switch Tenant
                    </button> -->
                    <button mat-menu-item class="menu-width" type="button" (click)="quickTour()" *ngIf="permissionService.CheckIfUserhasAccess_Action('Client','Add') == true && permissionService.CheckIfUserhasAccess_Action('Event','Add') == true">
                        <mat-icon>rocket_launch</mat-icon>
                        Take a Tour
                    </button>
<!-- 
                    <button mat-menu-item class="menu-width d-md-none d-lg-none d-xl-none" type="button"
                        (click)="upgrade()"
                        *ngIf="user?.SubscriptionType == 'stripe' && user?.Subscription == 'free' && isAdmin == true && isOwner == true">
                        <mat-icon>arrow_upward</mat-icon>
                        Upgrade
                    </button> -->


                   

                    <button mat-menu-item class="menu-width" type="button" (click)="removeSampleData()"
                        *ngIf="isSampleData == true">
                        <mat-icon>delete_forever</mat-icon>
                        Remove Sample Data
                    </button>

                    <mat-divider></mat-divider>

                    <button mat-menu-item class="menu-width " type="button" routerLink="/setup/settings">
                        <mat-icon>dashboard</mat-icon>
                        Settings
                    </button>

                    <mat-divider></mat-divider>

                    
                    <button mat-menu-item class="menu-width " type="button" (click)="openCustomerPortal()" *ngIf="isOwner == true && user?.SubscriptionType == 'stripe'">
                        <mat-icon>payments</mat-icon>
                        Billing
                    </button>
                    <mat-divider></mat-divider>
                    <button mat-menu-item class="menu-width" type="button" (click)="logout()"
                        *ngIf="teams.isTeamsApp() == false"><mat-icon>logout</mat-icon>Sign
                        out</button>

                </mat-menu>
            </div>

            <mat-form-field appearance="outline" class="matSearchFields mr-1 d-none d-md-inline  d-lg-inline d-sm-none"
                style="float: right;" [@openClose]="searchbar ? 'open' : 'close'">
                <mat-icon matPrefix class="mr-2" (click)="openSearch()" [hidden]="!searchbar">search</mat-icon>
                <input matInput placeholder="Search by Event ID or Name..." [matAutocomplete]="auto"
                    [formControl]="searchMoviesCtrl">
                <button mat-button *ngIf="searchMoviesCtrl.value" matSuffix mat-icon-button aria-label="Clear"
                    (click)="searchMoviesCtrl.setValue('')">
                    <mat-icon>close</mat-icon>
                </button>
                <mat-autocomplete class="matSearchFieldsOptions" #auto="matAutocomplete"
                    (optionSelected)="onEventClick($event.option.value)">
                    <mat-option *ngIf="isLoading" class="is-loading" disabled><span matSuffix
                            class="spinner-border spinner-border-sm mr-2"></span>Searching... </mat-option>
                    <mat-option disabled *ngIf="EventData.length > 0 && !isLoading"
                        style=" position: sticky;top: 0;z-index: 1;background-color: white; line-height:22px;border-bottom: 1px solid #d6d6d8; text-align: center; color: gray;">
                        Results found: {{EventData.length}}
                    </mat-option>
                    <ng-container *ngIf="!isLoading">

                        <mat-option *ngFor="let event of EventData" [value]="event.ID" style="font-size:14px;line-height:22px;border-bottom: 1px solid #d6d6d8;padding-top: 4px;
                  padding-bottom: 4px; height:78px;">
                            <a>

                                <b style=" font-size: 16px">
                                    {{event.EventName}}
                                </b>
                                <br>
                                <b style="font-weight: 500; font-size: 13px; color:gray">Event ID: {{event.EntryNo}} | Start
                                    Date:
                                    {{common.BLFormatDate(event.EventStartDate) | date:'MM/dd/yyyy'}} | End Date:
                                    {{common.BLFormatDate(event.EventEndDate) | date:'MM/dd/yyyy'}} | Venue:
                                    {{event.VenueName}} </b>

                            </a>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </mat-form-field>

            <!-- <span class="text-warning mr-3 TrialClass" *ngIf="user.IsFreeTrial" >
                <mat-icon style="display: inline;font-size: 18px;">warning</mat-icon>
                <span> {{user?.TrialRemainingDays}} days left </span>
            </span> -->

            <!-- <button mat-button (click)="showTour()" class="mr-4" 
                style="background: aliceblue !important;" [class]="theme.label">
                <mdb-icon fas icon="user-plus"></mdb-icon>
                Invite
            </button> -->
            <button mat-button (click)="addUser()" class="mr-4" [hidden]="permissionService.CheckIfUserhasAccess_Section('Users')"
                style="background: aliceblue !important;" [class]="theme.label" *ngIf="permissionService.CheckIfUserhasAccess_Action('Users','Add')">
                <mdb-icon fas icon="user-plus"></mdb-icon>
                Invite
            </button>

            <mat-icon [matMenuTriggerFor]="event" #eventTrigger="matMenuTrigger" *ngIf="permissionService.CheckIfUserhasAccess_Action('Event','Add') == true && permissionService.CheckIfUserhasAccess_Action('Client','Add') == true"
                class="d-none d-sm-none d-md-inline d-xl-inline d-lg-inline align-bottom add-pop ml-1"
                style="cursor: pointer;line-height: 1.3 !important;">add
            </mat-icon>

            <mat-icon (click)="basicModal.show();"
                class="d-none d-sm-none d-md-inline d-xl-inline d-lg-inline align-bottom mr-1 ml-1"
                style="cursor: pointer;line-height: 1.3 !important;">notifications
            </mat-icon>

            <mat-icon class=" d-none d-sm-none d-md-inline d-xl-inline d-lg-inline align-bottom mr-2"
                (click)="openSearch()" *ngIf="searchbar != null && searchbar"
                style="cursor: pointer;line-height: 1.3 !important;">close
            </mat-icon>

            <mat-icon class="mr-2 d-none d-sm-none d-md-inline d-xl-inline d-lg-inline align-bottom ml-1"
                (click)="openSearch()" *ngIf="searchbar == null || !searchbar"
                style="cursor: pointer;line-height: 1.3 !important;">search
            </mat-icon>

            <mat-menu #event="matMenu">

                <!-- <button mat-menu-item [matMenuTriggerFor]="eventBtn" class="menu-width event-btn" type="button"  *ngIf="permissionService.CheckIfUserhasAccess_Action('Event','Add')"> -->
                <button mat-menu-item class="menu-width event-btn" type="button"
                    *ngIf="permissionService.CheckIfUserhasAccess_Action('Event','Add')" (click)="openEventForm();">
                    <!-- <mdb-icon fas icon="plus" class="mr-1"></mdb-icon> -->
                    Event
                </button>
                <button mat-menu-item class="menu-width client-btn" type="button" (click)="openQuickClientForm()"
                    *ngIf="permissionService.CheckIfUserhasAccess_Action('Client','Add')">
                    <!-- <mdb-icon fas icon="plus" class="mr-1"></mdb-icon> -->
                    Client
                </button>
                <button mat-menu-item class="menu-width venue-btn" type="button" (click)="openQuickVeuneForm()" [hidden]="common.checkVenueExists()"
                    *ngIf="permissionService.CheckIfUserhasAccess_Action('Venue','Add')">
                    <!-- <mdb-icon fas icon="plus" class="mr-1"></mdb-icon> -->
                    Venue
                </button>

            </mat-menu>

        </div>
    </div>

    <div class="row" style="margin-left: 0;margin-right: 0;">
        <div class="col-7 top-mob">
            <a class="iconshadow mr-2" (click)="sideNavToggle()">
                <mdb-icon fas icon="bars"></mdb-icon>
            </a>
            <a routerLink="/calendar">
                <img src="assets/venue_arc_top.png" alt="image" class="venuearc_logo">
            </a>
        </div>
        <div class="col-5 top-mob">
            <div class="d-flex align-items-center justify-content-end">

                <mdb-icon fas icon="search"
                    class="mr-1 d-inline d-sm-inline mr-2 d-md-none d-xl-none d-lg-none align-bottom ml-1"
                    (click)="test.toggle()"></mdb-icon>

                <button class="mr-name  user-button-mob add-pop-mob" mat-mini-fab [matMenuTriggerFor]="eventmob" #eventTriggerMob="matMenuTrigger"
                *ngIf="permissionService.CheckIfUserhasAccess_Action('Event','Add') == true && permissionService.CheckIfUserhasAccess_Action('Client','Add') == true">
                    <mat-icon style="font-size: 20px;">add</mat-icon>
                </button>

                <button class=" user-button-mob setting-btn-mob" mat-mini-fab [matMenuTriggerFor]="settingmob"
                    [matTooltip]="user?.UserName">
                    <span>{{user?.ShortCode}} </span>
                </button>

                <mat-menu #settingmob="matMenu">
                    <p class="text-center mt-2 px-2"> <b> {{user?.UserName}} </b></p>
                    <mat-divider></mat-divider>
                    <!-- <button mat-menu-item class="menu-width" type="button" (click)="upgradetoenterprise()"
                        *ngIf="(user?.Subscription == 'premium' || user?.Subscription == 'organization') && isOwner == true">
                        <mat-icon>arrow_upward</mat-icon>
                        Upgrade
                    </button>
                    <button mat-menu-item class="menu-width d-md-none d-lg-none d-xl-none" type="button"
                        (click)="upgrade()"
                        *ngIf="user?.SubscriptionType == 'stripe' && user?.Subscription == 'free' && isAdmin == true && isOwner == true">
                        <mat-icon>arrow_upward</mat-icon>
                        Upgrade
                    </button> -->
                  
                    <mat-divider></mat-divider>

                    <button mat-menu-item class="menu-width " type="button" (click)="removeSampleData()"
                        *ngIf="isSampleData == true">
                        <mat-icon>delete_forever</mat-icon>
                        Remove Sample Data
                    </button>
                    <mat-divider></mat-divider>
                    <button mat-menu-item class="menu-width " type="button" (click)="quickTourMob()" *ngIf="permissionService.CheckIfUserhasAccess_Action('Event','Add') == true && permissionService.CheckIfUserhasAccess_Action('Client','Add') == true">
                        <mat-icon>rocket_launch</mat-icon>
                        Take a Tour
                    </button>

                    <button mat-menu-item class="menu-width " type="button" routerLink="/setup/settings">
                        <mat-icon>dashboard</mat-icon>
                        Settings
                    </button>
                    <mat-divider></mat-divider>

                    <button mat-menu-item class="menu-width " type="button" (click)="openCustomerPortal()" *ngIf="isOwner == true && user?.SubscriptionType == 'stripe'">
                        <mat-icon>payments</mat-icon>
                        Billing
                    </button>

                    <mat-divider></mat-divider>
                    <button mat-menu-item class="menu-width" type="button" (click)="logout()">
                        <mat-icon>logout</mat-icon>Sign out
                    </button>

                </mat-menu>

                <mat-menu #eventmob="matMenu">

                    <button mat-menu-item class="menu-width event-btn-mob" type="button" (click)="openEventForm();"
                        *ngIf="permissionService.CheckIfUserhasAccess_Action('Event','Add')">
                        Add Event
                    </button>
                    <button mat-menu-item class="menu-width client-btn-mob" type="button" (click)="openQuickClientForm()"
                        *ngIf="permissionService.CheckIfUserhasAccess_Action('Client','Add')">
                        Add Client
                    </button>
                    <button mat-menu-item class="menu-width venue-btn-mob" type="button" (click)="openQuickVeuneForm()" [hidden]="common.checkVenueExists()"
                        *ngIf="permissionService.CheckIfUserhasAccess_Action('Venue','Add')">
                        Add Venue
                    </button>
                </mat-menu>

                <!-- <mat-menu #eventBtnMob="matMenu">
                    <button mat-menu-item class="menu-width" (click)="openQuickEventForm();">
                        One-Day Event
                    </button>
                    <button mat-menu-item class="menu-width" (click)="openEventForm();">
                        Multi-Day Event
                    </button>
                </mat-menu> -->

            </div>
        </div>
        <div class="col-12 col-sm-12 mt-2" mdbCollapse #test="bs-collapse">
            <mat-form-field appearance="outline"
                class="matSearchFields d-inline d-sm-inline d-md-none d-xl-none d-lg-none p-0">
                <mat-icon matPrefix class="mr-2">search</mat-icon>
                <input matInput placeholder="Search by Event ID or Event Name..." [matAutocomplete]="auto"
                    [formControl]="searchMoviesCtrl">
                <button mat-button *ngIf="searchMoviesCtrl.value" matSuffix mat-icon-button aria-label="Clear"
                    (click)="searchMoviesCtrl.setValue('')">
                    <mat-icon>close</mat-icon>
                </button>
                <mat-autocomplete class="matSearchFieldsOptions" #auto="matAutocomplete"
                    (optionSelected)="onEventClick($event.option.value)">
                    <mat-option *ngIf="isLoading" class="is-loading" disabled><span matSuffix
                            class="spinner-border spinner-border-sm mr-2"></span>Searching... </mat-option>
                    <mat-option disabled *ngIf="EventData.length > 0 && !isLoading"
                        style=" position: sticky;top: 0;z-index: 1;background-color: white; line-height:22px;border-bottom: 1px solid #d6d6d8; text-align: center; color: gray;">
                        Results found: {{EventData.length}}
                    </mat-option>
                    <ng-container *ngIf="!isLoading">

                        <mat-option *ngFor="let event of EventData" [value]="event.ID" style="font-size:14px;line-height:22px;border-bottom: 1px solid #d6d6d8;padding-top: 4px;
                    padding-bottom: 4px; height:78px;">
                            <a>

                                <b style=" font-size: 16px">
                                    {{event.EventName}}
                                </b>
                                <br>
                                <b style="font-weight: 500; font-size: 13px; color:gray">Event ID: {{event.EntryNo}} | Start
                                    Date:
                                    {{common.BLFormatDate(event.EventStartDate) | date:'MM/dd/yyyy'}} | End Date:
                                    {{common.BLFormatDate(event.EventEndDate) | date:'MM/dd/yyyy'}} | Venue:
                                    {{event.VenueName}} </b>

                            </a>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>


</nav>



















<div mdbModal #basicModal="mdbModal" class="modal fade right" tabindex="-1" role="dialog"
    aria-labelledby="myBasicModalLabel" [config]="{backdrop: false, ignoreBackdropClick: false}" aria-hidden="true">
    <div class="modal-dialog modal-full-height modal-right" role="document">
        <div class="modal-content">
            <div class="modal-header auditTop">
                <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
                <h5 class="modal-title w-100" id="myModalLabel">Recent Activity</h5>
            </div>
            <div class="modal-body scrollBar">
                <app-audit-log></app-audit-log>
            </div>

        </div>
    </div>
</div>