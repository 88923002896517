<div class="row">
    <div class="col-md">
            <!-- <a (click)="modalRefEvent.hide()" style="float:right" mdbBtn floating="true" mdbTooltip="Close" 
            aria-label="Close" placement="left"  size="sm"  gradient="aqua" class="mr-5"
             mdbWavesEffect>
            <mdb-icon fas icon="times"></mdb-icon>   
</a> -->
            

<div class="btn-group" role="group" aria-label="Basic example">
    <a (click)="modalRefEvent.hide()">
    <button mdbBtn type="button" color="info" size="sm" class="waves-light" mdbWavesEffect>
      <mdb-icon fas icon="times" class="mr-1"></mdb-icon>Close
    </button>
  </a>

  </div>

    </div>
</div>

<app-event-master></app-event-master>
