import { Subject } from 'rxjs';
import { ToastType } from 'src/app/Enum/ToastType';
import { Autocomplete } from 'src/app/Base/Autocomplete'
import { EventStatus } from 'src/app/models/EventStatus.model';
import { Venue } from 'src/app/models/venue.model';
import { Component, ViewChild, TemplateRef, OnInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { GenericService } from 'src/app/services/generic.service';
import { GeneralService } from 'src/app/services/general.service';
import { CalendarEvent, CalendarEventAction, CalendarDateFormatter, CalendarEventTimesChangedEvent, CalendarView, CalendarMonthViewBeforeRenderEvent } from 'angular-calendar';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { Event } from '../modules/event/event-info/Event.model';
import { BLBase } from 'src/app/Base/BLBase/BLBase.component';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastService, MDBModalService, MDBModalRef, ModalDirective } from 'ng-uikit-pro-standard';
import { ConfirmDialogeService } from 'src/app/confirm-dialoge/confirm-dialoge.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Holiday } from 'src/app/setupForms/holiday/holiday.model'
import { LoginUser } from 'src/app/Base/User/login-user';
import { FilterBL } from 'src/app/models/FilterBL';
import { CustomDateFormatter } from './custom-date-formatter.provider';



import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';

// For Date Picker month
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';


import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
//import { EventNotesComponent } from '../modules/event/event-notes/event-notes/event-notes.component';
import { EventSubEventNotesComponent } from '../modules/event/event-sub-event-notes/event-sub-event-notes/event-sub-event-notes.component';

import { VenueMonthNotes } from '../models/venue-month-notes.model';
import { EnEventStatus } from '../Enum/Enums'
import { DateTime } from 'luxon';
import { CommonService } from '../Base/Common.service';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MessageDialogeService } from '../message-dialoge/message-dialoge.service';

import { SPOperationsService } from 'src/app/services/spoperations.service';
import { EventFuncService } from 'src/app/modules/event/EventFuncService.service';
import { Client } from '../modules/client/client.model';
import { takeUntil, take } from 'rxjs/operators';
import { CalendarNotesComponent } from 'src/app/calendar-notes/calendar-notes.component';
import { CalendarService } from './calendar-service.service';
import { ConfFilters } from '../models/conf-filters.model';
import { VenueService } from '../setupForms/venue/venue.service';
import { EventNotesService } from '../modules/event/event-notes/event-notes.service';
import { EventStatusService } from '../setupForms/event-status/event-status.service';
import { WelcomePageComponent } from '../modules/main/welcome-page/welcome-page.component';
import { MatDialog } from '@angular/material/dialog';
import { QuickEventComponent } from '../modules/event/quick-event/quick-event.component';
import { themeService } from '../services/theme.service';
import { GuidedTour, GuidedTourService, Orientation } from 'ngx-guided-tour';
import { TourGuideService } from '../services/tour-guide.service';
import { QuickEventMdbComponent } from '../modules/event/quick-event-mdb/quick-event-mdb.component';
import { ConfGlobalService } from '../services/conf-global.service';
import { PermissionService } from '../services/permission.service';
import { ClientService } from '../services/client.service';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


const colors: any = {};

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],

  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],

})
export class CalendarComponent extends BLBase<Event> implements OnInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;


  public modalRef: MDBModalRef;
  date = new FormControl(moment());
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  clickedDate: Date;
  clickedColumn: number;
  refresh: Subject<any> = new Subject();
  activeDayIsOpen: boolean = true;
  AutoCompVenue: Autocomplete<Venue> = new Autocomplete<Venue>("VenueName", "ID");
  AutoCompStatus: Autocomplete<EventStatus> = new Autocomplete<EventStatus>("EventStatus", "ID");
  FilterDate: Date = new Date();

  @ViewChild('eventStatus_SkillSell') eventStatus_SkillSell: MatSelect
  @ViewChild('venue_SkillSell') venue_SkillSell: MatSelect


  public selectedClients: number[] = [];
  searchClient: any = null;
  AutoCompClient: Autocomplete<Client> = new Autocomplete<Client>("ClientName", "ID");
  protected _onDestroy = new Subject<void>();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];
  events: CalendarEvent[];
  Allevents: CalendarEvent[];
  Holidays: Holiday[];

  VenueID: number[] = [];
  EventStatusID: number[] = [];

  selectedChipVenueName = "";
  selectedChipEventStatus = null;
 
  constructor(
    private modal: NgbModal,
    public sp: SPOperationsService,
    public modalService: MDBModalService,
    public genService: GeneralService,
    public spinner: NgxSpinnerService,
    public toast: ToastService,
    public service: GenericService,
    public dialog: ConfirmDialogeService,
    public router: Router,
    public route: ActivatedRoute,
    public common: CommonService,
    public eventFunc: EventFuncService,
    public calendarService: CalendarService,
    public venueService: VenueService,
    public eventNotesService: EventNotesService,
    public eventStatusService: EventStatusService,
    public  confGlobalService:ConfGlobalService,
    public permissionService:PermissionService,
    public clientService:ClientService,

    public messageDialog?: MessageDialogeService,
    public matDialog?: MatDialog,
    public theme?:themeService,
    public tourSevice?:TourGuideService


    // public venueMonthCivic : VenueMonthNotesClass
  ) {
    super(service, router, route, spinner, toast, dialog, messageDialog);
    this.addControllerName("Events");
    //this.getServiceFilters();
  }
 
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    var aa = window.innerWidth;
    console.log(aa);
  }

  public Initializeobject() {
    this.formData = new Event();
  }

  toggleSelectAll(selectAllValue: boolean) {

    this.AutoCompClient.results.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        var vals = val.map(a => a.ID);
        if (selectAllValue) {
          this.selectedClients = vals;
        } else {
          this.selectedClients = [];
        }
      })
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngAfterViewInit() {
    
  }


  async ngOnInit(): Promise<void> {

    super.ngOnInit();
 
   
      
   

    await this.onComponentInit();
    

  }

  getClients() {
    this.clientService.getClientDrp().then(res => {
      this.AutoCompClient.data = res['value'] as any[];
      this.AutoCompClient.resultObserve();
    })
  }


  async getStatus() {
    await this.eventStatusService.getAvailableEventStatus().then(res => {

      this.AutoCompStatus.data = res['value'] as any[];
      this.AutoCompStatus.resultObserve();


      var eventStatus: EventStatus[] = [];
      eventStatus = res['value'] as EventStatus[]

      eventStatus.forEach(element => {
        {
          colors[element.Color] = {
            primary: element.Color,
            secondary: element.Color,
          }
        }
      });


      colors['blue'] = {
        primary: '#33b5e5',
        secondary: '#D1E8FF',
      }

      colors['black'] = {
        primary: 'black',
        secondary: 'black',
      }

      this.setChips(eventStatus);


    })
  }

  groupEventStatus: any[] = [];

  async getGroupStatus() {
    await this.eventStatusService.getAvailableGroupAndNoneEventStatus().then(res => {
      this.groupEventStatus = res['value'] as any[]
    })
  }

  chpArray = [];
  setChips(eventStatus: any[]) {
    this.chpArray = [];

    // For Child Status Status
    //#region
    const uniqueStatusParentIDs = [...new Set(eventStatus.filter(x => x != undefined && x.LevelName == 'Child').map(item => item.ParentID))];

    //Get Selected Status Groups ID
    var groupEventStatus = this.groupEventStatus.filter(res => {
      return (uniqueStatusParentIDs).find(c => res.ID === c)
    })

    groupEventStatus.forEach(element => {

      var StatusChips: EventStatus[] = [];

      // Get all child status of each group
      var filteredStatus = eventStatus.filter(x => x != undefined && x.ParentID == element.ID)
      if (filteredStatus.length > 0) {
        filteredStatus.forEach(element => {
          StatusChips.push(element)
        });
      }

      // Fill in chips
      this.chpArray.push({ eventStatus: element, chipObj: StatusChips })

    })

    //#endregion


    // For None Status Status
    //#region

    var noneEventStatus = eventStatus.filter(x => {
      return x != undefined && x.LevelName == 'None';
    })

    noneEventStatus.forEach(element => {

      var StatusChips: EventStatus[] = [];
      StatusChips.push(element);

      // Fill in chips
      this.chpArray.push({ eventStatus: element, chipObj: StatusChips })

    })

    //#endregion

    

  }

 

  async getVenues() {
    await this.venueService.getDrpVenues().then(
      res => {
        this.AutoCompVenue.data = res['value'] as Venue[];
        this.AutoCompVenue.resultObserve();
        this.HideSpinner();
      }
      , error => {
        this.HideSpinner();
        this.ShowToast(error.message, ToastType.Error);
      }
    )
  }




  venueMonthNotesList: any[] = [];
  IsMonthNotesLoaded: boolean = false;
  IsManyVenues = false;
  GetVenueMonthData(MonthDay: Date) {
    //this.ShowSpinner();
    this.IsMonthNotesLoaded = false;

    this.venueService.getVenueAndMonthlyNotesData(moment(MonthDay).format("MM"), moment(MonthDay).format("YYYY"), this.VenueID).then(res => {
      console.log(res)
      this.venueMonthNotesList = res['value'];
      this.IsMonthNotesLoaded = true;

      if (this.venueMonthNotesList.length > 2)
        this.IsManyVenues = true;
      else
        this.IsManyVenues = false;


      //this.HideSpinner();
    }, error => {
      //this.HideSpinner();
      this.ShowToast(error.message, ToastType.Error);
    })

  }


  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
      this.calendarService.viewDate = this.viewDate;
    }
  }


  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        }
      },
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay(ChangedDate: any) {
    this.activeDayIsOpen = false;
    this.calendarService.viewDate = this.viewDate;
    this.GenerateCalendarData(ChangedDate);
    this.GetVenueMonthData(ChangedDate);
  }

  dayEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  filterCalendar(type: string, event: MatDatepickerInputEvent<Date>) {
    this.viewDate = new Date(event.value);
    this.calendarService.viewDate = this.viewDate;
    //this.refresh = new Subject();
    this.GenerateCalendarData(this.viewDate);
    this.GetVenueMonthData(this.viewDate);

  }

  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
    datepicker.close();

  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    ctrlValue.year(normalizedMonth.year());
    this.date.setValue(ctrlValue);
    datepicker.close();

    this.viewDate = new Date(this.date.value);
    this.calendarService.viewDate = this.viewDate;
    this.GenerateCalendarData(this.viewDate);
    this.GetVenueMonthData(this.viewDate);
  }


  OpenEventModal(event) {
    var eventDate = moment.parseZone(event).format('YYYY-MM-DD');
    this.router.navigate(['/event/event/form'], { queryParams: { EventAddDate: eventDate } })

  }

  deleteEventStatus(eventID: number) {
    if (eventID != null) {
      this.dialog.openConfirmDialog("Are you sure you want to delete this event?").afterClosed().subscribe(res => {
        if (res) {
          this.ShowSpinner();

          var obj = {
            "EventID": eventID
          }

          //this.genService.setData("Events/SetEventStatusDelete", obj).subscribe(res => {
          this.genService.setData("api/EventOp/SetEventStatusDelete", obj).subscribe(res => {
            this.AfterFullDelete();

          }, error => {
            this.HideSpinner();
            //this.ShowToast(error.message, ToastType.Error);


            if (error.error.text) {
              if (String(error.error.text).indexOf("Error:") == 0) {
                this.messageDialog.openMessageDialog(error.error.text);
              }
              else {
                this.ShowToast(error.message, ToastType.Error);
              }
            }
            else
              this.ShowToast(error.message, ToastType.Error);
          });

        }
      })

    }
  }

  async   deleteFromEventMasterSpList(currenteventID) {

    const query = {
      filter: 'EventID eq ' + currenteventID,
    };

    await this.sp.readItems("Event Master", query).then((res: any) => {
      if (res) {

        var EventMaster_ID = res.d.results[0].ID;
        var EventMaster_Title = res.d.results[0].Title

        var objEventMaster = {
          "Title": EventMaster_Title + " Delete",
          "IsDeleted": 'true'
        }

        this.sp.updateItem("Event Master", EventMaster_ID, objEventMaster).then(res => {
          this.AfterFullDelete();
        }, error => {
          this.ShowToast(error, ToastType.Error);
          //this.AfterFullDelete();
        });

      }
      else {
        this.AfterFullDelete();
      }

    });
  }

  AfterFullDelete() {

    this.ShowToast("Successfully Deleted!.", ToastType.Success)
    this.HideSpinner();
    this.GenerateCalendarData(this.viewDate);
  }

  OpenNotesModal(eventID: number) {

    // this.modalRef = this.modalService.show(EventNotesComponent, {
    //   backdrop: true,
    //   keyboard: true,
    //   focus: true,
    //   show: false,
    //   ignoreBackdropClick: true,
    //   class: 'modal-xl modal-content modal-dialog cascading-modal modal-top',
    //   containerClass: '',
    //   animated: true,
    //   styles: "overflow-y: auto",
    //   data: {
    //     eventID: eventID
    //   }
    // });

    this.modalRef = this.eventNotesService.OpenNotesModal(eventID, this.modalService, this.modalRef);

    this.modalRef.content.action.subscribe((result: any) => {
      this.GenerateCalendarData(this.viewDate);
    })
  }

  OpenPerformanceNotesModal(eventID: number, subEventID: number) {

    this.modalRef = this.modalService.show(EventSubEventNotesComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-xl modal-content modal-dialog cascading-modal modal-top',
      containerClass: '',
      animated: true,
      styles: "overflow-y: auto",
      data: {
        eventID: eventID,
        subEventID: subEventID
      }
    });

    this.modalRef.content.action.subscribe((result: any) => {
      this.GenerateCalendarData(this.viewDate);
    })

  }


  GenerateCalendarData(TodayDate: Date) {
    this.ShowSpinner();

    var allSelectedVenueID = "";
    for (let index = 0; index < this.VenueID.length; index++) {
      const element = this.VenueID[index];
      allSelectedVenueID += element.toString();
      if (index < this.VenueID.length - 1)
        allSelectedVenueID += ","
    }

    var allSelectedStatusID = "";
    for (let index = 0; index < this.EventStatusID.length; index++) {
      const element = this.EventStatusID[index];
      allSelectedStatusID += element.toString();
      if (index < this.EventStatusID.length - 1)
        allSelectedStatusID += ","
    }

    var allSelectedClientID = "";
    for (let index = 0; index < this.selectedClients.length; index++) {
      const element = this.selectedClients[index];
      allSelectedClientID += element.toString();
      if (index < this.selectedClients.length - 1)
        allSelectedClientID += ","
    }


    var startDate = moment(TodayDate).startOf('month').format("MM-DD-YYYY");
    var endDate = moment(TodayDate).endOf('month').format("MM-DD-YYYY");

    //var qryData = "exec SP_GetCalendarData '" + startDate + "', '" + endDate + "','" + allSelectedVenueID + "', '" + allSelectedStatusID + "', '" + allSelectedClientID + "'; ";
    //qryData += "exec SP_GetCalendarHolidayData '" + startDate + "', '" + endDate + "'";
    this.calendarService.GetCalendarData(startDate, endDate, allSelectedVenueID, allSelectedStatusID, allSelectedClientID).then(res => {
    //this.genService.GetConfigDataWithBodyQuery(qryData).subscribe(res => {
      var calendarData = res['value'] as [];
      this.Holidays = res['Table1'] as Holiday[];
      this.events = this.createCalendarEventData(calendarData);
      this.Allevents = [...this.events];
      this.getDataByChip(this.selectedChipVenueName, this.selectedChipEventStatus);
      this.refresh = new Subject();

      this.HideSpinner();

    });

    /*
    this.genService.GetConfigDataWithQuery("SP_GetCalendarData '" + startDate + "', '" + endDate + "','" + allSelectedVenueID + "', '" + allSelectedStatusID + "', '" + allSelectedClientID + "'").then(res => {
      var calendarData = res['value'] as [];

      this.events = this.createCalendarEventData(calendarData);
      this.Allevents = [...this.events];
      this.getDataByChip(this.selectedChipVenueName, this.selectedChipEventStatus);
      //this.refresh = new Subject();


      this.genService.GetConfigDataWithQuery("SP_GetCalendarHolidayData '" + startDate + "', '" + endDate + "'").then(res => {
        this.Holidays = res['value'] as Holiday[];
        this.createCalendarHolidayData();
        this.Allevents = [...this.events];
        this.refresh = new Subject();
      })

      this.HideSpinner();

    });
    */

  }





  createCalendarEventData(data: []): CalendarEvent[] {

    var eventarray: CalendarEvent[] = [];

    data.forEach(element => {
      eventarray.push(
        {
          id: element['ID'],
          start: this.common.ConvertStringToHhMm(element['SubEventDate'], element['ShowStartTime']),
          end: this.common.ConvertStringToHhMm(element['SubEventDate'], element['ShowEndTime']),
          title: (element['EventName'] + " " + (element['RescheduleSub'] == null ? "" : element['RescheduleSub'])),
          color: element['EventStatus'] == null ? colors.blue : colors[element['Color']],
          //color: element['EventStatus'] == null ? colors.blue : element['IsSecured'] == true ? colors.black : colors[element['Color']],
          //actions: this.actions,
          //allDay: true,
          // resizable: {
          //   beforeStart: true,
          //   afterEnd: true,
          // },
          meta: {
            Venue: element['VenueName'],
            EventID: element['EventID'],
            ModifiedBy: (element['ModifiedBy'] == null ? 'Not found' : element['FirstName'] + ' ' + element['LastName']),
            IsSecured: element['IsSecured'],
            IsSecuredEvent: element['IsSecured'],
            ModifiedDate: element['ModifiedDate'],
            icon: element['VenueShortCode'],
            ShowStartTime: this.common.ConvertStringToTime(element['ShowStartTime']),
            ShowEndTime: this.common.ConvertStringToTime(element['ShowEndTime']),
            EventStatus: element['EventStatus'] == null ? '' : element["EventStatus"],
            EventStatusID: element['EventStatusID'] == null ? '' : element["EventStatusID"],
            EventContractID: element['EventContractID'],
            FinancialsID: element['FinancialsID'],
            DepsitScheduleID: element['DepsitScheduleID'],
            SettlementEventID: element['SettlementEventID'],
            EventNotesID: element['EventNotesID'],
            SubEventNotesID: element['SubEventNotesID'],
            IsBooked: element['IsBooked'],
            IsSettlement: element['IsSettlement'],
            IsHoliday: false,
            TextColor: element['TextColor'],
            SubEventTypeName: element['EventTypeName'],
            ClientName: element['ClientName'],
            VenueID: element['VenueID'],
            InventoryEventID: element['InventoryEventID'],
            EventName: element['EventName'],
            EntryNo: element['EntryNo']

          }
        })

    });

    this.Holidays.forEach(element => {

      if (element['HolidayDate'] != null) {
        var startDate = new Date(this.BLFormatDate(element['HolidayDate']));

        eventarray.push({
          id: element['ID'],
          start: this.common.ConvertStringToHhMm(startDate, "11:00"),
          end: this.common.ConvertStringToHhMm(startDate, "11:00"),
          title: element['Description'],
          color: colors.blue,
          meta: {
            IsHoliday: true
          }
        })

      }
    })



    return eventarray;
  }

  addItem(newItem: string) {
    //this.items.push(newItem);
  }

  getDataByChipVenue(venueName) {
    if (venueName != "") {
      this.selectedChipVenueName = venueName;
      this.events = this.Allevents.filter(res => { return res.meta.Venue == venueName; })
    }
    else {
      this.events = [...this.Allevents];
    }
  }

  onChipVenueClick(venueName) {

    if (this.selectedChipVenueName == venueName) {
      this.selectedChipVenueName = "";
      this.getDataByChipEventStatus(this.selectedChipEventStatus);
    }
    else if (venueName != "" && this.selectedChipEventStatus == null)
      this.getDataByChipVenue(venueName);
    else
      this.getDataByChip(venueName, this.selectedChipEventStatus);

    this.setServiceFilters();
  }


  getDataByChipEventStatus(chp) {

    if (chp != null) {
      this.selectedChipEventStatus = chp;
      this.events = this.Allevents.filter(res => {
        return !!(chp.chipObj as []).find(c => res.meta.EventStatusID === c['ID'])
      })
    }
    else {
      this.events = [...this.Allevents];
    }

  }

  onChipEventStatusClick(chp) {

    if (this.selectedChipEventStatus != null && this.selectedChipEventStatus.eventStatus.EventStatus == chp.eventStatus.EventStatus) {
      this.selectedChipEventStatus = null;
      this.getDataByChipVenue(this.selectedChipVenueName);
    }
    else if (chp != null && this.selectedChipVenueName == "")
      this.getDataByChipEventStatus(chp);
    else
      this.getDataByChip(this.selectedChipVenueName, chp);

    this.setServiceFilters();
  }

  getDataByChip(venueName, chp) {

    if (chp != null && venueName != "") {

      this.events = this.Allevents.filter(res => {
        return (!!(chp.chipObj as []).find(c => res.meta.EventStatusID === c['ID']) && res.meta.Venue == venueName)
      })

      this.selectedChipEventStatus = chp;
      this.selectedChipVenueName = venueName;
    }
    else if (chp != null && venueName == "") {
      this.getDataByChipEventStatus(chp);
    }
    else if (chp == null && venueName != "") {
      this.getDataByChipVenue(venueName);
    }


  }

  getSelectedStatusAndSetChips() {
    if (this.EventStatusID.length > 0) {
      var eventStatusArr = [];
      this.EventStatusID.forEach(element => {
        eventStatusArr.push(this.AutoCompStatus.data.filter(x => x.ID == element)[0])
      });

      this.setChips(eventStatusArr);
    }
    else {
      this.setChips(this.AutoCompStatus.data);
    }
  }

  onFilterClick(frame: ModalDirective) {
    this.getSelectedStatusAndSetChips();
    this.selectedChipEventStatus = null;
    this.selectedChipVenueName = "";
    this.GenerateCalendarData(this.viewDate);
    this.GetVenueMonthData(this.viewDate);
    this.setServiceFilters();
    frame.hide();
  }


  onFilterClear(frame: ModalDirective) {
    let shand = document.getElementsByClassName('contractstatus') as HTMLCollectionOf<HTMLElement>;
    this.setChips(this.AutoCompStatus.data);
    this.VenueID = [];
    this.EventStatusID = [];
    this.selectedClients = [];
    this.selectedChipEventStatus = null;
    this.selectedChipVenueName = "";
    this.GenerateCalendarData(this.viewDate);
    this.GetVenueMonthData(this.viewDate);
    this.setServiceFilters();
    frame.hide();
  }

  setServiceFilters() {
    this.calendarService.VenueID = this.VenueID;
    this.calendarService.EventStatusID = this.EventStatusID;
    this.calendarService.selectedClients = this.selectedClients;
    this.calendarService.viewDate = this.viewDate;
    this.calendarService.selectedChipEventStatus = this.selectedChipEventStatus;
    this.calendarService.selectedChipVenueName = this.selectedChipVenueName;

  }

  getServiceFilters(isFilterDate: boolean) {
    this.VenueID = this.calendarService.VenueID;
    this.EventStatusID = this.calendarService.EventStatusID;
    this.selectedClients = this.calendarService.selectedClients;
    this.selectedChipEventStatus = this.calendarService.selectedChipEventStatus;
    this.selectedChipVenueName = this.calendarService.selectedChipVenueName;

    if (isFilterDate == false)
      this.viewDate = this.calendarService.viewDate;
    else
      this.calendarService.viewDate = this.viewDate;

  }


  allEventStatusSelected = false;
  allVenueSelected = false;

  EventStatustoggleAllSelection() {
    this.allEventStatusSelected = !this.allEventStatusSelected;  // to control select-unselect

    if (this.allEventStatusSelected) {
      this.eventStatus_SkillSell.options.forEach((item: MatOption) => item.select());
    } else {
      this.eventStatus_SkillSell.options.forEach((item: MatOption) => { item.deselect() });
    }
    //this.skillSel.close();
  }

  VenuetoggleAllSelection() {
    this.allVenueSelected = !this.allVenueSelected;  // to control select-unselect

    if (this.allVenueSelected) {
      this.venue_SkillSell.options.forEach((item: MatOption) => item.select());
    } else {
      this.venue_SkillSell.options.forEach((item: MatOption) => { item.deselect() });
    }
    //this.skillSel.close();
  }






  openeventdashboard(eventID: number, selectedDate: any) {
    selectedDate = moment.parseZone(selectedDate).format('YYYY-MM-DD');
    // this.router.navigate(['/event/dashboard'], { queryParams: { EventID: eventID, calendarDate: selectedDate } })
    this.router.navigate(['/event/portal'], { queryParams: { ID: eventID} })
  }





  UnlockEvent(eventid: number) {
    this.events.filter(items => items.meta.EventID == eventid).forEach(eventData => eventData.meta.IsSecuredEvent = false);
  }

  DeletePerformance(SubeventID: number, eventid: number) {

    this.dialog.openConfirmDialog("Are you sure you want to permanently delete this record from system?").afterClosed().subscribe(res => {
      if (res) {

        this.ShowSpinner();
        var Id = SubeventID;
        if (this.IsNullOrUndefined(Id) == false) {
          var obj = {
            "EventID": eventid,
            "ID": Id,
            "ModifiedBy": LoginUser.loggedinUser.UserID
          }

          //this.genService.setData("Events/DeleteRowFromSubEvent", obj).subscribe(res => {
          this.genService.setData("api/EventOp/DeleteRowFromSubEvent", obj).subscribe(res => {
            this.HideSpinner();
            this.ShowToast("Successfully deleted!", ToastType.Success);
            this.GenerateCalendarData(this.viewDate);

          }, error => {
            this.HideSpinner();

            if (error.error.text) {
              if (String(error.error.text).indexOf("Error:") == 0) {
                this.messageDialog.openMessageDialog(error.error.text);
              }
              else {
                this.ShowToast(error.message, ToastType.Error);
              }
            }
            else
              this.ShowToast(error.message, ToastType.Error);
          });
        }
        else {
          // this.SetEventDaysAfterRowDeletedFromPerformance(rowIndex);
          //this.UpdateDaysPerformances();

        }
      }
    })
  }


  OpenCalendarNotesModal(venueID) {

    this.modalRef = this.modalService.show(CalendarNotesComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-xl modal-content modal-dialog cascading-modal modal-top',
      containerClass: '',
      animated: true,
      styles: "overflow-y: auto",
      data: {
        venueID: venueID,
        monthDate: this.viewDate,
        Venues: this.AutoCompVenue.data
      }
    });

    this.modalRef.content.action.subscribe((result: any) => {
      this.GenerateCalendarData(this.viewDate);
      this.GetVenueMonthData(this.viewDate);
    })

  }

  EditCalendarNotesModal(venueID, monthNotes) {

    this.modalRef = this.modalService.show(CalendarNotesComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-xl modal-content modal-dialog cascading-modal modal-top',
      containerClass: '',
      animated: true,
      styles: "overflow-y: auto",
      data: {
        venueID: venueID,
        monthDate: this.viewDate,
        Venues: this.AutoCompVenue.data,
        MonthNotes: monthNotes
      }
    });

    this.modalRef.content.action.subscribe((result: any) => {
      this.GenerateCalendarData(this.viewDate);
      this.GetVenueMonthData(this.viewDate);
    })

  }

  DeleteNote(ID) {

    if (ID != 0 && ID != null) {
      this.dialog.openConfirmDialog("Are you sure you want to delete this record?").afterClosed().subscribe(res => {
        if (res) {
          this.service.removeData(ID, "VenueMonthNotes", "Monthly Notes").toPromise().then(res => {
            this.ShowToast("Successfylly Deleted", ToastType.Success);
            this.GenerateCalendarData(this.viewDate);
            this.GetVenueMonthData(this.viewDate);
          });
        }
      })
    }
  }

  saveFilters() {

    this.common.ShowSpinner();

    //Create Object with Section Name Defined in RBAC_Section table SQL
    var objFilter = new FilterBL('Booking Calendar');

    // Getting Old Save filters in objFilter object
    objFilter.oldObj_confFilters = this.calendarService.confFilters;

    // Filling array fields ; separated
    objFilter.addFilterObjectArray('VenueID', this.VenueID);
    objFilter.addFilterObjectArray('EventStatusID', this.EventStatusID);
    objFilter.addFilterObjectArray('ClientID', this.selectedClients);

    this.getSelectedStatusAndSetChips();
    this.GetVenueMonthData(this.viewDate);

    this.GenerateCalendarData(this.viewDate);

    this.genService.bulkPostData("Conf_Filters", objFilter.obj_confFilters, "Calendar Filters").subscribe(res => {

      this.common.ShowToast("Saved Successfully!", ToastType.Success);

      this.getFilters().then(res => {
        this.setServiceFilters();
        this.common.HideSpinner()
      });

    }, error => {

      console.log(error)
      this.common.ShowToast(error, ToastType.Error);

    });

  }

  async getFilters() {

    await this.confGlobalService.getFilterDataByParameter('Booking Calendar').toPromise().then(res => {

      this.calendarService.confFilters = res['value'] as ConfFilters[];
      this.VenueID = [];
      this.EventStatusID = [];
      this.selectedClients = [];

      this.calendarService.confFilters.forEach(element => {
        if (element.FieldName == 'VenueID')
          String(element.DefaultValues).split(';').forEach(a => a == '' ? [] : this.VenueID.push(Number(a)));
        else if (element.FieldName == 'EventStatusID')
          String(element.DefaultValues).split(';').forEach(a => a == '' ? [] : this.EventStatusID.push(Number(a)));
        else if (element.FieldName == 'ClientID')
          String(element.DefaultValues).split(';').forEach(a => a == '' ? [] : this.selectedClients.push(Number(a)));
      });
    })
  }

  eventInfo: any = null;

  ShowDetails(event) {
    this.eventInfo = event;
  }
  ifEventExist:boolean=null;
  isSkip : boolean = null;
 async getEventsLength() {
    this.genService.GetDataByQuery("Events?$select=ID&$top=1").then(res => {
      var ev = res['value'] as Event[];
      if(ev.length > 0){
        this.ifEventExist = true;
      }
      else{
        this.ifEventExist = false;
        // this.openWelcomePage();
      }
    })
  }

 async  openWelcomePage(){
    const dialogRef = await this.matDialog.open(WelcomePageComponent, {
      disableClose: true ,
      // height: '100%',
      // maxHeight: '100%',
      // width: '100%',
      // minWidth: '60%',
       height:'285px',
      autoFocus:true,
      hasBackdrop:true,
      // maxWidth:'47vw',
      // position:{
      //   left:'50%'
      // }
      // panelClass:'welcome-dialog'
    })

    dialogRef.afterClosed().subscribe(res=>{
      if(res == "Submit"){
         this.onComponentInit();
         this.isSkip = false;
      }
      else if(res == "Skip"){
        this.isSkip = true;
      }
    })
  }

 async openQuickEventForm(date){
    // const dialogQuick = await this.matDialog.open(QuickEventComponent, {
    //   // disableClose: true ,
    //   height: '88%',
    //   maxHeight: '100%',
    //   width: '50%',
    //   minWidth: '52%',
    //   panelClass: 'dialog-panel',
    //   position: {
    //     right: 10 + '%',
    //     top: 2 + '%',
    //     left: 24 + '%',
    //     bottom: 0 + '%',
    //   },
    //   data:{
    //     date:date
    //   },
    //   autoFocus: false,
    // })

    //  dialogQuick.afterClosed().subscribe(res=>{
    //    if(res.status == "Submit"){
    //     this.onComponentInit();
    //    }
    //  })

    this.modalRef = this.modalService.show(QuickEventMdbComponent, {
      // backdrop: false,
      // keyboard: false,
      // focus: true,
      // show: false,
      // ignoreBackdropClick: false,
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class:  'modal-side modal-full-height modal-right modal-lg',
      containerClass: 'right',
      animated: true,
      styles: "overflow-y: none",
      data: {
        date: date
      }

    });

    this.modalRef.content.action.subscribe(result=>{
      if(result.status == "Submit"){
          this.onComponentInit();
          
      }
    })
  }
  showPop:boolean = false;

  forShowPop(date):boolean{
    // if(this.ifEventExist !=null && this.ifEventExist == false && moment(date).format('MM/DD/YYYY') == moment(new Date()).format('MM/DD/YYYY')&& this.isSkip == true){
    if( moment(date).format('MM/DD/YYYY') == moment(new Date()).format('MM/DD/YYYY')){

      this.showPop = true
      return true
    }
    else{
      return false;
    }
  }
  async onComponentInit(){
    this.ShowSpinner();
    //await this.getEventsLength();


       var IsFilterDate = false;
       this.route.queryParams
         .subscribe(params => {
           this.FilterDate = new Date(params['viewDate']) || new Date();
         });

       if (isNaN(this.FilterDate.getTime())) {
         this.FilterDate = new Date();
         this.viewDate = this.calendarService.viewDate;
       }
       else {
         this.viewDate = new Date(this.FilterDate);
         IsFilterDate = true;
         this.refresh = new Subject();
       }

       this.getGroupStatus();


       this.getFilters()
         .then(res => {

           if (this.calendarService.IsAppLoaded == false)
             this.setServiceFilters()
           else
             this.getServiceFilters(IsFilterDate);



           this.GetVenueMonthData(this.viewDate);

           this.getVenues();


           this.getStatus().then(res => {
             this.GenerateCalendarData(this.viewDate);
             this.getSelectedStatusAndSetChips();
           });


           this.getClients();

           this.calendarService.IsAppLoaded = true;
         })

  }

  isCalendar:boolean = true;
  isAvail:boolean = null;
  isList:boolean = null;
  // calendarGroup:string | null ="Calendar";
  onCalendarClick(value:any){
    if(value == 'Calendar'){
      this.isAvail = false;
      this.isList = false;
      this.isCalendar = true;
      // this.calendarGroup = "Calendar";
      
    }
    else if(value == 'Avail'){
      this.isCalendar = false;
      this.isAvail =true;
      this.isList =false;
      // this.calendarGroup = "Avail";
      
    }
    else if(value == 'List'){
      this.isCalendar = false;
      this.isAvail =false;
      this.isList =true;
      
    }
  }
  

}
