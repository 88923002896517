<!-- <h6>Welcome {{user.UserName}}</h6> -->

  <div  class="row pl-4 pt-2  mt-3" style="margin-right:0 !important;">
    <div  class="col-lg-6 col-md-6 col-sm-6 mt-3">
      <h5 style="margin: 0.375rem" class="mt-2"> Welcome {{user?.UserName}}</h5>
      <p style="margin: 0.375rem" class="mt-3"> Now experience the easiest and fastest venue booking and event management like never
        before </p>
      <div  class="text-start mt-5">
        <button  type="button" class="btn btn-success event-btn-desk" (click)="quickTour()">Start Quick Tour</button>
        <!-- <button  type="button" class="btn btn-success event-btn-desk" (click)="openQuickEventForm()">Add your First Event</button> -->
        <!-- <button  type="button" class="btn btn-success event-btn-mob" (click)="openQuickEventForm()">Add Event</button> -->
        <!-- <button  type="button" class="btn btn-light" (click)="openDummyData()">Dummy Data </button> -->
        <button  type="button" class="btn btn-light" (click)="onSkip()">Skip </button>
      </div>
    </div>
    <div  class="col-lg-6 col-md-6 col-sm-6 event-img">
      <img  src="../../../../assets/event.jpg" height="200px" width="350px" alt="">
    </div>
  </div>


<!-- <div class="container-fluid p-4 mt-3">

  <h5 class="">
    Welcome {{user.UserName}}
  </h5>
  <p class="">
    Now experience the easiest and fastest venue booking and event management like never
    before
  </p>
  <div class="text-start">
    <button type="button" class="btn btn-success" (click)="openQuickEventForm()">Add your First Event</button>
  </div>
</div> -->

<ngx-guided-tour ></ngx-guided-tour>