import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { da, fi } from 'date-fns/locale';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { Autocomplete } from 'src/app/Base/Autocomplete';
import { CommonService } from 'src/app/Base/Common.service';
import { UserModuleAccess } from 'src/app/Base/models/user-module-access';
import { LoginUser } from 'src/app/Base/User/login-user';
import { ToastType } from 'src/app/Enum/ToastType';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { Customer } from 'src/app/models/customer.model';
import { Venue } from 'src/app/models/venue.model';
import { RBACGroups } from 'src/app/rbac/rbac-groups';
import { RbacUsers } from 'src/app/rbac/rbac-users.model';
import { CustomersService } from 'src/app/services/customers.service';
import { EventDocumentService } from 'src/app/services/event-document.service';
import { GeneralService } from 'src/app/services/general.service';
import { GenericService } from 'src/app/services/generic.service';
import { UserManagerService } from 'src/app/services/user-manager.service';
import { QuickEventComponent } from '../../event/quick-event/quick-event.component';
import { BoardingAddUserComponent } from '../boarding-add-user/boarding-add-user.component';
import * as Countries from '../../../../assets/countries.json';
import { Currency } from 'src/app/models/currency.model';
import { VenueService } from 'src/app/setupForms/venue/venue.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-on-boarding-user',
  templateUrl: './on-boarding-user.component.html',
  styleUrls: ['./on-boarding-user.component.scss']
})
export class OnBoardingUserComponent implements OnInit {
  radioModel="Left";
  @ViewChild('stepper') private myStepper: MatStepper
  formDataOrg: Customer = new Customer();
  formDataVen: Venue = new Venue();
  formDataUser: RbacUsers = new RbacUsers();
  imageSrc: any = null;
  selectedimg: any = null;
  borderForm: string = "Company Information";
  public modalRef: MDBModalRef;
  inProgress: boolean = null;
  stepperHandling: any[] = [
    { ID: 1, name: "Company Information", icon: "", formName: "organization", required: false },
    { ID: 2, name: "Venue Information", icon: "", formName: "Venue", required: false },
    // { ID: 3, name: "User", icon: "", formName: "User", required: true },
    { ID: 4, name: "Done", icon: "", formName: "", required: true }
  ]
  isVenueExists:boolean =false;

  AutoCompCurrency:Autocomplete<Currency> = new Autocomplete<Currency>("CurrencyName","ID");
  constructor(
    public genService: GeneralService,
    public service: GenericService,
    public modalService: MDBModalService,
    public commonService: CommonService,
    public customersService: CustomersService,
    public userManagerService: UserManagerService,
    public router: Router,
    public authenticationService: AuthenticationService,
    public venueService: VenueService,
    public dialog: MatDialog,
    public eventDocumentService: EventDocumentService,
    private permissionService:PermissionService,  
    public cdr?: ChangeDetectorRef


  ) { }

  ngOnInit() {
    this.FillCountry(this.AutoCompCntry);
    this.FillAutoComplete("Currencies",this.AutoCompCurrency);
    this.getCustomer();
  }

 async getCustomer() {
    this.commonService.ShowSpinner();
   await this.customersService.getMyCustomer().toPromise().then(res => {

      var data = res['value'] as any[];
      if (data.length > 0) {
        this.formDataOrg = data[0] as Customer;
        this.cdr.detectChanges();
        this.getSubmittedPhoneMask(data[0].CountryName);
        // this.formDataOrg = this.currentCustomer;
        this.getCurrUser();
        this.commonService.HideSpinner();
      }

    },error=>{
      this.commonService.HideSpinner();
      this.commonService.ShowToast(error.message,ToastType.Error);
    }
    )
  }

  async getVenue(){
    this.commonService.ShowSpinner()
    await this.venueService.getVenueDetail().then(res=>{
      var data = res['value'] as any[];
      
      if(data.length > 0){
        this.isVenueExists = true;
        this.formDataVen = data[0] as Venue;
      }

      this.commonService.HideSpinner();

    },error=>{
      this.commonService.HideSpinner();
      this.commonService.ShowToast(error.message,ToastType.Error);
    }
    )
  }
  stepperClick(action: string, form?: NgForm) {
    if (form) {
      if (!form.invalid) {
        if (action == "next") {
          if (this.myStepper.selectedIndex == 0) {
            this.borderForm = "Venue Information";
          }
          else if (this.myStepper.selectedIndex == 1 || this.myStepper.selectedIndex == 2) {
            this.borderForm = "User-Done";
          }
          else {
            this.borderForm = "Company Information";
          }
          this.myStepper.next()
        }
        else if (action == "back") {
          if (this.myStepper.selectedIndex == 1) {
            this.borderForm = "Company Information";
          }
          this.myStepper.previous()
        }
      }
    }
    else {
      if (action == "next") {
        if (this.myStepper.selectedIndex == 1 || this.myStepper.selectedIndex == 2) {
          this.borderForm = "User-Done";
        }
        this.myStepper.next();
      }
      else if (action == "back") {
        if (this.myStepper.selectedIndex == 3) {
          this.borderForm = "User-Done";
        }
        else if (this.myStepper.selectedIndex == 2) {
          this.borderForm = "Venue Information";
        }
        this.myStepper.previous();
      }
    }

  }

  addUser() {
    this.modalRef = this.modalService.show(BoardingAddUserComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-xl modal-content modal-dialog cascading-modal modal-top',
      containerClass: '',
      animated: true,
      styles: "overflow-y: auto",
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result != null) {
        this.formDataUser = result;
        this.myStepper.next();
      }
    });
  }

  openQuickEventForm() {
    const dialogRef = this.dialog.open(QuickEventComponent, {
      // disableClose: true ,
      height: '70%',
      maxHeight: '100%',
      width: '100%',
      minWidth: '60%',
      panelClass: 'dialog-panel',
      position: {
        right: 10 + '%',
        top: 5 + '%',
        left: 10 + '%',
        bottom: 0 + '%',
      },
      autoFocus: false,
    })

  }

  OnSubmit() {
    this.inProgress = true;
    this.customersService.update(this.formDataOrg.ID, this.formDataOrg).toPromise().then(res => {
      this.stepperHandling[0].icon = "check-circle";
      if (this.Files.length > 0) {
        this.uploadImage();
      }

      if (this.isVenueExists == true) {
        // For Update Venue
        this.service.updateData(this.formDataVen.ID,this.formDataVen,  this.formDataVen["CreatedBy"], this.formDataVen["CreatedDate"], "Venues", "Venue").toPromise().then(res => {
          this.stepperHandling[1].icon = "check-circle";

          // For Save User
          if (this.formDataUser.FirstName != null && this.formDataUser.LastName != null && this.formDataUser.Email != null && this.formDataUser.RBAC_Users_Groups.length > 0) {
            this.service.postData(this.formDataUser, "RBAC_Users", "Users").toPromise().then(res => {
              this.stepperHandling[2].icon = "check-circle";
              this.stepperHandling[3].icon = "check-circle";
              this.inProgress = false;

              localStorage.removeItem("onboardingstatus");
              this.router.navigate(['/calendar']);
            }, error => {
              this.commonService.ShowToast(error.message, ToastType.Error);
              this.inProgress = false;
            })

          }
          else {

            this.inProgress = false;
            localStorage.removeItem("onboardingstatus");
            this.router.navigate(['/calendar']);
          }
        }, error => {
          this.commonService.ShowToast(error.message, ToastType.Error);
          this.inProgress = false;
        })
      }
      else {
        // For Save Venue
        this.service.postData(this.formDataVen, "Venues", "Venue").toPromise().then(res => {
          this.stepperHandling[1].icon = "check-circle";

          // For Save User
          if (this.formDataUser.FirstName != null && this.formDataUser.LastName != null && this.formDataUser.Email != null && this.formDataUser.RBAC_Users_Groups.length > 0) {
            this.service.postData(this.formDataUser, "RBAC_Users", "Users").toPromise().then(res => {
              this.stepperHandling[2].icon = "check-circle";
              this.stepperHandling[3].icon = "check-circle";
              this.inProgress = false;

              localStorage.removeItem("onboardingstatus");
              this.router.navigate(['/calendar']);
            }, error => {
              this.commonService.ShowToast(error.message, ToastType.Error);
              this.inProgress = false;
            })

          }
          else {

            this.inProgress = false;
            localStorage.removeItem("onboardingstatus");
            this.router.navigate(['/calendar']);
          }
        }, error => {
          this.commonService.ShowToast(error.message, ToastType.Error);
          this.inProgress = false;
        })
      }

    }, error => {
      this.commonService.ShowToast(error.message, ToastType.Error);
      this.inProgress = false;
    })
  }


  removeImg() {
    this.imageSrc = null;
    this.selectedimg = null;
    this.Files = [];
  }

  async getCurrUser() {

    this.commonService.ShowSpinner();

    if (LoginUser.loggedinUser.UserID != null) {
      this.commonService.HideSpinner();
      return;
    }

    var currUser = this.authenticationService.CurrentUser();
    if (!currUser) {
      this.router.navigate(["/unauthorized"]);
    }
    //User code
    var email = ''
    if (currUser.email.includes('+'))
      email = currUser.email.replace('+', '%2B');
    else
      email = currUser.email;

    await this.userManagerService.getCustomer(currUser.Id, email).then(res => {
      var user = res['dataSet']['value'][0];
      if (user.isowner != 1) {
        if (res['dataSet'] == null || res['dataSet'] == undefined) {
          this.router.navigate(["/requestaccess"]);
          return;
        }

        if (res['dataSet']['value'] == null || res['dataSet']['value'] == undefined) {
          this.router.navigate(["/requestaccess"]);
          return;
        }
      }


      var customer = res['customer'];
      var productInfo = res['productInfo'];
      if (productInfo['SubscriptionType'] == "stripe") {
        if (productInfo.ProductName == "" || productInfo.ProductName == null) {
          this.router.navigate(["/membership/subscribe"]);
          return;
        }
      }


      LoginUser.loggedinUser.UserID = user.UserID;
      LoginUser.loggedinUser.UserName = user.FullName;
      LoginUser.loggedinUser.Email = user.Email;
      LoginUser.loggedinUser.ShortCode = this.getShortCode(user.FullName);
      LoginUser.loggedinUser.OrganisationName = customer.OrganisationName;
      LoginUser.loggedinUser.Subscription = productInfo.ProductName;
      LoginUser.loggedinUser.SubscriptionType = productInfo.SubscriptionType;
      currUser['subscription'] = productInfo.ProductName;
      currUser['isowner'] = user.isowner;
      this.authenticationService.login(currUser);
      //LoginUser.IsAdmin = user.IsAdmin;


      // Loading Section Data
      LoginUser.getSectionAccess = res['dataSet']['Table2'];
      var data = res['dataSet']['value'] as any[];
      var Inactive = data[0]["Inactive"];
      if (Inactive == true) {
        this.router.navigate(["/unauthorized"]);
      }

      /*
      if (data.length > 0 && data[0]["IsAdmin"] == true){
        LoginUser.IsAdmin = true;
        if(customer.OnBoardingStatus == 'signup')
          this.router.navigate(["/onboarding"]);
        // else
        //   this.router.navigate(["/calendar"]);
        resolve(true);
      }
      else */
      {
        // 06/09/2022
        //LoginUser.IsAdmin = true;
        LoginUser.IsAdmin = user.IsAdmin;
        this.permissionService.getActionData();

        // Getting User access data
        var rbacUM = res['dataSet']['Table1'] as UserModuleAccess[];
        var mAccess = rbacUM.filter(function (x) {
          return x.IsVisible == true
        })

        if (mAccess.length == 0) {

        }
        else {
          LoginUser.getModulesAccess = rbacUM;
          //resolve(true);
        }

        this.commonService.HideSpinner();
        return;

      }
    }, error => {

    })
  }

  getShortCode(fullName: string): string {
    var shortcode: string = '';
    if (fullName) {
      var arr = fullName.split(' ');
      if (arr.length > 0) {
        if (arr.length > 1) {
          shortcode = arr[0].substring(0, 1);
          shortcode += arr[1].substring(0, 1);
        }
        else {
          shortcode = arr[0].substring(0, 1);
        }
      }
    }

    return shortcode;
  }

  validate(): boolean {
    const websiteURlExpression = /^(https?:\/\/)?[a-z0-9\-]+(\.[a-z0-9\-]+)*\.[a-z]{2,6}$/i;
    
    if (
      this.formDataOrg.OrganisationName == null ||
      this.formDataOrg.Phone == null ||
      this.formDataOrg.Website == null ||
      this.formDataVen.VenueName == null ||
      this.formDataVen.ShortCode == null ||
      this.formDataOrg.OrganisationName == '' ||
      this.formDataOrg.Phone == '' ||
      this.formDataOrg.Website == '' ||
      this.formDataVen.VenueName == '' ||
      this.formDataVen.ShortCode == '' ||
      !this.formDataOrg.Website.match(websiteURlExpression) 
      ) {
      // (this.formDataOrg.Phone !== null && this.formDataOrg.Phone.invalid)
      return true
    }
    else {
      // this.submitBackground =  "linear-gradient(45deg, #145b96, #0dbbbf) !important";
      return false;
    }

  }

  onStepClick(event: any) {
    if (event.selectedIndex == 0) {
      this.borderForm = "Company Information";
    }
    else if (event.selectedIndex == 1) {
      this.borderForm = "Venue Information";
    }
    else if (event.selectedIndex == 2 || event.selectedIndex == 3) {
      this.borderForm = "User-Done";
    }

  }

  Files : any[]=[];
  folderName:string = "system/logo";
  action: Subject<any[]> = new Subject<any[]>();

  readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      this.selectedimg = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result;
      reader.readAsDataURL(this.selectedimg);

      this.pushFiles(event.target.files);
    }
  }

  pushFiles(file:FileList[]){
    debugger
    this.Files.push({'files':file})
  }


  uploadImage(){
    let formData = new FormData();
    let promises = [];
    for (let index = 0; index < this.Files.length; index++) {
      const element = this.Files[index];

      if(element.files.length > 0){
        const f = element.files[0];

        if (f.IsSelected != undefined && f.IsSelected == false)
          continue;

        formData = new FormData();
        formData.append('', f);
        promises.push(this.eventDocumentService.post(formData, this.folderName).toPromise())
      }
    }

    Promise.all(promises).then(res => {
      console.log('All files uploaded.');
      console.log('Uploading completed. ', new Date());
      // this.commonService.ShowToast("Attachment Uploaded Successfully", ToastType.Success);
      // this.commonService.HideSpinner();
      this.action.next(this.Files);
    },
      error => {
        console.error(error);
        console.log(error);
        console.log("settlementFolderName");
        console.log(this.Files);
        this.commonService.HideSpinner();
        // this.messageDialog.openMessageDialog("Some thing wrong. Documents are not uploaded. Please contact to Dev team.");
      })
  }
  AutoCompCntry: Autocomplete<any> = new Autocomplete<any>("name", "name");
  searchUserLead = null;
  countries: any[] = [];

  selectedMask = "";
  selectedCntryImg: any = null;
  selectedCntryCode: any = null;

  async FillCountry(Autocomplete: Autocomplete<any>) {
    this.countries = Countries['default']
    Autocomplete.data = null;
    Autocomplete.data = Countries['default'];
    Autocomplete.resultObserve();
  }

  getMaskbyCountryName(option) {
    debugger
    this.selectedMask = "";
    this.selectedCntryImg = null;
    this.formDataOrg.CountryCode = null;
    var selectedOption = this.countries.filter(a => a.name == option) as any[];
    if (selectedOption.length > 0) {
      var replVal = /#/gi;
      var typeOfMask = typeof selectedOption[0].mask;
      if (typeOfMask == "object") {
        this.selectedMask = selectedOption[0].mask[0]?.replace(replVal, "0");
        // this.selectedCntryCode = selectedOption[0].code != null ? selectedOption[0].code : '';
        this.selectedCntryImg = selectedOption[0].flag != null ? selectedOption[0].flag : '';
        this.formDataOrg.CountryCode = selectedOption[0].code != null ? selectedOption[0].code : '';

      }
      else {

        this.selectedMask = selectedOption[0].mask?.replace(replVal, "0");
        // this.selectedCntryCode = selectedOption[0].code != null ? selectedOption[0].code : '';
        this.formDataOrg.CountryCode = selectedOption[0].code != null ? selectedOption[0].code : '';
        this.selectedCntryImg = selectedOption[0].flag != null ? selectedOption[0].flag : '';
      }
    }

    this.cdr.detectChanges();
    this.formDataOrg.Phone = "";
  }

  async getSubmittedPhoneMask(option) {
    // this.formData.Phone= null;
    var selectedCountry = this.countries.filter(res => res.name == option) as any[];

    if (selectedCountry.length > 0) {
      var replVal = /#/gi;
      var typeOfMask = typeof selectedCountry[0].mask;
      if (typeOfMask == "object") {
        this.selectedMask = selectedCountry[0].mask[0]?.replace(replVal, "0");
        this.selectedCntryImg = selectedCountry[0].flag != null ? selectedCountry[0].flag : '';
      }
      else {
        this.selectedMask = selectedCountry[0].mask?.replace(replVal, "0");
        this.selectedCntryImg = selectedCountry[0].flag != null ? selectedCountry[0].flag : '';
      }
    }
  }

 async suggestedImageClick(url:any){
  this.Files = [];
    var res = await fetch(url);
    var blob = await res.blob();
    var file:FileList[] = [];
    return new Promise((resolve, reject) => {
      this.selectedimg = blob;
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.onload = e => this.imageSrc = reader.result;
      reader.readAsDataURL(this.selectedimg);
      file.push(this.selectedimg);
      this.pushFiles(file);
    })

  }

  async FillAutoComplete(query: string, Autocomplete: Autocomplete<any>) {
    // this.ShowSpinner();
    await this.genService.GetDataByQuery(query).then(
      res => {
        Autocomplete.data = null;
        Autocomplete.data = res['value'];
        Autocomplete.resultObserve();
        // this.HideSpinner();
      }
      , error => {
        this.commonService.HideSpinner();
        this.commonService.ShowToast(error.message, ToastType.Error);
      }
    )

  }
}


export class organization {
  name: string = null;
  website: string = null;
  logo: any = null;
  phone: string = null;
  employee: string = null;
}

