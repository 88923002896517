<div class="modal-header" style="border-bottom: none;">
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">x</span>
  </button>
  <h5 class="modal-title w-100" id="myModalLabel">{{Title}}</h5>
</div>
<div class="modal-body scrollBar " style="overflow:auto">

  <mat-form-field class="example-full-width">
    <input matInput placeholder="Search by Event ID or Name..."  [formControl]="searchMoviesCtrl">
    <button mat-button *ngIf="searchMoviesCtrl.value" matSuffix mat-icon-button aria-label="Clear"
      (click)="searchMoviesCtrl.setValue('')">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <div class="d-flex" *ngIf="isLoading">
    <span
    class="spinner-border spinner-border-sm mr-2"></span>Searching...
  </div>
  <div class="d-flex" *ngIf="EventData.length == 0 && !isLoading && isLoading !=null">
    Not Found
  </div>
  <ng-container *ngIf="EventData.length > 0 && !isLoading" >
    <div  class="result">
      Results Found: {{EventData.length}}
    </div>
    <div *ngFor="let event of EventData" class="event-data" (click)="onEventClick(event)">
      <b style="font-weight:500 !important;">{{event.EventName}}</b>
      <P style=" font-size: 13px;font-weight: 400;margin-top:5px" class="mb-0">
        <span class="d-flex justify-content-between">
          <span>Event ID: </span>
          <span>{{event.ID}}</span>
        </span>
        <span class="d-flex justify-content-between">
          <span>Start Date: </span>
          <span>{{common.BLFormatDate(event.EventStartDate)}}</span>
        </span>
        <span class="d-flex justify-content-between">
          <span>End Date: </span>
          <span>{{common.BLFormatDate(event.EventStartDate)}}</span>
        </span>
        <span class="d-flex justify-content-between">
          <span>Venue: </span>
          <span>{{event.VenueName}}</span>
        </span>
      </P>
    </div>
  </ng-container>
</div>
