import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';


import { RBAC_User } from 'src/app/models/rbac_user.model';
import { Observable } from 'rxjs';
import { TokenService } from 'src/app/services/token.service';
import { GeneralService } from 'src/app/services/general.service';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Customer } from 'src/app/models/customer.model';
import { CustomersService } from 'src/app/services/customers.service';
import { CommonService } from 'src/app/Base/Common.service';
import { ToastType } from 'src/app/Enum/ToastType';
import * as customConfig from 'src/app/customConfig.json';

import { TermsAndConditionsComponent } from 'src/app/modules/main/terms-and-conditions/terms-and-conditions.component';
import { MDBModalService, MDBModalRef } from 'ng-uikit-pro-standard';
import { MessageDialogeService } from 'src/app/message-dialoge/message-dialoge.service';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import * as Countries from '../../../../assets/countries.json';
import { Autocomplete } from 'src/app/Base/Autocomplete';
@Component({
  selector: 'app-register-customer',
  templateUrl: './register-customer.component.html',
  styleUrls: ['./register-customer.component.scss']
})
export class RegisterCustomerComponent implements OnInit {

  obj: Customer;
  repeatPassword: string = "";
  hide_password = true;
  hide_repeat_password = true;
  loginDisplay: number;
  searchUserLead = null;
  checkedTermsConditions: boolean = false;
  public modalRef: MDBModalRef;
  countries: any[] = [];
  AutoCompCntry: Autocomplete<any> = new Autocomplete<any>("name", "name");

  @Output() onSave: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public generalService: GeneralService,
    public tokenService: TokenService,   
    private authenticationService: AuthenticationService,
   
    public customersService: CustomersService,
    public common: CommonService,
    public modalService: MDBModalService,
    public messageDialog: MessageDialogeService,
    public cdr: ChangeDetectorRef

  ) {
    this.obj = new Customer();
  }

  ngOnInit(): void {
    this.FillAutoComplete(this.AutoCompCntry);
  }
  
  validate() {
    if (this.obj.FirstName == "" || this.obj.FirstName == null)
      return true;

    if (this.obj.LastName == "" || this.obj.LastName == null)
      return true;

    if (this.obj.Email == "" || this.obj.Email == null)
      return true;

    if (this.otherError() == true)
      return true;

    return false;
  }

  errorMessage: string = "";
  otherError() {
    return false;
  }

  IsshowProgress: boolean = false;
  IsSaved: boolean = false;

  Save() {

    this.common.ShowSpinner();
    this.IsshowProgress = true;
    // setInterval(() => {
    //   this.IsshowProgress =false;
    //   this.IsSaved = true;
    // }, 5000);

    this.obj.tid = customConfig.tenantId;
    // if (this.authenticationService.CurrentUser() && this.authenticationService.CurrentUser().tid)
    //     this.obj.tid = this.authenticationService.CurrentUser().tid;


    this.customersService.post(this.obj).subscribe(res => {
      
      this.IsshowProgress = false;
      this.IsSaved = true;
      this.common.HideSpinner();
      this.onSave.emit(this.IsSaved);
      
    }, error => {
      this.IsshowProgress = false;
      this.IsSaved = true;
      console.log(error);
      this.common.HideSpinner();
      if (error.error.text) {
        if (String(error.error.text).indexOf("Error:") == 0) {
          this.messageDialog.openMessageDialog(error.error.text);
        }
        else {
          this.messageDialog.openMessageDialog("Signup not succeeded. Please send an email to contact@venuearc.com.");
        }
      }
      else
        this.messageDialog.openMessageDialog("Signup not succeeded. Please send an email to contact@venuearc.com.");


      //this.messageDialog.openMessageDialog("Signup not succeeded. Please send an email to contact@venuearc.com.");
    }); // Inserting Data
  }

  login() {
    //this.authService.loginRedirect();
    this.authenticationService.redirectToLogin();
  }

  logout() {
   // this.authService.logout();
  }

  setLoginDisplay() {
   // this.loginDisplay = this.authService.getAllAccounts().length;
  }

  ngOnDestroy(): void {
  }

  // openTermsAndConditions(){
  //   console.log(this.checkedTermsConditions);
  //   this.modalRef = this.modalService.show(TermsAndConditionsComponent, {
  //     backdrop: true,
  //     keyboard: true,
  //     focus: true,
  //     show: false,
  //     ignoreBackdropClick: true,
  //     class: 'modal-lg modal-content modal-dialog cascading-modal modal-top',
  //     containerClass: '',
  //     animated: true,
  //     styles: "overflow-y: auto",
  //   });
  // }

  openTermsAndConditions() {
    console.log(this.checkedTermsConditions);
    this.modalRef = this.modalService.show(TermsAndConditionsComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-lg modal-content modal-dialog cascading-modal modal-top',
      containerClass: '',
      animated: true,
      styles: "overflow-y: auto",
    });

    this.modalRef.content.action.subscribe((result: any) => {
      if (result == true) {
        this.Save();
      }
    });
  }

  async FillAutoComplete(Autocomplete: Autocomplete<any>) {
    this.countries = Countries['default']
    Autocomplete.data = null;
    Autocomplete.data = Countries['default'];
    Autocomplete.resultObserve();
  }

  selectedMask = "";
  selectedCntryImg: any = null;
  selectedCntryCode: any = null;
  getMaskbyCountryName(option) {
    this.selectedMask = "";
    this.selectedCntryImg = null;
    this.obj.CountryCode = null;
    var selectedOption = this.countries.filter(a => a.name == option) as any[];
    if (selectedOption.length > 0) {
      var replVal = /#/gi;
      var typeOfMask = typeof selectedOption[0].mask;
      if (typeOfMask == "object") {
        this.selectedMask = selectedOption[0].mask[0]?.replace(replVal, "0");
        this.selectedCntryCode = selectedOption[0].code != null ? selectedOption[0].code : '';
        this.selectedCntryImg = selectedOption[0].flag != null ? selectedOption[0].flag : '';
        this.obj.CountryCode = selectedOption[0].code != null ? selectedOption[0].code : '';

      }
      else {

        this.selectedMask = selectedOption[0].mask?.replace(replVal, "0");
        this.selectedCntryCode = selectedOption[0].code != null ? selectedOption[0].code : '';
        this.obj.CountryCode = selectedOption[0].code != null ? selectedOption[0].code : '';
        this.selectedCntryImg = selectedOption[0].flag != null ? selectedOption[0].flag : '';
      }
    }

    this.cdr.detectChanges();
    this.obj.Phone = "";
  }
}
