import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { themeService } from '../services/theme.service';

@Component({
  selector: 'app-confirm-replace-dialoge',
  templateUrl: './confirm-replace-dialoge.component.html',
  styleUrls: ['./confirm-replace-dialoge.component.scss']
})
export class ConfirmReplaceDialogeComponent implements OnInit {

  selectall:boolean = false;
  fileData:any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef:MatDialogRef<ConfirmReplaceDialogeComponent>,
    public theme:themeService
    ) { }

  ngOnInit(): void {
    if (this.data.files) {
      this.data.files.forEach(element => {
        var f =  element;
        f.IsSelected = false;
        this.fileData.push(f);
      });
    }
  }

  ngAfterViewInit(){
    
  }

  closeDialoge(){
    this.fileData.forEach(element => {
      element.IsSelected = false;
    });
    this.dialogRef.close(this.fileData);
  }

  replaceAll(){
    this.dialogRef.close(this.fileData);
  }  

  onSelectAllChange(obj){
    this.fileData.forEach(element => {
        element.IsSelected = obj.checked;
    });
  }


}