import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { empty, ReplaySubject } from 'rxjs';
import { catchError, debounceTime, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { CommonService } from 'src/app/Base/Common.service';
import { EventNotesService } from 'src/app/modules/event/event-notes/event-notes.service';
import { GeneralService } from 'src/app/services/general.service';
import { themeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-dashboard-popup',
  templateUrl: './dashboard-popup.component.html',
  styleUrls: ['./dashboard-popup.component.scss']
})
export class DashboardPopupComponent implements OnInit {
  Title:String=null;
  formName:string = null;
  heading:string = null;
  actionName:string = null;
  spFormname:string=null;
  searchMoviesCtrl = new FormControl();
  EventData: any[] = [];
  isLoading = null;
  errorMsg: string;

  constructor(
    public genService:GeneralService,
    public modalRef: MDBModalRef,
    public theme:themeService,
    public common:CommonService,
    public route:Router,
    public eventNoteService:EventNotesService,
    public modalService:MDBModalService
  ) { }


  ngOnInit(): void {
    this.genService.GetConfigDataWithBodyQuery("EXEC SP_GetEventByForms  '" +this.spFormname.toLowerCase()+"' , '" +this.actionName.toLowerCase() +"' , ''" ).toPromise().then(res=>{
      this.EventData = res['value'];
    })
    this.theme.getCustomerTheme();
    this.setFormTitle();
    this.searchMoviesCtrl.setValue('')
    this.searchMoviesCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.EventData = [];
          this.isLoading = true;
        }),
        switchMap((value) => this.genService.GetConfigDataWithBodyQuery("EXEC SP_GetEventByForms  '" +this.spFormname.toLowerCase()+"' , '" +this.actionName.toLowerCase() +"' , '" +value + "'" )
          .pipe(
            catchError(error =>{
              console.log(error);
              return empty;
            }),
            finalize(() => {

              this.isLoading = false
            }),
          )
        )
      )
      .subscribe(data => {
        if (data['value'] == undefined) {
          this.errorMsg = data['Error'];
          this.EventData = [];
        } else {
          this.errorMsg = "";
          this.EventData = data['value'];
        }
      },
      (error)=>{
        console.log(error);
      }
      );
  }

  ngAfterViewInit(){
    this.theme.getCustomerTheme();
  }

  setFormTitle(){
    this.Title = this.heading + " / " + this.actionName;
  }

  onEventClick(data:any){
    this.modalRef.hide();
    if(this.actionName.toLowerCase() == 'add' || this.actionName.toLowerCase() == 'view'){
      this.onAddClick(data);
    }
    else if(this.actionName.toLowerCase() == 'edit'){
      this.onEditClick(data);
    }
  }

  onAddClick(event:any){

    var eventID:number = event.ID;
    var date = this.common.BLFormatDate(event.EventStartDate);

    if(this.formName == 'settlement'){

      this.route.navigate(['/'+this.formName+'/form'],{queryParams: { EventID: eventID , calendarDate: date}});
    }
    else if(this.formName == 'Notes'){
      this.eventNoteService.OpenNotesModal(eventID,this.modalService,this.modalRef)
    }
    else{
      this.route.navigate(['/event/'+this.formName+'/form'],{queryParams: { EventID: eventID , calendarDate: date}});
    }
  }

  onEditClick(event:any){

    var eventID:number = event.ID;
    var date = this.common.BLFormatDate(event.EventStartDate);

     if(this.formName == 'settlement'){

      this.route.navigate(['/settlement/list'], { queryParams: { EventID: eventID } })
     }
     else if(this.formName == 'Notes'){
       this.eventNoteService.OpenNotesModal(eventID,this.modalService,this.modalRef);
     }
     else if(this.formName == 'eventcontracts'){
      this.route.navigate(['/event/'+this.formName+'/form'],{queryParams: { EventID: eventID , ID: event.EventContractID, calendarDate: date}});
     }
     else if(this.formName == 'eventdueamount'){
      this.route.navigate(['/event/'+this.formName+'/form'],{queryParams: { EventID: eventID , ID: event.DepsitScheduleID, calendarDate: date}});
     }
     else if(this.formName == 'clientpayments'){
      this.route.navigate(['/event/'+this.formName+'/form'],{queryParams: { EventID: eventID , ID: event.FinancialsID, calendarDate: date}});
     }
     else if(this.formName == 'eventdocuments'){
      this.route.navigate(['/event/'+this.formName+'/form'],{queryParams: { EventID: eventID , ID: 0, calendarDate: date}});
     }
     else if(this.formName == 'event-reschedule'){
      this.route.navigate(['/event/'+this.formName+'/form'],{queryParams: { ID: eventID , calendarDate: date}});
     }
  }
}
