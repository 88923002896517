import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import * as customConfig from 'src/app/customConfig.json';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { CustomersService } from 'src/app/services/customers.service';
import { TeamsAppService } from 'src/app/services/teams-app.service';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-request-subscription',
  templateUrl: './request-subscription.component.html',
  styleUrls: ['./request-subscription.component.scss']
})
export class RequestSubscriptionComponent implements OnInit {

  marketPlace = customConfig.marketplacelink;
  appSource = customConfig.appsourcelink;
  constructor(
    public tokenService:TokenService,
    //private authService: MsalService,
    private authenticationService :AuthenticationService,
    public customersService:CustomersService,    
    private authService: MsalService,
    public teams:TeamsAppService
  ) { }

  ngOnInit(): void {
  }

  subscribeMP(){
    window.location.href =  customConfig.marketplacelink;
  }

  subscribeAS(){
    window.location.href =  customConfig.appsourcelink;
  }

 
  logout() {
    this.authenticationService.logout();
    return;
    this.tokenService.removeToken();

    this.authService.logoutRedirect({
      postLogoutRedirectUri: customConfig.callbackPathBase //'http://localhost:4200'
    });
  }


}
