import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//import { AuthenticateService } from 'src/app/services/base/authenticate.service';
//import { ThemeService } from 'src/app/services/base/theme.service';

//import { BaseService } from 'src/app/services/base/base.service';
import { TokenService } from 'src/app/services/token.service';
import { GeneralService } from 'src/app/services/general.service';
import { LoginUser } from 'src/app/Base/User/login-user';
import * as customConfig from 'src/app/customConfig.json';
import { UserModuleAccess } from 'src/app/Base/models/user-module-access';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  userId:string = "";
  password:string = "";
  hide = true;
  constructor(
    public router: Router, 
    public genService:GeneralService,        
    public tokenService:TokenService,    
    ) { }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

  errorMessage: string = "";

  openLink(){
    
    // #region
        
        this.genService.Authenticate(this.userId, this.password).then(res => {
        var data = res['body']['value'] as any[];
        // Sameer : 03/09/2022        
        LoginUser.loggedinUser.UserID = data[0]['UserID'];
        LoginUser.loggedinUser.UserName = data[0]['FullName'];
        LoginUser.loggedinUser.Email = data[0]['Email'];  
        LoginUser.loggedinUser.ShortCode = String(data[0]['ShortCode']).toUpperCase();  
        document.getElementById("authError").style.display = "none";
        this.tokenService.setToken(res['headers'].get('Token'))
        
       // LoginUser.IsAdmin = true;    
        this.router.navigate(['/calendar']);          
        //End Sameer : 03/09/2022

          //document.getElementById("authError").style.display = "none";          
          //localStorage.setItem( customConfig.tokenID ,res['headers'].get('Token'));         
          if (res['body']['value'] != null) {
            
            // Loading Section Data
            LoginUser.getSectionAccess = res['body']['Table2'] ;
                       
            var Inactive = data[0]["Inactive"];
            if(Inactive == true)
            {              
                alert("You don't have access. Contact to administrator.")
            }

            if (data.length > 0 && data[0]["IsAdmin"] == true){
              //LoginUser.IsAdmin = true;
              //resolve(true);          
            }
            else 
            {
              LoginUser.IsAdmin = data[0]["IsAdmin"];    
              // Getting User access data
              var rbacUM = res['body']['Table1'] as UserModuleAccess[];
              var mAccess = rbacUM.filter(function (x) {
                return x.IsVisible == true
              })

              if(mAccess.length == 0)
              {

              }
              else
              {               
                 LoginUser.getModulesAccess = rbacUM;                      
              }                         
            }           
          }
        }, error => {

          if(error)
          {
              if(error.statusText == "Unauthorized")
              {
                console.log("User is Unauthorized");            
                //alert("Sorry, You Are Not Allowed to Access This Page")
                document.getElementById("authError").innerHTML = "Sorry, you are not allowed to access this page.";
                document.getElementById("Apploader").style.display = "none";                
              }
          }
            
        })
        
       //#endregion
}

  validate() {
    if (this.userId == "")
      return true;

    if (this.password == "")
      return true;

    return false;
  }

  signup(){
    this.router.navigate(['/signup']);
  }

}
