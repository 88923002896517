<div class="myForm shadow border-form">
    <form #Venue="ngForm" autocomplete="off"class=" p-5 mb-5 rounded mt-4 " >
        <div class="d-flex justify-content-center">
            <div class="logo">
                <img src="assets/venuearc_logo.png" alt="Logo" style="width: 300px;">
            </div>
        </div>

        <div class="row">

            <div class="col-md-12 pt-5 pb-3 text-center">
                <h2 style="color:#0e83a8;">Add your Client</h2>
                <p>We are just 1 step away!</p>
            </div>
        </div>


        <div class="row">

            <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                <mat-form-field class="example-full-width">
                    <mat-label>Client Name</mat-label>
                    <input matInput class="MatInputBoxShadow"
                    required name="ClientName" #ClientName="ngModel"
                    [(ngModel)]="formData.ClientName" type="text" id="ClientName"
                    pattern="[a-zA-Z0-9-$&_(),. ]*"> 
                    <button type="button" mat-button *ngIf="formData.ClientName" matSuffix mat-icon-button aria-label="Clear" (click)="formData.ClientName=''">
                        <mat-icon>close</mat-icon>
                      </button>                 
                    <mat-error *ngIf="ClientName.invalid && ClientName.dirty && !ClientName.errors?.pattern">This field is required</mat-error> 
                    <mat-error *ngIf="ClientName.invalid && ClientName.errors?.pattern">Cannot contain any of the following characters: @ * # % " : < > ? / \ | </mat-error>
                </mat-form-field>  
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" >
                
            </div>         
            <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">    
                <mat-form-field class="example-full-width">
                    <mat-label>Contact #</mat-label> 
                   <input matInput                                   
                    placeholder="(___) ___-____"
                    class="MatInputBoxShadow" name="Phone" #Phone="ngModel" [(ngModel)]="formData.Phone" type="text" id="Phone">   
                    <button type="button" mat-button *ngIf="formData.Phone" matSuffix mat-icon-button aria-label="Clear" (click)="formData.Phone=''">
                    <mat-icon>close</mat-icon>
                    </button> 
                    <!-- <mat-error *ngIf="Phone.invalid">Please Enter a valid contact number e.g. (111) 222-3333</mat-error>  -->

                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">    
                <mat-form-field class="example-full-width">
                    <mat-label>Street Address</mat-label>
                    <input matInput class="MatInputBoxShadow" name="StreetAddress" #StreetAddress="ngModel"
                    [(ngModel)]="formData.StreetAddress" type="text" id="StreetAddress"> 
                    <button type="button" mat-button *ngIf="formData.StreetAddress" matSuffix mat-icon-button aria-label="Clear" (click)="formData.StreetAddress=''">
                        <mat-icon>close</mat-icon>
                      </button>                 
                  </mat-form-field>  
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">    
                <mat-form-field class="example-full-width">
                    <mat-label>Email Address</mat-label>
                    <input matInput placeholder="jsmith@example.com" class="MatInputBoxShadow"  name="Email" #Email="ngModel" [(ngModel)]="formData.Email" type="text"
                    id="Email" >   
                    <button type="button" mat-button *ngIf="formData.Email" matSuffix mat-icon-button aria-label="Clear" (click)="formData.Email=''">
                        <mat-icon>close</mat-icon>
                      </button>                                    
                  </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                <div>
                    <h5 class="d-block p-2 bg-primary text-white gradient-card-header blue-gradient ChildTitle">Contact Person Information </h5>
                  </div>
            </div>
            <hr>
            <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">    
                <mat-form-field class="example-full-width">
                    <mat-label>Contact Person Name</mat-label>
                    <input matInput class="MatInputBoxShadow"
                    required name="ContactPersonName" #ContactPersonName="ngModel"
                    [(ngModel)]="formDataContactPerson.ContactPersonName" type="text" id="ContactPersonName"> 
                    <button type="button" mat-button *ngIf="formDataContactPerson.ContactPersonName" matSuffix mat-icon-button aria-label="Clear" (click)="formDataContactPerson.ContactPersonName=''">
                        <mat-icon>close</mat-icon>
                      </button>                 
                    <mat-error *ngIf="ContactPersonName.invalid && ContactPersonName.dirty">This field is required</mat-error> 
                  </mat-form-field>  
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">    
                <mat-form-field class="example-full-width">
                    <mat-label>Contact Person Contact #</mat-label>
                    <input matInput                          
                    placeholder="(___) ___-____" 
                    class="MatInputBoxShadow"
                    name="Phone" #Phone="ngModel" [(ngModel)]="formDataContactPerson.Phone" type="text" id="Phone"> 
                    <button type="button" mat-button *ngIf="formDataContactPerson.Phone" matSuffix mat-icon-button aria-label="Clear" (click)="formDataContactPerson.Phone=''">
                        <mat-icon>close</mat-icon>
                      </button>                                
           
                </mat-form-field>  
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 mt-4">

                <button mdbBtn color="light" class="waves-light w-100" mdbWavesEffect
                    type="button"  rounded="true">
                    
                    <span class="mr-2">Skip Now</span>
                    <mdb-icon fas icon="play"></mdb-icon>
                </button>

            </div>
            <div class="col-md-6 mt-4">

                <button mdbBtn color="info" class="waves-light w-100" mdbWavesEffect
                    type="button" rounded="true">
                    
                    <mdb-icon fas icon="check"></mdb-icon>
                    <span class="ml-2">Submit</span>
                </button>

            </div>
        </div>

       


    </form>
</div>
