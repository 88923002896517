import { EventStatus } from 'src/app/models/EventStatus.model';

export class Event_SubEvent {
    ID: number = 0;
    EventID: number = null;
    LineID: number = null;
    EventDaysID: number = null;
    EventDaysLineID: number = null;
    SubEventTypeID: number = null;
    EventStatusID: number = null;
    SubEventDate: any = null;
    TimeIn: string = null;
    TimeOut: string = null;
    ShowStartTime: string = null;
    ShowEndTime: string = null;
    LicenseFeeTypeID: number = null;
    LicenseFee: number = null;
    DiscountedLicenseFee: number = null;
    Discount: number = null;
    IsReschedule: boolean = null;
    RescheduleReason: string = null;
    OriginalSubEventDate: any = null;
    RescheduleSub: string = null;
    CreatedBy: number = null;
    CreatedDate: Date = null;
    ModifiedBy: number = null;
    ModifiedDate: Date = null;
    IsDeleted: boolean = null;
    IsSettlement: boolean = null;
    SettlementID: number = null;
    StandardLicenseFee:number = null;
    eventStatus: EventStatus[] = [];
    errorMessage:string = null;
    IsUploadedFromOther:boolean = null;
}