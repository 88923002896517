import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-event-modal',
  templateUrl: './event-modal.component.html',
  styles: []
})
export class EventModalComponent implements OnInit {

  constructor( public modalRefEvent: MDBModalRef) { }

  ngOnInit(): void {
    
  }

}
