import { Component, OnInit } from '@angular/core';
import { Venue } from 'src/app/models/venue.model';
import { Router, ActivatedRoute } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import { TokenService } from 'src/app/services/token.service';
import { BLBase } from 'src/app/Base/BLBase/BLBase.component';
import { GenericService } from 'src/app/services/generic.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from 'ng-uikit-pro-standard';
import { ConfirmDialogeService } from 'src/app/confirm-dialoge/confirm-dialoge.service';
import { CommonService } from 'src/app/Base/Common.service';
import { MessageDialogeService } from 'src/app/message-dialoge/message-dialoge.service';
import { NgForm } from '@angular/forms';
import { UserManagerService } from 'src/app/services/user-manager.service';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { LoginUser } from 'src/app/Base/User/login-user';
import { UserModuleAccess } from 'src/app/Base/models/user-module-access';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-on-boarding-venue',
  templateUrl: './on-boarding-venue.component.html',
  styleUrls: ['./on-boarding-venue.component.scss']
})
export class OnBoardingVenueComponent extends BLBase<Venue> implements OnInit {
  //primaryKey: number;
  constructor(
    public service: GenericService,
    public router: Router,
    public route: ActivatedRoute,
    public spinner: NgxSpinnerService,
    public toast: ToastService,
    public dialog: ConfirmDialogeService,
    public common:CommonService,
    public userManagerService:UserManagerService,
    public  authenticationService:AuthenticationService,
    private permissionService:PermissionService ,
    public messageDialog?: MessageDialogeService,
    
  ) {
    //BLBase Constructor
    super(service, router, route, spinner, toast, dialog, messageDialog);

    // Form Heading will be shown in html
    this.formTitle = "Venue";

    //Defined Web API controller
    this.addControllerName("Venues");
  }

  public Initializeobject() {
    this.formData = new Venue();
  }

  ngOnInit(): void {
    // BLBase NgOnInit
    super.ngOnInit();
    if(localStorage.getItem("onboardingstatus") == null){
      this.router.navigate(['/calendar']);
    }

    this.getCustomer();
  }

  // This form function
  onSubmit(form: NgForm) {

    if (this.formData.IsSystemGenerated == true) {
      this.messageDialog.openMessageDialog("Cannot Update. This record is system generated.");
      return;
    }

    //BLBase function
    this.insertRecord(form);
  }

  AfterInsert(form: NgForm) {
    localStorage.removeItem("onboardingstatus");
    this.router.navigate(['/calendar']);
  }

  async getCustomer(){

    this.common.ShowSpinner();

    if(LoginUser.loggedinUser.UserID != null)
      {
        this.common.HideSpinner();
        return;
      }

    var currUser = this.authenticationService.CurrentUser();
    if(!currUser){
      this.router.navigate(["/unauthorized"]);            
    }
    //User code
    var email = ''
    if(currUser.email.includes('+'))
      email = currUser.email.replace('+', '%2B');
    else 
      email = currUser.email;

      await this.userManagerService.getCustomer(currUser.Id, email).then(res =>{     
        var user = res['dataSet']['value'][0];
        if (user.isowner != 1) {
          if (res['dataSet'] == null || res['dataSet'] == undefined) {
            this.router.navigate(["/requestaccess"]);
            return;
          }

          if (res['dataSet']['value'] == null || res['dataSet']['value'] == undefined) {
            this.router.navigate(["/requestaccess"]);
            return;
          }
        }
        

        var customer =  res['customer'];
        var productInfo =  res['productInfo'];
        if(productInfo['SubscriptionType'] == "stripe"){
          if (productInfo.ProductName == "" || productInfo.ProductName == null) {
            this.router.navigate(["/membership/subscribe"]);            
            return;
          }
        }
        

        LoginUser.loggedinUser.UserID = user.UserID;        
        LoginUser.loggedinUser.UserName = user.FullName;
        LoginUser.loggedinUser.Email = user.Email;                
        LoginUser.loggedinUser.ShortCode = this.getShortCode(user.FullName);
        LoginUser.loggedinUser.OrganisationName = customer.OrganisationName;   
        LoginUser.loggedinUser.Subscription = productInfo.ProductName;   
        LoginUser.loggedinUser.SubscriptionType = productInfo.SubscriptionType;   
        currUser['subscription'] = productInfo.ProductName;   
        currUser['isowner'] = user.isowner;   
        this.authenticationService.login(currUser);
        //LoginUser.IsAdmin = user.IsAdmin;     
       

            // Loading Section Data
        LoginUser.getSectionAccess = res['dataSet']['Table2'] ;
        var data = res['dataSet']['value'] as any[];                
        var Inactive = data[0]["Inactive"];
        if(Inactive == true)
        {              
          this.router.navigate(["/unauthorized"]);            
        }

        /*
        if (data.length > 0 && data[0]["IsAdmin"] == true){
          LoginUser.IsAdmin = true;
          if(customer.OnBoardingStatus == 'signup')
            this.router.navigate(["/onboarding"]);
          // else
          //   this.router.navigate(["/calendar"]);
          resolve(true);          
        }
        else */
        {
          // 06/09/2022
          //LoginUser.IsAdmin = true;
          LoginUser.IsAdmin = user.IsAdmin;     
          this.permissionService.getActionData();

          // Getting User access data
          var rbacUM = res['dataSet']['Table1'] as UserModuleAccess[];
          var mAccess = rbacUM.filter(function (x) {
            return x.IsVisible == true
          })

          if(mAccess.length == 0)
          {

          }
          else
          {               
            LoginUser.getModulesAccess = rbacUM;
            //resolve(true);                
          }
          
          this.common.HideSpinner();
          return;        

        }                                  
     },error =>{
       
     })  
  }

  getShortCode(fullName:string):string{
    var shortcode:string = '';
    if(fullName){
      var arr = fullName.split(' ');
      if(arr.length > 0){
        if(arr.length > 1){
           shortcode = arr[0].substring(0, 1);
           shortcode += arr[1].substring(0, 1);
        } 
        else{
          shortcode = arr[0].substring(0, 1);         
        }
      }
    }

    return shortcode;
  }



}
