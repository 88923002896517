<div class=" mt-3">

  <div class="">
    <div class="row">
      <div class="col-md-6 text-left pl-3" [hidden]="common.isSingleVenueExist == null || common.isSingleVenueExist">

        <!-- Chips -->



        <!-- <div [hidden]="IsManyVenues" class="chip " style="margin-bottom: 0.5rem !important;" -->
        <div class="chip " style="margin-bottom: 0.5rem !important;"
          *ngFor="let ven of venueMonthNotesList ; let i = index;" (click)="onChipVenueClick(ven.VenueName)"
          [ngClass]="{'selectedChip':selectedChipVenueName == ven.VenueName }">
          <div class="chipArea" style="border: solid;border-width: 1px;border-color: #d1dee3;text-align:center;">
            {{ven.ShortCode}}
          </div>
          {{ven.VenueName}}
        </div>

        <!-- Drop Down -->
        <!-- <div [hidden]="!IsManyVenues" class="btn-group " style="margin-bottom: 0.5rem !important;" mdbDropdown>
            <button type="button" mdbBtn color="info" size="sm" class="waves-light" mdbWavesEffect>Venues</button>
            <button type="button" mdbDropdownToggle mdbBtn color="info" class="dropdown-toggle dropdown-toggle-split px-3 waves-light"
              mdbWavesEffect></button>

            <div class="dropdown-menu dropdown-info">
                <a class="dropdown-item "
                *ngFor="let ven of venueMonthNotesList ; let i = index;">
                  {{ven.VenueName}}
                </a>
            </div>
      </div>  -->

      </div>
      <div class=" pr-4" [ngClass]="common.isSingleVenueExist ? 'col-md-12 text-left':'col-md-6 text-right'">
        <div>
          <!-- <ul class="pl-2 IsBig"> -->
          <ul class="pl-2">

            <div style="margin-bottom: 0.5rem !important;" *ngFor="let chp of chpArray"
              (click)="onChipEventStatusClick(chp)"
              [ngClass]="{'selectedChip':selectedChipEventStatus != null && selectedChipEventStatus.eventStatus.EventStatus == chp.eventStatus.EventStatus }"
              class="chip contractstatus text-left d-block d-sm-block d-md-inline-flex  d-xl-inline-flex d-lg-inline-flex pr-3">
              <div class="chipArea"
                [ngStyle]="{background:chp.eventStatus.ChipColor, border:chp.eventStatus.ChipColor == '#ffffff' ? 'solid 1px lightgray' : 'none' }">
              </div>
              {{chp.eventStatus.EventStatus}}
            </div>
          </ul>

          <!-- <button class="IsSmall" mat-button [matMenuTriggerFor]="menu1">Select Event Status</button>
      <mat-menu #menu1="matMenu">
        <button mat-menu-item
        *ngFor="let chp of chpArray">
        {{chp.eventStatus.EventStatus}}
        </button>
      </mat-menu> -->




        </div>
      </div>
    </div>

    <div class="row text-center">

      <div class=" col-sm-12 col-md-4 calendar_buttonRow">

        <div class="btn-group CalendarActionButton float-left">
          <button mdbBtn type="button" color="info" class="waves-light CalendarNavButtons" mwlCalendarPreviousView
            [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay(viewDate)" mdbWavesEffect>
            <mdb-icon fas icon="angle-left"></mdb-icon>
          </button>
          <button mdbBtn type="button" color="info" class="waves-light CalendarNavButtons" mwlCalendarToday
            [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay(viewDate)" mdbWavesEffect>Today</button>
          <button mdbBtn type="button" color="info" class="waves-light CalendarNavButtons" mwlCalendarNextView
            [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay(viewDate)" mdbWavesEffect>
            <mdb-icon fas icon="angle-right"></mdb-icon>
          </button>
          <!-- <mat-form-field appearance="fill" class="matDateFieldCalendar ">
            <mat-label>Jump to Month</mat-label>
            <input matInput [matDatepicker]="picker" [value]="viewDate"
              (dateChange)="filterCalendar('change', $event)">
            <mat-datepicker-toggle matSuffix [for]="picker" style="    color: #33b4e4;"></mat-datepicker-toggle>
            <mat-datepicker #picker startView="year" (monthSelected)="chosenMonthHandler($event, picker)"
              panelClass="example-month-picker">
            </mat-datepicker>
          </mat-form-field> -->
        </div>

      </div>

      <div class=" col-sm-12 col-md-4 text-center calendar_buttonRow d-flex justify-content-center">
        <mat-form-field appearance="fill" class="matDateFieldCalendar " style="height: 51px !important;line-height: 1px !important;box-shadow: -4px 4px 4px 0px #e0e0e0;">
          <mat-label>Jump to Month</mat-label>
          <input matInput [matDatepicker]="picker" [value]="viewDate"
            (dateChange)="filterCalendar('change', $event)">
          <mat-datepicker-toggle matSuffix [for]="picker" style="    color: #095e8f;"></mat-datepicker-toggle>
          <mat-datepicker #picker startView="year" (monthSelected)="chosenMonthHandler($event, picker)"
            panelClass="example-month-picker">
          </mat-datepicker>
        </mat-form-field>
        <h1 class="MonthHeading">
          <mdb-badge *ngIf="view === CalendarView.Month" color="white" style="color: gray !important;">
            {{ viewDate | date:'MMMM, yyyy' }}
          </mdb-badge>
          <mdb-badge *ngIf="view === CalendarView.Week || view === CalendarView.Day" color="white"
            style="color: gray !important;">
            {{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}
          </mdb-badge>
        </h1>
      </div>

      <div class=" col-sm-12 col-md-4  calendar_buttonRow">

        <div class="btn-group CalendarActionButton float-right" role="group" aria-label="Basic example">
          <button mdbBtn type="button" color="info" class="waves-light CalendarNavButtons"
            (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month"
            mdbWavesEffect>Month</button>
          <button mdbBtn type="button" color="info" class="waves-light CalendarNavButtons"
            (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week"
            mdbWavesEffect>Week</button>
          <button mdbBtn type="button" color="info" class="waves-light CalendarNavButtons"
            (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day"
            mdbWavesEffect>Day</button>
          <button mdbBtn type="button" color="info" style="background-color: whitesmoke !important;" outline="true"
            class="waves-light CalendarNavButtons" (click)="frame.show()" data-toggle="modal"
            data-target="#basicExample" mdbWavesEffect>
            <mdb-icon color="info" fas icon="filter"></mdb-icon>
          </button>

          <!-- <a (click)="frame.show()"
          data-toggle="modal" appearance="fill" data-target="#basicExample">
        <button mdbBtn type="button" style="background-color: rgba(0,0,0,.04);height: 100%;">
          <mdb-icon fas icon="filter" class="mat-datepicker-toggle"></mdb-icon>
        </button></a> -->
        </div>

      </div>


    </div>
    <br />

    <!-- (dayClicked)="OpenEventModal($event)" -->

    <div [ngSwitch]="view">
      <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
        [refresh]="refresh" (eventTimesChanged)="eventTimesChanged($event)" [cellTemplate]="customCellTemplate"
        (columnHeaderClicked)="clickedColumn = $event.isoDayNumber" [tooltipTemplate]="cellTooltipTemplate">
      </mwl-calendar-month-view>
      <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
        [refresh]="refresh" [cellTemplate]="customCellTemplate" (eventTimesChanged)="eventTimesChanged($event)"
        [tooltipTemplate]="cellTooltipTemplate" [dayStartHour]="6">
      </mwl-calendar-week-view>
      <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events"
        [refresh]="refresh" [tooltipTemplate]="cellTooltipTemplate" [dayStartHour]="6"
        (eventTimesChanged)="eventTimesChanged($event)">
      </mwl-calendar-day-view>
    </div>
  </div>
  <br>


  <div class="row" [hidden]="permissionService.CheckIfUserhasAccess_Section('Monthly Notes')"
    *ngIf="permissionService.CheckIfUserhasAccess_Action('Monthly Notes','View')">
    <div class="col-md-12">
      <mdb-accordion [multiple]="true">
        <mdb-accordion-item [collapsed]="true">
          <mdb-accordion-item-head [customClass]="'blue-gradient white-text'">Monthly Notes
          </mdb-accordion-item-head>
          <mdb-accordion-item-body>
            <div style="text-align: center;padding-top: 30px;" [hidden]="IsMonthNotesLoaded">
              <span class="spinner-border " role="status" aria-hidden="true"></span>
            </div>
            <div class="row" [hidden]="!IsMonthNotesLoaded">

              <div class="col-lg-6 col-md-12" *ngFor="let ven of venueMonthNotesList">

                <br>
                <div class="row" style="padding-bottom: 10px;">
                  <div class="col-md-3 col-xs-2">
                    <h5 style="margin-top: 10px;">{{ven.VenueName}}</h5>
                  </div>
                  <div class="col-md-9 col-xs-10 text-right">
                    <button type="button" (click)="OpenCalendarNotesModal(ven.ID)"
                      *ngIf="permissionService.CheckIfUserhasAccess_Action('Monthly Notes','Add') || permissionService.CheckIfUserhasAccess_Action('Monthly Notes','Edit')"
                      mdbBtn color="default" class="waves-light" mdbWavesEffect size="sm">
                      <mdb-icon fas icon="plus" class="mr-1"></mdb-icon>Add
                    </button>
                  </div>
                </div>

                <div class="z-depth-1" style="height:600px">
                  <table mdbTable mdbTableScroll scrollY="true" maxHeight="600"  class="InputTable table">
                    <thead>
                      <tr>
                        <th>Notes</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let es of ven.VenueMonthNotes">
                        <td>
                          <div class="NotesAuditInfo">
                            Created on {{es.CreatedDate | date:'MM/d/yyyy h:mm a'}} by
                            {{es.RBAC_Users1 == null ? '' : es.RBAC_Users1.FirstName + ' ' + es.RBAC_Users1.LastName}}
                          </div>
                          <div style="white-space: pre-wrap;" [innerHTML]="es.Notes">

                          </div>
                        </td>
                        <td>
                          <div class="btn-group float-right">
                            <button mat-button matTooltip="Edit"
                              *ngIf="permissionService.CheckIfUserhasAccess_Action('Monthly Notes','Edit')"
                              (click)="EditCalendarNotesModal(ven.ID, es)">
                              <mat-icon class="flat-Icon-Color">edit</mat-icon>
                            </button>
                            <button mat-button class="flat-Icon-Color" matTooltip="Delete"
                              *ngIf="permissionService.CheckIfUserhasAccess_Action('Monthly Notes','Delete')"
                               (click)="DeleteNote(es.ID)">
                              <mat-icon>delete</mat-icon>
                            </button>
                          </div>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>


            </div>
          </mdb-accordion-item-body>
        </mdb-accordion-item>

      </mdb-accordion>
    </div>


  </div>

</div>

<br /><br /><br />


<ng-template #modalContent let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Event action occurred</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      Action:
      <pre>{{ modalData?.action }}</pre>
    </div>
    <div>
      Event:
      <pre>{{ modalData?.event | json }}</pre>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="close()">
      OK
    </button>
  </div>
</ng-template>

<ng-template #customCellTemplate let-day="day" let-locale="locale" let-eventClicked="eventClicked"
  let-tooltipPlacement="tooltipPlacement" let-tooltipTemplate="tooltipTemplate"
  let-tooltipAppendToBody="tooltipAppendToBody" let-tooltipDelay="tooltipDelay">



  <div class="cal-cell-top" [matMenuTriggerFor]="DayMenu">

    <div class="">
      <!-- <div class="col-sm-10 col-md-10">
          <div  class="cal-events" *ngFor="let event of day.events"
          class="HolidayEventTile"
          [hidden]="!event.meta.IsHoliday"
          (mwlClick)="eventClicked.emit({event: event})" >
          {{event.title}}
          </div>

      </div> -->
      <!-- For Popover of Add event -->
      <!-- <span *ngIf="forShowPop(day.date)" class="text-center">
        <i
        mdbPopover="Click here to add your Event" placement="bottom"  triggers="focus" 	[isOpen]="showPop">

        </i>
      </span> -->

      <div class="">


        <span class="cal-day-number">
           {{ day.date | calendarDate:'monthViewDayNumber':locale }}
         <!-- {{ (day.date  ) == currentDay ?'current' : 'other'}} -->
        </span>
      </div>
      <!-- <div class="">
        <div  class="cal-events" *ngFor="let event of day.events"
        class="HolidayEventTile"
        [hidden]="!event.meta.IsHoliday"
        (mwlClick)="eventClicked.emit({event: event})" >
        {{event.title}}
        </div>
      </div> -->
    </div>


    <mat-menu #DayMenu="matMenu" yPosition="below">
      <!-- <a mdbBtn (click)="OpenEventModal(day.date)" floating="true"
      size="sm" gradient="blue">
        <mdb-icon fas icon="plus"></mdb-icon>
      </a> -->
      <button *ngIf="permissionService.CheckIfUserhasAccess_Action('Event','Add')" mat-menu-item
        (click)="OpenEventModal(day.date)">
        <mat-icon>add</mat-icon>
        <span>Add Event</span>
      </button>

      <button *ngIf="permissionService.CheckIfUserhasAccess_Action('Event','Add')" mat-menu-item
        (click)="openQuickEventForm(day.date)">
        <mat-icon>add</mat-icon>
        <span>Add Quick Event</span>
      </button>
      <!-- <button mat-menu-item (click)="OpenEventModal(day.date)" >
          <mat-icon>add</mat-icon>
          <span>Add New Event</span>
      </button>
      <button mat-menu-item (click)="OpenEventModal(day.date)" >
          <mat-icon>add</mat-icon>
          <span>Add New Event</span>
      </button> -->

    </mat-menu>


  </div>


  <div>
    <div class="cal-events HolidayEventTile" *ngFor="let event of day.events"
      (mwlClick)="eventClicked.emit({event: event})" [hidden]="!event.meta.IsHoliday" class="HolidayEventTile">
      <div>{{event.title}} </div>
    </div>
  </div>

  <div [hidden]="permissionService.CheckIfUserhasAccess_Action('Event','Secured Events') == false">
    <div class="cal-events" *ngFor="let event of day.events" [style.backgroundColor]="event.color?.primary"
      class="CalendarEventTile" (mwlClick)="eventClicked.emit({event: event})"
      [hidden]="!event.meta.IsSecuredEvent">
      <div *ngIf="!event.meta.IsHoliday">
        {{event.meta.icon}} | <mdb-icon fas icon="lock" style="float:right;line-height: inherit;margin-right:5px;"
          (click)="UnlockEvent(event.meta.EventID)"></mdb-icon>&nbsp;
      </div>
    </div>
  </div>

  <div [hidden]="permissionService.CheckIfUserhasAccess_Action('Event','Secured Events') == true">
    <div class="cal-events" *ngFor="let event of day.events" [style.backgroundColor]="event.color?.primary"
      class="CalendarEventTile" (mwlClick)="eventClicked.emit({event: event})"
      [hidden]="!event.meta.IsSecuredEvent">
      <div *ngIf="!event.meta.IsHoliday"
        [ngStyle]="{'color': event.meta.TextColor? 'event.meta.TextColor' : 'black'}">
        {{event.meta.icon}} | <mdb-icon fas icon="lock" style="float:right;line-height: inherit;margin-right:5px;">
        </mdb-icon>&nbsp;
      </div>
    </div>
  </div>

  <!-- <div class="cal-events" *ngFor="let event of day.events"
  [mwlCalendarTooltip]="event.title | calendarEventTitle: 'monthTooltip':event"
  [tooltipPlacement]="tooltipPlacement" [tooltipEvent]="event" [tooltipTemplate]="tooltipTemplate"
  [tooltipAppendToBody]="tooltipAppendToBody" [tooltipDelay]="tooltipDelay"
  [style.backgroundColor]="event.color?.primary" class="CalendarEventTile"[ngStyle]=" {'text-decoration': event.meta.EventStatusID == -1000 ? 'line-through':'none'}"
  (mwlClick)="eventClicked.emit({event: event})" [hidden]="event.meta.IsSecuredEvent || event.meta.IsHoliday" >
  {{event.meta.icon}} &nbsp;{{event.title}}
</div> -->

  <div class="cal-events" *ngFor="let event of day.events"
    [mwlCalendarTooltip]="event.title | calendarEventTitle: 'monthTooltip':event"
    [tooltipPlacement]="tooltipPlacement" [tooltipEvent]="event" [tooltipTemplate]="tooltipTemplate"
    [tooltipAppendToBody]="tooltipAppendToBody" [tooltipDelay]="tooltipDelay"
    [style.backgroundColor]="event.color?.primary" class="CalendarEventTile"
    [ngStyle]=" {'text-decoration': event.meta.EventStatusID == -1000 ? 'line-through':'none'}"
    (mwlClick)="eventClicked.emit({event: event})" [matMenuTriggerFor]="menu"
    [hidden]="event.meta.IsSecuredEvent || event.meta.IsHoliday">

    <!-- For Default Event-->
    <div [hidden]="event.meta.IsSecuredEvent"
      [ngStyle]="{'color': event.meta.TextColor  != null ? event.meta.TextColor : 'black'}">
      {{event.meta.icon}} | &nbsp;{{event.title}} <mdb-icon [hidden]="!event.meta.IsSecured" fas icon="unlock"
        style="float:right;line-height: inherit;margin-right:5px;"></mdb-icon>&nbsp;
      <mat-icon [hidden]="event.meta.EventNotesID == 0 && event.meta.SubEventNotesID == 0 "
        style="float: right; height: 19px; font-size: 19px; color: gray;">sticky_note_2</mat-icon>
    </div>




    <mat-menu #menu="matMenu" yPosition="below">

      <button mat-menu-item class="IsDesktopMobile" data-toggle="modal" data-target="#basicExample"
        (click)="frameEventPopup.show(); ShowDetails(event); ">
        <mat-icon>remove_red_eye</mat-icon>
        <span>View Details</span>
      </button>


      <button mat-menu-item [hidden]="permissionService.CheckIfUserhasAccess('Event Management')"
        (click)="openeventdashboard(event.meta.EventID, day.date)">
        <mat-icon>desktop_windows</mat-icon>
        <span>Event Portal</span>
      </button>
      <button [hidden]="permissionService.CheckIfUserhasAccess('Event Management')" mat-menu-item [matMenuTriggerFor]="Event">
          <mat-icon>open_in_new</mat-icon>
          Event
        </button>
      <button [hidden]="permissionService.CheckIfUserhasAccess('Event Management')" mat-menu-item [matMenuTriggerFor]="Notes">
        <mat-icon>open_in_new</mat-icon>
        Notes
      </button>

      <button [hidden]="permissionService.CheckIfUserhasAccess('Event Settlement')" mat-menu-item
        [matMenuTriggerFor]="Settlement">
        <mat-icon>open_in_new</mat-icon>
        Settlement
      </button>
      <button *ngIf="false" [hidden]="permissionService.CheckIfUserhasAccess('Event Inventory')" mat-menu-item
        [matMenuTriggerFor]="Production">
        <mat-icon>open_in_new</mat-icon>
        Production
      </button>
    </mat-menu>
    <mat-menu #Event="matMenu">

      <button *ngIf="permissionService.CheckIfUserhasAccess_Action('Event','View')" mat-menu-item
        [routerLink]="['/event/event/form']" [queryParams]="{ID:event.meta.EventID, calendarDate:day.date}">
        <mat-icon>remove_red_eye</mat-icon>
        <span>Event Details</span>
      </button>

      <button
        [disabled]=" permissionService.CheckIfUserhasAccess_Action('Contract Information','Add') == false && event.meta.EventContractID == 0"
        *ngIf="permissionService.CheckIfUserhasAccess_Action('Contract Information','View')"
        [hidden]="permissionService.CheckIfUserhasAccess_Section('Contract Information') " mat-menu-item
        [routerLink]="['/event/eventcontracts/form']"
        [queryParams]="{EventID:event.meta.EventID,ID:event.meta.EventContractID, calendarDate:day.date}">
        <mat-icon [hidden]="event.meta.EventContractID == 0">remove_red_eye</mat-icon>
        <mat-icon [hidden]="event.meta.EventContractID != 0">add</mat-icon>
        <span>Contract Information</span>
      </button>

      <button
        [disabled]=" permissionService.CheckIfUserhasAccess_Action('Deposit Schedule','Add') == false && event.meta.DepsitScheduleID == 0"
        *ngIf="permissionService.CheckIfUserhasAccess_Action('Deposit Schedule','View')"
        [hidden]="permissionService.CheckIfUserhasAccess_Section('Deposit Schedule')" mat-menu-item
        [routerLink]="['/event/eventdueamount/form']"
        [queryParams]="{EventID:event.meta.EventID,ID:event.meta.DepsitScheduleID, calendarDate:day.date}">
        <mat-icon [hidden]="event.meta.DepsitScheduleID == 0">remove_red_eye</mat-icon>
        <mat-icon [hidden]="event.meta.DepsitScheduleID != 0">add</mat-icon>
        <span>Deposit Schedule</span>
      </button>

      <button
        [disabled]=" permissionService.CheckIfUserhasAccess_Action('Financials','Add') == false && event.meta.FinancialsID == 0"
        *ngIf="permissionService.CheckIfUserhasAccess_Action('Financials','View')"
        [hidden]="permissionService.CheckIfUserhasAccess_Section('Financials')" mat-menu-item
        [routerLink]="['/event/clientpayments/form']"
        [queryParams]="{EventID:event.meta.EventID, ID:event.meta.FinancialsID, calendarDate:day.date}">
        <mat-icon [hidden]="event.meta.FinancialsID == 0">remove_red_eye</mat-icon>
        <mat-icon [hidden]="event.meta.FinancialsID != 0">add</mat-icon>
        <span>Financials</span>
      </button>

      <button *ngIf="permissionService.CheckIfUserhasAccess_Action('Event Reschedule','View')"
        [hidden]="event.meta.IsBooked != true && permissionService.CheckIfUserhasAccess_Section('Event Reschedule')"
        mat-menu-item [routerLink]="['/event/event-reschedule/form']"
        [queryParams]="{ID:event.meta.EventID, calendarDate:day.date}">
        <mat-icon>remove_red_eye</mat-icon>
        <span>Event Reschedule</span>
      </button>

      <button
      [hidden]="permissionService.CheckIfUserhasAccess_Section('Event Documents')"
      [routerLink]="['/event/eventdocuments/form']"
      [queryParams]="{EventID:event.meta.EventID, calendarDate:day.date}" mat-menu-item>
      <mat-icon>description</mat-icon>
      <span>Event Documents</span>
      </button>

      <button *ngIf="permissionService.CheckIfUserhasAccess_Action('Event','Performance Delete')"
        [hidden]=" event.meta.IsSettlement == true || event.meta.EventStatusID==1 || event.meta.EventStatusID==2 || event.meta.EventStatusID==3"
        mat-menu-item (click)="DeletePerformance(event.id, event.meta.EventID)">
        <mat-icon>delete</mat-icon>
        <span>Delete Performance</span>

      </button>

      <button *ngIf="permissionService.CheckIfUserhasAccess_Action('Event','Delete')" mat-menu-item
        (click)="deleteEventStatus(event.meta.EventID)">
        <mat-icon>delete</mat-icon>
        <span>Delete Event</span>
      </button>
    </mat-menu>
    <mat-menu #Settlement="matMenu">
      <button *ngIf="permissionService.CheckIfUserhasAccess_Action('Settlement','Add')" [routerLink]="['/settlement/form']"
        [queryParams]="{EventID:event.meta.EventID, calendarDate:day.date}" mat-menu-item>
        <mat-icon>add</mat-icon>
        <span>Add Settlement</span>
      </button>
      <button
        [hidden]="event.meta.SettlementEventID > 0 ? false : true && permissionService.CheckIfUserhasAccess_Section('Settlement List')"
        [routerLink]="['/settlement/list']" [queryParams]="{EventID:event.meta.EventID}" mat-menu-item>
        <mat-icon>remove_red_eye</mat-icon>
        <span>Settlement List</span>
      </button>
    </mat-menu>
    <mat-menu #Notes="matMenu">
      <button *ngIf="permissionService.CheckIfUserhasAccess_Action('Event Notes','View')"
        [hidden]="permissionService.CheckIfUserhasAccess_Section('Event Notes')" mat-menu-item
        (click)="OpenNotesModal(event.meta.EventID)">
        <mat-icon>remove_red_eye</mat-icon>
        <span>Event Notes</span>
        <mat-icon [hidden]="event.meta.EventNotesID == 0 " style="padding-left: 10px; color: gray;">sticky_note_2
        </mat-icon>
      </button>
      <button *ngIf="permissionService.CheckIfUserhasAccess_Action('Performance Notes','View')"
        [hidden]="permissionService.CheckIfUserhasAccess_Section('Performance Notes')" mat-menu-item
        (click)="OpenPerformanceNotesModal(event.meta.EventID, event.id)">
        <mat-icon>remove_red_eye</mat-icon>
        <span>Performance Notes</span>
        <mat-icon [hidden]="event.meta.SubEventNotesID == 0 " style="padding-left: 10px; color: gray;">sticky_note_2
        </mat-icon>
      </button>


    </mat-menu>
    <mat-menu #Production="matMenu">
      <button *ngIf="permissionService.CheckIfUserhasAccess_Action('Event Inventory','Add')"
        [hidden]="event.meta.InventoryEventID != 0" [routerLink]="['/event/event-inventory/form/edit']"
        [queryParams]="{EventID:event.meta.EventID, calendarDate:day.date, VenueID:event.meta.VenueID}"
        mat-menu-item>
        <mat-icon>add</mat-icon>
        <span>Add</span>
      </button>
      <button *ngIf="event.meta.InventoryEventID > 0"
        [hidden]="permissionService.CheckIfUserhasAccess_Section('Event Inventory')"
        [routerLink]="['/event/event-inventory/form/cards']"
        [queryParams]="{EventID: event.meta.EventID, calendarDate: day.date, VenueID:event.meta.VenueID}"
        mat-menu-item>
        <mat-icon>remove_red_eye</mat-icon>
        <span>View</span>
      </button>
    </mat-menu>


  </div>

</ng-template>

<ng-template #cellTooltipTemplate let-event="event" let-tooltipPlacement="tooltipPlacement" let-locale="locale">

  <div class="cal-tooltip IsMobile">

    <div class="cal-tooltip-arrow"></div>

    <div class="cal-tooltip-inner EventToolTip" [tooltipPlacement]="tooltipPlacement"
      style="background-color:transparent">



      <div class="TableToolTipCalendar" style="background-color: white;">
        <table mdbTable class="z-depth-1 InputTable">
          <tbody>
            <tr>
              <td data-label="Event ID">{{event.meta.EventID}}</td>
              <td data-label="Event Name">{{event.title}}</td>
              <td data-label="Start Date">{{event.start | date:'mediumDate'}}</td>
              <td data-label="End Date">{{event.end | date:'mediumDate'}}</td>
              <td data-label="Start Time">{{event.meta.ShowStartTime}} </td>
              <td data-label="End Time">{{event.meta.ShowEndTime}}</td>
              <td data-label="Status" [ngStyle]="{'background-color': event.color?.primary} ">
                {{event.meta.EventStatus}} </td>
              <td data-label="Venue">{{event.meta.Venue}}</td>
              <td data-label="Client">{{event.meta.ClientName}}</td>
              <td data-label="In Use">{{event.meta.SubEventTypeName}}</td>
              <td data-label="Modified By">{{event.meta.ModifiedBy}}</td>
              <td data-label="Modified Date">{{event.meta.ModifiedDate | date:'MM/d/yyyy'}}</td>
            </tr>


          </tbody>
        </table>
      </div>


    </div>

  </div>


</ng-template>


<div mdbModal #frameEventPopup="mdbModal" class="modal fade" id="frameEventPopupModalTop" tabindex="-1"
  role="dialog" aria-labelledby="frameEventPopupModalLabel" aria-hidden="true">
  <div class="modal-lg modal-content modal-dialog cascading-modal modal-top" role="document">
    <div class="modal-content">

      <div
        class="modal-header light-blue darken-3 white-text  gradient-card-header blue-gradient narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center">

        <h4 class="h4-responsive"> Event Details </h4>

        <div class="btn-group" role="group" aria-label="Basic example">

          <a (click)="frameEventPopup.hide()">
            <button mdbBtn type="button" color="info" size="sm" class="waves-light" mdbWavesEffect>
              <mdb-icon fas icon="times"></mdb-icon>
            </button>
          </a>

        </div>

      </div>

      <!--Body-->
      <div class="modal-body mb-0 text-center">

        <div class="TableToolTipCalendar" style="background-color: white;">
          <table mdbTable class="z-depth-1 InputTable">
            <tbody>
              <tr>
                <td data-label="Event ID">{{eventInfo != null ? eventInfo.meta.EventID : ''}}</td>
                <td data-label="Event Name">{{eventInfo != null ? eventInfo.title: ''}}</td>
                <td data-label="Start Date">{{eventInfo != null ? (eventInfo.start | date:'mediumDate'): ''}}</td>
                <td data-label="End Date">{{eventInfo != null ? (eventInfo.end | date:'mediumDate'): ''}}</td>
                <td data-label="Start Time">{{eventInfo != null ? eventInfo.meta.ShowStartTime: ''}} </td>
                <td data-label="End Time">{{eventInfo != null ? eventInfo.meta.ShowEndTime: ''}}</td>
                <td data-label="Status"
                  [ngStyle]="{'background-color': eventInfo != null ? eventInfo.color?.primary : 'white'} ">
                  {{eventInfo != null ? eventInfo.meta.EventStatus: ''}} </td>
                <td data-label="Venue">{{eventInfo != null ? eventInfo.meta.Venue: ''}}</td>
                <td data-label="Client">{{eventInfo != null ? eventInfo.meta.ClientName: ''}}</td>
                <td data-label="In Use">{{eventInfo != null ? eventInfo.meta.SubEventTypeName: ''}}</td>
                <td data-label="Modified By">{{eventInfo != null ? eventInfo.meta.ModifiedBy: ''}}</td>
                <td data-label="Modified Date">
                  {{eventInfo != null ?  (eventInfo.meta.ModifiedDate | date:'MM/d/yyyy'): ''}}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>

    </div>
  </div>
</div>


<div mdbModal #frame="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: true, ignoreBackdropClick: false}">
  <div class="modal-dialog modal-full-height modal-right modal-notify modal-info" role="document">
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">Filter
        </p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame.hide()">
          <span aria-hidden="true" class="white-text">×</span>
        </button>
      </div>

      <!-- <mdb-navbar SideClass="navbar navbar-expand-lg navbar-light  white" [containerInside]="false">
        <form class="form-inline">
          <links>
            <ul class="navbar-nav ">
              <li class="nav-item ">
                <a (click)="saveFilters()" color="info" class="nav-link waves-light filterModalButtons" style="color: #403e3e !important;" mdbWavesEffect>
                  <mdb-icon fas icon="save" class="mr-1" style="color: #fff !important;"></mdb-icon>Save Filters<span class="sr-only">(current)</span>
                </a>
              </li>
            </ul>
          </links>
        </form>
      </mdb-navbar> -->
      <!-- Safwan Changes - Start -->

      <div class="row">
        <div class="col-md-5 mt-2 p-1 pl-4">
          <a (click)="saveFilters()" color="info" class="nav-link waves-light filterModalButtons" style="color: #403e3e !important;" mdbWavesEffect>
            <mdb-icon fas icon="save" class="mr-1" style="color: #fff !important;"></mdb-icon>Save Filters<span class="sr-only">(current)</span>
          </a>
        </div>
      </div>

      <!-- Safwan Changes - End -->
      <!--Body-->
      <div class="modal-body">
        <div class="text-left">
          <!-- (selected)="GenerateCalendarData($event)" -->
          <div class="md-form" style="margin-top: 1rem !important;" [hidden]="common.isSingleVenueExist == null || common.isSingleVenueExist">
            <mat-form-field class="example-full-width">
              <mat-label>Venue</mat-label>
              <mat-select #venue_SkillSell [multiple]="true" label="Venue" id="VenueID" type="text" name="Venue"
                #Venue="ngModel" [(ngModel)]="VenueID">
                <mat-option [value]="0" (click)="VenuetoggleAllSelection()">-- Select All --</mat-option>
                <mat-option *ngFor="let option of AutoCompVenue.results | async" [value]="option.ID">
                  {{ option.VenueName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="md-form" style="margin-top: 1rem !important;">
            <mat-form-field class="example-full-width">
              <mat-label>Event Status</mat-label>
              <mat-select #eventStatus_SkillSell [multiple]="true" id="EventStatus" type="text" name="EventStatus"
                #EventStatus="ngModel" [(ngModel)]="EventStatusID">
                <mat-option [value]="0" (click)="EventStatustoggleAllSelection()">-- Select All --</mat-option>
                <mat-option *ngFor="let option of AutoCompStatus.results | async" [value]="option.ID">
                  {{ option.EventStatus }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="md-form" style="margin-top: 1rem !important;">
            <mat-form-field class="example-full-width">
              <mat-select name="selectedClient" #selectedClient="ngModel" [(ngModel)]="selectedClients"
                placeholder="Client" multiple #multiSelect>
                <mat-option>
                  <ngx-mat-select-search name="searchClientName" #searchClientName="ngModel" [(ngModel)]="searchClient"
                    (ngModelChange)="AutoCompClient.searchResult.next($event)" placeholderLabel="Search..."
                    noEntriesFoundLabel="Not Found!">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option disabled="disabled" class="filter-option matCustomOption">

                  <button mat-icon-button color="primary" (click)="toggleSelectAll(false)">
                    <span>
                      <mat-icon>unpublished</mat-icon>Clear All
                    </span>
                  </button>
                </mat-option>
                <mat-option *ngFor="let option of AutoCompClient.results | async " [value]="option.ID">
                  {{option.ClientName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row" style="margin-top: 1rem !important;">
            <div class="col-md-6">
              <button class="w-100 modalFiltersButton" mdbBtn color="info" size="md" type="button"
                (click)="onFilterClick(frame)" mdbWavesEffect>
                <mdb-icon fas icon="filter" class="mr-1 filterButton"></mdb-icon> Apply Filter
              </button>
            </div>
            <div class="col-md-6">
              <button class="w-100 modalFiltersButton" mdbBtn color="info" size="md" type="button"
                (click)="onFilterClear(frame)" mdbWavesEffect outline="true">
                <mdb-icon fas icon="minus" class="mr-1" style="color: #095e8f !important;"></mdb-icon> Clear Filter
              </button>
            </div>
          </div>
        </div>

      </div>



    </div>
  </div>
</div>
