<!--Double navigation-->
<header
  >
  <!-- Sidebar navigation -->
  <!-- <mdb-side-nav #sidenav  class="sn-bg-3 " [fixed]="true" >  fixed -->
    <!-- Safwan Changes Add mouseenter and mouseleave function - 01/25/2023 - Start -->
  <!-- <mdb-side-nav #sidenav class="" [fixed]="true" (mouseenter)="mouseenter()" [sidenavBreakpoint]="300" (mouseleave)="mouseleave()"> fixed -->
  <mdb-side-nav #sidenav class="fixed" [fixed]="true"> <!--fixed-->
    <mdb-navbar-brand>
      <li>
        <!-- Safwan Changes Add hidden for dual navigation on lg screen - 01/25/2023 - Start -->
        <div class="logo-wrapper waves-light" >
          <a routerLink="/calendar" *ngIf="showImage !=null"  style="    color: white;
            font-family: cursive;
            text-align: center;
            padding-top: 1px;">
            <img [src]="showImage" class="img-fluid flex-center"
              style=" height: 100px !important;width: 250px !important;">
            <!-- class="img-fluid flex-center"  style=" height: 100px !important;width: 250px !important;"> -->
            <!-- {{user?.OrganisationName}} -->


          </a>
          <a routerLink="/calendar"  style="    color: #1d1d1e;
            font-family: cursive;
            text-align: center;
            padding-top: 15px;"
            *ngIf="showImage ==null">

            {{user?.OrganisationName}}


          </a>
        </div>
        <!-- Safwan Changes Add hidden for dual navigation on lg screen - 01/25/2023 - End -->
      </li>

    </mdb-navbar-brand>

    <!-- <li>
      <!-- Safwan Changes Add ng class and hidden for dual navigation on lg screen - 01/25/2023 - Start 
      <div style="" class="" [ngClass]=" !mobileQuery.matches && sideBar != null && sideBar == true  ?'user-pic-close': !mobileQuery.matches && sideBar == false  ?  'user-pic' :'user-pic'">
        <div class="text-center" style="margin: 0 0 10px 0;">
          <img src="../../../../assets/user-img.jpg" alt="user-img" class=" rounded-circle mb-1 " [ngClass]="!mobileQuery.matches && sideBar != null && sideBar == true  ? 'avatar-sm' : 'avatar-xl'">

        </div>
        <div class="user-info text-center" >

          <h6 style="margin-bottom: 0px !important;color:#1d1d1e !important;" [hidden]="!(!mobileQuery.matches && sideBar != null && sideBar == true)"> </h6>
          <span style="font-size: 14px ;color:#1d1d1e !important;" [hidden]="!(!mobileQuery.matches && sideBar != null && sideBar == true)"> </span>
          <h6 style="margin-bottom: 0px !important;color:#1d1d1e !important;width: 194px !important;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;" [hidden]="!mobileQuery.matches && sideBar != null && sideBar == true" >{{user?.UserName}}</h6>
          <span style="font-size: 14px ;color:#1d1d1e !important;width: 194px !important;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;" [hidden]="!mobileQuery.matches && sideBar != null && sideBar == true">{{user?.OrganisationName}}</span>
          <span [matTooltip]="user?.Email" style="display: block;
          font-size: 14px;
          color: #1d1d1e !important;
          width: 194px !important;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;" [hidden]="!mobileQuery.matches && sideBar != null && sideBar == true">{{user?.Email}}</span>
          
        </div>
      </div>
      <!-- Safwan Changes Add ng class and hidden for dual navigation on lg screen - 01/25/2023 - End 
    </li> -->
    <div class="">
      <links>
        <!-- Side navigation links -->
        <li>
         
          <ul class="collapsible collapsible-accordion">
            <mdb-accordion [multiple]="true" aria-multiselectable="false">
              <!-- Event Management -->
              <mdb-accordion-item [hidden]="permissionService.CheckIfUserhasAccess('Event Management')" [collapsed]="false">
                <mdb-accordion-item-head mdbWavesEffect class="accordian-heading" matTooltip="Event Booking">
                  <!-- Safwan Changes Add fontsize  and hidden for dual navigation on lg screen - 01/25/2023 - Start -->
                  <i class="far fa-calendar-check sideNavIconColor" style="font-size: 0.8rem;" ></i> 
                  <!-- <i class="far fa-calendar-check"  [style.font-size.rem]="!mobileQuery.matches && sideBar != null && sideBar == true ? '1.3' :'0.8'"></i> -->
                  <span >Event Booking</span>
                  <!-- Safwan Changes Add fontsize  and hidden for dual navigation on lg screen - 01/25/2023 - End -->
                </mdb-accordion-item-head>
                <mdb-accordion-item-body >
                  <ul>
                    <li [hidden]="permissionService.CheckIfUserhasAccess_Section('Booking Calendar')">
                      <a routerLink="/calendar" mdbWavesEffect>
                        <mdb-icon far icon="calendar-alt" class="sideNavIconColor" style="width: 8px;"></mdb-icon> <span>Booking Calendar</span>
                      </a>
                    </li>
                    <!-- <li *ngIf="permissionService.CheckIfUserhasAccess_Action('Event','Add')"
                      [hidden]="permissionService.CheckIfUserhasAccess_Section('Event')">
                      <a routerLink="/event/event/form" mdbWavesEffect>
                        <mdb-icon fas icon="plus"></mdb-icon> New Event
                      </a>
                    </li> -->
                    <li [hidden]="permissionService.CheckIfUserhasAccess_Section('Event List')">
                      <a routerLink="/event/event/list"  mdbWavesEffect>
                        <mdb-icon fas icon="fas fa-list-ul" class="sideNavIconColor" style="width: 8px;"></mdb-icon>Event List
                      </a>
                    </li>
                    <li *ngIf="false">
                      <a routerLink="/event/event-request/list" mdbWavesEffect>
                        <mdb-icon fas icon="external-link-alt"></mdb-icon>Event Request
                      </a>
                    </li>
                  </ul>
                </mdb-accordion-item-body>
              </mdb-accordion-item>
              <!-- Client Management -->
             <!-- <mdb-accordion-item [hidden]="permissionService.CheckIfUserhasAccess('Client Management')" [collapsed]="true">
                <mdb-accordion-item-head mdbWavesEffect class="accordian-heading">
                  <!-- Safwan Changes Add fontsize  and hidden for dual navigation on lg screen - 01/25/2023 - Start 
                  <i class="fa-user-friends fas" style="font-size: 0.6rem;" matTooltip="Client Management"></i> 
                  <!-- <i class="fa-user-friends fas" [style.font-size.rem]="!mobileQuery.matches && sideBar != null && sideBar == true ? '1.1' :'0.6'"></i> 
                   <span [hidden]="!mobileQuery.matches && sideBar != null && sideBar == true ">Client Management</span>
                   <!-- Safwan Changes Add fontsize  and hidden for dual navigation on lg screen - 01/25/2023 - End 
                </mdb-accordion-item-head>
                <mdb-accordion-item-body [hidden]="!mobileQuery.matches && sideBar != null && sideBar == true ">
                  <ul>
                     <li *ngIf="permissionService.CheckIfUserhasAccess_Action('Client','Add')"
                      [hidden]="permissionService.CheckIfUserhasAccess_Section('Client')">
                      <a routerLink="/client/form" mdbWavesEffect>
                        <i class="fas fa-plus" style="padding-right: 13px;"></i>Add Client
                      </a>
                    </li> 
                    <li [hidden]="permissionService.CheckIfUserhasAccess_Section('Client List')">
                      <a routerLink="/client/list" mdbWavesEffect>
                        <i class="fas fa-list-ul" style="padding-right: 11px;"></i>Client List
                      </a>
                    </li>
                  </ul>
                </mdb-accordion-item-body>
              </mdb-accordion-item> -->

              

              <a  [hidden]="permissionService.CheckIfUserhasAccess_Section('Client List')" routerLink="/client/list" matTooltip="Client Management" 
               class="accordian-heading mb-0 modules-heading">
                <span >
                  <i class="fas fa-user-friends sideNavIconColor" style="font-size: 0.6rem;" ></i>
                  <span >Client Management</span>
                </span>
              </a>

              <a  [hidden]="permissionService.CheckIfUserhasAccess_Action('Contract Information','Add') == false && permissionService.CheckIfUserhasAccess_Action('Contract Information','Edit') == false" routerLink="/event/eventcontracts/list" 
               class="accordian-heading mb-0 modules-heading" matTooltip="Contract Management">
                <span >
                  <i class="fas fa-file-contract sideNavIconColor" style="font-size: 0.8rem;" ></i>
                  <span >Contract Management</span>
                </span>
              </a>

              <a  [hidden]="permissionService.CheckIfUserhasAccess_Section('Event Documents')" routerLink="/event/eventdocuments/list"  matTooltip="Document Center"
               class="accordian-heading mb-0 modules-heading">
                <span >
                  <i class="fas fa-file-alt sideNavIconColor" style="font-size: 0.8rem;" ></i>
                  <span  >Document Center</span>
                </span>
              </a>
              
              <a  [hidden]="permissionService.CheckIfUserhasAccess_Action('Deposit Schedule','Add') == false && permissionService.CheckIfUserhasAccess_Action('Deposit Schedule','Edit') == false" routerLink="/event/eventdueamount/list" 
               class="accordian-heading mb-0 modules-heading" matTooltip="Payment Tracker">
                <span >
                  <i class="fas fa-clipboard-list sideNavIconColor" style="font-size: 0.8rem;" ></i>
                  <span  >Payment Tracker</span>
                </span>
              </a>
              
              <a  [hidden]="permissionService.CheckIfUserhasAccess_Section('Settlement List')" routerLink="/settlement/list" 
               class="accordian-heading mb-0 modules-heading" matTooltip="Income Statement">
                <span >
                  <i class="fas fa-handshake sideNavIconColor" style="font-size: 0.6rem;" ></i>
                  <span  >Income Statement</span>
                </span>
              </a>
              
              <!-- Event Settlement -->
            <!--  <mdb-accordion-item [hidden]="permissionService.CheckIfUserhasAccess('Event Settlement')" [collapsed]="true">
                <mdb-accordion-item-head mdbWavesEffect class="accordian-heading">
                  <!-- Safwan Changes Add fontsize  and hidden for dual navigation on lg screen - 01/25/2023 - Start 
                  <i class="far fa-handshake" style="font-size: 0.6rem;" matTooltip="Event Income Statement"></i> 
                  <!-- <i class="far fa-handshake" [style.font-size.rem]="!mobileQuery.matches && sideBar != null && sideBar == true ? '1.3' :'0.6'"></i> 
                  <span [hidden]="!mobileQuery.matches && sideBar != null && sideBar == true ">Event Income Statement</span>
                  <!-- Safwan Changes Add fontsize  and hidden for dual navigation on lg screen - 01/25/2023 - End
                </mdb-accordion-item-head>
                <mdb-accordion-item-body [hidden]="!mobileQuery.matches && sideBar != null && sideBar == true ">
                  <ul>
                    <li *ngIf="permissionService.CheckIfUserhasAccess_Action('Settlement','Add')"
                      [hidden]="permissionService.CheckIfUserhasAccess_Section('Settlement')">
                      <a routerLink="/settlement/form" mdbWavesEffect>
                        <mdb-icon fas icon="fas fa-plus"></mdb-icon>New Settlement
                      </a>
                    </li>
                    <li [hidden]="permissionService.CheckIfUserhasAccess_Section('Settlement List')">
                      <a routerLink="/settlement/list" mdbWavesEffect>
                        <i class="fas fa-list-ul" style="padding-right: 13px;"></i>Event Income Statement List
                      </a>
                    </li>

                  </ul>
                </mdb-accordion-item-body>
              </mdb-accordion-item>  -->
              <!-- Event Budgeting links -->
              <mdb-accordion-item [hidden]="permissionService.CheckIfUserhasAccess('Event Reports') == true && permissionService.showBuyButton() == false" [collapsed]="true">
                <mdb-accordion-item-head mdbWavesEffect class="accordian-heading" matTooltip="Reports">
                  <!-- Safwan Changes Add fontsize  and hidden for dual navigation on lg screen - 01/25/2023 - Start -->
                  <i class="far fa-file-alt sideNavIconColor" style="font-size: 0.9rem;;" ></i> 
                  <!-- <i class="far fa-file-alt" [style.font-size.rem]="!mobileQuery.matches && sideBar != null && sideBar == true ? '1.4' :'0.9'"></i> -->
                  <span >Reports</span>
                  <!-- Safwan Changes Add fontsize  and hidden for dual navigation on lg screen - 01/25/2023 - End -->
                </mdb-accordion-item-head>
                <mdb-accordion-item-body >
                  <ul>

                    <li >
                      <a [hidden]="permissionService.CheckIfUserhasAccess_Section('Event Overview') == true && permissionService.checkIsFreeTrial() == false" routerLink="/report/event-overview" mdbWavesEffect>
                        <mdb-icon fas icon="external-link-alt" class="sideNavIconColor" style="width: 8px;"></mdb-icon>Event Overview
                      </a>
                      <a *ngIf="permissionService.showBuyButton() == true && permissionService.checkIsFreeTrial() == false "  mdbWavesEffect class="d-flex justify-content-between" (click)="upgradeBasicToPremium()">
                        <span>
                          <mdb-icon fas icon="external-link-alt" class="sideNavIconColor" style="width: 8px;"></mdb-icon>Event Overview
                        </span>
                        <span class="badge badge-pill badge-success buy-text">Premium</span>
                      </a>
                    </li>
                    <!-- <li [hidden]="permissionService.CheckIfUserhasAccess_Section('Event Master')">
                    <a  routerLink="/report/event-revenue" mdbWavesEffect>
                      <mdb-icon fas icon="external-link-alt"></mdb-icon>Event Master
                    </a>
                  </li>  -->
                    <!-- <li [hidden]="permissionService.CheckIfUserhasAccess_Section('Event Rental Days')">
                      <a  routerLink="/report/rental-days" mdbWavesEffect>
                        <mdb-icon fas icon="external-link-alt"></mdb-icon>Event Rental Days
                      </a>
                  </li>   -->
                    <li >
                      <a [hidden]="permissionService.CheckIfUserhasAccess_Section('Event Summary') == true && permissionService.checkIsFreeTrial() == false" routerLink="/report/event-summary" mdbWavesEffect>
                        <mdb-icon fas icon="external-link-alt" class="sideNavIconColor" style="width: 8px;"></mdb-icon>Event Summary
                      </a>
                      <a *ngIf="permissionService.showBuyButton() == true && permissionService.checkIsFreeTrial() == false"(click)="upgradeBasicToPremium()"  mdbWavesEffect class="d-flex justify-content-between">
                        <span>
                          <mdb-icon fas icon="external-link-alt" class="sideNavIconColor" style="width: 8px;"></mdb-icon>Event Summary
                        </span>
                        <span class="badge badge-pill badge-success buy-text">Premium</span>
                      </a>
                    </li>
                    <li >
                      <a [hidden]="permissionService.CheckIfUserhasAccess_Section('Event Highlights') == true && permissionService.checkIsFreeTrial() == false" routerLink="/report/event-highlights" mdbWavesEffect>
                        <mdb-icon fas icon="external-link-alt" class="sideNavIconColor" style="width: 8px;"></mdb-icon>Event Highlights
                      </a>
                      <a *ngIf="permissionService.showBuyButton() == true && permissionService.checkIsFreeTrial() == false"  (click)="upgradeBasicToPremium()"  mdbWavesEffect class="d-flex justify-content-between">
                        <span>
                          <mdb-icon fas icon="external-link-alt" class="sideNavIconColor" style="width: 8px;"></mdb-icon>Event Highlights
                        </span>
                        <span class="badge badge-pill badge-success buy-text">Premium</span>
                      </a>
                    </li>
                    <li >
                      <a  routerLink="/report/dasboard-analytics" mdbWavesEffect>
                        <mdb-icon fas icon="external-link-alt" class="sideNavIconColor" style="width: 8px;"></mdb-icon>Ticket Sales
                      </a>
                      
                    </li>
                    <!-- <li>
                      <a routerLink="/report/avail-report" mdbWavesEffect>
                        <mdb-icon fas icon="external-link-alt" class="sideNavIconColor"></mdb-icon>Calendar Avail
                      </a>
                    </li> -->
                    <!-- <li [hidden]="permissionService.CheckIfUserhasAccess_Section('Settlement List')">
                    <a  routerLink="/settlement/settlement-master-report" mdbWavesEffect>
                      <mdb-icon fas icon="external-link-alt"></mdb-icon>Settlement Master Report
                    </a>
                  </li> -->

                  </ul>
                </mdb-accordion-item-body>
              </mdb-accordion-item>
              <!-- Admin Center -->
              <mdb-accordion-item [hidden]="permissionService.CheckIfUserhasAccess('Admin Center') == true && permissionService.showBuyButton() == false" [collapsed]="true">
                <mdb-accordion-item-head mdbWavesEffect class="accordian-heading" matTooltip="Admin Center">
                  <!-- Safwan Changes Add fontsize  and hidden for dual navigation on lg screen - 01/25/2023 - Start -->
                  <i class="fas fa-cogs sideNavIconColor" style="font-size: 0.6rem;" ></i> 
                  <!-- <i class="fas fa-cogs" [style.font-size.rem]="!mobileQuery.matches && sideBar != null && sideBar == true ? '1.1' :'0.6'"></i> -->
                  <span>Admin Center</span>
                  <!-- Safwan Changes Add fontsize  and hidden for dual navigation on lg screen - 01/25/2023 - End -->
                </mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <ul>

                    <li >
                      <a [hidden]="permissionService.CheckIfUserhasAccess_Section('Contract Fields') == true  && permissionService.checkIsFreeTrial() == false" routerLink="/setup/contracttypes/list" mdbWavesEffect>
                        <mdb-icon fas icon="file-contract" class="sideNavIconColor" style="width: 8px;"></mdb-icon>Contract Fields
                      </a>

                      <a *ngIf="permissionService.showBuyButton() == true  && permissionService.checkIsFreeTrial() == false"  (click)="upgradeBasicToPremium()"  mdbWavesEffect class="d-flex justify-content-between">
                        <span >
                          <mdb-icon fas icon="file-contract" class="sideNavIconColor" style="width: 8px;"></mdb-icon>Contract Fields
                        </span>
                        <!-- <span class="badge badge-pill badge-success buy-text">Buy</span> -->
                        <span class="badge badge-pill badge-success buy-text">Premium</span>
                      </a>
                    </li>
                    <li [hidden]="permissionService.CheckIfUserhasAccess_Section('Contract Information')">
                      <a routerLink="/setup/contracts" mdbWavesEffect>
                        <mdb-icon fas icon="file-contract" class="sideNavIconColor" style="width: 8px;"></mdb-icon>Contract Templates
                      </a>
                    </li>


                    <li [hidden]="permissionService.CheckIfUserhasAccess_Section('Venue')">
                      <a routerLink="/setup/venue/list" mdbWavesEffect>
                        <mdb-icon fas icon="map-marker-alt" class="sideNavIconColor" style="width: 8px;"></mdb-icon>Venue
                      </a>
                    </li>

                    <li >
                      <a [hidden]="permissionService.CheckIfUserhasAccess_Section('Event Status') == true  && permissionService.checkIsFreeTrial() == false" routerLink="/setup/eventstatus/list" mdbWavesEffect>
                        <mdb-icon fas icon="external-link-alt" class="sideNavIconColor" style="width: 8px;"></mdb-icon>Event Status
                      </a>
                      <a *ngIf="permissionService.showBuyButton() == true  && permissionService.checkIsFreeTrial() == false"  (click)="upgradeBasicToPremium()" mdbWavesEffect class="d-flex justify-content-between">
                        <span>
                          <mdb-icon fas icon="external-link-alt" class="sideNavIconColor" style="width: 8px;"></mdb-icon>Event Status
                        </span>
                        <span class="badge badge-pill badge-success buy-text">Premium</span>
                      </a>
                    </li>
                    <li>
                      <a  [hidden]="permissionService.CheckIfUserhasAccess_Section('Genre') == true  && permissionService.checkIsFreeTrial() == false" routerLink="/setup/genre/list" mdbWavesEffect>
                        <mdb-icon fas icon="compact-disc" class="sideNavIconColor" style="width: 8px;"></mdb-icon>Genre
                      </a>
                      <a *ngIf="permissionService.showBuyButton() == true  && permissionService.checkIsFreeTrial() == false"  (click)="upgradeBasicToPremium()" mdbWavesEffect class="d-flex justify-content-between">
                        <span>
                          <mdb-icon fas icon="compact-disc" class="sideNavIconColor" style="width: 8px;"></mdb-icon>Genre
                        </span>
                        <span class="badge badge-pill badge-success buy-text">Premium</span>
                      </a>
                    </li>
                    <li >
                      <a [hidden]="permissionService.CheckIfUserhasAccess_Section('Bank') == true  && permissionService.checkIsFreeTrial() == false"  routerLink="/setup/bank/list" mdbWavesEffect>
                        <mdb-icon fas icon="money-check" class="sideNavIconColor" style="width: 8px;"></mdb-icon>Bank
                      </a>
                      <a *ngIf="permissionService.showBuyButton() == true  && permissionService.checkIsFreeTrial() == false"  (click)="upgradeBasicToPremium()"  mdbWavesEffect class="d-flex justify-content-between">
                        <span>
                          <mdb-icon fas icon="money-check" class="sideNavIconColor" style="width: 8px;"></mdb-icon>Bank
                        </span>
                        <span class="badge badge-pill badge-success buy-text">Premium</span>
                      </a>
                    </li>
                    <li >
                      
                      <a [hidden]="permissionService.CheckIfUserhasAccess_Section('Holiday') == true  && permissionService.checkIsFreeTrial() == false" routerLink="/setup/holiday/list" mdbWavesEffect>
                        <mdb-icon fas icon="calendar-day" class="sideNavIconColor" style="width: 8px;"></mdb-icon>Holidays
                      </a>
                      <a *ngIf="permissionService.showBuyButton() == true  && permissionService.checkIsFreeTrial() == false"  (click)="upgradeBasicToPremium()" mdbWavesEffect class="d-flex justify-content-between">
                        <span>
                          <mdb-icon fas icon="calendar-day" class="sideNavIconColor" style="width: 8px;"></mdb-icon>Holidays
                        </span>
                        <span class="badge badge-pill badge-success buy-text">Premium</span>
                      </a>
                    </li>
                    <li >
                      <a [hidden]="permissionService.CheckIfUserhasAccess_Section('Settlement Group') == true  && permissionService.checkIsFreeTrial() == false" routerLink="/setup/settlementgroup/list" mdbWavesEffect>
                        <mdb-icon fas icon="layer-group" class="sideNavIconColor" style="width: 8px;"></mdb-icon>GL Account Groups
                        <!-- <mdb-icon fas icon="layer-group" style="width: 25px;"></mdb-icon>Settlement Group -->
                      </a>
                      <a *ngIf="permissionService.showBuyButton() == true  && permissionService.checkIsFreeTrial() == false"  (click)="upgradeBasicToPremium()"  mdbWavesEffect class="d-flex justify-content-between">
                        <span>
                          <mdb-icon fas icon="layer-group" class="sideNavIconColor" style="width: 8px;"></mdb-icon>GL Account Groups
                        </span>
                        <span class="badge badge-pill badge-success buy-text">Premium</span>
                      </a>
                    </li>
                    <li >
                      <a [hidden]="permissionService.CheckIfUserhasAccess_Section('Settlement GL Account') == true  && permissionService.checkIsFreeTrial() == false" routerLink="/setup/settlementglaccount/list" mdbWavesEffect>
                        <mdb-icon fas icon="bars" class="sideNavIconColor" style="width: 8px;"></mdb-icon>GL Accounts
                        <!-- <mdb-icon fas icon="bars" style="width: 25px;"></mdb-icon>Settlement GL Account -->
                      </a>
                      <a *ngIf="permissionService.showBuyButton() == true  && permissionService.checkIsFreeTrial() == false"  (click)="upgradeBasicToPremium()"  mdbWavesEffect class="d-flex justify-content-between">
                        <span>
                          <mdb-icon fas icon="bars" class="sideNavIconColor" style="width: 8px;"></mdb-icon>GL Accounts
                        </span>
                        <span class="badge badge-pill badge-success buy-text">Premium</span>
                      </a>
                    </li>
                    <!-- <li>
                      <a  routerLink="/customer/list" mdbWavesEffect>
                        <mdb-icon fas icon="external-link-alt" style="width: 25px;"></mdb-icon>Customers
                      </a>
                    </li> -->


                    <li [hidden]="permissionService.CheckIfUserhasAccess_Section('Users')">
                      <a routerLink="/rbac/rbacuser/list" mdbWavesEffect>
                        <mdb-icon fas icon="user" style="width: 8px;" class="sideNavIconColor"></mdb-icon>User
                      </a>
                    </li>

                    <li [hidden]="permissionService.CheckIfUserhasAccess_Section('Group')" *ngIf="false">
                      <a routerLink="/rbac/rbacgroup/list" mdbWavesEffect>
                        <mdb-icon fas icon="users" style="width: 25px;" class="sideNavIconColor"></mdb-icon>Group
                      </a>
                    </li>

                    <li [hidden]="permissionService.CheckIfUserhasAccess_Section('Permission')" *ngIf="false">
                      <a routerLink="/rbac/rbacpermission/list" mdbWavesEffect>
                        <mdb-icon fas icon="users-cog" style="width: 25px;" class="sideNavIconColor"></mdb-icon>Permission
                      </a>
                    </li>
                    <!-- <li [hidden]="permissionService.CheckIfUserhasAccess_Section('Settings')">
                      <a  routerLink="/setup/settings" mdbWavesEffect>
                        <mdb-icon fas icon="users-cog" style="width: 25px;"></mdb-icon>Settings
                      </a>
                    </li> -->

                  </ul>
                </mdb-accordion-item-body>
              </mdb-accordion-item>





            </mdb-accordion>
          </ul>

        </li>

        <!-- <li class="footer"  style="
        bottom: 0;
        position: fixed;
        background: #d9d9d9;
        width: 100%;
        text-align: center;
        padding: 0px 19px;
        border-top: 1px solid rgba(153,153,153,.3) !important;
        box-shadow: 0px -1px 3px 0 rgb(153 153 153 / 30%);">

          <span class="" style="color: #1d1d1e;font-size: 11px;">

            Product By

            <a href="https://www.alrafayglobal.com/" target="_blank" style="text-decoration-line: revert;
          color: #095e8f;
          font-size: 13px;
          display: inline;
          padding: 0;
          font-weight: 500;">

              Al Rafay Consulting

            </a>

          </span>

        </li> -->

        <!--/. Side navigation links -->
        <div class="sidenav-bg mask-strong"></div>

      </links>
    </div>
    <!-- Footer -->
    <!-- Safwan Changes Add hidden for dual navigation on lg screen- 01/25/2023 - Start -->
    <li class="footer accordian-heading" >
      <span class="" style="color: #1d1d1e; font-size:11px;">
        Product By
        <a href="https://www.alrafayglobal.com/" target="_blank"
          style="color: #095e8f;font-size: 13px;display: inline;padding: 0;font-weight: 500;">
          Al Rafay Consulting
        </a>
      </span>
    </li>
    <!-- Safwan Changes Add hidden for dual navigation on lg screen- 01/25/2023 - End -->


    <!-- Footer -->

  </mdb-side-nav>

  <!--Right Nav   Work-->

  <div mdbModal #basicModal="mdbModal" class="modal fade right" tabindex="-1" role="dialog"
    aria-labelledby="myBasicModalLabel" [config]="{backdrop: false, ignoreBackdropClick: false}" aria-hidden="true">
    <div class="modal-dialog modal-full-height modal-right" role="document">
      <div class="modal-content">
        <div class="modal-header auditTop">
          <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()">
            <span aria-hidden="true">×</span>
          </button>
          <h5 class="modal-title w-100" id="myModalLabel">Recent Activity</h5>
        </div>
        <div class="modal-body scrollBar">
          <app-audit-log></app-audit-log>
        </div>

      </div>
    </div>
  </div>

  <!--/. Sidebar navigation -->
  <!-- Navbar -->
  <mdb-navbar id="navTop" SideClass="navbar fixed-top navbar-toggleable-md navbar-expand-lg  double-nav "
    [containerInside]="false">


    <div class="col-md-4 col-sm-5 col-6 top-desk">
      <a (click)="sidenav.toggle();" class="iconshadow  toggleBtn menu-btn"> <!--</a>d-none"> -->
      <!-- Safwan Changes Add hidden and Icon of X  - 01/25/2023 - Start -->

      <!-- <mdb-icon fas icon="bars" [hidden]="this.istoggleClick"></mdb-icon> -->

      <mdb-icon fas icon="bars"></mdb-icon>
      <!-- <mdb-icon fas icon="times" ></mdb-icon> -->

      <!-- <mdb-icon fas icon="bars" [hidden]="!(this.istoggleClick)"></mdb-icon> -->

      <!-- Safwan Changes Add hidden and Icon of X  - 01/25/2023 - End -->
      </a>
      <a class="pl-3 AppIcon" routerLink="/calendar" [title]="'Event Management Automated Platform'">
        <img src="assets/venue_arc_top.png" alt="image"  class="venuearc_logo">
      </a>
      <div [hidden]="true"> Seconds: {{intervalId}} / API call count : {{ApiCallCount}} </div>
    </div>


    <!-- <div class="col-md-3 col-sm-4 col-5">

        <a routerLink="/calendar" [title]="'Event Management Automated Platform'" >
            <img [src]="AppLogoImg" alt="image">
        </a>
      </div> -->


    <div class="col-md-8 col-sm-4 col-6 text-right align-middle top-desk">
    <!-- <div class="col-md-8 col-sm-4 col-1 text-right align-middle"> -->
      <div class=" ">

        <!-- <button
          mat-icon-button
          type="button"
          class="float-right ml-2 upgrade upgrade-icon"
          *ngIf=" user?.SubscriptionType == 'stripe' && user?.Subscription == 'free' && isAdmin == true"
          matTooltip="Upgrade"
          (click)="upgrade()">
            <mat-icon>upgrade</mat-icon>
            <span class="ml-2">Upgrade</span>
          </button> -->

        <!-- <button mat-button (click)="openDummyEvents()">Add Dummy Events</button> -->

       
        <button mat-button
          *ngIf="user?.SubscriptionType == 'stripe' && user?.Subscription == 'free' && isAdmin == true && isOwner == true"
          type="button"
          class="float-right ml-3 upgrade upgrade-icon upgrade-lg d-none d-sm-none d-md-inline d-xl-inline d-lg-inline"
          matTooltip="Upgrade" (click)="upgrade()">
          <mat-icon>upgrade</mat-icon>
          <span class="">Upgrade</span>
        </button>
        <!-- <button class="user-button float-right ml-3 upgrade upgrade-sm d-md-none d-xl-none d-lg-none" style="color:white;" (click)="upgrade()"  mat-mini-fab matTooltip="Upgrade">
            <mat-icon>upgrade</mat-icon>
          </button> -->
          <!-- <button mat-button class="mr-2 d-none quick-event-btn-mob" (click)="openQuickEventForm();">
          <mdb-icon fas icon="plus" class="mr-1"></mdb-icon>Event
        </button> -->
        <button class="mr-name float-right user-button ml-2 setting-btn" mat-mini-fab [matMenuTriggerFor]="setting" #settingTrigger="matMenuTrigger" 
          [matTooltip]="user?.UserName">
          <span>{{user?.ShortCode}} </span>
        </button>
       
        


        <mat-menu #setting="matMenu">
          <p class="text-center mt-2 px-2" [matTooltip]="user?.Email"> <b> {{user?.UserName}} </b></p>
          <mat-divider></mat-divider>
          <button mat-menu-item class="menu-width" type="button" (click)="upgradetoenterprise()"
            *ngIf="(user?.Subscription == 'premium' || user?.Subscription == 'organization') && isOwner == true">
            <mat-icon>arrow_upward</mat-icon>
            Upgrade
          </button>
          

          <!-- <button mat-menu-item (click)="onNewSigning()"  class="btn menu-width">new sign in?</button> -->

          <button mat-menu-item class="menu-width"  type="button" (click)="quickTour()">
            
            <!-- <mat-icon>rocket</mat-icon> -->
            <mat-icon>rocket_launch</mat-icon>
            Take a Tour
          </button>

          <button mat-menu-item class="menu-width d-md-none d-lg-none d-xl-none" type="button" (click)="upgrade()"
            *ngIf="user?.SubscriptionType == 'stripe' && user?.Subscription == 'free' && isAdmin == true && isOwner == true">
            <mat-icon>arrow_upward</mat-icon>
            Upgrade
          </button>
          <mat-divider></mat-divider>

          <!-- <button mat-menu-item class="menu-width " type="button"
            [hidden]="permissionService.CheckIfUserhasAccess_Section('Settings')" routerLink="/setup/settings">
            <mat-icon>dashboard</mat-icon>
            Settings
          </button> -->
          <!-- Safwan changes show setting button to all user - Start - 01/05/2023 -->
          <button mat-menu-item class="menu-width " type="button"
           routerLink="/setup/settings">
          <mat-icon>dashboard</mat-icon>
          Settings
        </button>
        <!-- Safwan changes show setting button to all user - End - 01/05/2023 -->

          <mat-divider></mat-divider>
          <button mat-menu-item class="menu-width" type="button" (click)="logout()" *ngIf="teams.isTeamsApp() == false" ><mat-icon>logout</mat-icon>Sign
            out</button>
          <!-- <button mat-menu-item class="menu-width" type="button" (click)="switchSubscription()" *ngIf="showSwitch"><mat-icon>toggle_on</mat-icon>Switch Subscription</button> -->
          <!-- <p  class="subsciptionName">{{user?.SubscriptionName}}</p> -->
        </mat-menu>
      
      </div>

<!-- 
      <button class=" user-button ml-2" mat-mini-fab [matMenuTriggerFor]="event" >
        <mat-icon style="font-size: 20px;">add</mat-icon>
      </button> -->

        <!-- <button mat-button class="mr-4 add-pop" [matMenuTriggerFor]="event" #eventTrigger="matMenuTrigger"
          style="background: aliceblue !important;color: #095e8f;">
          <mdb-icon fas icon="plus" class="mr-1"></mdb-icon>Add 
        </button> -->
        <button mat-button class="mr-4 " *ngIf="permissionService.CheckIfUserhasAccess_Action('Event','Add')" (click)="openQuickEventForm();" [hidden]="true"
          style="background: aliceblue !important;color: #095e8f;">
          <mdb-icon fas icon="plus" class="mr-1"></mdb-icon>Add Event 
        </button>

        <mat-menu #event="matMenu">
          
          <!-- <button mat-menu-item [matMenuTriggerFor]="eventBtn" class="menu-width event-btn" type="button"  *ngIf="permissionService.CheckIfUserhasAccess_Action('Event','Add')"> -->
          <button mat-menu-item class="menu-width event-btn" type="button"  *ngIf="permissionService.CheckIfUserhasAccess_Action('Event','Add')" (click)="openEventForm();">
            <!-- <mdb-icon fas icon="plus" class="mr-1"></mdb-icon> -->
             Event
          </button>
          <button mat-menu-item class="menu-width client-btn" type="button" (click)="openQuickClientForm()" *ngIf="permissionService.CheckIfUserhasAccess_Action('Client','Add')">
            <!-- <mdb-icon fas icon="plus" class="mr-1"></mdb-icon> -->
             Client
          </button>
          <button mat-menu-item class="menu-width venue-btn" type="button" (click)="openQuickVeuneForm()" *ngIf="permissionService.CheckIfUserhasAccess_Action('Venue','Add')">
            <!-- <mdb-icon fas icon="plus" class="mr-1"></mdb-icon> -->
             Venue
          </button>

        </mat-menu>
        <!-- <mat-menu #eventBtn="matMenu">
          <button mat-menu-item class="menu-width" (click)="openQuickEventForm();">
            One-Day Event
          </button>
          <button mat-menu-item class="menu-width" (click)="openEventForm();">
            Multi-Day Event
          </button>
        </mat-menu> -->

        <!-- <mat-form-field appearance="outline"  class="matSearchFields d-none " style="" [ngClass]="searchbar?'search-open':'seacrh-close'"> -->
        <mat-form-field appearance="outline" class="matSearchFields mr-1 d-none d-md-inline  d-lg-inline d-sm-none"
          style="float: right" [@openClose]="searchbar ? 'open' : 'close'">
          <mat-icon matPrefix class="mr-2" (click)="openSearch()" [hidden]="!searchbar">search</mat-icon>
          <input matInput placeholder="Search by Event ID or Name..." [matAutocomplete]="auto"
            [formControl]="searchMoviesCtrl">
          <button mat-button *ngIf="searchMoviesCtrl.value" matSuffix mat-icon-button aria-label="Clear"
            (click)="searchMoviesCtrl.setValue('')">
            <mat-icon>close</mat-icon>
          </button>
          <mat-autocomplete class="matSearchFieldsOptions" #auto="matAutocomplete"
            (optionSelected)="onEventClick($event.option.value)">
            <mat-option *ngIf="isLoading" class="is-loading" disabled><span matSuffix
                class="spinner-border spinner-border-sm mr-2"></span>Searching... </mat-option>
            <mat-option disabled *ngIf="EventData.length > 0 && !isLoading"
              style=" position: sticky;top: 0;z-index: 1;background-color: white; line-height:22px;border-bottom: 1px solid #d6d6d8; text-align: center; color: gray;">
              Results found: {{EventData.length}}
            </mat-option>
            <ng-container *ngIf="!isLoading">

              <mat-option *ngFor="let event of EventData" [value]="event.ID" style="font-size:14px;line-height:22px;border-bottom: 1px solid #d6d6d8;padding-top: 4px;
              padding-bottom: 4px; height:78px;">
                <a>

                  <b style=" font-size: 16px">
                    {{event.EventName}}
                  </b>
                  <br>
                  <b style="font-weight: 500; font-size: 13px; color:gray">Event ID: {{event.EntryNo}} | Start Date:
                    {{common.BLFormatDate(event.EventStartDate) | date:'MM/dd/yyyy'}} | End Date:
                    {{common.BLFormatDate(event.EventEndDate) | date:'MM/dd/yyyy'}} | Venue: {{event.VenueName}} </b>

                </a>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>
        <button mat-button (click)="addUser()" class="mr-4" style="background: aliceblue !important;color: #095e8f;">
          <!-- <mat-icon  matTooltip="Invite User"class="d-none d-sm-none d-md-inline d-xl-inline d-lg-inline align-bottom mr-2 ml-2"style="cursor: pointer;line-height: 1.3 !important;">person_add</mat-icon> -->
          <mdb-icon fas icon="user-plus"></mdb-icon>
          Invite 
      </button>
      
      <mat-icon [matMenuTriggerFor]="event" #eventTrigger="matMenuTrigger"
      class="d-none d-sm-none d-md-inline d-xl-inline d-lg-inline align-bottom add-pop ml-1"
      style="cursor: pointer;line-height: 1.3 !important;">add
    </mat-icon>

        <mat-icon (click)="basicModal.show();"
        class="d-none d-sm-none d-md-inline d-xl-inline d-lg-inline align-bottom mr-1 ml-1"
        style="cursor: pointer;line-height: 1.3 !important;">notifications
      </mat-icon>

      
       

        <mdb-icon fas icon="search" class="d-inline d-sm-inline d-md-none d-xl-none d-lg-none align-bottom ml-1"
          (click)="test.toggle()"></mdb-icon>

        <mat-icon class=" d-none d-sm-none d-md-inline d-xl-inline d-lg-inline align-bottom mr-2" (click)="openSearch()"
          *ngIf="searchbar != null && searchbar" style="cursor: pointer;line-height: 1.3 !important;">close</mat-icon>
        <!-- *ngIf="searchbar != null && searchbar" style="cursor: pointer;line-height: 1.6 !important;">close</mat-icon> -->
        <mat-icon class="mr-2 d-none d-sm-none d-md-inline d-xl-inline d-lg-inline align-bottom ml-1"
          (click)="openSearch()" *ngIf="searchbar == null || !searchbar"
          style="cursor: pointer;line-height: 1.3 !important;">search</mat-icon>
    </div>


    <div class="col-7 top-mob">
      <a (click)="sidenav.toggle();setSideNav();" class="iconshadow  toggleBtn "> <!--</a>d-none"> -->
      <!-- Safwan Changes Add hidden and Icon of X  - 01/25/2023 - Start -->
      <!-- <mdb-icon fas icon="bars" [hidden]="this.istoggleClick"></mdb-icon>
      <mdb-icon fas icon="times" [hidden]="!(this.istoggleClick)"></mdb-icon> -->
      <mdb-icon fas icon="times" ></mdb-icon>
      <!-- Safwan Changes Add hidden and Icon of X  - 01/25/2023 - End -->
      </a>
      <a class="pl-1 AppIcon" routerLink="/calendar" [title]="'Event Management Automated Platform'">
        <img src="assets/venue_arc_top.png" alt="image"  class="venuearc_logo">
      </a>
      <div [hidden]="true"> Seconds: {{intervalId}} / API call count : {{ApiCallCount}} </div>
    </div>

    <div class="col-5 top-mob" style="padding:0;">
    
      <div class="d-flex align-items-center justify-content-end">

        <!-- <button mat-button class="mr-2 " *ngIf="permissionService.CheckIfUserhasAccess_Action('Event','Add')" (click)="openQuickEventForm();"
          style="background: aliceblue !important;color: #095e8f;padding: 0 5px;height: 28px;line-height: 28px;font-size: 12px;">
          <mdb-icon fas icon="plus" class="mr-1"></mdb-icon>Event
        </button> -->

        <mdb-icon fas icon="search" class="mr-1 d-inline d-sm-inline mr-2 d-md-none d-xl-none d-lg-none align-bottom ml-1"
          (click)="test.toggle()"></mdb-icon>

        <button class="mr-name  user-button-mob " mat-mini-fab [matMenuTriggerFor]="eventmob" >
          <mat-icon style="font-size: 20px;">add</mat-icon>
        </button>

        <button class=" user-button-mob " mat-mini-fab [matMenuTriggerFor]="settingmob" [matTooltip]="user?.UserName">
          <span>{{user?.ShortCode}} </span>
        </button>

        <mat-menu #settingmob="matMenu">
          <p class="text-center mt-2 px-2"> <b> {{user?.UserName}} </b></p>
          <mat-divider></mat-divider>
          <button mat-menu-item class="menu-width" type="button" (click)="upgradetoenterprise()"
            *ngIf="(user?.Subscription == 'premium' || user?.Subscription == 'organization') && isOwner == true">
            <mat-icon>arrow_upward</mat-icon>
            Upgrade
          </button>
          <button mat-menu-item class="menu-width d-md-none d-lg-none d-xl-none" type="button" (click)="upgrade()"
            *ngIf="user?.SubscriptionType == 'stripe' && user?.Subscription == 'free' && isAdmin == true && isOwner == true">
            <mat-icon>arrow_upward</mat-icon>
            Upgrade
          </button>
          <mat-divider></mat-divider>
          <!-- Safwan changes show setting button to all user - Start - 01/05/2023 -->
          <button mat-menu-item class="menu-width " type="button" (click)="quickTour()">
            <mat-icon>rocket_launch</mat-icon>
            Take a Tour
          </button>

          <button mat-menu-item class="menu-width " type="button" routerLink="/setup/settings">
            <mat-icon>dashboard</mat-icon>
            Settings
          </button>
          <!-- Safwan changes show setting button to all user - End - 01/05/2023 -->
      
          <mat-divider></mat-divider>
          <button mat-menu-item class="menu-width" type="button" (click)="logout()">
            <mat-icon>logout</mat-icon>Sign out
          </button>
      
        </mat-menu>

        <mat-menu #eventmob="matMenu">
          
          <button mat-menu-item class="menu-width " type="button" (click)="openEventForm();"  *ngIf="permissionService.CheckIfUserhasAccess_Action('Event','Add')">
            <!-- <mdb-icon fas icon="plus" class="mr-1"></mdb-icon> -->
            Add Event
          </button>
          <button mat-menu-item class="menu-width client-btn" type="button" (click)="openQuickClientForm()" *ngIf="permissionService.CheckIfUserhasAccess_Action('Client','Add')">
            <!-- <mdb-icon fas icon="plus" class="mr-1"></mdb-icon> -->
             Add Client
          </button>
          <button mat-menu-item class="menu-width venue-btn" type="button" (click)="openQuickVeuneForm()" *ngIf="permissionService.CheckIfUserhasAccess_Action('Venue','Add')">
            <!-- <mdb-icon fas icon="plus" class="mr-1"></mdb-icon> -->
             Add Venue
          </button>
        </mat-menu>

        <mat-menu #eventBtnMob="matMenu">
          <button mat-menu-item class="menu-width" (click)="openQuickEventForm();">
            One-Day Event
          </button>
          <button mat-menu-item class="menu-width" (click)="openEventForm();">
            Multi-Day Event
          </button>
        </mat-menu>
      
      </div>
    </div>

    <!-- <div class="col-md-1">
          <mdb-icon fas icon="search" class="d-inline d-sm-inline d-md-none d-xl-none d-lg-none align-bottom" (click)="test.toggle()"></mdb-icon>
          <a class="iconshadow" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>settings</mat-icon>
          </a>
            <mat-menu #menu="matMenu">
              <button mat-menu-item>
                <mat-icon>dialpad</mat-icon>
                <span>Redial</span>
              </button>
            </mat-menu>
      </div> -->

    <div class="col-12 col-sm-12 mt-2" mdbCollapse #test="bs-collapse">
      <mat-form-field appearance="outline"
        class="matSearchFields d-inline d-sm-inline d-md-none d-xl-none d-lg-none p-0">
        <mat-icon matPrefix class="mr-2">search</mat-icon>
        <input matInput placeholder="Search by Event ID or Event Name..." [matAutocomplete]="auto"
          [formControl]="searchMoviesCtrl">
        <button mat-button *ngIf="searchMoviesCtrl.value" matSuffix mat-icon-button aria-label="Clear"
          (click)="searchMoviesCtrl.setValue('')">
          <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete class="matSearchFieldsOptions" #auto="matAutocomplete"
          (optionSelected)="onEventClick($event.option.value)">
          <mat-option *ngIf="isLoading" class="is-loading" disabled><span matSuffix
              class="spinner-border spinner-border-sm mr-2"></span>Searching... </mat-option>
          <mat-option disabled *ngIf="EventData.length > 0 && !isLoading"
            style=" position: sticky;top: 0;z-index: 1;background-color: white; line-height:22px;border-bottom: 1px solid #d6d6d8; text-align: center; color: gray;">
            Results found: {{EventData.length}}
          </mat-option>
          <ng-container *ngIf="!isLoading">

            <mat-option *ngFor="let event of EventData" [value]="event.ID" style="font-size:14px;line-height:22px;border-bottom: 1px solid #d6d6d8;padding-top: 4px;
              padding-bottom: 4px; height:78px;">
              <a>

                <b style=" font-size: 16px">
                  {{event.EventName}}
                </b>
                <br>
                <b style="font-weight: 500; font-size: 13px; color:gray">Event ID: {{event.EntryNo}} | Start Date:
                  {{common.BLFormatDate(event.EventStartDate) | date:'MM/dd/yyyy'}} | End Date:
                  {{common.BLFormatDate(event.EventEndDate) | date:'MM/dd/yyyy'}} | Venue: {{event.VenueName}} </b>

              </a>
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </div>

  </mdb-navbar>

  <!-- <div class="subscription" [matTooltip]="user?.SubscriptionName"
    [ngClass]="{'purple-gradient':user.Subscription == 'free', 'blue-gradient':(user.Subscription == 'premium' || user.Subscription == 'organization'), 'peach-gradient':user.Subscription == 'enterprise'}">
    {{user?.Subscription}}
  </div> -->
   <!-- <div class="fixed-top"> -->
    <div class="subscription d-none d-md-inline d-lg-inline d-sm-none d-xs-none"
    [matTooltip]="user?.SubscriptionName"
    [ngClass]="{'purple-gradient':user.Subscription == 'free', 'premium-gradient':(user.Subscription == 'premium' || user.Subscription == 'organization'), 'peach-gradient':user.Subscription == 'enterprise','basic-gradient':user.Subscription == 'basic'}">
     {{user?.Subscription}}
    </div>
    <!--/. Navbar -->
    <!-- Safwan 05/02/2023 - close this for trial -->
    <!-- *ngIf="user?.SubscriptionType == 'stripe' && user?.Subscription == 'free' && isAdmin == true && isOwner == true"> -->
    <!-- Safwan 05/02/2023 - close this for trial -->
  
    <div #alert class="alert alert-info alert-dismissible fade show slogan" role="alert" 
    [hidden]="theme.hideFreeSolgan" 
    *ngIf=" (user?.Subscription == 'free' || user?.IsFreeTrial == true) && isAdmin == true && isOwner == true">
    <button type="button" class="close" aria-label="Close" (click)="closeAlert()">
      <span aria-hidden="true">&times;</span>
    </button>

    You are using Free subcription. <a (click)="upgrade()" style="text-decoration:underline;" class="alert-link">Click here</a> to
    subscribe to Premium.
  </div>

 
</header>
<!--/.Double navigation-->

<!--Main Layout-->
<!-- Safwan Changes Add ngclass for dual navigation on lg screen- 01/25/2023 - Start -->

<main >
  <div class="container-fluid ">
      <router-outlet></router-outlet>


        <a mdbBtn floating=" true"   size="md" placement="right" gradient="blue"  mdbWavesEffect [hidden]="true"
    mdbPageScroll href="#navTop" style="right:25px; bottom:30px; position: fixed; z-index: 8" [pageScrollDuration]="1">
    <mdb-icon fas icon="arrow-up"></mdb-icon>
    </a>



  </div>
  <br>
  <br>
  <br>
</main>
<!-- Safwan Changes Add ngclass for dual navigation on lg screen- 01/25/2023 - End -->

<!-- <ngx-spinner  type="ball-spin-clockwise"></ngx-spinner> -->
<!-- <ngx-spinner
    bdColor="rgba(0, 0, 0, 1)">
    template="<img src='https://media.giphy.com/media/o8igknyuKs6aY/giphy.gif' />"
  </ngx-spinner> -->
<!-- <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <img *ngIf="AppLogoImg" [src]="AppLogoImg" alt="image">
</ngx-spinner> -->



<div mdbModal #trial="mdbModal" class="modal fade top modal-scrolling" id="frameModalTop" tabindex="-1"
  role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-frame modal-top modal-notify modal-info" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Body-->
      <div class="modal-body" style="padding: 0.5rem !important;">
        <div class="row d-flex justify-content-center align-items-center">
          <p class="pt-2 pr-2"> You are using Free Trial Version. <a (click)="upgrade()" style="text-decoration:underline;">Click here</a> to subscribe to Premium</p>
          <a type="button" mdbBtn color="info" class="waves-light" mdbWavesEffect>Upgrade
            <mdb-icon fas icon="arrow-up" class="ml-1 filterButton" ></mdb-icon>
          </a>
          <a type="button" mdbBtn color="info" outline="true" class="waves-light" mdbWavesEffect
            data-dismiss="modal" (click)="trial.hide();">Ok</a>
        </div>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<ngx-guided-tour ></ngx-guided-tour>