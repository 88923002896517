import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBModalRef, MDBModalService, ToastService } from 'ng-uikit-pro-standard';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { Autocomplete } from 'src/app/Base/Autocomplete';
import { BLBase } from 'src/app/Base/BLBase/BLBase.component';
import { CommonService } from 'src/app/Base/Common.service';
import { SharePointConfigService } from 'src/app/Base/SharePoint/share-point-config.service';
import { ToastType } from 'src/app/Enum/ToastType';
import { ConfirmDialogeService } from 'src/app/confirm-dialoge/confirm-dialoge.service';
import { MessageDialogeService } from 'src/app/message-dialoge/message-dialoge.service';

import { GeneralService } from 'src/app/services/general.service';
import { GenericService } from 'src/app/services/generic.service';
import { themeService } from 'src/app/services/theme.service';
import { ContractTypes } from '../contract-type.model';
import { TypesofContract } from 'src/app/models/contracttypes.model';
import { Occurence } from 'src/app/models/Occurence.model';
import { Venue } from 'src/app/models/venue.model';
import { FieldType } from 'src/app/models/FieldType.model';
import { VenueService } from '../../venue/venue.service';
@Component({
  selector: 'app-contract-type-popup',
  templateUrl: './contract-type-popup.component.html',
  styleUrls: ['./contract-type-popup.component.scss']
})
export class ContractTypePopupComponent extends BLBase<ContractTypes> implements OnInit {
  AutoCompFieldTypes: Autocomplete<TypesofContract> = new Autocomplete<TypesofContract>("ID", "Name");
  AutoCompOccurence: Autocomplete<Occurence> = new Autocomplete<Occurence>("ID", "OccurenceName");
  AutoCompVenue: Autocomplete<Venue> = new Autocomplete<Venue>("VenueName", "ID");
  public fieldtype: FieldType = new FieldType();
  currencySymbol:string = "$";
  
  action = new Subject<any>();
  venueId:any=null;
  constructor(
    public service: GenericService,
    public router: Router,
    public route: ActivatedRoute,
    public spinner: NgxSpinnerService,
    public toast: ToastService,
    public dialog: ConfirmDialogeService,
    public genService: GeneralService,
    public modalService: MDBModalService,
    public messageDialog: MessageDialogeService,
    public common: CommonService,
    //public spService: SPOperationsService,
    public spConfigService:SharePointConfigService,
    public modalRef: MDBModalRef,
    public venueService:VenueService,
    public theme:themeService
  ) { 
    super(service, router, route, spinner, toast, dialog, messageDialog);

    // Form Heading will be shown in html
    this.formTitle = "Contract Fields";

    //Defined Web API controller
    this.addControllerName("ContractTypes");

    this.isGetByPrimaryKey = false;
    this.primaryKey = 0;
    // Form ID
    this.formID = "frm_ContractTypes";
  }

  // This is BLBase function. Here we override it.
  public Initializeobject() {
    this.formData = new ContractTypes();
    this.formData.IsByDefault = true;
    this.scode = "adm_01";
  }


  ngOnInit(): void {
    // BLBase NgOnInit
    super.ngOnInit();

    this.getVenues();

    this.genService.GetDataByQuery("FieldTypes").then(
      res => {
        this.AutoCompFieldTypes.data = res['value'] as ContractTypes[];
        this.AutoCompFieldTypes.resultObserve();
        this.HideSpinner();
      }
      , error => {
        this.HideSpinner();
        this.ShowToast(error.message, ToastType.Error);
      }
    )

    this.genService.GetDataByQuery("Occurences").then(
      res => {
        this.AutoCompOccurence.data = res['value'] as Occurence[];
        this.AutoCompOccurence.resultObserve();
        this.HideSpinner();
      }
      , error => {
        this.HideSpinner();
        this.ShowToast(error.message, ToastType.Error);
      }
    )
  }

  getVenues(){
    this.venueService.getDrpVenues().then(
      res => {
        this.AutoCompVenue.data = null;
        this.AutoCompVenue.data = res['value'] as Venue[];
        this.AutoCompVenue.resultObserve();
        this.HideSpinner();
        // Safwan 09/01/2023 - Start
        if (this.AutoCompVenue.data.length == 1) {
          this.formData.VenueID = res['value'][0]?.ID;
          this.onVenueChange(this.formData.VenueID);
        }
      // Safwan 09/01/2023 - End
      }
      , error => {
        this.HideSpinner();
        this.ShowToast(error.message, ToastType.Error);
      }
    )
  }

  ngAfterViewInit(){
    
    this.formData.VenueID = this.venueId;
  }

  // This form function
  
  BeforeUpsert(form: NgForm) {
    if (this.formData.IsUseForSettlement == false)
      this.formData.GLCode = "";
  }

 
  // This form function
  onSubmit(form: NgForm) {
    //BLBase function
    this.insertRecord(form);
  }


  AfterInsert(form: NgForm) {
    this.action.next("Submit");
    this.modalRef.hide();
  }


  onVenueChange(VenueID:any){
    var selectedVenue = this.AutoCompVenue.data.filter(a => a.ID == VenueID)[0] as any;
    if(this.IsNullOrUndefined(selectedVenue) == false){
      if(selectedVenue?.Symbol != null)
        this.currencySymbol = selectedVenue?.Symbol;
    }
  }
}
