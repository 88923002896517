import {Currency} from './currency.model';
export class Venue{
    ID:number = 0;
    VenueName:string = null;
    ShortCode:string = null;
    Capacity:number=null;
    CreatedBy: number=null;
    CreatedDate: Date=null;
    ModifiedBy: number=null;
    ModifiedDate: Date=null;
    IsSystemGenerated:boolean=false;
    Inactive:boolean=false;
    CurrencyID:number=null;
    Currency:Currency=null;
}