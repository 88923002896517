import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { finalize, catchError, filter, takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { MsalBroadcastService, MsalInterceptor, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { AuthenticationService } from '../helpers/authentication.service';




@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  private readonly _destroying$ = new Subject<void>();
  constructor(
    private spinner: NgxSpinnerService,
    private authService: AuthenticationService,
    ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
   //debugger;
    //'Authorization' : 'Bearer ' + bearertoken,
    

   
    // If user logged in with MS teams
    if (localStorage.getItem("teams")){
      request = request.clone({
        setHeaders: {
          'teams' : "teams",      
        },
      });
    }

    
    // Set bearer token
    if (this.authService.CurrentUser()){
      request = request.clone({
        setHeaders: {
          'Authorization' : 'Bearer ' + this.authService.CurrentUser().token,      
        },
      });
    }
       
    return next.handle(request).pipe(
      finalize(
        () =>{
          //this.loaderService.isLoading.next(false);
          //this.spinner.hide();
        },      
      )
    );
  }

 
}
