import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { LoginUser } from 'src/app/Base/User/login-user';
import { AuditService } from 'src/app/services/audit.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss']
})
export class AuditLogComponent implements OnInit {
  dataAudit:any[] = [];
  showSpinner:boolean = false;
  constructor(
    public generalService:GeneralService ,
    public auditService:AuditService
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    //this.showSpinner = true;
    this.auditService.getAuditData().then(res => {
      var data = res['value'] as [];

      for (let index = 0; index < data.length; index++) {
        var element = data[index] as any;
        // element['ModifiedDate'] = moment(element['ModifiedDate']).local();
        // Safwan - 06/07/23
        element['ModifiedDate'] = moment(element['ModifiedDate']).format('MM/DD/YYYY hh:mm A');
        // Safwan - 06/07/23
      }

      this.dataAudit = data;
    });
  //   setTimeout(() => {
  //     this.showSpinner = false;
  // }, 500);
    
  }
}
