import { Injectable } from '@angular/core';
import * as customConfig from 'src/app/customConfig.json';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { AuthenticationService } from '../../helpers/authentication.service';
import { LoginUser } from 'src/app/Base/User/login-user';
import { ConfHttpHeadersService } from '../conf-http-headers.service';

@Injectable({
  providedIn: 'root'
})
export class AccessPermissionService {

  public rootURL = customConfig.api; 

  constructor(
    private http: HttpClient,
    private authenticationService :AuthenticationService,
    private hdr:ConfHttpHeadersService,
    
    ) {
    

  }

  requestAccess(jsonBody:any){

    var currUser = this.authenticationService.CurrentUser();

    jsonBody["CustomerObjectID"] = currUser.CustomerObjectID.toString();            
    return this.http.post(this.rootURL + "api/RequestAccess/Post", jsonBody, this.hdr.getHeaders()) ;
  }

  

  approveAccess(){
    
  }

  declineAccess(){
    
  }
}
