import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http'
import { LoginUser } from '../Base/User/login-user';
import moment from 'moment';
import * as customConfig from 'src/app/customConfig.json';
import { ConfHttpHeadersService } from './conf-http-headers.service';

@Injectable({
  providedIn: 'root'
})

export class GeneralService {

  constructor(
    private http: HttpClient,
    private hdr:ConfHttpHeadersService,
    ) { }

  list: object[];
  public ControllerName: string;
  readonly rootURL = customConfig.api; 

  postData(ControllerName: string, formData: any, sectionName:string) {
    var obj = {
      "Entity": formData,
      "AuditInfo": { 
      "ID": 0, 
      "UserId": LoginUser.loggedinUser.UserID, 
      "TransactionDate": moment(new Date()).format('YYYY-MM-DD'),
      "SectionName":sectionName,
      'CustomerObjectID' : LoginUser.customer == null ? '-1' : LoginUser.customer.CustomerObjectId.toString(),
      'UserObjectID' :  LoginUser.loggedinUser.UserID == null ? '-1' : LoginUser.loggedinUser.UserID.toString(),  
      }
    }

    return this.http.post(this.rootURL + ControllerName + "/Insert", obj, this.hdr.getHeaders());
  }
  
  post(controller: string, jsonBody: any) {
      
    if(jsonBody['CreatedDate'] == null)
      jsonBody['CreatedDate'] = new Date();
  
    if(jsonBody['ModifiedDate'] == null)
      jsonBody['ModifiedDate'] = new Date();
  
      return this.http.post(this.rootURL + controller, jsonBody);
    }

  bulkPostData(ControllerName: string, formData: any[], sectionName:string) {

    var obj = {
      "Entity": formData,
      "AuditInfo": { 
        "ID": 0, 
        "UserId":LoginUser.loggedinUser.UserID, 
        "TransactionDate": moment(new Date()).format('YYYY-MM-DD'),
        "SectionName":sectionName ,
        'CustomerObjectID' : LoginUser.customer == null ? '-1' : LoginUser.customer.CustomerObjectId.toString(),
        'UserObjectID' :  LoginUser.loggedinUser.UserID == null ? '-1' : LoginUser.loggedinUser.UserID.toString(),  
      }
    }

    return this.http.post(this.rootURL + ControllerName + "/BulkInsert", obj, this.hdr.getHeaders());
  }

  putData(ControllerName: string, ID: number, formData: any, sectionName:string) {
    
    var obj = {
      "Entity": formData,
      "AuditInfo": { 
      "ID": parseInt(ID.toString()), 
      "UserId": LoginUser.loggedinUser.UserID, 
      "TransactionDate": moment(new Date()).format('YYYY-MM-DD'),
      "SectionName":sectionName ,
      'CustomerObjectID' : LoginUser.customer == null ? '-1' : LoginUser.customer.CustomerObjectId.toString(),
      'UserObjectID' :  LoginUser.loggedinUser.UserID == null ? '-1' : LoginUser.loggedinUser.UserID.toString(),  
     }
    }

    return this.http.post(this.rootURL + ControllerName + "/Update", obj, this.hdr.getHeaders());

  }

  updateData(ControllerName: string, ID: number, formData: any, CreatedBy: number, CreatedDate: Date, sectionName:string) {
    var obj = {
      "Entity": formData,
      "AuditInfo": {
        "ID": parseInt(ID.toString()),
        "UserId": LoginUser.loggedinUser.UserID,
        "TransactionDate": moment(new Date()).format('YYYY-MM-DD'),
        "CreatedBy": CreatedBy == null ? '-1' : CreatedBy.toString(),
        "CreatedDate": CreatedDate == null ? moment(new Date()).format('YYYY-MM-DD') : moment(CreatedDate).format('YYYY-MM-DD'),
        "SectionName":sectionName ,
        'CustomerObjectID' : LoginUser.customer == null ? '-1' : LoginUser.customer.CustomerObjectId.toString(),
        'UserObjectID' :  LoginUser.loggedinUser.UserID == null ? '-1' : LoginUser.loggedinUser.UserID.toString(),  
      }
    }

    return this.http.post(this.rootURL + ControllerName + "/Update", obj, this.hdr.getHeaders());
  }

  setData(funcionName: string, obj: any) {
    return this.http.post(this.rootURL + funcionName, obj, this.hdr.getHeaders());
  }

  patchData(controllerName: string, ID: number, obj: any) {
    return this.http.patch(this.rootURL + controllerName + "(" + ID + ")", obj, this.hdr.getHeaders());
  }


  async GetDataByQuery(query: string) {
    return await this.http.get(this.rootURL + query, this.hdr.getHeaders()).toPromise()
  }

  async GetConfigDataByTableQuery(query: string) {
    return await this.http.get(this.rootURL + "api/Config/GetDataByTable(" + query + ")", this.hdr.getHeaders()).toPromise()
  }

  async GetConfigDataWithQuery(query: string) {
    return await this.http.get(this.rootURL + "api/Config/GetDataByQuery(" + query + ")", this.hdr.getHeaders()).toPromise()
  }

   GetConfigDataWithBodyQuery(query: string) {
     var obj = {
       Query : query
     }
    return this.http.post(this.rootURL + "api/Config/GetDataByBody",obj, this.hdr.getPostHeaders())
  }

  GetConfigDataWithQueryWithPipe(query: string) {
    return this.http.get(this.rootURL + "api/Config/GetDataByQuery(" + query + ")", this.hdr.getHeaders())
  }

  GetConfigDataByTableWithPipe(query: string) {
    return this.http.get(this.rootURL + "api/Config/GetDataByTable(" + query + ")", this.hdr.getHeaders())
  }


   async Authenticate(userName: any, password: any) {
    const httpOptions = {
      headers: new HttpHeaders({        
        'Authorization': 'Basic ' + btoa(userName + ':' + password),       
      })
      , observe: 'response' as 'response'
    };
    
    return await this.http.post(this.rootURL + 'authenticate', null, httpOptions).toPromise()      
  }

  async logout() {    
    
    return await this.http.post(this.rootURL + 'logout', null, this.hdr.getHeaders()).toPromise()      
  }

  async getToken(userName: any, password: any) {
    const httpOptions = {
      headers: new HttpHeaders({        
        'Authorization': 'Basic ' + btoa(userName + ':' + password),       
      })
      , observe: 'response' as 'response'
    };
    
    return await this.http.post(this.rootURL + 'Authenticate/get/token', null, httpOptions).toPromise()      
  }

  capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  capitalizeEachWord(str: string): string {

    var arrString = str.split(' ');
    arrString.every(a => a = a.charAt(0).toUpperCase() + a.slice(1));
    var capitialze_str:string = '';
    arrString.forEach(a => capitialze_str == '' ? capitialze_str = a : capitialze_str += ' ' + a);


    return capitialze_str.charAt(0).toUpperCase() + str.slice(1);
  }

}