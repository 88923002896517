<div class="modal-header darken-3 white-text  gradient-card-header narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center" [class]="theme.heading">       
          <h4 class="h4-responsive">
              Bulk Edit
          </h4>              
         

          <div class="btn-group" role="group" aria-label="Basic example">
              <a (click)="modalRef.hide()" id="btnClose">
              <button mdbBtn type="button" [class]="theme.button" size="sm" class="waves-light" mdbWavesEffect>
                <mdb-icon fas icon="times" ></mdb-icon>
              </button>
            </a>

            </div>
</div>

<!--Body-->
<div class="modal-body mb-0 text-left">
<form #EventHelper="ngForm" autocomplete="off">
  <div class=table-responsive>
    <table mdbTable class="z-depth-1 ">
                  <thead>
                    <tr>
                      <!-- <th> Time In</th>
                      <th> Time Out</th> -->
                      <!-- <th> In Use </th> -->
                      <th [hidden]="eventID == 0" [class]="theme.label"> Status </th>
                      <!-- <th> Start Time</th>
                      <th> End Time</th> -->
                      <th [class]="theme.label"> Standard Fee</th>
                      <th [hidden]="!IsDiscountApplied" [class]="theme.label"> Discounted Fee</th>
                      
                  
                    </tr>
                  </thead>
                  <tbody>
        <tr>
          <!-- <td>
            <mat-form-field appearance="outline" class="matTableFields matDateFieldWidth">
            <input type="time" matInput [ngModelOptions]="{standalone: true}"  [(ngModel)]="formData.TimeIn"/>
            </mat-form-field>
            </td>
        
            <td>
              <mat-form-field appearance="outline" class="matTableFields matDateFieldWidth">
                <input type="time" matInput [ngModelOptions]="{standalone: true}" [(ngModel)]="formData.TimeOut"/>
              </mat-form-field>
            </td> -->
          <!-- <td>
            <mat-form-field appearance="outline" class="matTableFields">
              <mat-select [ngModelOptions]="{standalone: true}"
                [(ngModel)]="formData.SubEventTypeID">
                <mat-option *ngFor="let option of subEventType" [value]="option.ID">
                  {{ option.EventTypeName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td> -->
          <td [hidden]="eventID == 0">
            <mat-form-field  appearance="outline" class="matTableFields">
              <mat-select [ngModelOptions]="{standalone: true}" [(ngModel)]="formData.EventStatusID" id="drpStatus">
                <mat-option *ngFor="let option of eventStatus" [value]="option.ID">
                  {{ option.EventStatus }}
                </mat-option>
              </mat-select>                           
            </mat-form-field>
          </td>



      <!-- <td>
        <mat-form-field appearance="outline" class="matTableFields matDateFieldWidth">
          <input type="time" matInput [ngModelOptions]="{standalone: true}" [(ngModel)]="formData.ShowStartTime"/>
        </mat-form-field>
      </td>
      <td>
        <mat-form-field appearance="outline" class="matTableFields matDateFieldWidth">
          <input type="time" matInput [ngModelOptions]="{standalone: true}" [(ngModel)]="formData.ShowEndTime"/>
        </mat-form-field>
      </td> -->
      <td>
        <mat-form-field appearance="outline" class="matTableFields matNumericFieldWidth">
          <input matInput type="text" appMatInputCurrency [ngModelOptions]="{standalone: true}" [(ngModel)]="formData.StandardLicenseFee" id="numStandardFee">
          <span matPrefix>{{currencySymbol}}&nbsp;</span>
          <!-- <span matPrefix>$&nbsp;</span> -->
        </mat-form-field>
      </td>
      <td [hidden]="!IsDiscountApplied">
        <div>
          <mat-form-field appearance="outline" class="matTableFields matNumericFieldWidth ">
            <input matInput type="text" appMatInputCurrency [ngModelOptions]="{standalone: true}" [(ngModel)]="formData.DiscountedLicenseFee" id="numDiscountedFee">
            <span matPrefix>{{currencySymbol}}&nbsp;</span>
            <!-- <span matPrefix>$&nbsp;</span> -->
          </mat-form-field>
        </div>
      </td>

    

      </tr>
      </tbody>  
    </table>   
    </div>
    <p style="font-style: oblique;font-size: 13px;">Note: Changes would apply to all selected performances. Settlement and Deleted performance are not applicable for Bulk Edit.</p>    
       
    <table  mdbTable class="z-depth-1 ">
      <thead>
        <!-- <th>Line ID</th> -->
        <th [class]="theme.label">Date</th>
        <th [class]="theme.label">Status</th>        
        <th [class]="theme.label" [hidden]="!IsDiscountApplied" >Discounted Fee</th>
        <th [class]="theme.label">Standard Fee </th>
        <th [class]="theme.label"> Apply </th>
      </thead>
      <tbody>
          <tr *ngFor="let es of BuklPorformances "  [hidden]="es.IsDeleted || es.IsSettlement ">
            <!-- <td>{{es.LineID}}</td> -->
              <td>{{common.BLFormatDate(es.PerformanceDate) | date: 'MM/dd/yyyy' }}</td>
              <td>
                  <mat-list *ngIf="es.EventStatusID">
                      <mat-list-item
                        *ngFor="let option of eventStatus | filter : 'ID' : es.EventStatusID">
                        {{option.EventStatus}}
                      </mat-list-item>
                    </mat-list>
              </td>
              <td [hidden]="!IsDiscountApplied" >{{es.DiscountedLicenseFee}}</td>
              <td>{{es.StandardLicenseFee}}</td>              
              <td> 
                <mdb-checkbox  [ngModelOptions]="{standalone: true}" [(ngModel)]="es.IsSelected">
                </mdb-checkbox>
              </td>
            </tr>
      </tbody>
      

    </table>
    <br>
    
    <button  type="button" (click)="onSubmit()"  mdbBtn [class]="theme.button" class="waves-light" mdbWavesEffect style="float: left;" id="btnSave">
        <span> <mdb-icon fas icon="save" class="mr-1"></mdb-icon>Update </span>
      </button>

</form>  
</div>