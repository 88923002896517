<div class="row fixed-top my-1 pl-3 pr-3 stickyMenuTop" >
    <div class="btn-group" role="group" aria-label="Basic example">
                          
      <a (click)="onSubmit(customer)" class="stickyButtons" [hidden]="customer.invalid || IsInvalidForm || isView">
          <button mdbBtn type="button" class="btn-sm" [disabled]="customer.invalid" [hidden]="isView">
              <mdb-icon fas icon="save" class="mr-1"></mdb-icon>{{SavebuttonText}}
          </button>
      </a>
     
      <a routerLink="/customer/list" style="color: #000000;" class="stickyButtons">
        <button mdbBtn type="button" class="btn-sm">
          <mdb-icon fas icon="list" class="mr-1"></mdb-icon>View List
        </button>
      </a>
    
      <a (click)="onReload(customer)" class="stickyButtons">
        <button mdbBtn *ngIf="isEdit || isCreated" type="button" class="btn-sm">
          <mdb-icon fas icon="times" class="mr-1"></mdb-icon>Cancel
        </button>
      </a>
  
      <a *ngIf="permissionService.CheckIfUserhasAccess_Action('Customer','Add')" (click)="onCreate(customer)" class="stickyButtons">
        <button mdbBtn type="button"  *ngIf="!isCreated" class="btn-sm">
          <mdb-icon fas icon="plus" class="mr-1"></mdb-icon>New
        </button>
      </a>
    
      <a *ngIf="permissionService.CheckIfUserhasAccess_Action('Customer','Edit')" (click)="onEdit()" class="stickyButtons">
        <button mdbBtn *ngIf="isView" class="btn-sm">
          <mdb-icon fas icon="edit" class="mr-1"></mdb-icon>Edit
        </button>
      </a>
      <a *ngIf="permissionService.CheckIfUserhasAccess_Action('Customer','Delete')" [hidden]="true" (click)="onDelete(primaryKey)" class="stickyButtons">
        <button mdbBtn *ngIf="isEdit" class="btn-sm">
          <mdb-icon fas icon="trash-alt" class="mr-1"></mdb-icon>Delete
        </button>
      </a>
    </div>
  
  </div>
  
  
  <div class="row py-4">
    
      <div class="col-lg-12 col-md-12">    
        <mdb-card cascade="true" narrower="true">      
        
          <div
            class="view view-cascade gradient-card-header blue-gradient narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center">
    
            <h4 class="h4-responsive">{{formTitle}}</h4>
    
            
          </div>
  
          <div class="row card-text">          
            <div class="col-sm-12">
              <div class="row AuditFields">
                <div class="col-md-12">
                  {{AuditDetails}}
                </div>
              </div>
            </div>
          </div>
          
          <form #customer="ngForm" autocomplete="off" (ngSubmit)="onSubmit(customer)"> 
          <mdb-card-body cascade="true">
              <fieldset [disabled]="isView">
                <div class="row">
                 
                  <div class="col-sm-4">
    
                  </div>
                  <div class="col-sm-4" [hidden]="true">
                    <div class="md-form">
                        <input name="ID" #ID="ngModel" [(ngModel)]="formData.ID" type="text"
                            id="clientID" class="form-control" mdbInput>
                        <label for="clientID">Customer ID</label>
                    </div>
                    </div>
                  <div class="col-sm-4">
                                                                               
                          <mat-form-field class="example-full-width">
                              <mat-label>First Name</mat-label>
                              <input  matInput class="MatInputBoxShadow"
                              type="text" id="FirstName" 
                              required name="FirstName" #FirstName="ngModel" [(ngModel)]="formData.FirstName">                                      
                              <mat-error *ngIf="FirstName.invalid && FirstName.dirty">This field is required</mat-error> 
                          </mat-form-field>
  
                          <mat-form-field class="example-full-width">
                              <mat-label>Last Name</mat-label>
                              <input  matInput class="MatInputBoxShadow"
                              type="text" id="LastName" 
                              required name="LastName" #LastName="ngModel" [(ngModel)]="formData.LastName">                                      
                              <mat-error *ngIf="LastName.invalid && LastName.dirty">This field is required</mat-error> 
                          </mat-form-field>
  
                          <mat-form-field class="example-full-width">
                            <mat-label>Email</mat-label>
                            <input  matInput class="MatInputBoxShadow"
                            type="email" id="Email" 
                            required name="Email" #Email="ngModel" [(ngModel)]="formData.Email">                                      
                            <mat-error *ngIf="Email.invalid && Email.dirty">This field is required</mat-error> 
                          </mat-form-field>
                         
                      </div>
                      
                                                       
                  <div class="col-sm-4" *ngIf="IsshowProgress">
                      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                  </div>
    
                </div>
  
            
              <div class="text-center">
                <button type="button" (click)="onSubmit(customer)" [disabled]="customer.invalid" [hidden]="isView" mdbBtn color="default" class="waves-light" mdbWavesEffect>
                  <mdb-icon fas icon="save" class="mr-1"></mdb-icon>{{SavebuttonText}}
                </button>
              </div>
            </fieldset>
                       
          </mdb-card-body>      
        </form>
      </mdb-card>   
      </div>  
    </div>
    
    
    