import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/Base/Common.service';
import { RBACGroups } from 'src/app/rbac/rbac-groups';
import { RBAC_Users_Groups } from 'src/app/rbac/rbac-users-groups';
import { RbacUsers } from 'src/app/rbac/rbac-users.model';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-boarding-add-user',
  templateUrl: './boarding-add-user.component.html',
  styleUrls: ['./boarding-add-user.component.scss']
})
export class BoardingAddUserComponent implements OnInit {

  
  formDataUser:RbacUsers = new RbacUsers();
  public rbacGroups:RBACGroups_This[]= [];
  public IsAdmin :boolean = false;
  action = new Subject<any>();

  constructor(
    public modalRef: MDBModalRef,
    public genService:GeneralService,
    public commonService:CommonService
  ) { }

  ngOnInit(): void {
    this.GetGroups()
  }
    
  onGroupClick(element:RBACGroups_This){
      if (element.IsAdmin == true) {
        if (element.IsSelected == true) {
          this.rbacGroups.forEach(element => {
            if(element.IsAdmin != true)
                element.IsSelected = false;
          });
  
          this.IsAdmin = true;
        }
        else
        {
          // this.rbacGroups.forEach(element => {
          //   element.IsSelected = false;
          // });
          this.IsAdmin = false;
        }
      }
  }

  async GetGroups(){
      // this.commonService.ShowSpinner();
      await this.genService.GetDataByQuery("RBAC_Groups").then(res => {
         var rbacGrps = res['value'] as RBACGroups[];
   
         rbacGrps.forEach(element => {
           var rbac = new RBACGroups_This();
           rbac.ID = element.ID;
           rbac.GroupName = element.GroupName;
           rbac.IsAdmin = element.IsAdmin;
           rbac.IsSystemGenerated = element.IsSystemGenerated;
           rbac.CreatedBy = element.CreatedBy;
           rbac.CreatedDate = element.CreatedDate;
           rbac.ModifiedBy = element.ModifiedBy;
           rbac.ModifiedDate = element.ModifiedDate;
           this.rbacGroups.push(rbac);
         });
        //  this.commonService.HideSpinner()
       })
  }

  onNext(){
    this.BeforeNext();
    this.action.next(this.formDataUser);
    this.modalRef.hide();
  }

  BeforeNext(){  
    
    var userGroupList: RBAC_Users_Groups[] = [];

    

    this.rbacGroups.forEach(element => {
      
      if(element.IsSelected == true)
      {
        var rbac = new RBAC_Users_Groups();
        rbac.ID = element.rbacUserGroupID;
        rbac.GroupId = element.ID;
        rbac.UserId = this.formDataUser.ID;                
        userGroupList.push(rbac);
      }

    });


   


    this.formDataUser.RBAC_Users_Groups = userGroupList;

    // Getting email
    // var ID =  this.formDataUser.ID;
    //  var selectedUser = this.rbacusers.filter(function(x){
    //    return x.ID == ID;
    //  })

    //form.value.Password = selectedUser[0].Password;
    //form.value.FirstName = selectedUser[0].FirstName;
    //form.value.LastName = selectedUser[0].LastName;

  }
}

export class RBACGroups_This {
  ID : number = 0;
  GroupName : string = null;
  IsAdmin : boolean = null;
  CreatedBy : number = null;
  CreatedDate : Date = null;
  ModifiedBy : number = null;
  ModifiedDate : Date = null;
  IsSystemGenerated : boolean = null;
  IsSelected : boolean = null;
  rbacUserGroupID :number = 0;
}