import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TeamsAppService {

  constructor() { }

  isTeamsApp(){
    return localStorage.getItem("teams") ? true : false;
  }

}
