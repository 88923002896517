import { Injectable, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { GuidedTour, GuidedTourService, Orientation } from 'ngx-guided-tour';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TourGuideService {

  
  constructor(public guidedTourService:GuidedTourService) { 
    
  }

  //#region Desktop tour

      public onActionDesk: Subject<any> = new Subject<any>();
      public onSkipDesk: Subject<any> = new Subject<any>();
      public onCompleteDesk: Subject<any> = new Subject<any>();
      @ViewChild('eventTrigger') eventTrigger:MatMenuTrigger;

      public deskTour: GuidedTour = {
        tourId: 'desk-tour',
        preventBackdropFromAdvancing:true,
        completeCallback : () => this.onDeskCompleteTour(),
        skipCallback: () => this.onDeskTourSkip(),
        steps: [
          {
            title: 'First Step',
            selector: '.setting-btn',
            content: 'Click here to change the Setting or Signout.',
            orientation: Orientation.BottomRight,
            closeAction: () => this.deskMenuOpen()
          },
          {
            title: 'Second Step',
            selector: '.add-pop',
            content: 'Click to set Event and Client.',
            closeAction: () => this.deskMenuOpen(),
            orientation:Orientation.Left
          },
          {
            title: 'Third Step',
            selector: '.event-btn',
            content: 'Click to Add Event.',
            closeAction: () => this.deskMenuOpen(),
            orientation:Orientation.Left
          },
          {
            title: 'Fourth Step',
            selector: '.client-btn',
            content: 'Click to Add Client.',
            closeAction: () => this.deskMenuOpen(),
            orientation: Orientation.Left
          }//,
          // {
          //   title: 'Fifth Step',
          //   selector: '.venue-btn',
          //   content: 'Click to Add Venue.',
          //   orientation: Orientation.Left,
          //   closeAction: () => this.deskMenuOpen(),
          // },
        ],
      };

      public deskTourEnterprise: GuidedTour = {
        tourId: 'desk-tour',
        preventBackdropFromAdvancing:true,
        completeCallback : () => this.onDeskCompleteTour(),
        skipCallback: () => this.onDeskTourSkip(),
        steps: [
          {
            title: 'First Step',
            selector: '.setting-btn',
            content: 'Click here to change the Setting or Signout.',
            orientation: Orientation.BottomRight,
            closeAction: () => this.deskMenuOpen()
          },
          {
            title: 'Second Step',
            selector: '.add-pop',
            content: 'Click to set Event, Client and Venue.',
            closeAction: () => this.deskMenuOpen(),
            orientation:Orientation.Left
          },
          {
            title: 'Third Step',
            selector: '.event-btn',
            content: 'Click to Add Event.',
            closeAction: () => this.deskMenuOpen(),
            orientation:Orientation.Left
          },
          {
            title: 'Fourth Step',
            selector: '.client-btn',
            content: 'Click to Add Client.',
            closeAction: () => this.deskMenuOpen(),
            orientation: Orientation.Left
          },
          {
            title: 'Fifth Step',
            selector: '.venue-btn',
            content: 'Click to Add Venue.',
            orientation: Orientation.Left,
            closeAction: () => this.deskMenuOpen(),
          },
        ],
      };

      deskMenuOpen(){
      
        let item ;
        var checkStep:boolean = false;  
        this.guidedTourService.guidedTourCurrentStepStream.subscribe(res=>{
            item = res ? res.title : null;
            
            if(!checkStep){
              if(item != null && (item !='First Step')  ){
                //  this.openEventMenu(true,false);
                this.onActionDesk.next(true);
                checkStep = true;
                }
                else{
                  this.onActionDesk.next(false)
                  this.deskEventMenu(true,true);
                  checkStep = true;

                }
            }
          });
        // console.log(this.guidedTourService.currentTourStepDisplay);
      }

      onDeskTourSkip(){
        this.onSkipDesk.next(true);
      }
      
      onDeskCompleteTour(){
        this.onCompleteDesk.next(true);
      }

      
      deskEventMenu(isClicked:boolean,isLastStep:boolean){
        if(isClicked == true && isLastStep == false){
          
          this.eventTrigger.menu.hasBackdrop = false;
          this.eventTrigger.openMenu();
        }
        else if(isClicked == true && isLastStep == true){
          this.eventTrigger.menu.hasBackdrop = true;
          this.eventTrigger.closeMenu();
        }
      }

  //#endregion

 //#region Mobile Tour
        public onActionMob: Subject<any> = new Subject<any>();
        public onSkipMob: Subject<any> = new Subject<any>();
        public onCompleteMob: Subject<any> = new Subject<any>();
        @ViewChild('eventTriggerMob') eventTriggerMob:MatMenuTrigger;

        

        public mobTour: GuidedTour = {
          tourId: 'first-tour',
          preventBackdropFromAdvancing:true,
          completeCallback : () => this.onCompleteTourMob(),
          skipCallback: () => this.onMobTourSkip(),
          steps: [
            {
              title: 'First Step',
              selector: '.setting-btn-mob',
              content: 'Click here to change the Setting or Signout.',
              orientation: Orientation.BottomRight,
              closeAction: () => this.mobMenuOpen()
            },
            {
              title: 'Second Step',
              selector: '.add-pop-mob',
              content: 'Click to set Event and Client.',
              closeAction: () => this.mobMenuOpen(),
              orientation:Orientation.Left
            },
            {
              title: 'Third Step',
              selector: '.event-btn-mob',
              content: 'Click to Add Event.',
              closeAction: () => this.mobMenuOpen(),
              orientation:Orientation.Left
            },
            {
              title: 'Fourth Step',
              selector: '.client-btn-mob',
              content: 'Click to Add Client.',
              closeAction: () => this.mobMenuOpen(),
              orientation: Orientation.Left
            }//,
            // {
            //   title: 'Fifth Step',
            //   selector: '.venue-btn-mob',
            //   content: 'Click to Add Venue.',
            //   orientation: Orientation.Left,
            //   closeAction: () => this.mobMenuOpen(),
            // },
          ],
        };

        public mobTourEnterprise: GuidedTour = {
          tourId: 'first-tour',
          preventBackdropFromAdvancing:true,
          completeCallback : () => this.onCompleteTourMob(),
          skipCallback: () => this.onMobTourSkip(),
          steps: [
            {
              title: 'First Step',
              selector: '.setting-btn-mob',
              content: 'Click here to change the Setting or Signout.',
              orientation: Orientation.BottomRight,
              closeAction: () => this.mobMenuOpen()
            },
            {
              title: 'Second Step',
              selector: '.add-pop-mob',
              content: 'Click to set Event, Client and Venue.',
              closeAction: () => this.mobMenuOpen(),
              orientation:Orientation.Left
            },
            {
              title: 'Third Step',
              selector: '.event-btn-mob',
              content: 'Click to Add Event.',
              closeAction: () => this.mobMenuOpen(),
              orientation:Orientation.Left
            },
            {
              title: 'Fourth Step',
              selector: '.client-btn-mob',
              content: 'Click to Add Client.',
              closeAction: () => this.mobMenuOpen(),
              orientation: Orientation.Left
            },
            {
              title: 'Fifth Step',
              selector: '.venue-btn-mob',
              content: 'Click to Add Venue.',
              orientation: Orientation.Left,
              closeAction: () => this.mobMenuOpen(),
            },
          ],
        };
        



        mobMenuOpen(){
          
          let item ;
          var checkStep:boolean = false;  
          this.guidedTourService.guidedTourCurrentStepStream.subscribe(res=>{
                item = res ? res.title : null;
              
                if(!checkStep){
                  if(item != null && (item !='First Step')  ){
                  //  this.openEventMenu(true,false);
                    this.onActionMob.next(true);
                    checkStep = true;
                  }
                  else{
                    this.onActionMob.next(false)
                    this.openMobEventMenu(true,true);
                    checkStep = true;

                  }
                }
            });
          // console.log(this.guidedTourService.currentTourStepDisplay);
        }
        

        onMobTourSkip(){
          this.onSkipMob.next(true);
        }

        onCompleteTourMob(){
          this.onCompleteMob.next(true);
        }


        openMobEventMenu(isClicked:boolean,isLastStep:boolean){
          if(isClicked == true && isLastStep == false){
            
            this.eventTriggerMob.menu.hasBackdrop = false;
            this.eventTriggerMob.openMenu();
          }
          else if(isClicked == true && isLastStep == true){
            this.eventTriggerMob.menu.hasBackdrop = true;
            this.eventTriggerMob.closeMenu();
          }
        }
 //#endregion

  showTour(tourName:GuidedTour){
    this.guidedTourService.startTour(tourName);
  }
  
}
