import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginUser } from 'src/app/Base/User/login-user';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { MessageDialogeService } from 'src/app/message-dialoge/message-dialoge.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-on-boarding-organization',
  templateUrl: './on-boarding-organization.component.html',
  styleUrls: ['./on-boarding-organization.component.scss']
})
export class OnBoardingOrganizationComponent implements OnInit {

  OrganizationName:string = "";
  IsshowProgress:boolean = false;
  constructor(
    public genService: GeneralService,
    public route: Router,
    public messageDialog: MessageDialogeService,
    public authenticationService: AuthenticationService,
  ) { }

  ngOnInit(): void {
  }

  onSubmit(){
    
    var user = this.authenticationService.CurrentUser();
    if(user == null)
      {
        alert("Something went wrong");
        return;
      }

    var obj = {
      "OrganizationName": this.OrganizationName,
      "CustomerID": user.OwnerId
    }
    
    this.genService.setData("api/CustomersTenant/UpdateOrganization", obj).subscribe(res => {      
      LoginUser.loggedinUser.OrganisationName = this.OrganizationName;
      this.route.navigate(['/calendar']);
      
    }, error => {
      

      if (error.error.text) {
        if (String(error.error.text).indexOf("Error:") == 0) {
          this.messageDialog.openMessageDialog(error.error.text);
        }
        else {
          this.messageDialog.openMessageDialog(error.message);          
        }
      }
      else
        this.messageDialog.openMessageDialog(error.message);
    });
  }
}
