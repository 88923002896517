
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastService } from 'ng-uikit-pro-standard';
import { ConfirmDialogeService } from 'src/app/confirm-dialoge/confirm-dialoge.service';
import { ToastType } from 'src/app/Enum/ToastType';
import { NgForm } from '@angular/forms';
import moment from 'moment';
import { startOfDay, addHours, addMinutes } from 'date-fns/esm';
import { SpConfig } from './SpConfig';
import { SPOperationsService } from '../services/spoperations.service';
import { LoginUser } from './User/login-user';
import { GeneralService } from '../services/general.service';
import { SharePointConfigService } from './SharePoint/share-point-config.service';
import { Venue } from '../models/venue.model';
import { themeService } from '../services/theme.service';
import { PermissionService } from '../services/permission.service';
import { AuthenticationService } from '../helpers/authentication.service';
import { HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})


export class CommonService {
  isSingleVenueExist:boolean = false;
  venueLength:number = 0;

  ToastType: any;
  constructor(
    public spinner: NgxSpinnerService,
    public toast: ToastService,
    public dialog: ConfirmDialogeService,
    public spService: SPOperationsService,
    public genService: GeneralService,
    public spConfigService: SharePointConfigService,
    public theme:themeService,
    public permissionService:PermissionService,
    private authenticationService :AuthenticationService,
  ) {

  }

  ShowSpinner() {
    this.spinner.show();
  }

  HideSpinner() {
    this.spinner.hide();
  }

  ShowToast(message: string, toasttype: ToastType) {
    const options = { opacity: 1, positionClass: 'md-toast-top-right', progressBar: true, closeButton: true, timeOut: 5000 };
    const optionsOther = { opacity: 1, positionClass: 'md-toast-top-right', progressBar: true, closeButton: true, timeOut: 5000 };

    if (toasttype == ToastType.Success)
      this.toast.success(message, "", options);
    else if (toasttype == ToastType.Error)
      this.toast.error(message, "Error", optionsOther);
    else if (toasttype == ToastType.Info)
      this.toast.info(message, "Information", optionsOther);
    else if (toasttype == ToastType.Warning)
      this.toast.warning(message, "Warning", optionsOther);
  }

  FillNgFormByObject(obj: any, form: NgForm): NgForm {

    for (let key of Object.keys(obj)) {
      if (key === "@odata.context")
        continue;

      form.value[key] = obj[key];

    }

    console.log(form.value)
    return form;

  }

  ConvertStringToTime(val): string {
    var year = 2020;
    var month = 1;
    var day = 1;

    var timeSplit = String(val).split(':');
    if (timeSplit.length > 0) {
      var hour = parseInt(timeSplit[0])
      var min = parseInt(timeSplit[1])
      var reserv = new Date(year, month, day, hour, min)
      return moment(reserv).format("hh:mm A")
    }
  }


  ConvertStringToHhMm(date: Date, val: string): Date {
    var timeSplit = String(val).split(':');
    if (timeSplit.length > 0) {
      var hour = parseInt(timeSplit[0])
      var min = parseInt(timeSplit[1])
      //var new_Date = addMinutes(addHours(startOfDay(new Date(date)), hour), min)
      var new_Date = new Date(new Date(date).setHours(hour, min))
      return new_Date;
    }
  }

  IsNullOrUndefined(value: any): boolean {
    if (value == null || value == undefined)
      return true;

    return false;
  }


  //Convert date in UTC time zone
  UTCTime(date) {
    //Create a date from the returned date/time field
    var itemsdatetime = new Date(date);
    //Get a time representation of that date in milliseconds
    var localTime = itemsdatetime.getTime();
    //Get the regional time offset based on the REST call to /_api/Web/RegionalSettings/TimeZone
    //value is in minutes, must be converted to milliseconds
    var regionTimeoffset = (SpConfig.UTCBias + SpConfig.UTCDaylightBias) * 60000;
    //Get the local time offset based on the date object
    //value is in minutes, must be converted to milliseconds
    var localTimeoffset = itemsdatetime.getTimezoneOffset() * 60000;
    //Create a new date object in the target field
    var adjusteddate = new Date();
    //Set the value of that date object to the original item plus 2x the difference between the local time zone offset and the regional time zone offset
    adjusteddate.setTime(localTime + ((localTimeoffset - regionTimeoffset) * 2));
    //Create a new date object in the target field
    var utcdate = new Date();
    //Set the value of the date object to the original item plus the differen
    //Set the value of the date object to the original item plus the difference between the local time zone offset and the regional time zone offset.
    utcdate.setTime(localTime + (localTimeoffset - regionTimeoffset));
    return utcdate;
  }

  BLFormatDate(date: string): string {
    if (date != null) {
      //return date.replace('-','/');
      return date.split('-').join('/');
    }
    else
      return date;

  }


  checkVenueExists(){ 
    if(this.venueLength >= 1 && LoginUser.loggedinUser.Subscription == 'basic'){
      return true;
    }
    else if(this.venueLength >= 1 && LoginUser.loggedinUser.Subscription == 'premium'){
      return true
    }
    else{
      return false;
    }
  }

  isEnterprise():boolean{
    var name = this.authenticationService.CurrentUser().subscription;
    if(name != null && name != '' && name == 'enterprise'){
      return true;
    }
    else{
      return false;
    }  
  }


  isPremium():boolean{
    var name = this.authenticationService.CurrentUser().subscription;
    if(name != null && name != '' && name == 'premium'){
      return true;
    }
    else{
      return false;
    }
    
  }
  isBasic():boolean{
    var name = this.authenticationService.CurrentUser().subscription;
    if(name != null && name != '' && name == 'basic'){
      return true;
    }
    else{
      return false;
    }
    
  }
  




}
