<div mdbModal #frame="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: false}">
  <div class="modal-dialog modal-full-height modal-right modal-notify modal-info" role="document">
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header" [class]="theme.heading">
        <p class="heading lead">Filter
        </p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame.hide()">
          <span aria-hidden="true" class="white-text">×</span>
        </button>
      </div>


      <div class="row">
        <div class="col-md-5 mt-2 p-1 pl-4">
          <a (click)="saveFilters()" class="nav-link waves-light filterModalButtons"  mdbWavesEffect>
            <mdb-icon fas icon="save" class="mr-1" [class]="theme.icon"></mdb-icon>Save Filters<span class="sr-only">(current)</span>
          </a>
        </div>
      </div>

      <!-- Safwan Changes - End -->
      <!--Body-->
      <div class="modal-body">
        <div class="text-left">
          <!-- (selected)="GenerateCalendarData($event)" -->
          <div class="md-form" style="margin-top: 1rem !important;" [hidden]="common.isSingleVenueExist == null || common.isSingleVenueExist">
            <mat-form-field class="example-full-width">
              <mat-label [class]="theme.label">Venue</mat-label>
              <mat-select #venue_SkillSell [multiple]="true" label="Venue" id="VenueID" type="text" name="Venue"
                #Venue="ngModel" [(ngModel)]="VenueID">
                <mat-option [value]="0" (click)="VenuetoggleAllSelection()">-- Select All --</mat-option>
                <mat-option *ngFor="let option of AutoCompVenue.results | async" [value]="option.ID">
                  {{ option.VenueName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="md-form" style="margin-top: 1rem !important;">
            <mat-form-field class="example-full-width">
              <mat-label [class]="theme.label">Event Status</mat-label>
              <mat-select #eventStatus_SkillSell [multiple]="true" id="EventStatus" type="text" name="EventStatus"
                #EventStatus="ngModel" [(ngModel)]="EventStatusID">
                <mat-option [value]="0" (click)="EventStatustoggleAllSelection()">-- Select All --</mat-option>
                <mat-option *ngFor="let option of AutoCompStatus.results | async" [value]="option.ID">
                  {{ option.EventStatus }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="md-form" style="margin-top: 1rem !important;">
            <mat-form-field class="example-full-width">
              <mat-label [class]="theme.label">Client</mat-label>
              <mat-select name="selectedClient" [class]="theme.label" #selectedClient="ngModel" [(ngModel)]="selectedClients"
                 multiple #multiSelect>
                <mat-option>
                  <ngx-mat-select-search name="searchClientName" #searchClientName="ngModel" [(ngModel)]="searchClient"
                    (ngModelChange)="AutoCompClient.searchResult.next($event)" placeholderLabel="Search..."
                    noEntriesFoundLabel="Not Found!">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option disabled="disabled" class="filter-option matCustomOption">

                  <button mat-button color="primary" (click)="toggleSelectAll(false)">
                    <span class="d-flex justify-content-between align-items-center">
                      <mat-icon style="font-size: 16px;height: 16px;width: 16px;margin-right: 6px;">unpublished</mat-icon>
                      <span style="font-size: 13px;">
                        Clear All
                      </span>
                    </span>
                      <!-- <mat-icon>unpublished</mat-icon>Clear All
                    </span> -->
                  </button>
                </mat-option>
                <mat-option *ngFor="let option of AutoCompClient.results | async " [value]="option.ID">
                  {{option.ClientName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row" style="margin-top: 1rem !important;">
            <div class="col-md-6">
              <button class="w-100 modalFiltersButton" mdbBtn [class]="theme.button" size="md" type="button"
                (click)="onFilterClick(frame)" mdbWavesEffect>
                <mdb-icon fas icon="filter" class="mr-1 filterButton" ></mdb-icon> Apply Filter
              </button>
            </div>
            <div class="col-md-6">
              <button class="w-100 modalFiltersButton" mdbBtn [class]="theme.outlineButton" size="md" type="button"
                (click)="onFilterClear(frame)" mdbWavesEffect outline="true">
                <mdb-icon fas icon="minus" class="mr-1" style="color: #095e8f !important;"></mdb-icon> Clear Filter
              </button>
            </div>
          </div>
        </div>

      </div>



    </div>
  </div>
</div>

<app-calendar-dashboard (onClick)="onCalendarClick($event)"></app-calendar-dashboard>

<div class="calendar mt-3" [hidden]="!isCalendar">
  <div class="">

    <div class="">
      
      <div class="row chips-desk mt-3">
        <div class="col-md-6 text-left pl-3" [hidden]="common.isSingleVenueExist == null || common.isSingleVenueExist">

          <!-- Chips -->



          <!-- <div [hidden]="IsManyVenues" class="chip " style="margin-bottom: 0.5rem !important;" -->
          <div class="chip " style="margin-bottom: 0.5rem !important;"
            *ngFor="let ven of venueMonthNotesList ; let i = index;" (click)="onChipVenueClick(ven.VenueName)"
            [ngClass]="{'selectedChip':selectedChipVenueName == ven.VenueName }">
            <div class="chipArea" style="border: solid;border-width: 1px;border-color: #d1dee3;text-align:center;">
              {{ven.ShortCode}}
            </div>
            {{ven.VenueName}}
          </div>

       

        </div>
     
        <div class=" pr-4" [ngClass]="common.isSingleVenueExist ? 'col-md-12 text-left':'col-md-6 text-right'">
          <div>
            <!-- <ul class="pl-2 IsBig"> -->
            <ul class="pl-2">

              <div style="margin-bottom: 0.5rem !important;" *ngFor="let chp of chpArray"
                (click)="onChipEventStatusClick(chp)"
                [ngClass]="{'selectedChip':selectedChipEventStatus != null && selectedChipEventStatus.eventStatus.EventStatus == chp.eventStatus.EventStatus }"
                class="chip contractstatus text-left d-block d-sm-block d-md-inline-flex  d-xl-inline-flex d-lg-inline-flex pr-3">
                <div class="chipArea"
                  [ngStyle]="{background:chp.eventStatus.ChipColor, border:chp.eventStatus.ChipColor == '#ffffff' ? 'solid 1px lightgray' : 'none' }">
                </div>
                {{chp.eventStatus.EventStatus}}
              </div>
            </ul>



          </div>
        </div>

        
      </div>

      <div class="chips-mob d-flex d-md-none d-lg-none d-xl-none d-sm-flex d-xs-flex align-items-center " [ngClass]="common.isSingleVenueExist ? 'justify-content-end':'justify-content-between'">
        <div class="" [hidden]="common.isSingleVenueExist == null || common.isSingleVenueExist">
          <div class="chip " style="margin-bottom: 0 !important;" *ngFor="let ven of venueMonthNotesList ; let i = index;"
            (click)="onChipVenueClick(ven.VenueName)" [ngClass]="{'selectedChip':selectedChipVenueName == ven.VenueName }">
            <div class="chipArea" style="border: solid;border-width: 1px;border-color: #d1dee3;text-align:center;">
              {{ven.ShortCode}}
            </div>
            {{ven.VenueName}}
          </div>
        </div>

        <div class="">
          <button [matMenuTriggerFor]="menuMob"  mat-icon-button type="button">
            <mdb-icon fas icon="ellipsis-v"></mdb-icon>
          </button>
        <mat-menu #menuMob="matMenu">

          <button mat-menu-item type="button" id="contact" class="btn-sm" *ngFor="let chp of chpArray" (click)="onChipEventStatusClick(chp)"  
          [ngClass]="{'selectedChip':selectedChipEventStatus != null && selectedChipEventStatus.eventStatus.EventStatus == chp.eventStatus.EventStatus }">
            {{chp.eventStatus.EventStatus}}
          </button>
    
        </mat-menu>
        </div>
      </div>

      <div class="row text-center">

        <div class=" col-sm-12 col-md-3 calendar_buttonRow">

          <div class="btn-group CalendarActionButton float-left">
            <button mdbBtn type="button" [class]="theme.button" class="waves-light CalendarNavButtons" mwlCalendarPreviousView
              [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay(viewDate)" mdbWavesEffect>
              <mdb-icon fas icon="angle-left"></mdb-icon>
            </button>
            <button mdbBtn type="button" [class]="theme.button" class="waves-light CalendarNavButtons" mwlCalendarToday
              [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay(viewDate)" mdbWavesEffect>Today</button>
            <button mdbBtn type="button" [class]="theme.button" class="waves-light CalendarNavButtons" mwlCalendarNextView
              [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay(viewDate)" mdbWavesEffect>
              <mdb-icon fas icon="angle-right"></mdb-icon>
            </button>
            
          </div>

        </div>

        <div class=" col-sm-12 col-md-5 text-center calendar_buttonRow d-flex justify-content-center calendar-icon-btn">
          <mat-form-field appearance="fill" class="matDateFieldCalendar " style="line-height: 1px !important;box-shadow: -4px 4px 4px 0px #e0e0e0;">
            <mat-label>Jump to Month</mat-label>
            <input matInput [matDatepicker]="picker" [value]="viewDate"
              (dateChange)="filterCalendar('change', $event)">
            <mat-datepicker-toggle matSuffix [for]="picker" [class]="theme.icon"></mat-datepicker-toggle>
            <mat-datepicker #picker startView="year" (monthSelected)="chosenMonthHandler($event, picker)"
              panelClass="example-month-picker">
            </mat-datepicker>
          </mat-form-field>
          <h1 class="MonthHeading">
            <mdb-badge *ngIf="view === CalendarView.Month" color="white" style="color: gray !important;">
              {{ viewDate | date:'MMMM, yyyy' }}
            </mdb-badge>
            <mdb-badge *ngIf="view === CalendarView.Week || view === CalendarView.Day" color="white"
              style="color: gray !important;">
              {{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}
            </mdb-badge>
          </h1>
        </div>

        <div class=" col-sm-12 col-md-4  calendar_buttonRow">

          <div class="btn-group CalendarActionButton float-right" role="group" aria-label="Basic example">
            <button mdbBtn type="button" [class]="theme.button" class="waves-light CalendarNavButtons"
              (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month"
              mdbWavesEffect>Month</button>
            <button mdbBtn type="button" [class]="theme.button" class="waves-light CalendarNavButtons"
              (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week"
              mdbWavesEffect>Week</button>
            <button mdbBtn type="button" [class]="theme.button" class="waves-light CalendarNavButtons"
              (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day"
              mdbWavesEffect>Day</button>
            <button mdbBtn type="button" [class]="theme.filterCalendarButton" style="background-color: whitesmoke !important;" outline="true"
              class="waves-light CalendarNavButtons" (click)="frame.show()" data-toggle="modal"
              data-target="#basicExample" mdbWavesEffect>
              <mdb-icon  fas icon="filter"></mdb-icon>
            </button>

         
          </div>

        </div>


      </div>
      <br />

 

      <div [ngSwitch]="view">
        <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
          [refresh]="refresh" (eventTimesChanged)="eventTimesChanged($event)" [cellTemplate]="customCellTemplate"
          (columnHeaderClicked)="clickedColumn = $event.isoDayNumber" [tooltipTemplate]="cellTooltipTemplate">
        </mwl-calendar-month-view>
        <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
          [refresh]="refresh" [cellTemplate]="customCellTemplate" (eventTimesChanged)="eventTimesChanged($event)"
          [tooltipTemplate]="cellTooltipTemplate" [dayStartHour]="6">
        </mwl-calendar-week-view>
        <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events"
          [refresh]="refresh" [tooltipTemplate]="cellTooltipTemplate" [dayStartHour]="6"
          (eventTimesChanged)="eventTimesChanged($event)">
        </mwl-calendar-day-view>
      </div>
    </div>
    <br>


    <div class="row" [hidden]="permissionService.CheckIfUserhasAccess_Section('Monthly Notes')"
      *ngIf="permissionService.CheckIfUserhasAccess_Action('Monthly Notes','View')">
      <div class="col-md-12">
        <mdb-accordion [multiple]="true">
          <mdb-accordion-item [collapsed]="true">
            <mdb-accordion-item-head [customClass]="theme.heading + ' white-text'" >Monthly Notes
            </mdb-accordion-item-head>
            <mdb-accordion-item-body>
              <div style="text-align: center;padding-top: 30px;" [hidden]="IsMonthNotesLoaded">
                <span class="spinner-border " role="status" aria-hidden="true"></span>
              </div>
              <div class="row" [hidden]="!IsMonthNotesLoaded">

                <div class="col-lg-6 col-md-12" *ngFor="let ven of venueMonthNotesList">

                  <br>
                  <div class="row" style="padding-bottom: 10px;">
                    <div class="col-md-3 col-xs-2">
                      <h5 style="margin-top: 10px;">{{ven.VenueName}}</h5>
                    </div>
                    <div class="col-md-9 col-xs-10 text-right">
                      <button type="button" (click)="OpenCalendarNotesModal(ven.ID)"
                        *ngIf="permissionService.CheckIfUserhasAccess_Action('Monthly Notes','Add') || permissionService.CheckIfUserhasAccess_Action('Monthly Notes','Edit')"
                        mdbBtn [class]="theme.button" class="waves-light" mdbWavesEffect size="sm">
                        <mdb-icon fas icon="plus" class="mr-1"></mdb-icon>Add
                      </button>
                    </div>
                  </div>

                  <div class="z-depth-1" style="height:600px">
                    <table mdbTable mdbTableScroll scrollY="true" maxHeight="600"  class="InputTable table">
                      <thead>
                        <tr>
                          <th>Notes</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let es of ven.VenueMonthNotes">
                          <td>
                            <div class="NotesAuditInfo">
                              Last Modified at {{es.CreatedDate | date:'MM/d/yyyy h:mm a'}} by
                              {{es.CreatedByName}}
                            </div>
                            <div style="white-space: pre-wrap;" [innerHTML]="es.Notes">

                            </div>
                          </td>
                          <td>
                            <div class="btn-group float-right">
                              <button mat-button matTooltip="Edit"
                                *ngIf="permissionService.CheckIfUserhasAccess_Action('Monthly Notes','Edit')"
                                (click)="EditCalendarNotesModal(ven.ID, es)">
                                <mat-icon class="flat-Icon-Color" [class]="theme.label">edit</mat-icon>
                              </button>
                              <button mat-button class="flat-Icon-Color" matTooltip="Delete"
                                *ngIf="permissionService.CheckIfUserhasAccess_Action('Monthly Notes','Delete')"
                                 (click)="DeleteNote(es.ID)">
                                <mat-icon [class]="theme.label">delete</mat-icon>
                              </button>
                            </div>
                          </td>

                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>


              </div>
            </mdb-accordion-item-body>
          </mdb-accordion-item>

        </mdb-accordion>
      </div>


    </div>

  </div>

  <br /><br /><br />


  <ng-template #modalContent let-close="close">
    <div class="modal-header">
      <h5 class="modal-title">Event action occurred</h5>
      <button type="button" class="close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        Action:
        <pre>{{ modalData?.action }}</pre>
      </div>
      <div>
        Event:
        <pre>{{ modalData?.event | json }}</pre>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="close()">
        OK
      </button>
    </div>
  </ng-template>

  <ng-template #customCellTemplate let-day="day" let-locale="locale" let-eventClicked="eventClicked"
    let-tooltipPlacement="tooltipPlacement" let-tooltipTemplate="tooltipTemplate"
    let-tooltipAppendToBody="tooltipAppendToBody" let-tooltipDelay="tooltipDelay">

    <!-- Safwan - 09/02/2023 - If user has no right to add event then show this div which is not clickable -->
    <div class="cal-cell-top" [hidden]="permissionService.CheckIfUserhasAccess_Action('Event','Add') == true">
      <div class="">
        <div class="">
          <span class="cal-day-number">
             {{ day.date | calendarDate:'monthViewDayNumber':locale }}
           <!-- {{ (day.date  ) == currentDay ?'current' : 'other'}} -->
          </span>
        </div>
      </div>
    </div>
    <!-- Safwan  - 09/02/2023 - Add *ngIf condition for that if user has rights to add event then show this div  -->
    <div class="cal-cell-top" [matMenuTriggerFor]="DayMenu" *ngIf="permissionService.CheckIfUserhasAccess_Action('Event','Add')">

      <div class="">
        

        <div class="">


          <span class="cal-day-number">
             {{ day.date | calendarDate:'monthViewDayNumber':locale }}
           <!-- {{ (day.date  ) == currentDay ?'current' : 'other'}} -->
          </span>
        
        </div>
        

   
      </div>
      <span class="calendar-add-btn" >
        <mat-icon style="font-size: 23px;">add</mat-icon>
        <span>Add Event</span>
      </span>

      <mat-menu #DayMenu="matMenu" yPosition="below" >
       

        <button *ngIf="permissionService.CheckIfUserhasAccess_Action('Event','Add')" mat-menu-item
          (click)="OpenEventModal(day.date)">
          <mat-icon>add</mat-icon>
          <span>New Event</span>
        </button>

      </mat-menu>


    </div>


    <div>
      <div class="cal-events HolidayEventTile" *ngFor="let event of day.events"
        (mwlClick)="eventClicked.emit({event: event})" [hidden]="!event.meta.IsHoliday" class="HolidayEventTile">
        <div>{{event.title}} </div>
      </div>
    </div>

    <div [hidden]="permissionService.CheckIfUserhasAccess_Action('Event','Secured Events') == false">
      <div class="cal-events" *ngFor="let event of day.events" [style.backgroundColor]="event.color?.primary"
        class="CalendarEventTile" (mwlClick)="eventClicked.emit({event: event})"
        [hidden]="!event.meta.IsSecuredEvent">
        <div *ngIf="!event.meta.IsHoliday">
          {{event.meta.icon}} | <mdb-icon fas icon="lock" style="float:right;line-height: inherit;margin-right:5px;"
            (click)="UnlockEvent(event.meta.EventID)"></mdb-icon>&nbsp;
        </div>
      </div>
    </div>

    <div [hidden]="permissionService.CheckIfUserhasAccess_Action('Event','Secured Events') == true">
      <div class="cal-events" *ngFor="let event of day.events" [style.backgroundColor]="event.color?.primary"
        class="CalendarEventTile" (mwlClick)="eventClicked.emit({event: event})"
        [hidden]="!event.meta.IsSecuredEvent">
        <div *ngIf="!event.meta.IsHoliday"
          [ngStyle]="{'color': event.meta.TextColor? 'event.meta.TextColor' : 'black'}">
          {{event.meta.icon}} | <mdb-icon fas icon="lock" style="float:right;line-height: inherit;margin-right:5px;">
          </mdb-icon>&nbsp;
        </div>
      </div>
    </div>

    

    <div class="cal-events" *ngFor="let event of day.events"
      [mwlCalendarTooltip]="event.title | calendarEventTitle: 'monthTooltip':event"
      [tooltipPlacement]="tooltipPlacement" [tooltipEvent]="event" [tooltipTemplate]="tooltipTemplate"
      [tooltipAppendToBody]="tooltipAppendToBody" [tooltipDelay]="tooltipDelay"
      [style.backgroundColor]="event.color?.primary" class="CalendarEventTile"
      [ngStyle]=" {'text-decoration': event.meta.EventStatusID == -1000 ? 'line-through':'none'}"
      (mwlClick)="eventClicked.emit({event: event})" [matMenuTriggerFor]="menu"
      [hidden]="event.meta.IsSecuredEvent || event.meta.IsHoliday">

      <!-- For Default Event-->
      <div [hidden]="event.meta.IsSecuredEvent" style="display: flex;justify-content: space-between;align-items: end;"
        [ngStyle]="{'color': event.meta.TextColor  != null ? event.meta.TextColor : 'black'}">
        <!-- <span style="display: inline-block;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;" [style]="event.meta.EventNotesID == 0 && event.meta.SubEventNotesID == 0 ? 'width: 150px;' :'width: 150px;' "> -->
          {{event.meta.icon}} |&nbsp;{{event.title}}
        <!-- </span>  -->
        <mdb-icon [hidden]="!event.meta.IsSecured" fas icon="unlock"
          style="float:right;line-height: inherit;margin-right:5px;"></mdb-icon>
        <mat-icon [hidden]="event.meta.EventNotesID == 0 && event.meta.SubEventNotesID == 0 "
          style="float: right; height: 19px; font-size: 19px; color: gray;" class="pl-2">sticky_note_2</mat-icon>
      </div>




      <mat-menu #menu="matMenu" yPosition="below">

        <button mat-menu-item class="IsDesktopMobile" data-toggle="modal" data-target="#basicExample"
          (click)="frameEventPopup.show(); ShowDetails(event); ">
          <mat-icon>remove_red_eye</mat-icon>
          <span>View Details</span>
        </button>


        <button mat-menu-item [hidden]="permissionService.CheckIfUserhasAccess('Event Management')"
          (click)="openeventdashboard(event.meta.EventID, day.date)">
          <mat-icon>desktop_windows</mat-icon>
          <span>Event Portal</span>
        </button>
        
        <button [hidden]="permissionService.CheckIfUserhasAccess('Event Management')" mat-menu-item [matMenuTriggerFor]="Notes">
          <mat-icon>open_in_new</mat-icon>
          Notes
        </button>

       
        <button *ngIf="false" [hidden]="permissionService.CheckIfUserhasAccess('Event Inventory')" mat-menu-item
          [matMenuTriggerFor]="Production">
          <mat-icon>open_in_new</mat-icon>
          Production
        </button>
      </mat-menu>
      <mat-menu #Event="matMenu">

         <button *ngIf="permissionService.CheckIfUserhasAccess_Action('Event','View')" mat-menu-item
          [routerLink]="['/event/portal']" [queryParams]="{ID:event.meta.EventID}">
          <mat-icon>remove_red_eye</mat-icon>
          <span>Event Details</span>
        </button>

    
        <button *ngIf="permissionService.CheckIfUserhasAccess_Action('Event','Performance Delete')"
          [hidden]=" event.meta.IsSettlement == true || event.meta.EventStatusID==1 || event.meta.EventStatusID==2 || event.meta.EventStatusID==3"
          mat-menu-item (click)="DeletePerformance(event.id, event.meta.EventID)">
          <mat-icon>delete</mat-icon>
          <span>Delete Performance</span>

        </button>

        <button *ngIf="permissionService.CheckIfUserhasAccess_Action('Event','Delete')" mat-menu-item
          (click)="deleteEventStatus(event.meta.EventID)">
          <mat-icon>delete</mat-icon>
          <span>Delete Event</span>
        </button>
      </mat-menu>
      <mat-menu #Settlement="matMenu">
        <button *ngIf="permissionService.CheckIfUserhasAccess_Action('Settlement','Add')" [routerLink]="['/settlement/form']"
          [queryParams]="{EventID:event.meta.EventID, calendarDate:day.date}" mat-menu-item>
          <mat-icon>add</mat-icon>
          <span>Add Settlement</span>
        </button>
        <button
          [hidden]="event.meta.SettlementEventID > 0 ? false : true && permissionService.CheckIfUserhasAccess_Section('Settlement List')"
          [routerLink]="['/settlement/list']" [queryParams]="{EventID:event.meta.EventID}" mat-menu-item>
          <mat-icon>remove_red_eye</mat-icon>
          <span>Settlement List</span>
        </button>
      </mat-menu>
      <mat-menu #Notes="matMenu">
        <button *ngIf="permissionService.CheckIfUserhasAccess_Action('Event Notes','View')"
          [hidden]="permissionService.CheckIfUserhasAccess_Section('Event Notes')" mat-menu-item
          (click)="OpenNotesModal(event.meta.EventID)">
          <mat-icon>remove_red_eye</mat-icon>
          <span>Event Notes</span>
          <mat-icon [hidden]="event.meta.EventNotesID == 0 " style="padding-left: 10px; color: gray;">sticky_note_2
          </mat-icon>
        </button>
        <button *ngIf="permissionService.CheckIfUserhasAccess_Action('Performance Notes','View')"
          [hidden]="permissionService.CheckIfUserhasAccess_Section('Performance Notes')" mat-menu-item
          (click)="OpenPerformanceNotesModal(event.meta.EventID, event.id)">
          <mat-icon>remove_red_eye</mat-icon>
          <span>Performance Notes</span>
          <mat-icon [hidden]="event.meta.SubEventNotesID == 0 " style="padding-left: 10px; color: gray;">sticky_note_2
          </mat-icon>
        </button>


      </mat-menu>
      <mat-menu #Production="matMenu">
        <button *ngIf="permissionService.CheckIfUserhasAccess_Action('Event Inventory','Add')"
          [hidden]="event.meta.InventoryEventID != 0" [routerLink]="['/event/event-inventory/form/edit']"
          [queryParams]="{EventID:event.meta.EventID, calendarDate:day.date, VenueID:event.meta.VenueID}"
          mat-menu-item>
          <mat-icon>add</mat-icon>
          <span>Add</span>
        </button>
        <button *ngIf="event.meta.InventoryEventID > 0"
          [hidden]="permissionService.CheckIfUserhasAccess_Section('Event Inventory')"
          [routerLink]="['/event/event-inventory/form/cards']"
          [queryParams]="{EventID: event.meta.EventID, calendarDate: day.date, VenueID:event.meta.VenueID}"
          mat-menu-item>
          <mat-icon>remove_red_eye</mat-icon>
          <span>View</span>
        </button>
      </mat-menu>


    </div>

  </ng-template>

  <ng-template #cellTooltipTemplate let-event="event" let-tooltipPlacement="tooltipPlacement" let-locale="locale">

    <div class="cal-tooltip IsMobile">

      <div class="cal-tooltip-arrow"></div>

      <div class="cal-tooltip-inner EventToolTip" [tooltipPlacement]="tooltipPlacement"
        style="background-color:transparent">



        <div class="TableToolTipCalendar" [class]="'TableToolTipCalendar-'+theme.themeColor" style="background-color: white;">
          <table mdbTable class="z-depth-1 InputTable">
            <tbody>
              <tr>
                <td data-label="Event ID">{{event.meta.EntryNo}}</td>
                <td data-label="Event Name">{{event.title}}</td>
                <td data-label="Start Date">{{event.start | date:'mediumDate'}}</td>
                <td data-label="End Date">{{event.end | date:'mediumDate'}}</td>
                <td data-label="Start Time">{{event.meta.ShowStartTime}} </td>
                <td data-label="End Time">{{event.meta.ShowEndTime}}</td>
                <td data-label="Status" [ngStyle]="{'background-color': event.color?.primary} ">
                  {{event.meta.EventStatus}} </td>
                <td data-label="Venue">{{event.meta.Venue}}</td>
                <td data-label="Client">{{event.meta.ClientName}}</td>
                <td data-label="In Use">{{event.meta.SubEventTypeName}}</td>
                <td data-label="Modified By">{{event.meta.ModifiedBy}}</td>
                <td data-label="Modified Date">{{event.meta.ModifiedDate | date:'MM/d/yyyy'}}</td>
              </tr>


            </tbody>
          </table>
        </div>


      </div>

    </div>


  </ng-template>


  <div mdbModal #frameEventPopup="mdbModal" class="modal fade" id="frameEventPopupModalTop" tabindex="-1"
    role="dialog" aria-labelledby="frameEventPopupModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: false}">
    <div class="modal-lg modal-content modal-dialog cascading-modal modal-top" role="document">
      <div class="modal-content">

        <div
          class="modal-header white-text  gradient-card-header narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center" [class]="theme.heading">

          <h4 class="h4-responsive"> Event Details </h4>

          <div class="btn-group" role="group" aria-label="Basic example">

            <a (click)="frameEventPopup.hide()">
              <button mdbBtn type="button" [class]="theme.button" size="sm" class="waves-light" mdbWavesEffect>
                <mdb-icon fas icon="times"></mdb-icon>
              </button>
            </a>

          </div>

        </div>

        <!--Body-->
        <div class="modal-body mb-0 text-center">

          <div class="TableToolTipCalendar " [class]="'TableToolTipCalendar-'+theme.themeColor" style="background-color: white;">
            <table mdbTable class="z-depth-1 InputTable">
              <tbody>
                <tr>
                  <td data-label="Event ID">{{eventInfo != null ? eventInfo.meta.EntryNo : ''}}</td>
                  <td data-label="Event Name">{{eventInfo != null ? eventInfo.title: ''}}</td>
                  <td data-label="Start Date">{{eventInfo != null ? (eventInfo.start | date:'mediumDate'): ''}}</td>
                  <td data-label="End Date">{{eventInfo != null ? (eventInfo.end | date:'mediumDate'): ''}}</td>
                  <td data-label="Start Time">{{eventInfo != null ? eventInfo.meta.ShowStartTime: ''}} </td>
                  <td data-label="End Time">{{eventInfo != null ? eventInfo.meta.ShowEndTime: ''}}</td>
                  <td data-label="Status"
                    [ngStyle]="{'background-color': eventInfo != null ? eventInfo.color?.primary : 'white'} ">
                    {{eventInfo != null ? eventInfo.meta.EventStatus: ''}} </td>
                  <td data-label="Venue">{{eventInfo != null ? eventInfo.meta.Venue: ''}}</td>
                  <td data-label="Client">{{eventInfo != null ? eventInfo.meta.ClientName: ''}}</td>
                  <td data-label="In Use">{{eventInfo != null ? eventInfo.meta.SubEventTypeName: ''}}</td>
                  <td data-label="Modified By">{{eventInfo != null ? eventInfo.meta.ModifiedBy: ''}}</td>
                  <td data-label="Modified Date">
                    {{eventInfo != null ?  (eventInfo.meta.ModifiedDate | date:'MM/d/yyyy'): ''}}</td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>

<div class="avail mt-3" [hidden]="!isAvail">
  <app-canlendar-avail [VenueMonthData]="venueMonthNotesList" [calendar_viewDate]="viewDate" ></app-canlendar-avail>
</div>

<div class="list" [hidden]="!isList">
  <!-- <app-calendar-list></app-calendar-list> -->
</div>

