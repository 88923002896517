import { Injectable } from '@angular/core';
import  { GenericService } from 'src/app/services/generic.service'

@Injectable({
  providedIn: 'root'
})
export class ContractTemplatesService {

controllerName:string = '';

  constructor(
    public genericService:GenericService

  ) { }

  post(formData:any){
    return this.genericService.postData(formData, this.controllerName, '');
  }

  postBulk(formData:any[]){
    return this.genericService.bulkPostData(this.controllerName, formData,'');
  }

}
