<div class="modal-header light-blue darken-3 white-text  gradient-card-header blue-gradient narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center">
    <h4 class="h4-responsive" style="margin-bottom: 0 !important;">
        Terms and conditions
    </h4>
    <div class="btn-group" role="group" aria-label="Basic example">
        <a (click)="modalRef.hide()">
            <button mdbBtn type="button" color="info" size="sm" class="waves-light" mdbWavesEffect>
                <mdb-icon fas icon="times"></mdb-icon>
            </button>
        </a>
    </div>
</div>

<!--Body-->
<div class="modal-body mb-0 ">
    <div class="row">
        <!-- <div class="col-md-12" [innerHTML]="terms?.content"></div> -->
        <div>
            <!-- <iframe src="https://docs.google.com/gview?url=http://ieee802.org/secmail/docIZSEwEqHFr.doc&embedded=true" frameborder="0">
            </iframe> -->
            <!-- <p class="MsoNormal"><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span></p> -->

           

            <h1 style="margin-left:18.0pt;text-indent:-18.0pt;mso-list:l4 level1 lfo1"><a name="_Toc116387215"><!--[if !supportLists]--><span lang="EN-US" style="font-size:
            13.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:
            Arial">1.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;
            </span></span><!--[endif]--><span lang="EN-US" style="font-size:13.0pt;
            line-height:107%;font-family:&quot;Arial&quot;,sans-serif">DEFINITIONS</span></a><span lang="EN-US" style="font-size:13.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif"><o:p></o:p></span></h1>

            
            <p class="MsoNormal" style="margin-left:1.2pt;text-align:justify;line-height:
            187%;mso-pagination:none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
            mso-border-shadow:yes"><span lang="EN-US" style="font-size:10.5pt;line-height:
            187%;mso-bidi-font-family:Arial;color:#414141">The "Software
            Services" means the development of a software system initiated by
            Provider, VenueArc LLC listed below, under this VenueArc Software Agreement
            (the “Agreement”).&nbsp; <o:p></o:p></span></p>

            <p class="MsoNormal" style="margin-top:20.0pt;margin-right:67.05pt;margin-bottom:
            8.0pt;margin-left:1.5pt;text-align:justify;line-height:142%;mso-pagination:
            none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
            yes"><span lang="EN-US" style="font-size:10.5pt;line-height:142%;mso-bidi-font-family:
            Arial;color:#414141">"Client Content" means all data, code,
            trademarks, and copyrighted content (images) provided by Client for use by
            Provider in the activities relating to the Software Services.&nbsp; <o:p></o:p></span></p>

            <p class="MsoNormal" style="margin-top:20.0pt;margin-right:51.9pt;margin-bottom:
            8.0pt;margin-left:1.8pt;text-align:justify;text-indent:-.3pt;line-height:142%;
            mso-pagination:none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
            mso-border-shadow:yes"><span lang="EN-US" style="font-size:10.5pt;line-height:
            142%;mso-bidi-font-family:Arial;color:#414141">"Provider Content"
            means all data, code, trade secrets, patents, designs, videos, and text created
            by Provider for use in the Software Services.&nbsp;
            <o:p></o:p></span></p>

            

            

            

            <h1 style="margin-left:18.0pt;text-indent:-18.0pt;mso-list:l4 level1 lfo1"><a name="_Toc116387216"><!--[if !supportLists]--><span lang="EN-US" style="font-size:
            13.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:
            Arial">2.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;
            </span></span><!--[endif]--><span lang="EN-US" style="font-size:13.0pt;
            line-height:107%;font-family:&quot;Arial&quot;,sans-serif">SOFTWARE SERVICES</span></a><span lang="EN-US" style="font-size:13.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif"><o:p></o:p></span></h1>

         
            <p class="MsoNormal" style="margin-top:6.95pt;margin-right:36.8pt;margin-bottom:
            8.0pt;margin-left:0cm;text-align:justify;text-indent:.15pt;line-height:145%;
            mso-pagination:none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
            mso-border-shadow:yes"><span lang="EN-US" style="font-size:10.5pt;line-height:
            145%;mso-bidi-font-family:Arial;color:#414141">The Software Services includes
            Enterprise Venue and Event Management on the Cloud as per VenueArc’s
            specifications.&nbsp; VenueArc reserves the
            right to add or remove features of the Software Services at any time, with or
            without notice.&nbsp; <o:p></o:p></span></p>

            <p class="MsoListParagraphCxSpFirst" style="margin-top:6.95pt;margin-right:36.8pt;
            margin-bottom:8.0pt;margin-left:18.15pt;mso-add-space:auto;text-align:justify;
            text-indent:-18.0pt;line-height:145%;mso-pagination:none;mso-list:l0 level1 lfo2;
            border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes"><!--[if !supportLists]--><b><span lang="EN-US" style="font-size:10.0pt;
            line-height:145%;mso-fareast-font-family:&quot;Century Gothic&quot;;mso-bidi-font-family:
            &quot;Century Gothic&quot;;color:#414141">1.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><!--[endif]--><b><u><span lang="EN-US" style="font-size:10.0pt;line-height:145%;mso-bidi-font-family:Arial;
            color:#414141">Access and Use.</span></u></b><span lang="EN-US" style="font-size:
            10.0pt;line-height:145%;mso-bidi-font-family:Arial;color:#414141"> VenueArc
            hereby grants Customer a limited, non-exclusive, revocable, non-transferable
            right to access the Service and use the Software in for its internal business
            purposes during the Subscription Term and subject to the terms of the Sales
            Order(s), this Agreement and the Documentation. Customer may not sub-license or
            transfer the rights granted by VenueArc in this Agreement without the express,
            written permission of VenueArc.<o:p></o:p></span></p>

            <p class="MsoListParagraphCxSpMiddle" style="margin-top:12.0pt;margin-right:0cm;
            margin-bottom:12.0pt;margin-left:18.15pt;mso-add-space:auto;text-indent:-18.0pt;
            mso-list:l0 level1 lfo2"><!--[if !supportLists]--><b><span lang="EN-US" style="font-size:10.0pt;line-height:107%;mso-fareast-font-family:
            &quot;Century Gothic&quot;;mso-bidi-font-family:&quot;Century Gothic&quot;;color:#202020">2.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></b><!--[endif]--><b><u><span lang="EN-US" style="font-size:10.0pt;line-height:107%;color:#202020">Subscriptions</span></u></b><span lang="EN-US" style="font-size:10.0pt;line-height:107%;color:#202020">. Unless
            otherwise specified in a Sales Order,<o:p></o:p></span></p>

            

            <p class="MsoListParagraphCxSpMiddle" style="margin-top:12.0pt;margin-right:0cm;
            margin-bottom:8.0pt;margin-left:54.0pt;mso-add-space:auto;text-indent:-18.0pt;
            mso-list:l1 level1 lfo3"><!--[if !supportLists]--><span lang="EN-US" style="font-size:10.0pt;line-height:107%;mso-fareast-font-family:&quot;Century Gothic&quot;;
            mso-bidi-font-family:&quot;Century Gothic&quot;;color:#202020">a)<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp; </span></span><!--[endif]--><span lang="EN-US" style="font-size:10.0pt;line-height:107%;color:#202020">All Services
            described under this Agreement shall be purchased as Subscriptions, defined as
            software service access provided by VenueArc in exchange for full payment,
            pursuant to all other terms and conditions of this Agreement.<o:p></o:p></span></p>

            

            <p class="MsoListParagraphCxSpMiddle" style="margin-top:12.0pt;margin-right:7.0pt;
            margin-bottom:8.0pt;margin-left:54.0pt;mso-add-space:auto;text-indent:-18.0pt;
            line-height:101%;mso-list:l1 level1 lfo3"><!--[if !supportLists]--><span lang="EN-US" style="font-size:10.0pt;line-height:101%;mso-fareast-font-family:
            &quot;Century Gothic&quot;;mso-bidi-font-family:&quot;Century Gothic&quot;;color:#202020">b)<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;
            </span></span><!--[endif]--><span lang="EN-US" style="font-size:10.0pt;
            line-height:101%;color:#202020">New subscriptions may be added during a Subscription
            Term in exchange for payment to VenueArc for the same price as the underlying
            Subscription and prorated for the portion of that underlying Subscription Term
            remaining (hereinafter "Added Subscriptions").<o:p></o:p></span></p>

            

            <p class="MsoListParagraphCxSpMiddle" style="margin-top:12.0pt;margin-right:7.0pt;
            margin-bottom:8.0pt;margin-left:54.0pt;mso-add-space:auto;text-indent:-18.0pt;
            line-height:101%;mso-list:l1 level1 lfo3"><!--[if !supportLists]--><span lang="EN-US" style="font-size:10.0pt;line-height:101%;mso-fareast-font-family:
            &quot;Century Gothic&quot;;mso-bidi-font-family:&quot;Century Gothic&quot;;color:#202020">c)<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;
            </span></span><!--[endif]--><span lang="EN-US" style="font-size:10.0pt;
            line-height:101%;color:#202020">Any Added Subscriptions will terminate on the
            same date as the underlying Subscriptions.<o:p></o:p></span></p>

            

            <p class="MsoListParagraphCxSpMiddle" style="margin-top:12.0pt;margin-right:7.0pt;
            margin-bottom:8.0pt;margin-left:54.0pt;mso-add-space:auto;text-indent:-18.0pt;
            line-height:101%;mso-list:l1 level1 lfo3"><!--[if !supportLists]--><span lang="EN-US" style="font-size:10.0pt;line-height:101%;mso-fareast-font-family:
            &quot;Century Gothic&quot;;mso-bidi-font-family:&quot;Century Gothic&quot;;color:#202020">d)<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;
            </span></span><!--[endif]--><span lang="EN-US" style="font-size:10.0pt;
            line-height:101%;color:#202020">All Subscriptions and Added Subscriptions shall
            be extended or terminated under the auto-renewal and termination provisions of
            this Agreement.<o:p></o:p></span></p>

            <p class="MsoListParagraphCxSpMiddle" style="margin-top:12.0pt;margin-right:7.0pt;
            margin-bottom:8.0pt;margin-left:18.15pt;mso-add-space:auto;text-indent:-18.0pt;
            line-height:101%;mso-list:l0 level1 lfo2"><!--[if !supportLists]--><b><span lang="EN-US" style="font-size:10.0pt;
            line-height:101%;mso-fareast-font-family:&quot;Century Gothic&quot;;mso-bidi-font-family:
            &quot;Century Gothic&quot;;color:#202020">3.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><!--[endif]--><b><u><span lang="EN-US" style="font-size:10.0pt;
            line-height:101%;color:#202020">Usage Limits.<o:p></o:p></span></u></b></p>

            

            <p class="MsoListParagraphCxSpMiddle" style="margin-top:12.0pt;margin-right:0cm;
            margin-bottom:8.0pt;margin-left:54.0pt;mso-add-space:auto;text-indent:-18.0pt;
            mso-list:l3 level1 lfo4"><!--[if !supportLists]--><span lang="EN-US" style="font-size:10.0pt;line-height:107%;mso-fareast-font-family:&quot;Century Gothic&quot;;
            mso-bidi-font-family:&quot;Century Gothic&quot;;color:#202020">a)<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp; </span></span><!--[endif]--><span lang="EN-US" style="font-size:10.0pt;line-height:107%;color:#202020">Services
            rendered under this Agreement subject to certain usage limits. The usage charges
            may apply for overage usage not included in the flat rate. <o:p></o:p></span></p>

            

            <p class="MsoListParagraphCxSpMiddle" style="margin-top:12.0pt;margin-right:7.0pt;
            margin-bottom:8.0pt;margin-left:54.0pt;mso-add-space:auto;text-indent:-18.0pt;
            line-height:101%;mso-list:l3 level1 lfo4"><!--[if !supportLists]--><span lang="EN-US" style="font-size:10.0pt;line-height:101%;mso-fareast-font-family:
            &quot;Century Gothic&quot;;mso-bidi-font-family:&quot;Century Gothic&quot;;color:#202020">b)<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;
            </span></span><!--[endif]--><span lang="EN-US" style="font-size:10.0pt;
            line-height:101%;color:#202020">The Services may not be accessed by more than
            the number of Users specified in the subscription scope. <o:p></o:p></span></p>

                        
            <p class="MsoListParagraphCxSpMiddle" style="margin-top:12.0pt;margin-right:7.0pt;
            margin-bottom:8.0pt;margin-left:54.0pt;mso-add-space:auto;text-indent:-18.0pt;
            line-height:101%;mso-list:l3 level1 lfo4"><!--[if !supportLists]--><span lang="EN-US" style="font-size:10.0pt;line-height:101%;mso-fareast-font-family:
            &quot;Century Gothic&quot;;mso-bidi-font-family:&quot;Century Gothic&quot;;color:#202020">c)<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;
            </span></span><!--[endif]--><span lang="EN-US" style="font-size:10.0pt;
            line-height:101%;color:#202020">A User’s password may not be shared by the
            Customer with any other entity or individual.<o:p></o:p></span></p>

            

            <p class="MsoListParagraphCxSpMiddle" style="margin-top:12.0pt;margin-right:7.0pt;
            margin-bottom:8.0pt;margin-left:54.0pt;mso-add-space:auto;text-indent:-18.0pt;
            line-height:101%;mso-list:l3 level1 lfo4"><!--[if !supportLists]--><span lang="EN-US" style="font-size:10.0pt;line-height:101%;mso-fareast-font-family:
            &quot;Century Gothic&quot;;mso-bidi-font-family:&quot;Century Gothic&quot;;color:#202020">d)<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;
            </span></span><!--[endif]--><span lang="EN-US" style="font-size:10.0pt;
            line-height:101%;color:#202020">Customer shall not disassemble, decompile,
            reverse engineer (except to the extent that the reverse engineering restriction
            is prohibited by law and then Customer shall provide VenueArc prompt written
            notice of any such action), copy, distribute, modify or sell Services except as
            expressly and unambiguously permitted by this Agreement. If Customer violates
            this section, VenueArc shall have the right to immediately suspend
            Services.&nbsp; <o:p></o:p></span></p>

            

            <p class="MsoListParagraphCxSpMiddle" style="margin-top:6.95pt;margin-right:36.8pt;
            margin-bottom:8.0pt;margin-left:18.15pt;mso-add-space:auto;text-align:justify;
            text-indent:-18.0pt;line-height:145%;mso-pagination:none;mso-list:l0 level1 lfo2;
            border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes"><!--[if !supportLists]--><b><span lang="EN-US" style="font-size:10.0pt;
            line-height:145%;mso-fareast-font-family:&quot;Century Gothic&quot;;mso-bidi-font-family:
            &quot;Century Gothic&quot;;color:#414141">4.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><!--[endif]--><b><u><span lang="EN-US" style="font-size:10.0pt;line-height:145%;mso-bidi-font-family:Arial;
            color:#414141">Internet Connection.</span></u></b><span lang="EN-US" style="font-size:10.0pt;line-height:145%;mso-bidi-font-family:Arial;color:#414141">
            A high-speed Internet connection is required for proper use of the Services.
            Customer is responsible for procuring, securing and maintaining network
            connections that connect Users to the Services, including but not limited to,
            "browser" software that supports protocols used by VenueArc. VenueArc
            is not responsible for notifying Customer or Users of any upgrades, fixes or
            enhancements to any such software or for any compromise of Customer Data
            transmitted across networks or telecommunications facilities that are not
            owned, operated or controlled by VenueArc.<o:p></o:p></span></p>

            

            <p class="MsoListParagraphCxSpMiddle" style="margin-top:6.95pt;margin-right:36.8pt;
            margin-bottom:8.0pt;margin-left:18.15pt;mso-add-space:auto;text-align:justify;
            text-indent:-18.0pt;line-height:145%;mso-pagination:none;mso-list:l0 level1 lfo2;
            border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes"><!--[if !supportLists]--><b><span lang="EN-US" style="font-size:10.0pt;line-height:145%;mso-fareast-font-family:
            &quot;Century Gothic&quot;;mso-bidi-font-family:&quot;Century Gothic&quot;;color:#414141">5.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></b><!--[endif]--><b><u><span lang="EN-US" style="font-size:
            10.0pt;line-height:145%;mso-bidi-font-family:Arial;color:#414141">Reservation
            of Rights, License. </span></u></b><span lang="EN-US" style="font-size:10.0pt;
            line-height:145%;mso-bidi-font-family:Arial;color:#414141">VenueArc and its
            licensors retain all rights, title, and interest to all intellectual property
            created, used, or provided by VenueArc for the purposes of this Agreement,
            including, but not limited to, all Software and Documentation. VenueArc shall
            own all rights, title, and interest in and to all modifications, improvements
            or derivatives of any part of the Services (created by either party). Customer
            hereby makes all assignments necessary to provide VenueArc such ownership rights.
            Notwithstanding anything in this Agreement to the contrary, VenueArc will
            always retain any and all ownership rights in VenueArc technology. <b><u><o:p></o:p></u></b></span></p>

            

            <p class="MsoListParagraphCxSpMiddle" style="margin-top:6.95pt;margin-right:36.8pt;
            margin-bottom:8.0pt;margin-left:18.15pt;mso-add-space:auto;text-align:justify;
            line-height:145%;mso-pagination:none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
            mso-border-shadow:yes"><span lang="EN-US" style="font-size:10.0pt;line-height:
            145%;mso-bidi-font-family:Arial;color:#414141">VenueArc grants to Customer a
            non-exclusive, non-transferable license to use for internal business purposes
            only VenueArc’s Software and associated documentation hereunder by VenueArc to
            Customer, subject to the license scope and other restrictions set forth in this
            agreement.&nbsp; Customer may permit its
            employees and officers to use the Software and associated documentation
            consistent with this Agreement; provided, however, that Customer shall be
            responsible for any acts of its employees, agents, and/or independent
            contractors which are inconsistent with this Agreement.&nbsp; Without VenueArc’s express, written, and
            prior consent, Customer may not (i) copy, sublicense, distribute, rent lease,
            loan, resell, modify, or translate the Software or create derivative works
            based thereon; (ii) directly or indirectly decompile, disassemble, reverse
            engineer or otherwise attempt to learn the source code, structure, algorithms
            or ideas underlying the Software; (iii) provided services bureau, time share or
            subscription services based on the Software; or (iv) remove, obscure or modify
            any markings, labels or any notice of the proprietary rights, including
            copyright, patent or trademark notices of VenueArc or its licensors.&nbsp; Unintentional, inadvertent, or third party
            public disclosure of any part of the Software shall not entitle or allow
            Customer to violate any part of this Agreement, including but not limited to
            these Reservation of Rights.&nbsp; Customer
            may make one backup copy of the Software solely for backup purposes.&nbsp; VenueArc and its licensors, as applicable, retain
            full ownership and intellectual property rights to the software and
            documentation.&nbsp; If Customer acquires any
            rights to the Software or documentation, Customer hereby assigns all of those
            rights to VenueArc or its licensors, as applicable.&nbsp; No license rights are granted (whether by
            implied license or otherwise) to Customer, except as specifically provided in
            this Agreement.&nbsp; VenueArc is and shall be
            the sole and exclusive owner of all intellectual property rights in the
            software and documentation it provides to Customer, including any and all
            inventions, technology, known-how and other intellectual property made,
            conceived, created, reduced to practice or otherwise developed by
            VenueArc.&nbsp; Customer acknowledges that the
            Software contains valuable intellectual property of VenueArc and the
            proprietary information of VenueArc.&nbsp;
            Accordingly, Customer agrees that it will not, any time during the term
            of this Agreement, reverse engineer or otherwise attempt to discern the intellectual
            property of the Software, nor will Customer permit, request, license, contract,
            or otherwise cause or request any third party to do any of the foregoing. <o:p></o:p></span></p>

            

            <p class="MsoListParagraphCxSpMiddle" style="margin-top:6.95pt;margin-right:36.8pt;
            margin-bottom:8.0pt;margin-left:18.15pt;mso-add-space:auto;text-align:justify;
            line-height:145%;mso-pagination:none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
            mso-border-shadow:yes"><span lang="EN-US" style="font-size:10.0pt;line-height:
            145%;mso-bidi-font-family:Arial;color:#414141">For technology advances and
            production efficiency, VenueArc reserves the right, from time to time, in its
            sole discretion and without incurring any liability to Customer to:<o:p></o:p></span></p>

            <p class="MsoListParagraphCxSpMiddle" style="margin-top:12.0pt;margin-right:0cm;
            margin-bottom:8.0pt;margin-left:54.0pt;mso-add-space:auto;text-indent:-18.0pt;
            mso-list:l2 level1 lfo5"><!--[if !supportLists]--><span lang="EN-US" style="font-size:10.0pt;line-height:107%;mso-fareast-font-family:&quot;Century Gothic&quot;;
            mso-bidi-font-family:&quot;Century Gothic&quot;;color:#202020">a)<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp; </span></span><!--[endif]--><span lang="EN-US" style="font-size:10.0pt;line-height:107%;color:#202020">Discontinue
            or limit its provision of any Services upon thirty (30) days’ notice.<o:p></o:p></span></p>

            

            <p class="MsoListParagraphCxSpMiddle" style="margin-top:12.0pt;margin-right:0cm;
            margin-bottom:8.0pt;margin-left:54.0pt;mso-add-space:auto;text-indent:-18.0pt;
            mso-list:l2 level1 lfo5"><!--[if !supportLists]--><span lang="EN-US" style="font-size:10.0pt;line-height:107%;mso-fareast-font-family:&quot;Century Gothic&quot;;
            mso-bidi-font-family:&quot;Century Gothic&quot;;color:#202020">b)<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp; </span></span><!--[endif]--><span lang="EN-US" style="font-size:10.0pt;line-height:107%;color:#202020">Without
            materially impairing the applicable functionality at the time Customer paid in
            full for its Subscription, alter the specifications, design, construction or
            territorial or other market scope of distribution of any product or service.<o:p></o:p></span></p>

            

            <p class="MsoListParagraphCxSpMiddle" style="margin-top:12.0pt;margin-right:0cm;
            margin-bottom:8.0pt;margin-left:54.0pt;mso-add-space:auto;text-indent:-18.0pt;
            mso-list:l2 level1 lfo5"><!--[if !supportLists]--><span lang="EN-US" style="font-size:10.0pt;line-height:107%;mso-fareast-font-family:&quot;Century Gothic&quot;;
            mso-bidi-font-family:&quot;Century Gothic&quot;;color:#202020">c)<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp; </span></span><!--[endif]--><span lang="EN-US" style="font-size:10.0pt;line-height:107%;color:#202020">Change its
            sales and distribution policies and practices. <o:p></o:p></span></p>

            
                        

            <h1 style="margin-left:18.0pt;text-indent:-18.0pt;mso-list:l4 level1 lfo1"><a name="_Toc116387217"><!--[if !supportLists]--><span lang="EN-US" style="font-size:
            13.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:
            Arial">3.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;
            </span></span><!--[endif]--><span lang="EN-US" style="font-size:13.0pt;
            line-height:107%;font-family:&quot;Arial&quot;,sans-serif">APPLICATION HOSTING</span></a><span lang="EN-US" style="font-size:13.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif"><o:p></o:p></span></h1>

            

            <p class="MsoNormal" style="margin-top:6.95pt;margin-right:36.8pt;margin-bottom:
            8.0pt;margin-left:0cm;text-align:justify;text-indent:.15pt;line-height:145%;
            mso-pagination:none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
            mso-border-shadow:yes"><span lang="EN-US" style="font-size:10.5pt;line-height:
            145%;mso-bidi-font-family:Arial;color:#414141">If customer choses to use their
            own physical and/or virtual servers for data hosting, Customer shall be
            responsible for the management and protection of its’ own Client Content.
            Customer waives any claim against VenueArc In a case of data breach, disaster
            and/or any instance that results in data loss and/or data breach, and VenueArc
            shall not be responsible for any such or related occurrence.<o:p></o:p></span></p>

            <h1 style="margin-left:18.0pt;text-indent:-18.0pt;mso-list:l4 level1 lfo1"><a name="_Toc116387218"><!--[if !supportLists]--><span lang="EN-US" style="font-size:
            13.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:
            Arial">4.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;
            </span></span><!--[endif]--><span lang="EN-US" style="font-size:13.0pt;
            line-height:107%;font-family:&quot;Arial&quot;,sans-serif">COMPENSATION &amp; TERM</span></a><span lang="EN-US" style="font-size:13.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif"><o:p></o:p></span></h1>

            
            <p class="MsoNormal" style="margin-top:6.95pt;margin-right:36.8pt;margin-bottom:
            8.0pt;margin-left:0cm;text-align:justify;line-height:145%;mso-pagination:none;
            border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes"><span lang="EN-US" style="font-size:10.5pt;line-height:145%;color:#414141">Client shall
            pay Provider fees specified for this contract, with the Software Services
            delivered at the beginning and throughout the term of the contract.&nbsp; The duration of this Agreement shall be one
            (1) year.&nbsp; This Agreement shall
            automatically renew on the anniversary of the Effective Date of this Agreement,
            unless terminated within thirty (30) days’ notice by Provider to Client.&nbsp; <o:p></o:p></span></p>

            <p class="MsoNormal" style="margin-top:18.6pt;margin-right:50.8pt;margin-bottom:
            8.0pt;margin-left:0cm;text-align:justify;line-height:145%;mso-pagination:none;
            border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes"><span lang="EN-US" style="font-size:10.5pt;line-height:145%;color:#414141">Provider may
            terminate this contract and suspend the Software Services immediately if it
            determines that the Client has materially breached any portion of this Contract
            or if payment is not fully made.&nbsp; All
            payments to be made by recurring bank transfer, credit or debit card, or check.
            Credit card payments are subjected to a 3% processing fee.&nbsp; <o:p></o:p></span></p>

            

            <h1 style="margin-left:18.0pt;text-indent:-18.0pt;mso-list:l4 level1 lfo1"><a name="_Toc116387219"><!--[if !supportLists]--><span lang="EN-US" style="font-size:
            13.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:
            Arial">5.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;
            </span></span><!--[endif]--><span lang="EN-US" style="font-size:13.0pt;
            line-height:107%;font-family:&quot;Arial&quot;,sans-serif">ADDITIONAL WORK</span></a><span lang="EN-US" style="font-size:13.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif"><o:p></o:p></span></h1>

            
            <p class="MsoNormal" style="margin-top:19.7pt;margin-right:51.75pt;margin-bottom:
            8.0pt;margin-left:0cm;text-align:justify;line-height:150%;mso-pagination:none"><span lang="EN-US" style="font-size:10.5pt;line-height:150%">Additional work, upon the
            request and authorization of the Client, will result in additional charges,
            mutually agreed by Client and Provider.&nbsp;
            Additional work is defined as any software services requested by Client
            that do not fit into the scope of the Software Services described herein, as
            determined by the Provider. In the case of requested additional work, the
            Client will be provided, at Provider’s option, a written change order including
            an estimate for any additional work requested. Provider may waive this requirement
            for a formal change order and accept written acceptance by Client of any
            change, including but not limited to email, text message, fax, or any other
            written communication.&nbsp; <span style="color:#414141"><o:p></o:p></span></span></p>

                       

            <h1 style="margin-left:18.0pt;text-indent:-18.0pt;mso-list:l4 level1 lfo1"><a name="_Toc116387220"><!--[if !supportLists]--><span lang="EN-US" style="font-size:
            13.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:
            Arial">6.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;
            </span></span><!--[endif]--><span lang="EN-US" style="font-size:13.0pt;
            line-height:107%;font-family:&quot;Arial&quot;,sans-serif">INTELLECTUAL PROPERTY AND
            CONFIDENTIALITY</span></a><span lang="EN-US" style="font-size:13.0pt;line-height:
            107%;font-family:&quot;Arial&quot;,sans-serif"> <o:p></o:p></span></h1>


            <p class="MsoNormal" style="margin-top:38.7pt;margin-right:62.4pt;margin-bottom:
            8.0pt;margin-left:0cm;text-align:justify;line-height:146%;mso-pagination:none;
            border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes"><span lang="EN-US" style="font-size:10.5pt;line-height:146%;color:#414141">Client
            agrees that Provider shall retain exclusive ownership rights to all software
            content provided by Provider, including but not limited to all software,
            graphics, namesake, likeness, code, libraries, confidential information, and
            other related intellectual property.&nbsp; The
            ownership of any content that is created by the Provider and posted to
            Provider-owned sites and channels remains the Provider's property.&nbsp; Client Data shall be administered pursuant to
            the Venue Arc Privacy Policy attached to this Agreement.&nbsp; Client agrees not to share any of Provider’s
            Confidential Information with any third party.&nbsp;
            Except to the extent otherwise required either by law or as otherwise
            provided herein,&nbsp; this Agreement imposes
            no obligation upon the Client with respect to information that: (i) was in the
            Client's possession (other than pursuant to a violation of a duty of
            confidentiality) without a restriction upon use before receipt from the other
            party;&nbsp; (ii) is or becomes available to
            the public through no fault of the Client;&nbsp;
            (iii) is received in good faith by the Client from a third party who is
            not subject&nbsp; to an obligation of
            confidentiality owed to the Client; or (iv) is independently developed by the
            Client without reference to information&nbsp;
            created or received in the course of performing this Agreement.&nbsp; <o:p></o:p></span></p>

            <p class="MsoNormal" style="margin-top:0cm;margin-right:62.4pt;margin-bottom:
            8.0pt;margin-left:.25pt;text-align:justify;text-indent:-.25pt;line-height:146%;
            mso-pagination:none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
            mso-border-shadow:yes"><span lang="EN-US" style="font-size:10.5pt;line-height:
            146%;color:#414141">Confidential information shall be defined by this Agreement
            as (i) the software, systems, internal organization,&nbsp; internal telephone directory, designs, flow
            charts, plans, product and component drawings, specifications,&nbsp; manuals, marketing information and other data
            and information relating to the existing or planned&nbsp; business, software, or information technology
            systems of either party (referenced as the “disclosing&nbsp; party”); (ii) the work product, and any other
            confidential information or trade secrets respecting the business affairs or
            property of the disclosing party as provided to or received by the other party
            (the&nbsp; “receiving party”) in connection
            with or as a result of the performance of this Agreement; (iii) the Client&nbsp; Data and Personal Information, (iv) the terms
            of this Agreement and any other agreement entered into&nbsp; by either party; and (v) all notes, analyses,
            compilations, studies or other material containing any of the&nbsp; information described in clauses (i), (ii),
            (iii), and (iv) above. Such information shall be deemed Confidential
            Information whether or not such information is provided in writing and whether
            or not such information is marked confidential or proprietary. <o:p></o:p></span></p>

            <p class="MsoNormal" style="margin-top:0cm;margin-right:62.4pt;margin-bottom:
            8.0pt;margin-left:.5pt;text-align:justify;text-indent:-.25pt;line-height:146%;
            mso-pagination:none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
            mso-border-shadow:yes"><span lang="EN-US" style="font-size:10.5pt;line-height:
            146%">Client agrees, subject to the provisions above, not to use any
            information</span><span lang="EN-US"> </span><span lang="EN-US" style="font-size:
            10.5pt;line-height:146%">generated in the business relationship between
            VenueArc and Client (including software, documents, work papers, tangible
            products, and other material, including all copies thereof) for any purposes
            other than in furtherance of Client’s performance of services in accordance
            with this Agreement. Client shall not license, sell, transfer, or otherwise
            monetize or enable third parties to monetize the Software Services, except for
            utilizing the Software Services for facilitating events, venues, and related
            gatherings.</span><span lang="EN-US">&nbsp; <o:p></o:p></span></p>

            

            <h1 style="margin-left:18.0pt;text-indent:-18.0pt;mso-list:l4 level1 lfo1"><a name="_Toc116387221"><!--[if !supportLists]--><span lang="EN-US" style="font-size:
            13.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:
            Arial">7.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;
            </span></span><!--[endif]--><span lang="EN-US" style="font-size:13.0pt;
            line-height:107%;font-family:&quot;Arial&quot;,sans-serif">LATE FEES</span></a><span lang="EN-US" style="font-size:13.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif"><o:p></o:p></span></h1>
            

            <p class="MsoNormal"><span lang="EN-US" style="font-size:10.5pt;line-height:107%;
            color:#414141">Late payments by Client shall be subject to late penalty fees of
            1.5% per month from the due date until the amount is paid, or the maximum
            extent allowable by law.&nbsp;&nbsp; </span><span lang="EN-US"><o:p></o:p></span></p>

            <h1 style="margin-left:18.0pt;text-indent:-18.0pt;mso-list:l4 level1 lfo1"><a name="_Toc116387222"><!--[if !supportLists]--><span lang="EN-US" style="font-size:
            13.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:
            Arial">8.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;
            </span></span><!--[endif]--><span lang="EN-US" style="font-size:13.0pt;
            line-height:107%;font-family:&quot;Arial&quot;,sans-serif">REFERENCES</span></a><span lang="EN-US" style="font-size:13.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif"><o:p></o:p></span></h1>
            

            <p class="MsoNormal" style="margin-top:6.2pt;text-align:justify;line-height:145%;
            mso-pagination:none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
            mso-border-shadow:yes"><span lang="EN-US" style="font-size:10.5pt;line-height:
            145%;color:#414141">To maintain our portfolio credentials, and the integrity of
            any applicable copyrights, the Provider shall be entitled to place an
            unobtrusive credit with a hypertext link (i.e., “software by…”) in the footer
            of its Software Services pages if applicable. The Provider is entitled to
            reproduce samples of Client’s website and SEO statistics in our portfolio and
            in any of our software materials. The Provider may provide reciprocal links
            amongst client websites in the website footers, posts, and pages, and the
            Client agrees to participate in this collective software arrangement.&nbsp; Client may have sole discretion about
            Provider’s use. <o:p></o:p></span></p>

            

            
            <h1 style="margin-left:18.0pt;text-indent:-18.0pt;mso-list:l4 level1 lfo1"><a name="_Toc116387223"><!--[if !supportLists]--><span lang="EN-US" style="font-size:
            13.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:
            Arial">9.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;
            </span></span><!--[endif]--><span lang="EN-US" style="font-size:13.0pt;
            line-height:107%;font-family:&quot;Arial&quot;,sans-serif">WARRANTIES</span></a><span lang="EN-US" style="font-size:13.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif"><o:p></o:p></span></h1>

            

            <p class="MsoNormal" style="text-align:justify;line-height:151%;mso-pagination:
            none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
            yes"><span lang="EN-US" style="font-size:10.5pt;line-height:151%;color:#414141">THE
            PROVIDER'S SERVICES AND CONTENT FURNISHED UNDER THIS AGREEMENT IS PROVIDED ON
            AN "AS IS" BASIS, WITHOUT ANY WARRANTIES OR REPRESENTATIONS EXPRESS,
            IMPLIED, OR STATUTORY; INCLUDING, WITHOUT LIMITATION, WARRANTIES OF QUALITY,
            PERFORMANCE, NONINFRINGEMENT, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR
            PURPOSE. NOR ARE THERE ANY WARRANTIES CREATED BY A COURSE OF DEALING, COURSE OF
            PERFORMANCE, OR&nbsp; TRADE USAGE. PROVIDER
            DOES NOT WARRANT THAT THE SOFTWARE SERVICES WILL YIELD ANY SPECIFIC RESULTS.
            THE FOREGOING EXCLUSIONS AND DISCLAIMERS ARE AN ESSENTIAL PART OF THIS
            AGREEMENT AND FORMED THE BASIS FOR DETERMINING THE PRICE CHARGED FOR SOFTWARE
            SERVICES.&nbsp; <o:p></o:p></span></p>

            

            <p class="MsoNormal" style="text-align:justify;line-height:151%;mso-pagination:
            none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
            yes"><span lang="EN-US" style="font-size:10.5pt;line-height:151%;color:#414141">Client
            understands that developed software will likely contain, at first use, ‘bugs’,
            issues, inefficiencies, and other optimization problems.&nbsp; Client will allow the Provider a reasonable
            time to fix, remedy, and find solutions for any software problems. <o:p></o:p></span></p>


            <h1 style="margin-left:18.0pt;text-indent:-18.0pt;mso-list:l4 level1 lfo1"><a name="_Toc116387224"><!--[if !supportLists]--><span lang="EN-US" style="font-size:
            13.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:
            Arial">10.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><!--[endif]--><span lang="EN-US" style="font-size:13.0pt;
            line-height:107%;font-family:&quot;Arial&quot;,sans-serif">INTELLECTUAL PROPERTY
            INFRINGEMENT CLAIMS</span></a><span lang="EN-US" style="font-size:13.0pt;
            line-height:107%;font-family:&quot;Arial&quot;,sans-serif"><o:p></o:p></span></h1>

            

            <p class="MsoNormal" style="text-align:justify;line-height:151%;mso-pagination:
            none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
            yes"><span lang="EN-US" style="font-size:10.5pt;line-height:151%;color:#414141">Client
            represents that to the best of its knowledge the Client Content delivered to
            Provider under this Agreement will not infringe any valid and existing
            intellectual property right of any third party.&nbsp;
            Client shall indemnify and hold harmless Provider from any and all
            claims by third parties against Provider for Client’s willful or negligent
            infringement of any valid and existing intellectual property right of a third
            party, including without limitation any and all damages or attorney’s fees
            incurred resulting from such claims.<o:p></o:p></span></p>

            

            <h1 style="margin-left:18.0pt;text-indent:-18.0pt;mso-list:l4 level1 lfo1"><a name="_Toc116387225"><!--[if !supportLists]--><span lang="EN-US" style="font-size:
            13.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:
            Arial">11.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><!--[endif]--><span lang="EN-US" style="font-size:13.0pt;
            line-height:107%;font-family:&quot;Arial&quot;,sans-serif">LIMITATION OF PROVIDER’S
            LIABILITY TO CLIENT</span></a><span lang="EN-US" style="font-size:13.0pt;
            line-height:107%;font-family:&quot;Arial&quot;,sans-serif"><o:p></o:p></span></h1>

            

            <p class="MsoNormal" style="margin-top:6.95pt;text-align:justify;line-height:
            150%;mso-pagination:none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
            mso-border-shadow:yes"><span lang="EN-US" style="font-size:10.5pt;line-height:
            150%;color:#414141">In no event shall Provider be liable to Client for lost
            profits of Client, or special or consequential damages, even if Provider has
            been advised of the possibility of such damages.&nbsp; Provider's total liability under this
            Agreement for damages, costs, and expenses, regardless of cause, shall not
            exceed the total amount of fees paid to Provider by Client under this
            Agreement.&nbsp; Provider shall not be liable
            for any claim or demand made against Client by any third party.&nbsp; Client shall indemnify Provider against all
            claims, liabilities, and costs, including reasonable attorney fees, of
            defending any third-party claim or suit arising out of the use of the content
            provided under this Agreement, other than for infringement of intellectual
            property rights. Provider shall promptly notify Client in writing of any
            third-party claim or suit and Client shall have the right to fully control the
            defense and any settlement of such claim or suit.&nbsp; <o:p></o:p></span></p>

            

            <h1 style="margin-left:18.0pt;text-indent:-18.0pt;mso-list:l4 level1 lfo1"><a name="_Toc116387226"><!--[if !supportLists]--><span lang="EN-US" style="font-size:
            13.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:
            Arial">12.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><!--[endif]--><span lang="EN-US" style="font-size:13.0pt;
            line-height:107%;font-family:&quot;Arial&quot;,sans-serif">CLIENT REPRESENTATION AND
            WARRANTIES</span></a><span lang="EN-US" style="font-size:13.0pt;line-height:107%;
            font-family:&quot;Arial&quot;,sans-serif"><o:p></o:p></span></h1>
            

            <p class="MsoNormal" style="margin-top:6.2pt;margin-right:0cm;margin-bottom:8.0pt;
            margin-left:5.25pt;text-align:justify;line-height:normal;mso-pagination:none;
            border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes"><span lang="EN-US" style="font-size:10.5pt;color:#414141">Client represents and
            warrants to Provider as follows:&nbsp; <o:p></o:p></span></p>

            <p class="MsoNormal" style="margin-top:6.45pt;margin-right:18.25pt;margin-bottom:
            8.0pt;margin-left:28.9pt;text-align:justify;text-indent:.3pt;line-height:145%;
            mso-pagination:none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
            mso-border-shadow:yes"><span lang="EN-US" style="font-size:10.5pt;line-height:
            145%;color:#414141">Client has the authority to enter into and perform its
            obligations under this Agreement; and Client has or will obtain all necessary
            and appropriate rights and licenses to grant the license to Provider to use
            Client Content for the Software Services.&nbsp;
            <o:p></o:p></span></p>

            <p class="MsoNormal" style="margin-top:.9pt;margin-right:50.75pt;margin-bottom:
            8.0pt;margin-left:5.15pt;text-align:justify;text-indent:.1pt;line-height:145%;
            mso-pagination:none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
            mso-border-shadow:yes"><span lang="EN-US" style="font-size:10.5pt;line-height:
            145%;color:#414141">Client will indemnify Provider from any third-party claims
            resulting in losses, damages, liabilities, costs, charges, and expenses,
            including reasonable attorney fees, arising out of any breach of any of
            Client’s representations and warranties contained in this Agreement. For such
            indemnification to be effective, however, Provider must give Client prompt
            written notice of any such claim and provide Client such reasonable cooperation
            and assistance as Client may request in the defense of such suit.&nbsp; <o:p></o:p></span></p>

            

            <h1 style="margin-left:18.0pt;text-indent:-18.0pt;mso-list:l4 level1 lfo1"><a name="_Toc116387227"><!--[if !supportLists]--><span lang="EN-US" style="font-size:
            13.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:
            Arial">13.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><!--[endif]--><span lang="EN-US" style="font-size:13.0pt;
            line-height:107%;font-family:&quot;Arial&quot;,sans-serif">DISPUTES</span></a><span lang="EN-US" style="font-size:13.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif"><o:p></o:p></span></h1>
            

            <p class="MsoNormal" style="margin-top:7.7pt;margin-right:20.2pt;margin-bottom:
            8.0pt;margin-left:5.05pt;text-align:justify;text-indent:.5pt;line-height:144%;
            mso-pagination:none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
            mso-border-shadow:yes"><span lang="EN-US" style="font-size:10.5pt;line-height:
            144%;color:#414141">Except for collection actions for payment of charges and
            for the right of either party to apply to a court in the Cook County, Illinois
            jurisdiction for a temporary restraining order, a preliminary injunction, or
            other equitable&nbsp; relief to preserve the
            status quo or prevent irreparable harm, any controversy or claim between the
            parties&nbsp; arises from or in our connection
            with this Agreement whether based on contract, tort, common law, equity,&nbsp; statute, regulation, order or otherwise (as
            "Dispute"), the Parties agree to reasonably discuss and make good
            faith efforts to negotiate an amicable settlement of such Dispute without the
            necessity of any third party.&nbsp; Client and
            Provider agree that the governing law of this Agreement is the law of the State
            of Illinois, and that the venue of any dispute regarding this Agreement shall
            be Cook County, Illinois.&nbsp; Client must
            dispute any dispute within fifteen (15) days. Client agrees that its failure to
            dispute any invoice within fifteen (15) days constitutes acceptance of such
            dispute, and upon that condition, Client agrees not to object to such invoice
            and payment, forever waives all claims against Provider regarding such invoice.
            Client agrees to testify in court or arbitration if a dispute arises regarding
            Client’s knowledge or knowledge of Provider, if requested by Provider.&nbsp; <o:p></o:p></span></p>

            <p class="MsoNormal" style="margin-top:7.7pt;margin-right:20.2pt;margin-bottom:
            8.0pt;margin-left:5.05pt;text-align:justify;text-indent:.5pt;line-height:144%;
            mso-pagination:none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
            mso-border-shadow:yes"><span lang="EN-US" style="font-size:10.5pt;line-height:
            144%;color:#414141">If the Parties are unable to resolve any Dispute in the
            manner set forth above, such Dispute shall be submitted to arbitration. The
            Parties agree that, except as otherwise provided above, any Dispute shall be
            settled by arbitration in accordance with the Commercial Arbitration Rules of
            the American Arbitration Association ("AAA") in Chicago, Illinois,
            with judgment upon the award rendered by the arbitrator to be&nbsp; entered in any court of competent jurisdiction.
            The arbitration proceedings existence, subject, evidence, and ruling shall be
            deemed confidential information and not disclosed by either parties or their
            representatives, except as ordered by any court of competent jurisdiction. The
            arbitrator shall be required to prepare written findings of the fact, and may
            grant any relief or remedy which the arbitrator deems just and equitable.&nbsp; This agreement shall be governed and
            construed in accordance with the Laws of the State of Illinois, without reference
            to conflict of law principles.&nbsp; <o:p></o:p></span></p>

            <p class="MsoNormal" style="margin-top:7.7pt;margin-right:20.2pt;margin-bottom:
            8.0pt;margin-left:5.05pt;text-align:justify;text-indent:.5pt;line-height:144%;
            mso-pagination:none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
            mso-border-shadow:yes"><span lang="EN-US" style="font-size:10.5pt;line-height:
            144%;color:#414141">CALIFORNIA NOTICE: Client and Provider agree that any
            dispute regarding this Agreement shall not be subject to the jurisdiction of
            California, shall not be subject to the laws of California, and shall not take
            place in the State of California.&nbsp; If
            this Agreement is ever found to be governed by the State or California or venue
            is found to be properly held in the State of California, the Client and
            Provider agree to waive any right to Arbitration stated above, and resolve any
            dispute related to this Agreement in a Court of competent jurisdiction in
            California.&nbsp; <o:p></o:p></span></p>

            <p class="MsoNormal" style="margin-top:7.7pt;margin-right:20.2pt;margin-bottom:
            8.0pt;margin-left:5.05pt;text-align:justify;text-indent:.5pt;line-height:144%;
            mso-pagination:none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
            mso-border-shadow:yes"><span lang="EN-US" style="font-size:10.5pt;line-height:
            144%;color:#414141">If any litigation or arbitration is necessary to enforce
            this Agreement, if Provider is the prevailing party, Provider will be entitled
            to its reasonable attorneys' fees and costs from the Client, including such
            fees and costs incurred in connection with any appeals, in addition to such
            other relief as may be provided by law.&nbsp; <o:p></o:p></span></p>


            <h1 style="margin-left:18.0pt;text-indent:-18.0pt;mso-list:l4 level1 lfo1"><a name="_Toc116387228"><!--[if !supportLists]--><span lang="EN-US" style="font-size:
            13.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:
            Arial">14.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><!--[endif]--><span lang="EN-US" style="font-size:13.0pt;
            line-height:107%;font-family:&quot;Arial&quot;,sans-serif">GENERAL PROVISIONS</span></a><span lang="EN-US" style="font-size:13.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif">
            <o:p></o:p></span></h1>
            

            <p class="MsoNormal" style="margin-top:6.2pt;text-align:justify;line-height:144%;
            mso-pagination:none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
            mso-border-shadow:yes"><span lang="EN-US" style="font-size: 10.5pt; line-height: 144%;">(a) </span><span lang="EN-US" style="font-size:10.5pt;
            line-height:144%;color:#414141">Complete Agreement: This Agreement, together
            with all exhibits, appendices, or other attachments, which are incorporated by
            reference, is the sole and entire Agreement between the parties for software
            activities. This Agreement supersedes all prior understandings, agreements, and
            documentation relating to such subject matter.&nbsp;
            <o:p></o:p></span></p>

            <p class="MsoNormal" style="margin-top:19.75pt;text-align:justify;line-height:
            145%;mso-pagination:none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
            mso-border-shadow:yes"><span lang="EN-US" style="font-size: 10.5pt; line-height: 145%;">(b) </span><span lang="EN-US" style="font-size:10.5pt;
            line-height:145%;color:#414141">Modifications to Agreement: Modifications and
            amendments to this Agreement, including any exhibit or appendix hereto, shall
            be enforceable only if they are in writing and are signed by authorized
            representatives of both parties.&nbsp; <o:p></o:p></span></p>

            <p class="MsoNormal" style="margin-top:19.65pt;text-align:justify;line-height:
            142%;mso-pagination:none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
            mso-border-shadow:yes"><span lang="EN-US" style="font-size: 10.5pt; line-height: 142%;">(a) </span><span lang="EN-US" style="font-size:10.5pt;
            line-height:142%;color:#414141">Applicable Law: This Agreement will be governed
            by the laws of the State of Illinois and the State of Illinois.&nbsp; <o:p></o:p></span></p>

            <p class="MsoNormal" style="margin-top:20.0pt;text-align:justify;line-height:
            150%;mso-pagination:none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
            mso-border-shadow:yes"><span lang="EN-US" style="font-size: 10.5pt; line-height: 150%;">(b) </span><span lang="EN-US" style="font-size:10.5pt;
            line-height:150%;color:#414141">Notices: All notices and other communications
            given in connection with this Agreement shall be in writing and shall be deemed
            given as follows:&nbsp; <o:p></o:p></span></p>

            <p class="MsoNormal" style="margin-top:6.45pt;margin-right:27.35pt;margin-bottom:
            8.0pt;margin-left:28.7pt;text-align:justify;text-indent:.1pt;line-height:150%;
            mso-pagination:none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
            mso-border-shadow:yes"><span lang="EN-US" style="font-size:10.5pt;line-height:
            150%;color:#414141">When delivered personally to the recipient's address as
            appearing in the introductory paragraph to this Agreement;&nbsp; <o:p></o:p></span></p>

            <p class="MsoNormal" style="margin-top:2.0pt;margin-right:26.15pt;margin-bottom:
            8.0pt;margin-left:29.1pt;text-align:justify;text-indent:-.1pt;line-height:150%;
            mso-pagination:none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
            mso-border-shadow:yes"><span lang="EN-US" style="font-size:10.5pt;line-height:
            150%;color:#414141">Three days after being deposited in the United States
            mails, postage prepaid to the recipient's address as appearing in the
            introductory paragraph to this Agreement; or&nbsp;
            <o:p></o:p></span></p>

            <p class="MsoNormal" style="margin-top:7.25pt;margin-right:18.45pt;margin-bottom:
            8.0pt;margin-left:29.4pt;text-align:justify;text-indent:-.55pt;line-height:
            150%;mso-pagination:none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
            mso-border-shadow:yes"><span lang="EN-US" style="font-size:10.5pt;line-height:
            150%;color:#414141">When sent by fax or electronic mail. Notice is effective
            upon receipt provided that a duplicate copy of the notice is promptly given by
            first-class or certified mail, or the recipient delivers a written confirmation
            of receipt.&nbsp; <o:p></o:p></span></p>

            <p class="MsoNormal" style="margin-top:19.25pt;margin-right:41.45pt;margin-bottom:
            8.0pt;margin-left:14.6pt;text-align:justify;text-indent:-9.2pt;line-height:
            148%;mso-pagination:none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
            mso-border-shadow:yes"><span lang="EN-US" style="font-size: 10.5pt; line-height: 148%;">(e) </span><span lang="EN-US" style="font-size:10.5pt;
            line-height:148%;color:#414141">No Agency: Nothing contained herein will be
            construed as creating any agency, partnership, joint venture, or other form of
            joint enterprise between the parties.&nbsp; <o:p></o:p></span></p>

            <p class="MsoNormal" style="margin-top:19.35pt;margin-right:34.45pt;margin-bottom:
            8.0pt;margin-left:12.0pt;text-align:justify;text-indent:-6.6pt;line-height:
            142%;mso-pagination:none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
            mso-border-shadow:yes"><span lang="EN-US" style="font-size: 10.5pt; line-height: 142%;">(a) </span><span lang="EN-US" style="font-size:10.5pt;
            line-height:142%;color:#414141">Assignment: The rights and obligations under
            this Agreement are freely assignable by either party.&nbsp; Client shall retain the obligation to pay if
            the assignee fails to pay as required by this Agreement.&nbsp; <o:p></o:p></span></p>

            <p class="MsoNormal" style="margin-top:20.0pt;margin-right:33.7pt;margin-bottom:
            8.0pt;margin-left:14.65pt;text-align:justify;text-indent:-9.25pt;line-height:
            142%;mso-pagination:none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
            mso-border-shadow:yes"><span lang="EN-US" style="font-size: 10.5pt; line-height: 142%;">(b) </span><span lang="EN-US" style="font-size:10.5pt;
            line-height:142%;color:#414141">Successors and Assigns: This agreement binds
            and benefits the heirs, successors, and assigns of the parties.&nbsp; <o:p></o:p></span></p>

            <p class="MsoNormal" style="margin-top:20.0pt;margin-right:17.35pt;margin-bottom:
            8.0pt;margin-left:14.65pt;text-align:justify;text-indent:-9.25pt;line-height:
            148%;mso-pagination:none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
            mso-border-shadow:yes"><span lang="EN-US" style="font-size: 10.5pt; line-height: 148%;">(c) </span><span lang="EN-US" style="font-size:10.5pt;
            line-height:148%;color:#414141">Severability: If a court finds any provision of
            this Agreement invalid or unenforceable, the remainder of this Agreement shall
            be enforced to the maximum extent of the law.&nbsp;
            A Court or Arbitration forum of competent jurisdiction may enforce terms
            to the maximum extent of the law permitted, and where unenforceable, may modify
            such provisions to the maximum legal extent possible.<o:p></o:p></span></p>

            <!-- <p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

            <p class="MsoNormal"><span lang="EN-US" style="font-size:13.0pt;line-height:107%;
            font-family:&quot;Arial&quot;,sans-serif;mso-fareast-font-family:&quot;Times New Roman&quot;;
            mso-fareast-theme-font:major-fareast;color:#2F5496;mso-themecolor:accent1;
            mso-themeshade:191">&nbsp;</span></p> -->

        </div>
    </div>

</div>

<!-- <div class="modal-footer d-flex justify-content-start">

  <div class="row align-items-center">
    <div class="col-md-6">
      <mat-checkbox
      class="example-margin"
      [(ngModel)]="checkedTermsConditions"
      name="checkedTerms"
      #checkedTerms="ngModel">
       I accept the  Terms & Conditions
       </mat-checkbox>

    </div>
    <div class="col-md-1"></div>
    <div class="col-md-2">
      <button style="height: 42px !important;" mdbBtn type="button" color="primary" (click)="modalRef.hide()" rounded="true" outline="true" mdbWavesEffect>Close</button>
    </div>
    <div class="col-md-2 ml-3">
      <button style="height: 42px !important;" mdbBtn [disabled]="!checkedTermsConditions" type="button" color="info" (click)="onProceed(checkedTerms.value)" rounded="true"  mdbWavesEffect>Proceed</button>
    </div>
  </div>
</div> -->
