<div class="modal-header head">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" id="btnClose">
        <span aria-hidden="true">x</span>
    </button>
    <h5 class="modal-title w-100" id="myModalLabel">Add Client</h5>
</div>
<div class="modal-body scrollBar " style="overflow:auto">
    <form #client="ngForm" autocomplete="off" (ngSubmit)="onSubmit(client)" >
        <fieldset [disabled]="isView">
            <div class="row">
                <div class="col-sm-4" [hidden]="true">
                    <div class="md-form">
                        <input name="ID" #ID="ngModel" [(ngModel)]="formData.ID" type="text" id="clientID"
                            class="form-control" mdbInput>
                        <label for="clientID" [class]="theme.label">Client ID</label>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                    <mat-form-field class="example-full-width">
                        <mat-label [class]="theme.label">Client Name</mat-label>
                        <input matInput class="MatInputBoxShadow" required name="ClientName" #ClientName="ngModel"
                            [(ngModel)]="formData.ClientName" type="text" id="txtClientName"
                            pattern="[a-zA-Z0-9-$&_(),. ]*">
                        <button type="button" mat-button *ngIf="formData.ClientName" matSuffix mat-icon-button
                            aria-label="Clear" (click)="formData.ClientName=''">
                            <mat-icon>close</mat-icon>
                        </button>
                        <mat-error *ngIf="ClientName.invalid && ClientName.dirty && !ClientName.errors?.pattern">This
                            field is required</mat-error>
                        <mat-error *ngIf="ClientName.invalid && ClientName.errors?.pattern">Cannot contain any of the
                            following characters: @ * # % " : <> ? / \ | </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                    <mat-form-field class="example-full-width">
                        <mat-label [class]="theme.label">Contact #</mat-label>
                        <input matInput placeholder="(___) ___-____" class="MatInputBoxShadow" name="Phone"
                            #Phone="ngModel" [(ngModel)]="formData.Phone" type="text" id="numPhone">
                        <button type="button" mat-button *ngIf="formData.Phone" matSuffix mat-icon-button
                            aria-label="Clear" (click)="formData.Phone=''">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                    <mat-form-field class="example-full-width">
                        <mat-label [class]="theme.label">Street Address</mat-label>
                        <input matInput class="MatInputBoxShadow" name="StreetAddress" #StreetAddress="ngModel"
                        [(ngModel)]="formData.StreetAddress" type="text" id="txtStreetAddress">
                        <button type="button" mat-button *ngIf="formData.StreetAddress" matSuffix mat-icon-button aria-label="Clear" (click)="formData.StreetAddress=''">
                            <mat-icon>close</mat-icon>
                          </button>
                      </mat-form-field>
                </div>
               
                <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                    <mat-form-field class="example-full-width">
                        <mat-label [class]="theme.label">City</mat-label>
                        <input matInput class="MatInputBoxShadow" name="City" #City="ngModel" [(ngModel)]="formData.City" type="text"
                        id="txtCity">
                        <button type="button" mat-button *ngIf="formData.City" matSuffix mat-icon-button aria-label="Clear" (click)="formData.City=''">
                            <mat-icon>close</mat-icon>
                          </button>
                        <mat-error *ngIf="City.invalid && City.dirty">This field is required</mat-error>

                      </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                    <mat-form-field class="example-full-width">
                        <mat-label [class]="theme.label">Email Address</mat-label>
                        <input matInput placeholder="jsmith@example.com" class="MatInputBoxShadow" name="Email"
                            #Email="ngModel" [(ngModel)]="formData.Email" type="text" id="emlEmailAddress">
                        <button type="button" mat-button *ngIf="formData.Email" matSuffix mat-icon-button
                            aria-label="Clear" (click)="formData.Email=''">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            

            <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                <mat-form-field class="example-full-width">
                    <mat-label [class]="theme.label">Contact Person Name</mat-label>
                    <input matInput class="MatInputBoxShadow"
                    required name="ContactPersonName" #ContactPersonName="ngModel"
                    [(ngModel)]="formDataContact.ContactPersonName" type="text" id="txtContactPersonName"> 
                    <button type="button" mat-button *ngIf="formDataContact.ContactPersonName" matSuffix mat-icon-button aria-label="Clear" (click)="formDataContact.ContactPersonName=''">
                        <mat-icon>close</mat-icon>
                      </button>                 
                    <mat-error *ngIf="ContactPersonName.invalid && ContactPersonName.dirty">This field is required</mat-error> 
                  </mat-form-field>  

            </div>

            <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                <mat-form-field class="example-full-width">
                    <mat-label [class]="theme.label">Contact Person Email Address</mat-label>
                    <input matInput placeholder="jsmith@example.com" class="MatInputBoxShadow"
                    name="ContactEmail" #ContactEmail="ngModel" [(ngModel)]="formDataContact.Email" type="text" id="emlContactEmail" > 
                    <button type="button" mat-button *ngIf="formDataContact.Email" matSuffix mat-icon-button aria-label="Clear" (click)="formDataContact.Email=''">
                        <mat-icon>close</mat-icon>
                      </button>                                       
                </mat-form-field> 

            </div>

        </div>

        </fieldset>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <!-- <button mat-raised-button [disabled]="client.invalid" type="button" (click)="onSubmit(client)" color="primary">
                Submit
              </button> -->
              <button type="button" (click)="onSubmit(client)" [disabled]="client.invalid" mdbBtn [class]="theme.button" class="waves-light" mdbWavesEffect id="btnSave">
                Submit
            </button>
            </div>
          </div>
    </form>
</div>