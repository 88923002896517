import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { LoginUser } from 'src/app/Base/User/login-user';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss']
})
export class SessionTimeoutComponent implements OnInit {

  mail:string = "";

  constructor(
    public router: Router,
    public tokenService:TokenService,
    private authService: MsalService,  
    private authenticationService :AuthenticationService
  ) {
    
   }

  ngOnInit(): void {
    
  }

  IsshowProgress:boolean = false;
  logout() {
    this.authenticationService.logout();    
    return;
    
  }

 
}
