<div 
  class="modal-header darken-3 white-text  gradient-card-header narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center"  [class]="theme.heading">
  <h4 class="h4-responsive">
   {{formTitle}}
  </h4>
  <!-- <a (click)="modalRef.hide()" mdbBtn floating="true" mdbTooltip="Close" aria-label="Close" placement="left"   size="sm"
     gradient="aqua"  mdbWavesEffect>
    <mdb-icon fas icon="times"></mdb-icon>
  </a>
   -->
  <div class="btn-group" role="group" aria-label="Basic example">
    <a (click)="modalRef.hide()" id="btnClose">
    <button mdbBtn type="button"  [class]="theme.button" size="sm" class="waves-light" mdbWavesEffect>
      <mdb-icon fas icon="times" ></mdb-icon>
    </button>
  </a>

  </div>
</div>



<div class="modal-body mb-0 text-left" id="modalBodyArea">

  
<div class="row">
    <div class="col-md-12">
      <mdb-accordion [multiple]="true">
        <mdb-accordion-item [collapsed]="true">
          <mdb-accordion-item-head>{{eventTableData.EventName}}</mdb-accordion-item-head>
          <mdb-accordion-item-body>
            <table class="table table-bordered InfoTable">
              <tbody>
                <tr>
                  <td><label  [class]="theme.label">Event ID</label></td>
                  <td data-label="Event ID">{{eventTableData.EntryNo}}</td>

                  <td><label  [class]="theme.label">Event Name</label></td>
                  <td data-label="Event Name">{{eventTableData.EventName}}</td>
                </tr>
                <tr>
                  <td><label  [class]="theme.label">Venue</label></td>
                  <td data-label="Venue">
                    <mat-list>
                      <mat-list-item>
                        {{eventTableData.Venue.VenueName}}
                      </mat-list-item>
                    </mat-list>
                  </td>

                  <td><label  [class]="theme.label">Genre</label></td>
                  <td data-label="Genre">
                    <mat-list>
                      <mat-list-item>
                          {{eventTableData.Genre == null ? '': eventTableData.Genre.GenreName}}
                      </mat-list-item>
                    </mat-list>
                  </td>
                </tr>
                <tr>
                  <td><label  [class]="theme.label">Client</label></td>
                  <td data-label="Client">
                    <mat-list>
                      <mat-list-item>
                        {{eventTableData.Client.ClientName}}
                      </mat-list-item>
                    </mat-list>
                  </td>

                  <td><label  [class]="theme.label">Contact Person</label></td>
                  <td data-label="Contact Person">
                    <mat-list>
                      <mat-list-item>
                          {{eventTableData.Client_ContactPerson1 == null ? '' : eventTableData.Client_ContactPerson1.ContactPersonName}}
                      </mat-list-item>
                    </mat-list>
                  </td>
                </tr>
                <tr>
                  <td><label  [class]="theme.label">Category</label></td>
                  <td data-label="Category">
                    <mat-list>
                      <mat-list-item>
                        {{eventTableData.EventCategory.CategoryName}}
                      </mat-list-item>
                    </mat-list>
                  </td>

                  <td><label  [class]="theme.label">Event Type</label></td>
                  <td data-label="Event Type">
                    <mat-list>
                      <mat-list-item>
                        {{eventTableData.EventTicketType.TicketTypeName}}
                      </mat-list-item>
                    </mat-list>
                  </td>
                </tr>

                <tr>
                  <td><label  [class]="theme.label">Start Date</label></td>
                  <td data-label="Start Date">
                    {{common.BLFormatDate(eventTableData.EventStartDate) | date:'MM/d/yyyy'}}
                  </td>

                  <td><label  [class]="theme.label">End Date</label></td>
                  <td data-label="End Date">
                    {{common.BLFormatDate(eventTableData.EventEndDate) | date:'MM/d/yyyy'}}
                  </td>
                </tr>

                <tr>
                  <td><label  [class]="theme.label">Total Days</label></td>
                  <td data-label="Total Days">
                    {{eventTableData.NoOfDays }}
                  </td>

                  <td><label  [class]="theme.label">Total Performances</label></td>
                  <td data-label="Total Performances">
                    {{eventTableData.NoOfPerformances}}
                  </td>
                </tr>
              </tbody>
            </table>
          </mdb-accordion-item-body>
        </mdb-accordion-item>
      </mdb-accordion>
    </div>
  </div>

  <div class="row" >
    <div class="col-sm-12">
      <form #Notes="ngForm" autocomplete="off" (ngSubmit)="onSubmit(Notes)"> 
        <div class="row">
          <div class="col-sm-4" [hidden]="true">
            <div class="md-form">
              <input name="ID" #ID="ngModel" [(ngModel)]="formData.ID" type="text" id="materialLoginFormbankID"
                class="form-control" mdbInput>
              <label for="materialLoginFormbankID"  [class]="theme.label">ID</label>
            </div>
          </div>
          
          <div  *ngIf="permissionService.CheckIfUserhasAccess_Action('Event Notes','Add')" class="col-sm-12">
            <div  class="md-form">
              <textarea    required type="text" id="rchtxtNotes" class="md-textarea md-textarea-auto form-control" mdbInput name="Notes"
                #Notes="ngModel" [(ngModel)]="formData.Notes">
              </textarea>
              <label for="NotesText" [class]="theme.label">Notes</label>
              <div class="validation-error" *ngIf="Notes.invalid && Notes.touched"> This field is required
              </div>
            </div>
          </div>
        </div>
        <div  class="text-right">
          <button  *ngIf="permissionService.CheckIfUserhasAccess_Action('Event Notes','Add')" type="button" (click)="onSubmit(Notes)" [disabled]="Notes.invalid" [hidden]="isView" mdbBtn [class]="theme.button" class="waves-light" id="btnSave"
            mdbWavesEffect>
            <mdb-icon fas icon="save" class="mr-1"></mdb-icon>{{SavebuttonText}}
          </button>
        </div>
      </form>
    </div>
  </div>

  <br>
  <div class="row">
    <div class="col-md-12">
    <div >
      <table mdbTable class="z-depth-1 InputTable table">
        <thead>
          <tr>
            <th [class]="theme.label">Notes</th>
            <!-- <th>Created Date</th>
            <th>Created By</th> -->
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let es of eventNotesList">
              <td >
                <div class="NotesAuditInfo">
                   Created on {{es.CreatedDate | date:'MM/d/yyyy hh:mm a'}} by {{es.RBAC_Users1 == null ? '' : es.RBAC_Users1.FirstName + ' ' + es.RBAC_Users1.LastName}}
                </div>
                <div style="white-space: pre-wrap;" [innerHTML]="es.Notes">

                </div>
              </td>
            
            <td >

              <div class="btn-group float-right">
                  <button mat-button class="flat-Icon-Color" id="btnEdit" matTooltip="Edit" *ngIf="permissionService.CheckIfUserhasAccess_Action('Event Notes','Edit')" [class]="theme.icon"
                  (click)="onEdit(es)">
                  <mat-icon >edit</mat-icon>                    
                  </button>
                  <button mat-button class="flat-Icon-Color" id="btnDelete" matTooltip="Delete" *ngIf="permissionService.CheckIfUserhasAccess_Action('Event Notes','Delete')" [class]="theme.icon"
                   (click)="DeleteNote(es.ID)">
                  <mat-icon>delete</mat-icon>                   
                  </button>                        
              </div>
                
            </td>
          </tr> 
        </tbody>
      </table>
    </div>
  </div>
  </div>



</div>