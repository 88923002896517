import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Autocomplete } from 'src/app/Base/Autocomplete';
import { CommonService } from 'src/app/Base/Common.service';
import { Table } from 'src/app/Base/Table';
import { GeneralService } from 'src/app/services/general.service';
import { GenericService } from 'src/app/services/generic.service';
import { themeService } from 'src/app/services/theme.service';
import { ContractTypes } from 'src/app/setupForms/contract-type/contract-type.model';
import { VenueService } from 'src/app/setupForms/venue/venue.service';
import { ContractTypePopupComponent } from 'src/app/setupForms/contract-type/contract-type-popup/contract-type-popup.component';
import { EventDetails } from '../../event-details.model';
import { Venue } from 'src/app/models/venue.model';
import { ToastType } from 'src/app/Enum/ToastType';
import { PermissionService } from 'src/app/services/permission.service';
import { ContractTypeService } from 'src/app/services/contract-type.service';

@Component({
  selector: 'app-event-contract-fields',
  templateUrl: './event-contract-fields.component.html',
  styleUrls: ['./event-contract-fields.component.scss']
})
export class EventContractFieldsComponent extends Table implements OnInit ,OnChanges{

  public searchInput = false;
  data:ContractTypes[]=[];
  public modalRef: MDBModalRef;
  @Input() VenueID:number=0;
  @Input() eventTableData:EventDetails = new EventDetails();
  @Output() isContractField:EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() contractFields:EventEmitter<any[]> = new EventEmitter<any[]>(); 
  AutoCompVenue: Autocomplete<Venue> = new Autocomplete<Venue>("VenueName", "ID");
  searchUserLead:any=null;
  displayedColumns: string[] = ['SerialNo', 'Name', 'FieldTypeID', 'VenueName', 'GLCode', 'DefaultValue', 'IsByDefault', 'ModifiedDate'];

  constructor(public service: GenericService,
    public router: Router,
    public genService: GeneralService,
    public common:CommonService,
    public venueService:VenueService,
    public modalService: MDBModalService,
    public theme : themeService,
    public permissionService:PermissionService,
    private contractTypeService:ContractTypeService,
  ) {
    super(router);    
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.VenueID > 0){
      this.getContractTypes(this.VenueID);
    }
  }

  async ngOnInit() {
    await this.getVenues();
    if(this.VenueID > 0){
      this.getContractTypes(this.VenueID);
    }
  }

  async getContractTypes(venueID:number){
    this.common.ShowSpinner();
    await this.contractTypeService.getContractTypeListByVenue(venueID).then(
      (res:any) => {        
        this.data = res['value'] as any[];
        this.listData = new MatTableDataSource(res as ContractTypes[]);
        this.listData.sort = this.sort;
        this.listData.paginator = this.paginator;
        
        this.contractFields.emit(this.data);
        this.common.HideSpinner();
        if(res['value'].length > 0){
          this.isContractField.emit(true);
        }
        else{
          this.isContractField.emit(false);
        }
      }
      , error => {
        console.log(error);
        this.common.HideSpinner();
       
      }
    )
  }

  addContractField(){
    this.modalRef = this.modalService.show(ContractTypePopupComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class:  'modal-side modal-full-height modal-right ',
      containerClass: 'right',
      animated: true,
      styles: "overflow-y: none",
      data:{
        venueId:this.VenueID
      }

    });

    this.modalRef.content.action.subscribe(result=>{
      if(result == "Submit"){
        this.getContractTypes(this.VenueID).then(res=>{

        });
      }
    })
  }

  onVenueChange(venueID:number){
    if(venueID > 0){
      this.getContractTypes(venueID);
    }
  }


  async getVenues() {
    await this.venueService.getDrpVenues().then(res => {
      this.AutoCompVenue.data = null;
      this.AutoCompVenue.data = res['value'];
      this.AutoCompVenue.resultObserve();
      // Safwan 09/01/2023 - Start

      // Safwan 09/01/2023 - End

    }, error => {
      console.log(error);
      this.common.ShowToast(error.message, ToastType.Error);
    })
  }
}

