import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { BLBase } from './Base/BLBase/BLBase.component';
import { ConfirmDialogeComponent } from './confirm-dialoge/confirm-dialoge.component';
import { DragDropFileComponent } from './modules/shared/drag-drop-file/drag-drop-file.component';
import { EventNotesComponent } from './modules/event/event-notes/event-notes/event-notes.component';
import { EventSubEventComponent } from './modules/event/event-sub-event/event-sub-event.component';
import { EventModalComponent } from './modules/event/event-modal/event-modal.component';
import { EventSubEventNotesComponent } from './modules/event/event-sub-event-notes/event-sub-event-notes/event-sub-event-notes.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CalendarNotesComponent } from './calendar-notes/calendar-notes.component';
import { PageNotFoundComponent } from './Base/AuthGuard/page-not-found/page-not-found.component'
import { NoPageAccessComponent } from './Base/AuthGuard/no-page-access/no-page-access.component';
import { CanlendarAvailComponent } from './calendar/canlendar-avail/canlendar-avail.component';
import { CalendarRequestComponent } from './calendar/calendar-request/calendar-request.component';
import { LandingPageComponent } from './modules/main/landing-page/landing-page.component';
import { LoginComponent } from './modules/main/login/login.component';
import { SignupComponent } from './modules/main/signup/signup.component';
import { AuthGuardLoginGuard } from './Base/AuthGuard/auth-guard-login.guard';
import { SecurityComponent } from './modules/main/security/security.component';
import { Adb2cPostProcessor } from './helpers/adb2c-post-processor';
import { ProfileComponent } from './home/profile/profile.component';
import { WebapiComponent } from './webapi/webapi.component';
import { HomeComponent } from './home/home/home.component';
import { MsalGuard } from '@azure/msal-angular';
import { CustomerComponent } from './modules/main/customer/customer.component';
import { CustomerListComponent } from './modules/main/customer-list/customer-list.component';
import { RegisterCustomerComponent } from './modules/main/register-customer/register-customer.component';
import { RegisterAccountComponent } from './modules/main/register-account/register-account.component';
import { OnBoardingVenueComponent } from './modules/on-boarding-venue/on-boarding-venue.component';
import { AlreadyLoggedInComponent } from './Base/AuthGuard/already-logged-in/already-logged-in.component';
import { SignupGuard } from './Base/AuthGuard/signup.guard';
import { MembershipGuard } from './Base/AuthGuard/membership.guard';
import { MarketPlaceComponent } from './modules/main/market-place/market-place.component';
import { SessionTimeoutComponent } from './modules/main/session-timeout/session-timeout.component';
import { SelectTenantComponent } from './modules/main/select-tenant/select-tenant.component';
import { SelectSignupComponent } from './modules/main/select-signup/select-signup.component';
import { RequestSubscriptionComponent } from './modules/main/request-subscription/request-subscription.component';
import { RequestAccessComponent } from './modules/main/request-access/request-access.component';
import { ApproveAccessComponent } from './modules/main/approve-access/approve-access.component';
import { AuthGuardGuard } from './Base/AuthGuard/auth-guard.guard';
import { MaintenanceGuard } from './Base/AuthGuard/maintenance.guard';
import { OnBoardingOrganizationComponent } from './modules/on-boarding-venue/on-boarding-organization/on-boarding-organization.component';
import { MaintainenceComponent } from './modules/main/maintainence/maintainence.component';
import { OnBoardingUserComponent } from './modules/on-boarding-venue/on-boarding-user/on-boarding-user.component';
import { OnBoardingClientComponent } from './modules/on-boarding-venue/on-boarding-client/on-boarding-client.component';
import { SsoComponent } from './modules/main/sso/sso.component';
import { SignupRedirectComponent } from './modules/main/signup-redirect/signup-redirect.component';
import { CalendarDashboardComponent } from './calendar/calendar-dashboard/calendar-dashboard.component';
import { SsoRedirectComponent } from './modules/main/sso-redirect/sso-redirect.component';
import { TeamsSigninComponent } from './modules/main/teams-signin/teams-signin.component';
import { LogoutComponent } from './modules/main/logout/logout.component';
import { SignInUserComponent } from './modules/main/sign-in-user/sign-in-user.component';
import { NewSigninComponent } from './modules/main/new-signin/new-signin.component';
import { TrialExpiredComponent } from './modules/main/trial-expired/trial-expired.component';
import { LandingPageNewComponent } from './modules/main/landing-page-new/landing-page-new.component';
import { NewOnboardDesComponent } from './modules/main/new-onboard-des/new-onboard-des.component';
//import { AuthGuardGuard } from './Base/AuthGuard/auth-guard.guard';

const routes: Routes = [
    // Default Route
    { path: '', redirectTo: '/calendar', pathMatch: 'full' },
    { path: "AccessDenied", component: NoPageAccessComponent, canActivate: [ SignupGuard]},
   
    {
        path: '', component: LandingPageNewComponent,
        children:[
        { path: "calendar", component: CalendarComponent,  canActivate: localStorage.getItem("teams") ? [ AuthGuardLoginGuard] : [  AuthGuardLoginGuard] },
        { path: "dashboard", component: CalendarDashboardComponent,  canActivate: localStorage.getItem("teams") ? [ AuthGuardLoginGuard] : [  AuthGuardLoginGuard]},
        {
          path: 'customer/form', component: CustomerComponent,
        },
        {
          path: 'customer/list', component: CustomerListComponent,
        },
            // Lazy Loading applied
        { path: 'client',   canActivate:localStorage.getItem("teams") ? [ AuthGuardLoginGuard, AuthGuardGuard] : [  AuthGuardLoginGuard, AuthGuardGuard], loadChildren: () => import('./modules/client/client.module').then(m => m.ClientModule) },
        { path: 'setup',   canActivate:localStorage.getItem("teams") ? [ AuthGuardLoginGuard, AuthGuardGuard] : [  AuthGuardLoginGuard, AuthGuardGuard], loadChildren: () => import('./setupForms/setup.module').then(m => m.SetupModule) },
        { path: 'rbac',   canActivate:localStorage.getItem("teams") ? [ AuthGuardLoginGuard, AuthGuardGuard] : [  AuthGuardLoginGuard, AuthGuardGuard], loadChildren: () => import('./rbac/rbac-module').then(m => m.RBACModule) },
        { path: 'report',   canActivate:localStorage.getItem("teams") ? [ AuthGuardLoginGuard, AuthGuardGuard] : [  AuthGuardLoginGuard, AuthGuardGuard], loadChildren: () => import('./modules/event/reports/report.module').then(m => m.ReportModule) },
        { path: 'settlement',   canActivate:localStorage.getItem("teams") ? [ AuthGuardLoginGuard, AuthGuardGuard] : [  AuthGuardLoginGuard, AuthGuardGuard], loadChildren: () => import('./modules/settlement/settlement.module').then(m => m.SettlementModule) },
        { path: 'event',   canActivate:localStorage.getItem("teams") ? [ AuthGuardLoginGuard, AuthGuardGuard] : [  AuthGuardLoginGuard, AuthGuardGuard], loadChildren: () => import('./modules/event/event.module').then(m => m.EventModule) },
        { path: 'inventory',   canActivate:localStorage.getItem("teams") ? [ AuthGuardLoginGuard, AuthGuardGuard] : [  AuthGuardLoginGuard, AuthGuardGuard], loadChildren: () => import('./modules/Inventory/Inventory.module').then(m => m.InventoryModule) },

        ]
    },
    // { path: "forms", component: OnBoardingUserComponent},
    // { path: "forms/client", component: OnBoardingClientComponent},
    { path: "signin", component: SignInUserComponent },
    { path: "register", component: SecurityComponent },
    { path: "registeraccount", component: RegisterAccountComponent},
    { path: "signupredirect", component: SignupRedirectComponent},
    { path: "login", component: SecurityComponent},
    { path: "resetpassword", component: SignInUserComponent },
    { path: "marketplace/resolve", component: MarketPlaceComponent},
    { path: "sso", component: SsoComponent},
    { path: "sso-redirect", component: SsoRedirectComponent},
    { path: "newsignin", component: NewSigninComponent},

    { path: "new-onboard", component: NewOnboardDesComponent},


    { path: "expired", component: TrialExpiredComponent  },


    //stripe
    { path: 'membership',  canActivate: [MembershipGuard], loadChildren: () => import('./modules/memberships/memberships.module').then(m => m.MembershipsModule) },

     /* Changes start here. */
    {
      path: 'profile', component: ProfileComponent, canActivate: [AuthGuardLoginGuard]
    },

  {
    path: 'webapi',
    component: WebapiComponent, canActivate: [AuthGuardLoginGuard]
  },
  // {
  //   // The home component allows anonymous access
  //   path: '', component: HomeComponent
  // },
  /* Changes end here. */

   //{ path: "signup", component: RegisterCustomerComponent, canActivate: [SignupGuard]  },
  //  { path: "signup", component: SignupComponent, canActivate: [ SignupGuard]  },
   { path: "signup", component: NewSigninComponent, canActivate: [ SignupGuard]  },
   { path: "select-signup", component: SelectSignupComponent  },
   { path: "expired", component: TrialExpiredComponent  },
   { path: "select-tenant", component: SelectTenantComponent  },
  //  { path: "onboarding", component: OnBoardingUserComponent,  canActivate: [MembershipGuard]},
   { path: "onboarding", component: NewOnboardDesComponent,  canActivate: [MembershipGuard]},
   { path: "onboarding/organization", component: OnBoardingOrganizationComponent},
   { path: "already-logged-in", component: AlreadyLoggedInComponent},
   { path: "unauthorized", component: SessionTimeoutComponent},
   { path: "requiredsubscription", component: RequestSubscriptionComponent},
   { path: "requestaccess", component: RequestAccessComponent,},
   { path: "approveaccess", component: ApproveAccessComponent},
   { path: "maintenance", component: MaintainenceComponent},
   { path: "teams-signin", component: TeamsSigninComponent},
   { path: "logout", component: LogoutComponent},

   { path: '**', component:PageNotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        useHash: false,
        onSameUrlNavigation: 'reload',
        // initialNavigation:'enabled',
        preloadingStrategy:PreloadAllModules
    })],
    exports: [RouterModule]
})

export class AppRoutingModule { }

export const RoutingComponent = [
    BLBase,
    CalendarComponent,
    DragDropFileComponent,
    EventNotesComponent,
    EventSubEventComponent,
    EventModalComponent,
    EventSubEventNotesComponent,
    CalendarNotesComponent,
    CanlendarAvailComponent,
    CalendarRequestComponent,
    // AvailReportComponent,
    CustomerComponent,
    CustomerListComponent,
    OnBoardingVenueComponent
]

export const EntryComponent = [
    ConfirmDialogeComponent,
    EventNotesComponent,
    EventSubEventComponent,
    EventModalComponent,
    EventSubEventNotesComponent,
    CalendarNotesComponent
]
