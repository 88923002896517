import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as customConfig from 'src/app/customConfig.json';

@Component({
  selector: 'app-maintainence',
  templateUrl: './maintainence.component.html',
  styleUrls: ['./maintainence.component.scss']
})
export class MaintainenceComponent implements OnInit {
  public Maintenance:boolean = customConfig.Maintenance;
  constructor(public router: Router) { }

  ngOnInit(): void {
    // if (this.Maintenance == false) {
    //   this.router.navigate(["/calendar"]);
    // }
  }

}
