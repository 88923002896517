//import { RBACGroups } from './rbac-groups'

export class RBAC_Users_Groups {
    ID :number=  0;
    UserId : number = null;
    GroupId  : number = null;
    CreatedBy  : number = null;
    CreatedDate :Date = null;
    ModifiedBy  : number = null;
    ModifiedDate :Date = null;
    //RBAC_Groups : RBACGroups  = new  RBACGroups();
    
}
