import { Injectable } from '@angular/core';
import { CommonService } from '../Base/Common.service';
import * as $ from 'jquery';
import { CustomersService } from './customers.service';
import { Customer } from '../models/customer.model';
import { ToastType } from '../Enum/ToastType';
import { MediaMatcher } from '@angular/cdk/layout';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class themeService {
  // iconColor:string = '#095e8f';
  // backgroundColor:string = '#095e8f';
  // btnColor:string = '#fff';
  // background:string = '#095e8f';
  color:string='#095e8f';
  textColor:string = '#fff';
  themeColor:string='default';
  rgbColor:string ='rgb(9 94 143 / 47%)';
  calendarColor:string = "rgb(0 103 207 / 9%)";
  customer:Customer = null;
  isSetTheme:boolean = false;
  mobileQuery: MediaQueryList;
  hideFreeSolgan = false;
  skipWelcomePage = false;

  heading:string = null;
  topNav:string = null;
  sidenavIcon:string=null;
  button:string=null;
  outlineButton:string=null;
  filterButton:string=null;
  filterCalendarButton:string=null;
  label:string=null;
  icon:string=null;
  backGround:string=null;

  constructor(public customersService:CustomersService,media: MediaMatcher){
    this.mobileQuery = media.matchMedia('(max-width: 1023px)');
    this.themeColor =  localStorage.getItem('themecolor');
    //this.getCustomerTheme();
    
      
  }

  

  async getCustomerTheme(){

    if (localStorage.getItem('themecolor')){
     this.themeColor =  localStorage.getItem('themecolor');
     this.setThemeByVariable();
     this.isSetTheme = true;
      return;
    }

      this.customersService.getMyCustomerByID().toPromise().then(res=>{
        
        /*var data = res['value'] as Customer[];
        if(data.length > 0){
          if(data[0].Theme !=null){
            localStorage.setItem('themecolor',data[0].Theme);
          }
          else{
            localStorage.setItem('themecolor','default');
          }
          this.themeColor = data[0].Theme;
          this.customer = data[0];
          this.setThemeByVariable();
          this.isSetTheme = true;
        }
        */

        let cus = res as any;
        cus.startDate = moment(cus.startDate).utcOffset('+05:00').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        cus.endDate = moment(cus.endDate).utcOffset('+05:00').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        cus.CreatedDate = moment(cus.CreatedDate).utcOffset('+05:00').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        cus.ModifiedDate = moment(cus.ModifiedDate).utcOffset('+05:00').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        delete cus.Customers_Stripe;
        delete cus.Customers_MarketPlace;
        
        if(cus){
          if(cus.Theme !=null){
            localStorage.setItem('themecolor',cus.Theme);
          }
          else{
            localStorage.setItem('themecolor','default');
          }
          this.themeColor = cus.Theme;
          this.customer = cus;
          this.setThemeByVariable();
          this.isSetTheme = true;
        }
      })
  }

 async updateCustomerTheme(ID:number,jsonBody:any){
  localStorage.setItem('themecolor',jsonBody.Theme);
  await  this.customersService.update(ID, jsonBody).toPromise().then(res => {
      // this.common.ShowToast("Successfully Updated!", ToastType.Success);
      this.getCustomerTheme();
    })
  }

  setThemeByVariable(){
    // this.themeColor =  localStorage.getItem('themecolor');
    this.heading = "varc-card-" + this.themeColor;
    this.topNav = "varc-top-nav-" + this.themeColor;
    this.sidenavIcon = "varc-side-nav-icon-" + this.themeColor;
    this.button = "varc-button-" + this.themeColor;
    this.outlineButton = "varc-outline-button-" + this.themeColor;
    this.filterCalendarButton = "varc-filter-calendar-" + this.themeColor;
    this.label = "varc-label-" + this.themeColor;
    this.icon = "varc-icon-" + this.themeColor; 
    this.backGround = "varc-bg-" + this.themeColor;

  }

  removeThemeToken(){
    localStorage.removeItem('themecolor');
  }

  //#region J-Query Theme Work
    
  setSideNavShadow(){
    $('.side-nav').attr('style', 'box-shadow: '+this.rgbColor+' 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px !important;width:15rem !important;transform:translateX(0%) !important;');
  }
  // setTheme(){
  //   // this.common.ShowSpinner();
  //   if(this.themeColor == 'default' || this.themeColor == null ||this.themeColor == '' ||this.themeColor == undefined  ){
  //     this.themeColor = 'default';
  //     this.color = '#40a9e6';
  //     this.rgbColor = 'rgb(149 189 212 / 50%)';
  //     this.calendarColor = "rgb(230 239 244)";
  //     }
  //     else if(this.themeColor == 'blue'){
       
  //       this.color = '#095e8f';
  //       this.rgbColor='rgb(9 94 143 / 47%)';
  //       // this.calendarColor = "rgb(0 103 207 / 20%)";
  //       this.calendarColor = "rgb(0 103 207 / 9%)";
  //   }
  //   else if(this.themeColor == 'purple'){
  //     this.color = '#444791';
  //     // this.color = '#4f5a96';
  //     this.rgbColor ='rgb(68 71 145)';
  //     // this.rgbColor ='rgb(89 103 181 / 55%)';
  //     // this.calendarColor = "rgb(141 150 201 / 42%)";
  //     this.calendarColor = "rgb(141 150 201 / 22%)";
  //   }
  //   else if(this.themeColor == 'black'){
  //     this.color = '#262626';
  //     this.rgbColor ='rgb(38 38 38 / 55%)';
  //     this.calendarColor = "rgb(187 187 187)";
  //   }
  //   $('.btn-default').attr('style', 'background-color: '+this.color +' !important;color:'+this.textColor+' !important');
  //   $('label').attr('style', '  color:'+this.color+'!important');
  //   $('table th').attr('style', ' color:'+this.color+'!important');
  //   $('.mat-header-cell').attr('style', '  color:'+this.color+'!important');
  //   $('.blue-gradient').attr('style', '   background: '+this.color+ '!important; color : #fff !important');
  //   // $('.stickyMenuTop .btn .fas').attr('style', 'color:'+this.color+'!important');
  //   // $('#search').attr('style', 'color:'+this.color+'!important');
  //   $('.quick-event-btn').attr('style', 'color:'+this.color+'!important; background:aliceblue !important;');
  //   $('.shadow-Icon-Color').attr('style', 'color:'+this.color+' !important;');
  //   $('.filter-icon').attr('style', 'color:'+this.color+' !important;');
  //   $('.navbar').attr('style', 'background-color: '+this.color+ ' !important; box-shadow:0 2px 5px 0 '+this.color+ ', 0 2px 10px 0 rgb(0 0 0 / 12%) !important;');
  //   $('.aqua-gradient').attr('style', 'background: '+this.color+ '!important;');
  //   $('.modal-dialog.modal-notify.modal-info .modal-header').attr('style', 'background-color:'+this.color+ '!important;');
  //   $('.btn-info').attr('style', 'background-color:'+this.color+ ' !important;');
    
  //   if(this.themeColor == 'default')
  //     $('.light-blue-skin .side-nav .collapsible .card .card-header a h5').attr('style', 'color:#262626 !important;');
  //   else
  //     $('.light-blue-skin .side-nav .collapsible .card .card-header a h5').attr('style', 'color:#262626 !important;');

  //   $('.mat-datepicker-toggle, .mat-datepicker-content .mat-calendar-next-button, .mat-datepicker-content .mat-calendar-previous-button').attr('style', 'color:'+this.color+ ' !important;');
  //   $('.btn-outline-info').attr('style', 'color:'+this.color+ ' !important; border :2px solid '+this.color+ ' !important');
  //   $('.card.card-cascade.narrower').attr('style', 'box-shadow: '+this.rgbColor+' 0px 8px 24px !important;');
  //   $('.modal-dialog.modal-notify.modal-info .fab, .modal-dialog.modal-notify.modal-info .far, .modal-dialog.modal-notify.modal-info .fas').attr('style', 'color: '+this.color+' !important;');
  //   $('.filterButton').attr('style', 'color: '+this.textColor+' !important;');
  //   // $('..modal-dialog.modal-notify.modal-info .btn .fab, .modal-dialog.modal-notify.modal-info .btn .far, .modal-dialog.modal-notify.modal-info .btn .fas').attr('style', 'color: '+this.color+' !important;');
  //   // $('.cal-month-view .cal-cell-top').attr('style', 'box-shadow: rgb(0 103 207 / 20%) 0px 8px 24px !important;'); //Blue
  //   // if(!this.mobileQuery.matches)
  //     // $('.cal-month-view .cal-cell-top').attr('style', 'box-shadow: '+this.calendarColor+' 0px 8px 24px !important;'); //Default
  //   // $('.cal-month-view .cal-cell-top').attr('style', 'box-shadow: rgb(187 187 187) 0px 8px 24px !important'); //Black
  //   // $('.cal-month-view .cal-cell-top').attr('style', 'box-shadow: rgb(141 150 201 / 42%) 0px 8px 24px !important;'); //purple
  //   // $('.side-nav').attr('style', 'box-shadow: '+this.rgbColor+' 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px !important;width:15rem !important;transform:translateX(0%) !important;');

  //   // $('.modal-dialog.modal-notify.modal-info .fab, .modal-dialog.modal-notify.modal-info .far, .modal-dialog.modal-notify.modal-info .fas').attr('style', 'color:'+this.color+ ' !important;');
  //   // this.common.HideSpinner();
  // }
  //#endregion
}
