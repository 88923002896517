import { Injectable } from '@angular/core';
import { HelpService } from './help.service';

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  constructor(private helpService:HelpService) { }


  async getDomainLists(){
   return await this.helpService.GetDataByHelpNo("hlpdomainlist");
  }

}
