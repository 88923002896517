import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import * as customConfig from 'src/app/customConfig.json';


 
@Injectable({ providedIn: 'root' })

export class AuthenticationService {
  
  constructor(
 ) {        
  }


  public CurrentUser(): any {
    let data = window.localStorage.getItem('currentUser');
    if (data == null) return null;//data = JSON.stringify(new User());
    return new BehaviorSubject<any>(JSON.parse(data)).value;
  }

  public CurrentToken(): any {
    let data = window.localStorage.getItem('currentToken');
    if (data == null) return null;//data = JSON.stringify(new User());
    return data;
  }

  public setCurrentToken(token: any) {
    window.localStorage.setItem('currentToken', token);    
  }


  public login(user: any) {
    window.localStorage.setItem('currentUser', JSON.stringify(user));    
    return user;
  }

  public redirectToLogin()   
  {
    

    if (!this.CurrentUser())
    {
      var url = `${customConfig.instance}/${customConfig.domain}/oauth2/v2.0/authorize?p=${customConfig.signupSignInPolicy}&client_id=${customConfig.clientId}&nonce=defaultNonce&redirect_uri=${customConfig.callbackPathBase}/signin&scope=openid&response_type=id_token&prompt=login`;
      window.location.href = url;
      return;
    }
     
  
    var tid = this.CurrentUser().tid;
    if (customConfig.tenantId == tid) {
      var url = `${customConfig.instance}/${customConfig.domain}/oauth2/v2.0/authorize?p=${customConfig.signupSignInPolicy}&client_id=${customConfig.clientId}&nonce=defaultNonce&redirect_uri=${customConfig.callbackPathBase}/signin&scope=openid&response_type=id_token&prompt=login`;
      window.location.href = url;
    } else{
      var url = `${customConfig.instance}/${customConfig.domain}/oauth2/v2.0/authorize?p=${customConfig.signupSignInPolicy}&client_id=${customConfig.clientId}&nonce=defaultNonce&redirect_uri=${customConfig.callbackPathBase}/signin&scope=openid&response_type=id_token&prompt=login`;
      window.location.href = url;
    }
  

    
    //var url = `${customConfig.instance}/${customConfig.domain}/oauth2/v2.0/authorize?p=${customConfig.signupSignInPolicy}&client_id=${customConfig.clientId}&redirect_uri=${customConfig.callbackPathBase}/resetpassword&scope=${customConfig.scope}&response_type=id_token&prompt=login`;
  }

  public redirectToMicrosoftLogin() {

    //if (!this.CurrentUser()) 
    {
      var url = `${customConfig.instance}/${customConfig.domain}/oauth2/v2.0/authorize?p=${customConfig.signupSignInPolicy}&client_id=${customConfig.clientId}&nonce=defaultNonce&redirect_uri=${customConfig.callbackPathBase}/signin&scope=openid&response_type=id_token&prompt=login&domain_hint=login.microsoftonline.com`;
      window.location.href = url;
      return;
    }

  }

  public logout() {
    // remove user from local storage to log user out
    window.localStorage.removeItem('currentUser');
    this.redirectToLogin();
    
  }

  IsvalidSession(currUser: any) :boolean{
    const now = new Date();
    const sessionTimeout = new Date(currUser.sessionTimeout);

    if (now > sessionTimeout) {
      return false;
    }

    return true;
  }





}


