import { Component, OnInit } from '@angular/core';
import { BLBase } from 'src/app/Base/BLBase/BLBase.component';
import { VenueMonthNotes } from 'src/app/models/venue-month-notes.model';
import { Subject } from 'rxjs';
import { GenericService } from 'src/app/services/generic.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService, MDBModalRef } from 'ng-uikit-pro-standard';
import { ConfirmDialogeService } from 'src/app/confirm-dialoge/confirm-dialoge.service';
import { GeneralService } from 'src/app/services/general.service';
import { MessageDialogeService } from 'src/app/message-dialoge/message-dialoge.service';
import { CommonService } from 'src/app/Base/Common.service';
import { NgForm } from '@angular/forms';
import moment from 'moment';
import { Venue } from '../models/venue.model';
import { themeService } from '../services/theme.service';
import { PermissionService } from '../services/permission.service';

@Component({
  selector: 'app-calendar-notes',
  templateUrl: './calendar-notes.component.html',
  styleUrls: []
})
export class CalendarNotesComponent extends BLBase<VenueMonthNotes> implements OnInit {

  venueID:number = 0;
  public monthDate:any = null;
  venueMonthNotesList: VenueMonthNotes[] = [];
  Venues:Venue[] =[];
  MonthNotes:VenueMonthNotes = null;
  action:Subject<VenueMonthNotes[]> = new Subject<VenueMonthNotes[]>();

  constructor(
    public service: GenericService,
    public router: Router,
    public route: ActivatedRoute,
    public spinner: NgxSpinnerService,
    public toast: ToastService,
    public dialog: ConfirmDialogeService,
    public genService: GeneralService,
    public modalRef: MDBModalRef,
    public messageDialog: MessageDialogeService,
    public common:CommonService,
    public theme:themeService,
    public permissionService:PermissionService
  ) {

    super(service, router, route, spinner, toast, dialog, messageDialog);
    

    this.formTitle = "Calendar Notes";

    this.addControllerName("VenueMonthNotes");
    this.isGetByPrimaryKey = false;
    this.primaryKey = 0;
  }

  public Initializeobject() {
    this.formData = new VenueMonthNotes();
  }

  ngOnInit(): void {

    super.ngOnInit();
    
    // var year =  moment(this.monthDate).format("YYYY")
    // var month = moment(this.monthDate).format("MM");

    // this.genService.GetDataByQuery("VenueMonthNotes?$expand=RBAC_Users1($select=ID,FirstName,LastName)&$filter=VenueID eq " + this.venueID + "and year(Month) eq " + year + " and month(Month) eq " + month + " &$orderby=CreatedDate desc").then(res => {
    //   this.venueMonthNotesList = res['value'] as VenueMonthNotes[];
    // })

  }

  ngAfterViewInit(){
    
  }

  setSectionName(){
    this.sectionName = "Monthly Notes";
  }

  AfterOnInit() {
    this.formTitle = this.heading;
    this.SavebuttonText = 'Submit';

    if(this.MonthNotes !=null)
    {
      this.formData.ID = this.MonthNotes.ID;
      this.formData.Notes = this.MonthNotes.Notes;
      this.formData.Month = this.MonthNotes.Month;
      this.formData.VenueID = this.MonthNotes.VenueID;
      this.formData.ModifiedBy = this.MonthNotes.ModifiedBy;
      this.formData.ModifiedDate = this.MonthNotes.ModifiedDate;
      this.formData.CreatedBy = this.MonthNotes.CreatedBy;
      this.formData.CreatedDate = this.MonthNotes.CreatedDate;
      this.primaryKey = this.MonthNotes.ID;
      this.SavebuttonText = 'Update';
    }

  }



  BeforeUpsert(form: NgForm) {
    form.value.VenueID = this.venueID;
    form.value.Month = moment(this.monthDate).startOf('month').format("YYYY-MM-DD");
  }

  onSubmit(form: NgForm) {
    this.insertRecord(form);
  }

  AfterInsert() {
    this.action.next();
    this.modalRef.hide();
  }

  AfterUpdate() {
    this.action.next();
    this.modalRef.hide();
  }

  onReload(form: NgForm) {
    this.Reload(form);
  }

  // DeleteNote(ID) {

  //   if (ID != 0 && ID != null) {
  //     this.dialog.openConfirmDialog("Are you sure you want to delete this record?").afterClosed().subscribe(res => {
  //       if (res) {
  //         this.deleteRecord(ID);
  //       }
  //     })
  //   }
  // }

  // AfterDelete() {
  //   this.action.next();
  //   this.modalRef.hide();
  // }

  // onEdit(row){

  //   document.getElementById('modalBodyArea').scrollTop = 0;
  //   if(row !=null)
  //   {
  //     this.formData.ID = row.ID;
  //     this.formData.Notes = row.Notes;
  //     this.formData.Month = row.Month;
  //     this.formData.VenueID = row.VenueID;
  //     this.formData.ModifiedBy = row.ModifiedBy;
  //     this.formData.ModifiedDate = row.ModifiedDate;
  //     this.formData.CreatedBy = row.CreatedBy;
  //     this.formData.CreatedDate = row.CreatedDate;
  //     this.primaryKey = row.ID;
  //     this.SavebuttonText = 'Update';
  //   }

  // }

}
