import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import { Venue } from 'src/app/models/venue.model';
import { Client } from 'src/app/modules/client/client.model';
import { GeneralService } from 'src/app/services/general.service';
import { GenericService } from 'src/app/services/generic.service';
import { themeService } from 'src/app/services/theme.service';
import { EventStatusService } from '../event-status/event-status.service';

@Component({
  selector: 'app-dummy-data',
  templateUrl: './dummy-data.component.html',
  styleUrls: ['./dummy-data.component.scss']
})
export class DummyDataComponent implements OnInit {

  VenueData:any[]=[
    {
      ID:0,
      VenueName:"Dummy Venue",
      ShortCode:"DV",
      Capacity:1200,
      Inactive:false 
    }
  ];
  formDataVenue:Venue = new Venue();
  clientData:any[]=[
    {
      ID:0,
      ClientName:"Dummy Client",
      Phone:"",
      Email:"dummyclient@gmail.com",
      Client_ContactPerson:[
        {
          ID:0,
          ClientID:0,
          ContactPersonName:"Dummy Contact Person",
          Email:"dummyContact@gmail.com",
        }
      ]
    }
  ];

  events:any[]=[
    {
      ID:1,
      EventName:"First Event",
      EventStartDate:this.getFiveDays()[0],
      EventEndDate:this.getFiveDays()[0],
      VenueName:"Dummy Venue",
      Status:"Contracted"
    },
    {
      ID:2,
      EventName:"Second Event",
      EventStartDate:this.getFiveDays()[1],
      EventEndDate:this.getFiveDays()[1],
      VenueName:"Dummy Venue",
      Status:"Contracted"
    },
    {
      ID:3,
      EventName:"Third Event",
      EventStartDate:this.getFiveDays()[2],
      EventEndDate:this.getFiveDays()[2],
      VenueName:"Dummy Venue",
      Status:"Contracted"
    },
    {
      ID:4,
      EventName:"Fourth Event",
      EventStartDate:this.getFiveDays()[3],
      EventEndDate:this.getFiveDays()[3],
      VenueName:"Dummy Venue",
      Status:"Contracted"
    },
    {
      ID:5,
      EventName:"Fifth Event",
      EventStartDate:this.getFiveDays()[4],
      EventEndDate:this.getFiveDays()[4],
      VenueName:"Dummy Venue",
      Status:"Contracted"
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public eventStatusService: EventStatusService,
    public theme:themeService,
    private dialogRef: MatDialogRef<DummyDataComponent>,
    public genService: GeneralService,
    public service: GenericService,
  ) { }

  ngOnInit(): void {
    console.log(this.getFiveDays());
    }

  ngAfterViewInit(){
    this.theme.getCustomerTheme();
  }

  onSubmit(){
    this.service.postData(this.VenueData[0],"Venues","Venue").toPromise().then(res=>{
       this.formDataVenue = res as Venue;
    })
  }


   getFiveDays():any[] {
     var days:any[]=[];
     const currentDate = new Date();
     const currentMonth = currentDate.getMonth();
     const currentYear = currentDate.getFullYear();
     for (let i = 1; i <= 5; i++) {
      const date = new Date(currentYear, currentMonth, i);
        var fDate = moment(date).format('MM/DD/yyyy');
        days.push(fDate);
    }
    return days;
  }
}
