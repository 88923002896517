import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { LoginUser } from 'src/app/Base/User/login-user';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-trial-expired',
  templateUrl: './trial-expired.component.html',
  styleUrls: ['./trial-expired.component.scss']
})
export class TrialExpiredComponent implements OnInit {

  constructor(
    public router: Router,
    public tokenService:TokenService,
    private authService: MsalService,  
    private authenticationService :AuthenticationService
  ) { }

  ngOnInit(): void {
  }

  logout(){
    this.authenticationService.logout();    
    return;
  }
}
