import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/Base/Common.service';
import { ToastType } from 'src/app/Enum/ToastType';
import { GeneralService } from 'src/app/services/general.service';
import { themeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-event-form-tour',
  templateUrl: './event-form-tour.component.html',
  styleUrls: ['./event-form-tour.component.scss'],
  animations: [
    trigger('slideAnimation', [
      transition(':increment', group([
        query(':enter', [
          style({
            transform: 'translateX(100%)',
            opacity: '0',
            display: 'none'
          }),
          animate('0.5s ease-in', style('*'))
        ]),
        query(':leave', [
          animate('0.5s ease-in', style({
            transform: 'translateX(-100%)',
            opacity: '0',
            display: 'none'
          }))
        ])
      ])),
      transition(':decrement', group([
        query(':enter', [
          style({
            transform: 'translateX(-100%)',
            opacity: '0',
            display: 'none'
          }),
          animate('0.5s ease-in', style('*'))
        ]),
        query(':leave', [
          animate('0.5s ease-in', style({
            transform: 'translateX(100%)',
            opacity: '0',
            display: 'none'
          }))
        ])
      ]))
    ])
  ]
})
export class EventFormTourComponent implements OnInit {

  sideNav: slides[] = [];
  currentIndex: number = 0;
  activeSlides: any[] = [];

  constructor(
    public common: CommonService,
    public genService: GeneralService,
    public dialogRef: MatDialogRef<EventFormTourComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public theme: themeService
  ) { }

  async ngOnInit() {
    await this.getData();
  }

  async getData() {
    this.common.ShowSpinner();
    await this.genService.GetDataByQuery("Tour_Sidenav?$expand=Tour_Slides").then(res => {
      this.sideNav = res['value'];
      this.setActive(this.sideNav[0]);
      this.common.HideSpinner();
    }, error => {
      this.common.HideSpinner();
      this.common.ShowToast(error.message, ToastType.Error);
    })
  }

  setActive(item: slides) {
    this.activeSlides = [];
    this.sideNav.map(a => a.Active = false);
    item.Active = true;
    this.currentIndex = 0;
    this.activeSlides = item.Tour_Slides;
  }

  isCurrentSlideIndex(index) {
    return this.currentIndex === index;
  }

  prevSlide(slides: any[]) {
    if ((this.currentIndex != 0) && (this.currentIndex <= slides.length - 1)) {
      --this.currentIndex;
    }
    else {
      this.currentIndex = slides.length - 1;
    }
  }

  nextSlide(slides: any[]) {
    if ((this.currentIndex == 0) || (this.currentIndex > 0 && (this.currentIndex < slides.length - 1))) {
      ++this.currentIndex;
    }
    else {
      this.currentIndex = 0
    }
  }

  onClose() {
    this.dialogRef.close()
  }

}

export interface slides {
  ID: number,
  Name: string,
  DisplayName: string,
  Icon: string,
  ActiveText: string,
  Active: boolean,
  Tour_Slides: any[]
}