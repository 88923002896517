import { Component, OnInit } from '@angular/core';
import { Event } from '../../event-info/Event.model';
import { EventDetails } from '../../event-details.model';
import { Subject } from 'rxjs';
import { ToastType } from 'src/app/Enum/ToastType';
import { ToastService, MDBModalService, MDBModalRef } from 'ng-uikit-pro-standard';
import { GeneralService } from 'src/app/services/general.service';
import { GenericService } from 'src/app/services/generic.service'
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { BLBase } from 'src/app/Base/BLBase/BLBase.component';
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmDialogeService } from 'src/app/confirm-dialoge/confirm-dialoge.service';
import { SubEventNotes } from '../event-sub-event-notes.model'
import { MessageDialogeService } from 'src/app/message-dialoge/message-dialoge.service';
import { Event_SubEvent } from '../../event-info/Event_SubEvent.model';
import { CommonService } from '../../../../Base/Common.service';
import { themeService } from 'src/app/services/theme.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-event-sub-event-notes',
  templateUrl: './event-sub-event-notes.component.html',
  styles: []
})
export class EventSubEventNotesComponent extends BLBase<SubEventNotes> implements OnInit {
  eventID: number = 0;
  subEventID:number = 0;
  eventNotesList: any[] = [];
  public eventTableData: EventDetails;
  action:Subject<SubEventNotes[]> = new Subject<SubEventNotes[]>();

  subEventDate:string ="";

  constructor(
    public service: GenericService,
    public router: Router,
    public route: ActivatedRoute,
    public spinner: NgxSpinnerService,
    public toast: ToastService,
    public dialog: ConfirmDialogeService,
    public genService: GeneralService,
    public modalRef: MDBModalRef,
    public messageDialog: MessageDialogeService,
    public common:CommonService,
    public theme:themeService,
    public permissionService:PermissionService
  ) {

    super(service, router, route, spinner, toast, dialog, messageDialog);

    this.formTitle = "Performance Notes";

    this.addControllerName("Event_SubEvent_Notes");

    this.isGetByPrimaryKey = false;
    this.primaryKey = 0;
  }

  public Initializeobject() {
    this.formData = new SubEventNotes();
    this.eventTableData = new EventDetails();
  }

  ngOnInit(): void {
    super.ngOnInit();
    
    this.genService.GetDataByQuery("Event_SubEvent_Notes?$expand=RBAC_Users1($select=ID,FirstName,LastName), Event_SubEvent&$filter=EventID eq " + this.eventID + " and SubEventID eq " + this.subEventID + " &$orderby=CreatedDate desc").then(res => {
      this.eventNotesList = res['value'] as any[];

      // if (this.eventNotesList.length > 0) {
      //   // if (this.eventNotesList[0]["Event_SubEvent"] != null && this.eventNotesList[0]["Event_SubEvent"] != undefined) {
      //   //   //this.subEventDate = " | " + this.eventNotesList[0]["Event_SubEvent"]["SubEventDate"];
      //   // }
      // }

    })

    this.getEventTableData();
    this.getSubEventTableData();
  }

  ngAfterViewInit(){
    
  }

  setSectionName(){
    this.sectionName = "Performance Notes";
  }

  AfterOnInit() {
    this.formTitle = this.heading;
    this.SavebuttonText = 'Submit';
  }


  getEventTableData() {
    this.genService.GetDataByQuery("Events(" + this.eventID + ")?$expand=Client, Client_ContactPerson1,Client_ContactPerson,EventCategory,Venue,EventTicketType,Genre").then(
      res => {
        // Sameer : 09042020 // Date Formatting
        res["EventStartDate"] = this.BLFormatDate(res["EventStartDate"]);
        res["EventEndDate"] = this.BLFormatDate(res["EventEndDate"]);

        this.eventTableData = res as EventDetails;


      }
      , error => {
        this.HideSpinner();
        this.ShowToast(error.message, ToastType.Error);
      }
    )
  }

  getSubEventTableData() {

    this.genService.GetDataByQuery("Event_SubEvent(" + this.subEventID + ")").then(
      res => {

        var subEvent = res as Event_SubEvent;
        this.subEventDate = " | " +  subEvent.SubEventDate;
      }
      , error => {
        this.HideSpinner();
        this.ShowToast(error.message, ToastType.Error);
      }
    )
  }

  onCreate(form: NgForm) {
    this.router.navigate(['/subeventnotes'], { replaceUrl: true });
    this.Create();

  }

  BeforeUpsert(form: NgForm) {
    form.value.EventID = this.eventID;
    form.value.SubEventID = this.subEventID;
  }

  onSubmit(form: NgForm) {
    this.insertRecord(form);
  }

  AfterInsert() {
    //this.router.navigate(['/eventnotes']);
    this.action.next();
    this.modalRef.hide();
  }

  AfterUpdate() {
    this.action.next();
    this.modalRef.hide();
  }

  onReload(form: NgForm) {
    this.Reload(form);
  }


  DeleteNote(ID) {

    //var ID =  this.eventNotesList[index].ID
    if (ID != 0 && ID != null) {
      this.dialog.openConfirmDialog("Are you sure you want to delete this record?").afterClosed().subscribe(res => {
        if (res) {
          this.deleteRecord(ID);

        }
      })
    }
  }


  AfterDelete() {
    this.action.next();
    this.modalRef.hide();
  }

  onEdit(row){

    document.getElementById('modalBodyArea').scrollTop = 0;
    if(row !=null)
    {
      this.formData.ID = row.ID;
      this.formData.Notes = row.Notes;
      this.formData.EventID = row.EventID;
      this.formData.SubEventID = row.SubEventID;
      this.formData.ModifiedBy = row.ModifiedBy;
      this.formData.ModifiedDate = row.ModifiedDate;
      this.formData.CreatedBy = row.CreatedBy;
      this.formData.CreatedDate = row.CreatedDate;
      this.primaryKey = row.ID;
      this.SavebuttonText = 'Update';
    }

  }


}
