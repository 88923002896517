import { Injectable } from '@angular/core';
import moment from 'moment';
import { ConfFilters } from 'src/app/models/conf-filters.model';
import { HelpService } from 'src/app/services/help.service';

@Injectable({
  providedIn: 'root'
})
export class EventListService {
  VenueID: number[] = [];
  EventStatusID: number[] = [];
  StartEventDate: Date = moment().startOf('year').toDate();
  EndEventDate: Date = moment().endOf('year').toDate();
  selectedClients:number[]=[];

  IsAppLoaded:boolean = false;
  confFilters:ConfFilters[] =[];

  constructor(private helpService:HelpService) { }


  async getEventList(StartDate:any,EndDate:any,VenueID:any,EventStatusID:any,ClientID:any){
    var filters ={
      startdate:StartDate,
      enddate:EndDate,
      venueid:VenueID,
      eventstatusid:EventStatusID,
      clientid:ClientID,
    }
   return await this.helpService.GetDataByHelpNo("hlpeventlistsp",filters);
  }
}
