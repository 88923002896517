import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';


import { RBAC_User } from 'src/app/models/rbac_user.model';
import { Observable } from 'rxjs';
import { TokenService } from 'src/app/services/token.service';
import { GeneralService } from 'src/app/services/general.service';

import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import * as customConfig from 'src/app/customConfig.json';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  loginUrl:string = customConfig.callbackPathBase;
  loginWith: string = "";
  constructor(
    private activatedRoute: ActivatedRoute,
  ) {


  }

  ngOnInit(): void {
    var source = "";
    this.activatedRoute.queryParams.subscribe(params => {
      source = params['source'];
    })    
    
    if(source)
      this.loginWith = source;
    

      this.loginWith = "venuearc";

  }

  isSaved:boolean = false;
  onSuccess($event){
    this.isSaved = $event;
  }


  navigateMarketPlaceSignUp(){
    //window.location.href = "https://portal.azure.com/#create/alrafayconsultingllc1646338685878.venuearc_test";
    //window.location.href = "https://portal.azure.com/#view/Microsoft_Azure_Marketplace/GalleryItemDetailsBladeNopdl/id/alrafayconsultingllc1646338685878.venuearc_mod3";
    window.location.href = customConfig.marketplacelink;
  }



  subscribeAS(){
    window.location.href =  customConfig.appsourcelink;
  }


}
