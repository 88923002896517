import { Component, OnInit, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { GenericService } from 'src/app/services/generic.service'
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { BLBase } from 'src/app/Base/BLBase/BLBase.component';
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmDialogeService } from 'src/app/confirm-dialoge/confirm-dialoge.service';
import { EventNotes } from '../event-notes.model'
import { Event } from '../../event-info/Event.model';
import { EventDetails } from '../../event-details.model';
import { Subject } from 'rxjs';
import { ToastType } from 'src/app/Enum/ToastType';
import { ToastService, MDBModalService, MDBModalRef } from 'ng-uikit-pro-standard';
import { MessageDialogeService } from 'src/app/message-dialoge/message-dialoge.service';
import { CommonService } from '../../../../Base/Common.service';
import { themeService } from 'src/app/services/theme.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-event-notes',
  templateUrl: './event-notes.component.html',
  styles: []
})
export class EventNotesComponent extends BLBase<EventNotes> implements OnInit {

  eventID: number = 0;
  eventNotesList: EventNotes[] = [];
  public eventTableData: EventDetails;
  action:Subject<EventNotes[]> = new Subject<EventNotes[]>();
  @ViewChild("MytxtElem") MyProp: ElementRef;


  constructor(
    public service: GenericService,
    public router: Router,
    public route: ActivatedRoute,
    public spinner: NgxSpinnerService,
    public toast: ToastService,
    public dialog: ConfirmDialogeService,
    public genService: GeneralService,
    public modalRef: MDBModalRef,
    public messageDialog: MessageDialogeService,
    public common:CommonService,
    public theme :themeService,
    public permissionService:PermissionService


  ) {

    super(service, router, route, spinner, toast, dialog, messageDialog);
    
    this.formTitle = "Event Notes";


    this.addControllerName("Event_Notes");
    this.isGetByPrimaryKey = false;
    this.primaryKey = 0;
  }

  setSectionName(){
    this.sectionName = "Event Notes";
  }

  public Initializeobject() {
    this.formData = new EventNotes();
    this.eventTableData = new EventDetails();
  }

  ngOnInit(): void {
    super.ngOnInit();
 
    this.genService.GetDataByQuery("Event_Notes?$expand=RBAC_Users1($select=ID,FirstName,LastName)&$filter=EventID eq " + this.eventID + " and (IsDeleted ne true or IsDeleted eq null)&$orderby=CreatedDate desc").then(res => {
      this.eventNotesList = res['value'] as EventNotes[];
    })

    this.getEventTableData();
  }

  ngAfterViewInit(){
  
  }

  AfterOnInit() {

    this.formTitle = this.heading;
    this.SavebuttonText = 'Submit';
  }


  getEventTableData() {
    this.genService.GetDataByQuery("Events(" + this.eventID + ")?$expand=Client, Client_ContactPerson1,Client_ContactPerson,EventCategory,Venue,EventTicketType,Genre").then(
      res => {

        // Sameer : 09042020 // Date Formatting
        res["EventStartDate"] = this.BLFormatDate(res["EventStartDate"]);
        res["EventEndDate"] = this.BLFormatDate(res["EventEndDate"]);

        this.eventTableData = res as EventDetails;
      }
      , error => {
        this.HideSpinner();
        this.ShowToast(error.message, ToastType.Error);
      }
    )
  }

  onCreate(form: NgForm) {
    this.router.navigate(['/eventnotes'], { replaceUrl: true });
    this.Create();

  }

  BeforeUpsert(form: NgForm) {
    form.value.EventID = this.eventID;
  }

  onSubmit(form: NgForm) {
    this.insertRecord(form);
  }

  AfterInsert() {
    //this.router.navigate(['/eventnotes']);
    this.action.next();
    this.modalRef.hide();
  }

  AfterUpdate() {
    this.action.next();
    this.modalRef.hide();
  }

  onReload(form: NgForm) {
    this.Reload(form);
  }

  DeleteNote(ID) {

    //var ID =  this.eventNotesList[index].ID
    if (ID != 0 && ID != null) {
      this.dialog.openConfirmDialog("Are you sure you want to delete this record?").afterClosed().subscribe(res => {
        if (res) {
          this.deleteRecord(ID);

        }
      })
    }
  }

  AfterDelete() {
    this.action.next();
    this.modalRef.hide();
  }
  /*
  onDelete(ID:number){
    this.dialog.openConfirmDialog("Are you sure you want to delete this record?").afterClosed().subscribe( res =>{
      if(res)
      {
        this.deleteRecord(ID);
      }
    })
  }
  */





  onEdit(row){

    document.getElementById('modalBodyArea').scrollTop = 0;
    if(row !=null)
    {
      this.formData.ID = row.ID;
      this.formData.Notes = row.Notes;
      this.formData.EventID = row.EventID;
      this.formData.ModifiedBy = row.ModifiedBy;
      this.formData.ModifiedDate = row.ModifiedDate;
      this.formData.CreatedBy = row.CreatedBy;
      this.formData.CreatedDate = row.CreatedDate;
      this.primaryKey = row.ID;
      this.SavebuttonText = 'Update';
    }

  }



}
