<div class="container-fluid">
        <!-- <button type="button" (click)="afterSave()"> Send email</button> -->
        <div class="row wholeContent">
                <div class="col-md-12  col-sm-12 col-lg-9 col-12">
                        <div class="float-right">
                               
                                <!-- <a class="signout" (click)="onEmail()">
                                        Email
                                </a> -->
                        </div>
                      <div class="LeftDiv">

                        <div class="head">
                                <h1 style="color: #2e4f9b;                                
                                font-weight: bold;">Let's begin your VenueArc journey!</h1>
                        </div>
                        
                        <div class="Stepper" id="step1">
                                <div class="d-flex mobnoflex">
                                        <div class="stepperOverlay"
                                                [ngStyle]="{ 'display': activeStepper == 'step1' ? 'none':'block' }">

                                        </div>
                                        <div class="col-12 logoforMob">
                                                <div class="logoDiv">
                                                        <img src="/assets/Venue Icon.ico">
                                                </div>
                                        </div>
                                        <div class="rightWrapper">
                                                <div class="stepperHeading mb-2">
                                                        Welcome,
                                                        <span style="display: inline-flex;
                                                        " id="username"> {{currentUsername | titlecase}}
                                                                <!-- <span class="useremail">{{currentUserEmail}}</span> -->
                                                                <img style="width: 25px;" src="../../../../assets//img//handcelbrating.png" alt="">
                                                        </span>
                                                </div>
                                                <div class="stepperParagraph">
                                                        Embark on a remarkable journey with VenueArc, the ultimate destination for seamless event and venue management! To kickstart your journey, we kindly request you to share the following details:
                                                </div>

                                                <div class="stepperForm d-flex flex-column">
                                                        <span class="mb-4">
                                                                Enter the name of your organization or company.
                                                        </span>
                                                        <span>
                                                                <input type="text" name="OrganisationName"
                                                                        class="stepperInput"
                                                                        [(ngModel)]="formDataOrg.OrganisationName"
                                                                        #OrganisationName="ngModel">
                                                        </span>
                                                        <div
                                                                class="smallTextDiv d-flex justify-content-between align-items-center">
                                                                <span class="smallText">You can also use the name of
                                                                        your workspace or organization</span>
                                                        </div>
                                                </div>
                                                <div class="stepperButtonDiv" style="display: flex;
                                                justify-content: flex-end;">
                                                        <button type="button"
                                                                [disabled]="formDataOrg.OrganisationName == '' || formDataOrg.OrganisationName == null"
                                                                (click)="scrollToNext('step2')" class="stepperBtn" >
                                                                Next
                                                        </button>

                                                </div>
                                        </div>
                                </div>
                        </div>

                        <!-- Safwan - 02/13/2024 -->
                        <!-- <div class="Stepper" id="step2">
                                <div class="stepperOverlay"
                                        [ngStyle]="{ 'display': activeStepper == 'step2' ? 'none':'block' }">

                                </div>
                                <div class="d-flex mobnoflex">
                                        <div class="col-12 logoforMob">
                                                <div class="logoDiv">
                                                        <img src="/assets/Venue Icon.ico">
                                                </div>
                                        </div>
                                        <div class="rightWrapper">

                                                <div class="stepperHeading mb-3">
                                                        Enter the number of employees in your organization.
                                                </div>
                                                <div class="d-flex flexWrapMob mt-4">
                                                        <div *ngFor="let item of dataChips" class="stepperChips"
                                                                (click)="selectChip(item)"
                                                                [ngClass]="{'selected': item.value === selectedChip}">
                                                                {{item.numbers}}
                                                        </div>
                                                </div>
                                                <div class="stepperButtonDiv mt-4 d-flex justify-content-between">
                                                       
                                                        <button type="button" (click)="scrollToPrevious('step1')"
                                                                class="stepperBtn">
                                                                Previous
                                                        </button>
                                                        <button type="button"
                                                                [disabled]="selectedChip == null || selectedChip == ''"
                                                                (click)="scrollToNext('step3')" class="stepperBtn">
                                                                Next
                                                        </button>
                                                </div>
                                        </div>
                                </div>
                        </div> -->

                        <div class="Stepper" id="step2">
                                <div class="stepperOverlay"
                                        [ngStyle]="{ 'display': activeStepper == 'step2' ? 'none':'block' }">

                                </div>
                                <div class="d-flex mobnoflex">
                                        <div class="col-12 logoforMob">
                                                <div class="logoDiv">
                                                        <img src="/assets/Venue Icon.ico">
                                                </div>
                                        </div>
                                        <div class="rightWrapper">

                                                <div class="stepperHeading mb-4">
                                                        Where is your company located?
                                                </div>

                                                <div class="stepperForm mb-3">


                                                        <span>
                                                                <!-- <input type="text" name="CountryName" class="stepperInput" 
                                                        [matAutocomplete]="auto" 
                                                        (ngModelChange)="AutoCompCntry.searchResult.next($event)" 
                                                        placeholder="Select"
                                                        [(ngModel)]="formDataOrg.CountryName"
                                                        #CountryName="ngModel" >

                                                        <mat-autocomplete #auto="matAutocomplete"  (optionSelected)="getMaskbyCountryName(formDataOrg.CountryName)"  [displayWith]="AutoCompCntry.onDisplayValue">
                                                                <mat-option *ngFor="let country of AutoCompCntry.results | async" [value]="country.name">
                                                                  {{country.name}}
                                                                </mat-option>
                                                        </mat-autocomplete> -->

                                                                <mat-form-field>
                                                                        <mat-select name="CountryName" [disabled]="activeStepper != 'step2'"
                                                                                [(ngModel)]="formDataOrg.CountryName"
                                                                                (selectionChange)="getMaskbyCountryName(formDataOrg.CountryName)"
                                                                                #CountryName="ngModel">
                                                                                <mat-option>
                                                                                        <ngx-mat-select-search
                                                                                                name="country"
                                                                                                #Location="ngModel"
                                                                                                [(ngModel)]="searchUserLead"
                                                                                                (ngModelChange)="AutoCompCntry.searchResult.next($event)"
                                                                                                placeholderLabel="Search..."
                                                                                                noEntriesFoundLabel="Not Found!"
                                                                                                [ngModelOptions]="{ standalone: true }">
                                                                                        </ngx-mat-select-search>
                                                                                </mat-option>
                                                                                <mat-option
                                                                                        *ngFor="let option of AutoCompCntry.results | async"
                                                                                        [value]="option.name">
                                                                                        {{option.name}}</mat-option>
                                                                        </mat-select>
                                                                </mat-form-field>
                                                        </span>
                                                </div>









                                                <div class="stepperButtonDiv mt-4 d-flex justify-content-between">
                                                        
                                                        <button type="button" (click)="scrollToPrevious('step1')"
                                                                class="stepperBtn">
                                                                Previous
                                                        </button>
                                                        <button class="stepperBtn"
                                                                [disabled]="formDataOrg.CountryName == '' || formDataOrg.CountryName == null"
                                                                (click)="scrollToNext('step3')">
                                                                Next
                                                        </button>
                                                </div>
                                        </div>
                                </div>
                        </div>

                        <!-- Safwan - 02/13/2024 -->
                        <!-- <div class="Stepper" id="step4">
                                <div class="stepperOverlay"
                                        [ngStyle]="{ 'display': activeStepper == 'step4' ? 'none':'block' }">

                                </div>
                                <div class="d-flex mobnoflex">
                                        <div class="col-12 logoforMob">
                                                <div class="logoDiv">
                                                        <img src="/assets/Venue Icon.ico">
                                                </div>
                                        </div>
                                        <div class="rightWrapper">

                                                <div class="stepperHeading mb-4">
                                                        Enter your contact number.
                                                </div>
                                                <div class="stepperForm mb-3">


                                                        <span class="d-flex">
                                                                <span class="flagicon"
                                                                        [hidden]="selectedCntryImg ==null || selectedCntryImg == ''"
                                                                        style="width: 27px;border-bottom: 1px solid #2e4f9b !important;padding: 9px 30px 9px 0px;">
                                                                        <img [src]="selectedCntryImg" alt=""
                                                                                height="30px" width="27px"
                                                                                style="padding-top: 4px;">
                                                                </span>
                                                                <span class="countrycode" style="border-bottom: 1px solid #2e4f9b !important;padding: 14px 0 9px 0;font-size: 24px;height: 50px;">{{formDataOrg.CountryCode}}</span>
                                                                <input type="text" name="Phone" class="stepperInput"
                                                                        [mask]="selectedMask" [showMaskTyped]="true"
                                                                        [(ngModel)]="formDataOrg.Phone"
                                                                        #Phone="ngModel">
                                                        </span>
                                                </div>








                                                <div class="stepperButtonDiv mt-4 d-flex justify-content-between">
                                                        
                                                        <button type="button" (click)="scrollToPrevious('step3')"
                                                                class="stepperBtn">
                                                                Previous
                                                        </button>
                                                        <button class="stepperBtn" [disabled]="chechCondition()"
                                                                (click)="scrollToNext('step5');">
                                                                Next
                                                        </button>
                                                </div>
                                        </div>
                                </div>
                        </div> -->

                        <!-- Safwan - 02/13/2024 -->
                        <!-- <div class="Stepper" id="step5">
                                <div class="stepperOverlay"
                                        [ngStyle]="{ 'display': activeStepper == 'step5' ? 'none':'block' }">

                                </div>
                                <div class="d-flex mobnoflex">
                                        <div class="col-12 logoforMob">
                                                <div class="logoDiv">
                                                        <img src="/assets/Venue Icon.ico">
                                                </div>
                                        </div>
                                        <div class="rightWrapper">

                                                <div class="stepperHeading mb-4">
                                                        Upload your Company logo

                                                </div>

                                                <div class="stepperForm d-flex mb-3">
                                                        <div class="desktop">
                                                                <div class="uploadBtn" *ngIf="!previewImage">
                                                                        <mat-icon class="iconColor">cloud_upload</mat-icon>
        
                                                                        <span>
                                                                                <label for="uploadInput" style="margin-bottom: 0;margin-top: 10px;" >
                                                                                        <span class="cstmLabel" (click)="uploadAndResize()">Upload an
                                                                                                Image
                                                                                                <br>
                                                                                                <span style="text-align: center;display: block;margin-top: 5px;">
                                                                                                        250 x 100
                                                                                                </span>
                                                                                        </span>
                                                                                        <!-- <input id="uploadInput" type="file"
                                                                                                class="hideBtn"
                                                                                                (change)="onFileSelected($event)"
                                                                                                accept="image/*"> -- =======
                                                                                </label>
                                                                                <!-- <label for="uploadInput" *ngIf="previewImage">
                                                                                <button type="button" style="border: none;background: transparent;" class="cstmLabel" (click)="deleteImage()">Delete an Image</button>
                                                                        </label> -- ===========
                                                                        </span>
        
                                                                </div>
        
                                                                <div class="verticalLine" *ngIf="!previewImage">
        
                                                                </div>
        
                                                                <div class="previewImage ">
                                                                        <img class="logoImg" *ngIf="previewImage"
                                                                                [src]="previewImage" alt="Preview Image">
                                                                        <mat-icon class="iconColor deleteIcon"  (click)="deleteImage()" *ngIf="previewImage">delete</mat-icon>
                                                                </div>
                                                        </div>
                                                        <div class="mobile">
                                                                <div class="uploadBtn" *ngIf="!previewImage">
                                                                        <mat-icon class="iconColor">cloud_upload</mat-icon>
        
                                                                        <span>
                                                                                <label for="uploadInput" style="margin-bottom: 0;margin-top: 10px;">
                                                                                        <span class="cstmLabel" (click)="uploadAndResize()">Upload an
                                                                                                Image
                                                                                                <br>
                                                                                                <span style="text-align: center;display: block;margin-top: 5px;">
                                                                                                        250 x 100
                                                                                                </span>
                                                                                        </span>
                                                                                        <!-- <input id="uploadInput" type="file"
                                                                                                class="hideBtn"
                                                                                                (change)="onFileSelected($event)"
                                                                                                accept="image/*"> -- ==================
                                                                                </label>
                                                                                <!-- <label for="uploadInput" *ngIf="previewImage">
                                                                                <button type="button" style="border: none;background: transparent;" class="cstmLabel" (click)="deleteImage()">Delete an Image</button>
                                                                        </label> -- ================
                                                                        </span>
        
                                                                </div>
        
                                                             
        
                                                                <div class="previewImage " *ngIf="previewImage">
                                                                        <img class="logoImg" *ngIf="previewImage"
                                                                                [src]="previewImage" alt="Preview Image">
                                                                        <mat-icon class="iconColor deleteIcon"  (click)="deleteImage()" *ngIf="previewImage">delete</mat-icon>
                                                                </div>
                                                        </div>
                                                        
                                                </div>





                                                <div class="stepperButtonDiv mt-4 d-flex justify-content-between">
                                                        
                                                        <button type="button" (click)="scrollToPrevious('step4')"
                                                                class="stepperBtn">
                                                                Previous
                                                        </button>
                                                        <button class="stepperBtn"
                                                                [disabled]="previewImage == '' || previewImage == null"
                                                                (click)="scrollToNext('step6')">
                                                                Next
                                                        </button>
                                                </div>
                                        </div>
                                </div>
                        </div> -->

                        <div class="Stepper" id="step3">
                                <div class="stepperOverlay"
                                        [ngStyle]="{ 'display': activeStepper == 'step3' ? 'none':'block' }">

                                </div>
                                <div class="d-flex mobnoflex">
                                        <div class="col-12 logoforMob">
                                                <div class="logoDiv">
                                                        <img src="/assets/Venue Icon.ico">
                                                </div>
                                        </div>
                                        <div class="rightWrapper">

                                                <div class="stepperHeading lessMargin mb-4">
                                                        What's your venue name?
                                                </div>
                                                <div class="stepperForm  mb-3">


                                                        <span class="">
                                                                <input type="text" class="stepperInput mb-5"
                                                                        placeholder="e.g. Riot Arena" name="VenueName"
                                                                        #VenueName="ngModel"
                                                                        [(ngModel)]="formDataVen.VenueName">
                                                        </span>

                                                        <span clas="stepperHeading">
                                                                Select the capacity or seating capacity of your venue.
                                                        </span>


                                                        <div class="d-flex flexWrapMob  mt-4">
                                                                <!-- <mat-button-toggle-group name="capacityGroup"
                                                                        class="swtich-btn mr-3"
                                                                        [(ngModel)]="capacityGroup"
                                                                        aria-label="Font Style">
                                                                        <mat-button-toggle value="Select"
                                                                                (click)="removeValue()">Select</mat-button-toggle>
                                                                        <mat-button-toggle value="Manual"
                                                                                (click)="removeValue()">Manual</mat-button-toggle>

                                                                </mat-button-toggle-group> -->

                                                                <!-- <input type="text" [hidden]="capacityGroup == 'Select'"
                                                                        [(ngModel)]="formDataVen.Capacity"
                                                                        name="Capacity" #Capacity="ngModel"
                                                                        class="capacityInput mr-4"> -->


                                                                <div *ngFor="let item of dataCapacityChips"
                                                                        class="stepperChips"
                                                                        (click)="selectCapcityChip(item)"
                                                                        [ngClass]="{'selected': item.value === selectedCapacityChip}">
                                                                        {{item.numbers}}
                                                                </div>



                                                        </div>

                                                </div>




                                                <div class="stepperButtonDiv mt-4 d-flex justify-content-between">
                                                        
                                                        <button type="button" (click)="scrollToPrevious('step2')"
                                                                class="stepperBtn">
                                                                Previous
                                                        </button>
                                                        <button class="stepperBtn"
                                                                [disabled]="formDataVen.VenueName == '' || formDataVen.VenueName == null  || formDataVen.Capacity == null "
                                                                (click)="scrollToNext('step4')">
                                                                Next
                                                        </button>
                                                </div>
                                        </div>
                                </div>
                        </div>
                        
                        <!-- Safwan - 02/13/2024 -->
                        <!-- <div class="Stepper" id="step7">
                                <div class="stepperOverlay"
                                        [ngStyle]="{ 'display': activeStepper == 'step7' ? 'none':'block' }">

                                </div>
                                <div class="d-flex mobnoflex">
                                        <div class="col-12 logoforMob">
                                                <div class="logoDiv">
                                                        <img src="/assets/Venue Icon.ico">
                                                </div>
                                        </div>
                                        <div class="rightWrapper">

                                                <div class="stepperHeading mb-3">
                                                        What currency you will be using?
                                                </div>
                                                <div class="stepperForm mb-3">


                                                        <div class="d-flex flexWrapMob">
                                                                <!-- <div *ngFor="let item of dataCurrency"
                                                                        class="stepperChips"
                                                                        (click)="selectCurrencyChip(item)"
                                                                        [ngClass]="{'selected': item.ID === selectedCurrencyChip}">
                                                                        <span style="color:#2FB390; font-weight: 700;">
                                                                                {{item.Symbol}} </span> -
                                                                        {{item.CurrencyName}}
                                                                </div> -- ================
                                                                <!-- <input type="text" name="CurrencyID" class="stepperInput" 
                                                                [matAutocomplete]="auto" 
                                                                (ngModelChange)="AutoCompCurrency.searchResult.next($event)" 
                                                                placeholder="Select"
                                                                [(ngModel)]="formDataVen.CurrencyID"
                                                                #CurrencyID="ngModel" >
        
                                                                <mat-autocomplete #auto="matAutocomplete"  [displayWith]="AutoCompCurrency.onDisplayValue">
                                                                        <mat-option *ngFor="let item of AutoCompCurrency.results | async" [value]="item.ID">
                                                                          {{item.CurrencyName}}
                                                                        </mat-option>
                                                                </mat-autocomplete> -- ============================= 

                                                                <mat-form-field>
                                                                        <mat-select name="CurrencyID" [disabled]="activeStepper != 'step7'"
                                                                                [(ngModel)]="formDataVen.CurrencyID"
                                                                                #CurrencyID="ngModel">
                                                                                <mat-option>
                                                                                        <ngx-mat-select-search
                                                                                                name="country"
                                                                                                #Location="ngModel"
                                                                                                [(ngModel)]="searchUserLeadCurrency"
                                                                                                (ngModelChange)="AutoCompCurrency.searchResult.next($event)"
                                                                                                placeholderLabel="Search..."
                                                                                                noEntriesFoundLabel="Not Found!"
                                                                                                [ngModelOptions]="{ standalone: true }">
                                                                                        </ngx-mat-select-search>
                                                                                </mat-option>
                                                                                <mat-option
                                                                                        *ngFor="let option of AutoCompCurrency.results | async"
                                                                                        [value]="option.ID">
                                                                                        {{ option.Symbol }} - {{ option.Country }} - {{ option.CurrencyName }}
                                                                                </mat-option>
                                                                        </mat-select>
                                                                </mat-form-field>
                                                        </div>

                                                </div>




                                                <div class="stepperButtonDiv mt-4 d-flex justify-content-between">
                                                        
                                                        <button type="button" (click)="scrollToPrevious('step6')"
                                                                class="stepperBtn">
                                                                Previous
                                                        </button>
                                                        <button class="stepperBtn"
                                                                [disabled]="formDataVen.CurrencyID == 0"
                                                                (click)="scrollToNext('step8')">
                                                                Next
                                                        </button>
                                                </div>
                                        </div>
                                </div>
                        </div> -->

                         <!-- Safwan - 02/13/2024 -->
                        <!-- <div class="Stepper" id="step8">
                                <div class="stepperOverlay"
                                        [ngStyle]="{ 'display': activeStepper == 'step8' ? 'none':'block' }">

                                </div>
                                <div class="d-flex mobnoflex">
                                        <div class="col-12 logoforMob">
                                                <div class="logoDiv">
                                                        <img src="/assets/Venue Icon.ico">
                                                </div>
                                        </div>
                                        <div class="rightWrapper">

                                                <div class="stepperHeading mb-3">
                                                        Select your favorite theme. 
                                                </div>
                                                <div class="stepperThemeRow themeRow mb-3">

                                                        <div *ngFor="let item of colors;" class="themeIcon"
                                                                (click)="selectColorChip(item)">
                                                                <div [style.background-color]="item.color"
                                                                        class="themeColorDiv"
                                                                        [ngClass]="{'selected': item.value === selectedColorChip}">
                                                                        <mat-icon
                                                                                *ngIf="item.value === selectedColorChip"
                                                                                style="color:white;">done</mat-icon>
                                                                </div>
                                                        </div>

                                                </div>




                                                <div class="stepperButtonDiv mt-4 d-flex justify-content-between">
                                                        
                                                        <button type="button" (click)="scrollToPrevious('step7')"
                                                                class="stepperBtn">
                                                                Previous
                                                        </button>
                                                        <button class="stepperBtn"
                                                                [disabled]="selectedColorChip == '' || selectedColorChip == null"
                                                                (click)="scrollToNext('step9')">
                                                                Next!
                                                        </button>
                                                </div>
                                        </div>
                                </div>
                        </div> -->

                         <!-- Safwan - 02/13/2024 -->
                        <!-- <div class="Stepper" id="step9">
                                <div class="stepperOverlay"
                                        [ngStyle]="{ 'display': activeStepper == 'step9' ? 'none':'block' }">

                                </div>
                                <div class="d-flex mobnoflex">
                                        <div class="col-12 logoforMob">
                                                <div class="logoDiv">
                                                        <img src="/assets/Venue Icon.ico">
                                                </div>
                                        </div>
                                        <div class="rightWrapper">

                                                <div class="stepperHeading mb-3">
                                                        Which option you would prefer to continue with VenueArc ?
                                                </div>
                                                <div class="stepperThemeRow themeRow mb-3">

                                                        <div class="container desktop"
                                                                style="display: flex;flex-wrap: nowrap;justify-content: space-around;">
                                                               <button disabled style="border: none;">
                                                                        <div class="card text-center" id="card1" [ngClass]="selectedCard =='card1' ? 'active-card': ''"
                                                                                (click)="selectCard('card1')">
                                                                                <img src="../../../../assets/sampledata.png" alt="" style="    height: 250px;width: 100%;">
                                                                                <span class="mt-3" style="font-size: 20px;"
                                                                                        [ngStyle]="{'font-weight':selectedCard =='card1' ? '500' :''}">Continue
                                                                                        with sample data</span>
                                                                        </div>
                                                               </button>
                                                                <div class="card text-center" id="card2"
                                                                        [ngClass]="selectedCard == 'card2' ? 'active-card': ''"
                                                                        (click)="selectCard('card2')" style="margin-left: 10px;;">
                                                                        <img src="../../../../assets/freshdata.png"
                                                                                alt=""
                                                                                style="    height: 250px;width: 100%;">
                                                                        <span class="mt-3" style="font-size: 20px;"
                                                                                [ngStyle]="{'font-weight':selectedCard =='card2' ? '500' :''}">Continue
                                                                                with the blank account</span>
                                                                </div>
                                                        </div>
                                                        <div class="container desktop" style="display: flex;flex-wrap: nowrap;justify-content: space-around;">
                                                                <h3 [class]="theme.label">
                                                                        Coming Soon...
                                                                </h3>
                                                                <h1>

                                                                </h1>
                                                        </div>

                                                        <div class="mobile  mt-3">
                                                                <div>
                                                                        <button mdbBtn [class]="theme.button" class="waves-light w-100" mdbWavesEffect id="card1" (click)="selectCard('card1')"  [ngClass]="selectedCard =='card1' ? 'active-card-mob': ''">Continue with sample data</button>
                                                                        <button mdbBtn [class]="theme.button" class="waves-light w-100" mdbWavesEffect id="card2" (click)="selectCard('card2')"  [ngClass]="selectedCard == 'card2' ? 'active-card-mob': ''">Continue with the blank</button>
                                                                </div>
                                                        </div>

                                                </div>




                                                <div class="stepperButtonDiv mt-4 d-flex justify-content-between">
                                                        
                                                        <button type="button" (click)="scrollToPrevious('step8')"
                                                                class="stepperBtn">
                                                                Previous
                                                        </button>
                                                        <button class="stepperBtn"
                                                                [disabled]="selectedCard == null || selectedCard == ''"
                                                                (click)="scrollToNext('step10')">
                                                                Next!
                                                        </button>
                                                </div>
                                        </div>
                                </div>
                        </div> -->

                        <div class="Stepper" id="step4" style="margin-bottom:350px !important;">
                                <div class="stepperOverlay"
                                        [ngStyle]="{ 'display': activeStepper == 'step4' ? 'none':'block' }">

                                </div>
                                <div class="d-flex mobnoflex">
                                        <div class="col-12 logoforMob">
                                                <div class="logoDiv">
                                                        <img src="/assets/Venue Icon.ico">
                                                </div>
                                        </div>
                                        <div class="rightWrapper">

                                                <div class="stepperHeading d-flex flex-column  mb-3">

                                                        <p>You’re all set! Have a fantastic event management experience like never before!</p>
                                                        <p>Let’s begin the journey!</p>
                                                        
                                                </div>




                                                <div class="mobile2">
                                                        <button  mdbBtn  class="waves-light w-100 mt-5" mdbWavesEffect style="background-color: green;color: white;border-radius: 28px;" (click)="onSubmit()">
                                                                Done, Get Started Now!
        
                                                               
        
                                                        </button>
        
                                                        <div class="stepperButtonDiv mt-4 d-flex justify-content-between w-100" style="display: flex !important;
                                                        justify-content: flex-end !important;">
                                                               
                                                                <button type="button" (click)="scrollToPrevious('step4')"
                                                                        class="stepperBtn w-100">
                                                                        Previous
                                                                </button>
                                                                
                                                                
                                                        </div>
        
                                                </div>

                                                <div class="desktop  d-flex justify-content-between align-items-center">
                                                      
        
                                                        
                                                               
                                                        <button type="button" (click)="scrollToPrevious('step3')"
                                                                class="stepperBtn ">
                                                                Previous
                                                        </button>
                                                        <button  mdbBtn  class="waves-light mt-5" mdbWavesEffect style="background-color: green;color: white;border-radius: 28px;" (click)="onSubmit()">
                                                                <mdb-icon fas icon="check-double"></mdb-icon>
                                                                <span class="ml-2">Done, Get Started Now!</span>
                                                        </button>
                                                                
                                                       
        
                                                </div>
                                              
                                               
                                        </div>
                                </div>
                        </div>
                      </div>

                </div>
                <div class="col-lg-3  rightDiv shadow" [class]="activeTheme">
                        
                         <a class="signout" (click)="logout()">
                                Sign Out
                        </a>
                </div>
        </div>
</div>