import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { themeService } from '../services/theme.service';

@Component({
  selector: 'app-confirm-dialoge',
  templateUrl: './confirm-dialoge.component.html',
  styleUrls: ['./confirm-dialoge.component.scss']
})
export class ConfirmDialogeComponent implements OnInit {



  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef:MatDialogRef<ConfirmDialogeComponent>,
    public theme:themeService
    ) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(){
    
  }

  closeDialoge(){
    this.dialogRef.close(false);
  }

}
