import { AfterContentChecked, AfterContentInit, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, DoCheck, ElementRef, HostListener, OnChanges, OnInit, SimpleChanges, ViewChild,  } from '@angular/core';
import { UserService } from 'src/app/Base/User/user.service';
import { FormControl } from '@angular/forms';
import { GeneralService } from 'src/app/services/general.service';
import { debounceTime, tap, switchMap, finalize, startWith, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SPOperationsService } from 'src/app/services/spoperations.service';
import { CommonService } from 'src/app/Base/Common.service';
import { SharePointConfigService } from 'src/app/Base/SharePoint/share-point-config.service';



import { Observable, Subject, timer, PartialObserver, interval, empty, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginUser } from 'src/app/Base/User/login-user';
import { ToastType } from 'src/app/Enum/ToastType';
import { MDBModalService, MDBModalRef, SidenavComponent, ModalDirective } from 'ng-uikit-pro-standard';

import * as customConfig from 'src/app/customConfig.json';
import { TokenService } from 'src/app/services/token.service';
//import { MsalService, BroadcastService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { CustomersService } from 'src/app/services/customers.service';
import { RequestEnterpriseComponent } from '../request-enterprise/request-enterprise.component';
import { MsalService } from '@azure/msal-angular';
import { EventDocumentService } from 'src/app/services/event-document.service';
import { AzblobStorageService } from 'src/app/services/azblob-storage.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { VenueService } from 'src/app/setupForms/venue/venue.service';
import { Venue } from 'src/app/models/venue.model';
import { MatDialog } from '@angular/material/dialog';
import { DummyDataComponent } from 'src/app/setupForms/dummy-data/dummy-data.component';
import { QuickEventComponent } from '../../event/quick-event/quick-event.component';
import { animate, state, style, transition, trigger } from '@angular/animations';

import * as $ from 'jquery';
import { themeService } from 'src/app/services/theme.service';
import { QuickClientMatComponent } from '../../client/quick-client-mat/quick-client-mat.component';
import { VenueDialogComponent } from 'src/app/setupForms/venue-dialog/venue-dialog.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { WelcomePageComponent } from '../welcome-page/welcome-page.component';
import { GuidedTour, GuidedTourService, Orientation } from 'ngx-guided-tour';
import { TourGuideService } from 'src/app/services/tour-guide.service';
import {RbacUsersModalComponent} from '../../../rbac/rbacUser/rbac-users-modal/rbac-users-modal.component'
import { QuickEventMdbComponent } from '../../event/quick-event-mdb/quick-event-mdb.component';
import { QuickClientComponent } from '../../client/quick-client/quick-client.component'
import { VenuePopupMdbComponent } from 'src/app/setupForms/venue/venue-popup-mdb/venue-popup-mdb.component';
import { RequestPermiumFromBasicComponent } from '../request-permium-from-basic/request-permium-from-basic.component';
import { TeamsAppService } from 'src/app/services/teams-app.service';
import { PermissionService } from 'src/app/services/permission.service';
import { EventsSearchService } from 'src/app/services/events-search.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  animations:[

    trigger('openClose',[
      state('open',style({
        width:'50%',
        display:'inline-block',
        opacity:1
      })),
      state('close',style({
        width:0,
        // display:'none',
        opacity:0
      })),
      transition('open => close',animate('0.3s')),//,style({display:'none'}))),
      transition('close => open',animate('0.3s')),
      // transition('open => close',animate('1s')),
      // transition('open <=> closed', [
      //   animate('0.5s')
      // ]),
    ]),
    trigger('slogan',[
      state('openSlogan',style({
        right:0
      })),
      transition('* => openSlogan',animate('0.5s'))
    ]),
  ]
})
export class LandingPageComponent implements OnInit,AfterViewInit{
  mobileQuery: MediaQueryList;

  @ViewChild('sidenav', { static: true }) public el: any;

  @HostListener('swiperight', ['$event']) public swipePrev(event: any) {
  this.el.show();
  }
  @ViewChild('sidenav', { static: true }) sidenav: SidenavComponent;
  @ViewChild('trial', { static: true }) trial: ModalDirective;
  title = 'Event Management';
  public searchInput = false;
  searchMoviesCtrl = new FormControl();
  EventData: any[] = [];
  isLoading = false;
  errorMsg: string;
  userManualLink: string = "";
  feedBackLink: string = "";
  settlementTamplateLink: string = "";
  contractTamplateLink: string = "";
  siteContentLink: string = "";
  public AppLogoImg = "";
  sideBar:boolean = null;
  user = LoginUser.loggedinUser;
  isAdmin:any = LoginUser.IsAdmin;
  isOwner:boolean = false;

  public modalRef: MDBModalRef;

  constructor(
    public userService: UserService,
    public genService: GeneralService,
    public router: Router,
    public sp: SPOperationsService,
    public common: CommonService,
    public spConfigService:SharePointConfigService,
    public tokenService:TokenService,
    //private authService: MsalService,
    private authenticationService :AuthenticationService,
    public customersService:CustomersService,
    public modalService: MDBModalService,
    private authService: MsalService,
    public eventDocumentService: EventDocumentService,
    public azblobStorageService: AzblobStorageService,
    public changeDetectorRef: ChangeDetectorRef,
     media: MediaMatcher,
     public venueService:VenueService,
     public permissionService:PermissionService,
     public eventsSearchService:EventsSearchService,
     public matDialog?: MatDialog,
     public theme?:themeService,
     public guidedTourService?: GuidedTourService,
     public tourService?:TourGuideService,
     public teams?:TeamsAppService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 1220px)');
   

  }

  ngAfterViewInit(): void {
        // Safwan Changes - Call the function of Theme - Start - 01/25/2023
        this.theme.getCustomerTheme();
        if(!this.mobileQuery.matches){

            // For Comment Teams App
            // this.theme.setSideNavShadow();
            // For Comment Teams App
          this.istoggleClick = true;
          // this.mouseenter();
        }
        // Safwan Changes - Call the function of Theme - End - 01/25/2023
  }

  
  intervalId: any;
  rxjsTimer = timer(1000, 1000);
  async ngOnInit() {

    this.tourService.onActionDesk.subscribe((data) => {
      if(data == true){
        this.openEventMenu(true,false);
      }
      else if(data == false){
        this.openEventMenu(true,true);
      }
    });

    
    this.tourService.onSkipDesk.subscribe((data) => {
      if(data == true){
        this.openEventMenu(true,true);
      }
    });

    

    this.rxjsTimer.pipe().subscribe(val => {
      this.intervalId = val;
    })

    //On every 25 minutes
    setInterval(() => {
      //this.callSharepointContextService();
      //this.callApiToken();
    }, 1500000); // 25 minutes
    //}, 120000); // 2 minutes

    this.searchMoviesCtrl.setValue('')
    this.searchMoviesCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.EventData = [];
          this.isLoading = true;
        }),

        switchMap((value) => this.eventsSearchService.GetEvents(value)
          .pipe(
            catchError(error =>{
              console.log(error);
              return empty;
            }),
            finalize(() => {

              this.isLoading = false
            }),
          )
        )
      )
      .subscribe(data => {
        if (data['value'] == undefined) {
          this.errorMsg = data['Error'];
          this.EventData = [];
        } else {
          this.errorMsg = "";
          this.EventData = data['value'];
        }

        console.log(this.EventData);
      },
      (error)=>{
        console.log(error);
      }
      );

    //this.sp.readConfigList().then(res => {


    await this.getVenues();
    this.getAppLogo();
    this.getOwner();
    this.getEventsLength();
   await this.getOrganizationLogo();
    // Safwan Changes - Call the function of Theme - Start - 01/25/2023
    this.theme.getCustomerTheme();
        // For Comment Teams App
        // if(!this.mobileQuery.matches)
        // this.theme.setSideNavShadow()
        // For Comment Teams App
    // Safwan Changes - Call the function of Theme - End - 01/25/2023

    //this.getFeedBackLink();
    //this.getUserManualLink();
    //this.getSettlementLink();
    //this.getContractTemplateLink();
    //this.getSiteContentsLink();
    //this.sp.getUTCTimeZoneInfo();


  }


  showSwitch:boolean = false;

  getTenants(tid:any, email:any) {
    this.customersService.getTenants(tid, email).toPromise().then(res => {
      if(res){
        var data:any[] = res as any[];
        if(data.length > 0){
          this.showSwitch = true;
        }
      }
    })
  }

  switchSubscription(){
    var curr_user = this.authenticationService.CurrentUser();
    var tid:string = "";
    if(curr_user != null && curr_user != undefined){
      tid = curr_user.tid;
    }

    this.router.navigate(['/select-tenant'], { queryParams: { ops: "switch", tid: tid} })
  }


  ApiCallCount:number = 0;
  callSharepointContextService(){
    //after u get data
    this.common.ShowSpinner();
    this.sp.getContext().then(res=>{
      this.common.HideSpinner();
      this.ApiCallCount++;

    });
  }


  getUserManualLink(){
    this.spConfigService.getRecordByTitleAsync("UserManual").then(res=>{
      this.userManualLink += res[0].ListUrl;
    });

  }

  getFeedBackLink(){
    this.spConfigService.getRecordByTitleAsync("Feedback").then(res=>{
      this.feedBackLink += res[0].ListUrl;
    });

  }

  getSettlementLink(){
    this.spConfigService.getRecordByTitleAsync("SettlementTemplates").then(res=>{
      this.settlementTamplateLink += res[0].ListUrl;
    });

  }

  getContractTemplateLink(){
    this.spConfigService.getRecordByTitleAsync("ContractTemplates").then(res=>{
      this.contractTamplateLink += res[0].ListUrl;
    });

  }

  getSiteContentsLink(){
    this.spConfigService.getRecordByTitleAsync("SiteContent").then(res=>{
      this.siteContentLink += res[0].ListUrl;
    });

  }


  getAppLogo() {
    this.AppLogoImg = customConfig.logourl;
    /*
    this.spConfigService.getRecordByTitleAsync("AppLogo").then(res=>{
      this.AppLogoImg += res[0].ListUrl;
    });
    */
  }

  onEventClick(eventID) {
    this.router.navigate(['/event/portal'], { queryParams: { ID: eventID } })
    this.searchMoviesCtrl.setValue('')
  }

  logout() {

    this.tokenService.removeToken();
    this.authenticationService.logout();
    // this.authService.logoutRedirect({
    //   postLogoutRedirectUri: customConfig.callbackPathBase // 'http://localhost:4200'
    // });
    /*
    this.tokenService.removeToken();
    LoginUser.loggedinUser.UserID = null;
    LoginUser.loggedinUser.UserName = null;
    LoginUser.loggedinUser.Email = null;
    LoginUser.loggedinUser.ShortCode = null;
    LoginUser.IsAdmin = false;
    this.authenticationService.logout();
    */
    //this.authService.logout();

    /*
    this.genService.logout().then(res => {
      this.tokenService.removeToken();
        LoginUser.loggedinUser.UserID = null;
        LoginUser.loggedinUser.UserName = null;
        LoginUser.loggedinUser.Email = null;
        LoginUser.loggedinUser.ShortCode = null;
        LoginUser.IsAdmin = false;
      //this.authenticate.user =  new RBAC_User();
      //this.authenticate.isLoggedIn = false;
      // this.router.navigate(['/login']);
      //this.router.navigate(['/app/home']);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/login'])
      );
    }, error => {
      console.log(error);
    })
    */
  }

  upgrade(){
    if(this.user?.SubscriptionType == 'stripe'){
    // Safwan 05/02/2023
      if(this.user.IsFreeTrial == true){
        this.router.navigate(['/membership/subscribe']);
      }
    // Safwan 05/02/2023

      else if(this.user?.Subscription == 'free'){
        this.router.navigate(['/membership/subscribe']);
      }
    }
    else if(this.user?.SubscriptionType == '' || this.user?.SubscriptionType == null ){
      if(this.user?.Subscription == 'free'){
        this.router.navigate(['/membership/subscribe']);
      }
    }
  }

  upgradetoenterprise(){
    this.modalRef = this.modalService.show(RequestEnterpriseComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-xl modal-content modal-dialog cascading-modal modal-top',
      containerClass: '',
      animated: true,
      styles: "overflow-y: auto",
    });
  }

  upgradeBasicToPremium(){
    this.modalRef = this.modalService.show(RequestPermiumFromBasicComponent,{
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-xl modal-content modal-dialog cascading-modal modal-top',
      containerClass: '',
      animated: true,
      styles: "overflow-y: auto",
    })
  }

  getOwner(){
  this.isOwner =   this.authenticationService.CurrentUser().isowner;
  var tid =   this.authenticationService.CurrentUser().tid;
  var email =   this.authenticationService.CurrentUser().email;
  this.getTenants(tid, email);

  }
  // sub: Subscription;
  // countDown;
  // count;

  // myTimer() {
  //   this.count = 10;


  //   this.countDown = Observable.timer(0, 1000)
  //     .subscribe(x => {
  //       this.count = this.count - 1;
  //     });

  //   this.sub = Observable.interval(500)
  //     .subscribe(x => {
  //       console.log(this.count);
  //       if (this.count === 0) {
  //         this.countDown.unsubscribe();
  //       }
  //     });
  // }
  showImage:any=null
  async getOrganizationLogo() {
    await this.eventDocumentService.getAll('system/logo').toPromise().then((res: any) => {
      if (res.length > 0) {
        this.showImage = this.azblobStorageService.getFileLocation(res);
      }
    })
  }


  searchbar:boolean = false;
  openSearch(){
    this.searchbar = !this.searchbar;
  }

 async getVenues(){
  // this.common.isLoadingVenue = true;
  await this.venueService.getVenues().then(
    res => {
      if( res['value'].length == 1){
        this.common.isSingleVenueExist = true;
        // this.common.isLoadingVenue = false;
      }
      else{
        this.common.isSingleVenueExist = false;
        // this.common.isLoadingVenue = false;
        }

        this.common.HideSpinner();
      }
      , error => {
        this.common.HideSpinner()
        this.common.ShowToast(error.message, ToastType.Error);
      }
    )
  }

 async openDummyEvents(){
    const dialogQuick = await this.matDialog.open(DummyDataComponent, {
      // disableClose: true ,
      height: '88%',
      maxHeight: '100%',
      width: '50%',
      minWidth: '52%',
      panelClass: 'dialog-panel',
      position: {
        right: 10 + '%',
        top: 2 + '%',
        left: 24 + '%',
        bottom: 0 + '%',
      },
      autoFocus: false,
    })

     dialogQuick.afterClosed().subscribe(res=>{

     })
  }

  async openQuickEventForm(){
    // const dialogQuick = await this.matDialog.open(QuickEventComponent, {
    //   // disableClose: true ,
    //   height: '88%',
    //   maxHeight: '100%',
    //   width: '50%',
    //   minWidth: '52%',
    //   panelClass: 'dialog-panel',
    //   position: {
    //     right: 10 + '%',
    //     top: 2 + '%',
    //     left: 24 + '%',
    //     bottom: 0 + '%',
    //   },
    //   autoFocus: false,
    // })
    // dialogQuick.afterOpened().subscribe(res=>{this.theme.setTheme();});
    

    //  dialogQuick.afterClosed().subscribe(res=>{
    //    if(res.status == "Submit"){
    //       if(this.router.url.includes('calendar')){
    //         // this.router.navigate(['/calendar']);
    //         this.router.navigate(['/calendar'], { queryParams: { viewDate: res.date } })
    //       }
    //     }
    //  })
    this.modalRef = this.modalService.show(QuickEventMdbComponent, {
      // backdrop: false,
      // keyboard: false,
      // focus: true,
      // show: false,
      // ignoreBackdropClick: false,
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class:  'modal-side modal-full-height modal-right modal-lg',
      containerClass: 'right',
      animated: true,
      styles: "overflow-y: none",


    });

    this.modalRef.content.action.subscribe(result=>{
      if(result.status == "Submit"){
        if(this.router.url.includes('calendar')){
          // this.router.navigate(['/calendar']);
          this.router.navigate(['/calendar'], { queryParams: { viewDate: result.date } });
          this.theme.getCustomerTheme();
        }
      }
    })
  }


  closeAlert() {
    this.theme.hideFreeSolgan = true;

  }
  istoggleClick:boolean=null;
      // Safwan Changes - Add these function for sidenav customizing style  - Start - 01/25/2023
      setSideNav() {
        if (!this.mobileQuery.matches) {
          if (this.sideBar == null || this.sideBar == false) {
            this.sideBar = true;
        this.istoggleClick = true;
        // Comment for teams app
        // $('.side-nav').attr('style', 'width:4rem !important;transform:translateX(0%) !important; cursor:pointer;box-shadow: '+this.theme.rgbColor+' 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px !important;');
        // Comment for teams app
        // $('.accordion .card.is-collapsed .card-header a .rotate-icon').attr('style', 'display:none !important');

      }
      else {
        // $('.accordion .card.is-collapsed .card-header a .rotate-icon').attr('style', 'display:inline !important');
        // Comment for teams app
        // $('.side-nav').attr('style', 'width:15rem !important;transform:translateX(0%) !important; box-shadow: '+this.theme.rgbColor+' 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px !important;');
        // Comment for teams app
        this.sideBar = false
        this.istoggleClick = false;

      }

    }
    else {
      // Comment for teams app
      // $('.side-nav').attr('style', 'width:15rem !important;transform:translateX(0%) !important;');
      this.sideBar = false;

    }
  }
  mouseenter() {
    if (this.istoggleClick == true) {
      if (!this.mobileQuery.matches) {

        $('.accordion .card.is-collapsed .card-header a .rotate-icon').attr('style', 'display:inline !important');
        $('.side-nav').attr('style', 'width:15rem !important;transform:translateX(0%) !important;box-shadow: '+this.theme.rgbColor+' 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px !important;');
        this.sideBar = false;
        this.istoggleClick = false;
      }
    }
  }

  mouseleave() {
    if (this.istoggleClick == true) {
      if (!this.mobileQuery.matches) {
        if (this.sideBar == null || this.sideBar == false) {
          this.sideBar = true;
          $('.side-nav').attr('style', 'width:4rem !important;transform:translateX(0%) !important;box-shadow: '+this.theme.rgbColor+' 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px !important;');
          $('.accordion .card.is-collapsed .card-header a .rotate-icon').attr('style', 'display:none !important');

        }
      }
    }
  }
  // Safwan Changes - Add these function for sidenav customizing style  - End - 01/25/2023

  async openQuickClientForm(){
    // const dialogQuick = await this.matDialog.open(QuickClientMatComponent, {
    //   // disableClose: true ,
    //   height: '42%',
    //   maxHeight: '100%',
    //   width: '40%',
    //   minWidth: '40%',
    //   panelClass: 'dialog-panel',
    //   position: {
    //     right: 10 + '%',
    //     top: 11 + '%',
    //     left: 30 + '%',
    //     bottom: 0 + '%',
    //   },
    //   autoFocus: false,
    // })
    // dialogQuick.afterOpened().subscribe(res=>{this.theme.setTheme();});
    

    //  dialogQuick.afterClosed().subscribe(res=>{
    //    if(res == "Submit"){
    //       // if(this.router.url.includes('calendar')){
    //       //   this.router.navigate(['/calendar']);
    //       // }
    //     }
    //  })
    this.modalRef = this.modalService.show(QuickClientComponent, {
      // backdrop: false,
      // keyboard: false,
      // focus: true,
      // show: false,
      // ignoreBackdropClick: false,
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class:  'modal-side modal-full-height modal-right ',
      containerClass: 'right',
      animated: true,
      styles: "overflow-y: none",


    });

    this.modalRef.content.action.subscribe(result=>{
      if(result !=null){
        this.theme.getCustomerTheme();
      }
    })
  }

  async openQuickVeuneForm(){
    // const dialogQuick = await this.matDialog.open(VenueDialogComponent, {
    //   // disableClose: true ,
    //   height: '48%',
    //   maxHeight: '100%',
    //   width: '40%',
    //   minWidth: '40%',
    //   panelClass: 'dialog-panel',
    //   position: {
    //     right: 10 + '%',
    //     top: 11 + '%',
    //     left: 30 + '%',
    //     bottom: 0 + '%',
    //   },
    //   autoFocus: false,
    // })
    // dialogQuick.afterOpened().subscribe(res=>{this.theme.setTheme();});
    

    //  dialogQuick.afterClosed().subscribe(res=>{
    //    if(res == "Submit"){
    //       // if(this.router.url.includes('calendar')){
    //       //   this.router.navigate(['/calendar']);
    //       // }
    //     }
    //  })
    this.modalRef = this.modalService.show(VenuePopupMdbComponent, {
      // backdrop: false,
      // keyboard: false,
      // focus: true,
      // show: false,
      // ignoreBackdropClick: false,
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class:  'modal-side modal-full-height modal-right ',
      containerClass: 'right',
      animated: true,
      styles: "overflow-y: none",


    });

    this.modalRef.content.action.subscribe(result=>{
      if(result == "Submit"){
          if(this.router.url.includes('calendar')){
            this.router.navigate(['/calendar']);
            this.theme.getCustomerTheme();
          }
        }
    })
  }

  openEventForm(){
    this.router.navigate(['/event/event/form'])
  }

  async  openWelcomePage(){
    const dialogRef = await this.matDialog.open(WelcomePageComponent, {
      disableClose: true ,
      // height: '100%',
      // maxHeight: '100%',
      // width: '100%',
      // minWidth: '60%',
       height:'285px',
      autoFocus:true,
      hasBackdrop:true,
      data:{
        onClicked:(isClicked,isLastStep) => this.openEventMenu(isClicked,isLastStep)
      }
      // maxWidth:'47vw',
      // position:{
      //   left:'50%'
      // }
      // panelClass:'welcome-dialog'
    })

  }

  @ViewChild('eventTrigger') eventTrigger:MatMenuTrigger;

 
  quickTour() {
    this.changeDetectorRef.detectChanges();
    // this.guidedTourService.startTour(this.dashboardTour);
    this.tourService.showTour(this.tourService.deskTour);
    this.changeDetectorRef.detectChanges();
  }

  openEventMenu(isClicked:boolean,isLastStep:boolean){
    if(isClicked == true && isLastStep == false){
      
      this.eventTrigger.menu.hasBackdrop = false;
      this.eventTrigger.openMenu();
    }
    else if(isClicked == true && isLastStep == true){
      this.eventTrigger.menu.hasBackdrop = true;
      this.eventTrigger.closeMenu();
    }
  }
  
  ifEventExist:boolean=null;
  async getEventsLength() {
    // Safwan 05/02/2023
    // if( (this.user?.Subscription == 'free' || this.user.IsFreeTrial == true) && this.isAdmin == true && this.isOwner == true && this.theme.hideFreeSolgan == false){
    //   this.trial.show();
    // }
    // Safwan 05/02/2023
    this.genService.GetDataByQuery("Events?$select=ID&$top=1&$filter=IsDeleted ne false").then(res => {
      var ev = res['value'] as Event[];
      if(ev.length > 0){
        this.ifEventExist = true;
      }
      else{
        this.ifEventExist = false;
        if(this.theme.skipWelcomePage == false){
           this.openWelcomePage();
          }
      }
    })
  }

  async addUser() {
    const dialogQuick = await this.matDialog.open(RbacUsersModalComponent, {
      // disableClose: true ,
      height: '55%',
      maxHeight: '100%',
      width: '55%',
      minWidth: '52%',
      panelClass: 'dialog-panel',
      position: {
        right: 10 + '%',
        top: 11 + '%',
        left: 24 + '%',
        bottom: 0 + '%',
      },
      autoFocus: false,
    })
  }

  onNewSigning(){
    this.router.navigate(['newsignin']);
  }
}
