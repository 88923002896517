import { Injectable } from '@angular/core';
import { Genre } from 'src/app/setupForms/genre/genre.model';
import { GenericService } from 'src/app/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { GeneralService } from './general.service';
import * as customConfig from 'src/app/customConfig.json';
import { HelpService } from './help.service';

@Injectable({
  providedIn: 'root'
})

export class GenreService {

  constructor(private helpService:HelpService) { }


  async getGenreList(){
   return await this.helpService.GetDataByHelpNo("hlpgenrelist");
  }

  async getGenreDrp(){
   return await this.helpService.GetDataByHelpNo("hlpgenredrp");
  }

}
