<div class="container-fluid bg-frm">
    <div class="d-flex align-items-center bdy2">
        <div class="box">
            <div class="text-center">
                <img src="assets/venue_arc_singup.png" alt="Logo" class="logo">
            </div>

            <div class="text-center">
                <mat-icon class="sessionIcon">alarm</mat-icon>
              </div>
        
            <div class="text-center">
                <div class="pt-2 pb-3 text-center">
                    <h2 class="sessionText">
                        Free Trial Expired
                    </h2>
                    <!-- <p class="subSessionText">Your free trial has expired. Upgrade to our Premium plan and get more additional features</p> -->
                    <p class="subSessionText">
                        Please <a routerLink="/membership/subscribe" style="font-weight: 500;">Upgrade</a> to the full version to continue using the application
                    </p>
                    
                </div>

                <!-- <div class="row" *ngIf="IsshowProgress">

                    <div class="col-md-12">
                        <div class="d-flex flex-column justify-content-center">
                            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                            <br>
                            <p class="text-center"> <i> Please wait. This will take few seconds. </i></p>
                        </div>
                    </div>


                </div> -->
                <div class="">
                    <button mdbBtn  class="waves-light w-75" mdbWavesEffect type="button" style="color: white;background: #223f81;border-radius: 24px !important;" (click)="logout()">
                        <mdb-icon fas icon="sign-out-alt"></mdb-icon>
                        <span class="pl-2"> Log out </span>
                      </button>
                </div>
            </div>
        </div>
    </div>
</div>