import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

import { JwtHelper } from 'src/app/helpers/jwt-helper';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { UserManagerService } from 'src/app/services/user-manager.service';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { CustomersService } from 'src/app/services/customers.service';
import { MessageDialogeService } from 'src/app/message-dialoge/message-dialoge.service';
import { CommonService } from 'src/app/Base/Common.service';
import { TokenService } from 'src/app/services/token.service';
import { TokenUser } from 'src/app/models/tokenuser.model';
import { EmailSenderService } from 'src/app/services/email-sender.service';


@Component({
  selector: 'app-signup-redirect',
  templateUrl: './signup-redirect.component.html',
  styleUrls: ['./signup-redirect.component.scss']
})
export class SignupRedirectComponent implements OnInit {

  constructor(
    private jwtHelper: JwtHelper,    
    private authService: AuthenticationService,
    private userManagerService:UserManagerService,
    private router: Router,
    public customersService:CustomersService,
    public messageDialog: MessageDialogeService,
    private commonService: CommonService,    
    private tokenService: TokenService,
    private emailSender:EmailSenderService    
  ) { 

  }


  ngOnInit(): void {
    debugger;    

    // Decode the token to use it later
    var token = null;
    var token_decoded = null;
   
    var url: string = window.location.href;
    if (url.includes("#id_token=")) {
        token = this.tokenService.getId_Token(url);
        token_decoded = this.jwtHelper.decodeToken(token)
       if(token_decoded.ownerId){
          localStorage.setItem("ownerId", token_decoded.ownerId);
       }
    }
    
    /*
    if(localStorage.getItem("userregistered")){
      localStorage.removeItem("userregistered");
      this.router.navigate(["signin"]);
      return;
    }
    */

    this.commonService.ShowSpinner();
    this.processSignup(token_decoded, token);
  }

  async processSignup(decoded, token) {
    debugger;

    // check if tenant id exists in token
    if (decoded.tid) {
      // check if owner id exists in token
      if (decoded.ownerId) {

        // get owner id
        var ownerId = null;
        ownerId = String(decoded.ownerId);

        // Save user in local stoarge
        var objUser: TokenUser = new TokenUser();
        objUser.Id = decoded.sub;
        objUser.OwnerId = ownerId;
        objUser.tid = decoded.tid;
        objUser.token = token;
        objUser.email = decoded.email;
        objUser.sessionTimeout = this.tokenService.sessionTimeout();
        this.authService.login(objUser);

        // Set object to post data
        var obj = {
          ownerId: ownerId,
          userObjectId: decoded.sub,
          userEmail: decoded.email
        }

        // Save Owner ID in Azure ADB2C
        await this.userManagerService.postNew(obj).toPromise().then(async res => {
          debugger;
          //this.emailSender.sendWelcome();
          this.commonService.HideSpinner();
          localStorage.setItem("userregistered", "true");
          this.router.navigate(["calendar"]);
        }, error => {
          console.log(error);
        })

      }
      else {
        this.router.navigate([""]);
      }
    }

  }

  /*
  async  processSignup(routefragment : ActivatedRouteSnapshot) {
     debugger;
     localStorage.setItem("fragment", routefragment.fragment);
 
     
     const fragment = routefragment.fragment ? routefragment.fragment : "dummyfragment";
     let token = "";
     if(fragment.includes('session_state') == false)
       token = fragment.substring(this.tokenIdentifier.length, fragment.length);
     else 
       token = fragment.split('&')[0].split("&")[0].substring(this.tokenIdentifier.length, fragment.split('&')[0].split("&")[0].length);
 
     const decoded = this.jwtHelper.decodeToken(token);
 
     if (decoded.tid) {

       if (decoded.ownerId)
       {
         if (decoded.ownerId) {
 
           var ownerId = null;
           ownerId = String(decoded.ownerId);
           var obj = {
             ownerId: ownerId,
             userObjectId: decoded.sub,
             userEmail: decoded.email
           }
 
           //User code
           var objUser: TokenUser = new TokenUser();
           objUser.Id = decoded.sub;
           objUser.OwnerId = ownerId;
           objUser.tid = decoded.tid;
           //objUser.Name = decoded.name;
           objUser.token = token;
           objUser.email = decoded.email;
           //objUser.sessionTimeout = this.sessionTimeout();
           this.authService.login(objUser);
 
           await this.userManagerService.postNew(obj).toPromise().then(async res => {
            debugger;
            this.commonService.HideSpinner();
             localStorage.removeItem("fragment");
             this.authService.logout();
             localStorage.setItem("userregistered", "true");
             this.authService.setCurrentToken(token);
             setInterval(() => {
              this.loginWithName(objUser.email);
             }, 5000); // 25 minutes
             //}, 5000); // 2 minutes

             
             //this.router.navigate(["/calendar"]);
 
           }, error => {
             console.log(error);
           })
         }
         else {
           this.router.navigate([""]);
         }
       }
       
     }
 
   }
 
   */

  
/*
  loginWithName(email:string){

    debugger;
    var authRequestConfig= {
      loginHint : email,
      redirectUri: customConfig.redirectUri //"http://localhost:4200/signin",
    };


    this.msalAuthService.loginRedirect(authRequestConfig as RedirectRequest);
  }

  mylogin(token:string){

    

    var authRequestConfig= {
      authority: "https://varctest.b2clogin.com/varctest.onmicrosoft.com/B2C_1A_SIGNUP_INVITATION",      
      redirectUri: customConfig.callbackPathBase + "/registeraccount", //"http://localhost:4200/registeraccount",
      scopes:["openid"],
      
      extraQueryParameters : {'id_token_hint' : token}

    };

    
    this.msalAuthService.loginRedirect(authRequestConfig as RedirectRequest);

    // let abc: RedirectRequest;
    // abc.authority = "https://varctest.b2clogin.com/varctest.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_SIGNUP_INVITATION";
    // abc.redirectUri = "http://localhost:4200/registeraccount",
    // abc.extraQueryParameters = {id_token_hint : 'your_id_token_hint'}
    
  }
  */

}




