<div class="header justify-content-between align-items-center mb-0 blue-gradient d-flex">
  <h4 class="h4-responsive" style="color: White;margin-bottom: 0 !important;">
    Dummy Data
  </h4>
  <div class="btn-group" role="group" aria-label="Basic example">
    <button mat-dialog-close mat-icon-button aria-label="Example icon button with a heart icon" style="color:white;">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<!-- <div class="modal-body mb-0 "> -->

<div class="container-fluid mt-4">
  <div class="row">
    <div class="col-md-12 mb-3">
      <h4>
        Venue
      </h4>
    </div>
    <div class="col-md-12">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Venue Name</th>
            <th>Short Code</th>
            <th>Capacity</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of VenueData">
            <td>{{row['VenueName']}}</td>
            <td>{{row['ShortCode']}}</td>
            <td>{{row['Capacity']}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <hr class="mt-4">
  <div class="row mt-3">
    <div class="col-md-12 mb-3">
      <h4>
        Client
      </h4>
    </div>
    <div class="col-md-12">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Client Name</th>
            <th>Client Contact</th>
            <th>Client Email</th>
            <th>Contact Person Name</th>
            <th>Contact Person Email</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of clientData">
            <td>{{row['ClientName']}}</td>
            <td>{{row['Phone']}}</td>
            <td>{{row['Email']}}</td>
            <td>{{row.Client_ContactPerson[0]['ContactPersonName']}}</td>
            <td>{{row.Client_ContactPerson[0]['Email']}}</td>            
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <hr class="mt-4">
  <div class="row mt-3">
    <div class="col-md-12 mb-3">
      <h4>
        Events
      </h4>
    </div>
    <div class="col-md-12">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Event ID</th>
            <th>Event Name</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Status</th>
            <th>Venue</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of events">
            <td>{{row['EntryNo']}}</td>
            <td>{{row['EventName']}}</td>
            <td>{{row['EventStartDate'] | date:'MM/d/yyyy'}}</td>
            <td>{{row['EventEndDate'] | date:'MM/d/yyyy'}}</td>
            <td>{{row['Status']}}</td>            
            <td>{{row['VenueName']}}</td>            
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <hr class="mt-4">
  <div class="row">
    <div class="col-md-10"></div>
    <div class="col-md-2 text-right">
      <button mat-raised-button color="primary">Submit</button>
    </div>
  </div>
</div>