import { Venue } from './venue.model';


export class VenueMonthNotes {
    ID: number = 0;
    VenueID: number = null;
    Month:any = null;
    Notes:string = null;
    CreatedBy: number = null;
    CreatedDate: Date = null;
    ModifiedBy: number = null;
    ModifiedDate: Date = null;
   // Venue: Venue = new Venue();
}


