import { Event_Dates } from './Event_Dates.model';
import { Event_Days } from './Event_Days.model';
import { Event_SubEvent } from './Event_SubEvent.model';

export class Event {
    ID: number = 0;
    EntryNo:string = null;
    EventName: string = null;
    EventStartDate: any = null;
    EventEndDate: any = null;
    VenueID: number = null;
    ClientID: number = null;
    SignatoryID: number = null;
    ContactPersonID: number = null;
    AdditionalContactPersonID: number = null;
    GenreID: number = null;
    EventTypeID: number = null;
    CategoryID: number = null;
    Attendance: number = null;
    IsSecured: boolean = null;
    NoOfDays: number = null;
    NoOfPerformances: number = null;
    IsReschedule: boolean = null;
    RescheduleReason: string = null;
    OriginalEventStartDate: any = null;
    OriginalEventEndDate: any = null;
    RescheduleSub: string
    CreatedBy: number = null;
    CreatedDate: Date = null;
    ModifiedBy: number = null;
    ModifiedDate: Date = null;
    Event_Dates:Event_Dates[] = [];
    Event_SubEvent:Event_SubEvent[] = [];
    Event_Days:Event_Days[] = [];
    IsSettlement: boolean = null;
    IsDiscountApplied:boolean = null;
    IsBooked:boolean = null;
    IsUploadedFromOther:boolean = null;
    Scode:string = null;
}
