import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { empty } from 'rxjs';
import { catchError, debounceTime, finalize, switchMap, tap } from 'rxjs/operators';
import { CommonService } from 'src/app/Base/Common.service';
import { LoginUser } from 'src/app/Base/User/login-user';
import { RbacUsersModalComponent } from 'src/app/rbac/rbacUser/rbac-users-modal/rbac-users-modal.component';
import { GeneralService } from 'src/app/services/general.service';
import { WelcomePageComponent } from '../../../main/welcome-page/welcome-page.component'
import { themeService } from 'src/app/services/theme.service';
import { VenuePopupMdbComponent } from 'src/app/setupForms/venue/venue-popup-mdb/venue-popup-mdb.component';
import { RequestEnterpriseComponent } from '../../../main/request-enterprise/request-enterprise.component';
import { TokenService } from 'src/app/services/token.service';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { TeamsAppService } from 'src/app/services/teams-app.service';
import { CustomersService } from 'src/app/services/customers.service';

import { MatMenuTrigger } from '@angular/material/menu';
import { TourGuideService } from 'src/app/services/tour-guide.service';
import { QuickClientComponent } from 'src/app/modules/client/quick-client/quick-client.component';
import { ToastType } from 'src/app/Enum/ToastType';
import { Customer } from 'src/app/models/customer.model';
import { ConfirmDialogeService } from 'src/app/confirm-dialoge/confirm-dialoge.service';
import { EmailSenderService } from 'src/app/services/email-sender.service';
import { EventFormTourComponent } from 'src/app/modules/event/event-form-tour/event-form-tour.component';
import * as stripeConfig  from '../../../../stripeConfig.json'
import { ContractSetupComponent } from 'src/app/modules/event/contract-setup/contract-setup.component';
import { PermissionService } from 'src/app/services/permission.service';
import { EventsSearchService } from 'src/app/services/events-search.service';
import { Orientation } from 'ngx-guided-tour';


@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        width: '50%',
        display: 'inline-block',
        opacity: 1
      })),
      state('close', style({
        width: 0,
        // display:'none',
        opacity: 0
      })),
      transition('open => close', animate('0.3s')),
      transition('close => open', animate('0.3s')),
    ]),
  ]
})
export class TopBarComponent implements OnInit {

  //#region For side-nav toggle
  @Output() navToggleClick: EventEmitter<any> = new EventEmitter();

  sideNavToggle() {
    this.navToggleClick.emit();
  }
  //#endregion

  user = LoginUser.loggedinUser;
  searchMoviesCtrl = new FormControl();
  EventData: any[] = [];
  isLoading = false;
  errorMsg: string;
  isOwner: boolean = false;

  isAdmin:any = LoginUser.IsAdmin;
  isSampleData:boolean = null;
  customerPortallink:string =  stripeConfig.stripeCustomerPortal;

  public modalRef: MDBModalRef;
  constructor(
    public genService: GeneralService,
    public router: Router,
    public common: CommonService,
    public matDialog: MatDialog,
    public modalService: MDBModalService,
    public theme: themeService,
    public tokenService: TokenService,
    private authenticationService: AuthenticationService,
    public teams: TeamsAppService,
    public customersService: CustomersService,
    public tourService:TourGuideService,
    public changeDetectorRef: ChangeDetectorRef,    
    public dialog: ConfirmDialogeService,
    public email:EmailSenderService,
    public permissionService:PermissionService,
    public eventsSearchService:EventsSearchService
  ) { }

  async ngOnInit() {

   //#region For Tour of mob and desktop
        this.tourService.onActionDesk.subscribe((data) => {
          if (data == true) {
            this.openDeskEventMenu(true, false);
          }
          else if (data == false) {
            this.openDeskEventMenu(true, true);
          }
        });


        this.tourService.onSkipDesk.subscribe((data) => {
          if (data == true) {
            this.openDeskEventMenu(true, true);
          }
        });

        this.tourService.onActionMob.subscribe((data) => {
          if (data == true) {
            this.openMobEventMenu(true, false);
          }
          else if (data == false) {
            this.openMobEventMenu(true, true);
          }
        });


        this.tourService.onSkipMob.subscribe((data) => {
          if (data == true) {
            this.openMobEventMenu(true, true);
          }
        });
   //#endregion


    //#region For seacrh of top
    this.searchMoviesCtrl.setValue('')
    this.searchMoviesCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.EventData = [];
          this.isLoading = true;
        }),
        // EV.ID ,EV.EventName ,EV.EventStartDate,EV.EventEndDate, V.VenueName ,
        switchMap((value) => this.eventsSearchService.GetEvents(value)
          .pipe(
            catchError(error => {
              console.log(error);
              return empty;
            }),
            finalize(() => {

              this.isLoading = false
            }),
          )
        )
      )
      .subscribe(data => {
        if (data['value'] == undefined) {
          this.errorMsg = data['Error'];
          this.EventData = [];
        } else {
          this.errorMsg = "";
          this.EventData = data['value'];
        }

        console.log(this.EventData);
      },
        (error) => {
          console.log(error);
        }
      );
    //#endregion

      this.getOwner();
      await this.getCustomer();
  }


  //#region For search of top 
  onEventClick(eventID) {
    this.router.navigate(['/event/portal'], { queryParams: { ID: eventID } })
    this.searchMoviesCtrl.setValue('')
  }

  searchbar: boolean = false;
  openSearch() {
    this.searchbar = !this.searchbar;
  }
  //#endregion


  async addUser() {
    const dialogQuick = await this.matDialog.open(RbacUsersModalComponent, {
      // disableClose: true ,
      height: '55%',
      maxHeight: '100%',
      width: '55%',
      minWidth: '52%',
      panelClass: 'dialog-panel',
      position: {
        right: 10 + '%',
        top: 11 + '%',
        left: 24 + '%',
        bottom: 0 + '%',
      },
      autoFocus: false,
    })
  }

  openEventForm() {
    this.router.navigate(['/event/event/form'])
  }

  async openQuickClientForm() {
    this.modalRef = this.modalService.show(QuickClientComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-side modal-full-height modal-right ',
      containerClass: 'right',
      animated: true,
      styles: "overflow-y: none",


    });

    this.modalRef.content.action.subscribe(result => {
      if (result != null) {
        
      }
    })
  }

  async openQuickVeuneForm() {

    this.modalRef = this.modalService.show(VenuePopupMdbComponent, {

      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-side modal-full-height modal-right ',
      containerClass: 'right',
      animated: true,
      styles: "overflow-y: none",


    });

    this.modalRef.content.action.subscribe(result => {
      if (result == "Submit") {
        if (this.router.url.includes('calendar')) {
          this.router.navigate(['/calendar']);
          
        }
      }
    })
  }


  upgradetoenterprise() {
    this.modalRef = this.modalService.show(RequestEnterpriseComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-xl modal-content modal-dialog cascading-modal modal-top',
      containerClass: '',
      animated: true,
      styles: "overflow-y: auto",
    });
  }

  upgrade() {
    if (this.user?.SubscriptionType == 'stripe') {
      // Safwan 05/02/2023
      if (this.user.IsFreeTrial == true) {
        this.router.navigate(['/membership/subscribe'],{queryParams:{b:1}});
      }
      // Safwan 05/02/2023

      else if (this.user?.Subscription == 'free') {
        this.router.navigate(['/membership/subscribe'],{queryParams:{b:1}});
      }
    }
    else if (this.user?.SubscriptionType == '' || this.user?.SubscriptionType == null) {
      if (this.user?.Subscription == 'free') {
        this.router.navigate(['/membership/subscribe'],{queryParams:{b:1}});
      }
    }
  }

  logout() {
    localStorage.removeItem("onboardingstatus");
    this.theme.removeThemeToken();
    this.tokenService.removeToken();
    this.authenticationService.logout();
  }

  getOwner() {
    this.isOwner = this.authenticationService.CurrentUser().isowner;
    var tid = this.authenticationService.CurrentUser().tid;
    var email = this.authenticationService.CurrentUser().email;
    this.getTenants(tid, email);

  }

  showSwitch: boolean = false;
  getTenants(tid: any, email: any) {
    this.customersService.getTenants(tid, email).toPromise().then(res => {
      if (res) {
        var data: any[] = res as any[];
        if (data.length > 0) {
          this.showSwitch = true;
        }
      }
    })
  }


  ifEventExist: boolean = null;
  async getEventsLength() {
    // Safwan 05/02/2023
    // if( (this.user?.Subscription == 'free' || this.user.IsFreeTrial == true) && this.isAdmin == true && this.isOwner == true && this.theme.hideFreeSolgan == false){
    //   this.trial.show();
    // }
    // Safwan 05/02/2023
    this.genService.GetDataByQuery("Events?$select=ID&$top=1&$filter=IsDeleted ne false").then(res => {
      var ev = res['value'] as Event[];
      if (ev.length > 0) {
        this.ifEventExist = true;
      }
      else {
        this.ifEventExist = false;
        if (this.theme.skipWelcomePage == false) {
          this.openWelcomePage();
        }
      }
    })
  }

  async  openWelcomePage(){
    const dialogRef = await this.matDialog.open(WelcomePageComponent, {
      disableClose: true ,
      // height: '100%',
      // maxHeight: '100%',
      // width: '100%',
      // minWidth: '60%',
       height:'285px',
      autoFocus:true,
      hasBackdrop:true,
      data:{
        onClicked:(isClicked,isLastStep) => this.openDeskEventMenu(isClicked,isLastStep)
      }
      // maxWidth:'47vw',
      // position:{
      //   left:'50%'
      // }
      // panelClass:'welcome-dialog'
    })

  }
  
  //#region Desktop Tour
      @ViewChild('eventTrigger') eventTrigger: MatMenuTrigger;

      openDeskEventMenu(isClicked: boolean, isLastStep: boolean) {
        if (isClicked == true && isLastStep == false) {

          this.eventTrigger.menu.hasBackdrop = false;
          this.eventTrigger.openMenu();
        }
        else if (isClicked == true && isLastStep == true) {
          this.eventTrigger.menu.hasBackdrop = true;
          this.eventTrigger.closeMenu();
        }
      }

      quickTour() {
        this.changeDetectorRef.detectChanges();
        // this.guidedTourService.startTour(this.dashboardTour);
        if(this.common.checkVenueExists() == false){
          this.tourService.showTour(this.tourService.deskTourEnterprise);
          this.changeDetectorRef.detectChanges();  
        }
        else{
          this.tourService.showTour(this.tourService.deskTour);
          this.changeDetectorRef.detectChanges();
        }
        
      }

  //#endregion

  //#region Mobile Tour
      @ViewChild('eventTriggerMob') eventTriggerMob: MatMenuTrigger;

      openMobEventMenu(isClicked: boolean, isLastStep: boolean) {
        if (isClicked == true && isLastStep == false) {

          this.eventTriggerMob.menu.hasBackdrop = false;
          this.eventTriggerMob.openMenu();
        }
        else if (isClicked == true && isLastStep == true) {
          this.eventTriggerMob.menu.hasBackdrop = true;
          this.eventTriggerMob.closeMenu();
        }
      }


      quickTourMob() {
        this.changeDetectorRef.detectChanges();
        // this.guidedTourService.startTour(this.dashboardTour);
        if(this.common.checkVenueExists() == false){
          this.tourService.showTour(this.tourService.mobTourEnterprise);
          this.changeDetectorRef.detectChanges();
        }
        else{
          this.tourService.showTour(this.tourService.mobTour);
          this.changeDetectorRef.detectChanges();
        }
      }
  //#endregion

  async getCustomer() {
    this.common.ShowSpinner();
    await this.customersService.getMyCustomer().toPromise().then(res => {

      var data = res['value'] as Customer[];
      if (data.length > 0) {
        this.isSampleData = data[0].IsSampleData;

        this.common.HideSpinner();
      }

    }, error => {
      this.common.ShowToast(error.message, ToastType.Error);
      this.common.HideSpinner();
    }
    )
  }

  removeSampleData() {
    this.dialog.openConfirmDialog("Are you sure you want to remove sample data?").afterClosed().subscribe(res => {
      if (res) {
        this.common.ShowSpinner();
        this.genService.GetConfigDataWithQuery("EXEC sp_deleteSampleData").then(spResponse => {

          this.theme.getCustomerTheme();
          // this.router.navigate(['/calendar']);
          location.reload();
          this.isSampleData = false;
          this.common.ShowToast("All data has been deleted.", ToastType.Success);
          this.common.HideSpinner();
        },
          error => {
            this.common.ShowToast(error.message, ToastType.Error);
            this.common.HideSpinner();
          }
        )
      }
    });


  }

  openCustomerPortal(){
     window.open(this.customerPortallink, "_blank");
     
   }

  showTour(){
    const dialogQuick = this.matDialog.open(EventFormTourComponent, {
      // disableClose: true ,
      height: '55%',
      maxHeight: '100%',
      width: '65%',
      // minWidth: '52%',
      panelClass: 'dialog-panel',
      position: {
        right: 17.5 + '%',
        top: 11 + '%',
        left: 17.5 + '%',
        bottom: 0 + '%',
      },
      autoFocus: false,
    })  
  }

  async setupContract() {
    const dialogQuick = await this.matDialog.open(ContractSetupComponent, {
      // disableClose: true ,
      height: '100vh',
      width: '100%',
      minWidth: '100%',
      panelClass: 'dialog-panel',
      // position: {
      //   right: 10 + '%',
      //   top: 11 + '%',
      //   left: 24 + '%',
      //   bottom: 0 + '%',
      // },
      autoFocus: false,
    })
  }

  switchTenant(){
    var user = this.authenticationService.CurrentUser();
    
    if(user !=null){
      localStorage.removeItem('currentUser');
      localStorage.removeItem('themecolor');
      var tid = user?.tid;
      var token = user?.token;
      var email = user?.email;
      this.router.navigate(['/select-tenant'],{queryParams:{
        tid:tid,
        token:token,
        email
      }})
      //http://localhost:4200/select-tenant?tid=851c8598-a70f-4e42-b7ef-68aac344ae6a&token=eyJhbGciOiJSUzI1NiIsImtpZCI6IjlEOTdDQUNDRDNEM0YzOEZGQjIwMDI3Mjg2ODY2OUY3Qjk4RTQ1QzAiLCJ4NXQiOiJuWmZLek5QVDg0XzdJQUp5aG9acDk3bU9SY0EiLCJ0eXAiOiJKV1QifQ.eyJ2ZXIiOiIxLjAiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vODUxYzg1OTgtYTcwZi00ZTQyLWI3ZWYtNjhhYWMzNDRhZTZhL3YyLjAiLCJzdWIiOiJuZnhNcmg1bTI3LU9XV1FGMnY3Tm94SUhxcEd0SzE4cW9sUzhtNi0xVUU0IiwiYXVkIjoiZmQxNTdjNGItNDk5Yi00ZWIyLTk5YzYtYmM2MjJkZDQ5NjIzIiwiZXhwIjoxNzAyNjU1MDIzLCJhY3IiOiJiMmNfMWFfc2lnbnVwX3NpZ25pbiIsIm5vbmNlIjoiZGVmYXVsdE5vbmNlIiwiaWF0IjoxNzAyNjI2MjIzLCJhdXRoX3RpbWUiOjE3MDI2MjYyMjMsInRpZCI6Ijg1MWM4NTk4LWE3MGYtNGU0Mi1iN2VmLTY4YWFjMzQ0YWU2YSIsIm5hbWUiOiJTYWZ3YW4gQWJpZCIsImVtYWlsIjoic2Fmd2FuLmFiaWRAYWxyYWZheWNvbnN1bHRpbmcuY29tIiwibmJmIjoxNzAyNjI2MjIzfQ.Ch7h0gmD3PUG4t82A7sNu0wTr438wi4s9ehmxEnz78QZQgVbz_g3DLNQqwpK56NJATc6dqwi0RcotipBsl0WY8XzU90NeGEWWooovNwz_J-85JugVellpfWCIyKBxyc4giMCV4vfKY2n6R-mdut2QmUj4sKHgNpYLsDoHR-U-tbQl0fHvJpXZ7oMZi7I90DZmyNFsE-7dlSz-BmnNEuNWPXu4RojyF0S0CDe-2ytyln8gUXkQ6M_kA-gPSiTF7jDNKSLgAqHv9iM5gfpmyRViyEbOFmvWFy2bVWQfnBdnAB9qmv78Oag3LsdjMrwmfFO3Hu_Zr3FxSb05dUu1xBT6Q&email=safwan.abid@alrafayconsulting.com
    }
  }
}
