<div class="row mt-3">
  <div class="col-md-12">
    <mat-form-field class="example-full-width">
      <mat-label [class]="theme.label">Venue</mat-label>
      <mat-select [(ngModel)]="VenueID" required  name="VenueID" id="drpVenue" (selectionChange)="onVenueChange(VenueID)" >
        <mat-option>
          <ngx-mat-select-search
                  name="venue"
                  #Venue="ngModel"
                  [(ngModel)]="searchUserLead"
                  (ngModelChange)="AutoCompVenue.searchResult.next($event)"
                  placeholderLabel="Search..."
                  noEntriesFoundLabel="Not Found!"
                  [ngModelOptions]="{ standalone: true }">
          </ngx-mat-select-search>
        </mat-option>
        <mat-option>-- Clear --</mat-option>
        <mat-option *ngFor="let option of AutoCompVenue.results | async" [value]="option.ID">
          {{ option.VenueName }}
        </mat-option>
      </mat-select>
      
    </mat-form-field>
  </div>
</div>
<div class="mb-1 d-flex justify-content-between align-items-center"  *ngIf="VenueID > 0" >
  <!-- <button type="button" (click)="addContractField()" *ngIf="permissionService.CheckIfUserhasAccess_Action('Contract Fields','Add')"
    mdbBtn [class]="theme.button" size="sm" class="waves-light" mdbWavesEffect>
    <mdb-icon fas icon="plus" class="mr-1"></mdb-icon>Add Contract Fields
  </button> -->
  <span style="font-size: 11px;"> <mdb-icon fas icon="info-circle" class=""></mdb-icon> Please create the required fields against Venue that will be automatically uploaded in your contract.</span>
  <span *ngIf="data.length > 0">
    <button type="button" (click)="addContractField()" *ngIf="permissionService.CheckIfUserhasAccess_Action('Contract Fields','Add')"
    mdbBtn [class]="theme.button" size="sm" class="waves-light" mdbWavesEffect>
    <mdb-icon fas icon="plus" class="mr-1"></mdb-icon>Add more Contract Fields
  </button>
  </span>
</div>

<div class="table-responsive " *ngIf="VenueID > 0">

  <table class="table " style="border: 1px solid #00000020;">
    <thead>
      <tr>
        <!-- <th [class]="theme.label">ID</th> -->
        <th [class]="theme.label">S #</th>
        <th [class]="theme.label">Name</th>
        <th [class]="theme.label">Type</th>
        <th [class]="theme.label">Venue</th>
        <th [class]="theme.label">GL Code</th>
        <!-- <th [class]="theme.label">Default</th> -->
        <th [class]="theme.label">Default Value</th>
        <th [class]="theme.label">ModifiedDate</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="data.length == 0">
        <td colspan="8" style="text-align: center;font-weight: 500;">No data found! <a (click)="addContractField()" style="color: #007bff;text-decoration: underline;">Create</a> your first field.</td>
      </tr>
      <tr *ngFor="let row of data">
        <!-- <td>{{row.ID}}</td> -->
        <td>{{row.OrderNo}}</td>
        <td>{{row.Name}}</td>
        <td>{{row.Type}}</td>
        <td>{{row.Venue}}</td>
        <td>{{row.GLCode}}</td>
        <!-- <td>{{row.IsByDefault == true? 'Yes' : 'No' }}</td> -->
        <td>{{row.DefaultValue}}</td>
        <td>{{row.ModifiedDate | date:'MM/d/yyyy'}}</td>
      </tr>
    </tbody>
  </table>


</div>
<!-- <div class="mt-1 float-right" *ngIf="data.length > 0">
  <button type="button" (click)="addContractField()" *ngIf="permissionService.CheckIfUserhasAccess_Action('Contract Fields','Add')"
    mdbBtn [class]="theme.button" size="sm" class="waves-light" mdbWavesEffect>
    <mdb-icon fas icon="plus" class="mr-1"></mdb-icon>Add more Contract Fields
  </button>
</div> -->