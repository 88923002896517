import { Injectable } from '@angular/core';
import { ConfFilters } from '../models/conf-filters.model';
import { HttpClient } from '@angular/common/http';
import { ConfHttpHeadersService } from '../services/conf-http-headers.service';
import * as customConfig from 'src/app/customConfig.json';

@Injectable({
  providedIn: 'root'
})
export class CalendarService{

  selectedClients:number[]=[];
  VenueID: number[] = [];
  EventStatusID: number[] = [];
  viewDate: Date = new Date();
  
  IsAppLoaded:boolean = false;
  confFilters:ConfFilters[] =[];

  selectedChipVenueName = ""; 
  selectedChipEventStatus = null;
  
  private readonly rootURL = customConfig.api; 
  constructor(
    private http:HttpClient,
    private hdr:ConfHttpHeadersService,
    
  ) { }

  public async GetCalendarData(startDate:string,endDate:string, allSelectedVenueID:string, allSelectedStatusID:string, allSelectedClientID:string){
    var query = {
      startDate:startDate,
      endDate:endDate,
      allSelectedVenueID:allSelectedVenueID,
      allSelectedStatusID:allSelectedStatusID,
      allSelectedClientID:allSelectedClientID
    };
    return await this.http.post(this.rootURL + "api/Calendar/GetCalendarData",query,this.hdr.getHeaders()).toPromise();
  }

  public async GetCalendarAvailData(startDate:string,endDate:string, allSelectedVenueID:any){
    var query = {
      startDate:startDate,
      endDate:endDate,
      allSelectedVenueID:allSelectedVenueID,
      allSelectedStatusID:'',
      allSelectedClientID:''
    };
    return await this.http.post(this.rootURL + "api/Calendar/GetAvailsData",query,this.hdr.getHeaders()).toPromise();
  } 
}
