import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { CommonService } from 'src/app/Base/Common.service';
import { ToastType } from 'src/app/Enum/ToastType';
import { themeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-contract-setup',
  templateUrl: './contract-setup.component.html',
  styleUrls: ['./contract-setup.component.scss']
})
export class ContractSetupComponent implements OnInit {

  isBack:boolean=false;
  venueID:number=0;
  constructor(
    public route:ActivatedRoute,
    public location:Location,
    public common:CommonService,
    public theme:themeService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params=>{
      this.isBack =  params['IsBack'] == "1" ? true : false;
      this.venueID =  Number(params['VenueID']) || 0;
    })
  }


  onCompleteSteps(){

    this.common.ShowToast("Setup Completed",ToastType.Success);
    if(this.isBack == true){
      this.location.back();
    }
  }

 

  // Step 1

  contractFields:any[]=[];
  isContractFields = new FormControl('');

  async getIsContractField(event:boolean){
    this.setState(this.isContractFields,event);
   
  }

  // Step 3
  isContractTemplate = new FormControl('');
  
  getIsContractTemplate(event:boolean){
    this.setState(this.isContractTemplate,event);
  }

  setState(control: FormControl, state: boolean) {
    if (!state) {
      control.setErrors({ "required": true })
    } else {
      control.reset()

    }
  }

  setContractFields(event:any[]){
    var contractFields:any[] = event.map(a=> ({ name:a.Name, actualname: a.Name }));
    for (let index = 0; index < contractFields.length; index++) {
      var element = contractFields[index];
          element.name = '{' + element.name.replaceAll(' ','_') + '}';
    }
    this.contractFields = contractFields;
  }

  onBack(){
    this.location.back();
    
  }
}
