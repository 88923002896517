<div class="row">
    <div class="col-md-12">
        <div
    class="modal-header darken-3 white-text  gradient-card-header narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center"  [class]="theme.heading">
    <h4 class="h4-responsive">
        Upgrade to Enterprise
    </h4>
    <div class="btn-group" role="group" aria-label="Basic example">
        <a (click)="modalRef.hide()">
            <button mdbBtn type="button" [class]="theme.button" size="sm" class="waves-light" mdbWavesEffect>
                <mdb-icon fas icon="times"></mdb-icon>
            </button>
        </a>
    </div>
</div>
<div class="modal-body mb-0 " id="modalBodyArea">
    <div class="row">
        <div class="col-lg-6 col-md-6">
            <mdb-card class="crd" cascade="true" narrower="true">
                <div
                    class="view view-cascade gradient-card-header peach-gradient narrower py-2 mx-4  d-flex justify-content-between align-items-center">
                    <h4 class="h4-responsive w-100 text-center">Enterprise</h4>
                </div>
                <mdb-card-body cascade="true" class="bdy">
                    <div>
                        <!-- <p class="text-center">
                            <span style="font-size:30px; font-weight: 500;"> Contact </span>
                        </p> -->
                        <hr>
                        <div class="row">
                            <div class="col-md-6">
                                <p class="pitem" *ngFor="let op of enterprise  | slice:0:13">
                                    <mdb-checkbox  [ngModelOptions]="{standalone: true}"   [(ngModel)]="op.selected" >
                                        <mat-label [class]="theme.label">
                                            {{op.name}}
                                        </mat-label>
                                    </mdb-checkbox> 
                                </p>

                            </div>
                            <div class="col-md-6">
                                <p class="pitem" *ngFor="let op of enterprise  | slice:13:20">
                                    <mdb-checkbox  [ngModelOptions]="{standalone: true}"   [(ngModel)]="op.selected" >
                                        <mat-label [class]="theme.label">
                                            {{op.name}}
                                        </mat-label>
                                    </mdb-checkbox> 
                                </p>

                            </div>
                        </div>
                    </div>
                </mdb-card-body>
            </mdb-card>
        </div>
        <div class="col-lg-6 col-md-6">
            <div class="md-form">
                <textarea required type="text" id="form7" rows="8"  class="md-textarea md-textarea-auto form-control" mdbInput [(ngModel)]="formData.Notes"
                    name="Notes">
            </textarea>
                <label for="NotesText" [class]="theme.label">Notes</label>
                
            </div>

            <div class="text-right">
                <button type="button" mdbBtn [class]="theme.button" class="waves-light" mdbWavesEffect (click)="sendRequest()">
                    Send Request
                </button>
            </div>
            <!-- <div class="row mt-5">
                <div class="col-md-6"></div>
                <div class="col-md-12" >
                     <p class="pitem2 text-right">User Name: {{user?.UserName}}</p>
                     <p class="pitem2 text-right">Email: {{user?.Email}}</p>
                </div>
            </div> -->
        </div>
    </div>

</div>
    </div>
</div>