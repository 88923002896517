import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../helpers/authentication.service';
import * as customConfig from 'src/app/customConfig.json';
import { LoginUser } from '../Base/User/login-user';

import { ConfHttpHeadersService } from './conf-http-headers.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  
  readonly rootURL = customConfig.api; 

  constructor(
    private http: HttpClient,
    private authenticationService :AuthenticationService,
    private hdr:ConfHttpHeadersService,
    ) { }

    // Add async/await on 02/06/2024
    async getActionData(){

      var obj = {
        customerid:LoginUser.customer.CustomerObjectId,
        userid:LoginUser.loggedinUser.UserID.toString()
      }

      return await this.http.post(this.rootURL + "api/Permissions/Actions/", obj , this.hdr.getHeaders()).toPromise().then(res =>{
        if (res['value'] != null) {
          var rbacUS = res['value'] as any[];
          LoginUser.getActionAccess = rbacUS;
        }
     }) ;
    }

    getSectionData(){

      var obj = {
        customerid:LoginUser.customer.CustomerObjectId,
        userid:LoginUser.loggedinUser.UserID.toString()
      }


      this.http.post(this.rootURL + "api/Permissions/Sections/", obj , this.hdr.getHeaders()).toPromise().then(res => {
        if (res['value'] != null) {
          var rbacUS = res['value'] as any[];
          LoginUser.getSectionAccess = rbacUS;
        }
      }) ;
    }

    CheckIfUserhasAccess(moduleName: string) {
      //return false; // sameer 03232022
      //if (LoginUser.IsAdmin == true)
        //return false;
  
      var mAccess = LoginUser.getModulesAccess.filter(function (x) {
        return x.ModuleName == moduleName && x.IsVisible == true
      })
  
      if (mAccess.length == 0)
        return true;
  
      return false;
  
    }
  
    CheckIfUserhasAccess_Section(sectionName: string) {
      // return false; // sameer 03232022
  
     // if (LoginUser.IsAdmin == true)
       // return false;
       if(LoginUser.loggedinUser.UserID  == null)
          return false;
  
  
      var mAccess = LoginUser.getSectionAccess.filter(function (x) {
        return x.SectionName == sectionName && x.IsVisible == true
      })
  
      if (mAccess.length == 0)
        return true;
  
      return false;
  
    }

    public showBuyButton() {
   
      if(LoginUser.loggedinUser.UserID  == null)
         return false;
 
     if(LoginUser.loggedinUser.Subscription == 'basic')
           return true;
 
     return false;
 
   }
 
   public checkIsFreeTrial(){
     if(LoginUser.loggedinUser.IsFreeTrial == true)
       return true;
     else 
       return false; 
   }
 
   public CheckIfUserhasAccess_SectionGuard(sectionName: string) {
     // return false; // sameer 03232022
 
    // if (LoginUser.IsAdmin == true)
      // return false;
 
      return true;
 
      if(LoginUser.loggedinUser.UserID == null)
         return true;
 
 
     var mAccess = LoginUser.getSectionAccess.filter(function (x) {
       return x.SectionName == sectionName && x.IsVisible == true
     })
 
     if (mAccess.length == 0)
       return false;
 
     return true;
 
   }
 
 
   public CheckIfUserhasAccess_Action(sectionName: string, actionName: string) {
     //return true; // sameer 03232022
 
     // if (LoginUser.IsAdmin == true)
     //   return true;
     var mAccess = LoginUser.getActionAccess.filter(function (x) {
       return x.SectionName == sectionName && x.RightName == actionName && x.IsSectionVisible == true && x.HasPermission == true
     })
 
     if (mAccess.length > 0)
       return true;
     else
       return false;
 
   }
 
}
