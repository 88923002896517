
<!-- <div class="myForm  shadow">
    <form class=" p-5 mb-5 rounded mt-4  border-form" >
        <div class="d-flex justify-content-center">
            <div class="logo">
                <img src="assets/venuearc_logo.png" alt="Logo" style="width: 300px;">
            </div>
        </div>

        <div class="row" >

            <div class="col-md-12 pt-5 pb-3 text-center">
                <h2 style="color:#0e83a8;">Select Your Subscription</h2>
            </div>
        </div>

        <div class="row" >


            <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12 text-center">

                <!-- <label for="">Subscriptions</label>
                <!-- <select [(ngModel)]="selectedSubscription"  class="form-control">
                    <option *ngFor="let option of customerSubscriptions" [value]="option.subscriptionid">
                        {{option.subscriptionName}}
                    </option>
                </select>


                <mat-form-field  class="example-full-width" appearance="fill">

                    <mat-select required
                    name="FirstName" #FirstName="ngModel"
                     type="text"
                     [(ngModel)]="selectedSubscription"  >
                      <mat-option *ngFor="let option of customerSubscriptions" [value]="option.subscriptionid">
                        {{option.subscriptionName}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="FirstName.invalid || FirstName.dirty">This field is required</mat-error>


                </mat-form-field>

                <br>


                <button mdbBtn color="info" class="waves-light w-50" rounded="true" mdbWavesEffect type="button"
                    (click)="processSignin()">
                    <mdb-icon fas icon="check"></mdb-icon>
                    <span class="ml-2"> Done </span>
                </button>

            </div>


        </div>

    </form>
</div> -->

<div class="container-fluid bg-frm">
  <div class="d-flex align-items-center bdy2">
    <div class="box">
      <div class="text-center">
        <img src="assets/venue_arc_singup.png" alt="Logo" class="logo">
      </div>
      <div class="text-center">
        <div class=" pt-2 pb-3 text-center">
          <h2 class="sessionText">
            Select Your Subscription
          </h2>
          <br>
        </div>

        <div class="">

          
          <mat-form-field  class="example-full-width" appearance="standard">

            <mat-select required
            placeholder="Select your subscription"
            name="FirstName" #FirstName="ngModel"
             type="text"
             [(ngModel)]="selectedSubscription"  >
              <mat-option *ngFor="let option of customerSubscriptions" [value]="option.subscriptionid">
                {{option.subscriptionName}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="FirstName.invalid || FirstName.dirty">This field is required</mat-error>


        </mat-form-field>
          <div class="mt-2">
            <button mdbBtn  class="waves-light w-75" mdbWavesEffect type="button" style="color: white;background: #1573a3 !important;border-radius: 12px !important;" (click)="processSignin()" >
              <mdb-icon fas icon="check"></mdb-icon>
              <span class="pl-2"> Done </span>

            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


