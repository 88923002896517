import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  terms: any = null;
  checkedTermsConditions:boolean=false;
  action = new Subject<any>();  
  constructor(
    public modalRef:MDBModalRef,
    public http:HttpClient
  ) {
    
   }

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    this.http.get("https://www.iubenda.com/api/terms-and-conditions/28807932").toPromise().then(res =>{
      this.terms = res;
    })
    
  }

  onProceed(value:any){
    this.action.next(value);
    this.modalRef.hide();
  }

}
