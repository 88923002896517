import { Injectable } from '@angular/core';
import { AuthenticationService } from '../helpers/authentication.service';
import { HttpHeaders } from '@angular/common/http';
import { LoginUser } from '../Base/User/login-user';
import * as customConfig from 'src/app/customConfig.json';

@Injectable({
  providedIn: 'root'
})
export class ConfHttpHeadersService {

  constructor(
    private authenticationService :AuthenticationService,
  ) { }

  getHeaders(){
    if(!this.authenticationService.CurrentUser())
      return ;
        

    //#region  testing code
    /*
    var IsTest:string = "";
    this.route.queryParams.subscribe(params => {
      var isQA = params['IsTest'] || 0;
      IsTest = isQA == 1 ? "test" : "";
    })

    if(IsTest != 'test'){
      if(IsTesting == true){
        IsTest = 'test';
      }
    }
    */
    //#endregion

    var ownerid = this.authenticationService.CurrentUser().OwnerId;    
    const httpOptions = {
      headers: new HttpHeaders({        
        'Token': localStorage.getItem(customConfig.tokenID), 
        'tid': ownerid,
        'uid': LoginUser.loggedinUser.UserID == null ? '-1' : LoginUser.loggedinUser.UserID.toString(),  
        'CustomerObjectID' : LoginUser.customer == null ? '-1' : LoginUser.customer.CustomerObjectId.toString(),
        'UserObjectID' :  LoginUser.loggedinUser.UserID == null ? '-1' : LoginUser.loggedinUser.UserID.toString(),  
        //'IsTest': IsTest
      })      
    };
  
    return httpOptions;
  }

  public getPostHeaders() {
    if (!this.authenticationService.CurrentUser())
      return;

    var ownerid = this.authenticationService.CurrentUser().OwnerId;


    const httpOptions = {
      headers: new HttpHeaders({
        'Token': localStorage.getItem(customConfig.tokenID),
        'Content-Type': 'application/json',
        'tid': ownerid,
        'uid': LoginUser.loggedinUser.UserID == null ? '-1' : LoginUser.loggedinUser.UserID.toString(),  
        'CustomerObjectID' : LoginUser.customer == null ? '-1' : LoginUser.customer.CustomerObjectId.toString(),
        'UserObjectID' :  LoginUser.loggedinUser.UserID == null ? '-1' : LoginUser.loggedinUser.UserID.toString(),  
      })
    };

    return httpOptions;
  }
}
