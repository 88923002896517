import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfHttpHeadersService } from 'src/app/services/conf-http-headers.service';
import { GeneralService } from 'src/app/services/general.service';
import * as customConfig from 'src/app/customConfig.json';
import { HelpService } from 'src/app/services/help.service';

@Injectable({
  providedIn: 'root'
})

export class VenueService {
  private readonly rootURL = customConfig.api; 
  constructor(
    private genService:GeneralService,
    private http:HttpClient,
    private hdr:ConfHttpHeadersService,
    private helpService:HelpService
  ) {}

  async getVenues(showAuditInfo:boolean = null) {            
    return await this.genService.GetDataByQuery("Venues?$filter=Inactive ne true and (IsDeleted ne true or IsDeleted eq null) " + this.getAuditquery(showAuditInfo))    
  }

  // async getAllVenues(showAuditInfo:boolean = null) {    
  //   return await this.genService.GetDataByQuery("Venues?$filter=IsDeleted ne true "+ this.getAuditquery(showAuditInfo))    
  // }

  async getVenue() {    
    return await this.genService.GetDataByQuery("Venues?$select=ID&$top=1")    
  }

  // async getInActiveVenues(showAuditInfo:boolean = null) {    
  //   return await this.genService.GetDataByQuery("Venues?$filter=Inactive eq true and IsDeleted ne true " + this.getAuditquery(showAuditInfo))    
  // }

  async getAllVenues(){
    return await this.helpService.GetDataByHelpNo("hlpvenuelist",{inactive:-1});
  }

  async getActiveVenues(){
    return await this.helpService.GetDataByHelpNo("hlpvenuelist",{inactive:0});
  }

  async getInActiveVenues(){
    return await this.helpService.GetDataByHelpNo("hlpvenuelist",{inactive:1});
  }

  async getDrpVenues(){
    return await this.helpService.GetDataByHelpNo("hlpvenuedrp");
  }

  private getAuditquery(showAuditInfo):string{
    if (showAuditInfo != null)
        return "&$expand=RBAC_Users1($select=FullName),Currency"

    return "";
  }

  
  async getVenueAndMonthlyNotesData(monthMM: string, yearYYYY: string, venueIDs: number[]):Promise<any> {
    var venues  = "''";

    for (let index = 0; index < venueIDs.length; index++) {
      const element = venueIDs[index];
      if(index == 0)
        venues = "'"+element.toString() + "'";
      else
        venues = "," + "'" + element.toString() + "'";
    }

    var filters = {
      venueid:venues,
      month:monthMM,
      year:yearYYYY
    };

    let resVenue =  await this.getDrpVenues();
    let resVenueMonth =  await this.helpService.GetDataByHelpNo("hlpvenuemonthnotes",filters);

    if(resVenue['value']){
      for (let index = 0; index < resVenue['value'].length; index++) {
        const element = resVenue['value'][index];
        let filteredData = resVenueMonth['value'].filter(a=> a.VenueID == element.ID);
        filteredData.forEach(ele => {
          ele.CreatedDate = ele.CreatedDate+"Z";
        });
        element['VenueMonthNotes'] = filteredData;
      }
    }

    return resVenue;
  }
  


  async getVenueDetail(){
    return await this.genService.GetDataByQuery("Venues?$select=*&$top=1")
  }

  currencies:any[]=[];
   getVenueCurrency(VenueID:any){
    if(this.currencies.length > 0){
      return this.currencies.filter(a=> a.ID == VenueID)[0].Symbol;
    }
    else{
      this.genService.GetDataByQuery("EXEC sp_GetVenueCurrency").then(res=>{
        this.currencies = res['value'];
        return this.currencies.filter(a=> a.ID == VenueID)[0].Symbol;
       })
    }
  }
  
  /*async getVenueAndMonthlyNotesData(monthMM: string, yearYYYY: string, venueIDs: number[]) {
    var venueFilter = "&$filter=Inactive ne true and IsDeleted ne true ";
    if (venueIDs.length > 0) {
      var i = 0 ;

      venueIDs.forEach(element => {
        
        if (i == 0)
          venueFilter += " and  (ID eq " + element;
        else
          venueFilter += "or ID eq " + element;

        i = 1;
      });

      venueFilter += ")";

    }

    return await this.genService.GetDataByQuery("Venues?$expand=VenueMonthNotes($expand=RBAC_Users1($select=ID,FirstName,LastName)),VenueMonthNotes($filter=month(month) eq " + monthMM + "  and year(Month) eq " + yearYYYY + ";$orderby=CreatedDate desc)" + venueFilter)
  }*/

  async getOnBoardVenue(){
    return await this.http.get(this.rootURL + "api/VenueOp/GetOnBoardVenue",this.hdr.getHeaders()).toPromise();
  }

}
