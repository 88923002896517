
export enum EnEventStatus {

    Deleted = -99,
    Request = -2,    
    Cancel = -1000,
    Contracted = 1,
    ContractedIssued = 2,
    Definite = 3,
    Hold1 = 4,
    Hold2 = 5,
    Hold3 = 6,
    Hold4 = 7,
    Hold5 = 8,
    Hold6 = 9,
    Budgeted = 10,

}

export enum EnTicketType {
    Ticketed = 1,
    NonTicketed = 2
}

export enum EnCategory {
    Ticketed = 1,
    NonTicketed = 2
}

export enum EnCategory {
    Profit = 1,
    NonProfit = 2
}

export enum EnEventType {
    Opera = 1,
    BroadwaySanDiego = 2,
    Dance = 3,
    Community = 5,
    NonPerformanceDay = 6,
    InvitationalActivity = 7,
    DressRehearsalStudent = 8,
    Performanceticketed = 9,
    Performancenonticket = 10,
    Maintenance = 11,
    Concertclassical = 12,
    ConcertEntpopular = 13,
    EthnicEvents = 14,
    LoadIn = 15,
    LoadOut = 16,
    SpecialPerformance = 17,
    Rehearsal = 18,
}

export enum EnFieldType {
    Text = 1,
    Number = 2,
    Currency = 3,
    Date = 4,
    Rich_Text = 5,
    Currency_Decimal_3 = 6
}


export enum EnRights {
    Add = 1,
    Edit = 2,    
    View = 3,
    Delete = 4,    
}

export enum EnOccurence {
    ByEvent = 1,
    ByDay = 2,    
    ByPerformance = 3   
}

export enum EnItemCategory {
    Unit = 1,
    Service = 2
}

export enum EnSettlementDataSource {
    Input = 1,
    ContractInformation = 2,
    DepositSchedule = 3
}

export enum EnAccountHead {
    Revenue = 1,
    Expense = 2
}



