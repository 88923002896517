<div class="container-fluid bg-frm">
  <!-- <div class="row pt-5">
    <div class="col-md-7 col-sm-12"></div>
    <div class="col-md-5 col-sm-12 ">
      <div class="row frm">
        <div class=" mt-4">
          <input type="text" placeholder="First Name*" class="form-control input-my" required name="FirstName" #FirstName="ngModel" [(ngModel)]="obj.FirstName">

        </div>
        <div class=" mt-4">
          <input type="text" placeholder="Last Name*" class="form-control input-my" required name="LastName" #LastName="ngModel" [(ngModel)]="obj.LastName">


        </div>
        <div class=" mt-4">
          <input type="text" placeholder="Organization/Company Name*" class="form-control input-my" required name="OrganisationName" #OrganisationName="ngModel" [(ngModel)]="obj.OrganisationName">

        </div>
        <div class=" mt-4">
          <input type="email" placeholder="Email*" class="form-control input-my" required name="Email" #Email="ngModel" [(ngModel)]="obj.Email">

        </div>
        <div class=" mt-4 ">

          ========= <mdb-checkbox [default]="true">I am agree with all statements in <a routerLink="/signup">Terms of Services

          </a></mdb-checkbox> ================
          <div class="d-flex">
            <mdb-checkbox [(ngModel)]="checkedTermsConditions"></mdb-checkbox>
            <label class="" for="">I agree with all the
              <a (click)="openTermsAndConditions()" style="color: #007bff;
              text-decoration: none;
              background-color: transparent;
              text-decoration: underline;">
                Terms & Condition
              </a>
            </label>
          </div>
         ====================== <div class="form-check">
              <input type="checkbox" class="form-check-input" id="exampleCheck1">
              <label class="form-check-label" for="exampleCheck1">I agree with all the
                <a routerLink="/signup">
                  Terms & Condition
                </a>
              </label>
            </div> ============================
        </div>
        <div class="col-md-12 col-sm-12  mt-4 btn-display ">

          <button class="btn btn-dark" [disabled]="validate()" (click)="Save()">Register</button>
          <br>
          <span class="ml-4">or Login With
            <a mdbBtn floating="true" size="md" placement="right" mdbWavesEffect style="background: #161f65" mdbTooltip="Azure Marketplace" (click)="navigateMarketPlaceSignUp()">
              <mdb-icon fab icon="adobe"></mdb-icon>
            </a>
            <a mdbBtn floating="true" size="md" placement="right" mdbWavesEffect style="background: #161f65" mdbTooltip="Microsoft App Source"  (click)="subscribeAS()">
              <mdb-icon fab icon="microsoft"></mdb-icon>
            </a>
          </span>

        </div>


      </div>

    </div>
  </div> -->
  <!-- width: 507px; -->
  <div class="row">
   <div class="col-md-12 col-sm-12 text-right pr-5 pt-3" style="font-size: 13px;">
    Already have an account? <a (click)="login()" style="color: #007bff;font-weight: 500;text-decoration: underline;">Sign In</a>
   </div> 
  </div>
  <div class="d-md-flex d-lg-flex d-sm-block d-xs-block align-items-center bdy" *ngIf="!IsSaved">

    <div class="bg-light img-box" >
      <div class="logo pb-2 mb-4 text-center">
        <img src="assets/venue_arc.png" alt="Logo" style="width: 250px;">
      </div>
        <!-- <h1 style="font-weight: 700;color: #1e3c83">Welcome to our Community</h1> -->
        <!-- <h3 style=" display:none;font-weight: 700;color: #1e3c83">Welcome to our Community</h3> -->
        <!-- <h1 style="font-weight: 700;color: #1e3c83"></h1> -->
        <!-- <p style="font-weight: 500;">
          A whole new productive journey starts here
        </p> -->
        <h5 class="text-content  text-center">Streamline your event planning and increase productivity with VenueArc!</h5>
        <h5 class="text-content text-center">Sign up now and see the difference.</h5>
        
        <!-- <button type="button" class="btn" style="padding: 10px 28px 10px 28px;background: #0fb4bd;border: none;border-radius: 12px;color: white; margin-top: 35px;" (click)="login()">Log in</button> -->
        <img src="../../../../assets/men.png" alt="" class="men-img">
    </div>
    <div class="input-box" >
      <h2 style="color:#1e3c83;font-size: 20px;" class="">Sign Up With VenueArc</h2>
      <!-- <h6 style="font-weight: 700;color: #696c74;" class="text-center mt-2">Sign Up</h6> -->
      <div class=" mt-4">
        <input type="text" placeholder="First Name*" class="form-control input-my" required name="FirstName" #FirstName="ngModel" [(ngModel)]="obj.FirstName">

      </div>
      <div class=" mt-4">
        <input type="text" placeholder="Last Name*" class="form-control input-my" required name="LastName" #LastName="ngModel" [(ngModel)]="obj.LastName">


      </div>
      <div class=" mt-4">
        <input type="text" placeholder="Organization/Company Name*" class="form-control input-my" required name="OrganisationName" #OrganisationName="ngModel" [(ngModel)]="obj.OrganisationName">

      </div>
      <div class=" mt-4">
        <input type="email" placeholder="Email*" class="form-control input-my" required name="Email" #Email="ngModel" [(ngModel)]="obj.Email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" >
        <mat-error *ngIf="Email.errors  && Email.errors.pattern">Email is invalid</mat-error>
      </div>
      <div class=" mt-4 ">

        <div class="d-flex">
          <mdb-checkbox [(ngModel)]="checkedTermsConditions"></mdb-checkbox>
          <p class="" for="" style="font-weight: 500 !important;">I agree with all the
            <a (click)="openTermsAndConditions()" style="color: #007bff;
            text-decoration: none;
            background-color: transparent;
            text-decoration: underline;">
              Terms & Condition
            </a>
          </p>
        </div>

      </div>
      <div class="mt-3 btn-display ">

        <button type="button" class="btn btn-dark w-100" [disabled]="validate()" (click)="Save()">Register</button>
        <br>
        <span class="l-with pt-3 d-block">or Sign Up With
          <a mdbBtn floating="true" size="md" placement="right" mdbWavesEffect style="background: #161f65" mdbTooltip="Azure Marketplace" (click)="navigateMarketPlaceSignUp()">
            <mdb-icon fab icon="adobe"></mdb-icon>
          </a>
          <a mdbBtn floating="true" size="md" placement="right" mdbWavesEffect style="background: #161f65" mdbTooltip="Microsoft App Source"  (click)="subscribeAS()">
            <mdb-icon fab icon="microsoft"></mdb-icon>
          </a>
        </span>

      </div>


    </div>
  </div>

  <div class="d-md-flex d-lg-flex d-sm-block d-xs-block align-items-center bdy" *ngIf="IsSaved">

    <div class="bg-light img-box" >

        <h1 style="font-weight: 700;color: #1e3c83">Welcome to our Community</h1>
        <h3 style=" display:none;font-weight: 700;color: #1e3c83">Welcome to our Community</h3>
        <!-- <h1 style="font-weight: 700;color: #1e3c83"></h1> -->
        <p style="font-weight: 500;">
          A whole new productive journey starts here
        </p>

        <!-- <p style=" font-weight: 500;">
          starts here
        </p> -->
        <button type="button" class="btn" style="padding: 10px 28px 10px 28px;background: #0fb4bd;border: none;border-radius: 12px;color: white;" (click)="login()">Log in</button>
        <img src="../../../../assets/men.png" alt="" class="men-img">
    </div>
    <div class="input-box" >
      <h1 style="font-weight: 700;color: #1e3c83;" class="text-center">Sign Up</h1>
      <div class=" mt-4">

        <div class="col-md-12 pt-1 pb-3 text-center">
          <h2 style="color:green;">Congratulations!</h2>
          <br>
          <p>You are successfully registered.</p>
          <p>Please check your email: {{obj.Email}} to continue.</p>
        </div>

      </div>



    </div>
  </div>


</div>


<!-- When user is successfully signup -->




