export class Contactperson {
    ID: number = 0;
    ClientID: number = null;
    ContactPersonName: string = null;
    Designation: string = null;
    City: string = null;
    State: string = null;
    ZipCode: string = null;
    Country: string = null;
    Address: string = null;
    Phone: string = null;
    Fax: string = null;
    Email: string = null;
    IsActive: boolean = null;
    CreatedBy: number = null;
    CreatedDate: Date = null;
    ModifiedBy: number = null;
    ModifiedDate: Date = null;
    
}
