import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { CommonService } from 'src/app/Base/Common.service';
import { ToastType } from 'src/app/Enum/ToastType';
import { GeneralService } from 'src/app/services/general.service';
import { GenericService } from 'src/app/services/generic.service';
import { themeService } from 'src/app/services/theme.service';
import { RBACGroups } from '../../rbac-groups';
import { RBAC_Users_Groups } from '../../rbac-users-groups';
import { RbacUsers } from '../../rbac-users.model';
import { LoginUser } from 'src/app/Base/User/login-user';
import { MessageDialogeService } from 'src/app/message-dialoge/message-dialoge.service';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { RbacGroupService } from 'src/app/services/rbac-group.service';
import { Domain } from 'src/app/setupForms/domain/domain.model';
import { DomainService } from 'src/app/services/domain.service';

@Component({
  selector: 'app-rbac-users-modal',
  templateUrl: './rbac-users-modal.component.html',
  styleUrls: ['./rbac-users-modal.component.scss']
})
export class RbacUsersModalComponent implements OnInit {

  formDataUser:RbacUsers = new RbacUsers();
  public rbacGroups:RBACGroups_This[]= [];
  public IsAdmin :boolean = false;
  customer = LoginUser.customer;
  domains:Domain[]=[];

  public message: string ="";
  constructor(
    public genService:GeneralService,
    public commonService:CommonService,
    public theme:themeService,
    public service: GenericService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<RbacUsersModalComponent>,
    public cdr:ChangeDetectorRef,
    public messageDialog:MessageDialogeService,
    private authService: AuthenticationService,
    private rbacGroupService: RbacGroupService,
    public domainService:DomainService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.GetGroups();
    await this.getDomains();
  }

  ngAfterViewInit(){
    this.cdr.detectChanges();
    
    this.cdr.detectChanges();
  }
    
  onGroupClick(element:RBACGroups_This){
      if (element.IsAdmin == true) {
        if (element.IsSelected == true) {
          this.rbacGroups.forEach(element => {
            if(element.IsAdmin != true)
                element.IsSelected = false;
          });
  
          this.IsAdmin = true;
        }
        else
        {
          // this.rbacGroups.forEach(element => {
          //   element.IsSelected = false;
          // });
          this.IsAdmin = false;
        }
      }
  }

  async GetGroups(){
      // this.commonService.ShowSpinner();
      //await this.genService.GetDataByQuery("RBAC_Groups").then(res => {
      await this.rbacGroupService.getGroups().then(res => {
         var rbacGrps = res['value'] as RBACGroups[];
   
         rbacGrps.forEach(element => {
           var rbac = new RBACGroups_This();
           rbac.ID = element.ID;
           rbac.GroupName = element.GroupName;
           rbac.IsAdmin = element.IsAdmin;
           rbac.IsSystemGenerated = element.IsSystemGenerated;
           rbac.CreatedBy = element.CreatedBy;
           rbac.CreatedDate = element.CreatedDate;
           rbac.ModifiedBy = element.ModifiedBy;
           rbac.ModifiedDate = element.ModifiedDate;
           this.rbacGroups.push(rbac);
         });
        //  this.commonService.HideSpinner()
       })
  }

  ValidateBeforeSave(): boolean {
    if(this.customer?.SubscriptionType != 'stripe'){
      var customerEmail = this.customer.Email;
      var customerEmailIndex = this.customer.Email.indexOf('@');
      var customerMailName = customerEmail.substr(customerEmailIndex);

      var CurrentUserEmail = this.formDataUser.Email; 
      var CurrentUserEmailIndex = CurrentUserEmail.indexOf('@');
      var CurrentUserMailName = CurrentUserEmail.substr(CurrentUserEmailIndex);

      if(customerMailName == CurrentUserMailName){
        return true
      }
      else{
        this.message = "Domain should be same as " + customerMailName;
        return false
      }
    }
    var userGroupList: RBAC_Users_Groups[] = [];

    this.rbacGroups.forEach(element => {
      
      if(element.IsSelected == true)
      {
        var rbac = new RBAC_Users_Groups();
        rbac.ID = element.rbacUserGroupID;
        rbac.GroupId = element.ID;
        rbac.UserId = this.formDataUser.ID;                
        userGroupList.push(rbac);
      }

    });

    if(userGroupList.length == 0){
      this.messageDialog.openMessageDialog("Please select atleast 1 Group.");
      return false;
    }

    if(this.validateDomain() == false){
      this.messageDialog.openMessageDialog("Domain does not match");
      return false;
    }
    return true;
  }

 async onSubmit(form:NgForm){
   this.commonService.ShowSpinner();
  if (this.ValidateBeforeSave() == false) {
    this.commonService.HideSpinner();
    if (this.message != "") {
       this.messageDialog.openMessageDialog(this.message);
    }
    return;

  }
    await this.BeforeSubmit();

    if (this.authService.CurrentUser() && this.authService.CurrentUser().tid)
        this.formDataUser.tid = this.authService.CurrentUser().tid;
    
    await this.service.postData(this.formDataUser, "RBAC_Users", "Users").subscribe(res => {
      this.commonService.ShowToast("Successfully Created!", ToastType.Success);
      this.afterSubmit();
      this.commonService.HideSpinner();
    }, error => {
      this.commonService.ShowToast(error.message, ToastType.Error);
      this.commonService.HideSpinner();
    });
  }

  BeforeSubmit(){  
    
    var userGroupList: RBAC_Users_Groups[] = [];

    

    this.rbacGroups.forEach(element => {
      
      if(element.IsSelected == true)
      {
        var rbac = new RBAC_Users_Groups();
        rbac.ID = element.rbacUserGroupID;
        rbac.GroupId = element.ID;
        rbac.UserId = this.formDataUser.ID;                
        userGroupList.push(rbac);
      }

    });


   


    this.formDataUser.RBAC_Users_Groups = userGroupList;

    // Getting email
    // var ID =  this.formDataUser.ID;
    //  var selectedUser = this.rbacusers.filter(function(x){
    //    return x.ID == ID;
    //  })

    //form.value.Password = selectedUser[0].Password;
    //form.value.FirstName = selectedUser[0].FirstName;
    //form.value.LastName = selectedUser[0].LastName;

  }

  afterSubmit(){
    this.dialogRef.close();
  }

  async getDomains(){
    this.commonService.ShowSpinner();
    await this.domainService.getDomainLists().then(res=>{
      let items = res['value'] as any[];
      this.domains = items;
      this.commonService.HideSpinner();
    },error=>{
      this.commonService.HideSpinner();
    })
  }

  validateDomain():boolean{
    var emailDomain:string = null;
    var emailSplit = String(this.formDataUser.Email).split('@');
    var isDomainExists:boolean = false;

    if(emailSplit.length > 1){
      emailDomain = emailSplit[1]
    }

    isDomainExists = this.domains.some(a=> a.DomainName == emailDomain)

    // for (let index = 0; index < this.domains.length; index++) {
    //   const element = this.domains[index] as Domain;
     
    //   if(element.DomainName.toLowerCase() == emailDomain.toLowerCase()){
    //     isDomainExists = true;
    //   }

    // }

    return isDomainExists;
  }
}

export class RBACGroups_This {
  ID : number = 0;
  GroupName : string = null;
  IsAdmin : boolean = null;
  CreatedBy : number = null;
  CreatedDate : Date = null;
  ModifiedBy : number = null;
  ModifiedDate : Date = null;
  IsSystemGenerated : boolean = null;
  IsSelected : boolean = null;
  rbacUserGroupID :number = 0;
}