import { Component, OnInit } from '@angular/core';
import { LoginUser } from 'src/app/Base/User/login-user';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { TokenService } from 'src/app/services/token.service';
import {Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-already-logged-in',
  templateUrl: './already-logged-in.component.html',
  styleUrls: ['./already-logged-in.component.scss']
})
export class AlreadyLoggedInComponent implements OnInit {

  email:string = "";

  constructor(
    public router: Router,
    public tokenService:TokenService,
    private authService: MsalService,  
    private authenticationService :AuthenticationService
  ) {
    // this.email = LoginUser.loggedinUser.Email;
    this.email = this.authenticationService.CurrentUser().email;
   }

  ngOnInit(): void {
    
  }

  IsshowProgress:boolean = false;
  logout() {
    this.authenticationService.logout();   
    return;
   
  }

  home(){
    this.IsshowProgress = true;
    this.router.navigate(["/calendar"]);
    this.IsshowProgress = false;
  }

}
