<app-side-bar (onToggleSideNav)="onToggleSideNav($event)" [isToggleClick]="navCollapsed"></app-side-bar>

<div class="bdy" [ngClass]="getBodyClass()">
    
    <app-top-bar (navToggleClick)="onHambangerClick()"></app-top-bar>
    <div class="subscription d-none d-md-inline d-lg-inline d-sm-none d-xs-none" [matTooltip]="user?.SubscriptionName"
        [ngClass]="{'purple-gradient':user.Subscription == 'free', 'premium-gradient':(user.Subscription == 'premium' || user.Subscription == 'organization'), 'peach-gradient':user.Subscription == 'enterprise','basic-gradient':user.Subscription == 'basic'}">
        <span> {{user?.Subscription}}</span>  
        <!-- <span *ngIf="user?.IsFreeTrial"> (TRIAL)</span>
        <span class="trialdaysleft" *ngIf="user.IsFreeTrial">
            {{user?.TrialRemainingDays}} days left 
        </span> -->
    </div>
    <div class="container-fluid">
        <div #alert class="alert alert-info alert-dismissible fade show slogan" 
            style="        
            color: #6c6972;
            background-color: #f2f6f7;
            border-color: #bee5eb;
            font-weight: 500;" role="alert" [hidden]="theme.hideFreeSolgan"
            *ngIf=" (user?.Subscription == 'free' || user?.IsFreeTrial == true) && isAdmin == true && isOwner == true">
            <!-- <button type="button" class="close" aria-label="Close" (click)="closeAlert()">
                <span aria-hidden="true">&times;</span>
            </button> -->
        
            You are using Free Trial. Your trial will be expired in {{user?.TrialRemainingDays}} days.
            <!-- <a (click)="upgrade()" style="text-decoration:underline;" class="alert-link">Click here</a> to -->
                <!-- Subscribe to 
                <span (click)="upgrade()" class="premium-gradient" style="cursor: pointer;padding: 6px;
                color: white;
                border-radius: 8px;">Premium</span> -->
                <span>
                <button class="ml-2"  (click)="upgrade()"
                    style="
                   border: none;
                  
                   color: white;
                   font-weight: 600;
                   BACKGROUND-COLOR: cornflowerblue;
                   BORDER-RADIUS: 15PX;
                   PADDING: 6PX 22PX;">Upgrade</button>
                <button class="ml-2"  (click)="closeAlert()"
                 style="
                border: none;
                background: #f1f5f6;
                color: cornflowerblue;
                font-weight: 600;
                BACKGROUND-COLOR: white;
                BORDER-RADIUS: 15PX;
                PADDING: 2PX 22PX;">Close</button>
            </span>
        </div>
        <router-outlet></router-outlet>
    </div>
</div>

<ngx-guided-tour ></ngx-guided-tour>