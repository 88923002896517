import { Injectable } from '@angular/core';
import { TokenUser } from '../models/tokenuser.model';
import { TokenService } from './token.service';
import * as customConfig from 'src/app/customConfig.json';
import { AuthenticationService } from '../helpers/authentication.service';
import { Router } from '@angular/router';
import { CustomersService } from './customers.service';
import { MessageDialogeService } from '../message-dialoge/message-dialoge.service';
import { Customer } from '../models/customer.model';

@Injectable({
  providedIn: 'root'
})
export class SignInUserService {

  constructor(
    private tokenService: TokenService,  
    private authService: AuthenticationService,
    private router: Router,  
    public customersService:CustomersService,
    public messageDialog: MessageDialogeService,
  ) { }

  
  async getTenants(tid, email) {
    return await this.customersService.getTenants(tid, email)
  }

  public async processSignin(decoded: any, token:string) {
  
    if (decoded.tid) {
      if (decoded.tid == customConfig.tenantId) {
        this.signInProcess_SameTenant(decoded, token);
      }
      else if (decoded.tid != customConfig.tenantId) {
        var email:string = "";
        if (decoded.preferred_username)
          email = decoded.preferred_username;
          else
          email = decoded.email;

          debugger;

        await (await this.getTenants(decoded.tid, email)).toPromise().then(async res => {

          if (res) {
            var customerMarketPlace: any[] = res as any[];
            if (customerMarketPlace.length > 1) {
              this.router.navigate(["/select-tenant"], { queryParams: { tid: decoded.tid, token: token, email:email } });
            } else {
              await this.signInProcess_DifferentTenant(decoded, token);
            }
          } else {
            debugger;
            this.router.navigate(["/requiredsubscription"]);
          }
        }, error => {
          if (error.error.text) {
            if (String(error.error.text).indexOf("Error:") == 0) {
              this.messageDialog.openMessageDialog(error.error.text);
            }
            else {
              this.messageDialog.openMessageDialog(error.message);
            }
          }
          else
            this.messageDialog.openMessageDialog(error.message);
        });

      }
    } else if (token && token.search("ess_denied&error_description=AADB2C90118") !== -1) {
      window.location.href = this.getResetPasswordUrl();
    } else {
      this.router.navigate(["signup"]);
    }

  }

  signInProcess_SameTenant(decoded: any, token: any) {

    var ownerId = null;
    ownerId = '1';
    //if (decoded.extension_ownerId) 
    {
      //ownerId = decoded.extension_ownerId;
      var objUser: TokenUser = new TokenUser();
      objUser.Id = decoded.sub;
      objUser.OwnerId = ownerId;
      objUser.Name = decoded.name;
      objUser.token = token;
      objUser.tid = decoded.tid;
      objUser.sessionTimeout = this.tokenService.sessionTimeout();
      objUser.email = decoded.email;
      this.authService.login(objUser);
      this.router.navigate(["calendar"]);
    }

  }

  async signInProcess_DifferentTenant(decoded: any, token: any) {
  
    if (decoded) {

      var objUser: TokenUser = new TokenUser();
      objUser.Id = decoded.sub;
      objUser.token = token;
      objUser.tid = decoded.tid;

      if (decoded.preferred_username)
        objUser.email = decoded.preferred_username;
      else
        objUser.email = decoded.email;

      if (decoded.name)
        objUser.Name = decoded.name;
      else
        objUser.Name = objUser.email;

      objUser.sessionTimeout = this.tokenService.sessionTimeout();


      //create customer object and save in sql database
      var userNameSplit: string[] = objUser.Name.split(' ');
      var objCustomer = new Customer();
      if (userNameSplit.length > 0)
        objCustomer.FirstName = userNameSplit[0];

      if (userNameSplit.length > 1)
        objCustomer.LastName = userNameSplit[1];

      objCustomer.OrganisationName = "";
      objCustomer.Email = objUser.email;
      objCustomer.tid = objUser.tid;
      objCustomer.AzObjectId = objUser.Id;
      console.log('Customer object while signin', objCustomer);
      //debugger;


      await this.customersService.post(objCustomer, token).subscribe( res => {
        console.log('customer after signup', res);
        //var usr:User = this.authService.CurrentUser();
        objUser.OwnerId = String(res['ID']);
        
        this.authService.login(objUser);
        localStorage.removeItem("fragment");
        this.router.navigate(["calendar"]);
        console.log("Customer Successfully Saved!");
      }, error => {
        window.location.reload();
        console.log(error);
        //this.messageDialog.openMessageDialog("Signup not succeeded. Please send an email to contact@venuearc.com.");
      })

    }
  }

  
  getResetPasswordUrl():string{ 
    var url = `${customConfig.instance}/${customConfig.domain}/oauth2/v2.0/authorize?p=${customConfig.passwordResetPolicy}&client_id=${customConfig.clientId}&redirect_uri=${customConfig.callbackPathBase}/resetpassword&scope=${customConfig.scope}&response_type=id_token&prompt=login`;
    return url
  }

}
