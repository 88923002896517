import { Event_Dates } from "./event-info/Event_Dates.model";
import { Event_SubEvent } from "./event-info/Event_SubEvent.model";
import { Event_Days } from "./event-info/Event_Days.model";
import { Client } from '../client/client.model'
import { Contactperson } from '../client/contactperson.model'
import { eventcategory } from '../../models/eventcategory'
import { eventTicketType } from '../../models/eventTicketType'
import { Genre } from '../../models/genre.model'
import { Venue } from '../../models/venue.model'


export class EventDetails {
    ID: number = 0;
    EventName: string = null;
    EventStartDate: any = null;
    EventEndDate: any = null;
    VenueID: number = null;
    EntryNo:string = null;
    ClientID: number = null;
    SignatoryID: number = null;
    ContactPersonID: number = null;
    AdditionalContactPersonID: number = null;
    GenreID: number = null;
    EventTypeID: number = null;
    CategoryID: number = null;
    Attendance: number = null;
    IsSecured: boolean = null;
    NoOfDays: number = null;
    NoOfPerformances: number = null;
    IsReschedule: boolean = null;
    RescheduleReason: string = null;
    OriginalEventStartDate: Date = null;
    OriginalEventEndDate: Date = null;
    RescheduleSub: string = null;
    IsBooked:boolean = null;
    CreatedBy: number = null;
    CreatedDate: Date = null;
    ModifiedBy: number = null;
    ModifiedDate: Date = null;
    Event_Dates:Event_Dates[] = [];
    Event_SubEvent:Event_SubEvent[] = [];
    Event_Days:Event_Days[] = [];
    Client:Client = new Client();
    Client_ContactPerson : Contactperson =  new Contactperson();    
    Client_ContactPerson1 : Contactperson =  new Contactperson();    
    EventCategory : eventcategory = new eventcategory();
    EventTicketType :eventTicketType = new eventTicketType(); 
    Genre :Genre =  new Genre();                   
    Venue :Venue = new Venue ();

     //ICollection<ClientPayment> ClientPayments 
    
     //ICollection<ClientPayment_Detail> ClientPayment_Detail 
    
     //ICollection<Event_Contract> Event_Contract 
    
     //ICollection<Event_Contract_Details> Event_Contract_Details 
                                  
            
}
