import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http'
import * as customConfig from 'src/app/customConfig.json';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationParameters } from 'msal';
import { RedirectRequest } from '@azure/msal-browser';
import { AuthenticationService } from 'src/app/helpers/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class MarketPlaceService {
  readonly rootURL = customConfig.api
  authToken:AccessTokenResult  = null;
  constructor(
    private http: HttpClient,
    private router: Router,
    private msalAuthService: MsalService,
    private authService: MsalService,
    private authenticationService: AuthenticationService,
  ) { }

  async GetAccessToken() {
    return await this.http.get(this.rootURL + "api/MarketPlaceAccessTokenController/PostAccessToken").toPromise().then(res =>{
      console.log(res);
      this.authToken = res as AccessTokenResult;
    });
  }

  async ResolveSubscription(marketplacetoken: string) {
    debugger;
    if (this.authToken == null || this.authToken == undefined)
      var a = await this.GetAccessToken();

    await this.http.get(this.rootURL + "api/SaasFulfilmentController/ResolveSubscription", this.getHeaders(marketplacetoken)).toPromise().then(res => {
      if (res != null && res['subscription'] != undefined) {
        
        localStorage.removeItem("mptoken");    
        this.authenticationService.redirectToMicrosoftLogin();
        //this.router.navigate(["/sso"]);
        
        
        
      } else {
        alert("Something went wrong. Contact Service Provider.")
      }
      console.log("Market Place token Resolved", res);
      //this.router.navigate([""]);
    }, error => {
      console.log(error);
    })

  }

  
  loginWithMicrosoft(){
    
    var authRequestConfig= {
      domainHint: "login.microsoftonline.com",
      redirectUri: customConfig.redirectUri //"http://localhost:4200/signin"
    };

    this.authService.loginRedirect(authRequestConfig as RedirectRequest);
  }

  async ActivateSubscription(planId:string, quantity:string) {

    if(this.authToken == null)
      var a  = await this.GetAccessToken();
    
    var obj = {
      planId: planId,
      quantity: quantity
    }

    return await this.http.post(this.rootURL + "api/SaasFulfilmentController/ActivateSubscription", obj, this.getHeaders());
  }


  async GetSubscriptionById(subscriptionId: string) {
    if(this.authToken == null)
      var a  = await this.GetAccessToken();

    return await this.http.get(this.rootURL + "api/SaasFulfilmentController/GetSubscriptionByID", this.getHeaders("", subscriptionId))
  }

  async GetAllSubscription() {
    if(this.authToken == null)
      var a  = await this.GetAccessToken();

    return await this.http.get(this.rootURL + "api/SaasFulfilmentController/GetAllSubscriptions", this.getHeaders())
  }


  async GetAvailablePlans() {
    if(this.authToken == null)
      var a  = await this.GetAccessToken();

    return await this.http.get(this.rootURL + "api/SaasFulfilmentController/GetAvailablePlans", this.getHeaders())
  }

  async ChangeSubscriptionPlan(planId:string) {
    if(this.authToken == null)
      var a  = await this.GetAccessToken();

    var obj = {
      planId: planId,   
    }

    return await this.http.post(this.rootURL + "api/SaasFulfilmentController/ChangeSubscriptionPlan", obj, this.getHeaders())
  }

  async ChangeSeatsQuantity(quantity:string) {
    if(this.authToken == null)
      var a  = await this.GetAccessToken();

    var obj = {
      quantity: quantity,   
    }

    return await this.http.post(this.rootURL + "api/SaasFulfilmentController/ChangeSeatsQuantity", obj, this.getHeaders())
  }

  async CancelSubscription() {   
    if(this.authToken == null)
      var a  = await this.GetAccessToken();
      
    return await this.http.get(this.rootURL + "api/SaasFulfilmentController/CancelSubscription", this.getHeaders())
  }



  getHeaders(marketplacetoken:string = "", subscriptionId:string = ""){
   
    const httpOptions = {
      headers: new HttpHeaders({                
//        'Authorization' : 'Bearer ' + this.authToken.access_token,
        'access_token' : 'Bearer ' + this.authToken.access_token,
        'x-ms-marketplace-token': marketplacetoken,        
        "subscriptionId": subscriptionId
      })      
    };

    return httpOptions;
  }
 
}

export class AccessTokenResult
{
    token_type:string = "";
    expires_in:string = "";
    ext_expires_in:string = "";
    expires_on:string = "";
    not_before:string = "";
    resource:string = "";
    access_token:string = "";
}
