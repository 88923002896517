<mdb-spinner *ngIf="showSpinner" spinnerColor="blue"></mdb-spinner>
<a *ngFor="let data of dataAudit" class="py-2">
    <div class="d-flex" style="margin-bottom: 26px;">
        <div>
            <div class="d-flex px-2">
                <span class="iconDiv d-flex align-items-center justify-content-center">
                    <!-- <mat-icon style="color: #82be84;">add</mat-icon> -->

                    <mat-icon *ngIf="data.Action == 'ADD'" style="color: #095e8f">add</mat-icon>
                    <mat-icon *ngIf="data.Action == 'UPDATE'" style="color: #095e8f">edit</mat-icon>
                    <mat-icon *ngIf="data.Action == 'DELETE'" style="color: #095e8f">delete</mat-icon>


                </span>
            </div>
        </div>
        <div class="col-md-10">
            <div>
                <div class="d-flex justify-content-between">
                    <p>{{data.SectionName}}</p>
                    <p style="font-size: 12px;" class="text-muted">{{data.ModifiedDate | date:'short'}}</p>
                </div>
                <p style="margin-top:-14px" class="textMute">{{data.Message}}</p>
            </div>
            <div style="margin-top: 8px;">


                <div *ngIf="data.Action == 'ADD'" class="addIconDiv">

                    <p style="margin-bottom: 0rem; font-size: 14px; color: #1da152; ">
                        Created</p>
                </div>

                <div *ngIf="data.Action == 'DELETE'" class="removeIconDiv">

                    <p style="margin-bottom: 0rem; font-size: 14px; color: #9b170c;">
                        Removed</p>
                </div>

                <div *ngIf="data.Action == 'UPDATE'" class="editIconDiv">

                    <p style="margin-bottom: 0rem; font-size: 14px; color: #a3a30c;">
                        Modified</p>
                </div>

            </div>
        </div>
    </div>
</a>

<!-- <a class="py-2">
    <div class="d-flex"  style="margin-bottom: 26px;">
        <div>
            <div class="d-flex px-2">
                <span class="iconDiv d-flex align-items-center justify-content-center">
                
                    <mat-icon style="color: #095e8f">delete</mat-icon>
                  


                </span>
            </div>
        </div>
        <div class="col-md-10">
            <div style="line-height: 0.4;">
                <div class="d-flex justify-content-between">
                    <p>Event</p>
                    <p style="font-size: 12px;" class="text-muted">10:34 PM</p>
                </div>
                <p class="textMute">Successfully Removed!</p>
            </div>
            <div style="margin-top: 8px;">


                <div class="removeIconDiv">

                    <p style="margin-bottom: 0rem; font-size: 14px; color: #9b170c;">
                        Removed</p>
                </div>

            </div>
        </div>
    </div>
    
</a>
<a>
    <div class="d-flex">
        <div>
            <div class="d-flex px-2">
                <span class="iconDiv d-flex align-items-center justify-content-center">
                

                    <mat-icon style="color: #095e8f">edit</mat-icon>
                  


                </span>
            </div>
        </div>
        <div class="col-md-10">
            <div style="line-height: 0.4;">
                <div class="d-flex justify-content-between">
                    <p>Event</p>
                    <p style="font-size: 12px;" class="text-muted">7:41 PM</p>
                </div>
                <p class="textMute">Successfully Modified!</p>
            </div>
            <div style="margin-top: 8px;">


                <div class="editIconDiv">

                    <p style="margin-bottom: 0rem; font-size: 14px; color: #a3a30c;">
                        Modified</p>
                </div>

            </div>
        </div>
    </div>

</a> -->