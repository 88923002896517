<div>
    <div class="content-container dialogHeader" style="display: flex;align-items: center;justify-content: space-between;">

        <!-- <h6>Error</h6> -->
        <!-- <a mdbBtn floating="true" size="sm" gradient="blue" mdbTooltip="Close"  placement="left" class="float-right" (click)="closeDialoge()" mdbWavesEffect>
            <mdb-icon fas icon="times"></mdb-icon>
        </a> -->
        <!-- style="padding-left: 10px;" -->
        <h5 class="pl-2">Error</h5>
        <button mat-icon-button (click)="closeDialoge()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <!-- <hr> -->
    <!-- <br> -->
    <!-- <br> -->
    <br>
    <div class="container">
        <div class="row">
            <div class="col">
                <span style="color: red;display: flex;word-break: break-word;justify-content: center;align-items: center;" class="content-span full-width">
                    <mdb-icon style="color: red;" fas icon="exclamation-triangle" class="mr-2"></mdb-icon>
                    <span>
                        {{data.message}}!
                    </span>
                </span>
            </div>
        </div>
    </div>
    <br>
    <br>
    <div class="btn-group dialogBtn" role="group" aria-label="Button group with nested dropdown">
        <button mdbBtn type="button"  [class]="theme.button" class="waves-light" mdbWavesEffect [mat-dialog-close]="true">
            <mdb-icon fas icon="check" class="mr-1"></mdb-icon>
            Ok
        </button>
    </div>
</div>