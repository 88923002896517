import { Injectable } from '@angular/core';
import * as customConfig from 'src/app/customConfig.json';
import { LoginUser } from '../Base/User/login-user';
import { HttpClient } from '@angular/common/http';
import { ConfHttpHeadersService } from './conf-http-headers.service';

@Injectable({
  providedIn: 'root'
})
export class AuditService {

  readonly rootURL = customConfig.api; 

  constructor(
    private http: HttpClient,
    private hdr:ConfHttpHeadersService,
    ) { }

    async getAuditData(){

      var obj = {
        customerid:  LoginUser.customer.CustomerObjectId,
        userid: LoginUser.loggedinUser.UserID.toString()
      }

      return await this.http.post(this.rootURL + "api/Audit/GetAuditData", obj, this.hdr.getHeaders()).toPromise();
    }
}
