import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { GuidedTour, GuidedTourService, Orientation } from 'ngx-guided-tour';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/Base/Common.service';
import { SharePointConfigService } from 'src/app/Base/SharePoint/share-point-config.service';
import { LoginUser } from 'src/app/Base/User/login-user';
import { UserService } from 'src/app/Base/User/user.service';
import { CustomersService } from 'src/app/services/customers.service';
import { GeneralService } from 'src/app/services/general.service';
import { SPOperationsService } from 'src/app/services/spoperations.service';
import { TourGuideService } from 'src/app/services/tour-guide.service';
import { DummyDataComponent } from 'src/app/setupForms/dummy-data/dummy-data.component';
import { Event } from '../../event/event-info/Event.model';
import { QuickEventComponent } from '../../event/quick-event/quick-event.component';
import { QuickEventMdbComponent } from '../../event/quick-event-mdb/quick-event-mdb.component';
import { themeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss']
})
export class WelcomePageComponent implements OnInit {
  
  user: any = LoginUser.loggedinUser;
  public modalRef: MDBModalRef;
  
  constructor(
    public userService: UserService,
    public genService: GeneralService,
    public router: Router,
    public sp: SPOperationsService,
    public common: CommonService,
    public spConfigService: SharePointConfigService,
    public customersService: CustomersService,
    public modalService: MDBModalService,
    public dialog: MatDialog,
    public guidedTourService: GuidedTourService,
    public changeDetectorRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<WelcomePageComponent>,
    public tourService:TourGuideService,
    public theme:themeService,
    
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
     this.tourService.onActionDesk.subscribe((data) => {
      if(data == true){
        this.data.onClicked(true,false);
      }
      else if(data == false){
        this.data.onClicked(true,true);
      }
    });

    this.tourService.onCompleteDesk.subscribe((data) => {
      if(data == true){
        this.onCompleteTour()
      }
    });
    this.tourService.onSkipDesk.subscribe((data) => {
      if(data == true){
        this.onSkipTour()
      }
    });
  }

  async openQuickEventForm() {
    // const dialogQuick = await this.dialog.open(QuickEventComponent, {
    //   // disableClose: true ,
    //   // disableClose: true ,
    //   height: '88%',
    //   maxHeight: '100%',
    //   width: '50%',
    //   minWidth: '52%',
    //   panelClass: 'dialog-panel',
    //   position: {
    //     right: 10 + '%',
    //     top: 2 + '%',
    //     left: 24 + '%',
    //     bottom: 0 + '%',
    //   },
    //   autoFocus: false,
    // })

    // dialogQuick.afterClosed().subscribe(res => {
    //   if (res == "Submit") {

    //     this.dialogRef.close("Submit")
    //   }
    // })
    this.modalRef = this.modalService.show(QuickEventMdbComponent, {
      // backdrop: false,
      // keyboard: false,
      // focus: true,
      // show: false,
      // ignoreBackdropClick: false,
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class:  'modal-side modal-full-height modal-right modal-lg',
      containerClass: 'right',
      animated: true,
      styles: "overflow-y: none",


    });

    this.modalRef.content.action.subscribe(result=>{
      if (result.status == "Submit") {
        this.dialogRef.close("Submit");
      }
    })
  }

  onSkip() {
    this.theme.skipWelcomePage = true;
    this.dialogRef.close("Skip");
  }

  quickTour() {
    this.changeDetectorRef.detectChanges();
    this.tourService.showTour(this.tourService.deskTour);
    this.changeDetectorRef.detectChanges();
  }


  async openDummyData() {

    const dialogRef = await this.dialog.open(DummyDataComponent, {
      disableClose: true,
      // height: '100%',
      // maxHeight: '100%',
      width: '90%',
      // minWidth: '60%',
      height: '90%',
      autoFocus: true,
      hasBackdrop: true,
      // maxWidth:'47vw',
      // position:{
      //   left:'50%'
      // }
      // panelClass:'welcome-dialog'
    })

    dialogRef.afterClosed().subscribe(res => {

    })
  }

  onSkipTour(){
    this.data.onClicked(true,true);
    this.dialogRef.close();
  }

  onCompleteTour(){
    this.dialogRef.close();
  }
}
