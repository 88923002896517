<div class="myForm mt-5">
    <form class=" p-5 mb-5 rounded border-form" >
        <div class="d-flex justify-content-center">
            <div class="logo">
                <img src="assets/venuearc_logo.png" alt="Logo" style="width: 300px;">                
            </div>
        </div>

        <div class="row">

            <div class="col-md-12 pt-5 pb-3 text-center">
                <h2 style="color:#0e83a8;">Log In</h2>
            </div>
        </div>


        <div class="row">
        
            <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                <mat-form-field class="example-full-width">
                    <mat-label>Email or Mobile Number</mat-label>
                    <input matInput class="MatInputBoxShadow" required name="UserId" #UserId="ngModel" [(ngModel)]="userId"
                        type="text" autocomplete="off">
                    <mat-error *ngIf="UserId.invalid || UserId.dirty">This field is required</mat-error>
                </mat-form-field>
            </div>
        
            <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                <mat-form-field  class="w-100">
                    <mat-label>Password</mat-label>
                    <input matInput class="MatInputBoxShadow" required name="Password" #Password="ngModel" [(ngModel)]="password"
                    [type]="hide ? 'password' : 'text'" autocomplete="off">
                    <button tabindex="-1" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>                    
                    <mat-error *ngIf="Password.invalid || Password.dirty">This field is required</mat-error>
                </mat-form-field>
            </div>
        </div>

        <mat-error>{{errorMessage}}</mat-error>

       

        <div class="row">
            <div class="col-md-12 mt-4">
              
                <button [disabled]="validate()" mdbBtn color="info" class="waves-light w-100" mdbWavesEffect
                    type="button" (click)="openLink()">
                    Sign In
                </button>

            </div>
        </div>

        <div class="links">
                                   
            <a mat-stroked-button >Forget Password?</a>
            <a mat-stroked-button (click)="signup()">Sign up New Account</a>
        </div>

    </form>
</div>