<!-- <div class="d-flex justify-content-center">
    <div class="logo">
        <img src="assets/venuearc_logo.png" alt="Logo" style="width: 300px;">
    </div>
</div>     -->
<div class="container-fluid mt-3">
    <div class="row">
        <div class="col-md-1 col-sm-1 col-lg-1"></div>
        <div class="col-md-6 col-lg-6 col-sm-6 ">
            <!-- <img src="assets/signup.png" height="100%" width="100%" alt=""  srcset=""> -->
            <img src="assets/Event-management.jpg" height="100%" width="100%" alt=""  srcset="">
            <!-- <div style="background-image:url(assets/signup.png);height: 100%;width: 100%; background-size: cover;">
                <div class="text-center">
                    <img src="assets/venuearc_logo.png" alt="Logo" style="width: 350px;">
                    
                </div>
            </div> -->

        </div>
        <div class="col-md-4 col-lg-4 col-sm-4 shadow">
            <form class=" pt-4 mb-5 rounded  border-form">
                <div class="row">
                    <div class="col-md-12  pb-3 text-center">
                        <h2 style="color:#0e83a8;">Sign up</h2>
                        <p>Create your new account</p>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                        <!-- <app-select-signup ></app-select-signup>                 -->
                        <app-register-customer></app-register-customer>
                        <div>
                            <p class="text-center pt-3"> Already have an account? <a href="">Log In</a> </p>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                        <button mdbBtn color="success" class="waves-light w-100" mdbWavesEffect type="button">
                            <mdb-icon fab icon="microsoft"></mdb-icon>
                            <span class="ml-2"> Sign Up with Azure Marketplace </span>
                        </button>
                        <button mdbBtn color="success" class="waves-light w-100" mdbWavesEffect type="button">
                            <mdb-icon fab icon="microsoft"></mdb-icon>
                            <span class="ml-2"> Sign Up with Microsoft App Source </span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>