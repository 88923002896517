import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { CommonService } from 'src/app/Base/Common.service';
import { themeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-request-permium-from-basic',
  templateUrl: './request-permium-from-basic.component.html',
  styleUrls: ['./request-permium-from-basic.component.scss']
})
export class RequestPermiumFromBasicComponent implements OnInit {

  constructor(
    // public modalRef: MDBModalRef,
    public commonService:CommonService,
    public dialogRef: MatDialogRef<RequestPermiumFromBasicComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public theme:themeService,
    public router:Router,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    
  }

  upgradeToPremium(){
    // this.modalRef.hide();
    this.dialogRef.close();
    this.router.navigate(['/membership/subscribe'],{queryParams:{b:1}});
  }

  onClose(){
    this.dialogRef.close()
  }
}
