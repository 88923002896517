
<br>
<div class="">

    <div class="">
        
      <div class="row">
        <div class="col-md-6 text-left pl-3">

          <div class="chip " style="margin-bottom: 0.5rem !important;"                  
          *ngFor="let ven of venueMonthNotesList ; let i = index;"
          (click)="onChipVenueClick(ven.ID)"
          [ngClass]="{'selectedChip':selectedChipVenueID == ven.ID }">
              <div class="chipArea" style="border: solid;border-width: 1px;border-color: #d1dee3;text-align:center;"> 
                {{ven.ShortCode}} 
              </div>
              {{ven.VenueName}}  
          </div>                              
        </div> 
        <div class="col-md-6 text-right pr-3">
                           
            
        </div>      
      </div>

      <div style="margin-top:16px;"></div>
      
        <div [hidden]="IsPrint">
                <div class="row text-center">
            
                    <div class=" col-sm-12 col-md-4 calendar_buttonRow">
            
                    <div class="btn-group CalendarActionButton float-left" >
                        <button mdbBtn type="button" color="info"  class="waves-light CalendarNavButtons" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                        (viewDateChange)="closeOpenMonthViewDay(viewDate)" mdbWavesEffect><mdb-icon fas icon="angle-left"></mdb-icon></button>
                        <button mdbBtn type="button"  color="info" class="waves-light CalendarNavButtons" mwlCalendarToday [(viewDate)]="viewDate"
                        (viewDateChange)="closeOpenMonthViewDay(viewDate)" mdbWavesEffect>Today</button>
                        <button mdbBtn type="button"  color="info" class="waves-light CalendarNavButtons" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                        (viewDateChange)="closeOpenMonthViewDay(viewDate)" mdbWavesEffect><mdb-icon fas icon="angle-right"></mdb-icon></button>
                        <mat-form-field  appearance="fill" class="matDateFieldCalendar ">
                        <mat-label>Jump to Month</mat-label>
                        <input matInput [matDatepicker]="picker" [value]="viewDate"
                            (dateChange)="filterCalendar('change', $event)">
                        <mat-datepicker-toggle matSuffix [for]="picker" style="    color: #33b4e4;"></mat-datepicker-toggle>
                        <mat-datepicker  #picker  
                            startView="year"
                            (monthSelected)="chosenMonthHandler($event, picker)"
                            panelClass="example-month-picker">
                        </mat-datepicker>
                        </mat-form-field>
                    </div>
            
                    </div>
            
                    <div class=" col-sm-12 col-md-4 text-center calendar_buttonRow">
                    <h1 class="MonthHeading">  
                        <mdb-badge *ngIf="view === CalendarView.Month" color="white" style="color: gray !important;">
                            {{ viewDate | date:'MMMM, yyyy' }}
                        </mdb-badge>
                        
                    </h1>
                    </div>
            
                    <div class=" col-sm-12 col-md-4  calendar_buttonRow">
            
                    <div class="btn-group CalendarActionButton float-right" role="group" aria-label="Basic example">
                        <button mdbBtn type="button" color="info" class="waves-light CalendarNavButtons" (click)="setView(CalendarView.Month)"
                        [class.active]="view === CalendarView.Month" mdbWavesEffect>Month</button>    
                        <button mdbBtn [disabled]="true" type="button"  color="info" class="waves-light CalendarNavButtons" (click)="setView(CalendarView.Week)"
                        [class.active]="view === CalendarView.Week" mdbWavesEffect>Week</button>
                    <button mdbBtn [disabled]="true" type="button"  color="info" class="waves-light CalendarNavButtons"(click)="setView(CalendarView.Day)"
                        [class.active]="view === CalendarView.Day" mdbWavesEffect>Day</button>   
                            
                    </div>        
                    
            
                    </div>
                
                
                </div>
                <br />
            
                <!-- (dayClicked)="OpenEventModal($event)" -->
            
                <div [ngSwitch]="view">
                    <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
                    [refresh]="refresh" (eventTimesChanged)="eventTimesChanged($event)" [cellTemplate]="customCellTemplate"
                    (columnHeaderClicked)="clickedColumn = $event.isoDayNumber" 
                    [tooltipTemplate]="cellTooltipTemplate">
                    </mwl-calendar-month-view>      
                </div>
        </div>

        <div [hidden]="!IsPrint">
            <app-avail-report [InputVenueID]="selectedChipVenueID" [InputMonthDate]="viewDate"></app-avail-report>
        </div>

    </div>
    <br>
        
  </div>  
  
    <br /><br /><br />
     
    <ng-template #customCellTemplate let-day="day" let-locale="locale" let-eventClicked="eventClicked"
      let-tooltipPlacement="tooltipPlacement" let-tooltipTemplate="tooltipTemplate"
      let-tooltipAppendToBody="tooltipAppendToBody" let-tooltipDelay="tooltipDelay">
  
      
  
      <div class="cal-cell-top" [matMenuTriggerFor]="DayMenu" >  
        <div class="">       
          <div class="">           
              <span class="cal-day-number">               
                  {{ day.date | calendarDate:'monthViewDayNumber':locale }}        
                </span>             
          </div>        
        </div>
       
        <mat-menu  #DayMenu="matMenu" yPosition="below" >         
          <button mat-menu-item (click)="OpenEventModal(day.date)">
            <mat-icon>add</mat-icon>
            <span>Add Event Request</span>
          </button>        
        </mat-menu>


        <div class="cal-events" *ngFor="let event of day.events"
        [mwlCalendarTooltip]="event.title | calendarEventTitle: 'monthTooltip':event"
        [tooltipPlacement]="tooltipPlacement" [tooltipEvent]="event" [tooltipTemplate]="tooltipTemplate"
        [tooltipAppendToBody]="tooltipAppendToBody" [tooltipDelay]="tooltipDelay"
        [style.backgroundColor]="event.color?.primary" class="CalendarEventTile"
        (mwlClick)="eventClicked.emit({event: event})" [matMenuTriggerFor]="menu" >
        
        <!-- For Default Event-->
        <div [ngStyle]="{'color': event.meta.TextColor  != null ? event.meta.TextColor : 'black'}">
            &nbsp;{{event.title}}
            
        </div>

        <mat-menu #menu="matMenu" yPosition="below">
  
          <button mat-menu-item class="IsDesktopMobile" data-toggle="modal" data-target="#basicExample">
            <mat-icon>remove_red_eye</mat-icon>
            <span>View Details</span>
          </button>
         
  
          <button mat-menu-item [routerLink]="['/event/event-request/form']" [queryParams]="{ID:event.ID}">
            <mat-icon>desktop_windows</mat-icon>
            <span>View Details</span>
          </button>
        
        </mat-menu>
      </div>

      </div>
      
                          
    </ng-template>
  

    <ng-template #cellTooltipTemplate let-event="event" let-tooltipPlacement="tooltipPlacement" let-locale="locale" >
   
            <div class="cal-tooltip IsMobile" >
                
              <div class="cal-tooltip-arrow"></div>
              
              <div class="cal-tooltip-inner EventToolTip" [tooltipPlacement]="tooltipPlacement"
                style="background-color:transparent" >
                               
                <div class="TableToolTipCalendar" style="background-color: white;">
                  <table mdbTable class="z-depth-1 InputTable" >    
                   
                    <tbody>

                      <tr >
                        <td data-label="Start Date">{{event.start | date:'mediumDate'}}</td>
                        <td data-label="End Date">{{event.end | date:'mediumDate'}}</td>
                        <td data-label="Venue">{{event.meta.VenueName}}</td>
                        <td data-label="Client">{{event.meta.ClientName}}</td>
                        <td data-label="Contact Person">{{event.meta.ContactPersonName}}</td>
                        <td data-label="Category">{{event.meta.CategoryName}}</td>    
                        <td data-label="Genre">{{event.meta.GenreName}}</td>  
                        <td data-label="Ticket Type">{{event.meta.TicketTypeName}}</td>                    
                        <td data-label="Modified By">{{event.meta.ModifiedBy}}</td>
                        <td data-label="Modified Date">{{event.meta.ModifiedDate | date:'MM/d/yyyy'}}</td>
                      </tr>
  
                     
                    </tbody>
                  </table>
                </div>
                
  
              </div>
           
            </div>
                               
    </ng-template>

    



  
  
  






