<!--  Safwan Changes - Start -26/01/2023 -->
<div class="container-fluid  pt-5 pb-5" [ngClass]="borderForm == 'Company Information' ? 'bg-frm': borderForm == 'Venue Information' ? 'bg-frm-venue' : 'bg-frm-user'">
<!--  Safwan Changes - End -26/01/2023 -->
  <div class="myForm shadow d-flex align-items-center "
  [ngClass]="borderForm == 'Company Information' ? 'border-form-company': borderForm == 'Venue Information' ? 'border-form-venue' : 'border-form-user-done'">

  <!-- <div class="d-md-flex d-lg-flex d-sm-block d-xs-block align-items-center bdy2"> -->
<!-- Safwan Remove 26/01/2023 - mb-5 -->
    <div class="p-4  align-items-center rounded form-bg">

      <div class="d-flex justify-content-center">
        <div class="logo">
          <img src="assets/venue_arc_singup.png" alt="Logo" style="width: 300px;">
        </div>
      </div>

      <div class="mt-5">
        <mat-vertical-stepper #stepper (selectionChange)="onStepClick($event)" class="form-bg">

          <mat-step *ngFor="let option of stepperHandling">

            <ng-template matStepLabel>
              <div class="d-flex">
                {{option.name}}
                <mdb-icon class="ml-1" fas [icon]="option.icon" style="font-size: 18px;color: #06d160"></mdb-icon>
              </div>
            </ng-template>

            <form #organization="ngForm" *ngIf="option.name == 'Company Information'">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                  <mat-form-field class="example-full-width" appearance="legacy">
                    <mat-label>Company Name</mat-label>
                    <input matInput class="MatInputBoxShadow" name="OrganisationName" #OrganisationName="ngModel" required
                      type="text" [(ngModel)]="formDataOrg.OrganisationName" autocomplete="off">
                    <mat-error *ngIf="OrganisationName.invalid && OrganisationName.dirty">This field is
                      required</mat-error>
                  </mat-form-field>
                </div>
                <!-- <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                  <mat-form-field class="example-full-width" appearance="legacy">
                    <mat-label>Contact #</mat-label>
                    <input matInput class="MatInputBoxShadow" name="Phone" #Phone="ngModel" required type="text"
                      [(ngModel)]="formDataOrg.Phone" autocomplete="off">
                    <mat-error *ngIf="Phone.invalid && Phone.dirty">This field is required
                    </mat-error>
                  </mat-form-field>
                </div> -->
                <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                  <mat-form-field class="example-full-width" appearance="legacy">
                    <mat-label>No. Of Employees</mat-label>
                    <input matInput class="MatInputBoxShadow" name="NoOfEmployees" #NoOfEmployees="ngModel" type="number"
                      [(ngModel)]="formDataOrg.NoOfEmployees" autocomplete="off">
                  </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                  <mat-form-field class="example-full-width" appearance="legacy">
                    <mat-label>Website</mat-label>
                    <input matInput class="MatInputBoxShadow" name="Website" #Website="ngModel" required type="text" pattern="^(https?:\/\/)?[a-z0-9\-]+(\.[a-z0-9\-]+)*\.[a-z]{2,6}$"
                      [(ngModel)]="formDataOrg.Website" autocomplete="off">
                    <mat-error *ngIf="Website.errors && Website.errors.required && (Website.dirty || Website.touched)">This field is required</mat-error>
                    <mat-error *ngIf="Website.errors  && Website.errors.pattern" >Website Url is invalid</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label>Country</mat-label>
                    <mat-select name="CountryName" [(ngModel)]="formDataOrg.CountryName" required  (selectionChange)="getMaskbyCountryName(formDataOrg.CountryName)" #CountryName="ngModel">
                      <mat-option>
                        <ngx-mat-select-search name="country" #Location="ngModel" [(ngModel)]="searchUserLead"
                          (ngModelChange)="AutoCompCntry.searchResult.next($event)" placeholderLabel="Search..."
                          noEntriesFoundLabel="Not Found!" [ngModelOptions]="{ standalone: true }">
                        </ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let option of AutoCompCntry.results | async" [value]="option.name"> {{
                        option.name}} {{ option.code}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="CountryName.invalid && (CountryName.touched || CountryName.dirty)">This field is required
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label>Phone</mat-label>
                    <span matSuffix class="mr-1" [hidden]="selectedCntryImg ==null || selectedCntryImg == ''">
                      <img [src]="selectedCntryImg" alt="" height="30px" width="27px">
                    </span>
                    <input matInput class="MatInputBoxShadow" [mask]="selectedMask" [showMaskTyped]="true" name="Phone"
                      #Phone="ngModel" [(ngModel)]="formDataOrg.Phone" type="text" autocomplete="off" required>
                      <mat-error *ngIf="Phone.invalid && (Phone.touched)">This field is required
                      </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-3 col-md-3 col-lg-3">
                  <button mat-stroked-button color="primary" style="height: 34px;" (click)="basicModal.show()">

                    <mat-icon *ngIf="!selectedimg">add</mat-icon> <span *ngIf="!selectedimg">  Add Logo</span>
                     <mat-icon *ngIf="selectedimg">edit</mat-icon> <span *ngIf="selectedimg"> Change Logo</span>
                  </button>
                </div>
                <input type='file' style="display: none;" accept=".png, .jpg" (change)="readURL($event);" #imgInput
                  class="ms-4" />


                <!-- Start -  Modal For Add Logo -->
                <div mdbModal #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
                  aria-labelledby="myBasicModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-lg cascading-modal modal-centered" role="document">
                    <div class="modal-content" style=" height: 545px;width: 100%; ">
                      <div
                        class="modal-header light-blue darken-3 white-text  gradient-card-header blue-gradient narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center">
                        <h4 class="h4-responsive mb-0">
                        Add Logo
                        </h4>
                        <div class="btn-group" role="group" aria-label="Basic example">
                          <a (click)="basicModal.hide()">
                            <button mdbBtn type="button" color="info" size="sm" class="waves-light" mdbWavesEffect>
                              <mdb-icon fas icon="times"></mdb-icon>
                            </button>
                          </a>
                        </div>
                      </div>
                      <div class="modal-body">
                        <div class="row mb-3">
                          <div class="col-md-4"></div>
                          <div class="col-md-4" *ngIf="selectedimg ==null">
                            <div class="imageBox" (click)="imgInput.click()">
                              <div>
                                <a mdbBtn floating="true"  mdbTooltip="Add" placement="left"
                                  size="sm" gradient="aqua" id="addBTN" mdbWavesEffect>
                                  <mdb-icon fas icon="plus"></mdb-icon>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4" *ngIf="selectedimg !=null">
                            <div class="imageBox2">
                              <img [src]="imageSrc" alt="" style="height: 100%; width:100%; border-radius:8px;">

                              <mat-icon class="removeImg" (click)="removeImg()">close
                              </mat-icon>
                            </div>
                          </div>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="row mt-3">
                          <div class="col-md-12 mb-4">
                            <h6 class="text-center">Default logos</h6>
                          </div>

                        </div>
                        <div class="d-flex justify-content-center align-items-center">

                          <div class=" default-logo"  (click)="suggestedImageClick('../../../../assets/company.png')">
                            <img src="../../../../assets/company.png"  style=" height: 80px !important;width: 130px !important;">
                          </div>
                          <div class=" default-logo"  (click)="suggestedImageClick('../../../../assets/company2.png')">
                            <img src="../../../../assets/company2.png"  style=" height: 80px !important;width: 130px !important;">
                          </div>
                          <div class=" default-logo"  (click)="suggestedImageClick('../../../../assets/company3.png')">
                            <img src="../../../../assets/company3.png"  style=" height: 80px !important;width: 130px !important;">
                          </div>
                        </div>
                        <div class="d-flex justify-content-center align-items-center mt-3">
                          <div class=" default-logo"  (click)="suggestedImageClick('../../../../assets/company4.png')">
                            <img src="../../../../assets/company4.png"  style=" height: 80px !important;width: 130px !important;">
                          </div>
                          <div class=" default-logo"  (click)="suggestedImageClick('../../../../assets/company5.png')">
                            <img src="../../../../assets/company5.png"  style=" height: 80px !important;width: 130px !important;">
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button mat-raised-button color="danger" style="height: 34px; margin-right: 10px;"
                          (click)="basicModal.hide()">Close</button>
                        <button mat-raised-button color="primary" style="height: 34px;"
                          (click)="basicModal.hide()">Add</button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End -  Modal For Add Logo -->

              </div>
              <div class="row mt-3">
                <div class="col-md-8 col-sm-8">
                  <p class="text-success" style="font-size:14px" *ngIf="selectedimg">Logo Successfully Added!</p>
                </div>
                <div class="col-md-4 col-sm-4">
                  <button mat-raised-button color="primary" style="height: 34px;"
                    (click)="stepperClick('next',organization)">
                    Next <mdb-icon fas icon="arrow-right" style="padding-bottom: 2px"></mdb-icon></button>
                </div>
              </div>
            </form>

            <form #Venue="ngForm" *ngIf="option.name == 'Venue Information'">
              <div class="row">

                <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" [hidden]="true">
                  <div class="md-form">

                    <input name="ID" #ID="ngModel" [(ngModel)]="formDataVen.ID" type="text" class="form-control" mdbInput>
                    <label>Venue ID</label>

                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                  <mat-form-field class="example-full-width" appearance="legacy">
                    <mat-label>Venue Name</mat-label>
                    <input matInput class="MatInputBoxShadow" required name="VenueName" #VenueName="ngModel"
                      [(ngModel)]="formDataVen.VenueName" type="text">
                    <mat-error *ngIf="VenueName.invalid && VenueName.dirty">This field is required
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                  <mat-form-field class="example-full-width" appearance="legacy">
                    <mat-label>Short Code</mat-label>
                    <input maxlength="2" matInput class="MatInputBoxShadow" required name="ShortCode" #ShortCode="ngModel"
                      [(ngModel)]="formDataVen.ShortCode" type="text">
                    <mat-hint align="start">Max length 2 Characters </mat-hint>
                    <mat-hint align="end">{{formDataVen.ShortCode == null ? 0 :
                      formDataVen.ShortCode.length}}
                      / 2</mat-hint>
                    <mat-error *ngIf="ShortCode.invalid && ShortCode.dirty">This field is required
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                  <mat-form-field class="example-full-width" appearance="legacy">
                    <mat-label>Capacity</mat-label>
                    <input matInput class="MatInputBoxShadow" name="Capacity" #Capacity="ngModel"
                      [(ngModel)]="formDataVen.Capacity" type="number">
                  </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                  <mat-form-field class="example-full-width" appearance="legacy">
                    <mat-label>Currency</mat-label>
                    <mat-select [(ngModel)]="formDataVen.CurrencyID" #CurrencyID="ngModel" name="CurrencyID" required >
                      <mat-option *ngFor="let option of AutoCompCurrency.results | async" [value]="option.ID">
                        {{ option.Symbol }} - {{ option.CurrencyName }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="CurrencyID.invalid && CurrencyID.dirty">This field is
                      required</mat-error>
                  </mat-form-field>
                </div>

              </div>
              <div class="row mt-3">
                <div class="col-md-4 col-sm-4"></div>
                <div class="col-md-8 col-sm-8">
                  <button mat-raised-button color="primary" style="height: 34px; margin-right: 10px;"
                    (click)="stepperClick('back',Venue)">
                    <mdb-icon fas icon="arrow-left" style="padding-bottom: 2px"></mdb-icon> Back</button>
                  <button mat-raised-button color="primary" style="height: 34px;" (click)="stepperClick('next',Venue)">
                    Next <mdb-icon fas icon="arrow-right" style="padding-bottom: 2px"></mdb-icon></button>
                </div>
              </div>
            </form>

            <!-- <form #User="ngForm" *ngIf="option.name == 'User'">

              <div class="row mt-3">
                <div class="col-md-12 col-sm-12">
                  <button mat-raised-button color="primary" style="height: 34px; margin-right: 10px;"
                    (click)="stepperClick('back')">
                    <mdb-icon fas icon="arrow-left" style="padding-bottom: 2px"></mdb-icon> Back</button>
                  <button mat-raised-button color="primary" style="height: 34px; margin-right: 10px;" (click)="addUser()">
                    Add User
                  </button>
                  <button mat-raised-button style="height: 34px; " (click)="stepperClick('next')">
                    Skip Now <mat-icon>skip_next</mat-icon>
                  </button>
                </div>
              </div>
            </form> -->

            <div *ngIf="option.name == 'Done'">
              <div class="row" *ngIf="validate() == true">
                <div class="col-md-12">
                  <mat-error>*Missing required fields.</mat-error>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-4 col-sm-4"></div>
                <div class="col-md-8 col-sm-8">
                  <button mat-raised-button color="primary" style="height: 34px; margin-right: 10px;"
                    (click)="stepperClick('back')">
                    <mdb-icon fas icon="arrow-left" style="padding-bottom: 2px"></mdb-icon> Back
                  </button>
                  <button color="primary" mat-raised-button (click)="OnSubmit()" [disabled]="validate()"
                    style="height: 34px; ">
                    <mdb-icon fas icon="save" style="padding-bottom: 2px"></mdb-icon>
                    Submit
                    <div *ngIf="inProgress !=null && inProgress == true" class="spinner-border spinner-border-sm"
                      role="status">
                      <span class="sr-only">Loading...</span>
                    </div>

                  </button>

                </div>
              </div>
            </div>
          </mat-step>
        </mat-vertical-stepper>
      </div>
    </div>
  <!-- </div> -->

</div>
</div>
