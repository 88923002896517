import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http'
import * as customConfig from 'src/app/customConfig.json';
import { AuthenticationService } from '../helpers/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AzblobStorageService {
  //public rootURL = customConfig.storageaccounturl + '/' + customConfig.DBPrefix ; 
  public rootURL = customConfig.storageaccounturl; 
  public catalogdb = customConfig.catalogdb; 

  constructor(
    private authenticationService :AuthenticationService
    ) { }

  DownloadFile(fileLocation:string){        
    var downloadUrl = this.rootURL + this.catalogdb  + this.getOwnerId() + '/' + fileLocation;


    const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = this.getfileName(fileLocation);
      document.body.appendChild(a);
      a.click();    
   }

   
  getFileLocation(fileObj: any){        
    var Url = this.rootURL +  fileObj[0]?.ContainerName + "/" + fileObj[0]?.Name;
    return Url; 
   }

 


  getfileName(blobName: string): string {
    var Name = blobName.split('/');
    return Name[Name.length - 1];
  }

  getOwnerId():string{
    if(!this.authenticationService.CurrentUser())
    return ;

    var ownerid = this.authenticationService.CurrentUser().OwnerId;
    return ownerid;
  }
}
