import { Injectable } from '@angular/core';
import { EventDocumentService } from './event-document.service';
import { AzblobStorageService } from './azblob-storage.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(
    public eventDocumentService: EventDocumentService,
    public azblobStorageService: AzblobStorageService,
  ) { }

  showImage:any=null
  async getOrganizationLogo() {
    await this.eventDocumentService.getAll('system/logo').toPromise().then((res: any) => {
      if (res.length > 0) {
        this.showImage = this.azblobStorageService.getFileLocation(res);
      }
    })
  }
}
