import { Component, OnInit,  } from '@angular/core';
import { UserService } from './Base/User/user.service';
import { TokenService } from './services/token.service';
import { GeneralService } from './services/general.service';
import { LoginUser } from './Base/User/login-user';
import { Subject, Subscription } from 'rxjs';
//import { MsalService, BroadcastService } from '@azure/msal-angular';

import { HttpClient } from '@angular/common/http';
import * as customConfig from 'src/app/customConfig.json';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
  //encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit{

  /*MSAL WORK*/ // 04/05/2022
  accountName = "";
  isLoggedIn = false;
  passwordResetAuthority = "https://varcpoc.b2clogin.com/varcpoc.onmicrosoft.com/B2C_1_passwordreset";
  subscriptions: Subscription[] = [];
  AppLogoImg:string = customConfig.logourl;
  
  private readonly _destroying$ = new Subject<void>();

  constructor(
    public tokenService:TokenService,
    public genService:GeneralService,
    /*MSAL WORK*/ // 04/05/2022
    private authService: MsalService,
    private broadcastService: MsalBroadcastService,
    private http: HttpClient
  ) {
    document.getElementById("authError").style.display = "none";

    // debugger;

    // this.broadcastService.inProgress$
    // .pipe(
    //   filter((status: InteractionStatus) => status === InteractionStatus.None),
    //   takeUntil(this._destroying$)
    // )
    // .subscribe(() => {
    //   this.setLoginDisplay();
    //   //this.router.navigate(['/profile']);
    // })

  }

  
  setLoginDisplay() {
    var loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    
  }

  ngOnInit() {

    
      /*MSAL WORK*/ // 04/05/2022
      /*
      let loginSuccessSubscription: Subscription;
      let loginFailureSubscription: Subscription;

      loginSuccessSubscription = this.broadcastService.subscribe(
        "msal:loginSuccess",
        (success) => {
          // We need to reject id tokens that were not issued with the default sign-in policy.
          // "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr")
          // To learn more about b2c tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
          if (success.idToken.claims["tfp"] === "B2C_1_signupsingin") {
            window.alert(
              "Password has been reset successfully. \nPlease sign-in with your new password"
            );
            return this.authService.logout();
          }
          console.log(
            "login succeeded. id token acquired at: " + new Date().toString()
          );
          console.log(success);
          this.checkAccount();
        }
      );
      */

      /*
      loginFailureSubscription = this.broadcastService.subscribe(
        "msal:loginFailure",
        (error) => {
          console.log("login failed");
          console.log(error);

          // Check for forgot password error
          // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
          if (error.errorMessage.indexOf("AADB2C90118") > -1) {
            {
              this.authService.loginPopup({
                authority: this.passwordResetAuthority,
              });
            }
          }
          this.checkAccount();
        }
      );
      */

      /*
      this.subscriptions.push(loginSuccessSubscription);
      this.subscriptions.push(loginFailureSubscription);
      this.checkAccount();
      */
      

      /* END MSAL WORK*/ // 04/05/2022

    //this.getUserDetails();

  }

  /*MSAL WORK*/ // 04/05/2022
  onLogin() {
    // if (this.isLoggedIn) {
    //   this.authService.logout();
    // } else {
    //   this.authService
    //     .loginPopup()
    //     .then((result) => {
    //       console.log("Login success", result);
    //     })
    //     .catch((err) => {
    //       console.log("Login failed : ", err);
    //     });
    // }
  }

   /*MSAL WORK*/ // 04/05/2022
  checkAccount() {
    // this.isLoggedIn = !!this.authService.getAccount();
    // if (this.isLoggedIn) {
    //   this.accountName = this.authService.getAccount().name;
    // } else {
    //   this.accountName = "";

    // }
  }

  /*MSAL WORK*/ // 04/05/2022
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }




}

export function UserProviderFactory(userProvider: UserService) {
  return () => userProvider.load();
}



