import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { ToastType } from 'src/app/Enum/ToastType';
import { ToastService, MDBModalService, MDBModalRef } from 'ng-uikit-pro-standard';
import { MessageDialogeService } from 'src/app/message-dialoge/message-dialoge.service';
import { GeneralService } from 'src/app/services/general.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmDialogeService } from 'src/app/confirm-dialoge/confirm-dialoge.service';
import { HttpClient } from '@angular/common/http';
import { CommonService } from 'src/app/Base/Common.service';
import { EventDocumentService } from 'src/app/services/event-document.service';
import { themeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent implements OnInit {


  actionType: string = "";
  folderName: string = "";
  action: Subject<any[]> = new Subject<any[]>();
  Documents = [];
  attachments: any[] = [];

  fileStore = [];
  fileFormat: string = "";

  constructor(
    public http: HttpClient,
    public router: Router,
    public route: ActivatedRoute,
    public spinner: NgxSpinnerService,
    public toast: ToastService,
    public dialog: ConfirmDialogeService,
    public modalRef: MDBModalRef,
    public messageDialog: MessageDialogeService,
    public common: CommonService,
    public genService: GeneralService,
    public eventDocumentService: EventDocumentService,
    public theme:themeService
  ) {
  }


  ngOnInit(): void {

    this.attachments.push({ index: 0 })
    // this.AttachedDocuments();
  }

  ngAfterViewInit(){
    
  }

  addRow() {
    this.attachments.push({ index: this.attachments.length });
  }

  RemoveAttachment(index: number) {
    this.attachments.splice(index, 1);

    var i: number = 0;
    
    for (let i = 0; i => 0; i++) {
      var fileIndex = this.fileStore.findIndex(a => a.index == index);
      if (fileIndex >= 0)
      this.fileStore.splice(fileIndex, 1);
      else
      break;
    }

    console.log(this.fileStore);
    
  }
  handleFileInput(files: FileList[], index: number) {

    // if(this.fileFormat == "word"){
    //   files.forEach(a => {
    //     var isWord = String((a as any).name).includes(".doc");
    //     if(isWord == false){
    //       this.messageDialog.openMessageDialog("Reqiured MS Word templates. " + (a as any).name + " is not valid file.")
    //       return;
    //     }

    //   });
    // }


    // for (let index = 0; index < files.length; index++) {
    //   const element = files[index];
    //   this.removefile(element);
    // }

    this.fileStore.push({ "files": files, "index": index });

  }

  onSave() {
    if (this.folderName != "")
      this.UploadFiles();
    else {
      //this.action.next(this.fileStore);
      this.modalRef.hide();
    }
  }

  CheckIfExists(): any[] {
    var arr: any[] = [];
    if (this.Documents.length > 0) {
      for (let index = 0; index < this.fileStore.length; index++) {
        var element = this.fileStore[index];
        for (let j = 0; j < element.files.length; j++) {
          const f = element.files[j];
          var doc = this.Documents.filter(a => a.DisplayName == f.name);
          if (doc.length > 0) {
            arr.push(f);
          }
        }
      }
    }
    return arr;
  }

  removefile(file: any) {
    // for (let index = 0; index < this.fileStore.length; index++) {
    //   var element = this.fileStore[index];

    //   for (let j = 0; j < element.files.length; index++) {
    //     const element_file = element.files[index];
    //     if (element_file.name == file.name) {
    //         element.files.splice(i, 1)
    //     }
    //   }
    // }
  }

  //Upload files in document library
  async UploadFiles() {
    var existsArr: any[] = this.CheckIfExists();
    if (existsArr.length > 0) {
      this.dialog.openConfirmReplaceDialog("Below files are already exists. Select files to replace.", existsArr, "name").afterClosed().subscribe(res => {
        if (res) {
          if (typeof (res) == "boolean") {

          } else if (typeof (res) == "object") {

            var data_selected =  res.filter(a => a.IsSelected == true);

            if (data_selected.length > 0) {
              for (let index = 0; index < res.length; index++) {
                const element = res[index];
                if (element.IsSelected == false) {
                  //this.removefile(element);
                }
              }
              this.saveFiles();
            } else {
              this.common.HideSpinner();
              this.action.next(this.fileStore);
              this.modalRef.hide();
              return;
            }

            //Save Data


          }
        }
      })
    }
    else {
      this.saveFiles();
    }
    return;
  }

  saveFiles() {
    this.common.ShowSpinner();
    console.log('Upload files inprogress... ', new Date());
    let promises = [];

    var ifNotWordfile:boolean =  false;

    let formData = new FormData();
    for (let index = 0; index < this.fileStore.length; index++) {
      var element = this.fileStore[index];

      for (let j = 0; j < element.files.length; j++) {
        const f = element.files[j];

        if (f.IsSelected != undefined && f.IsSelected == false)
          continue;

        if (this.fileFormat == "word") {
            var isWord = String((f as any).name).toLocaleLowerCase().includes(".doc");
            if (isWord == false) {
              ifNotWordfile = true;
              break;
            }
        }

        formData = new FormData();
        formData.append('', f);
        promises.push(this.eventDocumentService.post(formData, this.folderName).toPromise())
      }
    }

    if(ifNotWordfile == true){
      this.common.HideSpinner();
      this.messageDialog.openMessageDialog("Upload a Word/Text document from your device. You'll need to save it as a .docx file to upload.");
      return;
    }

    if(promises.length == 0){
      this.common.HideSpinner();
        this.messageDialog.openMessageDialog("Please add an attachment");
        return;
    }

    Promise.all(promises).then(res => {
      console.log('All files uploaded.');
      console.log('Uploading completed. ', new Date());
      this.common.ShowToast("Attachment Uploaded Successfully", ToastType.Success);
      this.common.HideSpinner();
      this.action.next(this.fileStore);
      this.modalRef.hide();
    },
      error => {
        console.error(error);
        console.log(error);
        console.log("settlementFolderName");
        console.log(this.fileStore);
        this.common.HideSpinner();
        this.messageDialog.openMessageDialog("Some thing wrong. Documents are not uploaded. Please contact to Dev team.");
      })

    // this.eventDocumentService.post(formData, this.folderName).subscribe(res =>{
    //   console.log('All files uploaded.');
    //   console.log('Uploading completed. ', new Date());
    //   this.common.ShowToast("Attachment Uploaded Successfully", ToastType.Success);
    //   this.common.HideSpinner();
    //   this.action.next(this.fileStore);
    //   this.modalRef.hide();
    // })
  }

}
