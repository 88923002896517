import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { JwtHelper } from 'src/app/helpers/jwt-helper';
import { UserManagerService } from 'src/app/services/user-manager.service';
import * as customConfig from 'src/app/customConfig.json';
import { Customer } from 'src/app/models/customer.model';
import { CustomersService } from 'src/app/services/customers.service';
import { MessageDialogeService } from 'src/app/message-dialoge/message-dialoge.service';
import moment from 'moment';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, Configuration, EventMessage, EventType, InteractionStatus, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { CommonService } from 'src/app/Base/Common.service';
import { msalConfig } from 'src/app/auth-config';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-register-account',
  templateUrl: './register-account.component.html',
  styleUrls: ['./register-account.component.scss']
})
export class RegisterAccountComponent implements OnInit {
  
  private readonly tokenIdentifier = "id_token=";
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,   
    private authService: AuthenticationService,
    private jwtHelper: JwtHelper,    
    private userManagerService:UserManagerService,
    private router: Router,
    public customersService:CustomersService,
    public messageDialog: MessageDialogeService,
    public common: CommonService,
    private msalAuthService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
  ) { }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }


   ngOnInit() {
    this.common.ShowSpinner();
    debugger;

   {
     
      debugger;


      this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None || status === InteractionStatus.HandleRedirect),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        if (this.msalAuthService.instance.getAllAccounts().length > 0) {
          console.log("logged in")
        }
        else {
          debugger;
          console.log("Not logged in")
        }

      })


    // this.msalAuthService.handleRedirectObservable().subscribe({
    //     next: (result: AuthenticationResult) => {
    //       if (result) {
    //         this.msalAuthService.instance.setActiveAccount(result.account);
    //         console.log(result);
    //       }
    //       // Sets login display when using redirects
    //       //this.setLoginDisplay();
    //     },
    //     error: (error) => console.log(error)
    //   });
  
      // Sets login display when using popups
     
      // this.msalBroadcastService.inProgress$
      //   .pipe(
      //     filter((status: InteractionStatus) => status === InteractionStatus.None)
      //   )
      //   .subscribe(() => {
      //     debugger;
      //     this.login();
      //    // this.setLoginDisplay();
      //   });


      // this.msalBroadcastService.msalSubject$
      // .pipe(
      //   filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
      //   takeUntil(this._destroying$)
      // )
      // .subscribe((result) => {
      //   console.log("login count", this.msalAuthService.instance.getAllAccounts().length);
      // }, error => {
      //   console.log("error", "error");
      // });

      // this.msalBroadcastService.inProgress$
      // .pipe(
      //   filter((status: InteractionStatus) => status === InteractionStatus.None || status === InteractionStatus.HandleRedirect),
      //   takeUntil(this._destroying$)
      // )
      // .subscribe(() => {
      //   if (this.msalAuthService.instance.getAllAccounts().length > 0) {
         
      //     this.router.navigate(["/signin"]);
          
      //   } else {
      //     this.login().then(a => {
          
      //     })
          
      //   }
        

      // })
      
        
      
      
    }


    


    //this.canActivate(this.activatedRoute.snapshot);
  }



  async login(){
    debugger;

     var accounts = this.msalAuthService.instance.getAllAccounts().length;
      //delete  msalConfig.auth.redirectUri;
      //msalConfig.auth.navigateToLoginRequestUrl = true;

    const msalInstance = new PublicClientApplication(msalConfig);
    
    await msalInstance.handleRedirectPromise(window.location.hash).then(res =>{
      debugger;
      console.log("Logged in");
      this.router.navigate(["/signin"])

    }).catch(error => {
      debugger;
      console.log("Logged out");

      // If .catch is called, this means your app was returning from a redirect operation
      // and an error occurred.
    });;



    // await this.msalAuthService.handleRedirectObservable().subscribe({
    //   next: (result: AuthenticationResult) => {
    //     debugger;
    //     console.log(result);        
    //     this.common.HideSpinner();
    //     this.router.navigate(["/signin"])

    //     // Perform actions related to user accounts here
    //   },
    //   error: (error) => console.log(error)
    // });
        
  }



  canActivate(route: ActivatedRouteSnapshot) {

    //debugger;
    const url = route.url[0].path;
    var source :string = null;
    this.activatedRoute.queryParams.subscribe(params =>{
        source = params['source'];
    })

    
    console.log(route);    
    
    var fragment = "";
    if(route.fragment)
      fragment = route.fragment;
    else if(source == "varc") 
      fragment = localStorage.getItem("fragment");

      if(!fragment){
        alert("Some thing went wrong. Please check inspect mode and contact to administrator")
        return;
      }
        
    
    if(url == "registeraccount"){
      this.processSignup(fragment);
    } 

  }


  async  processSignup(routefragment:string) {
    // debugger;
     localStorage.setItem("fragment", routefragment);

     const fragment = routefragment ? routefragment : "dummyfragment";
     let token = "";
     if(fragment.includes('session_state') == false)
       token = fragment.substring(this.tokenIdentifier.length, fragment.length);
     else 
       token = fragment.split('&')[0].split("&")[0].substring(this.tokenIdentifier.length, fragment.split('&')[0].split("&")[0].length);
 
     const decoded = this.jwtHelper.decodeToken(token);
 
     if (decoded.tid) {
      
      
       if (decoded.ownerId) {
    //debugger;
        //this.checkAndSetActiveAccount(decoded);

         var ownerId = null;
         ownerId = String(decoded.ownerId);
         var obj = {
           ownerId: ownerId,
           userObjectId: decoded.sub,
           userEmail: decoded.email
         }

         //User code
         var objUser: User = new User();
         objUser.Id = decoded.sub;
         objUser.OwnerId = ownerId;
         objUser.tid = decoded.tid;
         //objUser.Name = decoded.name;
         objUser.token = token;
         objUser.email = decoded.email;
         objUser.sessionTimeout = this.sessionTimeout();
         this.authService.login(objUser);

         await this.userManagerService.postNew(obj).toPromise().then(async res => {
          this.common.HideSpinner();
           localStorage.removeItem("fragment");
           this.router.navigate(["/calendar"]);

         }, error => {
           console.log(error);
         })
       }
       else {
         this.router.navigate([""]);
       }


     }
 
   }

  //  checkAndSetActiveAccount(decoded:any) {
  //   debugger;
  //    var account: AccountInfo =
  //    {
  //      "homeAccountId": decoded.sub + "-b2c_1a_signup_signin."+ decoded.tid,
  //      "environment": customConfig.instance,
  //      "tenantId": decoded.tid,
  //      "username": "",
  //      "localAccountId": decoded.sub,
  //      "name": "Sameer",
  //      "idTokenClaims": {
  //        "exp": 1672924169,
  //        "nbf": decoded.nbf,
  //        "ver": "1.0",
  //        "iss": customConfig.instance + "/" + decoded.tid + "/v2.0/",
  //        "sub": decoded.sub,
  //        "aud": decoded.clientId,
  //        "acr": "b2c_1a_signup_signin",         
  //        "iat": decoded.iat,
  //        "auth_time": decoded.auth_time,
  //        "email": decoded.email,
  //        "name": "Sameer",
  //        "tid": decoded.tid         
  //      }
  //    }


  //     this.msalAuthService.instance.setActiveAccount(account);

  // }


   private sessionTimeout(): Date {
    
    var sessionTimeout = moment().add(customConfig.sessionTimeoutMins,'minute').toDate();
    
    return sessionTimeout;
  }
  

}


class User {
  Name:string = "";
  Id:string = "";
  OwnerId:string = "";
  token:string = "";
  email:string = "";   
  tid:string = ""; 
  sessionTimeout:Date = null;
}
