import { Component, OnInit } from '@angular/core';
import { JwtHelper } from 'src/app/helpers/jwt-helper';
import { SignInUserService } from 'src/app/services/sign-in-user.service';
import { TokenService } from 'src/app/services/token.service';
import { AuthenticationService } from 'src/app/helpers/authentication.service';

@Component({
  selector: 'app-sign-in-user',
  templateUrl: './sign-in-user.component.html',
  styleUrls: ['./sign-in-user.component.scss']
})
export class SignInUserComponent implements OnInit {

  constructor(
    private jwtHelper: JwtHelper,    
    private tokenService: TokenService,  
    public signInUserService:SignInUserService,
    public authenticationService:AuthenticationService  
  ) { }

  ngOnInit(): void {
    debugger;
     // Decode the token to use it later
     var token = null;
     var token_decoded = null;
    
     var url: string = window.location.href;
     if (url.includes("#id_token=")) {
         token = this.tokenService.getId_Token(url);
         token_decoded = this.jwtHelper.decodeToken(token)
        if(token_decoded.ownerId){
           localStorage.setItem("ownerId", token_decoded.ownerId);
        }
     }

     if(token_decoded != null && token != null)
     this.signInUserService.processSignin(token_decoded, token);
     else if (url && url.search("ess_denied&error_description=AADB2C90118") !== -1) {
      window.location.href = this.signInUserService.getResetPasswordUrl();
    } else if (url && url.search("ess_denied&error_description=AADB2C90091") !== -1) {
      this.authenticationService.redirectToLogin();
    } 

    
  }


  

}
