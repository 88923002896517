import { Component, OnInit,ViewChild } from '@angular/core';
import { GenericService } from 'src/app/services/generic.service'
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { BLBase } from 'src/app/Base/BLBase/BLBase.component';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastService } from 'ng-uikit-pro-standard';
import { ConfirmDialogeService } from 'src/app/confirm-dialoge/confirm-dialoge.service';
import { ToastType } from 'src/app/Enum/ToastType';
import { SPOperationsService } from 'src/app/services/spoperations.service';
import { MessageDialogeService } from 'src/app/message-dialoge/message-dialoge.service';
import { GeneralService } from 'src/app/services/general.service';
import { CommonService } from '../../../Base/Common.service';
import { Customer } from 'src/app/models/customer.model';
import { CustomersService } from 'src/app/services/customers.service';
import { PermissionService } from 'src/app/services/permission.service';


@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent extends BLBase<Customer> implements OnInit {


  public rbacusers:Customer[]= [];  
  public IsAdmin :boolean = false;

  constructor(
    public service: GenericService, 
    public router: Router, 
    public route: ActivatedRoute, 
    public spinner: NgxSpinnerService,
    public toast: ToastService,
    public dialog:ConfirmDialogeService,
    public sp:SPOperationsService,
    public messageDialog:MessageDialogeService,
    public genService:GeneralService,
    public common:CommonService,
    public customerService:CustomersService,
    public permissionService:PermissionService,
    ) {   
    
    super(service, router, route, spinner, toast, dialog, messageDialog);    
    this.formTitle = "Customer";    
    this.addControllerName("Customers");    
    //this.IsUserform = true;
    }

  public Initializeobject() {
    this.formData = new Customer();
  }

  ngOnInit(): void {    
    super.ngOnInit();            
  }


  onCreate(form:NgForm){        
    this.router.navigate(['/customer/form'], {replaceUrl:true});   
    this.Create();
    
  }



  IsshowProgress:boolean = false;
  onSubmit(form: NgForm) {
    this.IsshowProgress =true;

    setInterval(() => {
      this.IsshowProgress =false;
    }, 50000);

      //this.ShowSpinner(); 
      // this.customerService.post(form.value).subscribe(res =>{
      //   this.IsshowProgress =false;
      //   this.router.navigate(['/customer/list']);

      // })                    
      //this.insertRecord(form);                  
  }


  // AfterInsert(){
  //   this.router.navigate(['/customer/list']);
  // }

  
  // AfterUpdate() {
  //   this.router.navigate(['/customer/list']);
  // }

  
  onReload(form: NgForm){
    //this.Reload(form);
    this.navigate('/customer/form', this.primaryKey);

  }

  onDelete(ID:number){   
    this.dialog.openConfirmDialog("Are you sure you want to delete this record?").afterClosed().subscribe( res =>{
      if(res)
      {
        this.deleteRecord(ID);
      }    
    })
  }

  AfterDelete() {
    this.router.navigate(['/customer/list']);
  }
 
  onEdit(){
    this.Edit();
  }

 
}

