<div class="header justify-content-between align-items-center mb-0 d-flex"  [class]="theme.heading">
    <h4 class="h4-responsive ml-3" style="color: White;margin-bottom: 0 !important;">
      Invite User
    </h4>
    <div class="btn-group" role="group" aria-label="Basic example">
      <button mat-dialog-close mat-icon-button aria-label="Example icon button with a heart icon" style="color:white;" id="btnClose">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  
  <!-- <div class="modal-body mb-0 "> -->
<form #rbac="ngForm" autocomplete="off" class="container-fluid">
    <div class="">
        <div class="row">
            <div class="col-md-6 mt-4">
                <div class="row ">
                    <div class="col-md-12">
                        <!-- <h4 class="styling"></h4> -->
                        <h5 class="d-block p-2 text-white gradient-card-header ChildTitle"  [class]="theme.heading">User Information</h5>
                    </div>
                    <div class="card z-depth-1 mt-2" style="margin-left: 15px;margin-right: 15px;">
                        <div class="card-body" style="padding: 0px 20px 12px 16px;">
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12 mt-2">
                                    <mat-form-field class="example-full-width">
                                        <mat-label  [class]="theme.label">First Name</mat-label>
                                        <input matInput class="MatInputBoxShadow" type="text" id="txtFirstName" required name="FirstName"
                                            #FirstName="ngModel" [(ngModel)]="formDataUser.FirstName" maxlength="50">
                                        <mat-error *ngIf="FirstName.invalid && FirstName.dirty">This field is required
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                                    <mat-form-field class="example-full-width">
                                        <mat-label  [class]="theme.label">Last Name</mat-label>
                                        <input matInput class="MatInputBoxShadow" type="text" id="txtLastName" required name="FirstName"
                                            #LastName="ngModel" [(ngModel)]="formDataUser.LastName" maxlength="50">
                                        <mat-error *ngIf="LastName.invalid && LastName.dirty">This field is required
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                                    <!-- <mat-form-field class="example-full-width">
                                        <mat-label  [class]="theme.label">Email</mat-label>
                                        <input matInput class="MatInputBoxShadow" type="email" id="emlEmail" autocomplete="off" required
                                            name="Email" #Email="ngModel" [(ngModel)]="formDataUser.Email">
                                        <mat-error *ngIf="Email.errors && Email.errors.required">This field is required</mat-error>
                                        <mat-error *ngIf="Email.errors  && Email.errors.pattern">Email is invalid</mat-error>
                                    </mat-form-field> -->
                                    <mat-form-field class="example-full-width">
                                        <mat-label  [class]="theme.label">Email</mat-label>
                                        <input  matInput class="MatInputBoxShadow"
                                        type="email" id="emlEmail" autocomplete="off"                           
                                        required name="Email" #Email="ngModel" [(ngModel)]="formDataUser.Email" pattern="^\w+([\.-]?\w+)*\+?\d*@\w+([\.-]?\w+)*(\.\w{2,3})+$">                                      
                                        <mat-error *ngIf="Email.errors && Email.errors.required">This field is required</mat-error>
                                        <mat-error *ngIf="Email.errors  && Email.errors.pattern">Email is invalid</mat-error>
                                    </mat-form-field>
                                </div>
                
                                <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                                    <mdb-checkbox name="Inactive" #Inactive="ngModel" [(ngModel)]="formDataUser.Inactive">
                                        <mat-label  [class]="theme.label">
                                            Inactive
                                        </mat-label>
                                    </mdb-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    
                </div>
            </div>
            <div class="col-md-6  mt-4">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                        <h5 class="d-block p-2 text-white gradient-card-header ChildTitle"  [class]="theme.heading">Select Groups </h5>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12 mt-2">
                        <table mdbTable class="z-depth-1 InputTable">
                            <thead>
                            <tr>                  
                                <th  [class]="theme.label">Group </th>
                                <th></th>                     
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor=" let es of rbacGroups; let i = index;" [ngStyle]="{'background-color': es.IsAdmin == true ? '#c4ffd9' : '' }">                      
                                <td>
                                {{es.GroupName}}
                                </td>
                                <td>
                                <div>
                                    <mdb-checkbox 
                                    [ngModelOptions]="{standalone: true}" 
                                    [(ngModel)]="es.IsSelected"
                                    (change)="onGroupClick(es)" 
                                    [disabled]="es.IsAdmin != true && IsAdmin==true">
                                    </mdb-checkbox>
                                </div>
                                </td>                     
                            </tr>                                  
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-5 col-sm-5 col-lg-5 col-xs-5 mt-3">
                    </div>
                    <div class="col-md-7 col-sm-7 col-lg-7 col-xs-7 mt-3">
                        <button type="button" (click)="onSubmit(rbac)" [disabled]="rbac.invalid || (Email.errors  && Email.errors.pattern)"  mdbBtn id="btnSave"  [class]="theme.button" class="waves-light float-right" mdbWavesEffect>
                            <mdb-icon fas icon="save" class="mr-1"></mdb-icon>
                            Submit
                        </button>
                    </div>
                </div>
                
                <!-- <mdb-card class="crd" cascade="true" narrower="true">
                    <div
                        class="view view-cascade gradient-card-header blue-gradient narrower py-2 mx-4  d-flex justify-content-between align-items-center">
                        <h4 class="h4-responsive w-100 text-center">Groups</h4>
                    </div>
                    <mdb-card-body cascade="true" class="bdy">
                        <div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <table mdbTable class=" InputTable">
                                        <thead>
                                            <tr>
                                                <th>Group </th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor=" let es of rbacGroups; let i = index;"
                                                [ngStyle]="{'background-color': es.IsAdmin == true ? '#c4ffd9' : '' }">
                                                <td>
                                                    {{es.GroupName}}
                                                </td>
                                                <td>
                                                    <div>
                                                        <mdb-checkbox [ngModelOptions]="{standalone: true}"
                                                            [(ngModel)]="es.IsSelected" 
                                                            (change)="onGroupClick(es)"
                                                            [disabled]="es.IsAdmin != true && IsAdmin==true">
                                                        </mdb-checkbox>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </mdb-card-body>
                </mdb-card> -->
            </div>
             
        </div>
    </div>
</form>