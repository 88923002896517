import { Subject } from 'rxjs';
import { ToastType } from 'src/app/Enum/ToastType';
import { Autocomplete } from 'src/app/Base/Autocomplete'
import { EventStatus } from 'src/app/models/EventStatus.model';
import { Venue } from 'src/app/models/venue.model';
import { Component, ViewChild, TemplateRef, OnInit, HostListener, Input, AfterViewInit , Output, EventEmitter} from '@angular/core';
import { GenericService } from 'src/app/services/generic.service';
import { GeneralService } from 'src/app/services/general.service';
import { CalendarEvent, CalendarEventAction, CalendarDateFormatter, CalendarEventTimesChangedEvent, CalendarView, CalendarMonthViewBeforeRenderEvent } from 'angular-calendar';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { Event } from 'src/app/modules/event/event-info/Event.model';
import { BLBase } from 'src/app/Base/BLBase/BLBase.component';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastService, MDBModalService, MDBModalRef, ModalDirective } from 'ng-uikit-pro-standard';
import { ConfirmDialogeService } from 'src/app/confirm-dialoge/confirm-dialoge.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Holiday } from 'src/app/setupForms/holiday/holiday.model'
import { LoginUser } from 'src/app/Base/User/login-user';
import { FilterBL } from 'src/app/models/FilterBL';
import { CustomDateFormatter } from '../custom-date-formatter.provider';



import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';

// For Date Picker month
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';


import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
//import { EventNotesComponent } from '../modules/event/event-notes/event-notes/event-notes.component';
import { EventSubEventNotesComponent } from '../../modules/event/event-sub-event-notes/event-sub-event-notes/event-sub-event-notes.component';

import { VenueMonthNotes } from '../../models/venue-month-notes.model';
import { EnEventStatus } from '../../Enum/Enums'
import { DateTime } from 'luxon';
import { CommonService } from '../../Base/Common.service';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MessageDialogeService } from '../../message-dialoge/message-dialoge.service';

import { SPOperationsService } from 'src/app/services/spoperations.service';
import { EventFuncService } from 'src/app/modules/event/EventFuncService.service';
import { Client } from '../../modules/client/client.model';
import { takeUntil, take } from 'rxjs/operators';
import { CalendarNotesComponent } from 'src/app/calendar-notes/calendar-notes.component';
import { CalendarService } from '../calendar-service.service';
import { ConfFilters } from '../../models/conf-filters.model';
import { VenueService } from '../../setupForms/venue/venue.service';
import { EventNotesService } from '../../modules/event/event-notes/event-notes.service';
import { EventStatusService } from '../../setupForms/event-status/event-status.service';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


const colors: any = {};

@Component({
  selector: 'app-calendar-request',
  templateUrl: './calendar-request.component.html',
  styleUrls: ['./calendar-request.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class CalendarRequestComponent extends BLBase<Event> implements OnInit{
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  
  
  @Output() newItemEvent = new EventEmitter<string>();

  public modalRef: MDBModalRef;
  date = new FormControl(moment());
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  clickedDate: Date;
  clickedColumn: number;
  refresh: Subject<any> = new Subject();
  activeDayIsOpen: boolean = true;
  AutoCompVenue: Autocomplete<Venue> = new Autocomplete<Venue>("VenueName", "ID");
  AutoCompStatus: Autocomplete<EventStatus> = new Autocomplete<EventStatus>("EventStatus", "ID");
  FilterDate: Date = new Date();

  @ViewChild('eventStatus_SkillSell') eventStatus_SkillSell: MatSelect
  @ViewChild('venue_SkillSell') venue_SkillSell: MatSelect

  @Input() VenueMonthData :any[];
  @Input() calendar_viewDate: Date = new Date();
  venueMonthNotesList: any[] = [];

  public selectedClients: number[] = [];
  searchClient: any = null;
  AutoCompClient: Autocomplete<Client> = new Autocomplete<Client>("ClientName", "ID");
  protected _onDestroy = new Subject<void>();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];
  events: CalendarEvent[];
  Allevents: CalendarEvent[];
  Holidays: Holiday[];
  eventsToolTip:[]=[];

  VenueID: number[] = [];
  EventStatusID: number[] = [];

  selectedChipVenueID = 0; 
  IsPrint: boolean = false;

  constructor(
    private modal: NgbModal,
    public sp: SPOperationsService,
    public modalService: MDBModalService,
    public genService: GeneralService,
    public spinner: NgxSpinnerService,
    public toast: ToastService,
    public service: GenericService,
    public dialog: ConfirmDialogeService,
    public router: Router,
    public route: ActivatedRoute,
    public common: CommonService,
    public eventFunc: EventFuncService,
    public calendarService: CalendarService,
    public venueService:VenueService,
    public eventNotesService:EventNotesService,
    public eventStatusService:EventStatusService,
    public messageDialog?: MessageDialogeService,
    


    // public venueMonthCivic : VenueMonthNotesClass
  ) {
    super(service, router, route, spinner, toast, dialog, messageDialog);
    this.addControllerName("Events");

  }

  addNewItem(value: string) {
    this.newItemEvent.emit(value);
  }


  ngOnChanges(changes: any): void {
    this.venueMonthNotesList = this.VenueMonthData;
    this.viewDate = this.calendar_viewDate;
    if (this.VenueMonthData.length > 0 ) {
      this.selectedChipVenueID = this.VenueMonthData[0].ID;
      this.GenerateCalendarData(this.viewDate);
    }
  }

  
  public Initializeobject() {
    this.formData = new Event();
  }

  toggleSelectAll(selectAllValue: boolean) {

    this.AutoCompClient.results.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        var vals = val.map(a => a.ID);
        if (selectAllValue) {
          this.selectedClients = vals;
        } else {
          this.selectedClients = [];
        }
      })
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngAfterViewInit() {
    this.venueMonthNotesList = this.VenueMonthData;
    //var elements = document.getElementsByClassName('cal-out-month');   
  }

  beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
    renderEvent.body.forEach((day) => {
      const dayOfMonth = day.date.getDate();
      if (dayOfMonth > 5 && dayOfMonth < 10 && day.inMonth) {
        //day.cssClass = 'bg-pink';
      }
    });
  }

 


  ngOnInit(): void {

    super.ngOnInit();

    this.ShowSpinner();

    var IsFilterDate = false;

    this.route.queryParams
      .subscribe(params => {
        this.FilterDate = new Date(params['viewDate']) || new Date();
      });

    if (isNaN(this.FilterDate.getTime())) {
      this.FilterDate = new Date();
      this.viewDate = this.calendarService.viewDate;
    }
    else {
      this.viewDate = new Date(this.FilterDate);
      IsFilterDate = true;
      this.refresh = new Subject();
    }
    
    this.GenerateCalendarData(this.viewDate);
    
    this.getVenues();


    this.setColors();


  }

  onPrint(){
    var MonthDate = moment.parseZone(this.viewDate).format('YYYY/MM/DD');
    this.router.navigate(['/report/avail-report'], { queryParams: { MonthDate: MonthDate, VenueID:this.selectedChipVenueID } })

  }

setColors(){
  colors['blue'] = {
    primary: '#33b5e5',
    secondary: '#D1E8FF',
  }

  colors['black'] = {
    primary: 'black',
    secondary: 'black',
  }

  colors['white'] = {
    primary: '#ffffff',
    secondary: '#ffffff',
  }

  colors['red'] = {
    primary: '#ff9980',
    secondary: '#ff9980',
  }

  colors['yellow'] = {
    primary: '#ffe499',
    secondary: '#ffe499',
  }

  colors['green'] = {
    primary: '#b5ffb3',
    secondary: '#b5ffb3',
  }

  

}

  getClients() {
    this.genService.GetDataByQuery("Clients?$select=ID,ClientName").then(res => {
      this.AutoCompClient.data = res['value'] as Client[];
      this.AutoCompClient.resultObserve();
    })
  }



  groupEventStatus:EventStatus[] =[];
  


  chpArray = [];
  setChips(eventStatus:EventStatus[]) {
    this.chpArray = [];

    // For Child Status Status
    //#region 
    const uniqueStatusParentIDs = [...new Set(eventStatus.filter(x => x != undefined && x.LevelName == 'Child').map(item => item.ParentID))];
        
    //Get Selected Status Groups ID
     var groupEventStatus = this.groupEventStatus.filter(res => {
      return (uniqueStatusParentIDs).find(c => res.ID === c)
    })
    
    groupEventStatus.forEach(element => {
      
      var StatusChips:EventStatus[] = [];

      // Get all child status of each group
      var filteredStatus = eventStatus.filter(x => x != undefined && x.ParentID == element.ID)
      if (filteredStatus.length > 0) {
        filteredStatus.forEach(element => {
          StatusChips.push(element)
        });
      }

      // Fill in chips
      this.chpArray.push({ eventStatus:element, chipObj: StatusChips })

    })

    //#endregion


    // For None Status Status
    //#region 
    
    var noneEventStatus = eventStatus.filter(x=> {
      return x != undefined && x.LevelName == 'None';
    })

    noneEventStatus.forEach(element => {
      
      var StatusChips:EventStatus[] = [];
      StatusChips.push(element);
      
      // Fill in chips
      this.chpArray.push({ eventStatus:element, chipObj: StatusChips })

    })

    //#endregion
    
  }


  async getVenues() {
    await this.venueService.getVenues().then(
      res => {
        this.AutoCompVenue.data = res['value'] as Venue[];
        this.AutoCompVenue.resultObserve();
        this.HideSpinner();
      }
      , error => {
        this.HideSpinner();
        this.ShowToast(error.message, ToastType.Error);
      }
    )
  }







  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
      this.calendarService.viewDate = this.viewDate;
    }
  }


  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        }
      },
    ];
  }

  

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay(ChangedDate: any) {
    this.activeDayIsOpen = false;
    this.calendarService.viewDate = this.viewDate;
    this.GenerateCalendarData(ChangedDate);
    
  }

  dayEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

 

  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
    datepicker.close();

  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    ctrlValue.year(normalizedMonth.year());
    this.date.setValue(ctrlValue);
    datepicker.close();

    this.viewDate = new Date(this.date.value);
    this.calendarService.viewDate = this.viewDate;
    this.GenerateCalendarData(this.viewDate);
    
  }


  OpenEventModal(event) {
    var eventDate = moment.parseZone(event).format('YYYY-MM-DD');
    this.router.navigate(['/event/event-request/form'], { queryParams: { EventAddDate: eventDate } })

  }

   
  GenerateCalendarData(TodayDate: Date) {
    //this.ShowSpinner();

    // var allSelectedVenueID = "";
    // for (let index = 0; index < this.VenueID.length; index++) {
    //   const element = this.VenueID[index];
    //   allSelectedVenueID += element.toString();
    //   if (index < this.VenueID.length - 1)
    //     allSelectedVenueID += ","
    // }
    
    var startDate = moment(TodayDate).startOf('month').format("MM-DD-YYYY");
    var endDate = moment(TodayDate).endOf('month').format("MM-DD-YYYY");

    this.genService.GetConfigDataWithQuery("sp_GetEventRequest '" + startDate + "', '" + endDate + "', '"+this.selectedChipVenueID+"'" ).then(res => {
      var calendarData = res['value'] as [];
        this.events = this.createCalendarEventData(calendarData);
        this.Allevents = [...this.events];
        //this.getDataByChip(this.selectedChipVenueName, this.selectedChipEventStatus);

        this.refresh = new Subject();
        this.HideSpinner();

        this.common.HideSpinner();
    });


  }

  createCalendarEventData(data: []): CalendarEvent[] {

    var eventarray: CalendarEvent[] = [];

    data.forEach(element => {
      eventarray.push(
        {
          id: element['ID'],
          start: this.common.ConvertStringToHhMm(element['Date'], "08:00"),
          end: this.common.ConvertStringToHhMm(element['Date'], "08:00"),
          title: element['EventName'],
          color:  element['IsConfirmed'] == true ?  colors.green : colors.yellow ,
          //color: element['EventStatus'] == null ? colors.blue : element['IsSecured'] == true ? colors.black : colors[element['Color']],
          //actions: this.actions,
          //allDay: true,
          // resizable: {
          //   beforeStart: true,
          //   afterEnd: true,
          // },
          meta: {
            StartDate: element['StartDate'],
            EndDate: element['EndDate'], 
            VenueName: element['VenueName'], 
            ClientName: element['ClientName'],        
            ContactPersonName: element['ContactPersonName'], 
            CategoryName: element['CategoryName'], 
            GenreName: element['GenreName'], 
            TicketTypeName: element['TicketTypeName'], 
            ModifiedBy: element['ModifiedBy'], 
            ModifiedDate: element['ModifiedDate'],    
          }        
        })

    });

  

    return eventarray;
  }

  
  getDataByChipVenue(venueID) {    

    if (venueID != "") {
      this.selectedChipVenueID = venueID;  
      this.ShowSpinner();
      this.GenerateCalendarData(this.viewDate);    
    }
  }

  onChipVenueClick(venueID) {

    if (this.selectedChipVenueID == venueID) {
      //this.selectedChipVenueName = "";      
    }
    else if(venueID != "")
     {       
        this.getDataByChipVenue(venueID);    
     }
    
  }

  

  filterCalendar(type: string, event: MatDatepickerInputEvent<Date>) {
    this.viewDate = new Date(event.value);
    this.calendarService.viewDate = this.viewDate;
    //this.refresh = new Subject();
    this.GenerateCalendarData(this.viewDate);
    

  }

   
  allVenueSelected = false;


  VenuetoggleAllSelection() {
    this.allVenueSelected = !this.allVenueSelected;  // to control select-unselect

    if (this.allVenueSelected) {
      this.venue_SkillSell.options.forEach((item: MatOption) => item.select());
    } else {
      this.venue_SkillSell.options.forEach((item: MatOption) => { item.deselect() });
    }
    //this.skillSel.close();
  }














 

}


