<div class="row fixed-top my-1 pl-3 pr-3 stickyMenuTop top-desk justify-content-between">
  <div class="btn-group" role="group" aria-label="Basic example">

    <a class=" top-btn " [class]="'theme-'+theme.themeColor">

      <button mdbBtn type="button" id="contact" class="btn-sm" (click)="onClientView()">
        <span class="btn-icon">
          <mdb-icon fas icon="user-friends" style="padding: 4px;"></mdb-icon>
        </span>
        <span style="padding-left: 10px;">
          Client Management
        </span>
      </button>
    </a>

    <a class=" top-btn" [class]="'theme-'+theme.themeColor"
      [hidden]="permissionService.CheckIfUserhasAccess_Action('Contract Information','Add') == false && permissionService.CheckIfUserhasAccess_Action('Contract Information','Edit') == false">
      <button mdbBtn type="button" id="contact" class="btn-sm" (click)="onContractView()">
        <span class="btn-icon">
          <mdb-icon fas icon="file-contract" style="padding: 4px;"></mdb-icon>
        </span>
        <span style="padding-left: 10px;">
          Contract Management
        </span>
      </button>
    </a>
    <a class=" top-btn" [class]="'theme-'+theme.themeColor">
      <button mdbBtn type="button" id="document" class="btn-sm"
        [hidden]="permissionService.CheckIfUserhasAccess_Section('Event Documents')"
        (click)="onDocumentView()">
        <span class="btn-icon">
          <mdb-icon fas icon="file-alt" style="padding: 4px;"></mdb-icon>
        </span>
        <span style="padding-left: 10px;">
          Document Center
        </span>
      </button>
    </a>
    <a class=" top-btn" [class]="'theme-'+theme.themeColor"
      [hidden]="permissionService.CheckIfUserhasAccess_Action('Deposit Schedule','Add') == false && permissionService.CheckIfUserhasAccess_Action('Deposit Schedule','Edit') == false">
      <button mdbBtn type="button" id="deposit" class="btn-sm" (click)="onEventDueAmountView()">
        <span class="btn-icon">
          <mdb-icon fas icon="clipboard-list" style="padding: 4px;"></mdb-icon>
        </span>
        <span style="padding-left: 10px;">
          Payment Tracker
        </span>
      </button>
    </a>
    <a class=" top-btn" [class]="'theme-'+theme.themeColor"
      [hidden]="permissionService.CheckIfUserhasAccess_Action('Settlement','Add') == false && permissionService.CheckIfUserhasAccess_Action('Settlement','Edit') == false">
      <button mdbBtn type="button" id="settlement" class="btn-sm" (click)="onSettlementView()">
        <span class="btn-icon">
          <mdb-icon fas icon="far fa-handshake" style="padding: 4px;"></mdb-icon>
        </span>
        <span style="padding-left: 10px;">
          Income Statements
        </span>
      </button>
    </a>
  </div>
  <mat-button-toggle-group name="calendarGroup" class="swtich-btn" [(ngModel)]="calendarGroup" aria-label="Font Style"
    [class]="'switch-'+theme.themeColor" style="margin-right: 120px;">
    <mat-button-toggle (click)="onCalendarClick()" value="Calendar">Calendar</mat-button-toggle>
    <mat-button-toggle (click)="onAvailClick()" value="Avail">Avail</mat-button-toggle>
  </mat-button-toggle-group>
</div>

<div class="row fixed-top my-1 pl-3 pr-3 stickyMenuTop top-mob">

  <div class="d-flex  align-items-center justify-content-between">
    <div class="">
      <mat-button-toggle-group name="calendarGroup" class="swtich-btn" [(ngModel)]="calendarGroup"
        aria-label="Font Style" [class]="'switch-'+theme.themeColor">
        <mat-button-toggle (click)="onCalendarClick()" value="Calendar">Calendar</mat-button-toggle>
        <mat-button-toggle (click)="onAvailClick()" value="Avail">Avail</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="float-right">
      <button mat-icon-button [matMenuTriggerFor]="menuMob">
        <mat-icon>apps</mat-icon>
      </button>
      <mat-menu #menuMob="matMenu">
        <button mat-menu-item type="button" id="contact" class="btn-sm" (click)="onClientView()">
          <mdb-icon fas icon="user-friends" class="mr-1"></mdb-icon>Client Management
        </button>
        <button mat-menu-item type="button" id="contact" class="btn-sm"
          [hidden]="permissionService.CheckIfUserhasAccess_Action('Contract Information','Add') == false && permissionService.CheckIfUserhasAccess_Action('Contract Information','Edit') == false"
          (click)="onContractView()">
          <mdb-icon fas icon="file-contract" class="mr-1"></mdb-icon>Contract Management
        </button>

        <button mat-menu-item type="button" id="document" class="btn-sm"
          [hidden]="permissionService.CheckIfUserhasAccess_Section('Event Documents')"
          (click)="onDocumentView()">
          <mdb-icon fas icon="file-alt" class="mr-1"></mdb-icon>Document Center
        </button>
        <button mat-menu-item type="button" id="deposit" class="btn-sm"
          [hidden]="permissionService.CheckIfUserhasAccess_Action('Deposit Schedule','Add') == false && permissionService.CheckIfUserhasAccess_Action('Deposit Schedule','Edit') == false"
          (click)="onEventDueAmountView()">
          <mdb-icon fas icon="clipboard-list" class="mr-1"></mdb-icon>Payment Tracker
        </button>

        <button mat-menu-item type="button" id="settlement" class="btn-sm"
          [hidden]="permissionService.CheckIfUserhasAccess_Action('Settlement','Add') == false && permissionService.CheckIfUserhasAccess_Action('Settlement','Edit') == false"
          (click)="onSettlementView()">
          <mdb-icon fas icon="far fa-handshake" class="mr-1"></mdb-icon>Income Statements
        </button>
      </mat-menu>

   
    </div>

  </div>
</div>