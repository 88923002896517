export class ParentTerm {
    PropertyName: string = null;
    PropertyValue: number = null;
    NewProperty: boolean = null;
    ParentGroup: string = null;
    PropertyOrder : number = 0;
    SectionName:string = null;
    ID:number = 0;
    ModifiedBy: string = null;
    ModifiedDate: string = null;
    DepartmentAttachments: any[] = [];
}
