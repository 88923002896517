import { Injectable } from '@angular/core';
import { HelpService } from './help.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private helpService:HelpService) { }


  async getClientList(Status:any){
    var filters ={
      clientstatus:Status
    }
   return await this.helpService.GetDataByHelpNo("hlpclientlistsp",filters);
  }

  async getClientView(ID:any){
    var filters ={
      clientid:ID
    }
   return await this.helpService.GetDataByHelpNo("hlpclientvw",filters);
  }

  async getClientContactPersonView(ID:any){
    var filters ={
      clientid:ID
    }
   return await this.helpService.GetDataByHelpNo("hlpclientcontactpersonvw",filters);
  }

  async getClientExcelList(){ 
   return await this.helpService.GetDataByHelpNo("hlpclientexcelsp");
  }

  async getClientDrp(){
   return await this.helpService.GetDataByHelpNo("hlpclientdrp");
  }

}
