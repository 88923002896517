import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { JwtHelper } from 'src/app/helpers/jwt-helper';
import { MessageDialogeService } from 'src/app/message-dialoge/message-dialoge.service';
import { CustomersService } from 'src/app/services/customers.service';
import * as customConfig from 'src/app/customConfig.json';
import moment from 'moment';
import { Customer } from 'src/app/models/customer.model';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationParameters } from 'msal';
import { LoginUser } from 'src/app/Base/User/login-user';

@Component({
  selector: 'app-select-tenant',
  templateUrl: './select-tenant.component.html',
  styleUrls: ['./select-tenant.component.scss']
})
export class SelectTenantComponent implements OnInit {
  public onBoaringUrl: string = "";    
  idtoken:any = null;
  customerSubscriptions:any[] = [];
  selectedSubscription:any = null;
  email:string = "";
  
  constructor(
    private router: Router,    
    public customersService:CustomersService,    
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,    
    private jwtHelper: JwtHelper,            
    public messageDialog: MessageDialogeService,    
    
  ) { }

  ops:string = "";
  ngOnInit(): void {
    
        
    //this.msalAuthService.loginRedirect()

    /**/

    var tid:any = null;
    this.activatedRoute.queryParams.subscribe(params => {
      tid = params['tid'];
      this.ops = params['ops'];
      this.idtoken = params['token'];
      this.email = params['email'];
      
    })

    if(this.ops == "switch"){
      var curr_user = this.authService.CurrentUser();
      if(curr_user != null && curr_user != undefined){
        this.idtoken = curr_user.token;
      }
    }

    this.getTenants(tid);
  }

  getTenants(tid:any) {
    this.customersService.getTenants(tid, this.email).toPromise().then(res => {
      if(res){
        var data:any[] = res as any[];
        if(data.length > 0){
          this.customerSubscriptions = data;
        }
      }
    })
  }

  
  public async processSignin() {
    
    const decoded = this.jwtHelper.decodeToken(this.idtoken);
    debugger;


    if (decoded.tid) {
      if (decoded.tid != customConfig.tenantId) {        
        await this.signInProcess_DifferentTenant(decoded, this.idtoken);
      }
    }

  }

  async signInProcess_DifferentTenant( decoded: any, token: any) {
    
    if (decoded) {    
      {    
        var objUser: User = new User();    
        objUser.Id = decoded.sub;        
        //objUser.Name = decoded.name;
        objUser.token = token;
        objUser.tid = decoded.tid;    
        if(decoded.preferred_username)
          objUser.email = decoded.preferred_username;
        else
          objUser.email = decoded.email;

        
        if (decoded.name)
          objUser.Name = decoded.name;
        else 
          objUser.Name = objUser.email;

        objUser.sessionTimeout = this.sessionTimeout();
        
        //create customer object and save in sql database
        var userNameSplit: string[] = objUser.Name.split(' ');
        var objCustomer = new Customer();
        if(userNameSplit.length > 0)
          objCustomer.FirstName = userNameSplit[0];
        
        if(userNameSplit.length > 0)
          objCustomer.LastName = userNameSplit[1];
          
        objCustomer.OrganisationName = "";
        
        objCustomer.Email = objUser.email;
        objCustomer.tid = objUser.tid;
        objCustomer.AzObjectId = decoded.sub;      
        console.log('Customer object while signin', objCustomer);
        debugger;

        var objCustomerTenant ={
          Customer:objCustomer,
          subscriptionId: this.selectedSubscription
        }

        await this.customersService.postBySubscription(objCustomerTenant, token).toPromise().then(async (res:any) => {
          if(res){
            console.log('customer after signup', res);          
            objUser.OwnerId = String(res.customer['ID']);
            LoginUser.customer = res.customer;
            LoginUser.customer['CustomerObjectId'] = String(res.customerObjectId);
            this.authService.login(objUser);
            localStorage.removeItem('onboardingstatus');
            localStorage.removeItem("fragment");            
            console.log("Customer Successfully Saved!");
            this.router.navigate(["/calendar"]);
          } else {
            this.router.navigate(["/calendar"]);
          }
          
        }, error => {

          console.log(error);
          this.messageDialog.openMessageDialog("Signup not succeeded. Please send an email to contact@venuearc.com.");
        })

      }
    }
  }
  
  private sessionTimeout(): Date {
    
    var sessionTimeout = moment().add(customConfig.sessionTimeoutMins,'minute').toDate();
    
    return sessionTimeout;
  }

}

class User {
  Name:string = "";
  Id:string = "";
  OwnerId:string = "";
  token:string = "";
  email:string = "";   
  tid:string = ""; 
  sessionTimeout:Date = null;
}
