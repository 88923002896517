import { Component, DebugElement, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { JwtHelper } from 'src/app/helpers/jwt-helper';
import { AppConfigService } from 'src/app/helpers/app-config.service';
import { UserManagerService } from 'src/app/services/user-manager.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { CustomersService } from 'src/app/services/customers.service';
import { Customer } from 'src/app/models/customer.model';
import * as customConfig from 'src/app/customConfig.json';
import { MessageDialogeService } from 'src/app/message-dialoge/message-dialoge.service';
import moment from 'moment';
import { EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss']
})
export class SecurityComponent implements OnInit {
  public onBoaringUrl: string = "";
  private readonly tokenIdentifier = "id_token=";
  private readonly _destroying$ = new Subject<void>();
  
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,    
    private jwtHelper: JwtHelper,    
    private userManagerService:UserManagerService,
    private msalAuthService: MsalService,
    public customersService:CustomersService,
    public messageDialog: MessageDialogeService,
    private msalBroadcastService: MsalBroadcastService,    
  ) { 
    
  }

  redirecturl:string = "calendar";

  ngOnInit(): void {
  debugger;
    console.log(this.router);
    console.log(this.activatedRoute);

    this.activatedRoute.queryParams.subscribe(params => {
      this.redirecturl = params['redirecturl'] || this.redirecturl;
    });

    //this.canActivate(this.activatedRoute.snapshot);
  debugger;
    

    // if(this.msalAuthService.instance.getAllAccounts().length == 0){
    //   this.msalAuthService.loginRedirect();
    // }

    if (this.msalAuthService.instance.getActiveAccount()) {

      //this.getAccessToken().then(res => {
        if (this.authService.CurrentUser() == null)
          this.processSignin_2(this.msalAuthService.instance.getActiveAccount()?.idTokenClaims);
        else {
          this.router.navigate([this.redirecturl]);
          return;
        }
      //})
    }
    else {

      this.msalBroadcastService.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None || status === InteractionStatus.HandleRedirect),
          takeUntil(this._destroying$)
        )
        .subscribe(() => {
          if (this.msalAuthService.instance.getAllAccounts().length > 0) {
            this.checkAndSetActiveAccount();
            //this.getAccessToken().then(res => {
              this.processSignin_2(this.msalAuthService.instance.getActiveAccount()?.idTokenClaims);
            //})

          }
          else{
            debugger;
            this.msalAuthService.loginRedirect();
          }
          // else {
          //   debugger;
          //   this.loginWithToken(this.activatedRoute.snapshot);
          // }

        })
    }
     

  }

  async getAccessToken(){
   
    /*
    if(this.authService.CurrentToken()){
      return;
    }
    */
    

    var tokenRequest = {
      scopes: ["https://varctest.onmicrosoft.com/api/read_data"]
    };

   await this.msalAuthService.acquireTokenSilent(tokenRequest)
    .toPromise().then(response => {
      // get access token from response
     this.authService.setCurrentToken(response.accessToken);
    })
    .catch(err => {
      // could also check if err instance of InteractionRequiredAuthError if you can import the class.
      
    });
    
  }

  loginWithToken(routefragment : ActivatedRouteSnapshot){        
    debugger;

    const fragment = routefragment.fragment ? routefragment.fragment : "dummyfragment";
    let token = "";
    if(fragment.includes('session_state') == false)
      token = fragment.substring(this.tokenIdentifier.length, fragment.length);
    else 
      token = fragment.split('&')[0].split("&")[0].substring(this.tokenIdentifier.length, fragment.split('&')[0].split("&")[0].length);

    if(!token){
      return;
    }

    const decoded = this.jwtHelper.decodeToken(token);
    debugger;


    var  authRequestConfig : any = {      
      extraQueryParameters : { "id_token_hint" : token}
    };

    // if (this.msalGuardConfig.authRequest) {
    //   authRequestConfig = { ...this.msalGuardConfig.authRequest } as RedirectRequest
    // }

    // var authRequestConfig = {      
    //   redirectUri: customConfig.redirectUri//"http://localhost:4200/signin"
    // };

    // authRequestConfig.redirectUri = customConfig.redirectUri;
    // authRequestConfig.extraQueryParameters = { "id_token_hint" : token};

    this.msalAuthService.loginRedirect(authRequestConfig as RedirectRequest);
  }

  // splitUrl(url:string){
  //   if(url.includes("?")){
  //      var qryparms: string = url.substring(url.indexOf("?"));
  //      var parms = qryparms.split("&");
  //      parms.forEach(element => {

  //      });
       

  //   }
      
  // }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  
  checkAndSetActiveAccount() {
    debugger;
    let activeAccount = this.msalAuthService.instance.getActiveAccount();

    if (!activeAccount && this.msalAuthService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalAuthService.instance.getAllAccounts();
      this.msalAuthService.instance.setActiveAccount(accounts[0]);
    }
  }

  canActivate(route: ActivatedRouteSnapshot) {
    //debugger;
    const url = route.url[0].path;
    var source :string = null;
    this.activatedRoute.queryParams.subscribe(params =>{
        source = params['source'];
    })

    
    console.log(route);    
    
    var fragment = "";
    if(route.fragment)
      fragment = route.fragment;
    else if(source == "varc") 
      fragment = localStorage.getItem("fragment");

      if(!fragment){
        alert("Some thing went wrong. Please check inspect mode and contact to administrator")
        return;
      }
        
    

    if(url == "signin"){
      this.processSignin(fragment);
    } else if(url == "resetpassword"){
      this.processResetPassword(fragment);
    } else if(url == "register"){
      this.processSignup(fragment);
    } else {
      //this.router.navigate([""]);         
    }

/*
    switch (url) {
      case "signin":
         this.processSignin(route);
      case "resetpassword":
         this.processResetPassword(route);
      case "signup":
         this.processSignup(route);
      case "register":
         this.processSignup(route);
      default:
        this.router.navigate([""]);         
    }
    */
  }


  private async processSignin(routefragment: string) {
    const fragment = routefragment ? routefragment : "dummyfragment";
    let token = "";
    if(fragment.includes('session_state') == false)
      token = fragment.substring(this.tokenIdentifier.length, fragment.length);
    else 
      token = fragment.split('&')[0].split("&")[0].substring(this.tokenIdentifier.length, fragment.split('&')[0].split("&")[0].length);


    const decoded = this.jwtHelper.decodeToken(token);
    debugger;


    if (decoded.tid) {
      if (decoded.tid == customConfig.tenantId) {
        this.signInProcess_SameTenant(decoded, token);
      }
      else if (decoded.tid != customConfig.tenantId) {
        var email:string = "";
        if (decoded.preferred_username)
          email = decoded.preferred_username;
          else
          email = decoded.email;

          debugger;

        await (await this.getTenants(decoded.tid, email)).toPromise().then(async res => {

          if (res) {
            var customerMarketPlace: any[] = res as any[];
            if (customerMarketPlace.length > 1) {
              this.router.navigate(["/select-tenant"], { queryParams: { tid: decoded.tid, token: token, email:email } });
            } else {
              await this.signInProcess_DifferentTenant(routefragment, decoded, token);
            }
          } else {
            debugger;
            this.router.navigate(["/requiredsubscription"]);
          }
        }, error => {
          if (error.error.text) {
            if (String(error.error.text).indexOf("Error:") == 0) {
              this.messageDialog.openMessageDialog(error.error.text);
            }
            else {
              this.messageDialog.openMessageDialog(error.message);
            }
          }
          else
            this.messageDialog.openMessageDialog(error.message);
        });

      }
    } else if (token && token.search("ess_denied&error_description=AADB2C90118") !== -1) {
      window.location.href = this.getResetPasswordUrl();
    } else {
      this.router.navigate(["signup"]);
    }

  }

  async signInProcess_DifferentTenant(routefragment: any, decoded: any, token: any) {
    //this.authService.login(objUser);
    localStorage.setItem("fragment", routefragment);
    if (decoded) {
      var ownerId = null;
      //if (decoded.extension_ownerId) 
      {
        //ownerId = decoded.extension_ownerId;
        var objUser: User = new User();
        //objUser.Id = decoded.oid;
        objUser.Id = decoded.sub;
        //objUser.OwnerId = ownerId;       

        objUser.token = token;
        objUser.tid = decoded.tid;
        //objUser.email = decoded.emails[0];
        if (decoded.preferred_username)
          objUser.email = decoded.preferred_username;
        else
          objUser.email = decoded.email;

        if (decoded.name)
          objUser.Name = decoded.name;
        else 
          objUser.Name = objUser.email;
          
        objUser.sessionTimeout = this.sessionTimeout();
        //this.router.navigate(["calendar"]);

        //create customer object and save in sql database
        var userNameSplit: string[] = objUser.Name.split(' ');
        var objCustomer = new Customer();
        if(userNameSplit.length > 0)
          objCustomer.FirstName = userNameSplit[0];
          
        if(userNameSplit.length > 1)
          objCustomer.LastName = userNameSplit[1];

        objCustomer.OrganisationName = "";
        objCustomer.Email = objUser.email;
        objCustomer.tid = objUser.tid;
        console.log('Customer object while signin', objCustomer);
        //debugger;


        await this.customersService.post(objCustomer, token).toPromise().then(async res => {
          console.log('customer after signup', res);
          //var usr:User = this.authService.CurrentUser();
          objUser.OwnerId = String(res['ID']);
          this.authService.login(objUser);
          localStorage.removeItem("fragment");
          this.router.navigate([this.redirecturl]);
          console.log("Customer Successfully Saved!");
        }, error => {

          console.log(error);
          this.messageDialog.openMessageDialog("Signup not succeeded. Please send an email to contact@venuearc.com.");
        })

      }
    }
  }

  signInProcess_SameTenant(decoded: any, token: any) {

    if (localStorage.getItem("fragment")) {
      this.processSignup(localStorage.getItem("fragment"))
    }
    else if (decoded) {
      var ownerId = null;
      if (decoded.extension_ownerId) {
        ownerId = decoded.extension_ownerId;
        var objUser: User = new User();
        //objUser.Id = decoded.oid;
        objUser.Id = decoded.sub;
        objUser.OwnerId = ownerId;
        objUser.Name = decoded.name;
        objUser.token = token;
        objUser.tid = decoded.tid;
        objUser.sessionTimeout = this.sessionTimeout();

        //objUser.email = decoded.emails[0];
        objUser.email = decoded.email;
        this.authService.login(objUser);
        this.router.navigate(["calendar"]);
      }
    } else if (token && token.search("ess_denied&error_description=AADB2C90118") !== -1) {
      window.location.href = this.getResetPasswordUrl();
    } else {
      this.router.navigate(["signup"]);
    }
  }

  private async processSignin_2(decoded:any) {
    debugger;
    if (decoded.tid) {
      if (decoded.tid == customConfig.tenantId) {
        this.signInProcess_SameTenant_2(decoded);
      }
      else if (decoded.tid != customConfig?.tenantId) {
        var email:string = "";
        if (decoded.preferred_username)
          email = decoded.preferred_username;
        else
          email = decoded.email;

        debugger;
        await (await this.getTenants(decoded.tid, email)).toPromise().then(async res => {
          debugger;
          if (res) {
            var customerMarketPlace: any[] = res as any[];
            if (customerMarketPlace.length > 1) {
              this.router.navigate(["/select-tenant"], { queryParams: { tid: decoded.tid, token: '123', email:email } });
            } else {
              await this.signInProcess_DifferentTenant_2(decoded);
            }
          } else {
            debugger;
            this.router.navigate(["/requiredsubscription"]);
          }
        }, (error: { error: { text: any; }; message: any; }) => {
          if (error.error.text) {
            if (String(error.error.text).indexOf("Error:") == 0) {
              this.messageDialog.openMessageDialog(error.error.text);
              //alert(error.error.text);
            }
            else {
              this.messageDialog.openMessageDialog(error.message);
              //alert(error.message);
            }
          }
          else
              this.messageDialog.openMessageDialog(error.message);
        });

      }
    } 
    // else if (token && token.search("ess_denied&error_description=AADB2C90118") !== -1) {
    //   window.location.href = this.getResetPasswordUrl();
    // } else {
    //   this.router.navigate(["signup"]);
    // }

  }

  async signInProcess_DifferentTenant_2(decoded: any) {
    
    if (decoded) {
      var ownerId = null;
    
      {    
        var objUser: User = new User();    
        objUser.Id = decoded.sub;    
        objUser.token = '123';//token;
        objUser.tid = decoded.tid;    
        if (decoded.preferred_username)
          objUser.email = decoded.preferred_username;
        else
          objUser.email = decoded.email;

        if (decoded.name)
          objUser.Name = decoded.name;
        else 
          objUser.Name = objUser.email;
          
        objUser.sessionTimeout = this.sessionTimeout();
        //this.router.navigate(["calendar"]);

        //create customer object and save in sql database
        var userNameSplit: string[] = objUser.Name.split(' ');
        var objCustomer = new Customer();
        if(userNameSplit.length > 0)
          objCustomer.FirstName = userNameSplit[0];
          
        if(userNameSplit.length > 1)
          objCustomer.LastName = userNameSplit[1];

        objCustomer.OrganisationName = "";
        objCustomer.Email = objUser.email;
        objCustomer.tid = objUser.tid;
        console.log('Customer object while signin', objCustomer);
        //debugger;


        await this.customersService.post(objCustomer, '123').toPromise().then(async res => {
          console.log('customer after signup', res);          
          objUser.OwnerId = String(res['ID']);       
          this.authService.login(objUser);   
          localStorage.removeItem("fragment");
          debugger;
          //this.router.navigateByUrl(this.redirecturl);
          //this.router.navigate([this.redirecturl]);
          this.router.navigate(['sso-redirect']);
          
          console.log("Customer Successfully Saved!");
        }, (error: any) => {

          console.log(error);          
          alert('Signup not succeeded. Please send an email to contact@venuearc.com.');
        })

      }
    }
  }

  signInProcess_SameTenant_2(decoded: any) {

    if (localStorage.getItem("fragment")) {
      this.processSignup(localStorage.getItem("fragment")?.toString());
    }
    else if (decoded) {
      var ownerId = null;

      if(decoded.extension_ownerId)
        ownerId = decoded.extension_ownerId;
      else if(localStorage.getItem("ownerId"))
        ownerId = localStorage.getItem("ownerId");
      
      if (ownerId) {
       
        var objUser: User = new User();
        
        objUser.Id = decoded.sub;
        objUser.OwnerId = ownerId;
        objUser.Name = decoded.name;
        objUser.token = '1223';//token;
        objUser.tid = decoded.tid;
        objUser.sessionTimeout = this.sessionTimeout();        
        objUser.email = decoded.email;
        this.authService.login(objUser);
        this.router.navigate([this.redirecturl]);
      }
    } 
    // else if (token && token.search("ess_denied&error_description=AADB2C90118") !== -1) {
    //   window.location.href = this.getResetPasswordUrl();
    // } 
    else {
      this.router.navigate(["signup"]);
    }
  }

  async getTenants(tid, email) {
    return await this.customersService.getTenants(tid, email)
  }

  async  processSignup(routefragment:string) {
   // debugger;
    localStorage.setItem("fragment", routefragment);

    //const fragment = routefragment ? routefragment : "dummyfragment";
    //const token = fragment.substring(this.tokenIdentifier.length, fragment.length);

    const fragment = routefragment ? routefragment : "dummyfragment";
    let token = "";
    if(fragment.includes('session_state') == false)
      token = fragment.substring(this.tokenIdentifier.length, fragment.length);
    else 
      token = fragment.split('&')[0].split("&")[0].substring(this.tokenIdentifier.length, fragment.split('&')[0].split("&")[0].length);

    const decoded = this.jwtHelper.decodeToken(token);

    if (decoded.tid) {
      //if (decoded.tid == customConfig.tenantId) 
      if (decoded.ownerId)
      {
        if (decoded.ownerId) {

          var ownerId = null;
          ownerId = String(decoded.ownerId);
          var obj = {
            ownerId: ownerId,
            userObjectId: decoded.sub,
            userEmail: decoded.email
          }

          //User code
          var objUser: User = new User();
          objUser.Id = decoded.sub;
          objUser.OwnerId = ownerId;
          objUser.tid = decoded.tid;
          //objUser.Name = decoded.name;
          objUser.token = token;
          objUser.email = decoded.email;
          objUser.sessionTimeout = this.sessionTimeout();
          this.authService.login(objUser);

          await this.userManagerService.post(obj).toPromise().then(async res => {
            localStorage.removeItem("fragment");
            this.router.navigate(["/calendar"]);

          }, error => {
            console.log(error);
          })
        }
        else {
          this.router.navigate([""]);
        }
      }
      else if (decoded.tid != customConfig.tenantId) {
        //this.authService.login(objUser);
        
        if (decoded) {
          var ownerId = null;
          //if (decoded.extension_ownerId) 
          {

            //ownerId = decoded.extension_ownerId;
            var objUser: User = new User();
            //objUser.Id = decoded.oid;
            objUser.Id = decoded.sub;
           // objUser.OwnerId = ownerId;
            objUser.Name = decoded.name;
            objUser.token = token;
            objUser.tid = decoded.tid;
            //objUser.email = decoded.emails[0];
            objUser.email = decoded.email;
            this.authService.login(objUser);
            objUser.sessionTimeout = this.sessionTimeout();
            //this.router.navigate(["calendar"]);

            //create customer object and save in sql database
            var userNameSplit:string[] =  objUser.Name.split(' ');
            var objCustomer =  new Customer();
            objCustomer.FirstName = userNameSplit[0];
            objCustomer.LastName = userNameSplit[1];
            objCustomer.OrganisationName = "";
            objCustomer.Email = objUser.email;
            objCustomer.tid = objUser.tid;
            console.log('Customer object while signin',objCustomer);
           // debugger;

            await this.customersService.post(objCustomer, token).toPromise().then(async res => {
              localStorage.removeItem("fragment");
              this.router.navigate(["/calendar"]);
              console.log("Customer Successfully Saved!");
            }, error => {

              console.log(error);
              this.messageDialog.openMessageDialog("Signup not succeeded. Please send an email to contact@venuearc.com.");
            })

          }
        }
       
      }
    }

  }

  private processResetPassword(routefragment:string): boolean {
    //debugger;
    //const fragment = routefragment ? routefragment : "dummyfragment";
    //const token = fragment.substring(this.tokenIdentifier.length, fragment.length);

    const fragment = routefragment ? routefragment : "dummyfragment";
    let token = "";
    if(fragment.includes('session_state') == false)
      token = fragment.substring(this.tokenIdentifier.length, fragment.length);
    else 
      token = fragment.split('&')[0].split("&")[0].substring(this.tokenIdentifier.length, fragment.split('&')[0].split("&")[0].length);

    const decoded = this.jwtHelper.decodeToken(token);

    if (decoded) {
      this.router.navigate(["calendar"]);
      // if (!this.authService.CurrentUser) {
      //   //this.msalAuthService.loginRedirect();
      //   this.authService.redirectToLogin();
      //   window.location.href = this.getResetPasswordUrl();;
      // } else {
      //   const ownerId = decoded.extension_ownerId;
      //   var objUser: User = new User();
      //   //objUser.Id = decoded.oid;
      //   objUser.Id = decoded.sub;
      //   objUser.OwnerId = ownerId;
      //   objUser.Name = decoded.name;
      //   objUser.token = token;
      //   objUser.tid = decoded.tid;
      //   objUser.sessionTimeout = this.sessionTimeout();
      //   //objUser.email = decoded.emails[0];
      //   objUser.email = decoded.email;
      //   this.authService.login(objUser);
      //   this.router.navigate(["calendar"]);
         return true;
      // }
    } 
    else if (token && token.search("ess_denied&error_description=AADB2C90091") !== -1) {
      this.router.navigate(["calendar"]);
    } 
    else {
      window.location.href = this.getResetPasswordUrl();
    }

    return false;
  }

  getResetPasswordUrl():string{ 
    var url = `${customConfig.instance}/${customConfig.domain}/oauth2/v2.0/authorize?p=${customConfig.passwordResetPolicy}&client_id=${customConfig.clientId}&redirect_uri=${customConfig.callbackPathBase}/resetpassword&scope=${customConfig.scope}&response_type=id_token&prompt=login`;
    return url
  }

  private sessionTimeout(): Date {
    
    var sessionTimeout = moment().add(customConfig.sessionTimeoutMins,'minute').toDate();
    
    return sessionTimeout;
  }
}


class User {
  Name:string = "";
  Id:string = "";
  OwnerId:string = "";
  token:string = "";
  email:string = "";   
  tid:string = ""; 
  sessionTimeout:Date = null;
}



