import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TokenService } from 'src/app/services/token.service';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { MsalService } from '@azure/msal-angular';
import { VenueService } from 'src/app/setupForms/venue/venue.service';
import { Venue } from 'src/app/models/venue.model';
import { UserManagerService } from 'src/app/services/user-manager.service';
import { LoginUser } from '../User/login-user';
import { UserModuleAccess } from '../models/user-module-access';
import { CommonService } from '../Common.service';
import moment from 'moment';
import { PermissionService } from 'src/app/services/permission.service';
import { SignInValidateService } from 'src/app/services/sign-in-validate.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardLoginGuard implements CanActivate {
  constructor(
    public router: Router,     
    public tokenService:TokenService,
    public  authenticationService:AuthenticationService,    
    private userManagerService:UserManagerService,    
    private permissionService:PermissionService,
    private signInValidateService:SignInValidateService
    ) { 
      
    }

    IsTest:boolean = false;
    
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
      this.IsTest = route.queryParams.IsTest  == "1" ? true : false;
    debugger;

    // Check If user is logged in
    var currUser = this.authenticationService.CurrentUser();
    if (currUser == null) {
      this.authenticationService.redirectToLogin();
      return;
    } else {
      // Check session time out
      if (this.authenticationService.IsvalidSession(currUser) == false) {
        this.router.navigate(["/unauthorized"]);
        return;
      }
    }

    // Check if user is in onboarding stage
    // Sameer : 06012023
    
    if (localStorage.getItem("onboardingstatus")) {
      this.router.navigate(["/onboarding"]);
      return true;
    }
    

    if (LoginUser.loggedinUser.UserID != null)
      return true;
    else
      return this.signInValidateService.processSignin(currUser); //return this.processSignin(currUser);


  }

  private async processSignin(currUser:any): Promise<boolean> {

    //debugger;

        return new Promise(async (resolve, reject) => {
               
          //debugger;
          //User code
          var email = ''
          if(currUser.email.includes('+'))
            email = currUser.email.replace('+', '%2B');
          else 
            email = currUser.email;

            await this.userManagerService.getCustomer(currUser.Id, email).then(res =>{
              // If no data found against the user/ Ask him to request access
              if(res['dataSet'] == null){
                this.router.navigate(["/requestaccess"]);
                return;
              }

              // If user is not owner
              var user = res['dataSet']['value'][0];
              if (user.isowner != 1) {

                if (res['dataSet'] == null || res['dataSet'] == undefined) {
                  this.router.navigate(["/requestaccess"]);
                  return;
                }

                if (res['dataSet']['value'] == null || res['dataSet']['value'] == undefined || res['dataSet']['Table1'].length == 0) {
                  this.router.navigate(["/requestaccess"]);
                  return;
                }
              }

              // If user is active or not
              if(user.Inactive == true)
              {              
                this.router.navigate(["/requestaccess"]);
                return;
              }

              // Get customer from response
              var customer =  res['customer'];
              // Get product info from response
              var productInfo =  res['productInfo'];

              // Set local global Login variable
              LoginUser.loggedinUser.UserID = user.UserID;
              LoginUser.loggedinUser.UserName = user.FullName;
              LoginUser.loggedinUser.Email = user.Email;                
              LoginUser.loggedinUser.ShortCode = this.getShortCode(user.FullName);
              LoginUser.loggedinUser.OrganisationName = customer.OrganisationName;   
              LoginUser.loggedinUser.Subscription = productInfo.ProductName;   
              LoginUser.loggedinUser.SubscriptionType = productInfo.SubscriptionType;   
              LoginUser.customer = customer;   
              LoginUser.loggedinUser.SubscriptionName = productInfo.SubscriptionName;   
              LoginUser.IsAdmin = user.IsAdmin;
              // Sameer : 05030023
              LoginUser.loggedinUser.IsFreeTrial = customer.isFreeTrial;
              LoginUser.loggedinUser.StartDate = customer.startDate ? moment(customer.startDate) : moment(customer.CreatedDate);
              LoginUser.loggedinUser.ExpiryDate = customer.endDate ? moment(customer.endDate) : moment(customer.CreatedDate).add(1, 'M');
              var currentDate = moment(new Date());
              LoginUser.loggedinUser.TrialRemainingDays = LoginUser.loggedinUser.ExpiryDate.diff(currentDate, 'days');                            
              // Sameer : 05030023

              LoginUser.loggedinUser.OnBoardingStatus = customer.OnBoardingStatus;

              // Update current user data in local Storage 
              currUser['subscription'] = productInfo.ProductName;  
              currUser['SubscriptionName'] = productInfo.SubscriptionName;   
              currUser['trialVersion'] = productInfo.trialVersion;   
              currUser['activeVersion'] = productInfo.activeVersion;   
              currUser['isowner'] = user.isowner;                 
              currUser['UserID'] = user.UserID;                 
              currUser['CustomerObjectID'] = customer.CustomerObjectId;                               
              this.authenticationService.login(currUser);

              // Load Section Access
              LoginUser.getSectionAccess = res['dataSet']['Table2'] ;

              // Get Action Access
              this.permissionService.getActionData();

              // Getting User Modules data
              var rbacUM = res['dataSet']['Table1'] as UserModuleAccess[];
              var mAccess = rbacUM.filter(function (x) {
                return x.IsVisible == true
              })

              if (mAccess.length > 0) {
                LoginUser.getModulesAccess = rbacUM;     
              }

              // if(productInfo.SubscriptionStatus != "active" && productInfo.SubscriptionStatus != "trialing"){
              //   this.router.navigate(["/requiredsubscription"]);
              // }
            
              // Check if subscribed to stripe or not if it is logged in via local signup 
              if (productInfo['SubscriptionType'] == "stripe") {
                if (productInfo.ProductName == "" || productInfo.ProductName == null) {
                  if(this.IsTest == true)                  
                    this.router.navigate(["/membership/subscribe"], {queryParams:{IsTest:"1"}});
                  else
                    this.router.navigate(["/membership/subscribe"]);
                    
                  resolve(true);
                  return;
                }
              }

              // If user is not completed steps of onboarding via stripe
              // Sameer : 06012023
              
              if (customer.OnBoardingStatus == 'signup') {
                localStorage.setItem('onboardingstatus', customer.OnBoardingStatus)
                this.router.navigate(["/onboarding"]);
               
              }
              

              // If user is not completed steps of onboarding via market place
              if (customer.SubscriptionType != 'stripe') {
                if(customer.OrganisationName == null || customer.OrganisationName == "")
                  this.router.navigate(["/onboarding"]);                  
              }

              if(moment(new Date) > moment(LoginUser.loggedinUser.ExpiryDate )){
                this.router.navigate(['/expired'])
              }
            
 
             resolve(true);
           },error =>{
             reject(false);
           })                        
        })
                                                        
    return false;
  }

  getShortCode(fullName:string):string{
    var shortcode:string = '';
    if(fullName){
      var arr = fullName.split(' ');
      if(arr.length > 0){
        if(arr.length > 1){
           shortcode = arr[0].substring(0, 1);
           shortcode += arr[1].substring(0, 1);
        } 
        else{
          shortcode = arr[0].substring(0, 1);         
        }
      }
    }

    return shortcode;
  }




}
