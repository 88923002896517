import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBModalRef, MDBModalService, ToastService } from 'ng-uikit-pro-standard';
import { NgxSpinnerService } from 'ngx-spinner';
import { BLBase } from 'src/app/Base/BLBase/BLBase.component';
import { CommonService } from 'src/app/Base/Common.service';
import { SharePointConfigService } from 'src/app/Base/SharePoint/share-point-config.service';
import { ConfirmDialogeService } from 'src/app/confirm-dialoge/confirm-dialoge.service';
import { MessageDialogeService } from 'src/app/message-dialoge/message-dialoge.service';
import { GeneralService } from 'src/app/services/general.service';
import { GenericService } from 'src/app/services/generic.service';
import { themeService } from 'src/app/services/theme.service';
import { Event } from '../event-info/Event.model';
import { eventType } from 'src/app/models/eventType.model';
import { EventStatus } from 'src/app/models/EventStatus.model';
import { Autocomplete } from 'src/app/Base/Autocomplete';
import { Venue } from 'src/app/models/venue.model';
import { Genre } from 'src/app/models/genre.model';
import { Client } from '../../client/client.model';
import { Contactperson } from '../../client/contactperson.model';
import { eventcategory } from 'src/app/models/eventcategory';
import { eventTicketType } from 'src/app/models/eventTicketType';
import { Event_SubEvent } from '../event-info/Event_SubEvent.model';
import moment from 'moment';
import { EnEventStatus, EnEventType } from 'src/app/Enum/Enums';
import { VenueService } from 'src/app/setupForms/venue/venue.service';
import { EventFuncService } from '../EventFuncService.service';
import { EventStatusService } from 'src/app/setupForms/event-status/event-status.service';
import { ToastType } from 'src/app/Enum/ToastType';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { LoginUser } from 'src/app/Base/User/login-user';

@Component({
  selector: 'app-quick-event-mdb',
  templateUrl: './quick-event-mdb.component.html',
  styleUrls: ['./quick-event-mdb.component.scss']
})
export class QuickEventMdbComponent extends BLBase<Event>  implements OnInit {
  subEventType: eventType[] = [];
  Event_Status:EventStatus[] = [];
  AutoCompVenue: Autocomplete<Venue> = new Autocomplete<Venue>("VenueName", "ID");
  AutoCompGenre: Autocomplete<Genre> = new Autocomplete<Genre>("GenreName", "ID");
  AutoCompClient: Autocomplete<Client> = new Autocomplete<Client>("ClientName", "ID");
  AutoCompContactPerson: Autocomplete<Contactperson> = new Autocomplete<Contactperson>("ContactPersonName", "ID");
  AutoCompCategory: Autocomplete<eventcategory> = new Autocomplete<eventcategory>("CategoryName", "ID");
  AutoCompTicketType: Autocomplete<eventTicketType> = new Autocomplete<eventTicketType>("TicketTypeName", "ID");
  AutoCompAdditionalContactPerson: Autocomplete<Contactperson> = new Autocomplete<Contactperson>("ContactPersonName", "ID");
  AutoCompSignatory: Autocomplete<Contactperson> = new Autocomplete<Contactperson>("ContactPersonName", "ID");
  IsAllDatesAreValid:any[]=[];
  errorMessageDatesStatus: string = "";
  public EventDatesUnavailableError = [];

  formDataSub: Event_SubEvent = new Event_SubEvent();
  date:any=null;
  action = new Subject<any>();

  dayEvent:string = "OneDay";
  singleDayObj:any={
    oneDayDate:null  
  }

  constructor(
    public service: GenericService,
    public router: Router,
    public route: ActivatedRoute,
    public spinner: NgxSpinnerService,
    public toast: ToastService,
    public dialog: ConfirmDialogeService,
    public genService: GeneralService,
    public modalService: MDBModalService,
    public messageDialoge: MessageDialogeService,
    public common: CommonService,
    //public spService: SPOperationsService,
    public spConfigService:SharePointConfigService,
    public modalRef: MDBModalRef,
    public theme:themeService,
    public eventFunc: EventFuncService,
    public venueService: VenueService,
    public eventStatusService: EventStatusService,
  ) {
    super(service, router, route, spinner, toast, dialog, messageDialoge);
    this.formTitle = "Event";
    this.addControllerName("Events");
    this.isGetByPrimaryKey = false;
  }

 async ngOnInit() {
    super.ngOnInit();
    await this.quickEventInit();
  }

  async ngAfterViewInit(){
    
   await this.setEventDates();

    
    // await this.quickEventInit();
  }

  isLoading:boolean = null;
  async quickEventInit(){
    this.isLoading = true;
    await this.getVenues();
    //await this.getEventStaus();
    await this.getClients();
    await this.FillAutoComplete("Genres?$filter=Inactive ne true &$orderby=GenreName", this.AutoCompGenre, "GenreID");
    //  await this.FillAutoComplete("Clients?$filter=IsActive ne true &$orderby=ClientName", this.AutoCompClient,"ClientID");
    this.FillSelect("EventTypes", "EventTypes");
    await this.FillAutoComplete("EventCategories", this.AutoCompCategory, "CategoryID");
    await this.FillAutoComplete("EventTicketTypes", this.AutoCompTicketType, "EventTypeID");
    await this.setEventDates(); 
    this.isLoading = false;
  }


  public Initializeobject() {
    this.formData = new Event();
  }

  async getVenues() {
    // this.ShowSpinner();
    await this.venueService.getVenues().then(res => {
      this.AutoCompVenue.data = null;
      this.AutoCompVenue.data = res['value'];
      this.AutoCompVenue.resultObserve();
      this.formData.VenueID = this.AutoCompVenue.data[0].ID;
      // this.HideSpinner();
    }, error => {
      this.HideSpinner();
      console.log(error);
      this.ShowToast(error.message, ToastType.Error);
    })
  }

  async getEventStaus(){
    // this.ShowSpinner();
    await this.eventStatusService.getEventStatus().then(res => {
      this.Event_Status = res['value'] as EventStatus[];
      this.formDataSub.EventStatusID = EnEventStatus.Hold1;
      // this.HideSpinner();
    }, error => {
      this.HideSpinner();
      console.log(error);
      this.ShowToast(error.message, ToastType.Error);
    })
  }

  onClientChange(CurrentContactPersonID?: number, CurrentAdditionalContactPersonID?: number, CurrentSigntoryContactPersonID?: number) {
    this.AutoCompContactPerson.data = [];
    this.formData.ContactPersonID = null;
    this.formData.AdditionalContactPersonID = null;
    this.formData.SignatoryID = null;

    if (this.formData.ClientID != null) {
      this.ShowSpinner();

      this.FillAutoComplete("Client_ContactPerson?$filter=IsActive ne true and ClientID eq " + this.formData.ClientID + "&$orderby=ContactPersonName", this.AutoCompContactPerson).then(
        res => {
          this.formData.ContactPersonID = CurrentContactPersonID;
          this.HideSpinner();
        }
        )

        this.FillAutoComplete("Client_ContactPerson?$filter=IsActive ne true and ClientID eq " + this.formData.ClientID + "&$orderby=ContactPersonName", this.AutoCompAdditionalContactPerson).then(
          res => {
            this.formData.AdditionalContactPersonID = CurrentAdditionalContactPersonID;
            this.HideSpinner();
          }
          )

          this.FillAutoComplete("Client_ContactPerson?$filter=IsActive ne true and ClientID eq " + this.formData.ClientID + "&$orderby=ContactPersonName", this.AutoCompSignatory).then(
            res => {
              this.formData.SignatoryID = CurrentSigntoryContactPersonID;
              this.HideSpinner();
        }
      )

    }
  }

  async FillAutoComplete(query: string, Autocomplete: Autocomplete<any>,modelName?:string) {
    // this.ShowSpinner();
    await this.genService.GetDataByQuery(query).then(
      res => {
        Autocomplete.data = null;
        Autocomplete.data = res['value'];
        Autocomplete.resultObserve();
        if(modelName !=null || modelName !='' || modelName != undefined){
          this.formData[modelName] = Autocomplete.data[0]?.ID;
        }
        // this.HideSpinner();
      }
      , error => {
        this.HideSpinner();
        this.ShowToast(error.message, ToastType.Error);
      }
    )

  }

  BeforeUpsert(form: NgForm) {
    debugger
    delete form.value.oneDayDate;
    delete form.value.dayEvent;
    this.sectionName = "Event";
    var Event_SubEvents: Event_SubEvent[] = [];
    if(this.onDatesChange() == false){
        this.HideSpinner();
      return
    }
    if(this.IsAllDatesAreValid.length > 0 || this.IsInvalidForm == true){
      this.HideSpinner();
      return
    }
    var dates = this.getDates(form.value.EventStartDate, form.value.EventEndDate);
    dates.forEach(element => {
      var subEvent: Event_SubEvent = new Event_SubEvent();
      delete subEvent.eventStatus;
      delete subEvent.errorMessage;
      subEvent.SubEventTypeID = form.value.SubEventTypeID;
      subEvent.EventStatusID = form.value.EventStatusID;
      subEvent.EventID = form.value.ID;
      subEvent.SubEventDate = element;
      subEvent.OriginalSubEventDate = element;
      subEvent.TimeIn = form.value.ShowStartTime;
      subEvent.TimeOut = form.value.ShowEndTime;
      subEvent.ShowStartTime = form.value.ShowStartTime;
      subEvent.ShowEndTime = form.value.ShowEndTime;
      subEvent.StandardLicenseFee = form.value.StandardLicenseFee;
      Event_SubEvents.push(subEvent);
    })
     delete form.value.SubEventTypeID;
     delete form.value.StandardLicenseFee;
     delete form.value.ShowStartTime;
     delete form.value.ShowEndTime;
     delete form.value.EventStatusID;

     form.value.Event_SubEvent = Event_SubEvents;
  }

  onSubmit(form: NgForm) {
    //BLBase function
    this.insertRecord(form);
  }

  AfterUpsert(form: NgForm) {
    var obj = {
      status:"Submit",
      date:this.formData.EventStartDate
    }
    this.action.next(obj);
    this.modalRef.hide();
  }

  getDates(StartDate: any, EndDate: any): any[] {
    var Dates: any[] = [];
    var currentDate: Date = new Date(StartDate);
    Dates.push(moment(currentDate).format('yyyy-MM-DD'));

    while (currentDate < new Date(EndDate)) {
      if (Dates.length > 0) {
        currentDate.setDate(currentDate.getDate() + 1);
        Dates.push(moment(currentDate).format('yyyy-MM-DD'));
      }

    }
    return Dates;
  }

  async FillSelect(selectName: string, query: string) {
    await this.genService.GetDataByQuery(query).then(
      res => {
        switch (selectName) {
          case "EventTypes": {
            this.subEventType = res['value'] as eventType[];
            this.formDataSub.SubEventTypeID = EnEventType.Performanceticketed;
            break;
          }
        }
      }
      , error => {
        this.HideSpinner();
        this.ShowToast(error.message, ToastType.Error);
      }
    )
  }

  async getClients(){
    // this.ShowSpinner();

    await this.genService.GetDataByQuery('Clients?$filter=IsActive ne true &$orderby=ClientName').then(
      res => {
        this.AutoCompClient.data = null;
        this.AutoCompClient.data = res['value'];
        this.AutoCompClient.resultObserve();
        if(this.AutoCompClient.data !=null && this.AutoCompClient.data.length != 0 && this.AutoCompClient.data.length == 1){
          this.formData.ClientID = this.AutoCompClient.data[0]?.ID;
          this.getContactPerson(this.formData.ClientID);
        }
        // this.HideSpinner();

      },error => {
        this.HideSpinner();
        this.ShowToast(error.message, ToastType.Error);
      }
    )
  }

  async getContactPerson(ClientID:number){
    // this.ShowSpinner();

    await this.genService.GetDataByQuery("Client_ContactPerson?$filter=IsActive ne true and ClientID eq " + ClientID + "&$orderby=ContactPersonName").then(
      res => {
        this.AutoCompContactPerson.data = null;
        this.AutoCompContactPerson.data = res['value'];
        this.AutoCompContactPerson.resultObserve();
        if(this.AutoCompContactPerson.data !=null && this.AutoCompContactPerson.data.length != 0){
          this.formData.ContactPersonID = this.AutoCompContactPerson.data[0]?.ID;
        }
        // this.HideSpinner();

      },error => {
        this.HideSpinner();
        this.ShowToast(error.message, ToastType.Error);
      }
    )
  }


  onDatesChange():boolean{
    this.singleDayObj.oneDayDate = this.formData.EventStartDate;
    if (this.IsVaildDates(this.formData.EventStartDate, this.formData.EventEndDate) == false) {
      this.ErrorMessage("Event start date cannot be greater than event end date.");
      this.messageDialog.openMessageDialog(this.message);
      return false;
    }

    this.ValidateDatesAvailability();

    return true
  }

  onSingleDayDatesChange():boolean{
    this.formData.EventStartDate = this.singleDayObj.oneDayDate;
    this.formData.EventEndDate = this.singleDayObj.oneDayDate;

    if (this.IsVaildDates(this.formData.EventStartDate, this.formData.EventEndDate) == false) {
      this.ErrorMessage("Event start date cannot be greater than event end date.");
      this.messageDialog.openMessageDialog(this.message);
      return false;
    }

    this.ValidateDatesAvailability();

    return true
  }

  IsVaildDates(StartDate, EndDate): boolean {
    if (StartDate == EndDate)
      return true;
    else if (moment(StartDate) > moment(EndDate))
      return false

    return true;
  }

  ValidateDatesAvailability(isonload:boolean = false) {

    if (this.primaryKey != 0)
      return;

    //this.ShowSpinner();;
    var promiseArr: any[] = [];
    this.IsAllDatesAreValid = [];



    this.GetAvailableDates().then(
      res => {
        //this.HideSpinner();;
        this.errorMessageDatesStatus = "";
        this.EventDatesUnavailableError = [];
        this.IsInvalidForm = false;

        if (this.IsAllDatesAreValid.length > 0) {
          if (isonload == true ) {
            this.formData.EventEndDate = null;
            this.formData.EventStartDate = null;
            return;
          }
          this.errorMessageDatesStatus = " " + '\n';
             
        
          this.IsAllDatesAreValid.forEach(element => {

            this.EventDatesUnavailableError = String(element.error).split(';');

            this.errorMessageDatesStatus += element.error;
         
          });

          this.IsInvalidForm = true;
        }
        else {
          this.errorMessageDatesStatus = "";
          this.EventDatesUnavailableError = [];
          this.IsInvalidForm = false;
        }

        this.IsAllDatesAreValid = [];
      }, error => {
        //this.HideSpinner();;
        this.ShowToast(error.message, ToastType.Error);
      })
  }

  async GetAvailableDates() {
    if (this.formData.VenueID == null)
      return;

      var obj = {
        VenueID : this.formData.VenueID,
        EventID : this.formData.ID ,
        // StartDate : this.datepipe.transform(this.formData.EventStartDate, 'MM/dd/yyyy'),
        StartDate : moment(this.formData.EventStartDate).format('MM/DD/yyyy'),
        // EndDate:this.datepipe.transform(this.formData.EventEndDate, 'MM/dd/yyyy')
        EndDate:moment(this.formData.EventEndDate).format('MM/DD/yyyy'),
        CustomerObjectID: LoginUser.customer.CustomerObjectId.toString(),
      }

    //var qry = "Events/GetAvailableDates(VenueID=" + this.formData.VenueID + ",EventID=" + this.formData.ID + ",StartDate='" + this.datepipe.transform(eventSeriesDate.EventStartDate, 'MM-dd-yyyy') + "',EndDate='" + this.datepipe.transform(eventSeriesDate.EventEndDate, 'MM-dd-yyyy') + "')";
    //var qry = "api/EventOp/GetAvailableDates/" + this.formData.VenueID + "/" + this.formData.ID + "/" + this.datepipe.transform(eventSeriesDate.EventStartDate, 'MM/dd/yyyy') + "/" + this.datepipe.transform(eventSeriesDate.EventEndDate, 'MM/dd/yyyy') ;
    var qry = "api/EventOp/GetAvailableDates" ;
    await this.genService.setData(qry, obj).toPromise().then(
      res => {

        var new_eventStatus: EventStatus[] = [];

        //var ev = res['value'] as EventStatus[];
        var ev = res as EventStatus[];
        ev.forEach(element => {

          var es = new EventStatus();
          es.ID = element.ID;
          es.EventStatus = element.EventStatus;
          es.DESCRPTION = element.DESCRPTION;
          es.Color = element.Color;
          new_eventStatus.push(es);

        });

        if (new_eventStatus.length > 0) {
          this.Event_Status = new_eventStatus;
          this.formDataSub.EventStatusID = new_eventStatus[0].ID;
        } 
        // this.Event_Status = new_eventStatus;
        // this.errorMessageDatesStatus = "";
        // this.IsInvalidForm = false;
      }
      , error => {
        //this.HideSpinner();;
        if (this.formDataSub.EventStatusID != EnEventStatus.Deleted && this.formDataSub.EventStatusID != EnEventStatus.Cancel) {
          this.IsAllDatesAreValid.push({
            'error': error.error
          })
        }

        //this.errorMessageDatesStatus = "Following Dates are booked. " + '\n' + error.error;
        //this.IsInvalidForm = true;
        //eventSeriesDate.eventStatus = [];
        // eventSeriesDate.eventStatus = this.eventStatus_WhenContracted;
        //this.IsAllDatesValid = false;
      }
    )
  }

  onDisabled(form:NgForm):boolean{
    if(form.invalid || this.EventDatesUnavailableError.length > 0){
      return true;
    }
    return false;
  }

  async setEventDates() {
    
    this.formDataSub.ShowStartTime = "08:30"; //moment(new Date()).format('HH:mm');
    this.formDataSub.ShowEndTime = "23:59";// moment(new Date()).format('HH:mm'); 
    if (this.date != null) {//|| this.data?.date !=''){
      this.formData.EventStartDate = moment(this.date).format('YYYY-MM-DD');
      this.formData.EventEndDate = moment(this.date).format('YYYY-MM-DD');
      this.singleDayObj.oneDayDate = moment(this.date).format('YYYY-MM-DD');
    }
    else {
      this.formData.EventStartDate = moment(new Date()).format('YYYY-MM-DD');
      this.formData.EventEndDate = moment(new Date()).format('YYYY-MM-DD');
      this.singleDayObj.oneDayDate = moment(new Date()).format('YYYY-MM-DD');
    }

   this.ValidateDatesAvailability();
  //  this.ValidateDatesAvailability(true);




  }

  hideDummyClient(row:any):boolean{
    if(this.AutoCompClient.data.length > 1  && row.CreatedBy == -1){

      return true;
    }
    return false;
  }

}
