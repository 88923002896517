import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Autocomplete } from 'src/app/Base/Autocomplete';
import { CommonService } from 'src/app/Base/Common.service';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { MessageDialogeService } from 'src/app/message-dialoge/message-dialoge.service';
import { Customer } from 'src/app/models/customer.model';
import { CustomersService } from 'src/app/services/customers.service';
import { GeneralService } from 'src/app/services/general.service';
import { TokenService } from 'src/app/services/token.service';
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms-and-conditions.component';
import * as Countries from '../../../../assets/countries.json';
import * as customConfig from 'src/app/customConfig.json';
import { MsalService } from '@azure/msal-angular';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-signup-new',
  templateUrl: './signup-new.component.html',
  styleUrls: ['./signup-new.component.scss']
})
export class SignupNewComponent implements OnInit {
  obj: Customer;
  repeatPassword: string = "";
  hide_password = true;
  hide_repeat_password = true;
  loginDisplay: number;
  searchUserLead = null;
  checkedTermsConditions: boolean = false;
  public modalRef: MDBModalRef;
  countries: any[] = [];
  AutoCompCntry: Autocomplete<any> = new Autocomplete<any>("name", "name");

  @Output() onSave: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public generalService: GeneralService,
    public tokenService: TokenService,
    private authenticationService: AuthenticationService,

    public customersService: CustomersService,
    public common: CommonService,
    public modalService: MDBModalService,
    public messageDialog: MessageDialogeService,
    public cdr: ChangeDetectorRef,
    private msalAuthService: MsalService,

  ) {
    this.obj = new Customer();
  }

  ngOnInit(): void {
    this.FillAutoComplete(this.AutoCompCntry);
  }

  // validate(email:NgModel) {
  validate() {
    if (this.obj.FirstName == "" || this.obj.FirstName == null)
      return true;

    if (this.obj.LastName == "" || this.obj.LastName == null)
      return true;

    // if (this.obj.Email == "" || this.obj.Email == null || (email.errors  && email.errors.pattern))
    if (this.obj.Email == "" || this.obj.Email == null )
      return true;

    if (this.checkedTermsConditions == false || this.checkedTermsConditions == null)
      return true;

    if (this.otherError() == true)
      return true;

    return false;
  }

  errorMessage: string = "";
  otherError() {
    return false;
  }

  IsshowProgress: boolean = false;
  IsSaved: boolean = false;

  Save() {

    this.common.ShowSpinner();
    this.IsshowProgress = true;
    // setInterval(() => {
    //   this.IsshowProgress =false;
    //   this.IsSaved = true;
    // }, 5000);

    this.obj.tid = customConfig.tenantId;
    // if (this.authenticationService.CurrentUser() && this.authenticationService.CurrentUser().tid)
    //     this.obj.tid = this.authenticationService.CurrentUser().tid;
    // return;
    this.customersService.post(this.obj).subscribe(res => {

      this.IsshowProgress = false;
      this.IsSaved = true;
      this.common.HideSpinner();
      this.onSave.emit(this.IsSaved);

    }, error => {
      this.IsshowProgress = false;
      this.IsSaved = false;
      console.log(error);
      this.common.HideSpinner();
      if (error.error.text) {
        if (String(error.error.text).indexOf("Error:") == 0) {
          this.messageDialog.openMessageDialog(error.error.text);
        }
        else {
          this.messageDialog.openMessageDialog("Signup not succeeded. Please send an email to contact@venuearc.com.");
        }
      }
      else
        this.messageDialog.openMessageDialog("Signup not succeeded. Please send an email to contact@venuearc.com.");


      //this.messageDialog.openMessageDialog("Signup not succeeded. Please send an email to contact@venuearc.com.");
    }); // Inserting Data
  }

  login() {
    //this.authService.loginRedirect();
    //this.authenticationService.redirectToLogin();
    this.msalAuthService.loginRedirect();
  }

  logout() {
   // this.authService.logout();
  }

  setLoginDisplay() {
   // this.loginDisplay = this.authService.getAllAccounts().length;
  }

  ngOnDestroy(): void {
  }

  // openTermsAndConditions(){
  //   console.log(this.checkedTermsConditions);
  //   this.modalRef = this.modalService.show(TermsAndConditionsComponent, {
  //     backdrop: true,
  //     keyboard: true,
  //     focus: true,
  //     show: false,
  //     ignoreBackdropClick: true,
  //     class: 'modal-lg modal-content modal-dialog cascading-modal modal-top',
  //     containerClass: '',
  //     animated: true,
  //     styles: "overflow-y: auto",
  //   });
  // }

  openTermsAndConditions() {

    this.modalRef = this.modalService.show(TermsAndConditionsComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-lg modal-content modal-dialog cascading-modal modal-top',
      containerClass: '',
      animated: true,
      styles: "overflow-y: auto",
    });

    this.modalRef.content.action.subscribe((result: any) => {
      if (result == true) {
        // this.Save();
      }
    });
  }

  async FillAutoComplete(Autocomplete: Autocomplete<any>) {
    this.countries = Countries['default']
    Autocomplete.data = null;
    Autocomplete.data = Countries['default'];
    Autocomplete.resultObserve();
  }

  selectedMask = "";
  selectedCntryImg: any = null;
  selectedCntryCode: any = null;
  getMaskbyCountryName(option) {
    this.selectedMask = "";
    this.selectedCntryImg = null;
    this.obj.CountryCode = null;
    var selectedOption = this.countries.filter(a => a.name == option) as any[];
    if (selectedOption.length > 0) {
      var replVal = /#/gi;
      var typeOfMask = typeof selectedOption[0].mask;
      if (typeOfMask == "object") {
        this.selectedMask = selectedOption[0].mask[0]?.replace(replVal, "0");
        this.selectedCntryCode = selectedOption[0].code != null ? selectedOption[0].code : '';
        this.selectedCntryImg = selectedOption[0].flag != null ? selectedOption[0].flag : '';
        this.obj.CountryCode = selectedOption[0].code != null ? selectedOption[0].code : '';

      }
      else {

        this.selectedMask = selectedOption[0].mask?.replace(replVal, "0");
        this.selectedCntryCode = selectedOption[0].code != null ? selectedOption[0].code : '';
        this.obj.CountryCode = selectedOption[0].code != null ? selectedOption[0].code : '';
        this.selectedCntryImg = selectedOption[0].flag != null ? selectedOption[0].flag : '';
      }
    }

    this.cdr.detectChanges();
    this.obj.Phone = "";
  }

  navigateMarketPlaceSignUp(){
    //window.location.href = "https://portal.azure.com/#create/alrafayconsultingllc1646338685878.venuearc_test";
    //window.location.href = "https://portal.azure.com/#view/Microsoft_Azure_Marketplace/GalleryItemDetailsBladeNopdl/id/alrafayconsultingllc1646338685878.venuearc_mod3";
    window.location.href = customConfig.marketplacelink;
  }

  subscribeAS(){
    window.location.href =  customConfig.appsourcelink;
  }
}
