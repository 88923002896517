

<!-- <div class="myForm  mt-1">
    <form class=" p-5 mb-5 rounded mt-4  border-form" >
        <div class="d-flex justify-content-center">
            <div class="logo">
                <img src="assets/venuearc_logo.png" alt="Logo" style="width: 300px;">
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 pt-5 pb-3 text-center">
                <h2 style="color:#0e83a8;">Subscription Required!</h2>
                <br>
                <p>You don't have any subscription of VenueArc.</p>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12  text-center">
                <p style="color: gray;">Subscribe with</p>
            </div>
        </div>

        <div class="row">
            <div class="col-md-2 col-sm-12"></div>
            <div class="col-md-8 col-sm-12">
                <a  mdbBtn color="secondary" class="waves-light w-100" mdbWavesEffect
                    type="button" (click)="subscribeMP()" rounded="true">
                    <mdb-icon fab icon="microsoft"></mdb-icon>
                    <span class="pl-2">Azure Market place</span>
                </a>
            </div>
            <div class="col-md-2 col-sm-12"></div>
        </div>

        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8 ">
                <a  mdbBtn color="info" class="waves-light w-100" mdbWavesEffect
                    type="button" (click)="subscribeAS()" rounded="true">
                    <mdb-icon fab icon="microsoft"></mdb-icon>
                    <span class="pl-2">Microsoft App Source</span>
                </a>
            </div>
            <div class="col-md-2"></div>
        </div>

        <div class="row mt-5">
            <div class="col-md-2"></div>
            <div class="col-md-8 ">
                <a  mdbBtn color="light" style="color: black;" class="waves-light w-100" mdbWavesEffect
                    type="button" (click)="logout()" >
                    <mdb-icon fas icon="sign-out-alt"></mdb-icon>
                    <span class="pl-2" >Log Out</span>
                </a>
            </div>
            <div class="col-md-2"></div>
        </div>


    </form>
</div> -->

<!-- <div class="container-fluid " [class]="teams.isTeamsApp() == true ? 'bg-frm' : 'bg-frm-teams'">
  <div class="d-flex align-items-center bdy2">
    <div class="box">
      <div class="text-center">
        <img src="assets/venue_arc_singup.png" alt="Logo" class="logo">
      </div>
      <div class="text-center">
        <div class=" pt-2 pb-3 text-center">
          <h2 style="color:#0e83a8">
            Subscription Required!
          </h2>
          <br>
          <p>You don't have any subscription of VenueArc.</p>
        </div>

        <div class="">
          <p class="text-center mb-0">
            Subscribed with
          </p>
          <div class="text-center" *ngIf="teams.isTeamsApp() == true">

            <a mdbBtn floating="true" size="md" placement="right" mdbWavesEffect style="background: #161f65" mdbTooltip="Azure Marketplace" (click)="subscribeMP()">
              <mdb-icon fab icon="adobe"></mdb-icon>
            </a>

            

            <a mdbBtn floating="true" size="md" placement="right" mdbWavesEffect style="background: #161f65" mdbTooltip="Microsoft App Source"  (click)="subscribeAS()" >
              <mdb-icon fab icon="microsoft"></mdb-icon>
            </a>

            
          </div>

          <div class="d-flex justify-content-center mt-2" *ngIf="teams.isTeamsApp() == false">

            <a class="d-flex flex-column align-items-center socialAccLink" matTooltip="Azure MarketPlace" [href]="marketPlace" target="_blank" style="margin-right: 12px;">
              <img class="mb-2 myWidth" src="assets/3.png" alt="Azure" width="70px" style="height: 30px; width: 60px;">

            </a>

            <a class="d-flex flex-column align-items-center socialAccLink"  matTooltip="Microsoft App Source" [href]="appSource" target="_blank" >
              <img class="mb-2 myWidth" src="assets/4.png" alt="Azure" style="height: 30px;width: 45px;">
            </a>

          </div>

          <div class="" *ngIf="teams.isTeamsApp() == true">
            <button mdbBtn  class="waves-light w-75" mdbWavesEffect type="button" style="color: white;background: #cd2828a1 !important;border-radius: 12px !important;" (click)="logout()" >
              <mdb-icon fas icon="sign-out-alt"></mdb-icon>
              <span class="pl-2"> Log out </span>

            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="container-fluid">
  <div class="row">
    <div class="col-md-7 hideDesktop autoHeight" style="background-color: #40a9e6; min-height: 100vh;">
      <div class="leftMidContainer">
        <img src="assets/venue_white.png" alt="VenueArc" width="220px" *ngIf="teams.isTeamsApp() == false">
        <div class="welcomeHeading">
          <h1 id="h1WelcomeHead">Welcome to VenueArc</h1>
        </div>

        <div class="paraHeading">
          <!-- <h4> You don't have any subscription of VenueArc </h4> -->
        </div>

        <div class="signupHeading">
          <h4>Subscription Required!</h4>
        </div>

        <div class="d-flex justify-content-center">
          <img src="assets/1.png" alt=".." class="midVector">
        </div>
        <img src="assets/2.png" alt=".." class="fixedBotImage">

      </div>
    </div>
    <div class="col-md-5 noPaddinginMob d-flex flex-column justify-content-center">

      <div class="overlayForMob">
        <div class="leftMidContainer">

          <div class="d-flex justify-content-center mt-3 mb-4 hideMobile">
            <img src="assets/venuearc_logo.png" width="250px" alt="venueArc">
          </div>

          <div class="d-flex justify-content-center mt-2 mb-2">
            
            <h4 id="loginHeading" class="smallText"> You don't have any subscription of VenueArc </h4>
          </div>

          <div class="d-flex justify-content-center mt-2 mb-1">
            <h4 id="loginHeading" class="smallText"> Subscribe with</h4>
          </div>



          <div class="col-12 socialIDPFlex d-flex justify-content-between align-items-end p-2">

            <a class="d-flex flex-column align-items-center socialAccLink" (click)="subscribeMP()"
              *ngIf="teams.isTeamsApp() == false">
              <img class="mb-2 myWidth" src="assets/3.png" alt="Azure" width="70px">
              <h5 class="moreSmaller">Azure MarketPlace</h5>

            </a>

            <a class="d-flex flex-column align-items-center socialAccLink" [href]="marketPlace" target="_blank"
              *ngIf="teams.isTeamsApp() == true">
              <img class="mb-2 myWidth" src="assets/3.png" alt="Azure" width="70px">
              <h5 class="moreSmaller">Azure MarketPlace</h5>

            </a>

            <div id="verticalLine">
            </div>


            <a class="d-flex flex-column align-items-center socialAccLink" (click)="subscribeAS()"
              *ngIf="teams.isTeamsApp() == false">
              <img class="mb-2 myWidth" src="assets/4.png" alt="Azure" width="50px">
              <h5 class="moreSmaller">Microsoft App Source</h5>

            </a>

            <a class="d-flex flex-column align-items-center socialAccLink" [href]="appSource" target="_blank"
              *ngIf="teams.isTeamsApp() == true">
              <img class="mb-2 myWidth" src="assets/4.png" alt="Azure" width="50px">
              <h5 class="moreSmaller">Microsoft App Source</h5>

            </a>

          </div>

          <div class="d-flex justify-content-center mt-2" *ngIf="teams.isTeamsApp() == false">
            <button class="signUpButton" type="button" (click)="logout()">
              Log Out
            </button>
          </div>
          <br>
          <p>For queries or support, contact VenueArc support team at contact@venuearc.com or visit <a href="https://venuearc.com/contact/" target="_blank">www.venuearc.com/contact-us</a>  </p>


        </div>
      </div>

    </div>

  </div>
</div>