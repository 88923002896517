<!-- <div class="header justify-content-between align-items-center mb-0 d-flex"  [class]="theme.heading">
    <h4 class="h4-responsive ml-3" style="color: White;margin-bottom: 0 !important;">
      Event Form Tour
    </h4>
    <div class="btn-group" role="group" aria-label="Basic example">
      <button mat-dialog-close mat-icon-button aria-label="Example icon button with a heart icon" style="color:white;">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div> -->
<!--  
  <mdb-carousel class="carousel slide carousel-fade "[animation]="'fade'">
    <mdb-carousel-item>
      <div class="view w-100">
        <img class="d-block w-100" src="../../../../assets/ProductTour/EventPage/Desktop - 1.png" alt="First slide">
        <div class="mask rgba-black-slight waves-light" mdbWavesEffect></div>
      </div>
      <div class="carousel-caption">
        <h3 class="h3-responsive">Add a New Event</h3>
        
      </div>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <div class="view w-100">
        <img class="d-block w-100" src="../../../../assets/ProductTour/EventPage/Desktop - 2.png" alt="Second slide">
        <div class="mask rgba-black-slight waves-light" mdbWavesEffect></div>
      </div>
      <div class="carousel-caption">
        <h3 class="h3-responsive">Event Name</h3>
        
      </div>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <div class="view w-100">
        <img class="d-block w-100" src="../../../../assets/ProductTour/EventPage/Desktop - 3.png" alt="Third slide">
        <div class="mask rgba-black-slight waves-light" mdbWavesEffect></div>
      </div>
      <div class="carousel-caption">
        <h3 class="h3-responsive">Set Event Dates</h3>
        
      </div>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <div class="view w-100">
        <img class="d-block w-100" src="../../../../assets/ProductTour/EventPage/Desktop - 4.png" alt="Third slide">
        <div class="mask rgba-black-slight waves-light" mdbWavesEffect></div>
      </div>
      <div class="carousel-caption">
        <h3 class="h3-responsive">Set Performances</h3>
        
      </div>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <div class="view w-100">
        <img class="d-block w-100" src="../../../../assets/ProductTour/EventPage/Desktop - 5.png" alt="Third slide">
        <div class="mask rgba-black-slight waves-light" mdbWavesEffect></div>
      </div>
      <div class="carousel-caption">
        <h3 class="h3-responsive">Event Days</h3>
        
      </div>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <div class="view w-100">
        <img class="d-block w-100" src="../../../../assets/ProductTour/EventPage/Desktop - 6.png" alt="Third slide">
        <div class="mask rgba-black-slight waves-light" mdbWavesEffect></div>
      </div>
      <div class="carousel-caption">
        <h3 class="h3-responsive">Submit</h3>
        
      </div>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <div class="view w-100">
        <img class="d-block w-100" src="../../../../assets/ProductTour/EventPage/Desktop - 7.png" alt="Third slide">
        <div class="mask rgba-black-slight waves-light" mdbWavesEffect></div>
      </div>
      <div class="carousel-caption">
        <h3 class="h3-responsive">Harry Porter</h3>
        
      </div>
    </mdb-carousel-item>
  </mdb-carousel> -->

<div class="modal-close">
  <button type="button" mat-dialog-close style="font-weight: 800;color: #fff;" mat-icon-button>
    <mat-icon>close</mat-icon></button>
</div>

<div class="container mt-4">
  <div class="row">
    <div class="col-md-12" style="padding-left: 20px;">
      <h1 class="prd-tour-head">Product Tour</h1>
      <h4 style="color: #979494;">How does it work?</h4>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <ul class="sideNav" >
        <li class="sideNav-link" *ngFor="let item of sideNav" [ngClass]="{'active-link':item.Active == true}">
          <a (click)="setActive(item)">
            <span>
              <mat-icon class="link-icon">{{item.Icon}}</mat-icon>
            </span>
            <span class="link-texts">
              <span class="link-text">{{item.DisplayName}}</span>
              <span class="link-active-text" *ngIf="item.Active == true">{{item.ActiveText}}</span>
            </span>
          </a>
        </li>
      </ul>
    </div>
    <div class="col-md-6">
      <div class="container slider">
        <div [@slideAnimation]="currentIndex">
          <ng-template ngFor [ngForOf]="activeSlides" let-slide let-i="index">
            <img class="slide slide-animation nonDraggableImage" 
                 *ngIf="isCurrentSlideIndex(i)"
                 [src]="slide.ImgPath">

            <p *ngIf="isCurrentSlideIndex(i)" class="mt-3" style="font-size: 13px;color: #988d8d;text-align: center;line-height: 1.2;">
              {{slide.ImgDesc}}
            </p>        
          </ng-template>
        </div>
        
      </div>
      <div class="text-center mt-4">
        <button class="" (click)="prevSlide(activeSlides)" mat-icon-button> <mat-icon style=" font-variation-settings:'FILL' 0,'wght' 700,'GRAD' 0,'opsz' 24;background: #D9D9D9;height: 30px;line-height: 30px;border-radius: 50%;width: 30px;"> chevron_left </mat-icon> </button>
        <button class="" (click)="nextSlide(activeSlides)" mat-icon-button> <mat-icon style=" font-variation-settings:'FILL' 0,'wght' 700,'GRAD' 0,'opsz' 24;background: #D9D9D9;height: 30px;line-height: 30px;border-radius: 50%;width: 30px;"> chevron_right </mat-icon> </button>
      </div>
    
    </div>
  </div>

</div>