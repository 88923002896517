import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { app, authentication } from '@microsoft/teams-js';
import { error } from 'console';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { JwtHelper } from 'src/app/helpers/jwt-helper';
import { Customer } from 'src/app/models/customer.model';
import { TokenUser } from 'src/app/models/tokenuser.model';
import { CustomersService } from 'src/app/services/customers.service';
import { SignInUserService } from 'src/app/services/sign-in-user.service';
import { TokenService } from 'src/app/services/token.service';


@Component({
  selector: 'app-teams-signin',
  templateUrl: './teams-signin.component.html',
  styleUrls: ['./teams-signin.component.scss']
})
export class TeamsSigninComponent implements OnInit {

  token:string = "";

  constructor(
    private jwtHelper: JwtHelper,    
    private authService: AuthenticationService,
    public customersService:CustomersService,
    private router: Router,
    private tokenService: TokenService,  
    private signInUserService : SignInUserService 
  ) { }

  ngOnInit(): void {
    this.loginTeams();
  }

  loginTeams(){
    app.initialize();
    authentication.getAuthToken().then(token =>{
      if (token) {
        token.replace('"', '');
        this.token = token;
        
        var decoded_token = this.jwtHelper.decodeToken(token);
        //this.token = decoded_token;

        

        //this.signInProcess_DifferentTenant_2(decoded_token, token);
        this.signInUserService.processSignin(decoded_token, token);
        localStorage.setItem("teams", "123");
      }
      
      
    }, error =>{
      console.log(error);
    });
  }

  async signInProcess_DifferentTenant_2(decoded: any, token:any) {
    
    if (decoded) {
      var ownerId = null;
    
      {    
        var objUser: TokenUser = new TokenUser();    
        objUser.Id = decoded.sub;    
        objUser.token = token;
        objUser.tid = decoded.tid;    
        if (decoded.preferred_username)
          objUser.email = decoded.preferred_username;
        else
          objUser.email = decoded.email;

        if (decoded.name)
          objUser.Name = decoded.name;
        else 
          objUser.Name = objUser.email;
          
        objUser.sessionTimeout = this.tokenService.sessionTimeout();
        

        //create customer object and save in sql database
        var userNameSplit: string[] = objUser.Name.split(' ');
        var objCustomer = new Customer();
        if(userNameSplit.length > 0)
          objCustomer.FirstName = userNameSplit[0];
          
        if(userNameSplit.length > 1)
          objCustomer.LastName = userNameSplit[1];

        objCustomer.OrganisationName = "";
        objCustomer.Email = objUser.email;
        objCustomer.tid = objUser.tid;
        console.log('Customer object while signin', objCustomer);
        //debugger;

        (await this.signInUserService.getTenants(objCustomer.tid, objCustomer.Email)).toPromise().then(async res =>{
          
          await this.customersService.post(objCustomer, '123').toPromise().then(async res => {
            console.log('customer after signup', res);          
            objUser.OwnerId = String(res['ID']);       
            this.authService.login(objUser);   
            localStorage.removeItem("fragment");
            debugger;
            //this.router.navigateByUrl(this.redirecturl);
            //this.router.navigate([this.redirecturl]);
            this.router.navigate(['calendar']);
            
            console.log("Customer Successfully Saved!");
          }, (error: any) => {
  
            console.log(error);          
            alert('Signup not succeeded. Please send an email to contact@venuearc.com.');
          })
        })


       

      }
    }
  }

}


