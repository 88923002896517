<!-- <div class="myForm  mt-1"> -->
<div class="">
    <!-- <form class=" p-5 mb-5 rounded mt-4  border-form" > -->
    <form class="" >
        <!-- <div class="d-flex justify-content-center">
            <div class="logo">
                <img src="assets/venuearc_logo.png" alt="Logo" style="width: 300px;">
            </div>
        </div> -->

        <div class="row" [hidden]="!IsSaved">

                <div class="col-md-12 pt-1 pb-3 text-center">
                    <h2 style="color:#0e83a8;">Congratulations!</h2>
                    <br>
                    <p>You are successfully registered.</p>
                    <p>Please check your email: {{obj.Email}} to continue. </p>
                </div>
        </div>


        <!-- <div class="row" [hidden]="IsSaved">

            <div class="col-md-12 pt-5 pb-3 text-center">
                <h2 style="color:#0e83a8;">Sign Up</h2>
                <p>to create your account</p>
            </div>
        </div> -->


        <div class="row" [hidden]="IsSaved">

            <div class="col-sm-6 col-md-6 col-lg-6 col-xs-12">
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>First Name</mat-label>
                    <mat-icon matPrefix class="mr-2 icon-Color" >person</mat-icon>
                    <input matInput class="MatInputBoxShadow" required name="FirstName" #FirstName="ngModel" [(ngModel)]="obj.FirstName"
                        type="text" autocomplete="off">
                    <mat-error *ngIf="FirstName.invalid || FirstName.dirty">This field is required</mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 col-xs-12">
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Last Name</mat-label>
                    <input matInput class="MatInputBoxShadow" required name="LastName" #LastName="ngModel" [(ngModel)]="obj.LastName"
                        type="text" autocomplete="off">
                    <mat-error *ngIf="LastName.invalid || LastName.dirty">This field is required</mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Organization/Company Name</mat-label>
                    <mat-icon matPrefix class="mr-2 icon-Color" >admin_panel_settings</mat-icon>
                    <input matInput class="MatInputBoxShadow" required name="OrganisationName" #OrganisationName="ngModel" [(ngModel)]="obj.OrganisationName"
                        type="text" autocomplete="off">
                    <mat-error *ngIf="OrganisationName.invalid || OrganisationName.dirty">This field is required</mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Email</mat-label>
                    <mat-icon matPrefix class="mr-2 icon-Color" >email</mat-icon>
                    <input matInput class="MatInputBoxShadow" required name="Email" #Email="ngModel" [(ngModel)]="obj.Email"
                        type="email" autocomplete="off"
                        pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                        <mat-error *ngIf="Email.errors && Email.errors.required">This field is required</mat-error>
                        <mat-error *ngIf="Email.errors  && Email.errors.pattern">Email is invalid</mat-error>
                </mat-form-field>
            </div>
             <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                <!-- <mat-form-field class="example-full-width"  appearance="fill">
                    <mat-label>Country</mat-label> -->

                    <!-- <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Country</mat-label>
                        <mat-icon matPrefix class="mr-2 icon-Color" >location_on</mat-icon>
                        <mat-select name="CountryName" required [(ngModel)]="obj.CountryName" (selectionChange)="getMaskbyCountryName(obj.CountryName)"
                          #CountryName="ngModel"  >
                          <mat-option>
                            <ngx-mat-select-search name="country" #Location="ngModel" [(ngModel)]="searchUserLead"
                              (ngModelChange)="AutoCompCntry.searchResult.next($event)" placeholderLabel="Search..."
                              noEntriesFoundLabel="Not Found!" [ngModelOptions]="{ standalone: true }">
                            </ngx-mat-select-search>
                          </mat-option>
                          <mat-option *ngFor="let option of AutoCompCntry.results | async" [value]="option.name"> {{
                            option.name}} {{ option.code}}</mat-option>

                        </mat-select>

                        <mat-error *ngIf="CountryName.invalid">This field is required</mat-error>
                      </mat-form-field> -->
                    <!-- <mat-icon matPrefix class="mr-2 icon-Color" >location_on</mat-icon>
                    <input type="text" matInput class="MatInputBoxShadow"  aria-label="Number" [matAutocomplete]="auto"
                        [(ngModel)]="obj.CountryName" #CountryName="ngModel" name="CountryName"
                        (ngModelChange)="AutoCompCntry.searchResult.next($event)" >

                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="AutoCompCntry.onDisplayValue" aria-label="Number"  (optionSelected)="getMaskbyCountryName(obj.CountryName)">
                        <mat-option *ngFor="let option of AutoCompCntry.results | async" [value]="option.name">
                            {{ option.name }} {{option.code}}

                        </mat-option>

                    </mat-autocomplete>
                  </mat-form-field> -->
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                <!-- <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Phone</mat-label>
                     <mat-icon matPrefix *ngIf="selectedCntryImg ==null || selectedCntryImg == ''" class="mr-2 icon-Color" >phone</mat-icon>
                     <span matPrefix class="mr-1"  [hidden]="selectedCntryImg ==null || selectedCntryImg == ''">
                        <img [src]="selectedCntryImg" alt="" height="30px" width="27px">
                     </span>
                    <input matInput class="MatInputBoxShadow" [mask]="selectedMask"
                    [showMaskTyped]="true" name="Phone" #Phone="ngModel" [(ngModel)]="obj.Phone"
                        type="text" autocomplete="off" >
                </mat-form-field> -->
            </div>




            <!-- <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12 mt-2">
                <div class="d-flex justify-content-start align-items-end">
                    <mat-checkbox
                        class="example-margin"
                        [(ngModel)]="checkedTermsConditions"
                        name="checkedTerms"
                        #checkedTerms="ngModel">
                        I accept the
                    </mat-checkbox>
                    <button class="ml-2" type="button"  mat-stroked-button (click)="openTermsAndConditions()">Terms and conditions</button>
                </div>
            </div> -->

            <!-- <div class="col-sm-12 mt-3" *ngIf="IsshowProgress">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    <br>
                    <p> <i> Please wait. This will take few seconds. </i></p>
            </div> -->

        </div>

        <div class="row" [hidden]="IsSaved">
            <div class="col-md-12 mt-4">

                <!-- <button [disabled]="validate() || checkedTermsConditions == false" mdbBtn color="info" class="waves-light w-100" mdbWavesEffect
                    type="button" (click)="Save()" rounded="true">
                    <mdb-icon fas icon="check"></mdb-icon>
                     <span class="ml-2"> Done </span>
                </button> -->

                <button [disabled]="validate()" mdbBtn color="info" class="waves-light w-100" mdbWavesEffect
                type="button" (click)="openTermsAndConditions()" rounded="true">
                <span class="mr-2"> Next </span>
                <mdb-icon fas icon="arrow-right"></mdb-icon>
            </button>

            </div>
        </div>



        <!-- <div class="links " >
                <a mat-stroked-button class="w-100" (click)="login()">Sign In</a>
        </div>
        -->


    </form>
</div>
