
<!-- 
<div class="frm p-5">
  <div class="myForm  showShadow">
    <form class=" p-4 mb-5 rounded   border-form">
      <div class="d-flex justify-content-center">
        <div class="logo">
          <img src="assets/venuearc_logo.png" alt="Logo" style="width: 220px;">
        </div>
      </div>

      <div class="row">

        <div class="col-md-12 pt-3 pb-3 text-center">
          <h3 style="color:#0e83a8;">Sign Up</h3>
          <p>to create your account</p>
        </div>
      </div>

      <div class="row">


        <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
          <app-select-signup *ngIf="loginWith == ''"></app-select-signup>
          <app-register-customer *ngIf="loginWith == 'venuearc' " (onSave)="onSuccess($event)"></app-register-customer>
          <div *ngIf="isSaved == false">
            <p class="text-center pt-4"> Already have an account? <a [href]="loginUrl">Log In</a> </p>
            <p class="text-center"> OR </p>
            <button mdbBtn color="success" class="waves-light w-100" mdbWavesEffect
              (click)="navigateMarketPlaceSignUp()" type="button">
              <mdb-icon fab icon="microsoft"></mdb-icon>
              <span class="ml-2"> Sign Up with Azure Marketplace </span>
            </button>
            <p class="text-center" style="margin-bottom: 1rem !important; margin-top: 1rem !important;"> OR </p>
            <button mdbBtn color="success" class="waves-light w-100" mdbWavesEffect (click)="subscribeAS()"
              type="button">
              <mdb-icon fab icon="microsoft"></mdb-icon>
              <span class="ml-2"> Sign Up with Microsoft App Source </span>
            </button>
          </div>

        </div>


      </div>

    </form>
  </div>
</div> -->
<app-signup-new (onSave)="onSuccess($event)"> 

</app-signup-new>
