import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http'
import * as customConfig from 'src/app/customConfig.json';
import { AuthenticationService } from '../helpers/authentication.service';
import { LoginUser } from '../Base/User/login-user';
import { ConfHttpHeadersService } from './conf-http-headers.service';

@Injectable({
  providedIn: 'root'
})
export class EventDocumentService {
  public rootURL = customConfig.userb2curl; 
  
  constructor(
    public http:HttpClient,
    private authenticationService :AuthenticationService,
    private hdr:ConfHttpHeadersService
  ) { 

  }

  post(formData:FormData, folderName:string){
    if(!this.authenticationService.CurrentUser())
    return ;

    var ownerid = this.authenticationService.CurrentUser().OwnerId
    formData.append('OwnerId', ownerid);
    formData.append('customerid', LoginUser.customer.CustomerObjectId.toString());    
    formData.append('ModifiedBy', LoginUser.loggedinUser.UserName);
    formData.append('FolderName', folderName);

   
   return this.http.post(this.rootURL + 'EventDocument/Post', formData, this.getHeaders())
  }

  delete(blobName:string){
    if(!this.authenticationService.CurrentUser())
    return ;

    let formData = new FormData();
    var ownerid = this.authenticationService.CurrentUser().OwnerId
    formData.append('OwnerId', ownerid);    
    formData.append('customerid', LoginUser.customer.CustomerObjectId.toString());    
    formData.append('BlobName', blobName);
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const httpOptions = {
      headers: headers
    };
   return this.http.post(this.rootURL + 'EventDocument/DeleteBlob', formData, this.getHeaders())
  }

  getAll(folderName:string){
    if(!this.authenticationService.CurrentUser())
    return ;

    //var query :string =  "?OwnerId=" + this.authenticationService.CurrentUser().OwnerId + "&FolderName=" +folderName;

    var obj = { 
      customerid: LoginUser.customer.CustomerObjectId.toString(),
      foldername: folderName
    }
   
    return this.http.post(this.rootURL + 'EventDocument/GetAllFiles', obj, this.getHeaders())
   }

   DownloadFile(){
    return this.http.get(this.rootURL + 'EventDocument/GetFile')
   }

   getHeaders(){
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    //headers.append('CustomerObjectID', LoginUser.customer.CustomerObjectId.toString());
    const httpOptions = {
      headers: headers
    };

    return httpOptions;
   }
   

}

