<div class="row py-4">
    <div class="col-lg-12 col-md-12">

        <mdb-card cascade="true" narrower="true">
            <div class="view view-cascade gradient-card-header narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center"
                [class]="theme.heading">
                <h4 class="h4-responsive">Contract Setup</h4>
                <div *ngIf="isBack">
                    <div class="btn-group" role="group" aria-label="Basic example" >
                      <a (click)="onBack()"  mdbBtn [class]="theme.button" size="sm" mdbWavesEffect>
                        <mdb-icon fas icon="arrow-left" class="mr-1"></mdb-icon> Go back
                      </a>
                    </div>
                  </div>
            </div>
            <div class="container-fluid">

                <mat-vertical-stepper [linear]="true" #stepper>

                    <mat-step [stepControl]="isContractFields">

                        <ng-template matStepLabel>Add contract fields</ng-template>

                        <app-event-contract-fields (isContractField)="getIsContractField($event)"
                            (contractFields)="setContractFields($event)" [VenueID]="venueID"></app-event-contract-fields>

                        <div class="mt-3">
                            <span class=" d-flex align-items-center justify-content-end">
                                <button type="button" [disabled]="isContractFields.invalid" mat-button matStepperNext
                                    class="step-next">Next</button>
                                <!-- <span style="    font-size: 11px;color: #7f7f7f;font-style: italic;margin-left: 3px;" *ngIf="isContractFields.invalid">(First add contract fields)</span> -->
                            </span>
                        </div>

                    </mat-step>

                    <mat-step>

                        <ng-template matStepLabel>Setup contract fields in templates</ng-template>

                        <div class="card my-2">
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="card-body">
                                        <ul class="list">
                                            <li>Open your contract word document file.</li>
                                            <li>Locate the values that you wish to replace with the values from your
                                                contract fields.
                                            </li>
                                            <li>Replace the identified values in the curly braces {{'{...}'}} (as seen
                                                in <span style="color: orange;">Orange color</span>), with the contract
                                                fields
                                                that you have defined in the first step, as demonstrated in the
                                                accompanying
                                                screenshot(1.1).</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-md-4" style="border-left: 1px solid #ddd;">
                                    <img src="assets/EventContract/Contracthead.png" alt=""
                                        style="height: 33px;width: inherit;">
                                    <div class="card p-3 mb-3 img-card scrollwrap scrollwrap_delayed">
                                        <div class="" *ngFor="let item of contractFields" style="font-size: 14px;">
                                            The value of <span class="font-weight-bold">{{item.actualname}}</span> is
                                            <span class="font-weight-bold" style="color: orange;">{{item.name}}</span>
                                        </div>
                                      
                                    </div>
                                    <div class="ss1 pr-3">
                                        Screenshot 1.1
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="mt-3">
                            <span class=" d-flex align-items-center justify-content-between">
                                <button type="button" mat-button matStepperPrevious
                                    class="step-next ml-3">Previous</button>
                                <button type="button" mat-button matStepperNext class="step-next">Next</button>
                                
                            </span>
                        </div>

                    </mat-step>

                    <mat-step [stepControl]="isContractTemplate">
                        <ng-template matStepLabel>Upload contract template</ng-template>

                        <app-event-contract-upload-template
                            (isContractTemplate)="getIsContractTemplate($event)"></app-event-contract-upload-template>

                        <div class="mt-3">
                            <span class=" d-flex align-items-center justify-content-between">
                                
                                <button type="button" mat-button matStepperPrevious
                                    class="step-next ml-3">Previous</button>
                                    <span>
                                        <button type="button" [disabled]="isContractTemplate.invalid" mat-button
                                            matStepperNext class="step-next">Next</button>
                                    </span>
                            </span>
                        </div>
                    </mat-step>

                    <mat-step>

                        <ng-template matStepLabel>Done</ng-template>

                        <p>Congratulations! You have successfully completed all the steps.
                            <!-- Now, you are ready to generate your contract. 
                                <a style="color: #007bff;text-decoration: underline;"(click)="onCompleteSteps()">Generate Now</a> -->
                        </p>

                        <div class="mt-3">
                            <span class=" d-flex align-items-center justify-content-between">
                                <button type="button" mat-button matStepperPrevious
                                class="step-next ml-3">Previous</button>
                                <button type="button" mat-button class="step-next"
                                    (click)="onCompleteSteps()">Done</button>
                              
                            </span>
                        </div>

                    </mat-step>


                </mat-vertical-stepper>
            </div>
        </mdb-card>
    </div>
</div>