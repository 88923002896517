import { Component, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as customConfig from 'src/app/customConfig.json';

@Component({
  selector: 'app-select-signup',
  templateUrl: './select-signup.component.html',
  styleUrls: ['./select-signup.component.scss']
})
export class SelectSignupComponent implements OnInit {
  
  

  constructor(
    private router: Router,    
  ) { }

  ngOnInit(): void {
  }

  navigateCustomSignUp(){

    var urlredirect:string = customConfig.callbackPathBase + "/signup?source=venuearc";
    window.location.href = urlredirect;
    //this.router.navigate(["/signup"], {queryParams:{source:'venuearc'}});
    //this.router.onSameUrlNavigation = 'reload';
  }

  navigateMarketPlaceSignUp(){
    //window.location.href = "https://portal.azure.com/#create/alrafayconsultingllc1646338685878.venuearc_test";
    //window.location.href = "https://portal.azure.com/#view/Microsoft_Azure_Marketplace/GalleryItemDetailsBladeNopdl/id/alrafayconsultingllc1646338685878.venuearc_mod3";
    window.location.href = customConfig.marketplacelink;
  }

}
