
export class Customer {
    ID:number = 0;
    FirstName :string = null;
    LastName :string = null;
    FullName :string = null;
    Email :string = null;
    Phone :string = null;
    Address1:string = null;
    Address2:string = null;
    City:string = null;
    State:string = null;
    CountryId:number = null;
    ZipCode:string = null;
    IsDeleted:boolean = null;
    CreatedBy :number = null;
    CreatedDate:Date = null;
    ModifiedBy :number = null;
    ModifiedDate :Date = null;
    OrganisationName: string = null;
    OnBoardingStatus: string = null;
    AzObjectId: string = null;
    SubscriptionDate: Date = null;
    ExpiryDate: Date = null;
    tid: string = null;
    SubscriptionType: string = null;
    MarketPlaceSubscriptionId: string = null;
    saasSubscriptionStatus : string = null;
    CountryCode:string = null;
    CountryName:string = null;
    NoOfEmployees:number = null;
    Website:string=null;
    Theme:string=null;
    isFreeTrial:boolean = null;
    startDate:any=null;
    endDate:any=null;
    IsSampleData:boolean = null;
    
}

