import { Injectable } from '@angular/core';
import { HelpService } from './help.service';

@Injectable({
  providedIn: 'root'
})
export class RbacGroupService {

  constructor(private helpService:HelpService) { }


  async getGroups(){
    
   return await this.helpService.GetDataByHelpNo("hlprbacgrouplist");
  }

}
