<div>
    <div class="content-container dialogHeader" >        
        <a mdbBtn floating="true" size="sm" [class]="theme.button" class="float-right" (click)="closeDialoge()" mdbWavesEffect>
                <mdb-icon fas icon="times"></mdb-icon>
        </a>
    </div>
    <br>
    <br>
    <br>
    <div class="container">
        <div class="row">
            <div class="col">
                    <span class="content-span full-width"><mdb-icon fas icon="exclamation-triangle" class="mr-2"></mdb-icon>{{data.message}}</span>
            </div>
        </div>
    </div>
    <br>
    <br>
    <div class="btn-group dialogBtn" role="group" aria-label="Button group with nested dropdown">
            <button mdbBtn type="button" [class]="theme.button" class="waves-light" mdbWavesEffect [mat-dialog-close]="false">
                    <mdb-icon fas icon="times-circle" class="mr-1"></mdb-icon>
                    No
            </button>
            <button mdbBtn type="button" [class]="theme.button" class="waves-light" mdbWavesEffect [mat-dialog-close]="true">
                    <mdb-icon fas icon="check" class="mr-1"></mdb-icon>
                    Yes
            </button>                   
    </div>
</div>
