<div
  class="modal-header  darken-3 white-text  gradient-card-header narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center" [class]="theme.heading">

  <h4 class="h4-responsive">
    {{formTitle}}
  </h4>

  <div class="btn-group" role="group" aria-label="Basic example">

    <a (click)="modalRef.hide()">

      <button mdbBtn type="button" [class]="theme.button" size="sm" class="waves-light" mdbWavesEffect>
        <mdb-icon fas icon="times" ></mdb-icon>
      </button>

    </a>

  </div>

</div>

<br>

<div class="modal-body mb-0 text-left" id="modalBodyArea">

  <div class="row">

    <div class="col-lg-1 col-md-2 col-sm-3 col-xs-4">
      <h6>Venue: </h6>
    </div>
    <div class="col-lg-11 col-md-10 col-sm-9 col-xs-8">
      <h6>
        <mat-list>
          <mat-list-item [hidden]="venueID == null" *ngFor="let option of Venues | filter : 'ID' : venueID ">
            {{option.VenueName}}
          </mat-list-item>
        </mat-list>
      </h6>
    </div>

  </div>

  <div class="row">

    <div class="col-lg-1 col-md-2 col-sm-3 col-xs-4">
      <h6>Year: </h6>
    </div>
    <div class="col-lg-11 col-md-10 col-sm-9 col-xs-8">
      <h6>{{monthDate | date:'yyyy'}}</h6>
    </div>

  </div>

  <div class="row">

    <div class="col-lg-1 col-md-2 col-sm-3 col-xs-4">
      <h6>Month: </h6>
    </div>
    <div class="col-lg-11 col-md-10 col-sm-9 col-xs-8">
      <h6>{{monthDate | date:'MMMM'}}</h6>
    </div>

  </div>
  <hr>
  <form #Notes="ngForm" autocomplete="off" (ngSubmit)="onSubmit(Notes)">
    <div class="row">
      <div class="col-sm-4" [hidden]="true">
        <div class="md-form">
          <input name="ID" #ID="ngModel" [(ngModel)]="formData.ID" type="text" id="materialLoginFormbankID"
            class="form-control" mdbInput>
          <label for="materialLoginFormbankID">ID</label>
        </div>
      </div>

      <div *ngIf="permissionService.CheckIfUserhasAccess_Action('Monthly Notes','Add')" class="col-sm-12">
        <div class="md-form">
          <textarea required type="text" id="form7" class="md-textarea md-textarea-auto form-control" mdbInput
            name="Notes" #Notes="ngModel" [(ngModel)]="formData.Notes">
                                  </textarea>
          <label for="NotesText" [class]="theme.label">Notes</label>
          <div class="validation-error" *ngIf="Notes.invalid && Notes.touched"> This field is required
          </div>
        </div>
      </div>
    </div>
    <div class="text-right">
      <button *ngIf="permissionService.CheckIfUserhasAccess_Action('Monthly Notes','Add')" type="button" (click)="onSubmit(Notes)"
        [disabled]="Notes.invalid" [hidden]="isView" mdbBtn [class]="theme.button" class="waves-light" mdbWavesEffect>
        <mdb-icon fas icon="save" class="mr-1"></mdb-icon>{{SavebuttonText}}
      </button>
    </div>
  </form>


  <br>
  <!-- <div class="row">
    <div class="col-md-12">
      <div>
        <table mdbTable class="z-depth-1 InputTable table">
          <thead>
            <tr>
              <th>Notes</th>
             
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let es of venueMonthNotesList">
              <td >
                  <div class="NotesAuditInfo">
                      Created on {{es.CreatedDate | date:'MM/d/yyyy h:mm a'}} by {{es.RBAC_Users1 == null ? '' : es.RBAC_Users1.FirstName + ' ' + es.RBAC_Users1.LastName}}
                   </div>
                   <div style="white-space: pre-wrap;" [innerHTML]="es.Notes">
   
                   </div>
              </td>
              
              <td>
               
                <div class="btn-group float-right">
                    <button mat-button  matTooltip="Edit" *ngIf="permissionService.CheckIfUserhasAccess_Action('Monthly Notes','Edit')"  
                    (click)="onEdit(es)">
                    <mat-icon class="flat-Icon-Color">edit</mat-icon>                  
                    </button>
                    <button mat-button class="flat-Icon-Color"  matTooltip="Delete" *ngIf="permissionService.CheckIfUserhasAccess_Action('Monthly Notes','Delete')" 
                     (click)="DeleteNote(es.ID)">
                    <mat-icon>delete</mat-icon>                    
                    </button>                        
                </div>
              </td>
            </tr> 
          </tbody>
        </table>
      </div>
    </div>
  </div> -->



</div>