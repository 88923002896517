import { Component, OnInit } from '@angular/core';
import { Event_SubEvent } from '../event-info/Event_SubEvent.model';
import { Subject } from 'rxjs';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { GeneralService } from 'src/app/services/general.service';
import { EventStatus } from 'src/app/models/EventStatus.model';
import { eventType } from 'src/app/models/eventType.model';
import { CommonService } from 'src/app/Base/Common.service';
import { EventStatusService } from 'src/app/setupForms/event-status/event-status.service';


@Component({
  selector: 'app-event-sub-event',
  templateUrl: './event-sub-event.component.html',
  styles: []
})
export class EventSubEventComponent implements OnInit {
  old_event_SubEvent: Event_SubEvent[] = [];
  event_SubEvent: Event_SubEvent[] = [];
  subEventStatus: EventStatus[] = [];
  subEventType: eventType[] = [];

  action: Subject<Event_SubEvent[]> = new Subject<Event_SubEvent[]>();

  constructor(
    public modalRef: MDBModalRef,
    public common: CommonService,
    public genService: GeneralService,
    public eventStatusService: EventStatusService,

  ) {

  }

  ngOnInit(): void {
    this.eventStatusService.getEventStatus().then(
      res => {
        this.subEventStatus = res['value'] as EventStatus[];
      }
      , error => {
        this.common.HideSpinner();
        this.common.ShowToast(error.message, this.common.ToastType.Error);
      }
    )

    this.genService.GetDataByQuery("EventTypes").then(
      res => {
        this.subEventType = res['value'] as eventType[];
      }
      , error => {
        this.common.HideSpinner();
        this.common.ShowToast(error.message, this.common.ToastType.Error);
      }
    )

    this.old_event_SubEvent.forEach(element => {
      var eventSub: Event_SubEvent = new Event_SubEvent();
      eventSub.ID = element.ID;
      eventSub.EventID = element.EventID;
      eventSub.LineID = element.LineID;
      eventSub.EventDaysID = element.EventDaysID;
      eventSub.EventDaysLineID = element.EventDaysLineID;
      eventSub.SubEventTypeID = element.SubEventTypeID;
      eventSub.EventStatusID = element.EventStatusID;
      eventSub.SubEventDate = element.SubEventDate;
      eventSub.TimeIn = element.TimeIn;
      eventSub.TimeOut = element.TimeOut;
      eventSub.ShowStartTime = element.ShowStartTime;
      eventSub.ShowEndTime = element.ShowEndTime;
      eventSub.LicenseFeeTypeID = element.LicenseFeeTypeID;
      eventSub.LicenseFee = element.LicenseFee;
      eventSub.DiscountedLicenseFee = element.DiscountedLicenseFee;
      eventSub.Discount = element.Discount;
      eventSub.IsReschedule = element.IsReschedule;
      eventSub.RescheduleReason = element.RescheduleReason;
      eventSub.OriginalSubEventDate = element.OriginalSubEventDate;
      eventSub.RescheduleSub = element.RescheduleSub;
      eventSub.CreatedBy = element.CreatedBy;
      eventSub.CreatedDate = element.CreatedDate;
      eventSub.ModifiedBy = element.ModifiedBy;
      eventSub.ModifiedDate = element.ModifiedDate;
      this.event_SubEvent.push(eventSub);
    });

  }

  onAdd() {
    this.action.next(this.event_SubEvent);
    this.modalRef.hide();
  }




}
