import { Injectable } from '@angular/core';
import { SPOperationsService } from '../../services/spoperations.service';
import { LoginUser } from './login-user';
import { GeneralService } from 'src/app/services/general.service';
//import { VWRBACGetUserPermissions } from 'src/app/rbac/vw-rbac-get-user-permissions';
import { UserModuleAccess } from '../models/user-module-access';
import { HttpResponse } from '@angular/common/http';
import * as customConfig from 'src/app/customConfig.json';
import { TokenService } from 'src/app/services/token.service';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})

export class UserService {

  public static getModulesAccess: UserModuleAccess[] =[];
  public Maintenance:boolean = customConfig.Maintenance;
  constructor(
    public genService:GeneralService,
    public tokenService:TokenService,
    public router: Router
    ) {
  }

  load(){
    return new Promise((resolve, reject) => {
      //resolve(true);        
      
      Promise.all([this.setUserDetails()]).then(res=>{

       
       this.tokenService.setToken('8600a4e6-d3a5-416d-95c9-3b0ab2705bcb')
       document.getElementById("authError").style.display = "none";
        resolve(true);         
        
       
      })   
      
  })
  }

  public async setUserDetails() {

   
    
  }

}
