<div
  class="modal-header light-blue darken-3 white-text  gradient-card-header blue-gradient narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center">
  <h4 class="h4-responsive">
    Event Days
  </h4>
  <!-- <a (click)="modalRef.hide()" mdbBtn floating="true" mdbTooltip="Close" aria-label="Close" placement="left"   size="sm"
     gradient="aqua"  mdbWavesEffect>
    <mdb-icon fas icon="times"></mdb-icon>
  </a> -->

  <div class="btn-group" role="group" aria-label="Basic example">
    <a (click)="modalRef.hide()">
    <button mdbBtn type="button" color="info" size="sm" class="waves-light" mdbWavesEffect>
      <mdb-icon fas icon="times" class="mr-1"></mdb-icon>Close
    </button>
  </a>

  </div>
</div>

<!--Body-->
<div class="modal-body mb-0 text-center">

  <form #SubEvent="ngForm" autocomplete="off" (ngSubmit)="onAdd()"> 
    <!-- <div class="row">
                <div class="col-sm-4" [hidden]="true">
                    <div class="md-form">
                        <input name="ID" #ID="ngModel" [(ngModel)]="formData.ID" type="text" id="ID"
                            class="form-control" mdbInput>
                        <label for="ID">ID</label>
                    </div>
                </div>               
            </div> -->

    <div class="row">
      <div class="col-sm-12">
        <table mdbTable class="z-depth-1 table table-responsive">
          <thead>
            <tr>
              <th> S # </th>
              <th> Date</th>
              <th> Time In</th>
              <th> Time Out</th>
              <th> In Use</th>
              <!-- <th> Status</th> -->
              <th> Start Time</th>
              <th> End Time</th>
              <th> Discounted Fee</th>
              <th> Standard Fee</th>
            </tr> 
          </thead>
          <tbody>
            <tr *ngFor=" let subEvent of event_SubEvent; let i=index;">
              <td>{{i + 1}}</td>
              <td>{{subEvent.SubEventDate | date:'fullDate'}}</td>
              <td>
                  <mat-form-field appearance="outline" class="matTableFields">
                      <input type="time" id="input"   matInput [ngModelOptions]="{standalone: true}" [(ngModel)]="subEvent.TimeIn" />
                   </mat-form-field>
              </td>
              <td>
                  <mat-form-field appearance="outline" class="matTableFields">
                     <input type="time" id="input"   matInput [ngModelOptions]="{standalone: true}" [(ngModel)]="subEvent.TimeOut" />
                  </mat-form-field>
              </td>
              <td>

                <mat-form-field appearance="outline" class="matTableFields">
                  <mat-select required [ngModelOptions]="{standalone: true}" [(ngModel)]="subEvent.SubEventTypeID">
                    <mat-option *ngFor="let option of subEventType" [value]="option.ID">
                      {{ option.EventTypeName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <!-- <td>
                      <mdb-select-2 class="mdbSelect" placeholder="Select" [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="subEvent.EventStatusID">
                        <mdb-select-option *ngFor="let option of subEventStatus" [value]="option.ID">
                          {{ option.EventStatus }}
                        </mdb-select-option>
                      </mdb-select-2>

                    </td> -->
              <td>

                  <mat-form-field appearance="outline" class="matTableFields">
                      <input type="time" id="input"   matInput [ngModelOptions]="{standalone: true}" [(ngModel)]="subEvent.ShowStartTime" />
                   </mat-form-field>
              </td>
              <td>
                  <mat-form-field appearance="outline" class="matTableFields">
                      <input type="time" id="input"   matInput [ngModelOptions]="{standalone: true}" [(ngModel)]="subEvent.ShowEndTime" />
                   </mat-form-field>              
              </td>
              <td>
                <mat-form-field appearance="outline" class="matTableFields">
                  <input matInput type="number" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="subEvent.DiscountedLicenseFee">
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline" class="matTableFields">
                  <input matInput type="number" [ngModelOptions]="{standalone: true}" [(ngModel)]="subEvent.LicenseFee">
                </mat-form-field>
              </td>
            </tr> 
          </tbody>
        </table>
      </div>
    </div>
    <button [disabled]="SubEvent.invalid" mdbBtn color="default" class="waves-light" mdbWavesEffect>
      <mdb-icon fas icon="plus" class="mr-1"></mdb-icon>Update
    </button>
  </form>


</div>