
export class EventStatus {
    ID: number = 0;
    EventStatus: string = null;
    DESCRPTION: string = null;
    Color: string = null;
    TextColor: string = null;
    ChipColor:string = null;
    ParentID:number = null;
    CreatedBy: number=null;
    CreatedDate: Date=null;
    ModifiedBy: number=null;
    ModifiedDate: Date=null;
    IsSystemGenerated:boolean=null;
    Inactive:boolean=null;
    LevelName:string=null;
    
}