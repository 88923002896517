import { Injectable } from '@angular/core';
import { HelpService } from './help.service';

@Injectable({
  providedIn: 'root'
})
export class ContractTypeService {

  constructor(private helpService:HelpService) { }


  async getAllContractTypeList(){
    var filters = {
      venueid:'0'
    }
   return await this.helpService.GetDataByHelpNo("hlpcontracttypelist",filters);
  }

  async getContractTypeListByVenue(VenueID:any){
    var filters = {
      venueid:VenueID
    }
   return await this.helpService.GetDataByHelpNo("hlpcontracttypelist",filters);
  }
}
