
export class CurrentUser {
   public UserID: string = null;
   public UserName: string = null;
   public Email: string = null;      
   public ShortCode: string = null;      
   public OrganisationName: string = null;      
   public Subscription: string = null;    
   public SubscriptionType  : string = null;    
   public SubscriptionName  : string = null;    
   public IsFreeTrial: boolean = null;    
   public StartDate: any = null;    
   public ExpiryDate: any = null;    
   public TrialRemainingDays: any = null;    
   public OnBoardingStatus:string  = null;
   
}
