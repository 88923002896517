import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/Base/Common.service';
import { LoginUser } from 'src/app/Base/User/login-user';
import { ToastType } from 'src/app/Enum/ToastType';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { GeneralService } from 'src/app/services/general.service';
import { themeService } from 'src/app/services/theme.service';

import { VenueService } from 'src/app/setupForms/venue/venue.service';
import * as customConfig from 'src/app/customConfig.json'
@Component({
  selector: 'app-landing-page-new',
  templateUrl: './landing-page-new.component.html',
  styleUrls: ['./landing-page-new.component.scss']
})
export class LandingPageNewComponent implements OnInit {

  user = LoginUser.loggedinUser;
  isOwner: boolean = false;

  isAdmin:any = LoginUser.IsAdmin;

  constructor(
    public common: CommonService,
    public venueService: VenueService,
    public genService: GeneralService,
    public theme:themeService,
    public router:Router,
    private authenticationService: AuthenticationService
  ) { }

  async ngOnInit() {
    console.log("Landing page load")
    this.getOwner();
    await this.getVenues();
    await this.theme.getCustomerTheme();
    
  }

  navCollapsed = null;
  isSideNavCollapsed = false;
  isClick = false;
  mouseEnter = false;
  screenWidth = 0;

  onToggleSideNav(data: sideNavToggle) {
    this.screenWidth = data.screenWidth;
    this.isSideNavCollapsed = data.collapsed;
    this.isClick = data.isClick;
  }

  getBodyClass() {
    let styleClass = '';
    if(this.isClick == false && this.screenWidth > 768){
      styleClass = 'bdy-focused';
    }
    else if (this.isSideNavCollapsed && this.screenWidth > 768) {
      styleClass = 'bdy-trimmed';
    }
    else if (this.isSideNavCollapsed && this.screenWidth <= 768 && this.screenWidth > 0) {
      styleClass = 'bdy-md-screen';
    }
    return styleClass
  }

  onHambangerClick() {
    if (this.navCollapsed == null) {
      this.navCollapsed = true
    }
    else {
      this.navCollapsed = !this.navCollapsed;
    }
  }

  async getVenues() {
    // this.common.isLoadingVenue = true;
    await this.venueService.getAllVenues().then(
      res => {

        this.common.venueLength = res['value'].length;
        if (res['value'].length == 1) {
          this.common.isSingleVenueExist = true;
          // this.common.isLoadingVenue = false;
        }
        else {
          this.common.isSingleVenueExist = false;
          // this.common.isLoadingVenue = false;
        }

        this.common.HideSpinner();
      }
      , error => {
        this.common.HideSpinner()
        this.common.ShowToast(error.message, ToastType.Error);
      }
    )
  }

  closeAlert() {
    this.theme.hideFreeSolgan = true;

  }
  
  upgrade(){
    if(this.user?.SubscriptionType == 'stripe'){
    // Safwan 05/02/2023
      if(this.user.IsFreeTrial == true){
        this.router.navigate(['/membership/subscribe'],{queryParams:{b:1}});
      }
    // Safwan 05/02/2023

      else if(this.user?.Subscription == 'free'){
        this.router.navigate(['/membership/subscribe'],{queryParams:{b:1}});
      }
    }
    else if(this.user?.SubscriptionType == '' || this.user?.SubscriptionType == null ){
      if(this.user?.Subscription == 'free'){
        this.router.navigate(['/membership/subscribe'],{queryParams:{b:1}});
      }
    }
    else{
      var url = customConfig.appsourcelink;
      window.location.href = url;
    }
  }
  getOwner() {
    this.isOwner = this.authenticationService.CurrentUser().isowner;
    var tid = this.authenticationService.CurrentUser().tid;
    var email = this.authenticationService.CurrentUser().email;

  }
}


interface sideNavToggle {
  screenWidth: number;
  collapsed: boolean;
  isClick:boolean;
}