<!-- <div class="row">
    <div class="col-md-12">
        <div
            class="modal-header darken-3 white-text  gradient-card-header narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center" [class]="theme.heading">
            <h4 class="h4-responsive">
                Upgrade to Premium
            </h4>
            <div class="btn-group" role="group" aria-label="Basic example">
                <a (click)="modalRef.hide()">
                    <button mdbBtn type="button" [class]="theme.button" size="sm" class="waves-light" mdbWavesEffect>
                        <mdb-icon fas icon="times"></mdb-icon>
                    </button>
                </a>
            </div>
        </div>
        <div class="modal-body mb-0 " id="modalBodyArea">
            <div class="row">
                <div class="col-md-12">
                    If you want to access this section. So, you have to upgrade your plan from Basic to Premium by click below given button.  
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-10"></div>
                <div class="col-md-2">
                    <button type="button" mdbBtn [class]="theme.button" class="waves-light" mdbWavesEffect (click)="upgradeToPremium()">
                        Upgrade
                    </button>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="container-fluid">
    <div class="row">
        <div class="col-md-4 leftcontainer" [class]="theme.backGround">
    
        </div>
        <div class="col-md-8 rightcontainer">
            <h5 style="color: gray;" class="mt-3">Update Your Subscription</h5>
            <h3 [class]="theme.label">Premium</h3>
            <p class="my-4">
                <!-- If you want to access this section. So, you have to upgrade your plan from Basic to Premium by click below given button.   -->
                Click below to upgrade your plan from Basic to Premium.
            </p>


            <div class="d-flex">
                    <button type="button" mdbBtn [class]="theme.button" class="waves-light" mdbWavesEffect (click)="upgradeToPremium()">
                        Upgrade
                    </button>

                    <button type="button" mdbBtn  class="waves-light" mdbWavesEffect (click)="onClose()">
                        Close
                    </button>  
            </div>
        </div>
    </div>
</div>