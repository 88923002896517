<div
    class="modal-header light-blue darken-3 white-text  gradient-card-header blue-gradient narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center">
    <h4 class="h4-responsive">
        Add User
    </h4>
    <div class="btn-group" role="group" aria-label="Basic example">
        <a (click)="modalRef.hide()">
            <button mdbBtn type="button" color="info" size="sm" class="waves-light" mdbWavesEffect>
                <mdb-icon fas icon="times"></mdb-icon>
            </button>
        </a>
    </div>
</div>

<div class="modal-body mb-0 " id="modalBodyArea">
    <form #rbac="ngForm" autocomplete="off">
        <div class="row">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                        <mat-form-field class="example-full-width">
                            <mat-label>First Name</mat-label>
                            <input matInput class="MatInputBoxShadow" type="text" id="FirstName" required name="FirstName"
                                #FirstName="ngModel" [(ngModel)]="formDataUser.FirstName">
                            <mat-error *ngIf="FirstName.invalid && FirstName.dirty">This field is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                        <mat-form-field class="example-full-width">
                            <mat-label>Last Name</mat-label>
                            <input matInput class="MatInputBoxShadow" type="text" id="FirstName" required name="FirstName"
                                #LastName="ngModel" [(ngModel)]="formDataUser.LastName">
                            <mat-error *ngIf="LastName.invalid && LastName.dirty">This field is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                        <mat-form-field class="example-full-width">
                            <mat-label>Email</mat-label>
                            <input matInput class="MatInputBoxShadow" type="email" id="Email" autocomplete="off" required
                                name="Email" #Email="ngModel" [(ngModel)]="formDataUser.Email">
                            <mat-error *ngIf="Email.errors && Email.errors.required">This field is required</mat-error>
                            <mat-error *ngIf="Email.errors  && Email.errors.pattern">Email is invalid</mat-error>
                        </mat-form-field>
                    </div>
    
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                        <mdb-checkbox name="Inactive" #Inactive="ngModel" [(ngModel)]="formDataUser.Inactive">
                            Inactive
                        </mdb-checkbox>
                    </div>
    
                    <div class="col-md-3 col-sm-3 col-lg-3 col-xs-3 mt-3">
                        <button mat-raised-button  [disabled]="rbac.invalid" class="w-100" style="height: 34px;color: white;background-color: #2b2b6a !important;" (click)="onNext()">
                            Next
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
    
                <mdb-card class="crd" cascade="true" narrower="true">
                    <div
                        class="view view-cascade gradient-card-header blue-gradient narrower py-2 mx-4  d-flex justify-content-between align-items-center">
                        <h4 class="h4-responsive w-100 text-center">Groups</h4>
                    </div>
                    <mdb-card-body cascade="true" class="bdy">
                        <div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <table mdbTable class=" InputTable">
                                        <thead>
                                            <tr>
                                                <th>Group </th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor=" let es of rbacGroups; let i = index;"
                                                [ngStyle]="{'background-color': es.IsAdmin == true ? '#c4ffd9' : '' }">
                                                <td>
                                                    {{es.GroupName}}
                                                </td>
                                                <td>
                                                    <div>
                                                        <mdb-checkbox [ngModelOptions]="{standalone: true}"
                                                            [(ngModel)]="es.IsSelected" (change)="onGroupClick(es)"
                                                            [disabled]="es.IsAdmin != true && IsAdmin==true">
                                                        </mdb-checkbox>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </mdb-card-body>
                </mdb-card>
            </div>
        </div>
    </form>
</div>