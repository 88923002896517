<div>
    <div class="content-container dialogHeader" >        
        <a mdbBtn floating="true" size="sm" class="float-right"  mdbWavesEffect [mat-dialog-close]="false" [class]="theme.button">
                <mdb-icon fas icon="times"></mdb-icon>
        </a>
    </div>
    <br>
    <br>
    <br>
    <div class="container">
        <div class="row">
            <div class="col">
                    <span class="content-span full-width"><mdb-icon fas icon="exclamation-triangle" class="mr-2"></mdb-icon>{{data.message}}</span>
            </div>              
        </div>
        <br>
        <div class="row">
            <div class="col-md-12">
                <table class="table table-striped w-100">
                    <thead>
                        <tr>
                            <th [class]="theme.label">File Name</th>
                            <th class="text-center">  
                                <!-- <mdb-checkbox [(ngModel)]="selectall" (change)="onSelectAllChange($event)"> 
                                </mdb-checkbox> -->
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let f of fileData">
                            <td>{{f.name}}</td>
                            <td class="text-center">
                                <mdb-checkbox [ngModelOptions]="{standalone: true}" [(ngModel)]="f.IsSelected"> 
                                </mdb-checkbox>                                 
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <br>
    <br>
    <div class="btn-group dialogBtn" role="group" aria-label="Button group with nested dropdown">
            <button mdbBtn type="button" [class]="theme.button" class="waves-light" mdbWavesEffect (click)="closeDialoge()" >
                    <mdb-icon fas icon="times-circle" class="mr-1"></mdb-icon>
                    No
            </button>
            <button mdbBtn type="button" [class]="theme.button" class="waves-light" mdbWavesEffect (click)="replaceAll()">
                    <mdb-icon fas icon="check" class="mr-1"></mdb-icon>
                    Replace Selected Files
            </button>                          
    </div>
</div>
