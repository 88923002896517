import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/Base/Common.service';
import { LoginUser } from 'src/app/Base/User/login-user';
import * as customConfig from 'src/app/customConfig.json'
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { ConfHttpHeadersService } from 'src/app/services/conf-http-headers.service';
@Injectable({
  providedIn: 'root'
})
export class RequestEnterpriseService {

  rootURL:string=customConfig.api;

  constructor(
    public http:HttpClient,
    private authenticationService :AuthenticationService,
    private common:CommonService,
    private hdr:ConfHttpHeadersService,

  ) { }
  sendRequest(obj:any){
    return this.http.post(this.rootURL + "/api/SendEmail/PlanChangeRequest", obj, this.hdr.getHeaders());   
  }


  

  
}
