<!-- <div class="myForm  mt-1 shadow" >
    <form class=" p-5 mb-5 rounded mt-4  border-form">
        <div class="d-flex justify-content-center">
            <div class="logo">
                <!-- <img src="assets/venuearc_logo.png" alt="Logo" style="width: 300px;">
                <!-- Safwan Changes - Start - logo change - 01/25/2023
                <img src="assets/venue_arc_singup.png" alt="Logo" style="width: 300px;">
                <!-- Safwan Changes - End - logo change - 01/25/2023
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 pt-5 pb-3 text-center">
                <h2 style="color:#0e83a8">
                    Access Required!
                </h2>
                <br>
                <h6 *ngIf="!successMessage" style="font-weight: 500;">You need permission to access VenueArc.</h6>
                <h6 *ngIf="successMessage" style="font-weight: 500;" class="d-flex justify-content-center">
                    <mat-icon style="color: green;" class="mr-2 fw-bold">done_all</mat-icon>
                    <span style="color: green;"> {{successMessage}} </span>
                </h6>
            </div>
        </div>

        <div class="row" *ngIf="IsshowProgress">

            <div class="col-md-12">
                <div class="d-flex flex-column justify-content-center">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    <br>
                    <p class="text-center"> <i> Please wait. This will take few seconds. </i></p>
                </div>
            </div>


        </div>



        <div class="row" >

            <div class="col-md-12 mt-2">
                <button [disabled]="IsshowProgress" mdbBtn color="info" class="waves-light w-100" mdbWavesEffect
                    type="button" (click)="requestAccess()" rounded="true">
                    <mdb-icon fas icon="fingerprint"></mdb-icon>
                    <span class="pl-2"> {{successMessage ? 'Request Access Again' : 'Request Access'}} </span>

                </button>

                <button mdbBtn color="light" class="waves-light w-100" mdbWavesEffect
                type="button" (click)="login()" rounded="true">
                <mdb-icon fas icon="redo-alt"></mdb-icon>
                <span class="pl-2"> Try Again </span>

                </button>

                <button mdbBtn color="primary" class="waves-light w-100" mdbWavesEffect
                    type="button" (click)="logout()" rounded="true">
                    <mdb-icon fas icon="sign-out-alt"></mdb-icon>
                    <span class="pl-2"> Log out </span>

                </button>
            </div>

        </div>


    </form>
</div> -->
<!-- Safwan Change -Start - 26/01/2023 -->
<!-- <div class="container-fluid bg-frm">
  <div class="d-flex align-items-center bdy2">
    <div class="box">
      <div class="text-center">
        <img src="assets/venue_arc_singup.png" alt="Logo" class="logo">
      </div>
      <div class="text-center">
        <div class=" pt-5 pb-3 text-center">
          <h2 style="color:#0e83a8">
            Access Required!
          </h2>
          <br>
          <h6 *ngIf="!successMessage" style="font-weight: 500;">You need permission to access VenueArc.</h6>
          <h6 *ngIf="successMessage" style="font-weight: 500;" class="d-flex justify-content-center">
            <mat-icon style="color: green;" class="mr-2 fw-bold">done_all</mat-icon>
            <span style="color: green;"> {{successMessage}} </span>
          </h6>
        </div>
        <div class="row" *ngIf="IsshowProgress">

            <div class="col-md-12">
                <div class="d-flex flex-column justify-content-center">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    <br>
                    <p class="text-center"> <i> Please wait. This will take few seconds. </i></p>
                </div>
            </div>


        </div>
        <div class="">

          <button [disabled]="IsshowProgress" mdbBtn  class="waves-light w-75" mdbWavesEffect type="button" style="color: white;background: #223f81 !important;border-radius: 12px !important;" (click)="requestAccess()">
            <mdb-icon fas icon="fingerprint"></mdb-icon>
            <span class="pl-2"> {{successMessage ? 'Request Access Again' : 'Request Access'}} </span>

          </button>

          <button mdbBtn  class="waves-light w-75" mdbWavesEffect type="button" style="color: white;background:#107ba8 !important;border-radius: 12px !important;" (click)="login()">
            <mdb-icon fas icon="redo-alt"></mdb-icon>
            <span class="pl-2"> Try Again </span>

          </button>

          <button mdbBtn  class="waves-light w-75" mdbWavesEffect type="button" style="color: white;background: #cd2828a1 !important;border-radius: 12px !important;" (click)="logout()" >
            <mdb-icon fas icon="sign-out-alt"></mdb-icon>
            <span class="pl-2"> Log out </span>

          </button>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!-- Safwan Change -End - 26/01/2023 -->

<div class="container-fluid bg-frm">
  <div class="d-flex align-items-center bdy2">
      <div class="box">
          <div class="text-center">
              <img src="assets/venue_arc_singup.png" alt="Logo" class="logo">
          </div>

          <!-- <div class="text-center">
              <mat-icon class="sessionIcon">alarm</mat-icon>
            </div> -->
      
          <div class="text-center">
              <div class="pt-2 pb-3 text-center">
                  <h2 class="sessionText">
                    Access Required!
                  </h2>
                  <!-- <p class="subSessionText">Your free trial has expired. Upgrade to our Premium plan and get more additional features</p> -->
                  <p class="subSessionText">
                    You need permission to access VenueArc.
                  </p>
                  
              </div>

              <!-- <div class="row" *ngIf="IsshowProgress">

                  <div class="col-md-12">
                      <div class="d-flex flex-column justify-content-center">
                          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                          <br>
                          <p class="text-center"> <i> Please wait. This will take few seconds. </i></p>
                      </div>
                  </div>


              </div> -->
              <div class="">
                  <button mdbBtn  class="waves-light w-75" mdbWavesEffect type="button" style="color: white;background: #1c97b2 ;border-radius: 24px !important;" (click)="requestAccess()">
                      <mdb-icon fas icon="fingerprint"></mdb-icon>
                      <span class="pl-2"> {{successMessage ? 'Request Access Again' : 'Request Access'}} </span>
                    </button>
                  <button mdbBtn  class="waves-light w-75" mdbWavesEffect type="button" style="color: white;background:#107ba8 ;border-radius: 24px !important;" (click)="login()">
                      <mdb-icon fas icon="redo-alt"></mdb-icon>
                      <span class="pl-2"> Try Again </span>
                    </button>
                  <button mdbBtn  class="waves-light w-75" mdbWavesEffect type="button" style="color: white;background: #223f81;border-radius: 24px !important;" (click)="logout()">
                      <mdb-icon fas icon="sign-out-alt"></mdb-icon>
                      <span class="pl-2"> Log out </span>
                    </button>
              </div>
          </div>
      </div>
  </div>
</div>