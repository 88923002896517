import { Injectable } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { EnEventStatus } from 'src/app/Enum/Enums';
import { HelpService } from 'src/app/services/help.service';

@Injectable({
  providedIn: 'root'
})
export class EventStatusService {

  constructor(private genService:GeneralService,private helpService:HelpService) { }


  // Get all status Group/Child/None
  // async getAllEventStatus(showAuditInfo:boolean = null) {    
  //   return await this.genService.GetDataByQuery("EventStatus?$filter= 1 eq 1 "+ this.getAuditquery(showAuditInfo) + "&$orderby=EventStatus ASC")    
  // }

  // // Get all active status Group/Child/None
  // async getAllActiveEventStatus(showAuditInfo:boolean = null) {    
  //   return await this.genService.GetDataByQuery("EventStatus?$filter= Inactive ne true  "+ this.getAuditquery(showAuditInfo) + "&$orderby=EventStatus ASC")    
  // }

  // // Get all Inactive status Group/Child/None
  // async getAllInActiveEventStatus(showAuditInfo:boolean = null) {    
  //   return await this.genService.GetDataByQuery("EventStatus?$filter= Inactive eq true  "+ this.getAuditquery(showAuditInfo) + "&$orderby=EventStatus ASC")    
  // }

  async getAllEventStatus() {    
    return await this.helpService.GetDataByHelpNo("hlpeventstatuslist",{inactive:-1})    
  }

  // Get all active status Group/Child/None
  async getAllActiveEventStatus() {    
    return await this.helpService.GetDataByHelpNo("hlpeventstatuslist",{inactive:0});
  }

  // Get all Inactive status Group/Child/None
  async getAllInActiveEventStatus() {    
    return await this.helpService.GetDataByHelpNo("hlpeventstatuslist",{inactive:1});
  }

  async getEventStatus() {    
    return await this.helpService.GetDataByHelpNo("hlpeventstatusactive");
  }

  // Get Active Child/None
 /* async getEventStatus(showAuditInfo:boolean = null) {            
    return await this.genService.GetDataByQuery("EventStatus?$filter=Inactive ne true and (LevelName eq 'None' OR LevelName eq 'Child') " + this.getAuditquery(showAuditInfo) + "&$orderby=EventStatus ASC")
  }*/

  async getInActiveEventStatus() {    
    return await this.helpService.GetDataByHelpNo("hlpeventstatusinactive");  
  }
  // Get Inactive Child/None
  /*async getInActiveEventStatus(showAuditInfo:boolean = null) {    
    return await this.genService.GetDataByQuery("EventStatus?$filter=Inactive eq true and (LevelName eq 'None' OR LevelName eq 'Child') " + this.getAuditquery(showAuditInfo) + "&$orderby=EventStatus ASC" )    
  }*/

  async getAvailableEventStatus() {            
    return await this.helpService.GetDataByHelpNo("hlpeventstatusavailable");
  }
  
  /*async getAvailableEventStatus(showAuditInfo:boolean = null) {            
    return await this.genService.GetDataByQuery("EventStatus?$filter=ID ne "+EnEventStatus.Deleted+" and (LevelName eq 'None' OR LevelName eq 'Child')  and Inactive ne true " + this.getAuditquery(showAuditInfo) + "&$orderby=EventStatus ASC")
  }*/

  async getAvailableGroupEventStatus() {            
    return await this.helpService.GetDataByHelpNo("hlpeventstatusavailablegroup");
  }

  /*async getAvailableGroupEventStatus(showAuditInfo:boolean = null) {            
    return await this.genService.GetDataByQuery("EventStatus?$filter=ID ne "+EnEventStatus.Deleted+" and Inactive ne true and LevelName eq 'Group' " + this.getAuditquery(showAuditInfo) + "&$orderby=EventStatus ASC")
  }*/

  async getAvailableGroupAndNoneEventStatus() {            
    return await this.helpService.GetDataByHelpNo("hlpeventstatusgroupnone");
  }

  /*async getAvailableGroupAndNoneEventStatus(showAuditInfo:boolean = null) {            
    return await this.genService.GetDataByQuery("EventStatus?$filter=ID ne "+EnEventStatus.Deleted+" and Inactive ne true and (LevelName eq 'Group' OR LevelName eq 'None') " + this.getAuditquery(showAuditInfo) + "&$orderby=EventStatus ASC")
  }*/

  private getAuditquery(showAuditInfo):string{
    if (showAuditInfo != null)
        return "&$expand=RBAC_Users1($select=FullName),EventStatu1($select=EventStatus)"

    return "";
  }

}
