import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as customConfig from 'src/app/customConfig.json';
import { LoginUser } from '../Base/User/login-user';
import { ConfHttpHeadersService } from './conf-http-headers.service';

@Injectable({
  providedIn: 'root'
})
export class ConfGlobalService {

  readonly rootURL = customConfig.api; 

  constructor(
    private http: HttpClient,
    private hdr:ConfHttpHeadersService,
    ) { }

    getFilterDataByParameter(sectionName:string){

      var obj = {
        customerid : LoginUser.customer.CustomerObjectId,
        userid : LoginUser.loggedinUser.UserID.toString(),
        sectionname : sectionName
      }

      return this.http.post(this.rootURL + "api/ConfGlobal/GetConfFilters",  obj, this.hdr.getHeaders()) ;
    }


}
