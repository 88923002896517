import { Observable, Subject } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

export class Autocomplete<T>{
  public searchResult = new Subject();
  public results: Observable<T[]>
  public data: T[];
  public selectedValue:any;
  
  constructor(public displayValueName: string, public filterIDName: string) {
    this.searchResult = new Subject();
    this.results = new Observable<T[]>();
  }

  public filter(value: string, fieldName: string): T[] | undefined {
    const filterValue = value.toLowerCase();
    return this.data.filter(option => option[fieldName].toLowerCase().indexOf(filterValue) === 0);
  }

  public onDisplayValue = (ID: number): string | undefined => {
    return ID? this.data.filter(x => x[this.filterIDName] === ID)[0][this.displayValueName] : undefined
  }

  public resultObserve() {
    this.results = this.searchResult.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value[this.displayValueName]
    ),
      map(name => name ? this.filter(name, this.displayValueName) : this.data.slice())
    );
  }

}