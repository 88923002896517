import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/Base/Common.service';
import { ToastType } from 'src/app/Enum/ToastType';
import { Customer } from 'src/app/models/customer.model';
import { Venue } from 'src/app/models/venue.model';
import { CustomersService } from 'src/app/services/customers.service';
import { EventDocumentService } from 'src/app/services/event-document.service';
import * as Countries from '../../../../assets/countries.json';
import * as CountriesCurrency from '../../../../assets/countriesC.json';
import { GeneralService } from 'src/app/services/general.service';
import { GenericService } from 'src/app/services/generic.service';
import { Router } from '@angular/router';
import { LoginUser } from 'src/app/Base/User/login-user';
import { UserModuleAccess } from 'src/app/Base/models/user-module-access';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { UserManagerService } from 'src/app/services/user-manager.service';
import { Autocomplete } from 'src/app/Base/Autocomplete';
import { themeService } from 'src/app/services/theme.service';
import { TokenService } from 'src/app/services/token.service';
import { EmailSenderService } from 'src/app/services/email-sender.service';
import { OnBoardingService } from 'src/app/services/on-boarding.service';
import { Currency } from 'src/app/models/currency.model';
import { DataUrl, NgxImageCompressService, UploadResponse } from 'ngx-image-compress-legacy';
import { PermissionService } from 'src/app/services/permission.service';
import { VenueService } from 'src/app/setupForms/venue/venue.service';
import moment from 'moment';

@Component({
  selector: 'app-new-onboard-des',
  templateUrl: './new-onboard-des.component.html',
  styleUrls: ['./new-onboard-des.component.scss']
})
export class NewOnboardDesComponent implements OnInit {

  overlay1Display = 'block';
  overlay2Display = 'none';
  activeStepper:string = 'step1';
  previewImage: string | undefined;
  searchUserLead = null;
  searchUserLeadCurrency = null;

  formDataOrg: Customer = new Customer();
  formDataVen: Venue = new Venue();

  dataChips:any[] =[
    {id:2, numbers:'2-10',value:10},
    {id:3, numbers:'10-50',value:50},
    {id:4, numbers:'50-200',value:200},
    {id:5, numbers:'200-500',value:500},
    {id:1, numbers:'Just me',value:1},
    {id:6, numbers:"don't know",value:1000}
  ];

  dataCapacityChips:any[] = [
    {id:1, numbers:'0-500',value:"500"},
    {id:2, numbers:'500-2,500',value:"2500"},
    {id:3, numbers:'2,500-10,000',value:"10000"},
    {id:4, numbers:'10,000-50,000',value:"50000"},
    {id:5, numbers:'50,000+',value:"100000"},
  ]

  dataCountry:any[] = [ ]

  colors: any[] = [
    { color: '#40a9e6', value: 'default' },
    { color: '#095e8f', value: 'blue' },
    { color: '#444791', value: 'purple' },
    { color: '#262626', value: 'black' }
  ];

  dataCurrency:any[] = [ ]

  isSelected: boolean = false;
  selectedChip: any = null;
  selectedCapacityChip: any = null;
  selectedCurrencyChip: any = null;
  selectedColorChip: any = null;

  selectedMask = "";
  selectedCntryImg: any = null;
  selectedCntryCode: any = null;
  
  currentUsername:string=null;
  currentUserEmail:string=null;
  capacityGroup:string="Select";
  AutoCompCntry: Autocomplete<any> = new Autocomplete<any>("name", "name");
  AutoCompCurrency:Autocomplete<Currency> = new Autocomplete<Currency>("Country","ID");

  activeTheme:string=null;
  @ViewChild('step1', {static: false}) step1: ElementRef;
  @ViewChild('step2', {static: false}) step2: ElementRef;
  @ViewChild('step3', {static: false}) step3: ElementRef;

  
  constructor(
    public eventDocumentService: EventDocumentService,
    public commonService: CommonService,
    public customersService: CustomersService,
    public cdr: ChangeDetectorRef,
    public genService: GeneralService,
    public service: GenericService,
    public router:Router,
    public authenticationService: AuthenticationService,
    public tokenService: TokenService,
    public userManagerService: UserManagerService,
    private imageCompress: NgxImageCompressService,
    public theme: themeService,    
    private permissionService:PermissionService,
    private venueService:VenueService,
    public emailSender:EmailSenderService
  ) { }

  async  ngOnInit() {
    await this.getCurrUser();
    await this.getCustomer();
    await this.getVenue();
    this.getCountries();
    await this.getCurrencies();
    this.selectColorChip({ color: '#40a9e6', value: 'default' });
  }

  scrollToNext(nextStepperId: string) {
    this.activeStepper = nextStepperId;
    const nextStepper = document.getElementById(nextStepperId);
    const yOffset = -130;
    nextStepper.scrollIntoView({ behavior: 'smooth',block: 'center', inline: 'nearest',offset: { top: yOffset } } as ScrollOptions);
    this.overlay1Display = 'none';
    this.overlay2Display = 'block';
  }

  scrollToPrevious(prevStepperId: string) {
    this.activeStepper = prevStepperId;
    const prevStepper = document.getElementById(prevStepperId);
    const yOffset = -130;
      prevStepper.scrollIntoView({ behavior: 'smooth',block: 'center', inline: 'nearest',offset: { top: yOffset } } as ScrollOptions);
      this.overlay1Display = 'block';
      this.overlay2Display = 'none';
}

selectChip(chip) {
  this.selectedChip = chip.value;
  this.formDataOrg.NoOfEmployees = this.selectedChip;
}
selectCapcityChip(chip) {
  this.selectedCapacityChip = chip.value;
  this.formDataVen.Capacity = Number(this.selectedCapacityChip);
}
selectCurrencyChip(chip) {
  this.selectedCurrencyChip = chip.ID;
  this.formDataVen.CurrencyID = Number(this.selectedCurrencyChip);
}

selectColorChip(colorchip) {
  this.selectedColorChip = colorchip.value;
  this.formDataOrg.Theme = this.selectedColorChip;

  this.setActiveTheme(colorchip.value);
  localStorage.setItem('themecolor', colorchip.value);



}

getMaskbyCountryName(option) {
  
  this.selectedMask = "";
  this.selectedCntryImg = null;
  this.formDataOrg.CountryCode = null;
  var selectedOption = this.dataCountry.filter(a => a.name == option) as any[];
  if (selectedOption.length > 0) {
    var selectedCurrency = this.dataCurrency.filter(a=> a.Country == selectedOption[0].name) as any[];
    if(selectedCurrency.length > 0){
      this.formDataVen.CurrencyID = selectedCurrency[0]?.ID; 
    }
    var replVal = /#/gi;
    var typeOfMask = typeof selectedOption[0].mask;
    if (typeOfMask == "object") {
      this.selectedMask = selectedOption[0].mask[0]?.replace(replVal, "0");
      // this.selectedCntryCode = selectedOption[0].code != null ? selectedOption[0].code : '';
      this.selectedCntryImg = selectedOption[0].flag != null ? selectedOption[0].flag : '';
      this.formDataOrg.CountryCode = selectedOption[0].code != null ? selectedOption[0].code : '';

    }
    else {

      this.selectedMask = selectedOption[0].mask?.replace(replVal, "0");
      // this.selectedCntryCode = selectedOption[0].code != null ? selectedOption[0].code : '';
      this.formDataOrg.CountryCode = selectedOption[0].code != null ? selectedOption[0].code : '';
      this.selectedCntryImg = selectedOption[0].flag != null ? selectedOption[0].flag : '';
    }
  }

  this.cdr.detectChanges();
  this.formDataOrg.Phone = "";
}


  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.Files = [];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.previewImage = e.target.result;
      };
      reader.readAsDataURL(file);

      this.pushFiles(event.target.files);
    }

   
  }

  Files: any[] = [];
  folderName: string = "system/logo";
  action: Subject<any[]> = new Subject<any[]>();
  pushFiles(file: FileList[]) {

    this.Files.push({ 'files': file })
  }

  deleteImage(){
    this.previewImage = null;
    this.Files = [];
  }


  async getCustomer() {
    this.commonService.ShowSpinner();
    await this.customersService.getMyCustomerByID().toPromise().then(res => {

      let cus = res as any;
      cus.startDate = moment(cus.startDate).utcOffset('+05:00').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      cus.endDate = moment(cus.endDate).utcOffset('+05:00').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      cus.CreatedDate = moment(cus.CreatedDate).utcOffset('+05:00').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      cus.ModifiedDate = moment(cus.ModifiedDate).utcOffset('+05:00').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      delete cus.Customers_Stripe;
      delete cus.Customers_MarketPlace;
      delete cus.PricingPlans_Customers;
      delete cus.PricingPlans_Customers_History;
      this.formDataOrg = cus;
      // this.formDataOrg.endDate = null;
      // this.formDataOrg.startDate = null;
      // this.formDataOrg.ExpiryDate = null;
      // this.formDataOrg.CreatedDate = null;
      // this.formDataOrg.ModifiedDate = null;
      // this.formDataOrg.SubscriptionDate = null;
      
     // this.formDataOrg = new Customer();
     /*
      this.formDataOrg.ID = cus.ID;
      this.formDataOrg.FirstName = cus.FirstName;
      this.formDataOrg.LastName = cus.LastName;      
      this.formDataOrg.Email = cus.Email;
      this.formDataOrg.Phone = cus.Phone;
      this.formDataOrg.Address1 = cus.Address1;
      this.formDataOrg.Address2 = cus.Address2;
      this.formDataOrg.City = cus.City;
      this.formDataOrg.State = cus.State;
      this.formDataOrg.CountryId = cus.CountryId;
      this.formDataOrg.ZipCode = cus.ZipCode;
      this.formDataOrg.IsDeleted = cus.IsDeleted;
      this.formDataOrg.CreatedBy = cus.CreatedBy;
      this.formDataOrg.ModifiedBy = cus.ModifiedBy;
      this.formDataOrg.OrganisationName = cus.OrganisationName;
      this.formDataOrg.OnBoardingStatus = cus.OnBoardingStatus;
      this.formDataOrg.AzObjectId = cus.AzObjectId;
      this.formDataOrg.SubscriptionType = cus.SubscriptionType;
      this.formDataOrg.MarketPlaceSubscriptionId = cus.MarketPlaceSubscriptionId;
      this.formDataOrg.saasSubscriptionStatus = cus.saasSubscriptionStatus;
      this.formDataOrg.CountryCode = cus.CountryCode;
      this.formDataOrg.CountryName = cus.CountryName;
      this.formDataOrg.NoOfEmployees = cus.NoOfEmployees;
      this.formDataOrg.Website = cus.Website;
      this.formDataOrg.Theme = cus.Theme;
      this.formDataOrg.isFreeTrial = cus.isFreeTrial;
      this.formDataOrg.IsSampleData = cus.IsSampleData;
      this.formDataOrg.tid = cus.tid;
      */
      this.selectedChip = this.formDataOrg.NoOfEmployees;
      
      this.cdr.detectChanges();
      
      this.selectChip(
        {id:2, numbers:'2-10',value:10}
      );
      
      // this.formDataOrg = this.currentCustomer;
      
      this.commonService.HideSpinner();

    }, error => {
      this.commonService.HideSpinner();
      this.commonService.ShowToast(error.message, ToastType.Error);
    }
    )
  }

  getCountries() {
    this.dataCountry = Countries['default'];
    this.AutoCompCntry.data = null;
    this.AutoCompCntry.data = Countries['default'];
    this.AutoCompCntry.resultObserve();
  }
  async getCurrencies() {
    await this.genService.GetDataByQuery("Currencies").then(
      res => {

        this.dataCurrency = res['value'];
        this.AutoCompCurrency.data = null;
        this.AutoCompCurrency.data = this.dataCurrency;
        this.AutoCompCurrency.resultObserve();

      }
      , error => {
        this.commonService.HideSpinner();
        this.commonService.ShowToast(error.message, ToastType.Error);
      }
    )
  }
  inProgress: boolean = null;

  onSubmit() {

    this.commonService.ShowSpinner();
    this.inProgress = true;
    // if(this.selectedCard == "card1"){
    //   this.formDataOrg.IsSampleData = true;
    // }
    // else{
    //   this.formDataOrg.IsSampleData = false;
    // }
    
    this.formDataOrg.IsSampleData = false;

    // if (this.Files.length > 0) {
    //   this.uploadImage();
    // } 

    this.saveCustomer();
    
  }

  saveCustomer() {
    this.customersService.update(this.formDataOrg.ID, this.formDataOrg).toPromise().then(res => {
      localStorage.setItem('themecolor',this.formDataOrg.Theme);
      
      

      console.log("Saved Customer Successfully!");
      this.saveVenue();
      // this.saveVenue().then(res =>{
      //   this.afterSave();
      // });

      
      
    }, error => {
      this.commonService.HideSpinner();
      this.commonService.ShowToast(error.message, ToastType.Error);
      this.inProgress = false;
    })
  }

  async saveVenue() {
    // For Save Venue

    this.formDataVen.ShortCode = this.getVenueShortCode(this.formDataVen.VenueName);
    this.formDataVen.Capacity = Number(this.formDataVen.Capacity);

    await this.service.updateData(this.formDataVen.ID, this.formDataVen, this.formDataVen.CreatedBy, this.formDataVen.CreatedDate, "Venues", "Venue").toPromise().then(resp => {
      console.log("Saved Venue Successfully!");

      if (this.Files.length > 0) {
        this.uploadImage();
      } 
      else{
        this.afterSave();
      }

    }, error => {
      this.commonService.HideSpinner();
      this.commonService.ShowToast(error.message, ToastType.Error);
      this.inProgress = false;
    })
  }

  afterSave(){

    this.commonService.HideSpinner();
    this.theme.getCustomerTheme();
    this.emailSender.sendAfterOnBoard();
    localStorage.removeItem("onboardingstatus");
    this.router.navigate(['/calendar']);      

    // this.customersService.sendEmail("sameerspatel95+STR1@gmail.com").toPromise().then(res =>{
    //   this.inProgress = false;            
      
    // })

  }

  
  uploadImage() {
    let formData = new FormData();
    let promises = [];
    for (let index = 0; index < this.Files.length; index++) {
      const element = this.Files[index];

      if (element.files.length > 0) {
        const f = element.files[0];

        if (f.IsSelected != undefined && f.IsSelected == false)
          continue;

        formData = new FormData();
        formData.append('', f);
        promises.push(this.eventDocumentService.post(formData, this.folderName).toPromise())
      }
    }

    Promise.all(promises).then(res => {
      console.log('All files uploaded.');
      console.log('Uploading completed. ', new Date());
      this.afterSave();

      // this.commonService.ShowToast("Attachment Uploaded Successfully", ToastType.Success);
      // this.commonService.HideSpinner();
      this.action.next(this.Files);
    },
      error => {
        console.error(error);
        console.log(error);
        console.log("settlementFolderName");
        console.log(this.Files);
        this.commonService.HideSpinner();
        // this.messageDialog.openMessageDialog("Some thing wrong. Documents are not uploaded. Please contact to Dev team.");
      })
  }

  async getCurrUser() {

    this.commonService.ShowSpinner();

    if (LoginUser.loggedinUser.UserID != null) {
      this.commonService.HideSpinner();
      return;
    }

    var currUser = this.authenticationService.CurrentUser();
    if (!currUser) {
      this.router.navigate(["/unauthorized"]);
    }
    //User code
    var email = ''
    if (currUser.email.includes('+'))
      email = currUser.email.replace('+', '%2B');
    else
      email = currUser.email;

    await this.userManagerService.getCustomer(currUser.Id, email).then(res => {
      var user = res['dataSet']['value'][0];
      if (user.isowner != 1) {
        if (res['dataSet'] == null || res['dataSet'] == undefined) {
          this.router.navigate(["/requestaccess"]);
          return;
        }

        if (res['dataSet']['value'] == null || res['dataSet']['value'] == undefined) {
          this.router.navigate(["/requestaccess"]);
          return;
        }
      }


      var customer = res['customer'];
      var productInfo = res['productInfo'];
      if (productInfo['SubscriptionType'] == "stripe") {
        if (productInfo.ProductName == "" || productInfo.ProductName == null) {
          this.router.navigate(["/membership/subscribe"]);
          return;
        }
      }

      // Get customer from response
      var customer =  res['customer'];

      LoginUser.loggedinUser.UserID = user.UserID;
      LoginUser.loggedinUser.UserName = user.FullName;
      this.currentUsername = user.FullName;
      LoginUser.loggedinUser.Email = user.Email;
      this.currentUserEmail = user.Email;
      LoginUser.loggedinUser.ShortCode = this.getShortCode(user.FullName);
      LoginUser.loggedinUser.OrganisationName = customer.OrganisationName;
      LoginUser.loggedinUser.Subscription = productInfo.ProductName;
      LoginUser.loggedinUser.SubscriptionType = productInfo.SubscriptionType;
      LoginUser.customer = customer;   
      currUser['subscription'] = productInfo.ProductName;
      currUser['isowner'] = user.isowner;
      
      currUser['CustomerObjectID'] = customer.CustomerObjectId;  
      this.authenticationService.login(currUser);
      //LoginUser.IsAdmin = user.IsAdmin;


      // Loading Section Data
      LoginUser.getSectionAccess = res['dataSet']['Table2'];
      var data = res['dataSet']['value'] as any[];
      var Inactive = data[0]["Inactive"];
      if (Inactive == true) {
        this.router.navigate(["/unauthorized"]);
      }

      /*
      if (data.length > 0 && data[0]["IsAdmin"] == true){
        LoginUser.IsAdmin = true;
        if(customer.OnBoardingStatus == 'signup')
          this.router.navigate(["/onboarding"]);
        // else
        //   this.router.navigate(["/calendar"]);
        resolve(true);
      }
      else */
      {
        // 06/09/2022
        //LoginUser.IsAdmin = true;
        LoginUser.IsAdmin = user.IsAdmin;
        this.permissionService.getActionData();

        // Getting User access data
        var rbacUM = res['dataSet']['Table1'] as UserModuleAccess[];
        var mAccess = rbacUM.filter(function (x) {
          return x.IsVisible == true
        })

        if (mAccess.length == 0) {

        }
        else {
          LoginUser.getModulesAccess = rbacUM;
          //resolve(true);
        }

        this.commonService.HideSpinner();
        return;

      }
    }, error => {

    })
  }

  getShortCode(fullName: string): string {
    var shortcode: string = '';
    if (fullName) {
      var arr = fullName.split(' ');
      if (arr.length > 0) {
        if (arr.length > 1) {
          shortcode = arr[0].substring(0, 1);
          shortcode += arr[1].substring(0, 1);
        }
        else {
          shortcode = arr[0].substring(0, 1);
        }
      }
    }

    return shortcode;
  }

  getVenueShortCode(fullName: string): string {
    var shortcode: string = '';
    if (fullName) {
      var arr = fullName.split(' ');
      if (arr.length > 0) {
        if (arr.length > 1) {
          shortcode = arr[0].substring(0, 1);
          shortcode += arr[1].substring(0, 1);
        }
        else {
          shortcode = arr[0].substring(0, 1);
        }
      }
    }

    return shortcode;
  }

  selectedCard:string=null;
  selectCard(cardId:string){
    this.selectedCard = cardId;
  }

  chechCondition(){
    const zeroCount = (this.selectedMask.match(/0/g) || []).length;
    

    if(this.formDataOrg.Phone == null || this.formDataOrg.Phone == undefined || this.formDataOrg.Phone == ''){
      return true
    }

    if (this.formDataOrg.Phone.length === zeroCount) {
      return false;
    } else {
      return true;
    }
  }

  removeValue(){
    this.selectedCapacityChip = null;
    this.formDataVen.Capacity = null;
  }

  logout() {
    localStorage.removeItem("onboardingstatus");
    this.theme.removeThemeToken();
    this.tokenService.removeToken();
    this.authenticationService.logout();
  }

  setActiveTheme(value:string){
    if (value == 'default'){

      this.activeTheme = "rightDiv-default";
    }
    else if (value == 'blue'){
      
      this.activeTheme = "rightDiv-blue";
    }
    else if (value == 'purple'){
      
      this.activeTheme = "rightDiv-purple";
    }
    else if (value == 'black'){
      
      this.activeTheme = "rightDiv-black";
    }
  }

  uploadAndResize() {
    this.Files = [];
    this.imageCompress
     .uploadFile()
     .then(({ image, orientation,fileName }: UploadResponse) => {
       

       this.imageCompress
         .compressFile(image, 1, 100, 100,250,100)
         .then((result: DataUrl) => {
           this.previewImage = result; 

           var file:any[] = this.dataURItoFile(result,fileName);
           this.pushFiles(file);
         });
     });
 }

 dataURItoFile(dataURI:any, filename:any) {
   // Split the data URI to get the media type and data
   debugger
   const [, mediaType, base64Data] = dataURI.match(/^data:(.*?);base64,(.*)$/);
 
   // Convert the Base64 data to binary format
   const byteString = atob(base64Data);
   const arrayBuffer = new ArrayBuffer(byteString.length);
   const uint8Array = new Uint8Array(arrayBuffer);
 
   for (let i = 0; i < byteString.length; i++) {
     uint8Array[i] = byteString.charCodeAt(i);
   }
 
   // Create a Blob (Binary Large Object) from the binary data
   const blob = new Blob([uint8Array], { type: mediaType });
 
   // Create a File object with the necessary properties
   var files:File[]=[];
   const file = new File([blob], filename, {
     lastModified: new Date().getTime(),
     type: mediaType
   });
   files.push(file);
 
   return files;
 }

 async getVenue(){
  await this.venueService.getOnBoardVenue().then((res:any)=>{
  //await this.genService.GetDataByQuery("Venues?$filter= ID eq 53").then((res:any)=>{
    let item = res['value'] as Venue[];
    if(item.length > 0){
      this.formDataVen =item[0];
      // this.formDataVen.CreatedDate = new Date(item[0].CreatedDate);
      // this.formDataVen.ModifiedDate = new Date(item[0].ModifiedDate);
      this.selectCapcityChip(
        {id:1, numbers:'0-500',value:"500"}
        );
    }
  },error=>{
    console.error("venue error");
  })
 }
}
