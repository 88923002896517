import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBModalRef, MDBModalService, ToastService } from 'ng-uikit-pro-standard';
import { CommonService } from 'src/app/Base/Common.service';
import { GeneralService } from 'src/app/services/general.service';
import { GenericService } from 'src/app/services/generic.service';
import { themeService } from 'src/app/services/theme.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmDialogeService } from 'src/app/confirm-dialoge/confirm-dialoge.service';
import { MessageDialogeService } from 'src/app/message-dialoge/message-dialoge.service';
import { EventFuncService } from 'src/app/modules/event/EventFuncService.service';
import { ContractTemplatesService } from 'src/app/services/setup/contract-templates.service';
import { AttachmentComponent } from 'src/app/modules/event//attachment/attachment.component';
import { EventDocumentService } from 'src/app/services/event-document.service';
import { AzblobStorageService } from 'src/app/services/azblob-storage.service';
import { ToastType } from 'src/app/Enum/ToastType';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-event-contract-upload-template',
  templateUrl: './event-contract-upload-template.component.html',
  styleUrls: ['./event-contract-upload-template.component.scss']
})
export class EventContractUploadTemplateComponent implements OnInit {

  Documents:any[] = [];
  public modalRef: MDBModalRef;      
  folderName:string = "system/contractgenerator";
  @Output() isContractTemplate:EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public service: GenericService,
    public router: Router,
    public route: ActivatedRoute,
    public spinner: NgxSpinnerService,
    public toast: ToastService,
    public modalService: MDBModalService,
    public dialog: ConfirmDialogeService,
    public genService: GeneralService,
    public common: CommonService,
    public eventFunc: EventFuncService,
    public eventDocumentService: EventDocumentService,
    public azblobStorageService: AzblobStorageService,
    public contractTemplatesService: ContractTemplatesService,
    public theme:themeService,
    public permissionService:PermissionService,
    public messageDialog?: MessageDialogeService,
  ) {  }

  ngOnInit(): void {
      this.getData();
  }

  getData(){
    this.eventDocumentService.getAll(this.folderName).toPromise().then(res =>{      
      this.Documents = res as any[];

      if(this.Documents.length > 0){
        this.isContractTemplate.emit(true);
      }
      else{
        this.isContractTemplate.emit(false);
      }

    })
  }

  OpenAttachmentModal( ) {

    if(this.Documents.length >= 4 && this.common.isPremium() == true){
      this.messageDialog.openMessageDialog("Sorry, you are using Premium plan and you have already created 4 contract. No more than 4 contracts are allowed")
      return;
    }
    
    this.modalRef = this.modalService.show(AttachmentComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-xl modal-content modal-dialog cascading-modal modal-top',
      containerClass: '',
      animated: true,
      styles: "overflow-y: auto",     
      data: {
        folderName: this.folderName,
        fileFormat:'word',
        Documents:this.Documents                   
      } 
    });

    this.modalRef.content.action.subscribe((result: any) => {      
      if(result){
        this.getData();               
      }
              
    })
  }
  
  delete(blobName:string){
    this.dialog.openConfirmDialog("Are you sure you want to delete this document?").afterClosed().subscribe(res => {
      if (res) {  
        this.common.ShowSpinner();
        this.eventDocumentService.delete(blobName).subscribe(res =>{      
          this.getData();
          this.common.HideSpinner();
          this.common.ShowToast("Successfully deleted!", ToastType.Success);
        }, error=>{
          this.common.HideSpinner();
          this.common.ShowToast("Error", ToastType.Error);
        })              
      }
    })   
  }


}