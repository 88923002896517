import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBModalRef, MDBModalService, ToastService } from 'ng-uikit-pro-standard';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { Autocomplete } from 'src/app/Base/Autocomplete';
import { BLBase } from 'src/app/Base/BLBase/BLBase.component';
import { CommonService } from 'src/app/Base/Common.service';
import { SharePointConfigService } from 'src/app/Base/SharePoint/share-point-config.service';
import { ToastType } from 'src/app/Enum/ToastType';
import { ConfirmDialogeService } from 'src/app/confirm-dialoge/confirm-dialoge.service';
import { MessageDialogeService } from 'src/app/message-dialoge/message-dialoge.service';
import { Currency } from 'src/app/models/currency.model';
import { Venue } from 'src/app/models/venue.model';
import { GeneralService } from 'src/app/services/general.service';
import { GenericService } from 'src/app/services/generic.service';
import { themeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-venue-popup-mdb',
  templateUrl: './venue-popup-mdb.component.html',
  styleUrls: ['./venue-popup-mdb.component.scss']
})
export class VenuePopupMdbComponent extends BLBase<Venue> implements OnInit {
  AutoCompCurrency:Autocomplete<Currency> = new Autocomplete<Currency>("Country","ID");
  searchUserLeadCurrency = null
  action = new Subject<any>();
  constructor(
    public service: GenericService,
    public router: Router,
    public route: ActivatedRoute,
    public spinner: NgxSpinnerService,
    public toast: ToastService,
    public dialog: ConfirmDialogeService,
    public genService: GeneralService,
    public modalService: MDBModalService,
    public messageDialog: MessageDialogeService,
    public common: CommonService,
    //public spService: SPOperationsService,
    public spConfigService:SharePointConfigService,
    public modalRef: MDBModalRef,
    public theme:themeService
  ) { 
    super(service, router, route, spinner, toast, dialog, messageDialog);

    // Form Heading will be shown in html
    this.formTitle = "Venue";

    //Defined Web API controller
    this.addControllerName("Venues");
    this.isGetByPrimaryKey = false;
  }

  public Initializeobject() {
    this.formData = new Venue();
    this.scode = "adm_08";
  }

  ngOnInit(): void {
    // BLBase NgOnInit
    super.ngOnInit();
    this.sectionName = "Venue";
    this.FillAutoComplete("Currencies",this.AutoCompCurrency);
  }

  public BeforeUpsert(form: NgForm): void {
    // form.value.ShortCode = this.getVenueShortCode(form.value.VenueName);
  }
  

  onCreate(form: NgForm) {
    this.router.navigate(['/setup/venue/form'], { replaceUrl: true });
    //BLBase function
    this.Create();
  }

  // This form function
  onSubmit(form: NgForm) {

    if (this.formData.IsSystemGenerated == true) {
      this.messageDialog.openMessageDialog("Cannot Update. This record is sustem generated.");
      return;
    }

    //BLBase function
    this.insertRecord(form);
  }

  AfterInsert(form: NgForm) {
    // this.router.navigate(['/setup/venue/list']);
    this.action.next("Submit");
    this.modalRef.hide();
  }

  
  async FillAutoComplete(query: string, Autocomplete: Autocomplete<any>) {
    // this.ShowSpinner();
    await this.genService.GetDataByQuery(query).then(
      res => {
        Autocomplete.data = null;
        Autocomplete.data = res['value'];
        Autocomplete.resultObserve();
        // this.HideSpinner();
      }
      , error => {
        this.HideSpinner();
        this.ShowToast(error.message, ToastType.Error);
      }
    )

  }

  getVenueShortCode(fullName: string): string {
    var shortcode: string = '';
    if (fullName) {
      var arr = fullName.split(' ');
      if (arr.length > 0) {
        if (arr.length > 1) {
          shortcode = arr[0].substring(0, 1);
          shortcode += arr[1].substring(0, 1);
        }
        else {
          shortcode = arr[0].substring(0, 1);
        }
      }
    }

    return shortcode;
  }
}
