<div class="modal-header head">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" id="btnClose">
        <span aria-hidden="true">x</span>
    </button>
    <h5 class="modal-title w-100" id="myModalLabel">Add Venue</h5>
</div>
<div class="modal-body scrollBar " style="overflow:auto">
    <form #Venue="ngForm"  autocomplete="off" (ngSubmit)="onSubmit(Venue)" >
        <fieldset [disabled]="isView">
           
            <div class="row">
                <div class="col-sm-4" [hidden]="true">
                    <div class="md-form">
    
                        <input name="ID" #ID="ngModel" [(ngModel)]="formData.ID" type="text" class="form-control" mdbInput>
                        <label>Venue ID</label>
    
                    </div>
                </div>
                <div class="col-sm-12">
    
                    <mat-form-field class="example-full-width">
                        <mat-label [class]="theme.label">Venue Name</mat-label>
                        <input matInput class="MatInputBoxShadow" required name="VenueName" #VenueName="ngModel" id="txtVenueName"
                            [(ngModel)]="formData.VenueName" type="text">
                        <mat-error *ngIf="VenueName.invalid && VenueName.dirty">This field is required</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12" >
                    <mat-form-field class="example-full-width">
                        <mat-label [class]="theme.label">Short Code</mat-label>
                        <input maxlength="2" matInput class="MatInputBoxShadow"  name="ShortCode" id="txtShortCode"
                            #ShortCode="ngModel" [(ngModel)]="formData.ShortCode" type="text" required>
                        <mat-hint align="start">Max length 2 Characters </mat-hint>
                        <mat-hint align="end">{{formData.ShortCode == null ? 0 : formData.ShortCode.length}} / 2</mat-hint>
                        <mat-error *ngIf="ShortCode.invalid && ShortCode.dirty">This field is required</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12">
                    <mat-form-field class="example-full-width">
                        <mat-label [class]="theme.label">Capacity</mat-label>
                        <input matInput class="MatInputBoxShadow" name="Capacity" #Capacity="ngModel" id="numCapacity"
                            [(ngModel)]="formData.Capacity" type="number">
                    </mat-form-field>
                </div>
                <div class="col-sm-12">
                    <mat-form-field class="example-full-width" appearance="legacy">
                        <mat-label [class]="theme.label">Currency</mat-label>
                        <mat-select [(ngModel)]="formData.CurrencyID" #CurrencyID="ngModel" name="CurrencyID" required id="drpCurrency">
                            <mat-option>
                                <ngx-mat-select-search name="currency" #currency="ngModel" [(ngModel)]="searchUserLeadCurrency"
                                    (ngModelChange)="AutoCompCurrency.searchResult.next($event)" placeholderLabel="Search..."
                                    noEntriesFoundLabel="Not Found!" [ngModelOptions]="{ standalone: true }">
                                </ngx-mat-select-search>
                            </mat-option>
                          <mat-option *ngFor="let option of AutoCompCurrency.results | async" [value]="option.ID">
                            {{ option.Symbol }} - {{ option.Country }} - {{ option.CurrencyName }}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="CurrencyID.invalid && CurrencyID.dirty">This field is
                          required</mat-error>
                      </mat-form-field>
                </div>
                <div class="col-sm-12">
                    <div class="">
                        <mdb-checkbox name="Inactive" #Inactive="ngModel" [(ngModel)]="formData.Inactive">
                            <mat-label [class]="theme.label">Inactive</mat-label>
                        </mdb-checkbox>
                    </div>
                </div>
    
            </div>
        </fieldset>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <!-- <button mat-raised-button  [disabled]="Venue.invalid" type="button" (click)="onSubmit(Venue)" color="primary">
                Submit
              </button> -->
              <button type="button" (click)="onSubmit(Venue)" [disabled]="Venue.invalid" mdbBtn [class]="theme.button" class="waves-light" mdbWavesEffect id="btnSave">
                Submit
            </button>
            </div>
          </div>
    </form>
</div>