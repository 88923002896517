import { Injectable } from '@angular/core';
import { PermissionService } from './permission.service';
import { UserManagerService } from './user-manager.service';
import { AuthenticationService } from '../helpers/authentication.service';
import { TokenService } from './token.service';
import { Router } from '@angular/router';
import { LoginUser } from '../Base/User/login-user';
import moment from 'moment';
import { UserModuleAccess } from '../Base/models/user-module-access';

@Injectable({
  providedIn: 'root'
})
export class SignInValidateService {

  IsTest:boolean = false;

  constructor(
    public router: Router,     
    public tokenService:TokenService,
    public  authenticationService:AuthenticationService,    
    private userManagerService:UserManagerService,    
    private permissionService:PermissionService
  ) { }

  public async processSignin(currUser:any): Promise<boolean> {

    //debugger;

    return new Promise(async (resolve, reject) => {


      this.getUser(currUser).then(res => {

        let isResolved = res as any;

        if (isResolved == true)
          resolve(true);
        else
          reject(false);

      }, error => {

      }
      )
    })
                                                        
    return false;
  }


  /*
  public async processSignin(currUser:any): Promise<boolean> {

    //debugger;

        return new Promise(async (resolve, reject) => {
               
          //debugger;
          //User code
          var email = ''
          if (currUser.email.includes('+'))
            email = currUser.email.replace('+', '%2B');
          else
            email = currUser.email;

          await this.userManagerService.getCustomer(currUser.Id, email).then(res => {
            // If no data found against the user/ Ask him to request access
            if (res['dataSet'] == null) {
              this.router.navigate(["/requestaccess"]);
              return;
            }

            // If user is not owner
            var user = res['dataSet']['value'][0];
            if (user.isowner != 1) {

              if (res['dataSet'] == null || res['dataSet'] == undefined) {
                this.router.navigate(["/requestaccess"]);
                return;
              }

              if (res['dataSet']['value'] == null || res['dataSet']['value'] == undefined || res['dataSet']['Table1'].length == 0) {
                this.router.navigate(["/requestaccess"]);
                return;
              }
            }

            // If user is active or not
            if (user.Inactive == true) {
              this.router.navigate(["/requestaccess"]);
              return;
            }

            // Get customer from response
            var customer = res['customer'];
            // Get product info from response
            var productInfo = res['productInfo'];

            // Set local global Login variable
            LoginUser.loggedinUser.UserID = user.UserID;
            LoginUser.loggedinUser.UserName = user.FullName;
            LoginUser.loggedinUser.Email = user.Email;
            LoginUser.loggedinUser.ShortCode = this.getShortCode(user.FullName);
            LoginUser.loggedinUser.OrganisationName = customer.OrganisationName;
            LoginUser.loggedinUser.Subscription = productInfo.ProductName;
            LoginUser.loggedinUser.SubscriptionType = productInfo.SubscriptionType;
            LoginUser.customer = customer;
            LoginUser.loggedinUser.SubscriptionName = productInfo.SubscriptionName;
            LoginUser.IsAdmin = user.IsAdmin;
            // Sameer : 05030023
            LoginUser.loggedinUser.IsFreeTrial = customer.isFreeTrial;
            LoginUser.loggedinUser.StartDate = customer.startDate ? moment(customer.startDate) : moment(customer.CreatedDate);
            LoginUser.loggedinUser.ExpiryDate = customer.endDate ? moment(customer.endDate) : moment(customer.CreatedDate).add(1, 'M');
            var currentDate = moment(new Date());
            LoginUser.loggedinUser.TrialRemainingDays = LoginUser.loggedinUser.ExpiryDate.diff(currentDate, 'days');
            // Sameer : 05030023

            LoginUser.loggedinUser.OnBoardingStatus = customer.OnBoardingStatus;

            // Update current user data in local Storage 
            currUser['subscription'] = productInfo.ProductName;
            currUser['SubscriptionName'] = productInfo.SubscriptionName;
            currUser['trialVersion'] = productInfo.trialVersion;
            currUser['activeVersion'] = productInfo.activeVersion;
            currUser['isowner'] = user.isowner;
            currUser['UserID'] = user.UserID;
            currUser['CustomerObjectID'] = customer.CustomerObjectId;
            this.authenticationService.login(currUser);

            // Load Section Access
            LoginUser.getSectionAccess = res['dataSet']['Table2'];

            // Get Action Access
            this.permissionService.getActionData();

            // Getting User Modules data
            var rbacUM = res['dataSet']['Table1'] as UserModuleAccess[];
            var mAccess = rbacUM.filter(function (x) {
              return x.IsVisible == true
            })

            if (mAccess.length > 0) {
              LoginUser.getModulesAccess = rbacUM;
            }

            // if(productInfo.SubscriptionStatus != "active" && productInfo.SubscriptionStatus != "trialing"){
            //   this.router.navigate(["/requiredsubscription"]);
            // }

            // Check if subscribed to stripe or not if it is logged in via local signup 
            if (productInfo['SubscriptionType'] == "stripe") {
              if (productInfo.ProductName == "" || productInfo.ProductName == null) {
                if (this.IsTest == true)
                  this.router.navigate(["/membership/subscribe"], { queryParams: { IsTest: "1" } });
                else
                  this.router.navigate(["/membership/subscribe"]);

                resolve(true);
                return;
              }
            }

            // If user is not completed steps of onboarding via stripe
            // Sameer : 06012023

            if (customer.OnBoardingStatus == 'signup') {
              localStorage.setItem('onboardingstatus', customer.OnBoardingStatus)
              this.router.navigate(["/onboarding"]);

            }


            // If user is not completed steps of onboarding via market place
            if (customer.SubscriptionType != 'stripe') {
              if (customer.OrganisationName == null || customer.OrganisationName == "")
                this.router.navigate(["/onboarding"]);
            }

            if (moment(new Date) > moment(LoginUser.loggedinUser.ExpiryDate)) {
              this.router.navigate(['/expired'])
            }


            resolve(true);
          }, error => {
            reject(false);
          })    

        })
                                                        
    return false;
  }
  */

  async getUser(currUser:any, checkIfSubscribedToStripe:boolean = true):Promise<boolean>{
        debugger;
          //User code
          var email = ''
          if (currUser.email.includes('+'))
            email = currUser.email.replace('+', '%2B');
          else
            email = currUser.email;
          let res;
          try{
             res = await this.userManagerService.getCustomer(currUser.Id, email); 
          }
          catch(error){
            throw new Error(error);
          }
          //await this.userManagerService.getCustomer(currUser.Id, email).then(res => {
            // If no data found against the user/ Ask him to request access
            if (res['dataSet'] == null) {
              this.router.navigate(["/requestaccess"]);
              return false;
            }
            
             // Get customer from response
             var customer = res['customer'];

            // If user is not owner
            var user = res['dataSet']['value'][0];
            if (user.isowner != 1) {

              if (res['dataSet'] == null || res['dataSet'] == undefined) {
                this.router.navigate(["/requestaccess"]);
                return false;
              }

              if (res['dataSet']['value'] == null || res['dataSet']['value'] == undefined || res['dataSet']['Table1'].length == 0) {
                
                currUser['CustomerObjectID'] = customer.CustomerObjectId;
                this.authenticationService.login(currUser);

                this.router.navigate(["/requestaccess"]);

                
                return false;
              }
            }

            // If user is active or not
            if (user.Inactive == true) {
              this.router.navigate(["/requestaccess"]);
              return false;
            }

           
            // Get product info from response
            var productInfo = res['productInfo'];

            // Set local global Login variable
            LoginUser.loggedinUser.UserID = user.UserID;
            LoginUser.loggedinUser.UserName = user.FullName;
            LoginUser.loggedinUser.Email = user.Email;
            LoginUser.loggedinUser.ShortCode = this.getShortCode(user.FullName);
            LoginUser.loggedinUser.OrganisationName = customer.OrganisationName;
            LoginUser.loggedinUser.Subscription = productInfo.ProductName;
            LoginUser.loggedinUser.SubscriptionType = productInfo.SubscriptionType;
            LoginUser.customer = customer;
            LoginUser.loggedinUser.SubscriptionName = productInfo.SubscriptionName;
            LoginUser.IsAdmin = user.IsAdmin;
            // Sameer : 05030023
            LoginUser.loggedinUser.IsFreeTrial = customer.isFreeTrial;
            LoginUser.loggedinUser.StartDate = customer.startDate ? moment(customer.startDate) : moment(customer.CreatedDate);
            LoginUser.loggedinUser.ExpiryDate = customer.endDate ? moment(customer.endDate) : moment(customer.CreatedDate).add(1, 'M');
            var currentDate = moment(new Date());
            LoginUser.loggedinUser.TrialRemainingDays = LoginUser.loggedinUser.ExpiryDate.diff(currentDate, 'days');
            // Sameer : 05030023

            LoginUser.loggedinUser.OnBoardingStatus = customer.OnBoardingStatus;

            // Update current user data in local Storage 
            currUser['subscription'] = productInfo.ProductName;
            currUser['SubscriptionName'] = productInfo.SubscriptionName;
            currUser['trialVersion'] = productInfo.trialVersion;
            currUser['activeVersion'] = productInfo.activeVersion;
            currUser['isowner'] = user.isowner;
            currUser['UserID'] = user.UserID;
            currUser['CustomerObjectID'] = customer.CustomerObjectId;
            currUser['OnBoardingStatus'] = customer.OnBoardingStatus;
            currUser['OrganisationName'] = customer.OrganisationName;
            currUser['OwnerId'] = String(user.CustomerID);
            this.authenticationService.login(currUser);

            // Load Section Access
            LoginUser.getSectionAccess = res['dataSet']['Table2'];

            // Get Action Access
            // Add await on 02/06/2024
            await this.permissionService.getActionData();

            // Getting User Modules data
            var rbacUM = res['dataSet']['Table1'] as UserModuleAccess[];
            var mAccess = rbacUM.filter(function (x) {
              return x.IsVisible == true;
            })

            if (mAccess.length > 0) {
              LoginUser.getModulesAccess = rbacUM;
            }

            // if(productInfo.SubscriptionStatus != "active" && productInfo.SubscriptionStatus != "trialing"){
            //   this.router.navigate(["/requiredsubscription"]);
            // }

            // Check if subscribed to stripe or not if it is logged in via local signup 
            if(checkIfSubscribedToStripe == true){

              if (productInfo['SubscriptionType'] == "stripe") {
                if (productInfo.ProductName == "" || productInfo.ProductName == null) {
                  if (this.IsTest == true)
                    this.router.navigate(["/membership/subscribe"], { queryParams: { IsTest: "1" } });
                  else
                    this.router.navigate(["/membership/subscribe"]);
  
                  //resolve(true);
                  return false;;
                }
              }

            }
            

            // If user is not completed steps of onboarding via stripe
            // Sameer : 06012023

            if (customer.OnBoardingStatus == 'signup') {
              localStorage.setItem('onboardingstatus', customer.OnBoardingStatus)
              this.router.navigate(["/onboarding"]);

            }


            // If user is not completed steps of onboarding via market place
            if (customer.SubscriptionType != 'stripe') {
              if (customer.OrganisationName == null || customer.OrganisationName == "")
                this.router.navigate(["/onboarding"]);
            }

            if (moment(new Date) > moment(LoginUser.loggedinUser.ExpiryDate)) {
              this.router.navigate(['/expired'])
            }


            return true;
          // }, error => {
          //   return false;
          // })    
  }

  
  getShortCode(fullName:string):string{
    var shortcode:string = '';
    if(fullName){
      var arr = fullName.split(' ');
      if(arr.length > 0){
        if(arr.length > 1){
           shortcode = arr[0].substring(0, 1);
           shortcode += arr[1].substring(0, 1);
        } 
        else{
          shortcode = arr[0].substring(0, 1);         
        }
      }
    }

    return shortcode;
  }
}
