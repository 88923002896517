<div
  class="modal-header darken-3 white-text  gradient-card-header narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center" [class]="theme.heading">
  <h4 class="h4-responsive">
    Add Attachment
  </h4> 
  <div class="btn-group" role="group" aria-label="Basic example">
    <a (click)="modalRef.hide()" id="btnClose">
    <button mdbBtn type="button" [class]="theme.button" size="sm" class="waves-light" mdbWavesEffect>
      <mdb-icon fas icon="times" ></mdb-icon>
    </button>
  </a>

  </div>
</div>




<div class="modal-body mb-0 text-left" id="modalBodyArea">
  <div class="row">
    <div class="col-sm-12">
      <form #Notes="ngForm" autocomplete="off" > 

        <div class="table-responsive">
          <table class="table table-striped table-bordered">
            <tbody>
              <tr *ngFor="let option of attachments; let index = index">
                <td >
                    <input multiple class="text-left" type="file" name="attach" id="fileAttach" 
                    (change)="handleFileInput($event.target.files,index)"
                    accept=".doc, .docx">                
                </td>
                <td style="width: 12px;" >
                    <button mat-button type="button" class="flat-Icon-Color"  matTooltip="Delete" [class]="theme.icon" id="btnDelete"         
                     (click)="RemoveAttachment(index)">
                    <mat-icon class="shadow-Icon-Color mr-1">delete</mat-icon>                   
                    </button>  
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <br>
        <div class="row">

          <div class="col-md-12">
           
            <div >
                <button class="matIconButtonsCustom-this"  mat-raised-button matTooltip="Add" id="btnAdd"
                style="float: left;"
                (click)="addRow();">
                  <mat-icon class=" mr-1" [class]="theme.icon">add</mat-icon> Add                                 
                </button>


                <button class="matIconButtonsCustom-this"  mat-raised-button matTooltip="Upload" id="btnUpload"
                style="float: right;"
                [disabled]="fileStore == null"
                (click)="onSave()">
                  <mat-icon class="mr-1" [class]="theme.icon">save</mat-icon> Upload
                </button>

            </div>
            
            
          </div>
        </div>

        
      </form>
    </div>
  </div>

  <br>


</div>
