import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { CommonService } from 'src/app/Base/Common.service';
import { LoginUser } from 'src/app/Base/User/login-user';
import { ToastType } from 'src/app/Enum/ToastType';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { themeService } from 'src/app/services/theme.service';
import { RequestEnterpriseService } from './request-enterprise.service';

@Component({
  selector: 'app-request-enterprise',
  templateUrl: './request-enterprise.component.html',
  styleUrls: ['./request-enterprise.component.scss']
})
export class RequestEnterpriseComponent implements OnInit {

  enterprise: any[] = [
    {selected:true,name:"Unlimited Users"},
    {selected:true,name:"Unlimited Venues"},
    {selected:true,name:"Unlimited Customer Support"},
    {selected:true,name:"Event Calendar"},
    {selected:true,name:"Avail View"},
    {selected:true,name:"Event Booking"},
    {selected:true,name:"Event List"},
    {selected:true,name:"Event Portal"},
    {selected:true,name:"Event Rescheduling"},
    {selected:true,name:"Document Management System"},
    {selected:true,name:"CRM - Clientele Management"},
    {selected:true,name:"Integration to Service Provider(TM, Tixtrack)"},
    {selected:true,name:"Notes"},
    {selected:true,name:"Contract Generator"},
    {selected:true,name:"Deposit Schedule"},
    {selected:true,name:"Financials"},
    {selected:true,name:"Settlement"},
    {selected:true,name:"Reporting"},
    {selected:true,name:"Permission Management"}
  ]

  formData:EnterpriseRequestModel = new EnterpriseRequestModel();
  user: any = LoginUser.loggedinUser;
  constructor(
    public modalRef: MDBModalRef,
    public requestEnterpriseService:RequestEnterpriseService,
    private authenticationService :AuthenticationService,
    public commonService:CommonService,
    public theme:themeService
  ) { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(){
    
  }

  sendRequest(){
    this.commonService.ShowSpinner();
    this.beforeSend();
    this.requestEnterpriseService.sendRequest(this.formData).subscribe(res=>{
      this.commonService.ShowToast("Succcessfully Sent.",ToastType.Success);
      this.modalRef.hide();
      this.commonService.HideSpinner();
    })
  }

  beforeSend(){
    debugger
    this.formData.RequestedBy = this.user.UserName;
    this.formData.RequestByEmail = this.user.Email;
    this.formData.OrganizationName = this.user.OrganisationName;
    this.formData.OldSubscription = this.user.Subscription;
    this.formData.OwnerID = this.authenticationService.CurrentUser().isowner.toString();
    this.formData.Items=[];
    var items =  this.enterprise.filter(item=> item.selected == true);
    items.forEach(element=>{
      this.formData.Items.push(element.name);
    })
  }
}

export class EnterpriseRequestModel{

  Notes:string=null;
  RequestedBy:string=null;
  RequestByEmail:string=null;
  OrganizationName:string=null;
  OldSubscription:string=null;
  Items:string[]=[];
  OwnerID:string=null;
}
