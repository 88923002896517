import { Injectable } from '@angular/core';
import * as customConfig from 'src/app/customConfig.json';
import { AuthenticationService } from '../helpers/authentication.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from '../Base/Common.service';
import { ConfHttpHeadersService } from './conf-http-headers.service';


@Injectable({
  providedIn: 'root'
})
export class UserManagerService {

  public rootURL = customConfig.api; 
  public controller: string = "UserADB2C";

  constructor(
    private http: HttpClient,
    private hdr:ConfHttpHeadersService,
  ) { 

  }

  post(jsonBody: any) {
    return this.http.post(this.rootURL + "api/" + this.controller, jsonBody, this.hdr.getHeaders());
    //return this.http.post('http://localhost:49364/api/UserADB2C/Post', jsonBody, this.getHeaders());
  }

  postNew(jsonBody: any) {
    return this.http.post(this.rootURL + "api/" + this.controller + "New", jsonBody, this.hdr.getHeaders());
    //return this.http.post('http://localhost:49364/api/UserADB2C/Post', jsonBody, this.getHeaders());
  }

  delete(jsonBody: any) {
    return this.http.post(this.rootURL + "api/" + this.controller + "/Delete", jsonBody, this.hdr.getHeaders());
    //return this.http.post('http://localhost:49364/api/UserADB2C/Post', jsonBody, this.getHeaders());
  }

  exists(email:string, userid:number){
    var obj = {
      email:email,
      userId: userid
    }
    return this.http.post(this.rootURL + "api/" + this.controller + "/Exists", obj, this.hdr.getHeaders());
  }

  async getCustomer(ID: string, email:string) {
    return await this.http.get(this.rootURL + "api/" + this.controller + "New" + "?id="+ID+"&email=" + email, this.hdr.getHeaders()).toPromise();    
  }

  

}



// export class studentComponent{

//   arrStudent:any[]= [];
//   objStudent:any = null;

//   save(){
//     this.arrStudent.push();
//   }

//   edit(index:number){
//     this.objStudent.ID = index;
//     this.objStudent =  this.arrStudent[index];
//   }

//   update(index){
//     // objStudent.ID
//     // (click) = "update(objStudent.ID)"
//     this.arrStudent[index].Name = this.objStudent.Name;

//   }



// }