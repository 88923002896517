import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfHttpHeadersService } from './conf-http-headers.service';
import * as customConfig from 'src/app/customConfig.json';

@Injectable({
  providedIn: 'root'
})
export class EventsSearchService {

   private rootURL = customConfig.api; 

  constructor(
    private http: HttpClient,
    private hdr:ConfHttpHeadersService,
    ) { }

  public GetEvents(value:any){
    var jsonbody ={
      value:value
    }
    return this.http.post(this.rootURL + "api/EventSearch/GetEvents" ,jsonbody,this.hdr.getHeaders());
  } 
 
  public GetContractEvents(value:any){
    var jsonbody ={
      value:value
    }
    return this.http.post(this.rootURL + "api/EventSearch/GetContractEvents" ,jsonbody,this.hdr.getHeaders());
  } 

  public GetPaymentEvents(value:any){
    var jsonbody ={
      value:value
    }
    return this.http.post(this.rootURL + "api/EventSearch/GetPaymentEvents" ,jsonbody,this.hdr.getHeaders());
  } 

}
