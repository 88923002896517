import { Injectable } from '@angular/core';
import {  MDBModalService, MDBModalRef } from 'ng-uikit-pro-standard';
import { EventNotesComponent } from './event-notes/event-notes.component';

@Injectable({
  providedIn: 'root'
})
export class EventNotesService {

  constructor() { }

  OpenNotesModal(eventID: number,  modalService: MDBModalService, modalRef: MDBModalRef) {

    modalRef = modalService.show(EventNotesComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-xl modal-content modal-dialog cascading-modal modal-top',
      containerClass: '',
      animated: true,
      styles: "overflow-y: auto",
      data: {
        eventID: eventID
      }
    });
    
    return modalRef;
  }
}
