export class ContractTypes {
    ID: number = 0;
    SerialNo: number = null;
    Name: string;
    FieldTypeID: number = null;
    VenueID:number=null;
    DefaultValue: string =null;
    IsByDefault: boolean = false;
    IsUseForSettlement: boolean = false;
    GLCode:string = null;
    CreatedBy: number = null;
    CreatedDate: Date = null;
    ModifiedBy: number = null;
    ModifiedDate: Date = null;
    Inactive:boolean=null;
    OccurenceID: number = null;
    FieldDescription: string = null;
}