<div class="mb-1 d-flex justify-content-between align-items-center">
  <!-- <button type="button" (click)="OpenAttachmentModal()" 
      mdbBtn [class]="theme.button" size="sm" class="waves-light" mdbWavesEffect>
      <mdb-icon fas icon="upload" class="mr-1"></mdb-icon>Upload
    </button> -->

    <span style="font-size: 11px;"><mdb-icon fas icon="info-circle" ></mdb-icon> Please upload the contract template that you have configured in Step 3.</span>
    <span *ngIf="Documents.length > 0 && permissionService.CheckIfUserhasAccess_Action('Contract Fields','Add')" >
      <button type="button" (click)="OpenAttachmentModal()" mdbBtn [class]="theme.button" size="sm" class="waves-light"
      mdbWavesEffect>
      <mdb-icon fas icon="upload" class="mr-1"></mdb-icon>Upload
    </button>
    </span>
  </div>

<div class="table-responsive ">

  <table class="table " style="border: 1px solid #00000020;">
    <thead>
      <tr>
        <th [class]="theme.label">S #</th>
        <th [class]="theme.label">Document</th>
        <th [class]="theme.label">Modified</th>
        <th [class]="theme.label">Modified By</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="Documents.length == 0">
        <td colspan="4" style="text-align: center;font-weight: 500;">No data found! <a (click)="OpenAttachmentModal()"
            style="color: #007bff;text-decoration: underline;">Upload</a> your first template.</td>
      </tr>
      <tr *ngFor="let row of Documents;let i = index">
        <td>{{i + 1}}</td>
        <td>{{row.DisplayName}}</td>
        <td>{{row.LastModified | date: 'MM/dd/yyyy hh:mm aa'}}</td>
        <td>{{row.ModifiedBy}}</td>
        <td>
          <button (click)="delete(row.Name)" mat-button type="button" class=" text-right float-right" [class]="theme.icon" *ngIf="permissionService.CheckIfUserhasAccess_Action('Contract Fields','Delete')"
            matTooltip="Delete">
            <mat-icon class="shadow-Icon-Color mr-1">delete</mat-icon>
          </button>
        </td>
      </tr>
    </tbody>
  </table>


</div>

<!-- <div class="mt-1 text-right" *ngIf="Documents.length > 0">
  <button type="button" (click)="OpenAttachmentModal()" mdbBtn [class]="theme.button" size="sm" class="waves-light"
    mdbWavesEffect>
    <mdb-icon fas icon="upload" class="mr-1"></mdb-icon>Upload
  </button>
</div> -->