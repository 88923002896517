import { Injectable } from '@angular/core';
import * as customConfig from 'src/app/customConfig.json';
import { AuthenticationService } from '../helpers/authentication.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { LoginUser } from '../Base/User/login-user';
import { RbacUsers } from '../rbac/rbac-users.model';
import { log } from 'console';
import { CommonService } from '../Base/Common.service';
import { ConfHttpHeadersService } from './conf-http-headers.service';

class RbacUsers_DTO{
  UserID: string = null;
  Email: string = null;
  FirstName: string = null;
}

@Injectable({
  providedIn: 'root'
})
export class EmailSenderService {

  private rootUrl:string = customConfig.api;
  private controller:string;
  user:RbacUsers_DTO = new RbacUsers_DTO();

  constructor(
    private http: HttpClient,
    private authenticationService :AuthenticationService,
    private common:CommonService,
    private  hdr: ConfHttpHeadersService
  ) { }

  sendWelcome(email:string, ownerid:string ){
    this.beforeSendEmail();
    
    this.http.post(this.rootUrl + "api/emailSender/welcome",this.user,this.hdr.getPostHeaders()).toPromise().then(a=>{
      debugger
      console.log("SendEmail");
    },error=>{
      debugger
      console.log("Get Errro");
    }
    );
  }

  sendAfterOnBoard(){
    this.beforeSendEmail();
    this.http.post(this.rootUrl + "api/emailSender/afteronboard",this.user,this.hdr.getPostHeaders()).toPromise().then(a=>{
      debugger
      console.log("SendEmail");
    },error=>{
      debugger
      console.log("Get Errro");
    }
    );
  }

  sendSdtxVarc(){
    this.beforeSendEmail();
    this.http.post(this.rootUrl + "api/emailSender/SdtxVarc",this.user,this.hdr.getPostHeaders()).toPromise().then(a=>{
      debugger
      console.log("SendEmail");
    },error=>{
      debugger
      console.log("Get Errro");
    }
    );
  }

  sendOffer(){
    this.beforeSendEmail();
    this.http.post(this.rootUrl + "api/emailSender/offerEmap",this.user,this.hdr.getPostHeaders()).toPromise().then(a=>{
      debugger
      console.log("SendEmail");
    },error=>{
      debugger
      console.log("Get Errro");
    }
    );
  }

  

  private beforeSendEmail(){
    this.user.UserID = LoginUser.loggedinUser.UserID; 
    this.user.FirstName = LoginUser.loggedinUser.UserName; 
    this.user.Email = LoginUser.loggedinUser.Email; 
  }




}
