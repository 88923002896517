<div class="sidenav" [ngClass]="collapsed ? 'sidenav-collapsed':''" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()">
  <div class="logo-container">
    <!-- <button class="logo" type="button" (click)="toggleSidenav()">A</button>
        <div class="logo-text" @fadeInOut *ngIf="collapsed">Angular </div> -->
        
    <a routerLink="" *ngIf="organizationService.showImage !=null && collapsed" style="    color: white;
                font-family: cursive;
                text-align: center;
                padding-top: 1px;">
      <img [src]="organizationService.showImage" class="img-fluid flex-center" style=" height: 100px !important;width: 250px !important;">

    </a>
    <a routerLink="" style="    color: #1d1d1e;
        font-family: cursive;
        text-align: center;
        width:100%;
" *ngIf="organizationService.showImage ==null && collapsed">
      {{user?.OrganisationName}}
    </a>

    <button class="btn-close" @rotate *ngIf="collapsed" (click)="closeSideNav()">
      <i class="fas fa-times close-icon"></i>
    </button>
  </div>

  <div class="scrollwrap scrollwrap_delayed">
    <ul class="sidenav-nav">
      <!-- <li class="sidenav-nav-item" *ngFor="let item of navData">
                <a  class="sidenav-nav-link" (click)="handleClick(item)" 
                    *ngIf="item.items && item.items.length > 0"
                >
                    <i class="sidenav-link-icon" [class]="item.icon"></i>
                    <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">{{item.label}}</span>
                    <i *ngIf="item.items && collapsed" class="menu-collapse-icon"
                    [ngClass]="!item.expanded ? 'fas fa-angle-right' : 'fas fa-angle-down'"
                    ></i>
                </a>
               
                <a  class="sidenav-nav-link" 
                    *ngIf="!item.items || (item.items && item.items.length === 0 )"
                    [routerLink]="item.routeLink"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact:true}"
                >
                    <i class="sidenav-link-icon" [class]="item.icon"></i>
                    <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">{{item.label}}</span>
                </a>
    
                <div *ngIf="collapsed && item.items && item.items.length > 0">
                    <app-sublevel-menu
                        [data]="item"
                        [collapsed]="collapsed"
                        [multiple]="multiple"
                        [expanded]="item.expanded"
                    ></app-sublevel-menu>
                </div>
            </li> -->
      <li class="sidenav-nav-item">
        <a class="sidenav-nav-link" [hidden]="permissionService.CheckIfUserhasAccess('Event Management')" (click)="onExpand1()">
          <i class="sidenav-link-icon far fa-calendar-check" [class]="theme.sidenavIcon"  matTooltip="Event Booking"></i>
          <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">Event Booking</span>
          <i *ngIf="collapsed" class="menu-collapse-icon"
            [ngClass]="!expanded1 ? 'fas fa-angle-down' : 'fas fa-angle-up'"></i>
        </a>

        <ul *ngIf="collapsed" class="sublevel-nav" [@submenu]="expanded1 ? 
                    {value:'visible',params:{transitionParams:'400ms cubic-bezier(0.86, 0, 0.07, 1)',height:'*'}}
                     : {value:'hidden',
                        params:{transitionParams:'400ms cubic-bezier(0.86, 0, 0.07, 1)',height:'0'}
                     }">
          <li class="sublevel-nav-item">

            <a class="sublevel-nav-link" [hidden]="permissionService.CheckIfUserhasAccess_Section('Booking Calendar')" @fadeInOut routerLink="/calendar" (click)="closeSideNav()"  id="sidenavCalendar"
              routerLinkActive="active-sublevel" [routerLinkActiveOptions]="{exact:true}">
              <i class="sublevel-link-icon fas fa-angle-right"></i>
              <span class="sublevel-link-text" *ngIf="collapsed">Calendar</span>

            </a>

            <a class="sublevel-nav-link" [hidden]="permissionService.CheckIfUserhasAccess_Section('Event List')" @fadeInOut routerLink="/event/event/list" (click)="closeSideNav()"  id="sidenavEventList"
              routerLinkActive="active-sublevel" [routerLinkActiveOptions]="{exact:true}">
              <i class="sublevel-link-icon fas fa-angle-right"></i>
              <span class="sublevel-link-text" *ngIf="collapsed">Event List</span>

            </a>
          </li>
        </ul>
      </li>
      <li class="sidenav-nav-item" [hidden]="permissionService.CheckIfUserhasAccess_Section('Client List')">
        <a class="sidenav-nav-link" routerLink="/client/list" routerLinkActive="active" (click)="closeSideNav()" id="sidenavClientList"
        [routerLinkActiveOptions]="{exact:true}"> 
         <span>
          <i class="sidenav-link-icon fas fa-user-friends" [class]="theme.sidenavIcon"  matTooltip="Client Management"></i>
          <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">Client Management</span>
         </span>
        </a>
      </li>
      <li class="sidenav-nav-item" [hidden]="permissionService.CheckIfUserhasAccess_Action('Contract Information','Add') == false && permissionService.CheckIfUserhasAccess_Action('Contract Information','Edit') == false">
        <a class="sidenav-nav-link" routerLink="/event/eventcontracts/list" routerLinkActive="active" (click)="closeSideNav()"  id="sidenavContractList"
                    [routerLinkActiveOptions]="{exact:true}">
          <span>
            <i class="sidenav-link-icon fas fa-file-contract" [class]="theme.sidenavIcon"  matTooltip="Contract Management"></i>
            <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">Contract Management</span>
          </span>
        </a>
      </li>
      <li class="sidenav-nav-item" [hidden]="permissionService.CheckIfUserhasAccess_Section('Event Documents')">
        <a class="sidenav-nav-link" routerLink="/event/eventdocuments/list" routerLinkActive="active" (click)="closeSideNav()"  id="sidenavDocumentList"
                    [routerLinkActiveOptions]="{exact:true}">
          <span>
            <i class="sidenav-link-icon fas fa-file-alt" [class]="theme.sidenavIcon"  matTooltip="Document Center"></i>
            <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">Document Center</span>
          </span>
        </a>
      </li>
      <li class="sidenav-nav-item" [hidden]="permissionService.CheckIfUserhasAccess_Action('Deposit Schedule','Add') == false && permissionService.CheckIfUserhasAccess_Action('Deposit Schedule','Edit') == false">
        <a class="sidenav-nav-link" routerLink="/event/eventdueamount/list" routerLinkActive="active" (click)="closeSideNav()"  id="sidenavPaymentList"
                    [routerLinkActiveOptions]="{exact:true}">
          <span>
            <i class="sidenav-link-icon fas fa-clipboard-list" [class]="theme.sidenavIcon"  matTooltip="Payment Tracker"></i>
            <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">Payment Tracker</span>
          </span>
        </a>
      </li>
      <li class="sidenav-nav-item" [hidden]="permissionService.CheckIfUserhasAccess_Section('Settlement List')">
        <a class="sidenav-nav-link" routerLink="/settlement/list" routerLinkActive="active" (click)="closeSideNav()"  id="sidenavIncomeStatementList"
                    [routerLinkActiveOptions]="{exact:true}">
         <span>
          <i class="sidenav-link-icon fas fa-handshake" [class]="theme.sidenavIcon"  matTooltip="Income Statement"></i>
          <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">Income Statement</span>
         </span>
        </a>
      </li>


      <!-- Disable report module to publish teams app  -->
      <li class="sidenav-nav-item" *ngIf="!teamsAppService.isTeamsApp()">
        <a class="sidenav-nav-link" *ngIf="permissionService.showBuyButton() == true  "  (click)="upgradeBasicToPremium();closeSideNav()">
          <span>
            <i class="sidenav-link-icon far fa-calendar-check" [class]="theme.sidenavIcon"  matTooltip="Reports"></i>
            <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">Reports</span>
          </span>
          <mat-icon class="premium-badge" matTooltip="Premium Required">stars</mat-icon>
        </a>
        <a class="sidenav-nav-link" [hidden]="permissionService.CheckIfUserhasAccess('Event Reports') == true && permissionService.showBuyButton() == true" (click)="onExpand2()">
          <i class="sidenav-link-icon far fa-calendar-check" [class]="theme.sidenavIcon"  matTooltip="Reports"></i>
          <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">Reports</span>
          <i *ngIf="collapsed" class="menu-collapse-icon"
            [ngClass]="!expanded2 ? 'fas fa-angle-down' : 'fas fa-angle-up'"></i>
        </a>

        <ul *ngIf="collapsed" class="sublevel-nav" [@submenu]="expanded2 ? 
                    {value:'visible',params:{transitionParams:'400ms cubic-bezier(0.86, 0, 0.07, 1)',height:'*'}}
                     : {value:'hidden',
                        params:{transitionParams:'400ms cubic-bezier(0.86, 0, 0.07, 1)',height:'0'}
                     }">
          <li class="sublevel-nav-item">

            <a class="sublevel-nav-link" [hidden]="permissionService.CheckIfUserhasAccess_Section('Event Overview') == true " @fadeInOut routerLink="/report/event-overview" (click)="closeSideNav()"
              routerLinkActive="active-sublevel" [routerLinkActiveOptions]="{exact:true}">
              <i class="sublevel-link-icon fas fa-angle-right"></i>
              <span class="sublevel-link-text" *ngIf="collapsed">Event Overview</span>

            </a>

            <a *ngIf="permissionService.showBuyButton() == true  "  class="d-flex justify-content-between sublevel-nav-link "  @fadeInOut  (click)="upgradeBasicToPremium();closeSideNav()">
              <span>
                <i class="sublevel-link-icon fas fa-angle-right"></i>
                <span class="sublevel-link-text" *ngIf="collapsed">Event Overview</span>
              </span>
              <mat-icon class="premium-badge" matTooltip="Premium Required">stars</mat-icon>
            </a>

            <a class="sublevel-nav-link" @fadeInOut routerLink="/report/event-summary" [hidden]="permissionService.CheckIfUserhasAccess_Section('Event Summary') == true " (click)="closeSideNav()"
              routerLinkActive="active-sublevel" [routerLinkActiveOptions]="{exact:true}">
              <i class="sublevel-link-icon fas fa-angle-right"></i>
              <span class="sublevel-link-text" *ngIf="collapsed">Event Summary</span>
            </a>

            <a *ngIf="permissionService.showBuyButton() == true " (click)="upgradeBasicToPremium();closeSideNav()" @fadeInOut class=" sublevel-nav-link d-flex justify-content-between">
              <span>
                <i class="sublevel-link-icon fas fa-angle-right"></i>
                <mat-icon class="premium-badge" matTooltip="Premium Required">stars</mat-icon>
              </span>
              <!-- <span class="badge badge-pill badge-success buy-text"  *ngIf="collapsed">Premium</span> -->
            </a>

            <a class="sublevel-nav-link" [hidden]="permissionService.CheckIfUserhasAccess_Section('Event Highlights') == true "  @fadeInOut routerLink="/report/event-highlights" (click)="closeSideNav()"
              routerLinkActive="active-sublevel" [routerLinkActiveOptions]="{exact:true}">
              <i class="sublevel-link-icon fas fa-angle-right"></i>
              <span class="sublevel-link-text" *ngIf="collapsed">Event Highlights</span>
            </a>

            <a *ngIf="permissionService.showBuyButton() == true "  (click)="upgradeBasicToPremium();closeSideNav()" @fadeInOut  class=" sublevel-nav-link d-flex justify-content-between">
              <span>
                <i class="sublevel-link-icon fas fa-angle-right"></i>
                <span class="sublevel-link-text" *ngIf="collapsed">Event Highlights</span>
              </span>
              <mat-icon class="premium-badge" matTooltip="Premium Required">stars</mat-icon>
            </a>

            <a class="sublevel-nav-link" @fadeInOut routerLink="/report/dasboard-analytics" (click)="closeSideNav()"
              routerLinkActive="active-sublevel" [routerLinkActiveOptions]="{exact:true}">
              <i class="sublevel-link-icon fas fa-angle-right"></i>
              <span class="sublevel-link-text" *ngIf="collapsed">Ticket Sales</span>
            </a>

          </li>
        </ul>
      </li>

      <li class="sidenav-nav-item">

        <a class="sidenav-nav-link" (click)="onExpand3()" [hidden]="permissionService.CheckIfUserhasAccess('Admin Center') == true && permissionService.showBuyButton() == false">
          <i class="sidenav-link-icon fas fa-cogs" [class]="theme.sidenavIcon" matTooltip="Admin Center"></i>
          <span class="sidenav-link-text" @fadeInOut *ngIf="collapsed">Admin Center</span>
          <i *ngIf="collapsed" class="menu-collapse-icon"
            [ngClass]="!expanded3 ? 'fas fa-angle-down' : 'fas fa-angle-up'"></i>
        </a>

        <ul *ngIf="collapsed" class="sublevel-nav" [@submenu]="expanded3 ? 
                    {value:'visible',params:{transitionParams:'400ms cubic-bezier(0.86, 0, 0.07, 1)',height:'*'}}
                     : {value:'hidden',
                        params:{transitionParams:'400ms cubic-bezier(0.86, 0, 0.07, 1)',height:'0'}
                     }">
          <li class="sublevel-nav-item">

            <a class="sublevel-nav-link" @fadeInOut routerLink="/setup/contracttypes/list" [hidden]="permissionService.CheckIfUserhasAccess_Section('Contract Fields') == true  "  id="sidenavContractFieldList" (click)="closeSideNav()"
              routerLinkActive="active-sublevel" [routerLinkActiveOptions]="{exact:true}">
              <i class="sublevel-link-icon fas fa-angle-right"></i>
              <span class="sublevel-link-text" *ngIf="collapsed">Contract Fields</span>
            </a>

            <a *ngIf="permissionService.showBuyButton() == true  "  (click)="upgradeBasicToPremium();closeSideNav()" @fadeInOut class="d-flex justify-content-between sublevel-nav-link">
              <span>
                <i class="sublevel-link-icon fas fa-angle-right"></i>
                <span class="sublevel-link-text" *ngIf="collapsed">Contract Fields</span>
              </span>
              <mat-icon class="premium-badge" matTooltip="Premium Required">stars</mat-icon>
            </a>

            <a class="sublevel-nav-link" @fadeInOut routerLink="/event/contract/setup"  id="sidenavContractSetupList"
              *ngIf="permissionService.CheckIfUserhasAccess_Action('Contract Fields','Add') && permissionService.showBuyButton() == false " (click)="closeSideNav()"
              routerLinkActive="active-sublevel" [routerLinkActiveOptions]="{exact:true}">
              <i class="sublevel-link-icon fas fa-angle-right"></i>
              <span class="sublevel-link-text" *ngIf="collapsed">Contract Setup</span>
            </a>

            <a *ngIf="permissionService.showBuyButton() == true  "  (click)="upgradeBasicToPremium();closeSideNav()" @fadeInOut class="d-flex justify-content-between sublevel-nav-link">
              <span>
                <i class="sublevel-link-icon fas fa-angle-right"></i>
                <span class="sublevel-link-text" *ngIf="collapsed">Contract Setup</span>
              </span>
              <mat-icon class="premium-badge" matTooltip="Premium Required">stars</mat-icon>
            </a>

             <a class="sublevel-nav-link" [hidden]="permissionService.CheckIfUserhasAccess_Section('Contract Information')" @fadeInOut routerLink="/setup/contracts" (click)="closeSideNav()"
              routerLinkActive="active-sublevel" [routerLinkActiveOptions]="{exact:true}">
              <i class="sublevel-link-icon fas fa-angle-right"></i>
              <span class="sublevel-link-text" *ngIf="collapsed">Contract Templates</span>

            </a> 

            <a class="sublevel-nav-link" @fadeInOut routerLink="/setup/domain/list" (click)="closeSideNav()" [hidden]="permissionService.CheckIfUserhasAccess_Section('Domains')"  id="sidenavDomainsList"
              routerLinkActive="active-sublevel" [routerLinkActiveOptions]="{exact:true}">
              <i class="sublevel-link-icon fas fa-angle-right"></i>
              <span class="sublevel-link-text" *ngIf="collapsed">Domains</span>

            </a>

            <a class="sublevel-nav-link" @fadeInOut routerLink="/setup/venue/list" (click)="closeSideNav()" [hidden]="permissionService.CheckIfUserhasAccess_Section('Venue')"  id="sidenavVenueList"
              routerLinkActive="active-sublevel" [routerLinkActiveOptions]="{exact:true}">
              <i class="sublevel-link-icon fas fa-angle-right"></i>
              <span class="sublevel-link-text" *ngIf="collapsed">Venue</span>

            </a>
            <a class="sublevel-nav-link" @fadeInOut routerLink="/setup/eventstatus/list" (click)="closeSideNav()" [hidden]="permissionService.CheckIfUserhasAccess_Section('Event Status') == true  "  id="sidenavEventStatusList"
              routerLinkActive="active-sublevel" [routerLinkActiveOptions]="{exact:true}">
              <i class="sublevel-link-icon fas fa-angle-right"></i>
              <span class="sublevel-link-text" *ngIf="collapsed">Event Status</span>
            </a>

            <a *ngIf="permissionService.showBuyButton() == true  "  (click)="upgradeBasicToPremium();closeSideNav()" @fadeInOut class="d-flex justify-content-between sublevel-nav-link">
              <span>
                <i class="sublevel-link-icon fas fa-angle-right"></i>
                <span class="sublevel-link-text" *ngIf="collapsed">Event Status</span>
              </span>
              <mat-icon class="premium-badge" matTooltip="Premium Required">stars</mat-icon>
              <!-- <span  *ngIf="collapsed"><mat-icon class="premium-badge">  stars</mat-icon></span> -->

            </a>

            <a class="sublevel-nav-link" @fadeInOut routerLink="/setup/genre/list" [hidden]="permissionService.CheckIfUserhasAccess_Section('Genre') == true  " (click)="closeSideNav()"  id="sidenavGenreList"
              routerLinkActive="active-sublevel" [routerLinkActiveOptions]="{exact:true}">
              <i class="sublevel-link-icon fas fa-angle-right"></i>
              <span class="sublevel-link-text" *ngIf="collapsed">Genre</span>
            </a>

            <a *ngIf="permissionService.showBuyButton() == true  "  (click)="upgradeBasicToPremium();closeSideNav()" @fadeInOut class="d-flex justify-content-between sublevel-nav-link">
              <span>
                <i class="sublevel-link-icon fas fa-angle-right"></i>
                <span class="sublevel-link-text" *ngIf="collapsed">Genre</span>
              </span>
              <mat-icon class="premium-badge" matTooltip="Premium Required">stars</mat-icon>
              <!-- <span  *ngIf="collapsed"><mat-icon class="premium-badge">  stars</mat-icon></span> -->

            </a>

            <a class="sublevel-nav-link" @fadeInOut routerLink="/setup/bank/list" [hidden]="permissionService.CheckIfUserhasAccess_Section('Bank') == true  " (click)="closeSideNav()"  id="sidenavBankList"
              routerLinkActive="active-sublevel" [routerLinkActiveOptions]="{exact:true}">
              <i class="sublevel-link-icon fas fa-angle-right"></i>
              <span class="sublevel-link-text" *ngIf="collapsed">Bank</span>
            </a>

            <a *ngIf="permissionService.showBuyButton() == true  "  (click)="upgradeBasicToPremium();closeSideNav()"  @fadeInOut class="d-flex justify-content-between sublevel-nav-link">
              <span>
                <i class="sublevel-link-icon fas fa-angle-right"></i>
                <span class="sublevel-link-text" *ngIf="collapsed">Bank </span>
              </span>
              <mat-icon class="premium-badge" matTooltip="Premium Required">stars</mat-icon>
              <!-- <span  *ngIf="collapsed">Premium<mat-icon class="premium-badge">  stars</mat-icon></span> -->

            </a>

            <a class="sublevel-nav-link" @fadeInOut routerLink="/setup/holiday/list" (click)="closeSideNav()" [hidden]="permissionService.CheckIfUserhasAccess_Section('Holiday') == true  "  id="sidenavHolidayList"
              routerLinkActive="active-sublevel" [routerLinkActiveOptions]="{exact:true}">
              <i class="sublevel-link-icon fas fa-angle-right"></i>
              <span class="sublevel-link-text" *ngIf="collapsed">Holidays</span>
            </a>

            <a *ngIf="permissionService.showBuyButton() == true  "  (click)="upgradeBasicToPremium();closeSideNav()" @fadeInOut class="d-flex justify-content-between sublevel-nav-link">
              <span>
                <i class="sublevel-link-icon fas fa-angle-right"></i>
                <span class="sublevel-link-text" *ngIf="collapsed">Holidays </span>
              </span>
              <mat-icon class="premium-badge" matTooltip="Premium Required">stars</mat-icon>
              <!-- <span  *ngIf="collapsed"><mat-icon class="premium-badge">star</mat-icon></span> -->

            </a>

            <a class="sublevel-nav-link" @fadeInOut routerLink="/setup/settlementgroup/list" (click)="closeSideNav()" [hidden]="permissionService.CheckIfUserhasAccess_Section('Settlement Group') == true  "  id="sidenavGLGroupList"
              routerLinkActive="active-sublevel" [routerLinkActiveOptions]="{exact:true}">
              <i class="sublevel-link-icon fas fa-angle-right"></i>
              <span class="sublevel-link-text" *ngIf="collapsed">GL Groups</span>
            </a>

            <a *ngIf="permissionService.showBuyButton() == true  "  (click)="upgradeBasicToPremium();closeSideNav()"  @fadeInOut class="d-flex justify-content-between sublevel-nav-link">
              <span>
                <i class="sublevel-link-icon fas fa-angle-right"></i>
                <span class="sublevel-link-text" *ngIf="collapsed">GL Groups </span>
              </span>
              <mat-icon class="premium-badge" matTooltip="Premium Required">stars</mat-icon>
              <!-- <span  *ngIf="collapsed"><mat-icon class="premium-badge">star</mat-icon></span> -->

            </a>

            <a class="sublevel-nav-link" @fadeInOut routerLink="/setup/settlementglaccount/list" (click)="closeSideNav()" [hidden]="permissionService.CheckIfUserhasAccess_Section('Settlement GL Account') == true  "  id="sidenavGLAccountList"
              routerLinkActive="active-sublevel" [routerLinkActiveOptions]="{exact:true}">
              <i class="sublevel-link-icon fas fa-angle-right"></i>
              <span class="sublevel-link-text" *ngIf="collapsed">GL Accounts</span>
            </a>

            <a *ngIf="permissionService.showBuyButton() == true  "  (click)="upgradeBasicToPremium()"  @fadeInOut class="d-flex justify-content-between  sublevel-nav-link">
              <span>
                <i class="sublevel-link-icon fas fa-angle-right"></i>
                <span class="sublevel-link-text" *ngIf="collapsed">GL Accounts </span>
              </span>
              <mat-icon class="premium-badge" matTooltip="Premium Required">stars</mat-icon>
              <!-- <span  *ngIf="collapsed"><mat-icon class="premium-badge">  stars</mat-icon></span> -->
            </a>

            <a class="sublevel-nav-link" @fadeInOut routerLink="/rbac/rbacuser/list" (click)="closeSideNav()" [hidden]="permissionService.CheckIfUserhasAccess_Section('Users')"  id="sidenavUserList"
              routerLinkActive="active-sublevel" [routerLinkActiveOptions]="{exact:true}">
              <i class="sublevel-link-icon fas fa-angle-right"></i>
              <span class="sublevel-link-text" *ngIf="collapsed">User</span>
            </a>

            <!-- <a class="sublevel-nav-link" @fadeInOut routerLink="/rbac/rbacgroup/list" (click)="closeSideNav()" [hidden]="permissionService.CheckIfUserhasAccess_Section('Group')"
              routerLinkActive="active-sublevel" [routerLinkActiveOptions]="{exact:true}">
              <i class="sublevel-link-icon fas fa-angle-right"></i>
              <span class="sublevel-link-text" *ngIf="collapsed">Group</span>
            </a>

            <a class="sublevel-nav-link" @fadeInOut routerLink="/rbac/rbacpermission/list" (click)="closeSideNav()" [hidden]="permissionService.CheckIfUserhasAccess_Section('Permission')"
              routerLinkActive="active-sublevel" [routerLinkActiveOptions]="{exact:true}">
              <i class="sublevel-link-icon fas fa-angle-right"></i>
              <span class="sublevel-link-text" *ngIf="collapsed">Permission</span>
            </a> -->

          </li>
        </ul>
      </li>

    </ul>
  </div>

  <li class="upgrade-btn" [hidden]="!collapsed" @fadeInOut  *ngIf="(permissionService.showBuyButton() == true ) || ((user?.Subscription == 'premium' || user?.Subscription == 'organization') && isOwner == true)" >
    
    <button mdbBtn type="button" [class]="theme.button" rounded="true" style="padding: 8px 21px;font-weight: 700;" mdbWavesEffect 
      *ngIf="permissionService.showBuyButton() == true " (click)="upgradeBasicToPremium();closeSideNav()">
      <mdb-icon fas icon="chess-queen" class="mr-1" style="font-size: 14px;color: #fff;" ></mdb-icon>  Upgrade
    </button>

    <button mdbBtn type="button" [class]="theme.button" rounded="true" style="padding: 8px 21px;font-weight: 700;" mdbWavesEffect 
      *ngIf="(user?.Subscription == 'premium' || user?.Subscription == 'organization') && isOwner == true" (click)="upgradetoenterprise();closeSideNav()">
      <mdb-icon fas icon="chess-queen" class="mr-1" style="font-size: 14px;color: #fff;" ></mdb-icon>  Upgrade
    </button>



  </li>

  <li class="sidenav-footer accordian-heading" *ngIf="collapsed" @fadeInOut >
    <span class="" style="color: #1d1d1e; font-size:11px;">
      Product By
      <a href="https://www.alrafayglobal.com/" target="_blank"
        style="color: #095e8f;font-size: 13px;display: inline;padding: 0;font-weight: 500;">
        Al Rafay Consulting
      </a>
    </span>
  </li>
</div>