import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginUser } from 'src/app/Base/User/login-user';
import { TokenService } from 'src/app/services/token.service';
import { AuthenticationService } from '../../../helpers/authentication.service';
import { AccessPermissionService } from '../../../services/rbac/access-permission.service';

@Component({
  selector: 'app-request-access',
  templateUrl: './request-access.component.html',
  styleUrls: ['./request-access.component.scss']
})
export class RequestAccessComponent implements OnInit {


  successMessage:string = "";
  IsshowProgress:boolean = false;
  constructor(
    public accessPermissionService:AccessPermissionService,
    public  authenticationService:AuthenticationService,
    public tokenService:TokenService,
    public router:Router,
  ) { }

  ngOnInit(
    
  ): void {

  }

  requestAccess(){
    
    var currUser = this.authenticationService.CurrentUser();
    if(currUser == null)
      return;

    this.IsshowProgress = true;



    var obj = {
      RequestedBy: currUser.Name,
      email: currUser.email,
      ownerId: currUser.OwnerId,
      tenantId: currUser.tid    
    }

    this.accessPermissionService.requestAccess(obj).toPromise().then(a => {
      this.successMessage = "Your request is successfully sent!";
      this.IsshowProgress = false;
    }, error =>{
      alert("Something went wrong")
      this.IsshowProgress = false;
    })

  }

  logout() {
    this.authenticationService.logout();
    return;
    this.tokenService.removeToken();
    LoginUser.loggedinUser.UserID = null;
    LoginUser.loggedinUser.UserName = null;
    LoginUser.loggedinUser.Email = null;  
    LoginUser.loggedinUser.ShortCode = null; 
    LoginUser.IsAdmin = false;    
    this.authenticationService.logout();
    this.router.navigate(['/calendar']);
    //this.authService.logout();

    /*
    this.genService.logout().then(res => {      
      this.tokenService.removeToken();
        LoginUser.loggedinUser.UserID = null;
        LoginUser.loggedinUser.UserName = null;
        LoginUser.loggedinUser.Email = null;  
        LoginUser.loggedinUser.ShortCode = null; 
        LoginUser.IsAdmin = false;    
      //this.authenticate.user =  new RBAC_User();
      //this.authenticate.isLoggedIn = false;
      // this.router.navigate(['/login']);
      //this.router.navigate(['/app/home']);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/login'])
      );
    }, error => {
      console.log(error);
    })
    */
  }

  login(){
    this.router.navigate(['/calendar']);
  }

}
