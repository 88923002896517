
import {Event} from '../event-info/Event.model';

export class EventNotes{

    ID:number = 0; 
    EventID :number = null;
    Notes:string = null;
    CreatedBy:number=null;
    CreatedDate:Date = null;
    ModifiedBy:number = null;
    ModifiedDate:Date = null;
    Event: Event=null;

}
