import { Contactperson } from './contactperson.model';

export class Client {
    ID:number = 0;
    ClientName :string = null;
    City :string = null;
    State :string = null;
    ZipCode :string = null;
    Country :string = null;
    StreetAddress :string = null;
    Phone :string = null;
    SecondaryContact:string = null;
    Fax :string = null;
    Email :string = null;
    Website :string = null;
    IsActive :boolean = null; 
    CreatedBy :number = null;
    CreatedDate:Date = null;
    ModifiedBy :number = null;
    ModifiedDate :Date = null; 
    Client_ContactPerson:Contactperson[] = [];   
    
}

