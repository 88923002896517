<div class="container-fluid">
        <div class="row">
                <div class="col-md-7 hideDesktop autoHeight" style="background-color: #40a9e6; min-height: 100vh;">
                        <div class="leftMidContainer">
                        <img src="assets/venue_white.png" alt="VenueArc" width="220px">
                        <div class="welcomeHeading"> 
                                <h1 id="h1WelcomeHead">Welcome to VenueArc</h1>
                        </div>

                        <div class="paraHeading"> 
                               <h4> Ready to take your venue booking and event management to the next level? </h4>
                        </div>

                        <div class="signupHeading">
                                <h4>Sign up now!</h4>
                        </div>

                        <div class="d-flex justify-content-center">
                                <img src="assets/1.png" alt=".." class="midVector">
                        </div>
                        <img src="assets/2.png" alt=".." class="fixedBotImage">

                        </div>
                </div>
                <div class="col-md-5 noPaddinginMob" *ngIf="!IsSaved">
                       
                      <div class="overlayForMob">
                        <div class="leftMidContainer">
                                <div class="d-flex justify-content-center mt-3 mb-4 hideMobile">
                                        <img src="assets/venuearc_logo.png" width="250px" alt="venueArc">
                                </div>

                                <div class="d-flex justify-content-center mt-3 mb-4">
                                        <h2 id="loginHeading" class="smallText"> Sign Up from</h2>
                                </div>

                               

                                <div class="col-12 socialIDPFlex d-flex justify-content-between align-items-end p-4">
                                <a class="d-flex flex-column align-items-center socialAccLink" (click)="navigateMarketPlaceSignUp()">
                                        <img class="mb-2 myWidth" src="assets/3.png" alt="Azure" width="70px">
                                        <h5 class="moreSmaller">Azure MarketPlace</h5>

                                </a>

                                <div id="verticalLine">
                                </div>


                                <a class="d-flex flex-column align-items-center socialAccLink" (click)="subscribeAS()">
                                        <img class="mb-2 myWidth" src="assets/4.png" alt="Azure" width="50px">
                                        <h5 class="moreSmaller">Microsoft App Source</h5>

                                </a>
                                </div>
   
                                <p id="textSeprator"><span>Or Register with</span></p>
                                     
                                <div class="inputDiv px-5 py-4" >
                                
                                        <div class="mb-4">
                                                <input class="form-control myInput " type="text" placeholder="First Name*" aria-label="def" required
                                                        name="FirstName" #FirstName="ngModel" [(ngModel)]="obj.FirstName" maxlength="50">
                                                <mat-error *ngIf="FirstName.invalid && (FirstName.dirty || FirstName.touched)" style="padding-left: 26px;">This field is required</mat-error>
                                        </div>
                                
                                        <div class="mb-4">
                                                <input class="form-control myInput  " type="text" placeholder="Last Name*" aria-label="def" required
                                                        name="LastName" #LastName="ngModel" [(ngModel)]="obj.LastName" maxlength="50">
                                                <mat-error *ngIf="LastName.invalid && (LastName.dirty || LastName.touched)" style="padding-left: 26px;">This field is required</mat-error>
                                        </div>
                                
                                        <div class="mb-4">
                                                <input class="form-control myInput  " type="text" placeholder="Organization/Company Name*"
                                                        aria-label="def" required name="OrganisationName" #OrganisationName="ngModel"
                                                        [(ngModel)]="obj.OrganisationName"  maxlength="100">
                                                <mat-error *ngIf="OrganisationName.invalid && (OrganisationName.dirty || OrganisationName.touched)" style="padding-left: 26px;">This field is required</mat-error>
                                        </div>
                                
                                        <div class="mb-2">
                                                <input class="form-control myInput  " type="text" placeholder="Email*" aria-label="def" required                                                        
                                                        name="Email" #Email="ngModel" [(ngModel)]="obj.Email"
                                                        pattern="^\w+([\.-]?\w+)*\+?\d*@\w+([\.-]?\w+)*(\.\w{2,3})+$">

                                                        <!-- <input class="form-control myInput  " type="text" placeholder="Email*" aria-label="def" required
                                                        name="Email" #Email="ngModel" [(ngModel)]="obj.Email"> -->
                                                <mat-error *ngIf="Email.errors && Email.errors.required && (Email.dirty || Email.touched)" style="padding-left: 26px;">This field is required</mat-error>
                                                <mat-error *ngIf="Email.errors  && Email.errors.pattern" style="padding-left: 26px;">Email is invalid</mat-error>
                                        </div>


                                        <div class="p-2 agreed-terms" style="display: flex;align-items: center;">
                                                <mat-checkbox color="primary" class="example-margin moreSmaller" class="p-2" [(ngModel)]="checkedTermsConditions">I agree to
                                                        all the statements in </mat-checkbox>
                                                <label for="" class="label-checkbox"><u (click)="openTermsAndConditions()"> Terms of Services </u></label>
                                        </div>
                                </div>


                                <div class="d-flex justify-content-center" >
                                        <button class="signUpButton" type="button" [disabled]="validate(Email)" (click)="Save()"> 
                                                Sign Up
                                        </button>
                                </div>

                                <p class="text-center mt-5" >Already have an account? <a href="https://portal.venuearc.com"> Sign in </a></p>
                               
                                
                        </div>
                      </div>  

                </div>

                <div class="col-md-5 noPaddinginMob d-flex flex-column align-items-center justify-content-center" *ngIf="IsSaved">
                       <mat-icon style="    font-size: 100px;
                       padding-bottom: 142px;
                       color: green;
                       width:auto;">check_circle</mat-icon>
                        <h4 class="mb-3">Thanks for signing up!</h4>
                        <h6 class="text-center">We have sent an email to your account: {{obj.Email}} for confirmation.</h6>
                        <h6>Check you inbox and confirm account.</h6>

                        <img src="assets/afterSingup.png" alt="" style="height: 200px;width: 250px;">
                  </div>
        </div>
</div>
