import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { MDBModalRef, MDBModalService, ToastService } from 'ng-uikit-pro-standard';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/Base/Common.service';
import { Table } from 'src/app/Base/Table';
import { LoginUser } from 'src/app/Base/User/login-user';
import { ConfirmDialogeService } from 'src/app/confirm-dialoge/confirm-dialoge.service';
import { ToastType } from 'src/app/Enum/ToastType';
import { MessageDialogeService } from 'src/app/message-dialoge/message-dialoge.service';
import { ConfFilters } from 'src/app/models/conf-filters.model';
import { EventStatus } from 'src/app/models/EventStatus.model';
import { Event } from 'src/app/modules/event/event-info/Event.model';
import { EventListService } from 'src/app/modules/event/event-list/event-list.service';
import { EventNotesService } from 'src/app/modules/event/event-notes/event-notes.service';
import { EventNotesComponent } from 'src/app/modules/event/event-notes/event-notes/event-notes.component';
import { EventFuncService } from 'src/app/modules/event/EventFuncService.service';
import { GeneralService } from 'src/app/services/general.service';
import { GenericService } from 'src/app/services/generic.service';
import { SPOperationsService } from 'src/app/services/spoperations.service';
import { themeService } from 'src/app/services/theme.service';
import { EventStatusService } from 'src/app/setupForms/event-status/event-status.service';
import { VenueService } from 'src/app/setupForms/venue/venue.service';
import { CalendarService } from '../calendar-service.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-calendar-list',
  templateUrl: './calendar-list.component.html',
  styleUrls: ['./calendar-list.component.scss']
})
export class CalendarListComponent extends Table implements OnInit {

  // displayedColumns: string[] = ['actions'];
  displayedColumns: string[] = ['actions','ID', 'EventName', 'EventStartDate', 'EventEndDate', 'AllStatus', 'VenueName', 'ModifiedDate', 'ModifiedByName'];
  VenueID: number[] = [14];
  EventStatusID: number[] = [];
  columns:any[]=[
    {name:'actions',header:'',type:''},
    {name:'ID',header:'Event ID',type:'text'},
    {name:'EventName',header:'Event Name',type:'text'},
    {name:'EventStartDate',header:'Start Date',type:'date'},
    {name:'EventEndDate',header:'End Date',type:'date'},
    {name:'ClientName',header:'Client',type:'text'},
    {name:'AllStatus',header:'Status',type:'text'},
    {name:'VenueName',header:'Venue',type:'text'},
    {name:'ModifiedDate',header:'Modified Date',type:'date'},
    {name:'ModifiedByName',header:'Modified By',type:'text'},
  ];

  public modalRef: MDBModalRef;
  public portaldata: any[] = [];
  public linkDocument:string =null;
  FilterDate: Date = new Date();
  viewDate: Date = new Date();
  refresh: Subject<any> = new Subject();
  groupEventStatus: EventStatus[] = [];
  public selectedClients: number[] = [];
  
  constructor(
    private modal: NgbModal,
    public sp: SPOperationsService,
    public spinner: NgxSpinnerService,
    public toast: ToastService,
    public service: GenericService,
    public dialog: ConfirmDialogeService,
    public calendarService: CalendarService,
    public genService: GeneralService,
    public router: Router,
    public route:ActivatedRoute,
    public common: CommonService,
    public eventListService:EventListService,
    public eventFunc:EventFuncService,
    public modalService: MDBModalService,
    public venueService:VenueService,
    public eventStatusService:EventStatusService,
    public eventNotesService: EventNotesService,
    public permissionService:PermissionService,
    public messageDialog?: MessageDialogeService,
    public matDialog?: MatDialog,
    public theme?:themeService,
  ) {
    super(router)
   }

  ngOnInit(): void {
    this.getListData(moment('2023-02-01').format('YYYY-MM-DD'),moment('2023-02-28').format('YYYY-MM-DD'))
  }


  showRowData(row:any,column:any){
    return row !=null ? row[column] : '';
  }
  data:any[]=[];


  getListData(startDate, endDate) {
    this.common.ShowSpinner();

    var allSelectedVenueID = "";
    for (let index = 0; index <  this.VenueID.length; index++) {
      const element =  this.VenueID[index];
      allSelectedVenueID += element.toString() ;
      if ( index < this.VenueID.length-1 )
        allSelectedVenueID += ","
    }
  
    var allSelectedStatusID = "";
    // for (let index = 0; index < this.EventStatusID.length; index++) {
    //   const element = this.EventStatusID[index];
    //   allSelectedStatusID += element.toString() ;
    //   if ( index < this.EventStatusID.length -1)
    //      allSelectedStatusID += ","
    // }

     

    // var allSelectedClientID = "";
    // for (let index = 0; index < this.selectedClients.length; index++) {
    //   const element = this.selectedClients[index];
    //   allSelectedClientID += element.toString() ;
    //   if ( index < this.selectedClients.length -1)
    //   allSelectedClientID += ","
    // }

   this.eventListService.getEventList(startDate,endDate,allSelectedVenueID,allSelectedStatusID, '').then((res:any) => {
      this.listData = new MatTableDataSource(res['value']);
      this.listData.sort = this.sort;
      this.data = res['value'];
      this.listData.paginator = this.paginator;
      // this.ExportData = res['value'];

      this.common.HideSpinner();
    });
  }


  getEventPortalData(row) {
    this.portaldata = [];
    this.eventFunc.getEventPortalPrimaryKeys(row.ID).then(res => {
      this.portaldata = res['value'];
    });

    this.GetDocumentLink(row);
  }

  async GetDocumentLink(row){
    this.linkDocument = await this.eventFunc.GetDocumentLink(row.ID, row.EventName, row.EventStartDate, row.VenueName);      
  }

  onEdit(gen: Event) {
    //this.router.navigate(['/event'], { queryParams: { ID: gen.ID } })
    this.router.navigate(['/event/dashboard'], { queryParams: { EventID: gen.ID } })
  }

  OpenEventNotesModal(ID) {
    
    this.modalRef = this.modalService.show(EventNotesComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: true,
      class: 'modal-xl modal-content modal-dialog cascading-modal modal-top',
      containerClass: '',
      animated: true,
      styles: "overflow-y: auto",
      data: {
        eventID: ID
      }
    });

    this.modalRef.content.action.subscribe((result: any) => {
      
    })
  }

 
}
