import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from './material/material.module'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NO_ERRORS_SCHEMA } from '@angular/core';
import { AppRoutingModule, RoutingComponent, EntryComponent } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { AppComponent, UserProviderFactory } from './app.component';
import { MDBSpinningPreloader, MDBBootstrapModulesPro, ToastModule, AutoCompleterModule, InputsModule, MdbSelectModule, StickyHeaderModule, NavbarModule  } from 'ng-uikit-pro-standard';
import { GenreService } from './services/genre.service';
import { GeneralService } from './services/general.service';
import { GenericService } from './services/generic.service';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ConfirmDialogeComponent } from './confirm-dialoge/confirm-dialoge.component';

import { CommonService } from './Base/Common.service';
import { MatButtonModule } from '@angular/material/button';
import { MessageDialogeComponent } from './message-dialoge/message-dialoge.component';

import { SptermsetService } from './services/sptermset.service';
import { SortPipe } from './Base/SortPipe';

import { UserService } from './Base/User/user.service';

import { EventHelpModalComponent } from './modules/event/event-help-modal/event-help-modal/event-help-modal.component';
import { RBACModuleAccessDirective } from './base/rbacmodule-access.directive';
import { EventFuncService } from './modules/event/EventFuncService.service';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ApplicationPipesModule } from './Base/AppPipesModule';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AuthGuardServiceService } from './Base/AuthGuard/auth-guard-service.service';
import { PageNotFoundComponent } from './Base/AuthGuard/page-not-found/page-not-found.component';
import { NoPageAccessComponent } from './Base/AuthGuard/no-page-access/no-page-access.component';

import {StimulsoftViewerModule} from 'stimulsoft-viewer-angular';
import { LandingPageComponent } from './modules/main/landing-page/landing-page.component';
import { LoginComponent } from './modules/main/login/login.component';
import { SignupComponent } from './modules/main/signup/signup.component';

import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig, protectedResources } from './auth-config';


import { SecurityComponent } from './modules/main/security/security.component'
import { Adb2cPostProcessor } from './helpers/adb2c-post-processor';
import { HomeComponent } from './home/home/home.component';
import { ProfileComponent } from './home/profile/profile.component';
import { WebapiComponent } from './webapi/webapi.component';
import {  HTTP_INTERCEPTORS } from '@angular/common/http';
import { RegisterCustomerComponent } from './modules/main/register-customer/register-customer.component';

import { HttpConfigInterceptor } from './services/http-config.interceptor';

//import { ExportComponent } from './Base/Export/export.component';

//import { NgxMatColorPickerModule, MAT_COLOR_FORMATS, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';



import * as customConfig from 'src/app/customConfig.json';
import { AlreadyLoggedInComponent } from './Base/AuthGuard/already-logged-in/already-logged-in.component';
import { ConfirmReplaceDialogeComponent } from './confirm-replace-dialoge/confirm-replace-dialoge.component';
import { TermsAndConditionsComponent } from './modules/main/terms-and-conditions/terms-and-conditions.component';
import { MarketPlaceComponent } from './modules/main/market-place/market-place.component';
import { SessionTimeoutComponent } from './modules/main/session-timeout/session-timeout.component';
import { SelectTenantComponent } from './modules/main/select-tenant/select-tenant.component';
import { SelectSignupComponent } from './modules/main/select-signup/select-signup.component';
import { RequestSubscriptionComponent } from './modules/main/request-subscription/request-subscription.component';
import { RequestAccessComponent } from './modules/main/request-access/request-access.component';
import { ApproveAccessComponent } from './modules/main/approve-access/approve-access.component';
import { OnBoardingOrganizationComponent } from './modules/on-boarding-venue/on-boarding-organization/on-boarding-organization.component';
import { MaintainenceComponent } from './modules/main/maintainence/maintainence.component';
import { RequestEnterpriseComponent } from './modules/main/request-enterprise/request-enterprise.component';
import { OnBoardingUserComponent } from './modules/on-boarding-venue/on-boarding-user/on-boarding-user.component';
import { BoardingAddUserComponent } from './modules/on-boarding-venue/boarding-add-user/boarding-add-user.component';
import { OnBoardingClientComponent } from './modules/on-boarding-venue/on-boarding-client/on-boarding-client.component';
import { OnBoardingSignupComponent } from './modules/on-boarding-venue/on-boarding-signup/on-boarding-signup.component';
import { WelcomePageComponent } from './modules/main/welcome-page/welcome-page.component';
import { SsoComponent } from './modules/main/sso/sso.component';
import { CustomersService } from './services/customers.service';
import { RegisterAccountComponent } from './modules/main/register-account/register-account.component';

import { SignupRedirectComponent } from './modules/main/signup-redirect/signup-redirect.component';

import { SignupNewComponent } from './modules/main/signup-new/signup-new.component';
import { AuditLogComponent } from './modules/main/audit-log/audit-log.component';
import { CalendarDashboardComponent } from './calendar/calendar-dashboard/calendar-dashboard.component';
import { CalendarViewComponent } from './calendar/calendar-view/calendar-view.component';
import { DashboardPopupComponent } from './calendar/dashboard-popup/dashboard-popup.component';
import { SsoRedirectComponent } from './modules/main/sso-redirect/sso-redirect.component';
import { TeamsSigninComponent } from './modules/main/teams-signin/teams-signin.component';
import { LogoutComponent } from './modules/main/logout/logout.component';
import { SignInUserComponent } from './modules/main/sign-in-user/sign-in-user.component';
import { CalendarListComponent } from './calendar/calendar-list/calendar-list.component';
//import { AvailReportComponent } from './modules/event/reports/avail-report/avail-report.component';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';
import { RouterModule } from '@angular/router';
import { RequestPermiumFromBasicComponent } from './modules/main/request-permium-from-basic/request-permium-from-basic.component';
import { NewSigninComponent } from './modules/main/new-signin/new-signin.component';

import { TrialExpiredComponent } from './modules/main/trial-expired/trial-expired.component';
import { LandingPageNewComponent } from './modules/main/landing-page-new/landing-page-new.component';
import { SideBarComponent } from './modules/shared/navbar/side-bar/side-bar.component';
import { TopBarComponent } from './modules/shared/navbar/top-bar/top-bar.component';
import { SublevelMenuComponent } from './modules/shared/navbar/side-bar/sublevel-menu.component';

import { NewOnboardDesComponent } from './modules/main/new-onboard-des/new-onboard-des.component';
import { NgxImageCompressService } from 'ngx-image-compress-legacy';



//import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;



@NgModule({
  declarations: [
    AppComponent,
    RoutingComponent,
    ConfirmDialogeComponent,
    MessageDialogeComponent,
    EventHelpModalComponent,
    RBACModuleAccessDirective,
    PageNotFoundComponent,
    NoPageAccessComponent,
    LandingPageComponent,
    LoginComponent,
    SignupComponent,
    SecurityComponent,
    HomeComponent,
    ProfileComponent,
    WebapiComponent,
    RegisterCustomerComponent,
    AlreadyLoggedInComponent,
    ConfirmReplaceDialogeComponent,
    TermsAndConditionsComponent,
    MarketPlaceComponent,
    SessionTimeoutComponent,
    SelectTenantComponent,
    SelectSignupComponent,
    RequestSubscriptionComponent,
    RequestAccessComponent,
    ApproveAccessComponent,
    OnBoardingOrganizationComponent,
    MaintainenceComponent,
    RequestEnterpriseComponent,
    OnBoardingUserComponent,
    BoardingAddUserComponent,
    OnBoardingClientComponent,
    OnBoardingSignupComponent,
    WelcomePageComponent,
    SsoComponent,
    RegisterAccountComponent,
    SignupRedirectComponent,
    SignupNewComponent,
    AuditLogComponent,
    CalendarDashboardComponent,
    CalendarViewComponent,
    DashboardPopupComponent,
    SsoRedirectComponent,
    TeamsSigninComponent,
    LogoutComponent,
    SignInUserComponent,
    CalendarListComponent,
    RequestPermiumFromBasicComponent,
    NewSigninComponent,
    TrialExpiredComponent,
    LandingPageNewComponent,
    SideBarComponent,
    TopBarComponent,
    SublevelMenuComponent,    
    NewOnboardDesComponent,    
   // AvailReportComponent


    //ExportComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    MaterialModule,
    MatButtonModule,
    BrowserAnimationsModule,
    FormsModule,
    NgbModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
  }),
    BsDatepickerModule.forRoot(),
    NgxSpinnerModule,
    NgxMaterialTimepickerModule,
    HttpClientModule,
    ToastModule.forRoot(),
    MdbSelectModule,
    AutoCompleterModule,
    InputsModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
    StickyHeaderModule,
    NavbarModule ,
    DataTablesModule,
    AgmCoreModule.forRoot({
      apiKey: 'Your_api_key'
    }),
    NgxMaskModule.forRoot(),
    // RouterModule,
    GuidedTourModule.forRoot(),
    ApplicationPipesModule,
    AppRoutingModule,
    StimulsoftViewerModule ,
    //NgxMatColorPickerModule
    // Initiate the MSAL library with the MSAL configuration object
    MsalModule.forRoot(new PublicClientApplication(msalConfig),
    {
      // The routing guard configuration.
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: protectedResources.todoListApi.scopes,
        //domainHint: "login.microsoftonline.com"
      }
    },
    {
      // MSAL interceptor configuration.
      // The protected resource mapping maps your web API with the corresponding app scopes. If your code needs to call another web API, add the URI mapping here.
      interactionType: InteractionType.Redirect,
      protectedResourceMap: new Map([
        [customConfig.api + "api/CustomersNew/Post", null],
        [customConfig.api + "api/UserADB2CNew", null],
        [customConfig.api + "api/MarketPlaceAccessTokenController", null],
        [customConfig.api + "api/SaasFulfilmentController", null],
        [customConfig.api + "api/CustomersTenant/GetTenants", null],
        [protectedResources.todoListApi.endpoint, protectedResources.todoListApi.scopes]
      ])
    })
  /* Changes end here. */
  ],
  entryComponents: [EntryComponent],
  providers: [
    MDBSpinningPreloader,
    GenreService,
    NgxImageCompressService,
    GuidedTourService,
    GeneralService,
    GenericService,
    CommonService,
    SptermsetService,
    EventFuncService,
    UserService,
    SortPipe,
    { provide: APP_INITIALIZER, useFactory: UserProviderFactory, deps: [UserService], multi: true, },
    AuthGuardServiceService,

     /* Changes start here. */
     
    //  {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: MsalInterceptor,
    //   multi: true
    // },
    // MsalGuard,
    
    /* Changes end here. */

    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }

    // {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}}
  ],
  bootstrap: [
    AppComponent,
    /* Changes start here. */
    MsalRedirectComponent
  /* Changes end here. */],
   schemas: [NO_ERRORS_SCHEMA]
})

export class AppModule { }
