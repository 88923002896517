import { Component, OnInit } from '@angular/core';
import { MDBModalRef,ToastService } from 'ng-uikit-pro-standard';
import { Event } from 'src/app/modules/event/event-info/Event.model';
import { Event_SubEvent } from 'src/app/modules/event/event-info/Event_SubEvent.model';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';
import { GenericService } from 'src/app/services/generic.service';
import { NgxSpinnerService } from "ngx-spinner";
import { GeneralService } from 'src/app/services/general.service';
import { EventStatus } from 'src/app/models/EventStatus.model';
import { eventType } from 'src/app/models/eventType.model';
import { EnEventStatus } from 'src/app/Enum/Enums';
import { BLBase } from 'src/app/Base/BLBase/BLBase.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogeService } from 'src/app/confirm-dialoge/confirm-dialoge.service';
import { ToastType } from 'src/app/Enum/ToastType';
import { CommonService } from 'src/app/Base/Common.service';
import { themeService } from 'src/app/services/theme.service';


@Component({
  selector: 'app-event-help-modal',
  templateUrl: './event-help-modal.component.html',
  styles: []
})
export class EventHelpModalComponent implements OnInit {
  formData: Event_SubEvent = new Event_SubEvent();
  action: Subject<any> = new Subject<any>();
  eventStatus: EventStatus[] = [];
  eventStatus_SubEvent: EventStatus[] = [];
  eventStatus_WhenContracted: EventStatus[] = [];
  subEventType: eventType[] = [];
  eventID :number= 0;
  IsDiscountApplied:boolean=null;
  SubEventData:Event_SubEvent[] = [];
  BuklPorformances:any[] = [];
  currencySymbol:string="$";

  constructor(public modalRef: MDBModalRef,
    public toast: ToastService,
    public common:CommonService,
    public genService:GeneralService,
    public theme:themeService
    ) {
  }

  ngOnInit(): void {
    Promise.all([
     // this.FillSelect("EventTypes", "EventTypes"),
      this.FillSelect("EventStatus", "EventStatus?$filter=LevelName ne 'Group'&$orderby=EventStatus")
    ])

    this.SubEventData.forEach(element => {

      //if(element.IsSettlement != true)
      {
        this.BuklPorformances.push({
          "PerformanceID" : element.ID,
          "PerformanceDate":element.SubEventDate,
          "IsSettlement":element.IsSettlement,
          "IsDeleted":element.IsDeleted,
          "LineID":element.LineID,
          "EventStatusID":element.EventStatusID,
          "StandardLicenseFee":element.StandardLicenseFee,
          "DiscountedLicenseFee":element.DiscountedLicenseFee,
          "IsSelected":true,

        })
      }
    });
  }

  ngAfterViewInit(){
    
  }
  async FillSelect(selectName: string, query: string) {
    await this.genService.GetDataByQuery(query).then(
      res => {
        switch (selectName) {
          case "EventStatus": {
            this.eventStatus = res['value'] as EventStatus[];
            this.eventStatus_SubEvent = res['value'] as EventStatus[];
            var eStatus = res['value'] as EventStatus[];


            eStatus.forEach(element => {

              if (element.ID == EnEventStatus.Deleted || element.ID == EnEventStatus.Cancel) {
                var es = new EventStatus();
                es.ID = element.ID;
                es.EventStatus = element.EventStatus;
                es.DESCRPTION = element.DESCRPTION;
                es.Color = element.Color;

                this.eventStatus_WhenContracted.push(es);
              }
            });

            break;
          }
          case "EventTypes": {
            this.subEventType = res['value'] as eventType[];
            break;
          }
        }
      }
      , error => {
        this.common.HideSpinner();
        this.common.ShowToast(error.message, ToastType.Error);
      }
    )
  }


  onSubmit() {

    this.BuklPorformances.forEach(element => {
      element.EventStatusID = this.formData.EventStatusID;
      element.StandardLicenseFee = this.formData.StandardLicenseFee;
      element.DiscountedLicenseFee = this.formData.DiscountedLicenseFee;
    });

    this.action.next(this.BuklPorformances);
    this.modalRef.hide();

  }


}
