import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import * as customConfig from 'src/app/customConfig.json';
import { ConfHttpHeadersService } from './conf-http-headers.service';
import { LoginUser } from '../Base/User/login-user';
import { AuthenticationService } from '../helpers/authentication.service';


@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  public rootURL = customConfig.api; 
  public controller: string = "Customers";
  
  constructor(
    private http: HttpClient,
    private  hdr: ConfHttpHeadersService,
    private authenticationService:AuthenticationService
    ) {
    console.log(this.rootURL);
    }

    async getDataByQuery(controllerName: string, query: string, token:string = "") {
      return await this.http.get(this.rootURL + controllerName + query, this.getHeaders(token)).toPromise().then(
        res => res['value'] as any[]
      );
    }

    // post(jsonBody: any, token:string = "") {
    //   return this.http.post(this.rootURL + this.controller, jsonBody, this.getHeaders(token));
    // }


    post(jsonBody: any, token:string = "") {
      return this.http.post(this.rootURL + "api/CustomersNew/Post", jsonBody, this.getHeaders(token));
    }


    postBySubscription(jsonBody: any, token:string = "") {
      return this.http.post(this.rootURL + "api/CustomersTenant/Post", jsonBody, this.getHeaders(token));
    }

    

    getTenants(tenantid: any, email:string, token:string = "") {
      //debugger;
      return this.http.get(this.rootURL + "api/CustomersTenant/GetTenants?tenantid=" + tenantid + "&email=" + email, this.getHeaders(token));
    }

    sendEmail(email:string) {
      //debugger;
      return this.http.get(this.rootURL + "api/CustomersNew/SendWelcomeEmail?email=" + email, this.getHeaders(""));
    }

    


    getHeaders(token:string){
     
  
      const httpOptions = {
        headers: new HttpHeaders({        
          'Token': '123',           
          'tid': customConfig.catalogdb,
          'catalog': customConfig.catalogdb,
          'uid': '-1',          
          'Content-Type': 'application/json',
          
        })      
      };
    
  
      return httpOptions;
    }

  getMyCustomer() {
    return this.http.get(this.rootURL + this.controller , this.hdr.getPostHeaders());
  }

  getMyCustomerByID() {
    var currentUser = this.authenticationService.CurrentUser();

    var obj ={
      customerid:currentUser.CustomerObjectID
    }
    
    return this.http.post(this.rootURL + "api/UserADB2CNew/GetCustomer", obj, this.hdr.getPostHeaders());
  }



    getById(ID:number){
      return this.http.get(this.rootURL + this.controller +"(" +ID + ")",  this.hdr.getPostHeaders());
    }

    update(ID:number,jsonBody:any){
      return this.http.put(this.rootURL + this.controller +"(" +ID + ")", jsonBody, this.hdr.getPostHeaders());
    }

    getCountries(){
      return this.http.get("../../assets/countries.json");
    }
}
