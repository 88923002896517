import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBModalRef, MDBModalService, ToastService } from 'ng-uikit-pro-standard';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { BLBase } from 'src/app/Base/BLBase/BLBase.component';
import { CommonService } from 'src/app/Base/Common.service';
import { SharePointConfigService } from 'src/app/Base/SharePoint/share-point-config.service';
import { ConfirmDialogeService } from 'src/app/confirm-dialoge/confirm-dialoge.service';
import { MessageDialogeService } from 'src/app/message-dialoge/message-dialoge.service';
import { GeneralService } from 'src/app/services/general.service';
import { GenericService } from 'src/app/services/generic.service';
import { themeService } from 'src/app/services/theme.service';
import { Client } from '../client.model';
import { Contactperson } from '../contactperson.model';

@Component({
  selector: 'app-quick-client',
  templateUrl: './quick-client.component.html',
  styleUrls: ['./quick-client.component.scss']
})
export class QuickClientComponent extends BLBase<Client> implements OnInit {

  formDataContact:Contactperson=new Contactperson();
  action = new Subject<any>();

  constructor(
    public service: GenericService,
    public router: Router,
    public route: ActivatedRoute,
    public spinner: NgxSpinnerService,
    public toast: ToastService,
    public dialog: ConfirmDialogeService,
    public genService: GeneralService,
    public modalService: MDBModalService,
    public messageDialoge: MessageDialogeService,
    public common: CommonService,
    //public spService: SPOperationsService,
    public spConfigService:SharePointConfigService,
    public modalRef: MDBModalRef,
    public theme:themeService
  ) {
    super(service, router, route, spinner, toast, dialog, messageDialoge);
    this.formTitle = "Add Client";
    this.addControllerName("Clients");
    this.filterOnGet = "?$select=*&$expand=Client_ContactPerson";
    this.isGetByPrimaryKey = false;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.sectionName = "Client";
  }

  ngAfterViewInit(){
    
  }
  
  public Initializeobject() {
    this.formData = new Client();
    this.scode = "crm_01";
  }

  onSubmit(form : NgForm){
    this.insertRecord(form);
  }

  BeforeInsert(form: NgForm) {
    this.formData.Client_ContactPerson.push(this.formDataContact);
    form.value.Client_ContactPerson = this.formData.Client_ContactPerson;
    
    delete form.value.ContactEmail;
    delete form.value.ContactPersonName;
  }

  AfterInsert(form: NgForm) {
    this.modalRef.hide();
    this.action.next(form);
  }
}
