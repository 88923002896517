import { SPOperationsService } from "../../services/spoperations.service";
import { Injectable } from "@angular/core";
import { SpConfig } from "../../Base/SpConfig";
import { SharePointConfigService } from 'src/app/Base/SharePoint/share-point-config.service';
import { GeneralService } from "src/app/services/general.service";
import { HelpService } from "src/app/services/help.service";

@Injectable({
    providedIn: 'root'
})

export class EventFuncService {

    spConfig_EventDocumentsArchived = null;
    spConfig_Docsethomepage = null;
    spConfig_Documents = null;

    constructor(
        public spService: SPOperationsService,
        public spConfigService: SharePointConfigService,
        public genService:GeneralService,
        private helpService:HelpService,
    ) {
    }

    async GetDocumentLink(eventID: number, eventName: string, eventStartDate: any, venueName: string) {
        var Linkurl = "";
        var event_startdate = new Date(eventStartDate);

        if (event_startdate < new Date('2020/01/01')) {
            var docSetPage = await this.getEventDocumentsArchived();
            Linkurl += docSetPage[0].ListUrl + "?";
            Linkurl += String(docSetPage[0]["RootFolder"]).replace('{Event}', eventID + '-' + venueName);
            Linkurl += "&FolderCTID=" + docSetPage[0]['ContentTypeID0'];
        }
        else {

            var docSetPage = await this.getDocsethomepage();
            Linkurl += docSetPage[0].ListUrl + eventID + ' - ' + eventName ;
            /*
            const eventFolderNameInDocSet: any = await this.spService.getEventFolderInfo("Event Documents", eventID);

            if (eventFolderNameInDocSet['d'].results.length > 0) {
                var Linkurl = "";

                var docSetPage = await this.getDocsethomepage();
                Linkurl += docSetPage[0].ListUrl + "?";
                Linkurl += "ID=" + eventFolderNameInDocSet['d'].results[0].ID;

                var docSet = await this.getDocuments(); 
                Linkurl += "&FolderCTID=" + docSet[0]['ContentTypeID0'];
                Linkurl += "&List=" + docSet[0].ListGUID;
                Linkurl += String(docSet[0]["RootFolder"]).replace('{Event}', eventID + ' - ' + eventName);
                Linkurl += String(docSet[0]["RecSRC"]).replace('{Event}', eventID + ' - ' + eventName);;
                
            }
            */
        }

        return Linkurl;

    }

    async getEventDocumentsArchived() {

        if (this.spConfig_EventDocumentsArchived != null)
            return this.spConfig_EventDocumentsArchived;

        const obj = await this.spConfigService.getRecordByTitleAsync("EventDocumentsArchived")
        this.spConfig_EventDocumentsArchived = obj;
        return obj;

    }

    async getDocsethomepage() {

        if (this.spConfig_Docsethomepage != null)
            return this.spConfig_Docsethomepage;

        const obj = await this.spConfigService.getRecordByTitleAsync("docsethomepage")
        this.spConfig_Docsethomepage = obj;
        return obj;

    }

    async getDocuments() {

        if (this.spConfig_Documents != null)
            return this.spConfig_Documents;

        const obj = await this.spConfigService.getRecordByTitleAsync("Documents")
        this.spConfig_Documents = obj;
        return obj;

    }

    async getEventPortalPrimaryKeys(eventID:any) {
        var filters={
            eventid:eventID
        }
        return await this.helpService.GetDataByHelpNo("hlpeventrelatedids",filters);
    }

    async getEventDetails(eventID:any) {
        var filters={
            eventid:eventID
        }
        return await this.helpService.GetDataByHelpNo("hlpeventdetailssp",filters);
    }

    async getSubEventDetails(eventID:any) {
        var filters={
            eventid:eventID
        }
        return await this.helpService.GetDataByHelpNo("hlpsubeventdetailssp",filters);
    }

    async getEventVW(eventID:any) {
        var filters={
            eventid:eventID
        }
        return await this.helpService.GetDataByHelpNo("hlpeventvw",filters);
    }
}