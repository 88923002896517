import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    public msal:MsalService
  ) { }

  ngOnInit(): void {

      //On every 25 minutes
      setInterval(() => {
        this.msal.loginRedirect();
      
      }, 5000); // 5 seconds
    
  

  }





}
