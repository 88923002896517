import { Component, OnInit } from '@angular/core';
import { Table } from 'src/app/Base/Table';
import { GenericService } from 'src/app/services/generic.service';
import { Router } from '@angular/router';
import { MessageDialogeService } from 'src/app/message-dialoge/message-dialoge.service';
import { CommonService } from 'src/app/Base/Common.service';
import { MatTableDataSource } from '@angular/material/table';
import { Customer } from 'src/app/models/customer.model';
import { CustomersService } from 'src/app/services/customers.service';
import { PermissionService } from 'src/app/services/permission.service';


@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent extends Table implements OnInit {
  public searchInput = false;
  displayedColumns: string[] = [ 'actions', 'ID', 'FirstName', 'LastName','Email'];
  
  showAcitve: boolean = false;
  showInacitve: boolean = false;
  showSystemGenerated: boolean = false;
  

  constructor(
    public service: CustomersService, 
    public router: Router, 
    public messageDialoge: MessageDialogeService,
    public common:CommonService,
    public permissionService:PermissionService,) {
    super(router);    
  }

  ngOnInit(): void {
    this.showAcitve = true;
    this.getCustomers();
  }

  getCustomers(){
    this.listData = null;
    var queryOption = "";   
    //var queryOption = "?$filter= 1 eq 1 ";   
    //queryOption += "&$orderby=FirstName ASC";

    this.service.getDataByQuery("Customers", queryOption).then(res => {

      this.listData = new MatTableDataSource(res as Customer[]);
      this.listData.sort = this.sort;
      this.listData.paginator = this.paginator;

    });
  }

  onEdit(gen: Customer) {    
      this.router.navigate(['/customer/form'], { queryParams: { ID: gen.ID } })
  }



}