
<!-- <div class="myForm  shadow">
    <form class=" p-5 mb-5 rounded mt-4  border-form" >
        <div class="d-flex justify-content-center">
            <div class="logo">
                <img src="assets/venuearc_logo.png" alt="Logo" style="width: 300px;">
            </div>
        </div>

        <div class="row">

            <div class="col-md-12 pt-5 pb-3 text-center">
                <h2 style="color:gray" >Session Expired!</h2>
                <br>
                <h6 style="font-weight: 500;">Your session has expired.</h6>
                <br>
                <h6 style="font-weight: 500;">Please sign in again.</h6>                
            </div>

            <div class="col-sm-12" *ngIf="IsshowProgress">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                <br>
                <p> <i> Please wait. This will take few seconds. </i></p>
            </div>
        </div>
        
        <div class="row">
            <div class="col-md-12 mt-4">

                <button  mdbBtn color="info" class="waves-light w-100" mdbWavesEffect
                    type="button" (click)="logout()" rounded="true">                    
                    <mdb-icon fas icon="sign-in-alt"></mdb-icon>                        
                    <span class="ml-2">Sign In</span>
                </button>

            </div>
        </div>

            
    </form>
</div>
 -->


<div class="container-fluid bg-frm">
    <div class="d-flex align-items-center bdy2">
      <div class="box shadow">
        <div class="imgDiv">
          <img src="assets/venue_arc_singup.png" alt="Logo" class="logo">
        </div>

        <div class="text-center">
          <mat-icon class="sessionIcon">update_disabled</mat-icon>
        </div>

        <div class="text-center">
          <div class=" pt-2 pb-3 text-center">
            <h2 class="sessionText">
               Your session has expired
            </h2>
            <p class="subSessionText">Your session has been expired due to inactivity, Please Sign in again to keep using this app</p>
          </div>

          <div class="row" *ngIf="IsshowProgress">

            <div class="col-md-12">
                <div class="d-flex flex-column justify-content-center">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    <br>
                    <p class="text-center"> <i> Please wait. This will take few seconds. </i></p>
                </div>
            </div>


        </div>
          <div class="">
              <button mdbBtn  class="waves-light w-75" mdbWavesEffect type="button" style="color: white;background: #223f81;border-radius: 24px !important;" (click)="logout()" >
                <mdb-icon fas icon="sign-out-alt"></mdb-icon>
                <span class="pl-2"> Sign In </span>
  
              </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  