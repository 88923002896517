import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as customConfig from 'src/app/customConfig.json';

import { ConfHttpHeadersService } from './conf-http-headers.service';

@Injectable({
  providedIn: 'root'
})
export class HelpService {

  private rootURL = customConfig.api; 

  constructor(
    private http: HttpClient,
    private hdr:ConfHttpHeadersService,
    ) { }

  public async GetSpHelp(name:string,jsonbody:any={}){
    return await this.http.post(this.rootURL + "api/Common/"+ name,jsonbody,this.hdr.getHeaders()).toPromise();
  } 
 
  public async GetDataByHelpNo(helpNo:string,filters?:any){
    var query = {
      helpno:helpNo,
      filters:filters ? filters : {}
    };
    return await this.http.post(this.rootURL + "api/HelpSetup/GetHelpData",query,this.hdr.getHeaders()).toPromise();
  } 

  
}
