import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import * as customConfig from 'src/app/customConfig.json';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
 
export const b2cPolicies = {
     names: {
         signUpSignIn: customConfig.signupSignInPolicy
     },
     authorities: {
         signUpSignIn: {
             authority: customConfig.authority,
            
         }
     },
     authorityDomain: customConfig.instance
 };
 
 
export const msalConfig: Configuration = {
     auth: {
         clientId: customConfig.clientId,
         authority: b2cPolicies.authorities.signUpSignIn.authority,
         knownAuthorities: [b2cPolicies.authorityDomain],
         redirectUri: customConfig.redirectUri, 
         postLogoutRedirectUri: customConfig.logoutUrl
         //redirectUri: 'http://localhost:4200/signin', 
         
         
     },
     cache: {
         cacheLocation: BrowserCacheLocation.LocalStorage,
         storeAuthStateInCookie: isIE, 
     },
     system: {
         loggerOptions: {
            loggerCallback: (logLevel, message, containsPii) => {
                console.log(message);
             },
             logLevel: LogLevel.Verbose,
             piiLoggingEnabled: false
         }
     }
 }

 

export const protectedResources = {
  todoListApi: {
    endpoint: customConfig.api,    
    scopes: [customConfig.authscope],
  },
}
export const loginRequest = {
  scopes: []
};