import { animate, animation, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { INavbarData } from './helper';
import { EventDocumentService } from 'src/app/services/event-document.service';
import { AzblobStorageService } from 'src/app/services/azblob-storage.service';
import { LoginUser } from 'src/app/Base/User/login-user';
import { themeService } from 'src/app/services/theme.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { TeamsAppService } from 'src/app/services/teams-app.service';
import { CommonService } from 'src/app/Base/Common.service';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { RequestPermiumFromBasicComponent } from 'src/app/modules/main/request-permium-from-basic/request-permium-from-basic.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { RequestEnterpriseComponent } from 'src/app/modules/main/request-enterprise/request-enterprise.component';
import { PermissionService } from 'src/app/services/permission.service';
import * as customConfig from 'src/app/customConfig.json'

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('350ms',
          style({ opacity: 1 })
        )
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('100ms',
          style({ opacity: 0 })
        )
      ])
    ]),
    trigger('rotate', [
      transition(':enter', [
        animate('1000ms',
          keyframes([
            style({ transform: 'rotate(0deg)', offset: '0' }),
            style({ transform: 'rotate(2turn)', offset: '1' }),
          ])
        )
      ])
    ]),
    trigger('submenu', [
      state('hidden', style({
        height: '0',
        overflow: 'hidden'
      })),
      state('visible', style({
        height: '*'
      })),
      transition('visible <=> hidden', [style({ overflow: 'hidden' }),
      animate('{{transitionParams}}')]),
      transition('void => *', animate(0))

    ])

  ]
})
export class SideBarComponent implements OnInit, OnChanges {

  @Output() onToggleSideNav: EventEmitter<sideNavToggle> = new EventEmitter<sideNavToggle>();
  //#region For side-nav purpose
  collapsed: boolean = true;
  screenWidth = 0;
  @Input() isToggleClick = false;
  isClick: boolean = false;
  // navData:any[] = navbarData;
  // multiple : boolean =true;

  expanded1: boolean = true;
  expanded2: boolean = false;
  expanded3: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 768) {
      this.collapsed = false;
      this.onToggleSideNav.emit(
        {
          screenWidth: this.screenWidth, collapsed: this.collapsed, isClick: this.isClick
        }
      )
    }
  }
  //#endregion
  public modalRef: MDBModalRef;

  user = LoginUser.loggedinUser;
  isOwner: boolean = false;
  constructor(

    public eventDocumentService: EventDocumentService,
    public azblobStorageService: AzblobStorageService,
    public organizationService: OrganizationService,
    public theme: themeService,
    public teamsAppService: TeamsAppService,
    public common: CommonService,
    public modalService: MDBModalService,
    public router: Router,
    public matDialog: MatDialog,
    private authenticationService: AuthenticationService,
    public permissionService: PermissionService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isToggleClick != null) {

      this.toggleSidenav();
    }
  }

  getOwner() {
    this.isOwner = this.authenticationService.CurrentUser().isowner;
  }
  async ngOnInit() {
    this.getOwner();
    this.screenWidth = window.innerWidth;
    this.isClick = !this.isClick;
    if (this.screenWidth <= 768) {
      this.collapsed = false;
    }
    this.onToggleSideNav.emit(
      {
        screenWidth: this.screenWidth, collapsed: this.collapsed, isClick: this.isClick
      }
    )

    await this.organizationService.getOrganizationLogo();
  }



  toggleSidenav() {
    this.collapsed = !this.collapsed;
    this.isClick = !this.isClick;
    this.onToggleSideNav.emit(
      {
        screenWidth: this.screenWidth, collapsed: this.collapsed, isClick: this.isClick
      }
    )
  }

  closeSideNav() {
    if (this.screenWidth <= 768) {
      this.collapsed = false;
      this.onToggleSideNav.emit(
        {
          screenWidth: this.screenWidth, collapsed: this.collapsed, isClick: this.isClick
        }
      )
    }
  }

  mouseLeave() {
    if (this.isClick == false) {
      this.collapsed = false;
      this.onToggleSideNav.emit(
        {
          screenWidth: this.screenWidth, collapsed: this.collapsed, isClick: this.isClick
        }
      )
    }
  }

  mouseEnter() {
    if (this.isClick == false) {
      this.collapsed = true;
      this.onToggleSideNav.emit(
        {
          screenWidth: this.screenWidth, collapsed: this.collapsed, isClick: this.isClick
        }
      )
    }
  }

  // handleClick(item:INavbarData){
  //   if(!this.multiple){
  //       for (let modelItem of this.navData) {
  //           if(item !== modelItem && modelItem.expanded){
  //             modelItem.expanded = false;
  //           }

  //       }
  //   }
  //   item.expanded = !item.expanded;
  // }

  onExpand1() {
    this.expanded1 = !this.expanded1;
  }

  onExpand2() {
    this.expanded2 = !this.expanded2;
  }

  onExpand3() {
    this.expanded3 = !this.expanded3;
  }

  // upgradeBasicToPremium(){
  //   this.modalRef = this.modalService.show(RequestPermiumFromBasicComponent,{
  //     backdrop: true,
  //     keyboard: true,
  //     focus: true,
  //     show: false,
  //     ignoreBackdropClick: true,
  //     class: 'modal-xl modal-content modal-dialog cascading-modal modal-top',
  //     containerClass: '',
  //     animated: true,
  //     styles: "overflow-y: auto",
  //   })
  // }

  async upgradeBasicToPremium() {
    // const dialogQuick = await  this.matDialog.open(RequestPermiumFromBasicComponent, {
    //   // disableClose: true ,
    //   height: '55%',
    //   maxHeight: '100%',
    //   width: '55%',
    //   minWidth: '52%',
    //   panelClass: 'dialog-panel',
    //   position: {
    //     right: 10 + '%',
    //     top: 11 + '%',
    //     left: 24 + '%',
    //     bottom: 0 + '%',
    //   },
    //   autoFocus: false,
    // })
    var url = customConfig.appsourcelink;
    if (this.user?.SubscriptionType == 'stripe')
      this.router.navigate(["/membership/subscribe"], { queryParams: { b: 1 } });
    else
      window.location.href = url;
  }

  upgradetoenterprise() {

    var url = customConfig.appsourcelink;
    if (this.user?.SubscriptionType == 'stripe'){
      
      if (LoginUser.loggedinUser.IsFreeTrial == true) {
        this.router.navigate(["/membership/subscribe"], { queryParams: { b: 1 } });
      } else {
        this.modalRef = this.modalService.show(RequestEnterpriseComponent, {
          backdrop: true,
          keyboard: true,
          focus: true,
          show: false,
          ignoreBackdropClick: true,
          class: 'modal-xl modal-content modal-dialog cascading-modal modal-top',
          containerClass: '',
          animated: true,
          styles: "overflow-y: auto",
        });
      }
    }
    else{
      window.location.href = url;
    }
    // if(LoginUser.loggedinUser.IsFreeTrial == true){
    //   this.router.navigate(["/membership/subscribe"],{queryParams:{b:1}});
    // } else{
    //   this.modalRef = this.modalService.show(RequestEnterpriseComponent, {
    //     backdrop: true,
    //     keyboard: true,
    //     focus: true,
    //     show: false,
    //     ignoreBackdropClick: true,
    //     class: 'modal-xl modal-content modal-dialog cascading-modal modal-top',
    //     containerClass: '',
    //     animated: true,
    //     styles: "overflow-y: auto",
    //   });
    // }


  }
}


interface sideNavToggle {
  screenWidth: number;
  collapsed: boolean;
  isClick: boolean;
}

