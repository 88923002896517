<!-- <div class="col-md-12 pb-3 text-center">
    <h6 style="color: gray;">Sign Up with</h6>    
</div> -->
<div class="row">
    <div class="col-md-12 text-center">
        <button mdbBtn color="success" class="waves-light w-75" rounded="true" mdbWavesEffect type="button"
            (click)="navigateCustomSignUp()">
            <mdb-icon fas icon="sign-in-alt"></mdb-icon>
            <span class="ml-2"> VenueArc </span>
        </button>
        <p class="text-center pt-4">OR</p>
        <button mdbBtn color="info" class="waves-light w-75" rounded="true" mdbWavesEffect type="button"
            (click)="navigateMarketPlaceSignUp()">

            <mdb-icon fas icon="sign-in-alt"></mdb-icon>
            <span class="ml-2"> Azure Marketplace </span>
        </button>
    </div>

</div>