<div class="modal-header head">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" id="btnClose">
        <span aria-hidden="true">x</span>
    </button>
    <h5 class="modal-title w-100" id="myModalLabel">Add Contract</h5>
</div>
<div class="modal-body scrollBar " style="overflow:auto">
    <form #ContractType="ngForm" autocomplete="off" (ngSubmit)="onSubmit(ContractType)">
        <div class="row">
            <div class="col-sm-3" [hidden]="true">
                <div class="md-form">
                    <input class="MatInputBoxShadow" name="ID" #ID="ngModel" [(ngModel)]="formData.ID" type="text"
                        id="materialLoginFeeTypeID" class="form-control" mdbInput>
                    <label for="materialLoginFeeTypeID" [class]="theme.label">License Fee ID</label>
                </div>
            </div>

            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <mat-form-field class="example-full-width"
                    [hidden]="common.isSingleVenueExist == null || common.isSingleVenueExist">
                    <mat-label [class]="theme.label">Venue</mat-label>
                    <mat-select required id="VenueID" class="MatInputBoxShadow" type="text" name="VenueID" itemid="drpVenue"
                        #VenueID="ngModel" [(ngModel)]="formData.VenueID"
                        (ngModelChange)="AutoCompVenue.searchResult.next($event)"
                        (selectionChange)="onVenueChange(formData.VenueID)">
                        <mat-option>-- Clear --</mat-option>
                        <mat-option *ngFor="let option of AutoCompVenue.results | async" [value]="option.ID">
                            {{ option.VenueName }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="VenueID.invalid && VenueID.dirty">This field is required</mat-error>

                </mat-form-field>
            </div>
            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <mat-form-field class="example-full-width">
                    <mat-label [class]="theme.label">Order #</mat-label>
                    <input matInput required class="MatInputBoxShadow" name="SerialNo" #SerialNo="ngModel"
                        [(ngModel)]="formData.SerialNo" type="number" id="numOrder">
                    <mat-error *ngIf="SerialNo.invalid && SerialNo.dirty">This field is required</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <mat-form-field class="example-full-width">
                    <mat-label [class]="theme.label">Name</mat-label>
                    <input matInput required class="MatInputBoxShadow" name="Name" #Name="ngModel"
                        [(ngModel)]="formData.Name" type="text" id="txtName">
                    <button type="button" mat-button *ngIf="formData.Name" matSuffix mat-icon-button aria-label="Clear"
                        (click)="formData.Name=''">
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-error *ngIf="Name.invalid && Name.dirty">This field is required</mat-error>

                </mat-form-field>
            </div>
            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <mat-form-field class="example-full-width">
                    <mat-label [class]="theme.label">Description</mat-label>
                    <input matInput class="MatInputBoxShadow" name="FieldDescription" #FieldDescription="ngModel"
                        [(ngModel)]="formData.FieldDescription" type="text" id="txtDescription">
                    <button type="button" mat-button *ngIf="formData.FieldDescription" matSuffix mat-icon-button
                        aria-label="Clear" (click)="formData.FieldDescription=''">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <mat-form-field class="example-full-width">
                    <mat-label [class]="theme.label">Field Type</mat-label>
                    <mat-select required id="drpFieldType" type="text" name="FieldTypeID" #FieldTypeID="ngModel"
                        [(ngModel)]="formData.FieldTypeID"
                        (ngModelChange)="AutoCompFieldTypes.searchResult.next($event)">
                        <mat-option>-- Clear --</mat-option>
                        <mat-option *ngFor="let option of AutoCompFieldTypes.results | async" [value]="option.ID">
                            {{ option.Name }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="FieldTypeID.invalid && FieldTypeID.dirty">This field is required</mat-error>

                </mat-form-field>
            </div>
            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <mat-form-field class="example-full-width">
                    <mat-label [class]="theme.label">Occurrence</mat-label>
                    <mat-select required id="drpOccurence" type="text" name="OccurenceID" #OccurenceID="ngModel"
                        [(ngModel)]="formData.OccurenceID"
                        (ngModelChange)="AutoCompOccurence.searchResult.next($event)">
                        <mat-option>-- Clear --</mat-option>
                        <mat-option *ngFor="let option of AutoCompOccurence.results | async" [value]="option.ID">
                            {{ option.OccurenceName }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="OccurenceID.invalid && OccurenceID.dirty">This field is required</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <mat-form-field class="example-full-width"
                    *ngIf="formData.FieldTypeID != null && formData.FieldTypeID == fieldtype.Text">
                    <mat-label [class]="theme.label">Default Value</mat-label>
                    <input matInput name="DefaultValue" #DefaultValue="ngModel" [(ngModel)]="formData.DefaultValue" id="txtDefaultValue">
                </mat-form-field>
                <mat-form-field class="example-full-width" matInputCommified
                    *ngIf="formData.FieldTypeID != null && (formData.FieldTypeID == fieldtype.Number)">
                    <mat-label [class]="theme.label">Default Value</mat-label>

                    <input matInput type="text" name="DefaultValue" #DefaultValue="ngModel" id="numDefaultValue"
                        [(ngModel)]="formData.DefaultValue">
                </mat-form-field>
                <mat-form-field class="example-full-width"
                    *ngIf="formData.FieldTypeID != null && (formData.FieldTypeID == fieldtype.Currency)">
                    <mat-label [class]="theme.label">Default Value</mat-label>

                    <input matInput type="text" name="DefaultValue" #DefaultValue="ngModel" id="numDefaultValue"
                        [(ngModel)]="formData.DefaultValue" appMatInputCurrency>
                    <span matPrefix>{{currencySymbol}}&nbsp;</span>
                    <!-- <span matPrefix >$&nbsp;</span> -->
                </mat-form-field>
                <mat-form-field class="example-full-width"
                    *ngIf="formData.FieldTypeID != null && (formData.FieldTypeID == fieldtype.Currency_Decimal_3)">
                    <mat-label [class]="theme.label">Default Value</mat-label>

                    <input matInput type="text" name="DefaultValue" #DefaultValue="ngModel" id="numDefaultValue"
                        [(ngModel)]="formData.DefaultValue" appMatInputCurrency currencydecimal="1.3">
                    <span matPrefix>{{currencySymbol}}&nbsp;</span>
                    <!-- <span matPrefix >$&nbsp;</span> -->
                </mat-form-field>
                <mat-form-field class="example-full-width"
                    *ngIf="formData.FieldTypeID != null && (formData.FieldTypeID == fieldtype.Rich_Text)">
                    <mat-label [class]="theme.label">Default Value</mat-label>

                    <textarea rows="3" matInput name="DefaultValue" #DefaultValue="ngModel" id="rchtxtDefaultValue"
                        [(ngModel)]="formData.DefaultValue"></textarea>
                </mat-form-field>

                <input type="date" class="form-control"
                    *ngIf="formData.FieldTypeID != null && formData.FieldTypeID == fieldtype.Date" name="DefaultValue"
                    #DefaultValue="ngModel" [(ngModel)]="formData.DefaultValue" />
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" [hidden]="true">
                <div class="md-form">
                    <mdb-checkbox name="IsByDefault" #IsByDefault="ngModel" [(ngModel)]="formData.IsByDefault">
                        <mat-label [class]="theme.label">Default</mat-label>
                    </mdb-checkbox>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="md-form">
                    <mdb-checkbox name="IsUseForSettlement" #IsUseForSettlement="ngModel"
                        [(ngModel)]="formData.IsUseForSettlement">
                        <mat-label [class]="theme.label">Use in Income Statement</mat-label>
                    </mdb-checkbox>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <mat-form-field class="example-full-width">
                    <mat-label [class]="theme.label">GL Code</mat-label>
                    <input matInput class="MatInputBoxShadow " [disabled]="!formData.IsUseForSettlement" name="GLCode"
                        #GLCode="ngModel" [(ngModel)]="formData.GLCode" type="text" id="numGLCode">
                    <button type="button" mat-button *ngIf="formData.GLCode" matSuffix mat-icon-button
                        aria-label="Clear" (click)="formData.GLCode=''">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="md-form">
                    <mdb-checkbox name="Inactive" #Inactive="ngModel" [(ngModel)]="formData.Inactive">
                        <mat-label [class]="theme.label">Inactive</mat-label>
                    </mdb-checkbox>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button type="button" (click)="onSubmit(ContractType)" [disabled]="ContractType.invalid" mdbBtn [class]="theme.button" class="waves-light" mdbWavesEffect id="btnSave">
                    Submit
                </button>
            </div>
        </div>
    </form>
</div>