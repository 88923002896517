<div class="myForm shadow border-form">
    <form #Venue="ngForm" autocomplete="off" (ngSubmit)="onSubmit(Venue)" class=" p-5 mb-5 rounded mt-4 " >
        <div class="d-flex justify-content-center">
            <div class="logo">
                <img src="assets/venuearc_logo.png" alt="Logo" style="width: 300px;">
            </div>
        </div>

        <div class="row">

            <div class="col-md-12 pt-5 pb-3 text-center">
                <h2 style="color:#0e83a8;">Add your Venue</h2>
                <p>We are just 1 step away!</p>
            </div>
        </div>


        <div class="row">

            <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" [hidden]="true">
                <div class="md-form">

                    <input 
                    name="ID" 
                    #ID="ngModel" 
                    [(ngModel)]="formData.ID" 
                    type="text"                    
                    class="form-control" mdbInput>
                    <label>Venue ID</label>

                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Venue Name</mat-label>                    
                    <input matInput class="MatInputBoxShadow"
                    required 
                    name="VenueName" 
                    #VenueName="ngModel" 
                    [(ngModel)]="formData.VenueName"
                    type="text" >                                 
                    <mat-error *ngIf="VenueName.invalid && VenueName.dirty">This field is required</mat-error> 
                </mat-form-field>  
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12" >
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Short Code</mat-label>                    
                    <input maxlength="2" matInput class="MatInputBoxShadow"
                    required 
                    name="ShortCode" 
                    #ShortCode="ngModel" 
                    [(ngModel)]="formData.ShortCode"
                    type="text" >                                 
                    <mat-hint align="start">Max length 2 Characters </mat-hint>
                    <mat-hint align="end">{{formData.ShortCode == null ? 0 : formData.ShortCode.length}} / 2</mat-hint>
                    <mat-error *ngIf="ShortCode.invalid && ShortCode.dirty">This field is required</mat-error> 
                </mat-form-field>  
            </div>         
            <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">    
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Capacity</mat-label>                    
                    <input matInput class="MatInputBoxShadow"                         
                    name="Capacity" 
                    #Capacity="ngModel" 
                    [(ngModel)]="formData.Capacity"
                    type="number" >                                                         
                </mat-form-field>  
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 mt-4">

                <button mdbBtn color="info" class="waves-light w-100" mdbWavesEffect
                    type="button" (click)="onSubmit(Venue)" rounded="true">
                    
                    <mdb-icon fas icon="check"></mdb-icon>
                    <span class="ml-2">Submit</span>
                </button>

            </div>
        </div>

       


    </form>
</div>
