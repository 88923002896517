<!--While Loading Data-->
<h6 class="text-center pt-4" [hidden]="listData!=null">
    Loading...
    <div class="spinner-grow ml-2" role="status">
        <span class="sr-only float-right">Loading...</span>
    </div>
</h6>

<!-- When Data not Exists-->
<h6 class="text-center pt-4" [hidden]="!(listData != null && listData.data.length == 0)">
    <div [hidden]="isAccess == false">
        No data found! <!--<a (click)="create()" style="color: #007bff;text-decoration: underline;">Create</a> your {{formName}}. -->
    </div>
    <div [hidden]="isAccess == true">
        No data found!
    </div>
</h6>

<!--When No Data Matched-->
<h6 class="text-center pt-4"
    [hidden]="!(listData != null && listData.filteredData.length == 0 && listData.data.length > 0)">
    No data matching the search keyword <strong><i> '{{search}}' </i></strong>
</h6>