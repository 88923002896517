import { Component, OnInit } from '@angular/core';
import { Client } from '../../client/client.model';
import { Contactperson } from '../../client/contactperson.model';

@Component({
  selector: 'app-on-boarding-client',
  templateUrl: './on-boarding-client.component.html',
  styleUrls: ['./on-boarding-client.component.scss']
})
export class OnBoardingClientComponent implements OnInit {

  formData:Client = new Client();
  formDataContactPerson:Contactperson = new Contactperson();
  constructor() { }

  ngOnInit(): void {
  }

}
