
<!-- <br> -->
<div class="">

    <div class="">


      <div class="row mt-3">
        <!-- Safwan Changes -Start -->
        <div class="col-md-6 text-left pl-3" [hidden]="common.isSingleVenueExist == null || common.isSingleVenueExist">
        <!-- Safwan Changes -End -->
          <!-- <div class="col-md-6 text-left pl-3" > -->

          <div class="chip " style="margin-bottom: 0.5rem !important;"
          *ngFor="let ven of venueMonthNotesList ; let i = index;"
          (click)="onChipVenueClick(ven.ID)"
          [ngClass]="{'selectedChip':selectedChipVenueID == ven.ID }">
              <div class="chipArea" style="border: solid;border-width: 1px;border-color: #d1dee3;text-align:center;">
                {{ven.ShortCode}}
              </div>
              {{ven.VenueName}}
          </div>
        </div>

        <!-- <div class="col-md-2 text-center">
          <mat-button-toggle-group  name="calendarGroup" [(ngModel)]="calendarGroup" aria-label="Font Style" [ngClass]="setNgClass()">
            <mat-button-toggle (click)="onSwitchClick('Calendar')" value="Calendar">Calendar</mat-button-toggle> 
            <mat-button-toggle (click)="onSwitchClick('Avail')" value="Avail">Availability</mat-button-toggle>
            </mat-button-toggle-group>
        </div> -->
        
        <!-- Safwan Changes -Start -->
        <div class=" pr-3" [ngClass]="common.isSingleVenueExist ? 'col-md-12 text-left':'col-md-6 text-right'">
        <!-- Safwan Changes -End -->

          <!-- <div class="col-md-6 text-right pr-3"> -->
            <!-- <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
                    <mat-button-toggle value="web" (click)="IsPrint=false;">Web</mat-button-toggle>
                    <mat-button-toggle value="report" (click)="IsPrint=true;">Report</mat-button-toggle>
            </mat-button-toggle-group> -->

            <div class="chip pl-4 pr-4" style="margin-bottom: 0.5rem !important;"
            (click)="IsPrint=false;"
            [ngClass]="{'selectedChip':IsPrint == false }">
                Web
            </div>
            <div class="chip pl-4 pr-4" style="margin-bottom: 0.5rem !important;"
            (click)="IsPrint=true;" [hidden]="true"
            [ngClass]="{'selectedChip':IsPrint == true }">
                Print
            </div>

        </div>
      </div>

      <div style="margin-top:16px;"></div>

        <div [hidden]="IsPrint">
                <div class="row text-center">

                    <div class=" col-sm-12 col-md-3 calendar_buttonRow">

                    <div class="btn-group CalendarActionButton float-left" >
                        <button mdbBtn type="button" [class]="theme.button"  class="waves-light CalendarNavButtons" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                        (viewDateChange)="closeOpenMonthViewDay(viewDate)" mdbWavesEffect><mdb-icon fas icon="angle-left"></mdb-icon></button>
                        <button mdbBtn type="button"  [class]="theme.button" class="waves-light CalendarNavButtons" mwlCalendarToday [(viewDate)]="viewDate"
                        (viewDateChange)="closeOpenMonthViewDay(viewDate)" mdbWavesEffect>Today</button>
                        <button mdbBtn type="button"  [class]="theme.button" class="waves-light CalendarNavButtons" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                        (viewDateChange)="closeOpenMonthViewDay(viewDate)" mdbWavesEffect><mdb-icon fas icon="angle-right"></mdb-icon></button>
                        <!-- <mat-form-field  appearance="fill" class="matDateFieldCalendar ">
                        <mat-label>Jump to Month</mat-label>
                        <input matInput [matDatepicker]="picker" [value]="viewDate"
                            (dateChange)="filterCalendar('change', $event)">
                        <mat-datepicker-toggle matSuffix [for]="picker" style="    color: #095e8f"></mat-datepicker-toggle>
                        <mat-datepicker  #picker
                            startView="year"
                            (monthSelected)="chosenMonthHandler($event, picker)"
                            panelClass="example-month-picker">
                        </mat-datepicker>
                        </mat-form-field> -->
                    </div>

                    </div>

                    <div class=" col-sm-12 col-md-5 text-center calendar_buttonRow d-flex justify-content-center calendar-icon-btn">
                      <mat-form-field appearance="fill" class="matDateFieldCalendar " style="line-height: 1px !important;box-shadow: -4px 4px 4px 0px #e0e0e0;">
                        <mat-label>Jump to Month</mat-label>
                        <input matInput [matDatepicker]="picker" [value]="viewDate"
                          (dateChange)="filterCalendar('change', $event)">
                        <mat-datepicker-toggle matSuffix [for]="picker" [class]="theme.icon"></mat-datepicker-toggle>
                        <mat-datepicker #picker startView="year" (monthSelected)="chosenMonthHandler($event, picker)"
                          panelClass="example-month-picker">
                        </mat-datepicker>
                      </mat-form-field>
                    <h1 class="MonthHeading">
                        <mdb-badge *ngIf="view === CalendarView.Month" color="white" style="color: gray !important;">
                            {{ viewDate | date:'MMMM, yyyy' }}
                        </mdb-badge>

                    </h1>
                    </div>

                    <div class=" col-sm-12 col-md-4  calendar_buttonRow">

                    <div class="btn-group CalendarActionButton float-right" role="group" aria-label="Basic example">
                        <button mdbBtn type="button" [class]="theme.button" class="waves-light CalendarNavButtons" (click)="setView(CalendarView.Month)"
                        [class.active]="view === CalendarView.Month" mdbWavesEffect>Month</button>
                        <button mdbBtn [disabled]="true" type="button"  [class]="theme.button" class="waves-light CalendarNavButtons" (click)="setView(CalendarView.Week)"
                        [class.active]="view === CalendarView.Week" mdbWavesEffect>Week</button>
                    <button mdbBtn [disabled]="true" type="button"  [class]="theme.button" class="waves-light CalendarNavButtons"(click)="setView(CalendarView.Day)"
                        [class.active]="view === CalendarView.Day" mdbWavesEffect>Day</button>

                    </div>


                    </div>


                </div>
                <br />

                <!-- (dayClicked)="OpenEventModal($event)" -->

                <div [ngSwitch]="view">
                    <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
                    [refresh]="refresh" (eventTimesChanged)="eventTimesChanged($event)" [cellTemplate]="customCellTemplate"
                    (columnHeaderClicked)="clickedColumn = $event.isoDayNumber"
                    [tooltipTemplate]="cellTooltipTemplate">
                    </mwl-calendar-month-view>
                </div>
        </div>

        <div [hidden]="!IsPrint" *ngIf="IsPrint">
            <app-avail-report [InputVenueID]="selectedChipVenueID" [InputMonthDate]="viewDate"></app-avail-report>

        </div>

    </div>
    <br>

  </div>

    <br /><br /><br />

    <ng-template #customCellTemplate let-day="day" let-locale="locale" let-eventClicked="eventClicked"
      let-tooltipPlacement="tooltipPlacement" let-tooltipTemplate="tooltipTemplate"
      let-tooltipAppendToBody="tooltipAppendToBody" let-tooltipDelay="tooltipDelay">



      <div class="cal-cell-top" [matMenuTriggerFor]="DayMenu" >
        <div class="">
          <div class="">
              <span class="cal-day-number">
                  {{ day.date | calendarDate:'monthViewDayNumber':locale }}
                </span>
          </div>
        </div>

        <mat-menu  #DayMenu="matMenu" yPosition="below" >
          <button *ngIf="permissionService.CheckIfUserhasAccess_Action('Event','Add')"  mat-menu-item (click)="OpenEventModal(day.date)">
            <mat-icon>add</mat-icon>
            <span>Add Event</span>
          </button>
        </mat-menu>

        <div class="cal-events" *ngFor="let event of day.events"
        [mwlCalendarTooltip]="event.title | calendarEventTitle: 'monthTooltip':event"
        [tooltipPlacement]="tooltipPlacement" [tooltipEvent]="event" [tooltipTemplate]="tooltipTemplate"
        [tooltipAppendToBody]="tooltipAppendToBody" [tooltipDelay]="tooltipDelay"
        [style.backgroundColor]="event.color?.primary" class="AvailCalendarEventTile"
        (mwlClick)="eventClicked.emit({event: event})"  [hidden]="event.title == null" >

            <!-- For Default Event-->
            <!-- <div style="color: black; padding: 10%; padding-left: 30%;"> -->
            <div style="color: black;padding-top: 10px; ">
                {{event.title}}
            </div>
        </div>

      </div>


    </ng-template>


    <ng-template #cellTooltipTemplate let-event="event" let-tooltipPlacement="tooltipPlacement" let-locale="locale" >

            <div class="cal-tooltip IsMobile" >

              <div class="cal-tooltip-arrow"></div>

              <div class="cal-tooltip-inner EventToolTip" [tooltipPlacement]="tooltipPlacement"
                style="background-color:transparent" >

                <div class="TableToolTipCalendar" style="background-color: white;">
                  <table mdbTable class="z-depth-1 InputTable" >

                    <tbody>

                        <tr>
                            <td ><label [class]="theme.label">Events</label></td>
                        </tr>

                        <tr *ngFor=" let row of eventsToolTip | filter : 'SubEventDate' :
                        event.meta.ToolTipDate ;let k=index ">
                            <td >{{row.EventID}} - {{row.EventName}}</td>
                        </tr>


                    </tbody>
                  </table>
                </div>


              </div>

            </div>

    </ng-template>














