import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import * as customConfig from 'src/app/customConfig.json';
@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss']
})
export class SsoComponent implements OnInit {

  constructor(
    private authService: MsalService,
    private router: Router,
  ) { }


  ngOnInit(): void {

    if (localStorage.getItem("mptoken"))
      this.loginWithMicrosoft();
    else
      this.router.navigate(["/signin"]);

  }


    
  loginWithMicrosoft(){
    
    localStorage.removeItem("mptoken");    

    var authRequestConfig= {
      domainHint: "login.microsoftonline.com",
      redirectUri: customConfig.redirectUri//"http://localhost:4200/signin"
    };

    this.authService.loginRedirect(authRequestConfig as RedirectRequest);
    
  }

}
