<div class="header justify-content-between align-items-center mb-0 d-flex" [class]="theme.heading">
  <h4 class="h4-responsive ml-3" style="color: White;margin-bottom: 0 !important;">
    Add Event
  </h4>
  <div class="btn-group" role="group" aria-label="Basic example">
    <button mat-dialog-close mat-icon-button aria-label="Example icon button with a heart icon" style="color:white;">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<!-- <div class="modal-body mb-0 "> -->

<form #event="ngForm" autocomplete="off" class="container-fluid">
  <div class="mt-3">
    <fieldset>
      <div class=" mb-1 mt-2" [ngClass]="isLoading ? 'form-loading' :'form-after-load'">
        <div class="" [hidden]="true">
          <div class="md-form">
            <input name="ID" #ID="ngModel" [(ngModel)]="formData.ID" type="text" id="eventID" class="form -control"
              mdbInput>
            <label for="eventID" [class]="theme.label">Event ID</label>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <mat-form-field class="example-full-width" appearance="legacy">
              <mat-label [class]="theme.label">Event Name</mat-label>
              <input matInput class="MatInputBoxShadow" type="text" id="EventName" required name="EventName"
                pattern="[a-zA-Z0-9-$&_() ]*" #EventName="ngModel" [(ngModel)]="formData.EventName"
                [disabled]="primaryKey > 0">

              <mat-error *ngIf="EventName.invalid && EventName.dirty && !EventName.errors?.pattern">This
                field is required</mat-error>
              <mat-error *ngIf="EventName.invalid && EventName.errors?.pattern">Cannot contain
                any
                of the following characters: @ * # % " : <> ? / \ | </mat-error>

            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"
            [hidden]="common.isSingleVenueExist == null || common.isSingleVenueExist">
            <mat-form-field class="example-full-width" appearance="legacy">
              <mat-label [class]="theme.label">Venue</mat-label>
              <mat-select [(ngModel)]="formData.VenueID" #VenueID="ngModel" name="VenueID" required (selectionChange)="onDatesChange();">
                <mat-option *ngFor="let option of AutoCompVenue.results | async" [value]="option.ID">
                  {{ option.VenueName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="VenueID.invalid && VenueID.dirty">This field is
                required</mat-error>
            </mat-form-field>
          </div>
        </div>
        <!-- <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
          <mat-form-field class="example-full-width" appearance="legacy">
            <mat-label>Event Name</mat-label>
            <input matInput class="MatInputBoxShadow" type="text" id="EventName" required name="EventName"
              pattern="[a-zA-Z0-9-$&_() ]*" #EventName="ngModel" [(ngModel)]="formData.EventName" [disabled]="primaryKey > 0">

            <mat-error *ngIf="EventName.invalid && EventName.dirty && !EventName.errors?.pattern">This
              field is required</mat-error>
            <mat-error *ngIf="EventName.invalid && EventName.errors?.pattern">Cannot contain
              any
              of the following characters: @ * # % " : <> ? / \ | </mat-error>

          </mat-form-field>
        </div> -->
        <div class="row">


          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <mat-form-field class="example-full-width" appearance="legacy">
              <mat-label [class]="theme.label">Client</mat-label>
              <input type="text" matInput class="MatInputBoxShadow" aria-label="Number" [matAutocomplete]="auto"
                required [(ngModel)]="formData.ClientID" #ClientID="ngModel" name="ClientID"
                (ngModelChange)="AutoCompClient.searchResult.next($event)">
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="AutoCompClient.onDisplayValue"
                aria-label="Number" (optionSelected)="onClientChange()">
                <mat-option *ngFor="let option of AutoCompClient.results | async" [value]="option.ID"
                  style="font-size:13px;line-height:16px;border-bottom: 1px solid #d6d6d8;padding-top: 4px; padding-bottom: 4px;" >
                  {{ option.ClientName }}
                   <br>
                  <span [hidden]="option.StreetAddress==''">
                    Address:
                    {{option.StreetAddress}} , 
                  </span>
                  <span [hidden]="option.City==''">
                    City: {{option.City}}
                  </span>
                </mat-option>

              </mat-autocomplete>

              <mat-error *ngIf="ClientID.invalid && ClientID.dirty">This field is
                required</mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <mat-form-field class="example-full-width" appearance="legacy">
              <mat-label [class]="theme.label">Contact Person</mat-label>
              <mat-select [(ngModel)]="formData.ContactPersonID" #ContactPersonID="ngModel" required
                name="ContactPersonID">
                <mat-option>-- Clear --</mat-option>
                <mat-option *ngFor="let option of AutoCompContactPerson.results | async" [value]="option.ID">
                  {{ option.ContactPersonName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="ContactPersonID.invalid && ContactPersonID.dirty">This field
                is
                required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <mat-form-field class="example-full-width" appearance="legacy">
              <mat-label [class]="theme.label">Attendance</mat-label>
              <input matInput matInputCommified class="MatInputBoxShadow" type="text" id="Attendance" name="Attendance"
                #Attendance="ngModel" [(ngModel)]="formData.Attendance">
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <mat-form-field class="example-full-width" appearance="legacy">
              <mat-label [class]="theme.label">Standard Fee</mat-label>
              <input matInput matInputCommified class="MatInputBoxShadow" type="text" name="StandardLicenseFee"
                #StandardLicenseFee="ngModel" [(ngModel)]="formDataSub.StandardLicenseFee">
            </mat-form-field>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <mat-form-field class="example-full-width" appearance="legacy">
              <mat-label [class]="theme.label">Genre</mat-label>
              <input type="text" matInput class="MatInputBoxShadow" aria-label="Number" [matAutocomplete]="autoGenreID"
                [(ngModel)]="formData.GenreID" #GenreID="ngModel" name="GenreID"
                (ngModelChange)="AutoCompGenre.searchResult.next($event)">
              <mat-autocomplete #autoGenreID="matAutocomplete" [displayWith]="AutoCompGenre.onDisplayValue"
                aria-label="Number">
                <mat-option *ngFor="let option of AutoCompGenre.results | async" [value]="option.ID">
                  {{ option.GenreName }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <mat-form-field class="example-full-width" appearance="legacy">
              <mat-label [class]="theme.label">In Use</mat-label>
              <mat-select [(ngModel)]="formDataSub.SubEventTypeID" #SubEventTypeID="ngModel" name="SubEventTypeID"
                required>
                <mat-option *ngFor="let option of subEventType" [value]="option.ID">
                  {{ option.EventTypeName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="SubEventTypeID.invalid && SubEventTypeID.dirty">This field is
                required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <mat-form-field class="example-full-width" appearance="legacy">
              <mat-label [class]="theme.label">Event Start Date</mat-label>

              <input type="date" matInput name="EventStartDate" [(ngModel)]="formData.EventStartDate" required
                (change)="onDatesChange()" #EventStartDate="ngModel" />
            </mat-form-field>

          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <mat-form-field class="example-full-width" appearance="legacy">
              <mat-label [class]="theme.label">Event End Date</mat-label>

              <input type="date" matInput name="EventEndDate" [(ngModel)]="formData.EventEndDate" required
                (change)="onDatesChange()" #EventEndDate="ngModel" />
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <mat-form-field class="example-full-width" appearance="legacy">
              <mat-label>Event Start Time</mat-label>

              <input type="time" matInput name="ShowStartTime" #ShowStartTime="ngModel"
                [(ngModel)]="formDataSub.ShowStartTime" />
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6x">
            <mat-form-field class="example-full-width" appearance="legacy">
              <mat-label [class]="theme.label">Event End Time</mat-label>

              <input type="time" matInput [(ngModel)]="formDataSub.ShowEndTime" name="ShowEndTime"
                #ShowEndTime="ngModel" />
            </mat-form-field>
          </div>


          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <mat-form-field class="example-full-width" appearance="legacy">
              <mat-label [class]="theme.label">Status</mat-label>
              <mat-select [(ngModel)]="formDataSub.EventStatusID" #EventStatusID="ngModel" name="EventStatusID"
                required>
                <mat-option *ngFor="let option of Event_Status" [value]="option.ID">
                  {{ option.EventStatus }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="EventStatusID.invalid && EventStatusID.dirty">This field is
                required</mat-error>
            </mat-form-field>
          </div>

          <!-- <div class="row" style="line-height: 2.5">

                      <div class="col-md-2 col-sm-2 col-lg-2 col-xs-2">
                          <label for="">Category:</label>
                      </div>

                      <div class="col-md-4 col-sm-4 col-lg-4 col-xs-4">
                          <mat-radio-group aria-label="Select an option" class="d-flex justify-content-end" name="CategoryID"
                              #CategoryID="ngModel" [(ngModel)]="formData.CategoryID">
                              <mat-radio-button value="1" class=" pr-4">Profit</mat-radio-button>
                              <mat-radio-button value="2" class="pl-2">Non Profit</mat-radio-button>
                          </mat-radio-group>
                      </div>
                      <div class="col-md-2 col-sm-2 col-lg-2 col-xs-2">
                          <label for="">Event Type:</label>
                      </div>
                      <div class="col-md-4 col-sm-4 col-lg-4 col-xs-4">
                          <mat-radio-group aria-label="Select an option" class="d-flex justify-content-end" name="EventTypeID"
                              #EventTypeID="ngModel" [(ngModel)]="formData.EventTypeID">
                              <mat-radio-button value="1" class="">Ticketed</mat-radio-button>
                              <mat-radio-button value="2" class="pl-2">Non Ticketed</mat-radio-button>
                          </mat-radio-group>


                      </div>
                  </div> -->

          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <mat-form-field class="example-full-width" appearance="legacy">
              <mat-label [class]="theme.label">Category</mat-label>
              <mat-select required [(ngModel)]="formData.CategoryID" #CategoryID="ngModel" name="CategoryID">
                <mat-option>-- Clear --</mat-option>
                <mat-option *ngFor="let option of AutoCompCategory.results | async" [value]="option.ID">
                  {{ option.CategoryName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="CategoryID.invalid && CategoryID.dirty">This field is required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <mat-form-field class="example-full-width" appearance="legacy">
              <mat-label [class]="theme.label">Event Type</mat-label>
              <mat-select required [(ngModel)]="formData.EventTypeID" #EventTypeID="ngModel" name="EventTypeID">
                <mat-option>-- Clear --</mat-option>
                <mat-option *ngFor="let option of AutoCompTicketType.results | async" [value]="option.ID">
                  {{ option.TicketTypeName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="EventTypeID.invalid && EventTypeID.dirty">This field is required</mat-error>
            </mat-form-field>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" [hidden]="!errorMessageDatesStatus">
            <div *ngIf="errorMessageDatesStatus" class="alert alert-danger" role="alert">
              <div *ngIf="!EventDatesUnavailableError">
                <mdb-icon fas icon="exclamation-triangle" class="mr-2"></mdb-icon> {{errorMessageDatesStatus}}
              </div>
              <div *ngIf="EventDatesUnavailableError">
                <h6 class="alert-heading"> <mdb-icon fas icon="exclamation-triangle" class="mr-2"></mdb-icon> Following
                  dates
                  are unavailable</h6>
                <hr>
                <ul style="font-size:13px">
                  <li *ngFor="let arr of EventDatesUnavailableError" [hidden]="arr == ''">
                    {{arr}}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="loader" [hidden]="!isLoading">
  
        <div class="spinner-border " style="width: 3rem; height: 3rem;" role="status">
          <span class="sr-only">Loading...</span>
        </div>

      </div>
      <div class="row mb-1" [hidden]="true">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
          <mdb-checkbox name="IsSecured" #IsSecured="ngModel" [(ngModel)]="formData.IsSecured">
            <mat-label [class]="theme.label">
              Secured
            </mat-label>
          </mdb-checkbox>
          <mdb-checkbox name="IsDiscountApplied" #IsDiscountApplied="ngModel"
            [(ngModel)]="formData.IsDiscountApplied">
            <mat-label [class]="theme.label">
              Apply Discount
            </mat-label>
          </mdb-checkbox>
        </div>
      </div>

    </fieldset>
    <div class="row">
      <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 "></div>
      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
        <!-- <button mat-raised-button [disabled]="onDisabled(event)" type="button" (click)="onSubmit(event)" color="primary"
          class="btn-next ">
          Submit
        </button> -->
        <button type="button" (click)="onSubmit(event)" [disabled]="onDisabled(event)" mdbBtn [class]="theme.button" class="waves-light" mdbWavesEffect>
          Submit
        </button>
      </div>
    </div>
  </div>
</form>