<mdb-card cascade="true" narrower="true">

  <div
    class="view view-cascade gradient-card-header blue-gradient narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center">
    <h4 class="h4-responsive">Events</h4>
  </div>
  <mdb-card-body cascade="true">

    <!-- <div class="col-md-12 col-sm-12 col-xs-12 IsBigScreen justify-content-center">
        <!-- <mat-paginator (page)="syncPrimaryPaginator($event)"  [pageSize]="paginator.pageSize" [pageIndex]="paginator.pageIndex"
          [length]="paginator.length" [pageSizeOptions]="paginator.pageSizeOptions" showFirstLastButtons></mat-paginator> ===
        <br>
    </div> -->


    <div class="mat-elevation-z8">
      <mat-table [dataSource]="listData" matSort>

        <!-- <ng-container matColumnDef="actions" >

              <div  mat-cell *matCellDef="let row" class="card mt-3 " >
                <div class="">
                  <div class="card-header text-right" *ngFor="let col of columns" [ngClass]="col.name != 'actions' ? 'd-none' :''" style="font-size: 12px;background: none;">
                   
                    <button (click)="getEventPortalData(row)" class="iconshadow m-0" mdbBtn type="button" flat="true" size="md" mdbWavesEffect [matMenuTriggerFor]="menu">
                      <mdb-icon fas icon="ellipsis-h"></mdb-icon>
                    </button>
      
                    <mat-menu #menu="matMenu" yPosition="below" >
      
                      <button mat-menu-item
                      *ngIf="permissionService.CheckIfUserhasAccess_Action('Event','View')" (click)="onEdit(row)">
                      <mat-icon>remove_red_eye</mat-icon>
                        <span>Event Portal</span>
                      </button>
      
                      <button
                      *ngIf="permissionService.CheckIfUserhasAccess_Action('Event','View')"
                      mat-menu-item [routerLink]="['/event/event/form']"
                      [queryParams]="{ID:row.ID}">
                      <mat-icon>remove_red_eye</mat-icon>
                      <span>Event Details</span>
                      </button>
      
                      <button mat-menu-item [hidden]="portaldata.length > 0" class="text-center">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      </button>
      
                      <div [hidden]="portaldata.length == 0">
      
      
                        <button
                        [disabled]="permissionService.CheckIfUserhasAccess_Action('Contract Information','Add') == false && (portaldata.length > 0 ? portaldata[0].EventContractID : 0) == 0"
                        *ngIf="permissionService.CheckIfUserhasAccess_Action('Contract Information','View')"
                        [hidden]="permissionService.CheckIfUserhasAccess_Section('Contract Information')"
                        mat-menu-item [routerLink]="['/event/eventcontracts/form']"
                        [queryParams]="{EventID:portaldata.length>0 ? portaldata[0].EventID : 0,ID:portaldata.length>0 ? portaldata[0].EventContractID : 0}">
                        <mat-icon [hidden]="portaldata.length > 0 && portaldata[0].EventContractID == 0">remove_red_eye</mat-icon>
                        <mat-icon [hidden]="portaldata.length > 0 && portaldata[0].EventContractID != 0">add</mat-icon>
                          <span>Contract Information</span>
                        </button>
      
                        <button
                        [disabled]="permissionService.CheckIfUserhasAccess_Action('Deposit Schedule','Add') == false && (portaldata.length>0 ? portaldata[0].DepsitScheduleID : 0) == 0"
                        *ngIf="permissionService.CheckIfUserhasAccess_Action('Deposit Schedule','View')"
                        [hidden]="permissionService.CheckIfUserhasAccess_Section('Deposit Schedule')"
                        mat-menu-item [routerLink]="['/event/eventdueamount/form']"
                        [queryParams]="{EventID:portaldata.length>0 ? portaldata[0].EventID : 0,ID:portaldata.length>0 ? portaldata[0].DepsitScheduleID : 0}">
                        <mat-icon [hidden]="portaldata.length > 0 && portaldata[0].DepsitScheduleID == 0">remove_red_eye</mat-icon>
                        <mat-icon [hidden]="portaldata.length > 0 && portaldata[0].DepsitScheduleID != 0">add</mat-icon>
                          <span>Deposit Schedule</span>
                        </button>
      
                        <button
                        [disabled]="permissionService.CheckIfUserhasAccess_Action('Financials','Add') == false && (portaldata.length>0 ? portaldata[0].FinancialsID : 0) == 0"
                        *ngIf="permissionService.CheckIfUserhasAccess_Action('Financials','View')"
                        [hidden]="permissionService.CheckIfUserhasAccess_Section('Financials')"
                        mat-menu-item [routerLink]="['/event/clientpayments/form']"
                        [queryParams]="{EventID:portaldata.length>0 ? portaldata[0].EventID : 0,ID:portaldata.length>0 ? portaldata[0].FinancialsID  : 0}">
                          <mat-icon [hidden]="portaldata.length > 0 && portaldata[0].FinancialsID == 0">remove_red_eye</mat-icon>
                          <mat-icon [hidden]="portaldata.length > 0 && portaldata[0].FinancialsID != 0">add</mat-icon>
                          <span>Financials</span>
                        </button>
      
                        <button *ngIf="permissionService.CheckIfUserhasAccess_Action('Settlement','Add')" mat-menu-item [hidden]="portaldata.length > 0 && portaldata[0].SettlementEventID > 0 ? true : false"
                          [routerLink]="['/settlement/form']" [queryParams]="{EventID:row.ID}">
                          <mat-icon>add</mat-icon>
                          <span>Add Settlement</span>
                        </button>
      
                        <button *ngIf="!permissionService.CheckIfUserhasAccess_Section('Settlement List')" mat-menu-item [hidden]="portaldata.length > 0 && portaldata[0].SettlementEventID > 0 ? false : true"
                          [routerLink]="['/settlement/list']" [queryParams]="{EventID:portaldata.length>0 ? portaldata[0].SettlementEventID : 0}">
                          <mat-icon>remove_red_eye</mat-icon>
                          <span>Settlement List</span>
                        </button>
      
                        <button
                          [hidden]="permissionService.CheckIfUserhasAccess_Section('Event Reschedule')"
                          *ngIf="permissionService.CheckIfUserhasAccess_Action('Event Reschedule','View')"
                          mat-menu-item [routerLink]="['/event/event-reschedule/form']"
                          [queryParams]="{ID:portaldata.length > 0 ? portaldata[0].EventID : 0}">
                          <mat-icon>remove_red_eye</mat-icon>
                          <span>Event Reschedule</span>
                        </button>
      
                        <button mat-menu-item (click)="OpenEventNotesModal(row.ID)"
                        *ngIf="permissionService.CheckIfUserhasAccess_Action('Event Notes','View')"
                        [hidden]="permissionService.CheckIfUserhasAccess_Section('Event Notes')">
                          <mat-icon>remove_red_eye</mat-icon>
                          <span>Event Notes </span>
                          <mat-icon [hidden]="portaldata.length > 0 && portaldata[0].EventNotesID > 0 ? false : true" style="padding-left: 10px; color: gray;">sticky_note_2</mat-icon>
                        </button>
      
                        <button  mat-menu-item
                        [hidden]="portaldata.length > 0 && permissionService.CheckIfUserhasAccess_Section('Event Documents')"
                        [routerLink]="['/event/eventdocuments/form']"
                        [queryParams]="{EventID:portaldata.length > 0 ? portaldata[0].EventID : 0}" >
                        <mat-icon>remove_red_eye</mat-icon>
                        <span>Event Documents</span>
                        </button>
      
                      </div>
      
                    </mat-menu> 
                    
                  </div>

                  <div class="card-body" >
                    <div class=" justify-content-between" *ngFor="let col of columns"  [ngClass]="col.name == 'actions' ? 'd-none' :'d-flex'"   style="font-size: 12px;height: 25px;" >
                      <span  style="font-weight: 500;">{{col.header}} :</span>
                      <span > 
                        {{col.type == 'currency' ? (showRowData(row,col.name) | currency) : col.type == 'date' ? (showRowData(row,col.name) | date:'MM/d/yyyy' ) : showRowData(row,col.name)}}
                      </span>
                    </div>
                  </div>
                </div>
      
              </div>
            
          </ng-container>  -->

        <ng-container [matColumnDef]="col.name" *ngFor="let col of columns">

          <mat-header-cell *matHeaderCellDef mat-sort-header>{{col.header}}</mat-header-cell>

          <td mat-cell *matCellDef="let row">
            <span *ngIf="col.name == 'actions'" class="mob-action">

              <button (click)="getEventPortalData(row)" class="iconshadow m-0" mdbBtn type="button" flat="true" size="md" mdbWavesEffect [matMenuTriggerFor]="menu">
                <mdb-icon fas icon="ellipsis-h"></mdb-icon>
              </button>

              <mat-menu #menu="matMenu" yPosition="below" >

                <button mat-menu-item
                *ngIf="permissionService.CheckIfUserhasAccess_Action('Event','View')" (click)="onEdit(row)">
                <mat-icon>remove_red_eye</mat-icon>
                  <span>Event Portal</span>
                </button>

                <button
                *ngIf="permissionService.CheckIfUserhasAccess_Action('Event','View')"
                mat-menu-item [routerLink]="['/event/event/form']"
                [queryParams]="{ID:row.ID}">
                <mat-icon>remove_red_eye</mat-icon>
                <span>Event Details</span>
                </button>

                <button mat-menu-item [hidden]="portaldata.length > 0" class="text-center">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>

                <div [hidden]="portaldata.length == 0">


                  <button
                  [disabled]="permissionService.CheckIfUserhasAccess_Action('Contract Information','Add') == false && (portaldata.length > 0 ? portaldata[0].EventContractID : 0) == 0"
                  *ngIf="permissionService.CheckIfUserhasAccess_Action('Contract Information','View')"
                  [hidden]="permissionService.CheckIfUserhasAccess_Section('Contract Information')"
                  mat-menu-item [routerLink]="['/event/eventcontracts/form']"
                  [queryParams]="{EventID:portaldata.length>0 ? portaldata[0].EventID : 0,ID:portaldata.length>0 ? portaldata[0].EventContractID : 0}">
                  <mat-icon [hidden]="portaldata.length > 0 && portaldata[0].EventContractID == 0">remove_red_eye</mat-icon>
                  <mat-icon [hidden]="portaldata.length > 0 && portaldata[0].EventContractID != 0">add</mat-icon>
                    <span>Contract Information</span>
                  </button>

                  <button
                  [disabled]="permissionService.CheckIfUserhasAccess_Action('Deposit Schedule','Add') == false && (portaldata.length>0 ? portaldata[0].DepsitScheduleID : 0) == 0"
                  *ngIf="permissionService.CheckIfUserhasAccess_Action('Deposit Schedule','View')"
                  [hidden]="permissionService.CheckIfUserhasAccess_Section('Deposit Schedule')"
                  mat-menu-item [routerLink]="['/event/eventdueamount/form']"
                  [queryParams]="{EventID:portaldata.length>0 ? portaldata[0].EventID : 0,ID:portaldata.length>0 ? portaldata[0].DepsitScheduleID : 0}">
                  <mat-icon [hidden]="portaldata.length > 0 && portaldata[0].DepsitScheduleID == 0">remove_red_eye</mat-icon>
                  <mat-icon [hidden]="portaldata.length > 0 && portaldata[0].DepsitScheduleID != 0">add</mat-icon>
                    <span>Deposit Schedule</span>
                  </button>

                  <button
                  [disabled]="permissionService.CheckIfUserhasAccess_Action('Financials','Add') == false && (portaldata.length>0 ? portaldata[0].FinancialsID : 0) == 0"
                  *ngIf="permissionService.CheckIfUserhasAccess_Action('Financials','View')"
                  [hidden]="permissionService.CheckIfUserhasAccess_Section('Financials')"
                  mat-menu-item [routerLink]="['/event/clientpayments/form']"
                  [queryParams]="{EventID:portaldata.length>0 ? portaldata[0].EventID : 0,ID:portaldata.length>0 ? portaldata[0].FinancialsID  : 0}">
                    <mat-icon [hidden]="portaldata.length > 0 && portaldata[0].FinancialsID == 0">remove_red_eye</mat-icon>
                    <mat-icon [hidden]="portaldata.length > 0 && portaldata[0].FinancialsID != 0">add</mat-icon>
                    <span>Financials</span>
                  </button>

                  <button *ngIf="permissionService.CheckIfUserhasAccess_Action('Settlement','Add')" mat-menu-item [hidden]="portaldata.length > 0 && portaldata[0].SettlementEventID > 0 ? true : false"
                    [routerLink]="['/settlement/form']" [queryParams]="{EventID:row.ID}">
                    <mat-icon>add</mat-icon>
                    <span>Add Settlement</span>
                  </button>

                  <button *ngIf="!permissionService.CheckIfUserhasAccess_Section('Settlement List')" mat-menu-item [hidden]="portaldata.length > 0 && portaldata[0].SettlementEventID > 0 ? false : true"
                    [routerLink]="['/settlement/list']" [queryParams]="{EventID:portaldata.length>0 ? portaldata[0].SettlementEventID : 0}">
                    <mat-icon>remove_red_eye</mat-icon>
                    <span>Settlement List</span>
                  </button>

                  <button
                    [hidden]="permissionService.CheckIfUserhasAccess_Section('Event Reschedule')"
                    *ngIf="permissionService.CheckIfUserhasAccess_Action('Event Reschedule','View')"
                    mat-menu-item [routerLink]="['/event/event-reschedule/form']"
                    [queryParams]="{ID:portaldata.length > 0 ? portaldata[0].EventID : 0}">
                    <mat-icon>remove_red_eye</mat-icon>
                    <span>Event Reschedule</span>
                  </button>

                  <button mat-menu-item (click)="OpenEventNotesModal(row.ID)"
                  *ngIf="permissionService.CheckIfUserhasAccess_Action('Event Notes','View')"
                  [hidden]="permissionService.CheckIfUserhasAccess_Section('Event Notes')">
                    <mat-icon>remove_red_eye</mat-icon>
                    <span>Event Notes </span>
                    <mat-icon [hidden]="portaldata.length > 0 && portaldata[0].EventNotesID > 0 ? false : true" style="padding-left: 10px; color: gray;">sticky_note_2</mat-icon>
                  </button>

                  <button  mat-menu-item
                  [hidden]="portaldata.length > 0 && permissionService.CheckIfUserhasAccess_Section('Event Documents')"
                  [routerLink]="['/event/eventdocuments/form']"
                  [queryParams]="{EventID:portaldata.length > 0 ? portaldata[0].EventID : 0}" >
                  <mat-icon>remove_red_eye</mat-icon>
                  <span>Event Documents</span>
                  </button>

                </div>

              </mat-menu> 
            </span>
            <span *ngIf="col.name != 'actions'" class="mob-row ">
              <div class="mob-heading">{{col.header}}</div>
              <div class="mob-text">
                {{col.type == 'currency' ? (showRowData(row,col.name) | currency) : col.type == 'date' ?
                (showRowData(row,col.name) | date ) : showRowData(row,col.name)}}
              </div>
            </span>
          </td>

        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

      </mat-table>

      <app-table-bottom-area [listData]="listData" [search]="searchKey">
      </app-table-bottom-area>
    </div>
  </mdb-card-body>
</mdb-card>