import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { CommonService } from 'src/app/Base/Common.service';
import { EventDetails } from 'src/app/modules/event/event-details.model';
import { themeService } from 'src/app/services/theme.service';
import { DashboardPopupComponent } from '../dashboard-popup/dashboard-popup.component';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-calendar-dashboard',
  templateUrl: './calendar-dashboard.component.html',
  styleUrls: ['./calendar-dashboard.component.scss']
})
export class CalendarDashboardComponent implements OnInit {

  public modalRef: MDBModalRef;
  calendarGroup: string | null = "Calendar";
  @Output() onClick = new EventEmitter<any>();

  constructor(
    public theme: themeService,
    public modalService: MDBModalService,
    public common: CommonService,
    public route: Router,
    public permissionService:PermissionService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
  }

  onCalendarClick() {
    this.onClick.emit('Calendar');
  }

  onAvailClick() {

    this.onClick.emit('Avail');

  }
  onListClick() {

    this.onClick.emit('List');

  }

  onView(headingName: string, action: string, formName: string) {
    if (formName == 'settlement') {

      this.route.navigate(['/settlement/list'])
    }
    else {

      this.route.navigate(['/event/' + formName + '/list'])
    }

  }

 
  
  
  onClientView(){
    this.route.navigate(['/client/list']);
  }

  onContractView(){
    this.route.navigate(['/event/eventcontracts/list'])
  }

  onDocumentView(){
    this.route.navigate(['/event/eventdocuments/list'])
  }

  onEventDueAmountView(){
    this.route.navigate(['/event/eventdueamount/list'])
  }

  onSettlementView(){
    this.route.navigate(['/settlement/list'])
  }
}
