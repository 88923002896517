import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { themeService } from '../services/theme.service';


@Component({
  selector: 'app-message-dialoge',
  templateUrl: './message-dialoge.component.html',
  styleUrls: ['./message-dialoge.component.scss']
})
export class MessageDialogeComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef:MatDialogRef<MessageDialogeComponent>,
    public theme:themeService
    ) { }

  ngOnInit(): void {
    if(this.data.message.includes('Error:')){
      this.data.message = this.data.message.replace('Error:','');
    }
  }

  ngAfterViewInit(){
    
  }
  closeDialoge(){
    this.dialogRef.close();
  }

}
