<div class="myForm   shadow">
    <form class=" p-5 mb-5 rounded mt-4  border-form">
        <div class="d-flex justify-content-center">
            <div class="logo">
                <img src="assets/venuearc_logo.png" alt="Logo" style="width: 300px;">
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 pt-5 pb-3 text-center">
                <h5 style="color:#0e83a8">
                    Company/Orgnization Name Required!
                </h5>
            </div>
        </div> 

        <div class="row ">

            <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                <mat-form-field class="example-full-width" appearance="fill">
                    <!-- <mat-label>Company/Orgnization Name</mat-label>                     -->
                    <input matInput class="MatInputBoxShadow"
                    required    
                    name="Organization"                                     
                    [(ngModel)]="OrganizationName"
                    type="text" >                                 
                    <mat-error *ngIf="OrganizationName == null || OrganizationName == ''">This field is required</mat-error> 
                </mat-form-field>  
            </div>
           
        </div>

       

        <div class="row" >
            
            <div class="col-md-12 mt-2">
                <button [disabled]="IsshowProgress || OrganizationName == null || OrganizationName == ''" mdbBtn color="info" class="waves-light w-100" mdbWavesEffect
                    type="button" (click)="onSubmit()" rounded="true">
                    <mdb-icon fas icon="check"></mdb-icon>
                    
                    <span class="ml-2">Submit</span>
                </button>
            </div>
            
        </div>


    </form>
</div>